
    <div class="device-field-simulator-widget" *ngIf="!entity">
      <widget-spinner></widget-spinner>
    </div>
    <div class="device-field-simulator-widget" *ngIf="entity?.subject">
      <div class="row">
        <div class="col-2 text-right">{{ entity.subject.identifier }}</div>
        <div class="col text-center">
          <div *ngIf="!entity.subject.field?.is_derived_type">
            <div class="alert alert-success">
              <ng-container *ngIf="entity.subject.field.meta.include_previous">
                <input
                  type="number"
                  min="{{ rangeLow }}"
                  max="{{ rangeHigh }}"
                  [(ngModel)]="entity.previous_value"
                  (blur)="changeValue(entity)"
                  name="device"
                  size="10"
                />
                <span> - </span>
                <input
                  type="number"
                  min="{{ rangeLow }}"
                  max="{{ rangeHigh }}"
                  [(ngModel)]="entity.value"
                  (blur)="changeValue(entity)"
                  name="device"
                  size="10"
                />
              </ng-container>

              <ng-container *ngIf="!entity.subject.field.meta.include_previous">
                <input
                  type="number"
                  min="{{ rangeLow }}"
                  max="{{ rangeHigh }}"
                  [(ngModel)]="entity.value"
                  (blur)="changeValue(entity)"
                  name="device"
                  size="10"
                />
              </ng-container>
            </div>
          </div>
          <div *ngIf="entity.subject.field?.is_derived_type">
            <div class="alert alert-info">[Derived Field]</div>
          </div>
        </div>
        <div class="col text-left">
          <b>Range</b>: [{{ rangeLow }}, {{ rangeHigh }}], <b>Precision</b>:
          {{ entity.subject.field?.precision }}, <b>Unit</b>:
          {{ entity.subject.field?.unit }}
        </div>
      </div>
    </div>
  