import { Component } from "@angular/core";
import { Location } from "@angular/common";

@Component({
  selector: "back-button",
  template: `
    <i
      class="fa fa-2x fa-arrow-circle-o-left text-dark"
      (click)="back($event)"
    ></i>
  `,
})
export class BackButtonComponent {
  constructor(private locationService: Location) {}

  back(e) {
    e.preventDefault();
    this.locationService.back();
  }
}
