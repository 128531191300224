
import {ChangeDetectionStrategy, Component, Inject, Input, NgZone, OnInit} from '@angular/core';
import {SystemReportRepo} from '../../../repos/system-report.repo';
import {UserPreferencesService} from '../../../services/user-preferences.service';
import {environment} from '../../../../environments/environment';
import {SafePipe} from '../../../pipes';
import {FirebaseAuthService} from '../../../noizu/services/firebase-auth.service';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';


@Component({
  templateUrl: './timescale.component.html',
  //changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SystemHealthTimeScaleComponent implements OnInit {

  public liveViewLink: SafeResourceUrl | boolean = false;
  public pageVersionToggle = false;
  public pageVersion = '1.0';
  public expand = {
    pg: {
      summary: false,
      queries: false,
    },
    oban: {
      jobs: false
    },
    hypertables: {
      info: false,
      chunks: false,
      extended: false
    },
    jobs: {
      info: false,
      stats: false,
      errors: false,
      extended: false
    }
  }

  public data = {
    pg: {
      summary: null,
      queries: null,
    },
    oban: {
      jobs: null
    },
    hypertables: {
      info: null,
      chunks: null,
      extended: null
    },
    jobs: {
      info: null,
      stats: null,
      errors: null,
      extended: null
    }
  }


  constructor(
    public repo: SystemReportRepo,
    public preferences: UserPreferencesService,
    public auth: FirebaseAuthService,
    private domSanitizer: DomSanitizer
  ) {

  }


  ngOnInit() {
    this.loadVersion();
    this.auth.getTokenPromise().then((token) => {
      let link = environment.live_view + `/time-scale/dashboard?jwt=${token}`;
      this.liveViewLink = link;//this.domSanitizer.bypassSecurityTrustResourceUrl(link);
    })
  }

  liveViewLinkSanitized() {
    return this.liveViewLink;
  }

  loadVersion() {
    if (this.preferences ) {
      this.preferences.session.readyPromise.then(() => {
        this.pageVersion = this.preferences.featureVersion('timescale-dashboard', '1.0');
        this.pageVersionToggle = this.pageVersion == '2.0';
      });
    }
  }


  togglePageVersion(event) {
    event.preventDefault();
    if (this.pageVersion == '1.0') {
      this.liveViewLink = false;
      this.auth.getTokenPromise().then((token) => {
        let link = environment.live_view + `/time-scale/dashboard?jwt=${token}`;
        this.liveViewLink = link;//this.domSanitizer.bypassSecurityTrustResourceUrl(link);
      })
      this.pageVersion = '2.0';
    } else {
      this.pageVersion = '1.0';
    }
    this.pageVersionToggle = this.pageVersion == '2.0';
    this.preferences.setFeatureVersion('timescale-dashboard', this.pageVersion);
  }


  pg_summary() {
    this.repo.pgSummary().then((r) => {this.data.pg.summary = r});
  }

  pg_queries() {
    this.repo.pgQueries().then((r) => {this.data.pg.queries = r});
  }

  oban_jobs() {
    this.repo.obanJobs().then((r) => {this.data.oban.jobs = r});
  }

  hypertables() {
    this.repo.hyperTables().then((r) => {this.data.hypertables.info = r});
  }

  hypertables_chunks() {
    this.repo.hyperTableChunks().then((r) => {this.data.hypertables.chunks = r});
  }

  hypertables_extended() {
    this.repo.hyperTableExtended().then((r) => {this.data.hypertables.extended = r});
  }

  time_scale_jobs() {
    this.repo.timeScaleJobs().then((r) => {this.data.jobs.info = r});
  }

  time_scale_job_errors() {
    this.repo.timeScaleJobErrors().then((r) => {this.data.jobs.errors = r});
  }

  time_scale_job_stats() {
    this.repo.timeScaleJobStats().then((r) => {this.data.jobs.stats = r});
  }

  time_scale_job_extended() {
    this.repo.timeScaleJobExtended().then((r) => {this.data.jobs.extended = r});
  }

}
