
    <div *ngIf="!entity">
      <div class="row">
        <div class="col">
          <new-spinner [size]="'sm'"></new-spinner>
        </div>
      </div>
    </div>
    <ng-container *ngIf="entity">
      <firmware-release-widget
        [entity]="entity"
        [layout]="'full'"
        [options]="{}"
      ></firmware-release-widget>
    </ng-container>
  