import { AlertFacadeEntity } from "../facade.entity";
import { WidgetEnum_Alert_Facade } from "../../../enums/widget/alert";

export class AlertContactFacadeEntity extends AlertFacadeEntity {
  public triggerOnContact: any;

  public constructor(alert, json) {
    super(alert, json);
    this.triggerOnContact = json.triggerOnContact;
  }
  
  public get template(): string | null {
    return "contact";
  }

  alert_facade_widget() {
    return WidgetEnum_Alert_Facade.ALERT_FACADE_WIDGET__CONTACT;
  }
}
