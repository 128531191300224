import { Inject, Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { FirebaseAuthService } from "../../noizu/services/firebase-auth.service";
import { AppengineRepo } from "../appengine.repo";
import { UserDeviceAssociationEntity } from "../../entities/user/device-association.entity";

@Injectable()
export class UserDeviceAssociationRepo extends AppengineRepo {
  public _kind = "sensor-associations";
  public _singular = "sensor-association";

  constructor(
    @Inject(HttpClient) client: HttpClient,
    @Inject(FirebaseAuthService) auth: FirebaseAuthService
  ) {
    super(client, auth);
  }

  entity(record) {
    return new UserDeviceAssociationEntity(this.client, this.auth, record);
  }

  getUserListPromise(userIdentifier: string, options = {}) {
    let url = `${this.apiBase()}/user/${userIdentifier}/sensorAssociations`;
    return this._getListPromise(
      url,
      (record) => {
        return this.entity(record);
      },
      options
    );
  }
}
