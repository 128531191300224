import { Injectable } from "@angular/core";
import { DataManufacturersRepo } from "app/repos";
import { DataManufacturersEntity } from "app/entities";

@Injectable({
  providedIn: "root",
})
export class ManufacturerListService {
  constructor(private repo: DataManufacturersRepo) {}
  getManufacturers(): Promise<DataManufacturersEntity[]> {
    return this.repo.getListPromise().then((response: any) => {
      return response.items;
    });
  }
}
