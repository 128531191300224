import { Component, Input, Output, EventEmitter } from "@angular/core";
import { EntityLogEntity } from "../../../entities/log/entity-log.entity";
import { WidgetBase } from "../widget-base";
import { LogWidgetEventStruct } from "./log-widget-event.struct";
import { LogWidgetEnum } from "../../../enums";

@Component({
  selector: "log-details-generic-widget",
  template: `
    <ng-container *ngIf="entity">
      <ng-container [ngSwitch]="layout">
        <div class="log-details-generic-widget" *ngSwitchCase="'card-details'">
          <div class="row mb-4">
            <div class="col-12"><strong>[No Custom Format]</strong></div>
          </div>

          <div class="row">
            <div class="col-12">
              <div class="card">
                <div class="card-header">
                  Raw Data<span class="float-right">
                    <i
                      (click)="expandRaw = !expandRaw"
                      class="fa fa-sm"
                      [ngClass]="{
                        'fa-chevron-down': !expandRaw,
                        'fa-chevron-up': expandRaw
                      }"
                    >
                    </i>
                  </span>
                </div>
                <div class="card-body" *ngIf="expandRaw">
                  <div class="row">
                    <div class="col-12">
                      <pre>Topic: {{ entity.topic | json }}</pre>
                      <pre>Type: {{ entity.type | json }}</pre>
                      <pre>Subject: {{ entity.subject | json }}</pre>
                      <pre>Message: {{ entity.message | json }}</pre>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="log-details-generic-widget"
          *ngSwitchCase="'table-row-details'"
        >
          <div class="row">
            <div class="col-2"><b>Topic:</b></div>
            <div class="col">{{ entity.topic }}</div>
          </div>
          <div class="row">
            <div class="col-2"><b>Type:</b></div>
            <div class="col">{{ entity.type }}</div>
          </div>
          <div class="row">
            <div class="col-2"><b>Subject:</b></div>
            <div class="col">{{ entity.subject }}</div>
          </div>
          <div class="row">
            <div class="col">
              <pre>Message: {{ entity.message | json }}</pre>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
  `,
})
export class LogDetailsGenericWidgetComponent extends WidgetBase {
  @Input() entity: EntityLogEntity = null;
  @Input() options: any = {};
  @Input() layout: string = null;
  @Output() widgetEvent = new EventEmitter<LogWidgetEventStruct>();
  forwardEvent(e, widgetEvent: LogWidgetEventStruct) {
    e.preventDefault();
    this.widgetEvent.emit(widgetEvent);
  }
  public LogWidgetEnum = LogWidgetEnum;
  public expand = false;
  public expandRaw = false;

  constructor() {
    super();
  }
}
