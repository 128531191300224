
    <div [ngSwitch]="layout">
      <div *ngSwitchCase="'card'">
        <div class="card border-info">
          <div class="card-header bg-info m-0 p-1">
            <div class="row">
              <div class="col-9">
                {{ options.title || "Gateway Diagnostics" }}
              </div>
              <div class="col-3 text-right">
                <i
                  (click)="gatewayDiagExpand = !gatewayDiagExpand"
                  class="fa fa-sm pr-2"
                  [ngClass]="{
                    'fa-chevron-down': !gatewayDiagExpand,
                    'fa-chevron-up': gatewayDiagExpand
                  }"
                >
                </i>
              </div>
              <div class="col-3 text-right">
                <i
                  (click)="gatewayDiagExpand = !gatewayDiagExpand"
                  class="fa fa-sm pr-2"
                  [ngClass]="{
                    'fa-chevron-down': !gatewayDiagExpand,
                    'fa-chevron-up': gatewayDiagExpand
                  }"
                >
                </i>
              </div>
            </div>
          </div>

          <div class="card-body m-0 p-1" *ngIf="gatewayDiagExpand">
            <div class="row">
              <div class="col-12">
                <embed-device-gateway-diagnostics
                  [entity]="entity"
                  [layout]="'table'"
                  [options]="options"
                ></embed-device-gateway-diagnostics>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngSwitchCase="'table'">
        <div>
          <div class="div-table-th">
            <div class="row">
              <div class="col-5">Key</div>
              <div class="col-5">Value</div>
            </div>
          </div>

          <div
            class="row"
            *ngIf="
              entity._gatewayDiagList && entity?._gatewayDiagList.length === 0
            "
          >
            <div class="col">
              <div class="alert-info">
                <widget-spinner></widget-spinner>
              </div>
            </div>
          </div>

          <div *ngIf="entity._gatewayDiagList">
            <div
              *ngFor="let gat of entity?._gatewayDiagList; let i = index"
              [ngClass]="{
                'div-table-tr': i % 2 == 1,
                'div-table-tr-alt': i % 2 == 0
              }"
            >
              <div class="row">
                <div class="col-5">
                  <b>{{ gat.key }}:</b>
                </div>
                <div class="col-5">
                  <span>{{ gat.value }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  