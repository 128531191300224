import { Inject, Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { FirebaseAuthService } from "../../../noizu/services/firebase-auth.service";
import { ElixirRepo } from "../../elixir.repo";
import { MCUFirmwareLinkEntity } from "../../../entities/mcu/firmware";

@Injectable()
export class MCUFirmwareLinkRepo extends ElixirRepo {
  public _kind = "mcu-firmware-links";
  public _singular = "mcu-firmware-links";

  constructor(
    @Inject(HttpClient) client: HttpClient,
    @Inject(FirebaseAuthService) auth: FirebaseAuthService
  ) {
    super(client, auth);
  }

  entity(record) {
    return new MCUFirmwareLinkEntity(this.client, this.auth, record);
  }

  createPromise(entity: MCUFirmwareLinkEntity) {
    if (entity.identifier == "new") {
      entity.identifier = null;
    }
    const data = entity.toJson();
    console.log("CREATE LINK", data);
    return this._post(`${this.apiBase()}/mcu/firmware/links`, data, (v, r) => {
      r(this.entity(v));
    });
  }

  updatePromise(entity: MCUFirmwareLinkEntity) {
    const data = entity.toJson();
    console.log("UPDATE LINK", data);
    return this._put(
      `${this.apiBase()}/mcu/firmware/links/${entity.identifier}`,
      data,
      (v, r) => {
        r(this.entity(v));
      }
    );
  }

  getEntityPromise(id, options = {}) {
    return this._getEntityPromise(
      `${this.apiBase()}/mcu/firmware/links/${id}`,
      (record, resolve) => {
        resolve(this.entity(record));
      },
      options
    );
  }

  getListPromise(options = {}) {
    return new Promise((resolve, reject) => {
      this._get(
        `${this.apiBase()}/mcu/firmware/links`,
        (json, resolve, reject) => {
          try {
            const response = [];
            if (Array.isArray(json)) {
              json.forEach((item) => {
                response.push(this.entity(item));
              });
            } else {
              const errorMessage = "Invalid response: Expected an array";
              reject(new Error(errorMessage));
              return;
            }
            resolve(response);
          } catch (error) {
            reject(error);
          }
        },
        options
      );
    });
  }
}
