
    <div class="card border-dark alert-facade-probe-widget">
      <!-- Card Header -->
      <div class="card-header bg-dark text-white">
        <h5 class="mb-0">Probe Alert</h5>
      </div>

      <!-- Card Body -->
      <div class="card-body">
        <!-- Device Section -->
        <div class="row mb-3 align-items-center">
          <div class="col-4 fw-bold">Device</div>
          <div class="col-8">
            <a
              [routerLink]="'/portal/devices/show/' + entity.device"
              class="text-primary text-decoration-none"
            >
              {{ entity.device }}
            </a>
          </div>
        </div>

        <!-- Association Section -->
        <div class="row mb-3 align-items-center">
          <div class="col-4 fw-bold">Association</div>
          <div class="col-8">{{ entity.deviceAssociation }}</div>
        </div>

        <!-- Field Section -->
        <div class="row mb-3 align-items-center">
          <div class="col-4 fw-bold">Field</div>
          <div class="col-8">{{ entity.field }}</div>
        </div>

        <!-- Divider -->
        <hr class="my-3" />

        <!-- Temperature Settings Section -->
        <div [ngSwitch]="temperatureFormat">
          <!-- Temperature Above Section -->
          <div *ngSwitchCase="FormatEnum.ValueAbove" class="row mb-3">
            <div class="col-5"><b>Temperature Above</b></div>
            <div *ngIf="!options?.edit" class="col-7">
              <b>{{ entity.temperatureAbove.value }}</b>
              <span> {{ entity.temperatureAbove.unit?.name }}</span>
            </div>
            <div *ngIf="options?.edit" class="col-7">
              <input
                type="number"
                [(ngModel)]="entity.temperatureAbove.value"
                (ngModelChange)="facadeChange($event)"
                class="form-control"
                name="temp_above"
              />
              <span> {{ entity.temperatureAbove.unit?.name }}</span>
            </div>
          </div>

          <!-- Temperature Below Section -->
          <div *ngSwitchCase="FormatEnum.ValueBelow" class="row mb-3">
            <div class="col-5"><b>Temperature Below</b></div>
            <div *ngIf="!options?.edit" class="col-7">
              <b>{{ entity.temperatureBelow.value }}</b>
              <span> {{ entity.temperatureBelow.unit?.name }}</span>
            </div>
            <div *ngIf="options?.edit" class="col-7">
              <input
                type="number"
                [(ngModel)]="entity.temperatureBelow.value"
                (ngModelChange)="facadeChange($event)"
                class="form-control"
                name="temp_below"
              />
              <span> {{ entity.temperatureBelow.unit?.name }}</span>
            </div>
          </div>

          <!-- Temperature Between Section -->
          <div *ngSwitchCase="FormatEnum.ValueBetween" class="row mb-3">
            <div class="col-5"><b>Temperature Between</b></div>
            <div *ngIf="!options?.edit" class="col-7">
              <b>{{ entity.temperatureAbove.value }}</b>
              <span> {{ entity.temperatureAbove.unit?.name }}</span> and
              <b>{{ entity.temperatureBelow.value }}</b>
              <span> {{ entity.temperatureBelow.unit?.name }}</span>
            </div>
            <div *ngIf="options?.edit" class="col-7">
              <input
                type="number"
                [(ngModel)]="entity.temperatureAbove.value"
                (ngModelChange)="facadeChange($event)"
                class="form-control mb-2"
                name="temp_above"
              />
              <span> {{ entity.temperatureAbove.unit?.name }}</span>
              and
              <input
                type="number"
                [(ngModel)]="entity.temperatureBelow.value"
                (ngModelChange)="facadeChange($event)"
                class="form-control mt-2"
                name="temp_below"
              />
              <span> {{ entity.temperatureBelow.unit?.name }}</span>
            </div>
          </div>

          <!-- Temperature Outside Section -->
          <div *ngSwitchCase="FormatEnum.ValueOutside" class="row mb-3">
            <div class="col-5"><b>Temperature Outside</b></div>
            <div *ngIf="!options?.edit" class="col-7">
              <b>{{ entity.temperatureBelow.value }}</b>
              <span> {{ entity.temperatureBelow.unit?.name }}</span> and
              <b>{{ entity.temperatureAbove.value }}</b>
              <span> {{ entity.temperatureAbove.unit?.name }}</span>
            </div>
            <div *ngIf="options?.edit" class="col-7">
              <input
                type="number"
                [(ngModel)]="entity.temperatureBelow.value"
                (ngModelChange)="facadeChange($event)"
                class="form-control mb-2"
                name="temp_below"
              />
              <span> {{ entity.temperatureBelow.unit?.name }}</span>
              and
              <input
                type="number"
                [(ngModel)]="entity.temperatureAbove.value"
                (ngModelChange)="facadeChange($event)"
                class="form-control mt-2"
                name="temp_above"
              />
              <span> {{ entity.temperatureAbove.unit?.name }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  