import { Component, Input } from "@angular/core";

@Component({
  selector: "widget-time-stamp",
  template: `<span *ngIf="time">{{ time }}</span>`,
})
export class WidgetTimeStampComponent {
  @Input() input: any;
  public time = null;
  constructor() {}
  ngOnInit() {
    console.log("Input ", this.input);
    if (this.input) {
      this.time = new Date(this.input * 1000);
    } else {
      this.time = "Null";
    }
  }
}
