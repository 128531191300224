import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  FirmwareLogicalWidgetEnum,
  FirmwareWidgetEnum,
  ModalWidgetEnum,
  WidgetEnum,
} from "../../../enums/widget";
import {
  FirmwareApprovalEntity,
  FirmwareDefinitionEntity,
  FirmwareDefinitionLogicalWidget,
  FirmwareReleaseEntity,
  FirmwareTargetSetLogicalWidget,
  ModalWidget,
} from "../../../entities/";
import { ToasterService } from "angular2-toaster";
import { FirmwareWidgetEventStruct } from "./firmware-widget-event.struct";
import { FirmwareLogicalWidgetEventStruct } from "../logical/firmware/firmware-logical-widget-event.struct";
import { EntityReference } from "../../../noizu/structs/entity-reference";
import { BsModalRef, BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import {
  FirmwareApprovalRepo,
  FirmwareDefinitionRepo,
  FirmwareLinkV2Repo,
} from "../../../repos";
import { AuthService } from "../../../services/auth.service";

@Component({
  selector: "firmware-release-widget",
  template: `
    <ng-container *ngIf="entity">
      <!-- ========================================================== -->
      <!--                     Headers                                -->
      <!-- ========================================================== -->
      <ng-template #fullHeader let-closeIcon="close || false">
        <div class="card-header">
          <a routerLink="/portal/firmware/releases/{{ entity.identifier }}"
            >#{{ entity.identifier }}</a
          >
          ({{ entity.release_name }})
          <div class="card-header-actions" *ngIf="closeIcon">
            <i class="fa fa-close" (click)="closeSidebar($event)"></i>
          </div>
        </div>
      </ng-template>

      <ng-template #thumbHeader>
        <div class="card-header p-1">
          <div class="row">
            <div class="col">
              <a
                href="/portal/firmware/releases/{{ entity.identifier }}"
                (click)="openSidebar($event)"
                >#{{ entity.identifier }}</a
              >
            </div>
            <div class="col-1 text-center">|</div>
            <div
              class="col text-right"
              [ngClass]="{
                'text-success': entity.status == 'complete',
                'text-dark': entity.status == 'rejected'
              }"
            >
              ({{ entity.status }})
              <i
                class="fa fa-expand text-secondary"
                (click)="editRelease($event)"
              ></i>
            </div>
          </div>
          <div class="row">
            <div class="col border-bottom"></div>
          </div>
        </div>
      </ng-template>

      <!-- ========================================================== -->
      <!--                        Widgets                             -->
      <!-- ========================================================== -->
      <ng-template #workerStatus>
        <div class="card">
          <div class="card-body bg-white text-body">
            <div class="row">
              <div class="col-6"><strong>Worker Status:</strong></div>
              <div class="col-6">
                {{ entity.roll_out_report?.release_status?.status }}
              </div>
            </div>

            <div class="row">
              <div class="col-6"><strong>Current Stage:</strong></div>
              <div class="col-6">
                {{ entity.roll_out_report?.release_status?.active_group }}
              </div>
            </div>

            <div class="row">
              <div class="col-6"><strong>Active Units:</strong></div>
              <div class="col-6">
                {{ entity.roll_out_report?.managed_units }}
              </div>
            </div>
            <div class="row">
              <div class="col-6"><strong>Control Units:</strong></div>
              <div class="col-6">
                {{ entity.roll_out_report?.control_units }}
              </div>
            </div>

            <div class="row">
              <div class="col-6">Started:</div>
              <div class="col-6">(...)</div>
            </div>
            <div class="row">
              <div class="col-6">Completed:</div>
              <div class="col-6">(...)</div>
            </div>
          </div>
        </div>
      </ng-template>

      <ng-template #groupAssignment>
        <div class="card">
          <div class="card-header bg-secondary">Group Assignment3</div>
          <div class="card-body bg-white mt-1 pt-1">
            <div class="row mb-1">
              <div class="col-6">
                <strong class="text-black-50">Control Group</strong>
              </div>
              <div class="col-6">
                {{
                  entity.roll_out_report?.managed_workers?.group_totals[
                    "control"
                  ] | json
                }}
              </div>
            </div>
            <div class="row" *ngFor="let v of range(10); let i = index">
              <div class="col-6">
                <i
                  class="fa pr-2"
                  [ngClass]="{
                    'fa-plus text-success': groupActive(i + 1),
                    'fa-minus text-danger': !groupActive(i + 1)
                  }"
                ></i>
                <strong [ngClass]="{ 'text-black-50': !groupActive(i + 1) }"
                  >Stage {{ i + 1 }} Group</strong
                >
              </div>
              <div class="col-6">
                {{
                  entity.roll_out_report?.managed_workers?.group_totals[
                    "" + (i + 1)
                  ] | json
                }}
              </div>
            </div>
          </div>
        </div>
      </ng-template>

      <ng-template #rolloutSnapshotChart>
        <div class="card bg-info">
          <div class="card-header bg-info">Rollout Snapshot</div>
          <div class="card-body bg-white">
            <canvas
              baseChart
              class="chart"
              [data]="releaseRollOutPieData"
              [labels]="releaseRollOutPieLabels"
              [chartType]="'pie'"
              (chartHover)="chartHovered('releaseRollout', $event)"
              (chartClick)="chartClicked('releaseRollout', $event)"
            ></canvas>
          </div>
        </div>
      </ng-template>

      <ng-template #releaseStagesChart>
        <div class="card bg-info">
          <div class="card-header bg-info">Release Stages</div>
          <div class="card-body bg-white">
            <canvas
              baseChart
              class="chart"
              [datasets]="groupStageChartData"
              [labels]="groupStageChartLabels"
              [legend]="false"
              [options]="groupStageChartOptions"
              [chartType]="'bar'"
              (chartHover)="chartHovered('groupStageChat', $event)"
              (chartClick)="chartClicked('groupStageChat', $event)"
            ></canvas>
          </div>
        </div>
      </ng-template>

      <!-- ========================================================== -->
      <!--                        Fields                              -->
      <!-- ========================================================== -->

      <!-- .......................................................... -->
      <!-- Text Edit                                                  -->
      <!-- .......................................................... -->

      <ng-template
        #textFieldRow
        let-fieldName="field"
        let-fieldIndex="fieldIndex"
        let-leftCol="lcol || 'col-3 text-right'"
        let-rightCol="rcol || 'col text-left'"
        let-textSize="textSize || 16"
      >
        <div class="row" *ngIf="entity?.validation?.messages[fieldIndex]">
          <div class="col">
            <div class="alert alert-danger">
              {{ entity?.validation?.messages[fieldIndex] }}
            </div>
          </div>
        </div>

        <div class="row">
          <div class="{{ leftCol }}">
            <i
              class="fa fa-exclamation text-danger"
              *ngIf="entity?.validation?.messages[fieldIndex]"
            ></i
            ><b class="">{{ fieldName }}:</b>
          </div>
          <div class="{{ rightCol }}">
            <span *ngIf="options?.edit">
              <input
                type="text"
                [(ngModel)]="entity[fieldIndex]"
                (ngModelChange)="recordChange($event, fieldIndex)"
                size="{{ textSize }}"
              />
            </span>
            <span *ngIf="!options?.edit">
              <span *ngIf="entity[fieldIndex]">{{ entity[fieldIndex] }}</span>
              <span *ngIf="!entity[fieldIndex]" class="text-danger"
                >(NULL)</span
              >
            </span>
          </div>
        </div>
      </ng-template>

      <ng-template
        #selectWidgetRow
        let-fieldName="field"
        let-fieldIndex="fieldIndex"
        let-logicalWidget="logicalWidget"
        let-leftCol="lcol || 'col-3 text-right'"
        let-rightCol="rcol || 'col text-left'"
        let-textSize="textSize || 16"
      >
        <div class="row" *ngIf="entity?.validation?.messages[fieldIndex]">
          <div class="col">
            <div class="alert alert-danger">
              {{ entity?.validation?.messages[fieldIndex] }}
            </div>
          </div>
        </div>
        <div class="row">
          <div class="{{ leftCol }}">
            <i
              class="fa fa-exclamation text-danger"
              *ngIf="entity?.validation?.messages[fieldIndex]"
            ></i
            ><b class="">{{ fieldName }}:</b>
          </div>
          <div class="{{ rightCol }}">
            <logical-widget
              *ngIf="logicalWidget"
              (widgetEvent)="eventHandler($event, fieldIndex, releaseModal)"
              [entity]="logicalWidget"
              [options]="options"
              [layout]="'standard'"
            ></logical-widget>
          </div>
        </div>
      </ng-template>

      <ng-template #coreSettings>
        <ng-container
          *ngTemplateOutlet="
            textFieldRow;
            context: { field: 'Name', fieldIndex: 'release_name' }
          "
        ></ng-container>
        <ng-container
          *ngTemplateOutlet="
            textFieldRow;
            context: { field: 'Description', fieldIndex: 'release_description' }
          "
        ></ng-container>
        <ng-container
          *ngTemplateOutlet="
            textFieldRow;
            context: { field: 'Note', fieldIndex: 'release_note' }
          "
        ></ng-container>

        <ng-container
          *ngTemplateOutlet="
            selectWidgetRow;
            context: {
              field: 'Target Firmware',
              fieldIndex: 'firmware',
              logicalWidget: targetFirmware
            }
          "
        ></ng-container>
        <ng-container
          *ngTemplateOutlet="
            selectWidgetRow;
            context: {
              field: 'Rollback Firmware',
              fieldIndex: 'rollback',
              logicalWidget: rollbackFirmware
            }
          "
        ></ng-container>

        <div class="row">
          <div class="col-3 text-right"><b>Approval:</b></div>
          <div class="col text-left">
            <a
              *ngIf="entity.approval"
              (click)="showApproval($event, releaseModal)"
              href="/portal/firmware/approvals/{{ entity.approval }}"
              >{{ entity.approval }}</a
            >
            <div
              class="btn btn-pill btn-success"
              *ngIf="!entity.approval"
              (click)="approveRelease($event, releaseModal)"
            >
              Approve
            </div>
          </div>
        </div>

        <div
          class="row"
          *ngIf="entity.roll_out_report?.release_status?.managed_firmware_links"
        >
          <div class="col-3 text-right"><b>Links:</b></div>
          <div class="col text-left">
            <div
              class="row"
              *ngFor="
                let link of entity.roll_out_report?.release_status
                  ?.managed_firmware_links
              "
            >
              <div class="col">
                <a
                  (click)="showLink($event, link, releaseModal)"
                  href="/portal/firmware/links/{{ link }}"
                  >{{ link }}</a
                >
              </div>
            </div>
          </div>
        </div>

        <ng-container
          *ngTemplateOutlet="
            selectWidgetRow;
            context: {
              field: 'Targets',
              fieldIndex: 'targets',
              logicalWidget: releaseTargetSet,
              lcol: 'font-xl col-12 text-center',
              rcol: 'col-12'
            }
          "
        ></ng-container>
      </ng-template>

      <!-- ========================================================== -->
      <!--                        Content                             -->
      <!-- ========================================================== -->

      <!-- .......................................................... -->
      <!-- Extended Content                                           -->
      <!-- .......................................................... -->
      <ng-template #extendedContent>
        <div class="row">
          <div class="col">
            <div class="card">
              <div class="card-header">Core Settings</div>
              <div class="card-body">
                <div
                  class="row"
                  *ngIf="
                    !entity?.validation?.valid &&
                    entity?.validation?.messages['common']
                  "
                >
                  <div class="col">
                    <div class="alert alert-warning">
                      {{ entity?.validation?.messages["common"] }}
                    </div>
                  </div>
                </div>

                <ng-container *ngTemplateOutlet="coreSettings"></ng-container>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <ng-container *ngTemplateOutlet="workerStatus"></ng-container>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <ng-container *ngTemplateOutlet="groupAssignment"></ng-container>
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <ng-container
              *ngTemplateOutlet="rolloutSnapshotChart"
            ></ng-container>
          </div>
          <div class="col-6">
            <ng-container *ngTemplateOutlet="releaseStagesChart"></ng-container>
          </div>
        </div>

        <div
          class="row"
          *ngIf="
            entity?.roll_out_report?.roll_up_report?.sample &&
            objectKeys(entity.roll_out_report.roll_up_report.sample).length > 0
          "
        >
          <div class="col">
            <div class="card">
              <div class="card-header">Sample</div>
              <div class="card-body">
                <ng-container
                  *ngFor="
                    let section of objectKeys(
                      entity.roll_out_report.roll_up_report.sample
                    )
                  "
                >
                  <div class="row">
                    <div class="col-12">
                      <strong>{{ section }}:</strong>
                    </div>
                  </div>
                  <div class="row mb-4">
                    <div class="col">
                      <span
                        *ngFor="
                          let device of entity.roll_out_report.roll_up_report
                            .sample[section]
                        "
                      >
                        <a
                          class="mr-2"
                          routerLink="/portal/devices/show/{{ device }}"
                          >{{ device }}</a
                        >
                      </span>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </ng-template>

      <!-- .......................................................... -->
      <!-- Edit Content                                               -->
      <!-- .......................................................... -->
      <ng-template #editContent>
        <div
          class="row"
          *ngIf="
            !entity?.validation?.valid && entity?.validation?.messages['common']
          "
        >
          <div class="col">
            <div class="alert alert-warning">
              {{ entity?.validation?.messages["common"] }}
            </div>
          </div>
        </div>

        <ng-container *ngTemplateOutlet="coreSettings"></ng-container>
      </ng-template>

      <!-- .......................................................... -->
      <!-- Compact Content                                            -->
      <!-- .......................................................... -->
      <ng-template #compactContent>
        <!-- ================ Collapse Graph =============== -->
        <div class="row border-bottom">
          <div class="col-10">
            <B>Graph</B>
          </div>
          <div class="col-2 text-right">
            <i
              class="fa"
              (click)="toggleGraph()"
              [ngClass]="{
                'fa-arrow-up': !collapseGraph,
                'fa-arrow-down': collapseGraph
              }"
              [attr.aria-expanded]="!collapseGraph"
              aria-controls="collapseGraph"
            ></i>
          </div>
        </div>
        <div
          id="collapseGraph"
          class="border-bottom pl-3 mb-3"
          [collapse]="collapseGraph"
          [isAnimated]="true"
        >
          <div class="well well-lg">
            <div class="row">
              <div class="col">
                <div class="row" *ngIf="entity.status">
                  <div class="col-12 bg-dark">
                    <canvas
                      baseChart
                      class="chart"
                      [data]="releaseRollOutPieData"
                      [labels]="releaseRollOutPieLabels"
                      [legend]="false"
                      [chartType]="'pie'"
                      (chartHover)="chartHovered('releaseRollout', $event)"
                      (chartClick)="chartClicked('releaseRollout', $event)"
                    ></canvas>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- ================ Collapse Extended Details =============== -->
        <div class="row border-bottom">
          <div class="col-10">
            <B>Extended Details</B>
          </div>
          <div class="col-2 text-right">
            <i
              class="fa"
              (click)="toggleExtended()"
              [ngClass]="{
                'fa-arrow-up': collapseExtendedDetails,
                'fa-arrow-down': !collapseExtendedDetails
              }"
              [attr.aria-expanded]="!collapseExtendedDetails"
              aria-controls="collapseExtendedDetails"
            ></i>
          </div>
        </div>

        <div
          id="collapseExtendedDetails"
          class="pl-1 mb-3"
          [collapse]="collapseExtendedDetails"
          [isAnimated]="true"
        >
          <div class="well well-lg">
            <!-- ================ Collapse Notes =============== -->
            <div class="row border-bottom">
              <div class="col-10">
                <B>Notes</B>
              </div>
              <div class="col-2 text-right">
                <i
                  class="fa"
                  (click)="toggleNotes()"
                  [ngClass]="{
                    'fa-arrow-up': collapseNotes,
                    'fa-arrow-down': !collapseNotes
                  }"
                  [attr.aria-expanded]="!collapseNotes"
                  aria-controls="collapseNotes"
                ></i>
              </div>
            </div>

            <div
              id="collapseNotes"
              class="border-bottom pl-3 mb-3"
              [collapse]="collapseNotes"
              [isAnimated]="true"
            >
              <div class="well well-lg">
                <div class="row">
                  <div class="col-12"><b>Release Name:</b></div>
                  <div class="col-12">{{ entity.release_name }}</div>
                </div>

                <div class="row">
                  <div class="col-12"><b>Release Description:</b></div>
                  <div class="col-12">{{ entity.release_description }}</div>
                </div>

                <div class="row">
                  <div class="col-12"><b>Release Note:</b></div>
                  <div class="col-12">{{ entity.release_note }}</div>
                </div>
              </div>
            </div>

            <!-- ================ Collapse Time Stamps =============== -->
            <div class="row border-bottom">
              <div class="col-10">
                <B>Time Stamps</B>
              </div>
              <div class="col-2 text-right">
                <i
                  class="fa"
                  (click)="toggleTimeStamps()"
                  [ngClass]="{
                    'fa-arrow-up': collapseTimeStamps,
                    'fa-arrow-down': !collapseTimeStamps
                  }"
                  [attr.aria-expanded]="!collapseTimeStamps"
                  aria-controls="collapseNotes"
                ></i>
              </div>
            </div>
            <div
              id="collapseTimeStamps"
              class="border-bottom pl-3 mb-3"
              [collapse]="collapseTimeStamps"
              [isAnimated]="true"
            >
              <div class="well well-lg">
                <div class="row">
                  <div class="col-12"><b>Created On:</b></div>
                  <div class="col-12">{{ entity.created_on }}</div>
                </div>

                <div class="row">
                  <div class="col-12"><b>Modified On:</b></div>
                  <div class="col-12">{{ entity.modified_on }}</div>
                </div>
              </div>
            </div>

            <!-- ================ Collapse Links =============== -->
            <div class="row border-bottom">
              <div class="col-10">
                <B>Firmware Links</B>
              </div>
              <div class="col-2 text-right">
                <i
                  class="fa"
                  (click)="toggleLinks()"
                  [ngClass]="{
                    'fa-arrow-up': collapseLinks,
                    'fa-arrow-down': !collapseLinks
                  }"
                  [attr.aria-expanded]="!collapseLinks"
                  aria-controls="collapseLinks"
                ></i>
              </div>
            </div>

            <div
              id="collapseLinks"
              class="border-bottom pl-3 mb-3"
              [collapse]="collapseLinks"
              [isAnimated]="true"
            >
              <div class="well well-lg">
                <div class="row">
                  <div class="col-12"><b>Firmware:</b></div>
                  <div class="col-12">
                    <a
                      (click)="showFirmware($event, releaseModal)"
                      href="/portal/firmware/definitions/{{ entity.firmware }}"
                      >({{ entity.firmware_entity?.identifier }} )
                      {{ entity.firmware_entity?.firmwareVersion }}@{{
                        entity.firmware_entity?.series
                      }}</a
                    >
                  </div>
                </div>
                <div class="row">
                  <div class="col-12"><b>Rollback:</b></div>
                  <div class="col-12">
                    <a
                      (click)="showRollback($event, releaseModal)"
                      href="/portal/firmware/definitions/{{ entity.rollback }}"
                      >({{ entity.rollback_entity?.identifier }} )
                      {{ entity.rollback_entity?.firmwareVersion }}@{{
                        entity.rollback_entity?.series
                      }}</a
                    >
                  </div>
                </div>

                <div class="row">
                  <div class="col-12"><b>Approval:</b></div>
                  <div class="col-12">
                    <a
                      *ngIf="entity.approval"
                      (click)="showApproval($event, releaseModal)"
                      href="/portal/firmware/approvals/{{ entity.approval }}"
                      >{{ entity.approval }}</a
                    >
                    <div
                      class="btn btn-sm btn-secondary"
                      *ngIf="!entity.approval"
                      (click)="approveRelease($event, releaseModal)"
                    >
                      (Approve Release)
                    </div>
                  </div>
                </div>

                <div
                  class="row"
                  *ngIf="
                    entity.roll_out_report?.release_status
                      ?.managed_firmware_links
                  "
                >
                  <div class="col-12"><b>Links:</b></div>
                  <div
                    class="col-12"
                    *ngFor="
                      let link of entity.roll_out_report?.release_status
                        ?.managed_firmware_links
                    "
                  >
                    <a
                      (click)="showLink($event, link, releaseModal)"
                      href="/portal/firmware/links/{{ link }}"
                      >{{ link }}</a
                    >
                  </div>
                </div>
              </div>
            </div>

            <!-- ================ Collapse Targets =============== -->
            <div class="row border-bottom">
              <div class="col-10">
                <B>Targets</B>
              </div>
              <div class="col-2 text-right">
                <i
                  class="fa"
                  (click)="toggleTargets()"
                  [ngClass]="{
                    'fa-arrow-up': collapseTargets,
                    'fa-arrow-down': !collapseTargets
                  }"
                  [attr.aria-expanded]="!collapseTargets"
                  aria-controls="collapseTargets"
                ></i>
              </div>
            </div>

            <div
              id="collapseTargets"
              class="pl-3 mb-3"
              [collapse]="collapseTargets"
              [isAnimated]="true"
            >
              <div class="well well-lg">
                <div class="row" *ngFor="let target of entity.targets">
                  <div class="col text-center">
                    <firmware-target-widget
                      [entity]="target"
                      [layout]="'inline'"
                      [options]="options"
                    ></firmware-target-widget>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>

      <!-- ========================================================== -->
      <!--                        Layout                              -->
      <!-- ========================================================== -->

      <ng-container [ngSwitch]="layout">
        <!-- ============================================ -->
        <!--              SideBar                         -->
        <!-- ============================================ -->
        <div class="firmware-release-widget" *ngSwitchCase="'sidebar'">
          <div
            class="card shadow"
            [ngClass]="{
              'card-accent-success': entity.status == 'complete',
              'card-accent-dark': entity.status == 'rejected'
            }"
          >
            <ng-container
              *ngTemplateOutlet="fullHeader; context: { close: true }"
            ></ng-container>
            <div class="card-body">
              <ng-container
                *ngTemplateOutlet="
                  entity.identifier == 'new' ? editContent : extendedContent
                "
              ></ng-container>
            </div>
          </div>
        </div>

        <!-- ============================================ -->
        <!--              Full                            -->
        <!-- ============================================ -->
        <div class="firmware-release-widget" *ngSwitchCase="'full'">
          <div
            class="card shadow"
            [ngClass]="{
              'card-accent-success': entity.status == 'complete',
              'card-accent-dark': entity.status == 'rejected'
            }"
          >
            <ng-container *ngTemplateOutlet="fullHeader"></ng-container>
            <div class="card-body">
              <ng-container
                *ngTemplateOutlet="
                  entity.identifier == 'new' ? editContent : extendedContent
                "
              ></ng-container>
            </div>
          </div>
        </div>

        <!-- ============================================ -->
        <!--              Shadowbox                       -->
        <!-- ============================================ -->
        <div class="firmware-release-widget" *ngSwitchCase="'shadowbox'">
          <ng-container
            *ngTemplateOutlet="
              entity.identifier == 'new' ? editContent : extendedContent
            "
          ></ng-container>
        </div>

        <!-- ============================================ -->
        <!--              Default Case                    -->
        <!-- ============================================ -->
        <div class="firmware-release-widget" *ngSwitchDefault>
          <div
            class="card shadow"
            [ngClass]="{
              'card-accent-success': entity.status == 'complete',
              'card-accent-dark': entity.status == 'rejected'
            }"
          >
            <ng-container *ngTemplateOutlet="thumbHeader"></ng-container>
            <div class="card-body">
              <ng-container *ngTemplateOutlet="compactContent"></ng-container>
            </div>
          </div>
        </div>
        <!-- End Switch Default Case -->
      </ng-container>
    </ng-container>

    <!--                                                        -->
    <!--                    Pop-Up Menu                         -->
    <!--                                                        -->
    <ng-template #releaseModal>
      <modal-widget
        *ngIf="currentModal"
        (widgetEvent)="eventHandler($event, 'pop-up', releaseModal)"
        [entity]="currentModal"
        [layout]="'shadowbox'"
        [options]="{}"
      ></modal-widget>
    </ng-template>
  `,
})
export class FirmwareReleaseWidgetComponent implements OnInit {
  //-----------------------------------------------
  // Input Section
  //-----------------------------------------------
  @Input() entity: FirmwareReleaseEntity | null = null;
  @Input() options: any = null;
  @Input() layout = "standard";
  objectKeys = Object.keys;
  //-----------------------------------------------
  // Output Section
  //-----------------------------------------------
  @Output() widgetEvent = new EventEmitter<FirmwareWidgetEventStruct>();
  forwardEvent(e, widgetEvent: FirmwareWidgetEventStruct) {
    e.preventDefault();
    this.widgetEvent.emit(widgetEvent);
  }

  //-----------------------------------------------
  // Properties
  //-----------------------------------------------
  public collapseNotes = false;
  public collapseTimeStamps = true;
  public collapseLinks = false;
  public collapseTargets = true;
  public collapseGraph = false;
  public collapseExtendedDetails = true;
  public modalRef: BsModalRef;
  public currentModal: any = null;
  FirmwareWidgetEnum = FirmwareWidgetEnum;

  // Pie
  public releaseRollOutPieLabels: string[] = ["Querying"];
  public releaseRollOutPieData: number[] = [1];

  public groupStageChartOptions = {
    scales: {
      xAxes: [
        {
          stacked: true,
        },
      ],
      yAxes: [
        {
          stacked: true,
        },
      ],
    },
  };
  public groupStageChartLabels: string[] = ["Querying"];
  public groupStageChartData: any[] = [{ data: [0], label: "Group1" }];

  public targetFirmware: any = null;
  public rollbackFirmware: any = null;
  public releaseTargetSet: any = null;

  public firmware_cache: any = {};
  public approval_cache: any = {};
  public link_cache: any = {};

  //-----------------------------------------------
  // Constructor
  //-----------------------------------------------
  constructor(
    public toasterService: ToasterService,
    public modalService: BsModalService,
    public authService: AuthService,
    public firmwareDefinitionRepo: FirmwareDefinitionRepo,
    public approvalRepo: FirmwareApprovalRepo,
    public firmwareLinkRepo: FirmwareLinkV2Repo
  ) {}

  showModal(current, modal, clickOut = false, customClass = "") {
    this.currentModal = current;
    if (!clickOut) {
      let config: ModalOptions = {
        backdrop: "static",
        keyboard: false,
        class: customClass,
      };
      this.currentModal.modalRef = this.modalService.show(modal, config);
    } else {
      let config: ModalOptions = {
        class: customClass,
      };
      this.currentModal.modalRef = this.modalService.show(modal, config);
    }
  }

  //-----------------------------------------------
  //
  //-----------------------------------------------

  eventHandler(event, field, modal) {
    if (event instanceof FirmwareLogicalWidgetEventStruct) {
      if (
        event.event_firmware_logical_widget_type ==
        FirmwareLogicalWidgetEnum.FIRMWARE_LOGICAL_WIDGET__DEFINITION
      ) {
        if (event.event_type == "firmware_selection_made") {
          if (field == "firmware") {
            this.entity.firmware = new EntityReference(
              this.entity.client,
              this.entity.auth,
              event.event_body.sref
            );

            this.entity.meta.pending = true;
            if (
              this.entity.validation.validated &&
              !this.entity.validation.valid
            ) {
              this.entity.validate();
            }

            return;
          }
          if (field == "rollback") {
            this.entity.rollback = new EntityReference(
              this.entity.client,
              this.entity.auth,
              event.event_body.sref
            );

            this.entity.meta.pending = true;
            if (
              this.entity.validation.validated &&
              !this.entity.validation.valid
            ) {
              this.entity.validate();
            }

            return;
          }
        }
      }

      if (
        event.event_firmware_logical_widget_type ==
        FirmwareLogicalWidgetEnum.FIRMWARE_LOGICAL_WIDGET__TARGET_SET
      ) {
        if (event.event_type == "is_valid") {
          this.entity.validate();
          return;
        }
      }
    }

    if (event.event_type == "show_firmware" && field == "firmware") {
      this.showFirmware(null, modal);
      return;
    }
    if (event.event_type == "show_firmware" && field == "rollback") {
      this.showRollback(null, modal);
      return;
    }
    console.log("Release Unhandled Event", field, event);
  }

  toggleNotes() {
    this.collapseNotes = !this.collapseNotes;
    this.collapseExtendedDetails = true;
    setTimeout(() => {
      this.collapseExtendedDetails = false;
    }, 500);
  }
  toggleTimeStamps() {
    this.collapseTimeStamps = !this.collapseTimeStamps;
    this.collapseExtendedDetails = true;
    setTimeout(() => {
      this.collapseExtendedDetails = false;
    }, 500);
  }
  toggleLinks() {
    this.collapseLinks = !this.collapseLinks;
    this.collapseExtendedDetails = true;
    setTimeout(() => {
      this.collapseExtendedDetails = false;
    }, 500);
  }
  toggleTargets() {
    this.collapseTargets = !this.collapseTargets;
    this.collapseExtendedDetails = true;
    setTimeout(() => {
      this.collapseExtendedDetails = false;
    }, 500);
  }
  toggleGraph() {
    this.collapseGraph = !this.collapseGraph;
  }
  toggleExtended() {
    this.collapseExtendedDetails = !this.collapseExtendedDetails;
  }

  emitEvent(type, body: any = null) {
    let event = new FirmwareWidgetEventStruct();
    event.event_type = type;
    event.event_widget_type = WidgetEnum.EMBED_WIDGET__FIRMWARE;
    event.event_firmware_widget_type =
      FirmwareWidgetEnum.FIRMWARE_WIDGET__RELEASE;
    event.event_body = body;
    this.widgetEvent.emit(event);
  }

  showFirmware(e, modal) {
    if (e) e.preventDefault();
    let firmware = this.entity.firmware.toString();
    if (this.firmware_cache[firmware]) {
      let entity = this.firmware_cache[firmware];
      let dialog = new ModalWidget(
        `Target Firmware: - ${entity.firmwareVersion}@${entity.series}`,
        ModalWidgetEnum.MODAL_WIDGET__SHOW,
        entity,
        { edit: false },
        "shadowbox"
      );
      this.showModal(dialog, modal, true);
    } else {
      let identifier = this.firmwareDefinitionRepo.srefToIdentifier(
        "firmware",
        firmware,
        false
      );
      this.firmwareDefinitionRepo
        .getEntityPromise(identifier)
        .then((entity: FirmwareDefinitionEntity) => {
          this.firmware_cache[firmware] = entity;
          let dialog = new ModalWidget(
            `Target Firmware: - ${entity.firmwareVersion}@${entity.series}`,
            ModalWidgetEnum.MODAL_WIDGET__SHOW,
            entity,
            { edit: false },
            "shadowbox"
          );
          this.showModal(dialog, modal, true);
        });
    }
  }

  showRollback(e, modal) {
    if (e) e.preventDefault();
    let firmware = this.entity.rollback.toString();
    if (this.firmware_cache[firmware]) {
      let entity = this.firmware_cache[firmware];
      let dialog = new ModalWidget(
        `Rollback Firmware: - ${entity.firmwareVersion}@${entity.series}`,
        ModalWidgetEnum.MODAL_WIDGET__SHOW,
        entity,
        { edit: false },
        "shadowbox"
      );
      this.showModal(dialog, modal, true);
    } else {
      let identifier = this.firmwareDefinitionRepo.srefToIdentifier(
        "firmware",
        firmware,
        false
      );
      this.firmwareDefinitionRepo
        .getEntityPromise(identifier)
        .then((entity: FirmwareDefinitionEntity) => {
          this.firmware_cache[firmware] = entity;
          let dialog = new ModalWidget(
            `Rollback Firmware: - ${entity.firmwareVersion}@${entity.series}`,
            ModalWidgetEnum.MODAL_WIDGET__SHOW,
            entity,
            { edit: false },
            "shadowbox"
          );
          this.showModal(dialog, modal, true);
        });
    }

    //this.emitEvent('rollback_modal', {'sref': this.entity.rollback});
  }

  showApproval(e, modal) {
    if (e) e.preventDefault();
    let approval = this.entity.approval.toString();
    if (this.approval_cache[approval]) {
      let entity = this.approval_cache[approval];
      let dialog = new ModalWidget(
        `Release Approval - ${approval}`,
        ModalWidgetEnum.MODAL_WIDGET__SHOW,
        entity,
        { edit: false },
        "shadowbox"
      );
      this.showModal(dialog, modal, true);
    } else {
      this.approvalRepo
        .getEntityPromise(approval)
        .then((entity: FirmwareApprovalEntity) => {
          this.approval_cache[approval] = entity;
          let dialog = new ModalWidget(
            `Release Approval - ${approval}`,
            ModalWidgetEnum.MODAL_WIDGET__SHOW,
            entity,
            { edit: false },
            "shadowbox"
          );
          this.showModal(dialog, modal, true, "lg-modal");
        });
    }

    // this.emitEvent('approval_modal', {'sref': this.entity.approval});
  }

  approveRelease(e, modal) {
    if (e) e.preventDefault();

    let approver = this.authService.user.sref();
    console.log("user", this.authService.user);
    let approved_on = new Date();
    let reference = this.entity.sref();
    let entity = this.approvalRepo.entity({
      identifier: "new",
      approver: approver,
      approved_on: approved_on,
      reference: reference,
    });

    let create_approval = {
      msg: "Approve!",
      cb: (self, event, modalRef, toaster) => {
        let confirmAction = "Approve Release";
        toaster.pop("info", confirmAction, "Saving Changes.");
        if (self.subject) {
          let valid = self.subject.validate();
          if (!valid.valid) {
            toaster.pop("error", confirmAction, valid.error);
          } else {
            self.subject
              .approveRelease()
              .then(() => {
                toaster.pop("success", confirmAction, "Record has been saved");
                modalRef.hide();
              })
              .catch((e) => {
                toaster.pop("error", confirmAction, "Call Failed.");
              });
          }
        } else {
          toaster.pop("warning", confirmAction, "Null Subject?! - unexpected");
        }
      },
    };

    let dialog = new ModalWidget(
      `Add Release Approval`,
      ModalWidgetEnum.MODAL_WIDGET__CREATE,
      entity,
      { create_approval: true, edit: true },
      "shadowbox",
      { confirm: create_approval }
    );
    this.showModal(dialog, modal, false);

    //this.emitEvent('approve_release', {'sref': this.entity.identifier});
  }

  showLink(e, link, modal) {
    if (e) e.preventDefault();
    if (this.link_cache[link]) {
      let entity = this.link_cache[link];
      let dialog = new ModalWidget(
        `Firmware Association: ${link}`,
        ModalWidgetEnum.MODAL_WIDGET__SHOW,
        entity,
        { edit: false },
        "shadowbox"
      );
      this.showModal(dialog, modal, true);
    } else {
      let identifier = this.firmwareLinkRepo.srefToIdentifier(
        "fw-link-v2",
        link,
        false
      );
      this.firmwareLinkRepo.getEntityPromise(identifier).then((entity) => {
        this.link_cache[link] = entity;
        let dialog = new ModalWidget(
          `Firmware Association: ${link}`,
          ModalWidgetEnum.MODAL_WIDGET__SHOW,
          entity,
          { edit: false },
          "shadowbox"
        );
        this.showModal(dialog, modal, true);
      });
    }

    e.preventDefault();
    //this.emitEvent('link_modal', {'sref': link});
  }

  public closeSidebar(e) {
    if (e) e.preventDefault();
    this.emitEvent("close_sidebar");
  }

  public openSidebar(e) {
    if (e) e.preventDefault();
    this.emitEvent("open_sidebar", { entity: this.entity });
  }

  public editRelease(e) {
    if (e) e.preventDefault();
    this.emitEvent("edit_release", { entity: this.entity });
  }

  //================================================
  // events
  //================================================
  public chartClicked(chart: any, e: any): void {
    console.log(e);
  }

  public chartHovered(chart: any, e: any): void {
    console.log(e);
  }

  recordChange(e, field) {
    this.entity.meta.pending = true;
    if (this.entity.validation.validated && !this.entity.validation.valid) {
      this.entity.validate();
    }
  }

  ngOnInit() {
    this.releaseTargetSet = new FirmwareTargetSetLogicalWidget();
    this.releaseTargetSet.subject = this.entity.targets;
    console.log(this.entity.targets);

    this.targetFirmware = new FirmwareDefinitionLogicalWidget();
    this.targetFirmware.subject = this.entity.firmware
      ? this.entity.firmware.toString()
      : null;

    this.rollbackFirmware = new FirmwareDefinitionLogicalWidget();
    this.rollbackFirmware.subject = this.entity.rollback
      ? this.entity.rollback.toString()
      : null;

    if (this.entity.identifier != "new") {
      this.entity.getRollOutReport().then((report: any) => {
        if (report["roll_up"]) {
          this.releaseRollOutPieLabels = [
            "Pending",
            "Baseline",
            "Revert",
            "First Install",
            "First Rollback",
            "Second Install",
            "Second Rollback",
            "Final Install",
            "Flagged",
          ];
          this.releaseRollOutPieData = [
            report.roll_up.pending,
            report.roll_up.baseline,
            report.roll_up.revert,
            report.roll_up.first_install,
            report.roll_up.first_rollback,
            report.roll_up.second_install,
            report.roll_up.second_rollback,
            report.roll_up.final_install,
            report.roll_up.error + report.roll_up.flagged,
          ];
        } else {
          this.releaseRollOutPieLabels = ["Error"];
          this.releaseRollOutPieData = [1];
        }

        if (report["roll_up_detailed"]) {
          this.groupStageChartLabels = [
            "Baseline",
            "1st Install",
            "1st RollBack",
            "2nd Install",
            "2nd Rollback",
            "Final",
          ];
          let temp = {};
          for (let group in report.roll_up_detailed) {
            for (let phase in report.roll_up_detailed[group]) {
              for (let step in report.roll_up_detailed[group][phase]) {
                if (temp[step] == undefined || temp[step] == null)
                  temp[step] = {};
                if (temp[step][phase] == undefined || temp[step][phase] == null)
                  temp[step][phase] = 0;
                temp[step][phase] +=
                  report.roll_up_detailed[group][phase][step];
              }
            }
          }
          let final = [];
          for (let step in temp) {
            let baseline = temp[step]["baseline"] || 0;
            let first_install = temp[step]["first_install"] || 0;
            let first_rollback = temp[step]["first_rollback"] || 0;
            let second_install = temp[step]["second_install"] || 0;
            let second_rollback = temp[step]["second_rollback"] || 0;
            let final_install = temp[step]["final_install"] || 0;
            let entry = {
              data: [
                baseline,
                first_install,
                first_rollback,
                second_install,
                second_rollback,
                final_install,
              ],
              label: step,
            };
            final.push(entry);
          }
          console.log("Final Chart", final);
          this.groupStageChartData = final;
        } else {
          this.groupStageChartLabels = ["Error"];
          this.groupStageChartData = [{ data: [0], label: "Group1" }];
        }
      });
    }
  }

  groupActive(group) {
    return (
      (this.entity.roll_out_report &&
        this.entity.roll_out_report.release_status &&
        this.entity.roll_out_report.release_status.active_group &&
        this.entity.roll_out_report.release_status.active_group >= group) ||
      false
    );
  }
  range(i) {
    return Array(i);
  }
}
