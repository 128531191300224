<page-header [section]="'device-fields'">
  <page-title>Device Fields</page-title>
  <page-blurb
    >View detailed information of available device type definitions.</page-blurb
  >
</page-header>

<spinner *ngIf="!entries"></spinner>

<div *ngIf="entries">
  <widget [entity]="entries" [layout]="layout" [options]="options"></widget>
</div>
