import { HttpClient } from "@angular/common/http";
import { LacrosseEntity } from "../lacrosse.entity";
import { FirebaseAuthService } from "../../noizu/services/firebase-auth.service";
import { DeviceDefinitionCategory } from "../../structs/device/definition/category";
import { DeviceDefinitionFieldEntry } from "../../structs/device/definition/field-entry";
import { WidgetEnum } from "../../enums";

export enum AttributeFieldType {
  BooleanField = 1,
  ManufactureField,
  NumericField,
}

export enum StreamingPolicyFieldType {
  NumericField,
}

export enum GatewayDiagnosticFieldType {
  NumericField,
}

export class DeviceDefinitionEntity extends LacrosseEntity {
  public _kind = "sensor-types";
  public _sref_module = "device-type";

  public name: string = null;
  public handle: string = null;
  public internalName: string = null;
  public category: DeviceDefinitionCategory = null;
  public description: string = null;
  public defaultSeries: string = null;
  public default_device_name: string = null;
  public image: string = null;
  public showField: boolean = false;
  public fields: any = null;
  public fieldEntries: Array<DeviceDefinitionFieldEntry> | null = null;
  public fieldEntriesMap: any = {};
  public attributes: Object = null;
  public _attributeList: any = null;
  public streamingPolicy: Object = null;
  public _policyList: any = null;
  public _pending = false;

  get pending() {
    if (this._pending) return true;
    if (this.fieldEntries) {
      for (const value of this.fieldEntries) {
        if (value.pending) return true;
      }
    }
    return false;
  }

  set pending(v) {
    this._pending = v;
  }

  private fieldTypes = {
    factory: AttributeFieldType.ManufactureField,
    display: AttributeFieldType.BooleanField,
    "data-stream": AttributeFieldType.BooleanField,
    "device-glyph": AttributeFieldType.NumericField,
  };

  constructor(client: HttpClient, auth: FirebaseAuthService, json) {
    super(client, auth, json);
    let fe = json["fieldEntries"] || json["field_entries"];
    let fieldEntries = null;
    let fieldEntriesMap = {};
    if (fe) {
      fieldEntries = [];
      for (let i = 0; i < fe.length; i++) {
        if (fe[i]) {
          let e = new DeviceDefinitionFieldEntry(client, auth, fe[i]);
          fieldEntries.push(e);
          fieldEntriesMap[e.identifier] = e;
        }
      }
    }
    this.fieldEntries = fieldEntries;
    this.fieldEntriesMap = fieldEntriesMap;
    this.fields = json["fields"];
    this.setFields(json);
    this.strip_from_json["_pending"] = 1;
    this.strip_from_json["_attributeList"] = 1;
    this.strip_from_json["_policyList"] = 1;
    this.strip_from_json["pending"] = 1;
    this.strip_from_json["attributeList"] = 1;
    this.strip_from_json["fieldEntriesMap"] = 1;
    //this.strip_from_json["fieldEntries"] = 1;

    //Defined in Lacrosse.entity.ts, critical part of API URL
    this._singular = "sensor-type";
    this.raw = null;
  } // end constructor

  public attributeType(key) {
    return this.fieldTypes[key] || AttributeFieldType.NumericField;
  }

  public streamingPolicyType(key) {
    return this.fieldTypes[key] || StreamingPolicyFieldType.NumericField;
  }

  // Sets the fields, used above, and to reset the values when canceling an edit
  setFields(json) {
    this.handle = json["handle"];
    this.name = json["name"];
    this.internalName = json["internalName"] || json["internal_name"];
    this.defaultSeries = json["defaultSeries"];
    this.category = json["category"]
      ? new DeviceDefinitionCategory(json["category"])
      : new DeviceDefinitionCategory("1");
    this.description = json["description"];
    this.default_device_name =
      json["defaultDeviceName"] || json["default_device_name"];
    this.image = json["image"];
    this.attributes = {};
    this.streamingPolicy = {};
    if (json["attributes"]) {
      for (const key in json["attributes"]) {
        let value = json["attributes"][key];
        this.attributes[key] = parseInt(value);
      }
    }
    if (json["policy"]) {
      for (const key in json["policy"]) {
        let value = json["policy"][key];
        this.streamingPolicy[key] = parseInt(value);
      }
    }
  }

  widget() {
    return "embed-device-type";
  }
  widget_type() {
    return WidgetEnum.EMBED_WIDGET__DEVICE_TYPE;
  }

  set attributeList(v) {
    this._attributeList = v;
  }

  get attributeList() {
    if (this._attributeList) return this._attributeList;
    this._attributeList = [];
    if (this.attributes) {
      for (const key in this.attributes) {
        let value = this.attributes[key];
        this._attributeList.push({
          key: key,
          originalKey: key,
          value: value,
          type: this.attributeType(key),
        });
      }
      this._attributeList = this._attributeList.sort((a, b) => a.key < b.key);
    }
    return this._attributeList;
  }

  updateAttribute(attribute) {
    this.pending = true;

    if (attribute.originalKey != attribute.key) {
      delete this.attributes[attribute.originalKey];
    }

    this.attributes[attribute.key] = attribute.value;
    this.attributeList = null;
    console.log("Update ", attribute);
  }

  addAttribute(attribute) {
    this.pending = true;
    this.attributes[attribute.key] = attribute.value;
    this.attributeList = null;
    console.log("Add ", attribute);
  }

  dropAttribute(attribute) {
    this.pending = true;
    delete this.attributes[attribute.key];
    this.attributeList = null;
    console.log("drop", attribute);
  }

  set policyList(v) {
    this._policyList = v;
  }

  get policyList() {
    if (this._policyList) return this._policyList;
    this._policyList = [];
    if (this.streamingPolicy) {
      for (const key in this.streamingPolicy) {
        let value = this.streamingPolicy[key];
        this._policyList.push({
          key: key,
          originalKey: key,
          value: value,
          type: this.streamingPolicyType(key),
        });
      }
      this._policyList = this._policyList.sort((a, b) => a.key < b.key);
    }
    return this._policyList;
  }

  updatePolicy(policy) {
    this.pending = true;

    if (policy.originalKey != policy.key) {
      delete this.streamingPolicy[policy.originalKey];
    }

    this.streamingPolicy[policy.key] = policy.value;
    this._policyList = null;
    console.log("Update ", policy);
  }

  addPolicy(policy) {
    this.pending = true;
    this.streamingPolicy[policy.key] = policy.value;
    this._policyList = null;
    console.log("Add ", policy);
  }

  dropPolicy(policy) {
    this.pending = true;
    delete this.streamingPolicy[policy.key];
    this._policyList = null;
    console.log("drop", policy);
  }

  updateStreamingPolicy(attribute) {
    this.pending = true;

    if (attribute.originalKey != attribute.key) {
      delete this.streamingPolicy[attribute.originalKey];
    }

    this.streamingPolicy[attribute.key] = attribute.value;
    this.policyList = null;
    console.log("Update ", attribute);
  }

  addStreamingPolicy(attribute) {
    this.pending = true;
    this.streamingPolicy[attribute.key] = attribute.value;
    this.policyList = null;
    console.log("Add ", attribute);
  }

  dropStreamingPolicy(attribute) {
    this.pending = true;
    delete this.streamingPolicy[attribute.key];
    this.policyList = null;
    console.log("drop", attribute);
  }

  filter(filter: string) {
    if (filter) {
      //filter = filter.toLowerCase();
      if (this.meta["filter"] == filter) return this.meta["filter_result"];

      // Prep Lower Case Data
      if (!this.meta["filter_set"]) {
        this.meta["filter_set"] = {};
        this.meta["filter_set"]["name"] = this.name || "";
        this.meta["filter_set"]["internal"] = this.name || "";
        this.meta["filter_set"]["handle"] = this.internalName || "";
        this.meta["filter_set"]["identifier"] = `${this.identifier || ""}`;
        this.meta["filter_set"]["description"] = `${this.description || ""}`;
      }

      this.meta["filter"] = filter;
      this.meta["filter_result"] = false;
      if (this.meta.filter_set.name.includes(filter))
        this.meta["filter_result"] = true;
      else if (this.meta.filter_set.description.includes(filter))
        this.meta["filter_result"] = true;
      else if (this.meta.filter_set.identifier.includes(filter))
        this.meta["filter_result"] = true;
      else if (this.meta.filter_set.name.includes(filter))
        this.meta["filter_result"] = true;
      else if (this.meta.filter_set.internal.includes(filter))
        this.meta["filter_result"] = true;
      else if (this.meta.filter_set.handle.includes(filter))
        this.meta["filter_result"] = true;
      return this.meta.filter_result;
    } else {
      return true;
    }
  }
} // end Package
