import { Component, Input, Output, EventEmitter } from "@angular/core";
import { AlertConstraintStatus } from "../../../entities/alert";
import { WidgetBase } from "../widget-base";
import { AlertWidgetEventStruct } from "./alert-widget-event.struct";

@Component({
  selector: "alert-constraint-status-widget",
  template: `
    <div
      class="alert-constraint-status-widget card"
      [ngClass]="{ 'mb-0': options?.nested }"
    >
      <div class="card-header" [ngClass]="{ 'p-2 m-0': options?.nested }">
        <div class="row">
          <div class="col">
            <b class="text-left">Constraint: {{ entity?.handle }}</b>
          </div>
          <div class="col-2 text-right p-0 text-middle">
            <toggle-widget [options]="expand_settings"></toggle-widget>
          </div>
        </div>
      </div>
      <div class="card-body" [ngClass]="{ 'd-none': !expand_settings.toggle }">
        <div class="row">
          <div class="col-2">last_reported</div>
          <div class="col">
            {{ entity.last_reported_value?.value }},{{
              entity.last_reported_value?.unit?.name ||
                entity.last_reported_value?.unit?.enum
            }}
          </div>
        </div>

        <div class="row">
          <div class="col-2">constraint_met</div>
          <div class="col">
            <span *ngIf="!entity.constraint_met">false</span>
            <span *ngIf="entity.constraint_met">{{
              entity.constraint_met | json
            }}</span>
          </div>
        </div>

        <div class="row mb-4">
          <div class="col-2">last_update</div>
          <div class="col">
            {{ entity.last_update | json }}
          </div>
        </div>

        <hr />

        <div class="row">
          <div class="col">
            <alert-constraint-trigger-state-widget
              [entity]="entity.trigger_state"
              [options]="{ id: entity.handle }"
              [layout]="layout"
            ></alert-constraint-trigger-state-widget>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <alert-trigger-event-set-widget
              [entity]="entity.trigger_events"
              [options]="{ id: entity.handle }"
              [layout]="layout"
            ></alert-trigger-event-set-widget>
          </div>
        </div>
      </div>
    </div>
  `,
})
export class AlertConstraintStatusWidgetComponent extends WidgetBase {
  @Input() entity: AlertConstraintStatus = null;
  @Input() options: any = null;
  @Input() layout: string = null;
  @Output() widgetEvent = new EventEmitter<AlertWidgetEventStruct>();
  forwardEvent(e, widgetEvent: AlertWidgetEventStruct) {
    e.preventDefault();
    this.widgetEvent.emit(widgetEvent);
  }
  constructor() {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    if (!("nested" in this.options)) {
      this.options.nested = false;
    }
  }
}
