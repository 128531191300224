import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { WidgetBase } from "../../widget-base";
import { ExtendedUserSubscriptionDetailsEntity } from "../../../../entities";
import { UserWidgetEventStruct } from "../user-widget-event.struct";

@Component({
  selector: "extended-user-subscription-details",
  template: `
    <div class="row">
      <div class="col-2">
        <widget
          *ngIf="entity?.effective"
          [entity]="entity.effective"
          [options]="options"
          [layout]="layout"
        ></widget>
      </div>
      <div class="col-10">
        <widget
          *ngIf="entity?.subscriptions"
          [entity]="entity.subscriptions"
          [options]="options"
          [layout]="layout"
        ></widget>
      </div>
    </div>
  `,
})
export class UserExtendedSubscriptionDetailsWidgetComponent
  extends WidgetBase
  implements OnInit
{
  public editMode = false;
  @Input() layout: string = null;
  @Input() entity: ExtendedUserSubscriptionDetailsEntity;
  @Input() options: any;
  @Output() widgetEvent = new EventEmitter<UserWidgetEventStruct>();
  forwardEvent(e, widgetEvent: UserWidgetEventStruct) {
    e.preventDefault();
    this.widgetEvent.emit(widgetEvent);
  }

  constructor() {
    super();
  }

  ngOnInit(): void {}
}
