import {
  ChangeDetectorRef,
  Component,
  NgZone,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ToasterService } from "angular2-toaster";
import { DeviceDefinitionRepo } from "../../../repos/device/definition.repo";
import { DeviceDefinitionEntity } from "../../../entities/device/definition.entity";
import { DeviceEntity } from "../../../entities/device.entity";
import { DeviceRepo } from "../../../repos/device.repo";
import { BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import { BsModalRef } from "ngx-bootstrap/modal";
import { AuthService } from "../../../services/auth.service";
import { SimulatorWidget } from "../../../entities/device/simulator-widget";
import { DragulaService } from "ng2-dragula";
import { Subscription } from "rxjs";
import {
  BatchLogicalWidget,
  DeviceFeedLogicalWidget,
  DeviceReadingLogicalWidget,
  ModalWidget,
  PinEntity,
  PinGroupEntity,
} from "../../../entities";
import { PinGroupRepo, PinRepo } from "../../../repos";
import { UserPreferencesService } from "../../../services/user-preferences.service";
import { ModalWidgetEnum } from "../../../enums";
import { environment } from "../../../../environments/environment";
import { WidgetEventStruct } from "../../../widgets";

@Component({
  selector: "device-show",
  templateUrl: "./show.component.html",
  styles: [".vcenter {display: flex;align-items: center;}"],
})
export class DeviceShowComponent implements OnInit, OnDestroy {
  public identifier: any;
  public stage = environment.stage;
  public clone: DeviceEntity;
  public device: DeviceEntity;
  public batchSelect: BatchLogicalWidget;
  public subscription: any;
  public currentModal: any;
  public modalRef: BsModalRef;
  public backUserID;
  public notification = { showLoading: false, message: "", iserror: false };
  public logSize = 10;
  public showChart = false;
  public chartLoading = false;
  public claimable: boolean = null;
  public dsErr = { show: false, msg: "" };
  public devError = false;
  public loadingErrors = {};
  public displayToggles = { chart: false, tblVal: "Average" };
  public pinGroups: Array<PinGroupEntity> = [];
  public pinned: PinEntity | null = null;
  public stageTester = false;
  public chartTimeValues = null;
  public timeValue = "";
  public dataValue = "";
  public durationValue = "1d";
  public units = "";
  public loadSimulator = false;
  public showResetRain = false;
  public rr = {
    startVal: 1,
    startInt: "d",
    startDefault: true,
    endVal: 1,
    endInt: "h",
    endDefault: true,
  };
  public lineChartData: Array<any> = [];
  public lineChartLabels: Array<any> = [];
  public lineChartOptions: any = {
    responsive: true,
    maintainAspectRatio: false,
  };
  public lineChartLegend = false;
  public lineChartType = "line";
  public availableReadings = {
    high: false,
    low: false,
    tally: false,
    spot: false,
    average: false,
  };
  public tableData;
  public cardsLoaded = false;
  public attributeExpand = false;
  public gatewayDiagExpand = false;
  public attributeEdit = false;
  public fieldsExpand = false;
  public streamingPolicyExpand = false;
  public streamingPolicyEdit = false;
  public simulator: SimulatorWidget = null;

  public device_feed: DeviceFeedLogicalWidget;
  public device_readings: DeviceReadingLogicalWidget;

  static AiToSeconds(i) {
    let r = 900;
    switch (i) {
      case "ai.minutes.5":
        r = 300;
        break;
      case "ai.minutes.30":
        r = 1800;
        break;
      case "ai.hours.1":
        r = 3600;
        break;
      case "ai.days.1":
        r = 86400;
        break;
    }
    return r;
  }

  static timeLetterToString(s) {
    switch (s) {
      case "h":
        return "hour";
      case "d":
        return "day";
      default:
        return "false";
    }
  }

  static compareRRVals(v) {
    let s = v.startVal;
    let e = v.endVal;
    if (v.startInt === "d") {
      s *= 24;
    }
    if (v.endInt === "d") {
      e *= 24;
    }
    return s > e;
  }

  public type_attribute_options = {
    expand: true,
    collapsible: true,
    optional_edit: false,
    attributes: { expand: true, edit: false, title: "Device Attributes" },
    edit: false,
  };
  public device_attribute_options = {
    expand: true,
    collapsible: false,
    attributes: { expand: true, edit: false, title: "Device Type Attributes" },
    edit: false,
  };
  public device_streaming_policy_options = {
    expand: true,
    collapsible: false,
    attributes: { expand: true, edit: false, title: "Device Steaming Policy" },
    edit: false,
  };
  public device_admin = false;

  public subs = new Subscription();

  public dragula_sections = {
    loaded: false,
    "left-drag-section": 3,
    "center-drag-section": 2,
    "right-drag-section": 1,
  };

  public putUpdateDeviceStreamingPolicy(clone) {
    this.toasterService.pop(
      "info",
      "Streaming Policy Update",
      `Streaming Policy update started.`
    );
    this.device.putUpdatePolicy(clone).then((result: any) => {
      if (result.success) {
        this.toasterService.pop(
          "info",
          "Streaming Policy Updated",
          `Streaming Policy updated successfully.`
        );
      } else {
        this.toasterService.pop(
          "info",
          "Streaming Policy updation failed",
          result.error.details.error.msg
        );
      }
    });
    this.updateDeviceStreamingPolicy();
  }

  public feed_version = "1.0";
  public feed_version_toggle = false;

  constructor(
    public changeDetector: ChangeDetectorRef,
    public router: Router,
    public deviceRepo: DeviceRepo,
    public pinRepo: PinRepo,
    public pinGroupRepo: PinGroupRepo,
    public zone: NgZone,
    public activatedRoute: ActivatedRoute,
    public modalService: BsModalService,
    public definitionRepo: DeviceDefinitionRepo,
    public toasterService: ToasterService,
    public authService: AuthService,
    private dragulaService: DragulaService,
    public preferences: UserPreferencesService
  ) {
    this.zone.run(() => {
      if (this.authService.loadedPromise) {
        this.authService.loadedPromise.then(() => {
          if (
            this.authService.userHasPermission("device_type_admin") ||
            this.authService.userHasPermission("firmware_admin")
          ) {
            this.device_admin = true;
            this.type_attribute_options.optional_edit = true;
          }

          if (
            this.authService.userHasPermission("device_type_admin") ||
            this.authService.userHasPermission("firmware_admin") ||
            this.authService.userHasPermission("stage_tester")
          ) {
            this.stageTester = true;
          }
        });
      }
    });
  }

  handleEvent(event) {
    if (event instanceof WidgetEventStruct) {
      console.log("HANDLE EVENT", event.event_type);
      switch (event.event_type) {
        case "batch_selection_made":
          if (
            event.event_body.sref &&
            event.event_body.sref.startsWith("ref.batch.")
          ) {
            this.device.batch = parseInt(event.event_body.sref.substring(10));
          } else {
            this.device.batch = null;
          }
          this.device.meta["batch_change"] = true;
          break;
      }
    }
  }

  loadVersion() {
    if (this.preferences) {
      this.preferences.session.readyPromise.then(() => {
        this.feed_version = this.preferences.featureVersion("feed-form", "2.0");
        this.feed_version_toggle = this.feed_version == "1.0" ? false : true;
      });
    }
  }

  changeBatch(event) {
    this.device.meta["batch_change"] = true;
  }

  toggleFeedVersion(event) {
    event.preventDefault();
    this.feed_version = this.feed_version_toggle ? "2.0" : "1.0";
    this.preferences.setFeatureVersion("feed-form", this.feed_version);
  }

  revertClone(e) {
    e.preventDefault();
    this.clone = this.device.deepCopy();
    this.attributeEdit = false;
    this.device_attribute_options.edit = this.attributeEdit;
    this.device_attribute_options.attributes.edit = this.attributeEdit;
  }

  revertCloneStreamingPolicy(e: any = {}) {
    e?.preventDefault;
    this.toasterService.pop(
      "info",
      "Streaming Policy Revert",
      `Streaming Policy revert started.`
    );
    this.clone = this.device.deepCopy();
    this.device_streaming_policy_options.edit = false;
    this.device_streaming_policy_options.attributes.edit =
      this.streamingPolicyEdit;
    this.toasterService.pop(
      "info",
      "Streaming Policy Revert",
      `Streaming Policy data reverted.`
    );
  }

  editStreamingPolicy(e) {
    e.preventDefault();
    if (this.device_admin) {
      this.streamingPolicyEdit = !this.streamingPolicyEdit;
      this.device_streaming_policy_options.edit = this.streamingPolicyEdit;
      this.device_streaming_policy_options.attributes.edit =
        this.streamingPolicyEdit;
    }
  }

  editAttribute(e) {
    e.preventDefault();
    if (this.device_admin) {
      this.attributeEdit = !this.attributeEdit;
      this.device_attribute_options.edit = this.attributeEdit;
      this.device_attribute_options.attributes.edit = this.attributeEdit;
    }
  }

  updateDevice(e) {
    e.preventDefault();
    this.clone.saveAttributes().then(() => {
      this.toasterService.pop(
        "info",
        "Attributes Updated",
        `Device Attributes Updated.`
      );
      this.zone.run(() => {
        setTimeout(() => {
          this.clone.pending = false;
          this.attributeEdit = !this.attributeEdit;
          this.device_attribute_options.edit = this.attributeEdit;
          this.device_attribute_options.attributes.edit = this.attributeEdit;
        }, 2000);
      });
    });
  }

  updateDeviceStreamingPolicy() {
    this.clone.pending = false;
    this.device_streaming_policy_options.edit = this.streamingPolicyEdit;
    this.device_streaming_policy_options.attributes.edit =
      this.streamingPolicyEdit;
  }

  refresh(event) {
    event.preventDefault();
    if (this.device) {
      this.device.status = null;
      this.getDeviceStatus();
    } else {
      this.loadDevice(this.identifier);
    }
  }

  back(event) {
    event.preventDefault();
    this.router.navigateByUrl(`/portal/devices`);
  }

  backUser(event) {
    event.preventDefault();
    this.router.navigateByUrl(`/portal/users/show/${this.backUserID}`);
  }

  toUser(event, id) {
    event.preventDefault();
    this.router.navigateByUrl(`/portal/users/show/${id}`);
  }

  hideMessage() {
    this.notification.message = "";
    this.notification.iserror = false;
  }

  reloadDevice() {
    this.devError = false;
    this.ngOnInit();
  }

  loadDeviceBySerial(serial) {
    return this.deviceRepo
      .getBySerialPromise(serial)
      .then((entity: DeviceEntity) => {
        this.identifier = entity.identifier;
        entity.getUsersPromise();
        entity.getPermissionEntriesPromise().then((res: any) => {
          this.claimable = res;
        });

        this.device = entity;
        this.simulator = new SimulatorWidget(this.device);
        this.device_feed = new DeviceFeedLogicalWidget(this.device);
        this.device_readings = new DeviceReadingLogicalWidget(this.device);

        this.batchSelect = new BatchLogicalWidget();
        if (this.device.batch) {
          this.batchSelect.subject = `ref.batch.${this.device.batch}`;
        } else {
          this.batchSelect.subject = null;
        }
        this.getDeviceStatus();
        this.definitionRepo
          .getEntityPromise(this.device.sensorTypeEntityId)
          .then((definition: DeviceDefinitionEntity) => {
            this.device.type = definition;
          });
        if (entity.fields.Temperature) {
          this.dataValue = "Temperature";
        } else if (entity.fields.Rain) {
          this.dataValue = "Rain";
        } else if (entity.fields.WindSpeed) {
          this.dataValue = "WindSpeed";
        } else {
          this.dataValue = null;
        }
      });
  }

  loadDevice(id) {
    return this.deviceRepo
      .getEntityPromise(id)
      .then((entity: DeviceEntity) => {
        // Load Users
        entity.getUsersPromise();

        // Get Permission Entries
        entity.getPermissionEntriesPromise().then((res: any) => {
          this.claimable = res;
        });

        this.device = entity;
        this.simulator = new SimulatorWidget(this.device);
        this.device_feed = new DeviceFeedLogicalWidget(this.device);
        this.device_readings = new DeviceReadingLogicalWidget(this.device);

        this.batchSelect = new BatchLogicalWidget();
        if (this.device.batch) {
          this.batchSelect.subject = `ref.batch.${this.device.batch}`;
        } else {
          this.batchSelect.subject = null;
        }

        // Get Device Status
        this.getDeviceStatus();

        // Get Sensor Definition
        this.definitionRepo
          .getEntityPromise(this.device.sensorTypeEntityId)
          .then((definition: DeviceDefinitionEntity) => {
            this.device.type = definition;
            if (this.device.type.attributes["display"] === 1) {
              this.device.meta["is_gateway"] = true;
              if (!this.dragula_sections.loaded) {
                this.dragula_sections["right-drag-section"] = 3;
                this.dragula_sections.loaded = true;
              }

              this.device.getEnvironmentTargetPromise();
              this.device.getFirmwareReportPromise().then((r) => {});
            }
          });

        // Load DataValue?
        if (entity.fields.Temperature) {
          this.dataValue = "Temperature";
        } else if (entity.fields.Rain) {
          this.dataValue = "Rain";
        } else if (entity.fields.WindSpeed) {
          this.dataValue = "WindSpeed";
        } else {
          this.dataValue = null;
        }
      })
      .catch(() => {
        this.devError = true;
      });
  }

  ngOnDestroy() {
    this.dragulaService.destroy("HANDLES");
  }

  checkPin() {
    this.pinGroups = [];
    this.pinGroupRepo
      .getListPromise({ expand: false, pins: true })
      .then((r: Array<PinGroupEntity>) => {
        this.pinGroups = r;
        this.pinGroups.sort((a: PinGroupEntity, b: PinGroupEntity) => {
          return a.identifier >= b.identifier ? 1 : -1;
        });
        let sref = `ref.device.${this.device.serial}@${this.device.series}`;
        for (let pinGroup in this.pinGroups) {
          if (this.pinGroups[pinGroup].pins) {
            for (let pin in this.pinGroups[pinGroup].pins) {
              if (this.pinGroups[pinGroup].pins[pin].subject == sref) {
                this.pinned = this.pinGroups[pinGroup].pins[pin];
                break;
              }
            }
          }
        }
      });
  }

  ngOnInit() {
    try {
      this.dragulaService.createGroup("HANDLES", {
        moves: (el, container, handle) => {
          return handle.className.includes("dragula-handle");
        },
      });

      this.subs.add(
        this.dragulaService
          .drop("HANDLES")
          .subscribe(({ name, el, target, source, sibling }) => {
            console.log("drop");
            this.dragula_sections[source.id]--;
            this.dragula_sections[target.id]++;
            console.log("SOURCE:", source.id);
            console.log("TARGET:", target.id);
          })
      );
    } catch (e) {
      console.log("dragula error", e);
    }

    this.loadVersion();

    this.subscription = this.activatedRoute.params.subscribe((params: any) => {
      if ("id" in params) {
        this.device = null;
        if (params.id.startsWith("ref.device.")) {
          const serial = params.id.substring(11, 17);
          this.loadDeviceBySerial(serial).then(() => {
            this.clone = this.device.deepCopy();
            this.checkPin();
          });
        } else if (params.id.startsWith("ref.gateway.")) {
          const serial = params.id.substring(12, 18);
          this.loadDeviceBySerial(serial).then(() => {
            this.clone = this.device.deepCopy();
            this.checkPin();
          });
        } else if (params.id.startsWith("ref.gateway-worker.")) {
          const serial = params.id.substring(19, 25);
          this.loadDeviceBySerial(serial).then(() => {
            this.clone = this.device.deepCopy();
            this.checkPin();
          });
        } else {
          // tslint:disable-next-line:no-construct
          const id = Number(params.id);
          this.identifier = id;
          this.loadDevice(id).then(() => {
            this.clone = this.device.deepCopy();
            this.checkPin();
          });
        }
      }
      if ("uid" in params) {
        this.backUserID = params.uid;
      }
    });
  }

  ensureDataStreamCards() {
    if (!this.cardsLoaded) {
      this.cardsLoaded = true;
      this.device.getDataStreamV2();
    }
  }

  fetchDataStreamCards() {
    this.device.getDataStreamV2();
  }

  loadInfo(key) {
    switch (key) {
      case "c":
        if (!this.device.claims) {
          this.device.getClaimsPromise();
        }
        break;
      case "s":
        this.loadSimulator = true;
        break;
      case "d":
        this.device.getDataStream();
        break;
      case "f":
        if (!this.device.forecast) {
          this.device.getForecastData("false");
        }
        break;
      case "a":
        if (!this.device.alarmData) {
          this.device.getAlarmData();
        }
        break;
    }
  }

  getDeviceStatus() {
    this.loadingErrors["status"] = null;

    this.device
      .getStatusPromise()
      .then(() => {
        const times = [];
        const accepted = [
          "ai.ticks.1",
          "ai.minutes.5",
          "ai.minutes.15",
          "ai.minutes.30",
          "ai.hours.1",
          "ai.days.1",
        ];
        let first = null;
        times.push("ai.ticks.1");
        for (const item of this.device.status.feed) {
          if (accepted.indexOf(item.name) > -1) {
            if (null == first) {
              first = item.name;
            }
            times.push(item.name);
          }
        }
        this.chartTimeValues = times;
        this.timeValue = first;
      })
      .catch((e) => {
        console.log("error fetching status");
        this.loadingErrors["status"] = 0;
      });
  }

  addDataStream() {
    this.device.addNewDataStream();
  }

  removeStreamItem(e) {
    this.device.dataStream.cards.splice(e, 1);
  }

  toggleStreamItem(i) {
    this.device.toggleDataStream(i).then((ret) => {
      if (ret["code"] === 3) {
        this.dsErr.msg = "Limit 3 enabled";
        this.dsErr.show = true;
      }
    });
  }

  viewDevice(device, event) {
    event.preventDefault();
    this.device = null;
    if (this.backUserID) {
      // noinspection JSIgnoredPromiseFromCall
      this.router.navigateByUrl(
        `/portal/devices/show/${device.sensorId}/${this.backUserID}`
      );
    } else {
      // noinspection JSIgnoredPromiseFromCall
      this.router.navigateByUrl(`/portal/devices/show/${device.sensorId}`);
    }
  }

  celsiusToFahrenheit(v) {
    if (isNaN(v) || v == null) {
      return null;
    } else {
      return Math.round((v * (9 / 5) + 32) * 10) / 10;
    }
  }

  centimeterToInch(v) {
    if (isNaN(v) || v == null) {
      return v;
    } else {
      return Math.round((v / 25.4) * 100) / 100;
    }
  }

  kphToMph(v) {
    if (isNaN(v) || v == null) {
      return v;
    } else {
      return Math.round(v * 0.621371 * 100) / 100;
    }
  }

  getChartData(event) {
    event.preventDefault();
    if (this.dataValue !== "") {
      this.chartLoading = true;
      this.showChart = false;
      this.lineChartLabels = null;
      this.lineChartData = null;
      const dv = this.dataValue;
      const interval = this.timeValue;
      const durationValue = this.durationValue;
      const gapfinder = DeviceShowComponent.AiToSeconds(interval); // timevalue in seconds, for filling in empties

      const plotMode = interval === "ai.ticks.1";

      this.device
        .getChartDataPromise(dv, { interval: interval, length: durationValue })
        .then((res: any) => {
          const readings = {
            high: false,
            low: false,
            tally: false,
            spot: false,
            average: false,
          };
          const tl =
            "ref.device." + this.device.serial + "@" + this.device.series;
          const fieldInfo = res[tl][interval]["fields"][dv];
          this.units = fieldInfo.unit;
          const vals = fieldInfo["values"];
          // Data is not sorted as I am writing this, so sort it here.
          vals.sort((a, b) => {
            return a.u > b.u ? 1 : b.u > a.u ? -1 : 0;
          });
          const data = [];
          let tally = 0;
          const labels = [];
          let c = "a"; // Container of data
          if (dv === "Rain") {
            c = "t"; // Rains data is
          }

          // Load data into the data array, convert its units if nessecary
          let newest = null;
          let neweststamp = 0;
          let h, l, s;
          let oData = [];
          this.displayToggles.tblVal = dv === "Rain" ? "Accum." : "Average";

          for (const x of vals) {
            let t = 0;

            readings.high = readings.high || "h" in x;
            readings.low = readings.low || "l" in x;
            readings.tally = readings.tally || "t" in x;
            readings.average = readings.average || "a" in x;
            readings.spot = readings.spot || "s" in x;

            if (dv === "Temperature") {
              t = this.celsiusToFahrenheit(x[c]); // Math.round((x[c] * (9 / 5) + 32) * 10) / 10;
              h = this.celsiusToFahrenheit(x["h"]); // Math.round((x['h'] * (9 / 5) + 32) * 10) / 10;
              l = this.celsiusToFahrenheit(x["l"]); // Math.round((x['l'] * (9 / 5) + 32) * 10) / 10;
              s = this.celsiusToFahrenheit(x["s"]); // Math.round((x['s'] * (9 / 5) + 32) * 10) / 10;
              this.units = "Fahrenheit";
            } else if (dv === "Rain") {
              t = this.centimeterToInch(x[c]); // Math.round((x[c] / 25.4) * 100) / 100;
              //s = this.centimeterToInch(x['s']); // Math.round((x['s'] / 25.4) * 100) / 100;
              s = x["s"];
              this.units = "Inches";
            } else if (dv === "HeatIndex") {
              t = x[c];
              h = x["h"];
              l = x["l"];
              s = x["s"];
              this.units = "Fahrenheit";
            } else if (dv === "WindSpeed" || dv === "WindGust") {
              t = this.kphToMph(x[c]); // Math.round(x[c] * 0.621371 * 100) / 100;
              h = this.kphToMph(x["h"]); // Math.round(x['h'] * 0.621371 * 100) / 100;
              l = this.kphToMph(x["l"]); // Math.round(x['l'] * 0.621371 * 100) / 100;
              s = this.kphToMph(x["s"]); // Math.round(x['s'] * 0.621371 * 100) / 100;
              this.units = "MPH";
            } else {
              t = x[c];
              h = x["h"];
              l = x["l"];
              s = x["s"];
            }

            // Plot Graph Workaround.
            if (plotMode) {
              data.push(s);
            } else {
              data.push(t);
            }
            oData.push({ h: h, l: l, s: s });

            tally++;
            const temp = new Date(x["u"] * 1000);
            // labels.push(temp.getMonth() + 1 + '-' + temp.getDate() + ' ' + temp.getHours() + ': ' + (('0' + temp.getMinutes()).slice(-2)));
            labels.push(temp);
            if (temp.getTime() > neweststamp) {
              neweststamp = temp.getTime();
              newest = temp;
            }
          }
          if (neweststamp > 0) {
            const nowTime = new Date().getTime();
            let newestTime = newest.getTime();

            if (newestTime < nowTime - gapfinder * 3000) {
              // 3 missed intervals
              while (newestTime < nowTime - gapfinder * 2000) {
                // fill in the gap
                newestTime += gapfinder * 1000;
                const tempDate = new Date(newestTime);
                labels.push(
                  tempDate.getMonth() +
                    1 +
                    "-" +
                    tempDate.getDate() +
                    " " +
                    tempDate.getHours() +
                    ": " +
                    ("0" + tempDate.getMinutes()).slice(-2)
                );
                data.push(null);
              }
            }
          }

          this.lineChartData = [{ data: data, label: this.dataValue }];
          this.lineChartLabels = labels;
          console.log("Line Chart Data", this.lineChartData);
          console.log("Line Chart Labels", this.lineChartLabels);

          const rx = labels.slice().reverse();
          const ry = data.slice().reverse();
          oData = oData.reverse();
          const td = [];

          // Hack workaround to show plot data in charts.
          rx.forEach(function (x) {
            if (oData[td.length]) {
              td.push({
                x: x,
                y: ry[td.length],
                h: oData[td.length]["h"],
                l: oData[td.length]["l"],
                s: oData[td.length]["s"],
              });
            } else {
              // td.push({'x': x, 'y': ry[td.length], 'h': null, 'l':  null, 's':  null});
            }
          });

          this.tableData = td;
          this.availableReadings.average = readings.average;
          this.availableReadings.tally = readings.tally;
          this.availableReadings.high = readings.high;
          this.availableReadings.low = readings.low;
          this.availableReadings.spot = readings.spot;

          this.showChart = true;
          this.chartLoading = false;
          // Force change detection.
          this.changeDetector.detectChanges();
        });
    }
  }

  public chartClicked(e: any): void {
    // console.log(e);
  }

  public chartHovered(e: any): void {
    // console.log(e);
  }

  public pinDevice(event, modal) {
    event.preventDefault();
    if (this.pinned) {
      let dialog = new ModalWidget(
        "Pin: " + this.device.elixirSref(),
        ModalWidgetEnum.MODAL_WIDGET__UPDATE,
        this.pinned,
        {
          edit: true,
          revert: false,
          confirmName: "Update",
          deleteName: "Unpin",
        },
        "shadowbox"
      );
      this.showModal(dialog, modal);
    } else {
      let pin = this.pinRepo.entity({});
      pin.group =
        this.pinGroups && this.pinGroups.length > 0
          ? this.pinGroups[0].sref()
          : null;
      pin.name = `Device ${this.device.serial}@${this.device.series}`;
      pin.description = "auto generated";
      pin.subject = this.device.elixirSref();
      pin.meta.new = true;
      let dialog = new ModalWidget(
        "Pin: " + this.device.elixirSref(),
        ModalWidgetEnum.MODAL_WIDGET__CREATE,
        pin,
        { edit: true, revert: false, confirmName: "Pin" },
        "shadowbox"
      );
      this.showModal(dialog, modal);
    }
  }

  showModal(current, modal, clickOut = false, customClass = "") {
    this.currentModal = current;
    if (!clickOut) {
      let config: ModalOptions = {
        backdrop: "static",
        keyboard: false,
        class: customClass,
      };
      this.currentModal.modalRef = this.modalService.show(modal, config);
    } else {
      let config: ModalOptions = {
        class: customClass,
      };
      this.currentModal.modalRef = this.modalService.show(modal, config);
    }
  }

  deletePermissionEntry(confirmModal, pItem, event) {
    event.preventDefault();
    this.currentModal = {
      title: "Confirm Delete User Permissions",
      msg: `This will wipe this users permissions for this device?`,
      confirmMsg: "Yes, Proceed",
      confirm: () => {
        this.modalRef.hide();
        pItem.loading = true;
        this.notification.showLoading = true;
        this.device
          .deletePermissionEntry(pItem.id, pItem.groupId)
          .then((result: any) => {
            pItem.loading = false;
            this.notification.showLoading = false;
            if (result.outcome === true) {
              this.notification.message = "Success";
              this.device.getPermissionEntriesPromise();
            } else {
              this.notification.message = "An Error Occurred";
              this.notification.iserror = true;
            }
          })
          .catch((error: any) => {
            pItem.loading = false;
            console.log(error);
            this.notification.message = "An Error Occurred";
            this.notification.iserror = true;
            this.notification.showLoading = false;
          });
      },
      cancelMsg: "No, Cancel",
      cancel: () => {
        this.modalRef.hide();
      },
    };
    let config: ModalOptions = {
      backdrop: "static",
      keyboard: false,
    };
    this.modalRef = this.modalService.show(confirmModal, config);
  }

  factoryReset(confirmModal, device, event) {
    event.preventDefault();
    this.currentModal = {
      title: "Confirm Factory Reset",
      msg:
        "This step is irreversible and will clear all historic sensor data, geo settings, " +
        "and permissions. Are you sure you wish to factory reset " +
        `${device.series}: ${device.serial}?`,
      confirmMsg: "Yes, Proceed",
      confirm: () => {
        this.modalRef.hide();
        this.notification.showLoading = true;
        device
          .factoryReset()
          .then((result: any) => {
            this.notification.showLoading = false;
            if (result === true) {
              this.notification.message = "Success";
              this.device.getUsersPromise();
              this.device.getPermissionEntriesPromise().then((res: any) => {
                this.claimable = res;
              });
            } else {
              this.notification.message = "An Error Occurred";
              this.notification.iserror = true;
            }
          })
          .catch((error: any) => {
            console.log(error);
            this.notification.message = "An Error Occurred";
            this.notification.iserror = true;
            this.notification.showLoading = false;
          });
      },
      cancelMsg: "No, Cancel",
      cancel: () => {
        this.modalRef.hide();
      },
    };
    let config: ModalOptions = {
      backdrop: "static",
      keyboard: false,
    };
    this.modalRef = this.modalService.show(confirmModal, config);
  }

  reweightCards(event) {
    console.log("REWEIGHT CARDS");
    const violations = [];
    const preparedList = [];

    for (let i = 1; i < event.length; i++) {
      const card = event[i];
      if (!card.new && !card.deleted) {
        preparedList.push(card);
      }
    }

    for (let i = 1; i < preparedList.length; i++) {
      const card = preparedList[i];
      if (card.weight < preparedList[i - 1].weight) {
        violations.push(i);
      }
    }

    this.device.dataStreamV2.cards = event;

    if (violations.length === 2) {
      const moved = violations[0];
      const cardA = preparedList[moved].sref();
      const cardB = preparedList[moved - 1].sref();
      this.device.putCardAfter(event[moved], event[moved - 1]).then(() => {
        console.log(`Card ${cardA} moved behind ${cardB}`);
        this.toasterService.pop(
          "success",
          "DataStream",
          `Card ${cardA} moved behind ${cardB}`
        );
      });
    }

    if (violations.length === 1) {
      if (violations[0] === event.length - 1) {
        const moved = violations[0];
        const cardA = preparedList[moved].sref();
        const cardB = preparedList[moved - 1].sref();
        this.device.putCardAfter(event[moved], event[moved - 1]).then(() => {
          console.log(`Card ${cardA} moved behind ${cardB}`);
          this.toasterService.pop(
            "success",
            "DataStream",
            `Card ${cardA} moved behind ${cardB}`
          );
        });
      } else {
        const moved = violations[0] - 1;
        const cardA = preparedList[moved].sref();
        const cardB = preparedList[moved + 1].sref();
        this.device.putCardBefore(event[moved], event[moved + 1]).then(() => {
          console.log(`Card ${cardA} moved before ${cardB}`);
          this.toasterService.pop(
            "success",
            "DataStream",
            `Card ${cardA} moved before ${cardB}`
          );
        });
      }
    }
  }

  permissionReset(confirmModal, device, event) {
    event.preventDefault();
    this.currentModal = {
      title: "Confirm Permission Reset",
      msg:
        "This step is irreversible and will clear sensor owner/subscriber permissions." +
        "Are you sure you wish to reset the permissions on " +
        `${device.series}: ${device.serial}?`,
      confirmMsg: "Yes, Proceed",
      confirm: () => {
        this.modalRef.hide();
        this.notification.showLoading = true;
        device
          .permissionReset()
          .then((result: any) => {
            this.notification.showLoading = false;
            if (result === true) {
              this.notification.message = "Success";
            } else {
              this.notification.message = "An Error Occurred";
              this.notification.iserror = true;
            }
          })
          .catch((error: any) => {
            console.log(error);
            this.notification.message = "An Error Occurred";
            this.notification.iserror = true;
            this.notification.showLoading = false;
          });
      },
      cancelMsg: "No, Cancel",
      cancel: () => {
        this.modalRef.hide();
      },
    };
    let config: ModalOptions = {
      backdrop: "static",
      keyboard: false,
    };
    this.modalRef = this.modalService.show(confirmModal, config);
  }

  saveBatch(confirmModal, event) {
    event.preventDefault();
    this.currentModal = {
      title: "Confirm Update Sensor Batch",
      msg: `Are you sure you want to make these changes?`,
      confirmMsg: "Yes, Proceed",
      confirm: () => {
        this.modalRef.hide();
        this.notification.showLoading = true;
        this.device
          .saveBatchPromise()
          .then((result: DeviceEntity) => {
            this.device.refresh(
              result.toJson({
                strip_from_json: {
                  status: 1,
                  permissionEntries: 1,
                  logs: 1,
                  users: 1,
                },
              })
            );
            this.device.meta["batch_change"] = false;
            this.notification.message = "Success";
            this.notification.showLoading = false;
          })
          .catch((error: any) => {
            console.log(error);
            this.notification.message = "An Error Occurred";
            this.notification.iserror = true;
            this.notification.showLoading = false;
          });
      },
      cancelMsg: "No, Cancel",
      cancel: () => {
        this.modalRef.hide();
      },
    };
    let config: ModalOptions = {
      backdrop: "static",
      keyboard: false,
    };
    this.modalRef = this.modalService.show(confirmModal, config);
  }

  saveCode(confirmModal, event) {
    event.preventDefault();
    this.currentModal = {
      title: "Confirm Update Verification Code",
      msg: `Are you sure you want to make these changes?`,
      confirmMsg: "Yes, Proceed",
      confirm: () => {
        this.modalRef.hide();
        this.notification.showLoading = true;
        this.device
          .saveVerificationCodePromise()
          .then((result: DeviceEntity) => {
            this.device.refresh(
              result.toJson({
                strip_from_json: {
                  status: 1,
                  permissionEntries: 1,
                  logs: 1,
                  users: 1,
                },
              })
            );
            this.notification.message = "Success";
            this.notification.showLoading = false;
          })
          .catch((error: any) => {
            console.log(error);
            this.notification.message = "An Error Occurred";
            this.notification.iserror = true;
            this.notification.showLoading = false;
          });
      },
      cancelMsg: "No, Cancel",
      cancel: () => {
        this.modalRef.hide();
      },
    };
    let config: ModalOptions = {
      backdrop: "static",
      keyboard: false,
    };
    this.modalRef = this.modalService.show(confirmModal, config);
  }

  resetRain(confirmModal, event) {
    event.preventDefault();

    let from = "-366d";
    if (!this.rr.startDefault && this.rr.startVal > 0) {
      from = "-" + this.rr.startVal + this.rr.startInt;
    }
    let to;
    if (!this.rr.endDefault && this.rr.endVal >= 0) {
      if (DeviceShowComponent.compareRRVals(this.rr)) {
        to = "-" + this.rr.endVal + this.rr.endInt;
      } else {
        this.notification.message = "Reset Rain values out of Order";
        this.notification.iserror = true;
        this.notification.showLoading = false;
        return;
      }
    } else {
      to = false;
    }
    let s = "";
    s += this.rr.startDefault
      ? (s += "1 year")
      : (s +=
          this.rr.startVal +
          " " +
          DeviceShowComponent.timeLetterToString(this.rr.startInt));
    s += " ago to ";
    if (this.rr.endDefault) {
      s += "now";
    } else {
      s +=
        this.rr.endVal +
        " " +
        DeviceShowComponent.timeLetterToString(this.rr.endInt) +
        "s ago";
    }

    this.currentModal = {
      title: "Confirm Reset Device Rain data",
      msg: `Are you sure you want to reset this devices rain data to 0 from ${s}?`,
      confirmMsg: "Yes, Proceed",
      confirm: () => {
        this.modalRef.hide();
        this.notification.showLoading = true;
        this.device
          .resetRain(from, to)
          .then((result: any) => {
            this.notification.message =
              "Emptying rain started, check the feed below later";
            this.notification.iserror = false;
            this.notification.showLoading = false;
          })
          .catch((error: any) => {
            console.log(error);
            this.notification.message = "An Error Occurred";
            this.notification.iserror = true;
            this.notification.showLoading = false;
          });
      },
      cancelMsg: "No, Cancel",
      cancel: () => {
        this.modalRef.hide();
      },
    };
    let config: ModalOptions = {
      backdrop: "static",
      keyboard: false,
    };
    this.modalRef = this.modalService.show(confirmModal, config);
  }
}
