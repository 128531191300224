export enum FieldTypeEnum {
  NotSupported = 0,
  Temperature,
  Humidity,
  Rain,
  WindSpeed,
  WindHeading,
  HeatIndex,
  WindChill,
  Lightning,
  Wetness = 10,
  WindGust,
  FeelsLike,
  WindLevel,
  PrecipitationLevel,
}
