import { FirebaseAuthService } from "./services/firebase-auth.service";
import { DomainObject } from "./domain-object";
import { AppengineEntityList } from "./structs/appengine-entity-list";
import { WidgetEnum } from "../enums";
import { HttpClient } from "@angular/common/http";
import { ElixirEntityList } from "./structs";

export class DomainObjectRepo extends FirebaseAuthService {
  public _api_version = "v1";
  public _kind = null;
  public _containerContents: WidgetEnum = null;
  public _appengine = true;
  public _sort = null;

  constructor(
    public client: HttpClient,
    public auth: FirebaseAuthService /*, afAuth: AngularFireAuth*/
  ) {
    super();
  }

  filterOptions() {
    return null;
  }

  //A simple Get call that expects an array of results.
  //Built special for the admin endpoints, likely will not work for others.
  _getListPromise(url, init, options = {}) {
    return new Promise((resolve, reject) => {
      this.auth
        .getTokenPromise()
        .then(
          (token) => {
            let requestOptions = this.auth.request_options(token, options);
            this.client
              .get(url, requestOptions)
              .toPromise()
              .then((response: any) => {
                try {
                  let data = response;
                  if (this._appengine) {
                    let entries = [];
                    if (data.items) {
                      for (var i = 0; i < data.items.length; i++) {
                        entries.push(init(data.items[i]));
                      }
                    }
                    if (options["sort"])
                      entries = entries.sort(options["sort"]);
                    else if (this._sort) entries = entries.sort(this._sort);

                    let set = new AppengineEntityList(
                      this._kind,
                      entries,
                      data.cursor || null,
                      this._containerContents,
                      this,
                      options
                    );
                    resolve(set);
                  } else {
                    let entries = [];

                    if (data && data["response"]) {
                      if (Array.isArray(data.response)) {
                        for (var i = 0; i < data.response.length; i++) {
                          entries.push(init(data.response[i]));
                        }
                      }
                    } else {
                      if (!Array.isArray(data)) {
                        data = [data];
                      }
                      for (var i = 0; i < data.length; i++) {
                        entries.push(init(data[i]));
                      }
                    }
                    if (options["set"]) {
                      let set = new ElixirEntityList(
                        this._kind,
                        entries,
                        data["cursor"] || null,
                        this._containerContents,
                        this,
                        options
                      );
                      resolve(set);
                    } else {
                      resolve(entries);
                    }
                  }
                } catch (e) {
                  console.log(`Exception Raised ([GET] ${url})`, e);
                  throw e;
                }
              })
              .catch((error) => {
                console.warn(`Request Error . . . ${url}`, error);
                reject({ message: "request error", details: error });
              });
          },
          (error) => {
            console.warn("Token Error", error);
            reject({ message: "token error", details: error });
          }
        )
        .catch((error) => {
          console.warn("Token Error", error);
          reject({ message: "token error", details: error });
        });
    });
  }

  //Get request that expects a single result
  //Built special for the admin endpoints, likely will not work for others.
  _getEntityPromise(url, init, options = {}) {
    return new Promise((resolve, reject) => {
      this.auth.getTokenPromise().then(
        (token) => {
          let requestOptions = this.auth.request_options(token, options);
          this.client
            .get(url, requestOptions)
            .toPromise()
            .then((response: any) => {
              try {
                if (options["return-null"] == true && response.status == 204) {
                  resolve(null);
                } else {
                  init(response, resolve);
                }
              } catch (e) {
                console.log(`Exception Raised ([GET] ${url})`, e);
                throw e;
              }
            })
            .catch((error) => {
              console.warn(`Request Error . . . ${url}`, error);
              reject({ message: "request error", details: error });
            });
        },
        (error) => {
          console.error("Token Error", error);
          reject({ message: "token error", details: error });
        }
      );
    });
  }

  /**
   * Generic method for deleting an item/ handling [DELETE] requests.
   * @param {string} url The resource url.
   * @param {Object} [options={}] Additional optional parameters
   * @returns {Promise}
   */

  _deleteItemPromise(url, init, options = {}) {
    return new Promise((resolve, reject) => {
      this.auth.getTokenPromise().then(
        (token) => {
          let requestOptions = this.auth.request_options(token, options);
          this.client
            .delete(url, requestOptions)
            .toPromise()
            .then((response: any) => {
              init(response, resolve);
            })
            .catch((error) => {
              console.warn(`Request Error . . . ${url}`, error);
              reject({ message: "request error", details: error });
            });
        },
        (error) => {
          reject({
            message: "token error",
            details: error,
          });
        }
      );
    });
  }

  /**
   * Generic method for creating new items/ handling [POST] requests.
   * @param {string} url The url to query.
   * @param {DomainObject} data The data for new object
   * @param {Object} [options={}] Additional optional parameters
   * @returns {Promise}
   */

  _postItemPromise(url, data, init, options = {}) {
    return new Promise((resolve, reject) => {
      this.auth.getTokenPromise().then(
        (token) => {
          let requestOptions = this.auth.request_options(token, options);
          this.client
            .get(url, requestOptions)
            .toPromise()
            .then((response: any) => {
              let data = response;
              if (data && data["data"]) {
                data = data["data"];
              }
              init(data, resolve);
            })
            .catch((error) => {
              console.warn(`Request Error . . . ${url}`, error);
              reject({ message: "request error", details: error });
            });
        },
        (error) => {
          reject({
            message: "token error",
            details: error,
          });
        }
      );
    });
  }

  //============================================================================
  // REST Helpers
  //============================================================================
  /**
   * Makes the REST calls
   * @url The url to be called
   * @data (when required) the body of the POST or PUT requestOptions
   * @init The callback function
   * @options Object that customizes headers and other info.
   * See the FirebaseAuthService.request_options for how Options is used.
   **/

  /* Example Call in a Repo or Entity page
    function Postthis() {
      return this._post(
        `${this.ingvEndpoint()}/path/parameter`,
        payload,
        (data, resolve) => {resolve(data);},
        {}
      );
    }
    // Make a call to specific URL with body Payload. On response resolve the async call with the data and use default Headers
    // Get requests for instance do not have payload, and so one less parameter

    The call that calls the repo or entity function example above

      this.Postthis().then((data)=> {
        //Handle the function and update the web page.
      })
    */

  _put(url, data, init, options = {}) {
    return new Promise((resolve, reject) => {
      let json = DomainObject.dataToJson(data, options);
      this.auth.getTokenPromise().then(
        (token) => {
          let requestOptions = this.auth.request_options(token, options);
          this.client
            .put(url, JSON.stringify(json), requestOptions)
            .toPromise()
            .then((response: any) => {
              init(response, resolve);
            })
            .catch((error) => {
              console.warn(`Request Error . . . ${url}`, error);
              reject({ message: "request error", details: error });
            });
        },
        (error) => {
          console.error("Token Error", error);
          reject({ message: "token error", details: error });
        }
      );
    });
  }

  _post(url, data, init, options = {}) {
    let p = new Promise((resolve, reject) => {
      let json = DomainObject.dataToJson(data, options);
      this.auth.getTokenPromise().then(
        (token) => {
          let requestOptions = this.auth.request_options(token, options);
          this.client
            .post(url, JSON.stringify(json), requestOptions)
            .toPromise()
            .then((response: any) => {
              init(response, resolve);
            })
            .catch((error) => {
              console.warn(`Request Error . . . ${url}`, error);
              reject({ message: "request error", details: error });
            });
        },
        (error) => {
          console.error("Token Error", error);
          reject({ message: "token error", details: error });
        }
      );
    });
    return p;
  }

  _get(url, init, options = {}) {
    return new Promise((resolve, reject) => {
      this.auth.getTokenPromise().then(
        (token) => {
          let requestOptions = this.auth.request_options(token, options);
          this.client
            .get(url, requestOptions)
            .toPromise()
            .then((response: any) => {
              init(response, resolve);
            })
            .catch((error) => {
              console.warn(`Request Error . . . ${url}`, error);
              reject({ message: "request error", details: error });
            });
        },
        (error) => {
          console.error("Token Error", error);
          reject({ message: "token error", details: error });
        }
      );
    });
  }

  _delete(url, init, options = {}) {
    return new Promise((resolve, reject) => {
      this.auth.getTokenPromise().then(
        (token) => {
          let requestOptions = this.auth.request_options(token, options);
          this.client
            .delete(url, requestOptions)
            .toPromise()
            .then((response: any) => {
              init(response, resolve);
            })
            .catch((error) => {
              console.warn(`Request Error . . . ${url}`, error);
              reject({ message: "request error", details: error });
            });
        },
        (error) => {
          console.error("Token Error", error);
          reject({ message: "token error", details: error });
        }
      );
    });
  }

  // ============================================================================
  // Utils
  // ============================================================================
  isObject(value) {
    return value === Object(value);
  }

  isSRef(value) {
    return this.isString(value) && value.startsWith("ref.");
  }

  isString(value) {
    return typeof value === "string";
  }

  identifierToSref(srefModule, json, provider: (any) => string = null) {
    if (!this.isSRef(json)) {
      let raw = provider ? provider(json) : json;
      return `ref.${srefModule}.${raw}`;
    } else {
      return json;
    }
  }

  srefToIdentifier(srefModule, json, castInt = false) {
    if (this.isSRef(json)) {
      console.log("is sref", json);
      let prefix = `ref.${srefModule}.`;
      if (json && this.isString(json) && json.startsWith(prefix)) {
        let raw = json.substring(prefix.length);
        if (castInt) {
          return Number.parseInt(raw);
        } else {
          return raw;
        }
      } else {
        console.log("is not prefix", json, prefix);
        return json || null;
      }
    } else {
      console.log("is not sref", json);
      return json;
    }
  }
}
