import { HttpClient } from "@angular/common/http";
import { DomainObjectRepo } from "../noizu/domain-object-repo";
import { FirebaseAuthService } from "../noizu/services/firebase-auth.service";
import { environment } from "../../environments/environment";
import { DomainObject } from "../noizu/domain-object";

export class AppengineRepo extends DomainObjectRepo {
  public _kind;
  public _singular;
  public _version = "v1.1";
  public _api = "lacrosseAdmin";
  public _endpoint = environment.lax_gateway_url + "/_ah/api/lacrosseAdmin";
  public _ingv_endpoint = environment.ingv2_url + "/api";
  public _ingv_version = "v1.1";

  constructor(client: HttpClient, auth: FirebaseAuthService) {
    super(client, auth);
  }

  apiBase() {
    return `${this._endpoint}/${this._version}`;
  }

  ingvEndpoint() {
    return `${this._ingv_endpoint}/${this._ingv_version}`;
  }

  entity(record): DomainObject {
    throw "Must Implement entity(record) method";
  }

  getEntityPromise(id, options = {}) {
    return this._getEntityPromise(
      `${this.apiBase()}/${this._singular}/${id}`,
      (record, resolve) => {
        resolve(this.entity(record));
      },
      options
    );
  }

  getListPromise(options = {}) {
    let rpp =
      options["resultsPerPage"] > 0
        ? `resultsPerPage=${options["resultsPerPage"]}`
        : `resultsPerPage=250`;
    let cursor = options["cursor"] ? `cursor=${options["cursor"]}` : false;
    let query_param =
      rpp || cursor
        ? `?${rpp ? rpp : ""}${cursor && rpp ? "&" : ""}${cursor ? cursor : ""}`
        : "";
    return this._getListPromise(
      `${this.apiBase()}/${this._kind}${query_param}`,
      (record) => {
        return this.entity(record);
      },
      options
    );
  }
}
