import { AlertFacadeEntity } from "../facade.entity";
import { WidgetEnum_Alert_Facade } from "../../../enums/widget/alert";

export class AlertMotionFacadeEntity extends AlertFacadeEntity {
  public triggerOnMotion: any;

  public constructor(alert, json) {
    super(alert, json);
    this.triggerOnMotion = json.triggerOnMotion;
  }

  public get template(): string | null {
    return "motion";
  }

  alert_facade_widget() {
    return WidgetEnum_Alert_Facade.ALERT_FACADE_WIDGET__MOTION;
  }
}
