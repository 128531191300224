import { Component, Input, Output, EventEmitter } from "@angular/core";
import { AlertTriggerEvent } from "../../../entities/alert";
import { WidgetBase } from "../widget-base";
import { AlertWidgetEventStruct } from "./alert-widget-event.struct";

@Component({
  selector: "alert-trigger-event-widget",
  template: `
    <div
      class="alert-trigger-event-widget card"
      [ngClass]="{ 'mb-0': options?.nested }"
    >
      <div class="card-header" [ngClass]="{ 'p-2 m-0': options?.nested }">
        <div class="row">
          <div class="col">
            <b class="text-left">Event: #{{ entity.identifier }}</b>
          </div>
          <div class="col-2 text-right p-0 text-middle">
            <toggle-widget [options]="expand_settings"></toggle-widget>
          </div>
        </div>
      </div>
      <div class="card-body" [ngClass]="{ 'd-none': !expand_settings.toggle }">
        <pre>{{ entity.toJson() | json }}</pre>
      </div>
    </div>
  `,
})
export class AlertTriggerEventWidgetComponent extends WidgetBase {
  @Input() entity: AlertTriggerEvent = null;
  @Input() options: any = null;
  @Input() layout: string = null;
  @Output() widgetEvent = new EventEmitter<AlertWidgetEventStruct>();
  forwardEvent(e, widgetEvent: AlertWidgetEventStruct) {
    e.preventDefault();
    this.widgetEvent.emit(widgetEvent);
  }

  constructor() {
    super();
  }
}
