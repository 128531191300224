
    <div *ngIf="!entity">
      <new-spinner [size]="'sm'"></new-spinner>
    </div>
    <ng-container *ngIf="entity">
      <ng-container [ngSwitch]="entity.user_extended_widget_type()">
        <extended-user-subscription-details
          *ngSwitchCase="
            UserExtendedWidgetEnum.USER_EXTENDED_WIDGET__SUBSCRIPTION_DETAILS
          "
          (widgetEvent)="forwardEvent($event)"
          [entity]="entity"
          [layout]="layout"
          [options]="options"
        ></extended-user-subscription-details>
        <extended-user-subscription
          *ngSwitchCase="
            UserExtendedWidgetEnum.USER_EXTENDED_WIDGET__SUBSCRIPTION
          "
          (widgetEvent)="forwardEvent($event)"
          [entity]="entity"
          [layout]="layout"
          [options]="options"
        ></extended-user-subscription>
        <extended-user-subscription-payment
          *ngSwitchCase="
            UserExtendedWidgetEnum.USER_EXTENDED_WIDGET__SUBSCRIPTION_PAYMENT
          "
          (widgetEvent)="forwardEvent($event)"
          [entity]="entity"
          [layout]="layout"
          [options]="options"
        ></extended-user-subscription-payment>
        <extended-user-effective-subscription
          *ngSwitchCase="
            UserExtendedWidgetEnum.USER_EXTENDED_WIDGET__EFFECTIVE_SUBSCRIPTION
          "
          (widgetEvent)="forwardEvent($event)"
          [entity]="entity"
          [layout]="layout"
          [options]="options"
        ></extended-user-effective-subscription>

        <!-- Default -->
        <ng-container *ngSwitchDefault>
          <div [ngSwitch]="layout">
            <div *ngSwitchCase="'shadowbox'">
              <div class="row mb-5">
                <div class="col text-center border-bottom">
                  <span class="display-4">{{
                    UserExtendedWidgetEnum[entity.user_extended_widget_type()]
                  }}</span>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <pre>{{ entity.toJson() | json }}</pre>
                </div>
              </div>
            </div>
            <div *ngSwitchDefault>
              <div class="card" [ngClass]="{ 'mb-0': options?.nested }">
                <div
                  class="card-header"
                  [ngClass]="{ 'p-2 m-0': options?.nested }"
                >
                  {{
                    UserExtendedWidgetEnum[entity.user_extended_widget_type()]
                  }}
                </div>
                <div class="card-body">
                  <pre>{{ entity.toJson() | json }}</pre>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  