import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ToasterService } from "angular2-toaster";
import { AlertContactFacadeEntity } from "../../../../entities/alert";
import { AlertFacadeDefaultWidgetComponent } from "./default.widget.component";
import { AlertFacadeWidgetEventStruct } from "./alert-facade-widget-event.struct";
import { AlertEntity } from "app/entities";

@Component({
  selector: "alert-facade-contact-widget",
  template: `
    <div class="card card-accent-dark alert-facade-contact-widget">
      <!-- Card Header -->
      <div class="card-header bg-dark text-white">
        <h5 class="mb-0">Contact Alert</h5>
      </div>

      <!-- Card Body -->
      <div class="card-body">
        <!-- Device Section -->
        <div class="row mb-3">
          <div class="col-4 font-weight-bold">Device</div>
          <div class="col-8">
            <a
              [routerLink]="'/portal/devices/show/' + entity.device"
              class="text-primary"
              >{{ entity.device }}</a
            >
          </div>
        </div>

        <!-- Association Section -->
        <div class="row mb-3">
          <div class="col-4 font-weight-bold">Association</div>
          <div class="col-8">{{ entity.deviceAssociation }}</div>
        </div>

        <hr class="my-3" />

        <!-- On Contact Section -->
        <div class="row mb-3">
          <div class="col-4 font-weight-bold">On Contact</div>
          <div *ngIf="!options?.edit" class="col-8 text-muted">
            {{ entity.triggerOnContact | json }}
          </div>
          <div *ngIf="options?.edit" class="col-8">
            <label class="switch switch-3d switch-primary">
              <input
                class="switch-input switch-primary"
                (change)="facadeChange($event)"
                [(ngModel)]="entity.triggerOnContact"
                type="checkbox"
              />
              <span class="switch-slider"></span>
            </label>
          </div>
        </div>
      </div>
    </div>
  `,
})
export class EmbedAlertContactFacadeComponent extends AlertFacadeDefaultWidgetComponent {
  public editMode = false;
  @Input() layout: string = null;
  @Input() entity: AlertContactFacadeEntity;
  @Input() options: any;
  @Input() data: AlertEntity | null = null;
  @Input() deviceData: any = null;

  @Output() widgetEvent = new EventEmitter<AlertFacadeWidgetEventStruct>();
  forwardEvent(e, widgetEvent: AlertFacadeWidgetEventStruct) {
    e.preventDefault();
    this.widgetEvent.emit(widgetEvent);
  }

  setDeviceValues() {
    if (!this.options.edit) return;
    this.entity.device = this.deviceData.deviceId;
    this.entity.deviceAssociation = this.deviceData.associationId;
  }
  ngOnInit() {
    if (
      this.deviceData.deviceId !== undefined &&
      this.deviceData.associationId !== undefined
    ) {
      this.setDeviceValues();
    }
  }

  constructor(public toasterService: ToasterService) {
    super();
  }
}