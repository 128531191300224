import { Inject, Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { FirebaseAuthService } from "../noizu/services/firebase-auth.service";
import { ElixirRepo } from "./elixir.repo";

@Injectable()
export class GenericRepo extends ElixirRepo {
  public _kind = "";
  public _singular = "";

  constructor(
    @Inject(HttpClient) client: HttpClient,
    @Inject(FirebaseAuthService) auth: FirebaseAuthService
  ) {
    super(client, auth);
  }
}
