import { Inject, Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { FirebaseAuthService } from "../noizu/services/firebase-auth.service";
import { ElixirRepo } from "./elixir.repo";

@Injectable()
export class SystemReportRepo extends ElixirRepo {
  constructor(
    @Inject(HttpClient) client: HttpClient,
    @Inject(FirebaseAuthService) auth: FirebaseAuthService
  ) {
    super(client, auth);
  }

  public pgSummary() {
    return this._get(
      `${this.apiBase()}/system/monitor/pg-stat-activity/summary`,
      (record, resolve) => {
        if (record.outcome) {
          resolve(record.response);
        }
      },
      {}
    );
  }

  public pgQueries() {
    return this._get(
      `${this.apiBase()}/system/monitor/pg-stat-activity/queries`,
      (record, resolve) => {
        if (record.outcome) {
          resolve(record.response);
        }
      },
      {}
    );
  }

  public obanJobs() {
    return this._get(
      `${this.apiBase()}/system/monitor/oban/jobs`,
      (record, resolve) => {
        if (record.outcome) {
          resolve(record.response);
        }
      },
      {}
    );
  }

  public hyperTables() {
    return this._get(
      `${this.apiBase()}/system/monitor/hypertables`,
      (record, resolve) => {
        if (record.outcome) {
          resolve(record.response);
        }
      },
      {}
    );
  }

  public hyperTableChunks() {
    return this._get(
      `${this.apiBase()}/system/monitor/hypertables/chunks`,
      (record, resolve) => {
        if (record.outcome) {
          resolve(record.response);
        }
      },
      {}
    );
  }

  public hyperTableExtended() {
    return this._get(
      `${this.apiBase()}/system/monitor/hypertables/extended`,
      (record, resolve) => {
        if (record.outcome) {
          resolve(record.response);
        }
      },
      {}
    );
  }

  public timeScaleJobs() {
    return this._get(
      `${this.apiBase()}/system/monitor/time-scale-jobs`,
      (record, resolve) => {
        if (record.outcome) {
          resolve(record.response);
        }
      },
      {}
    );
  }

  public timeScaleJobStats() {
    return this._get(
      `${this.apiBase()}/system/monitor/time-scale-jobs/stats`,
      (record, resolve) => {
        if (record.outcome) {
          resolve(record.response);
        }
      },
      {}
    );
  }

  public timeScaleJobErrors() {
    return this._get(
      `${this.apiBase()}/system/monitor/time-scale-jobs/errors`,
      (record, resolve) => {
        if (record.outcome) {
          resolve(record.response);
        }
      },
      {}
    );
  }

  public timeScaleJobExtended() {
    return this._get(
      `${this.apiBase()}/system/monitor/time-scale-jobs/extended`,
      (record, resolve) => {
        if (record.outcome) {
          resolve(record.response);
        }
      },
      {}
    );
  }
}
