
    <form
      action=""
      class="data-management-psk-upload-widget form-horizontal"
      [formGroup]="uploadFile"
      (ngSubmit)="onSubmit($event)"
    >
      <div class="card">
        <div class="card-header bg-danger">
          <h1>Gateway PSK Upload</h1>
        </div>
        <div class="card-body m-2 p-0" *ngIf="entity?.initialized">
          <div class="row mb-3">
            <div class="col text-center">
              <div class="btn-group" role="group" aria-label="Upload Type">
                <button
                  class="btn"
                  [ngClass]="{
                    'btn-primary': entity?.upload_type == 'csv',
                    'btn-secondary': entity?.upload_type != 'csv'
                  }"
                  (click)="setUploadType('csv', $event)"
                  type="button"
                >
                  CSV File
                </button>
                <button
                  class="btn"
                  [ngClass]="{
                    'btn-primary': entity?.upload_type == 'csv-snippet',
                    'btn-secondary': entity?.upload_type != 'csv-snippet'
                  }"
                  (click)="setUploadType('csv-snippet', $event)"
                  type="button"
                >
                  CSV Text
                </button>
                <button
                  class="btn"
                  [ngClass]="{
                    'btn-primary': entity?.upload_type == 'json-snippet',
                    'btn-secondary': entity?.upload_type != 'json-snippet'
                  }"
                  (click)="setUploadType('json-snippet', $event)"
                  type="button"
                >
                  Json
                </button>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <div class="alert alert-success">
                <!-- has header -->
                <div
                  class="row"
                  *ngIf="
                    entity?.upload_type == 'csv' ||
                    entity?.upload_type == 'csv-snippet'
                  "
                >
                  <div class="col">
                    <label>
                      <input
                        type="checkbox"
                        [checked]="entity.flags.has_header"
                        (change)="
                          entity.flags.has_header = !entity.flags.has_header
                        "
                      />
                      CSV Has Header?
                    </label>
                  </div>
                </div>

                <!-- over write? -->
                <div class="row">
                  <div class="col">
                    <label>
                      <input
                        type="checkbox"
                        [checked]="entity.flags.overwrite"
                        (change)="
                          entity.flags.overwrite = !entity.flags.overwrite
                        "
                      />
                      Overwrite Existing?
                    </label>
                  </div>
                </div>

                <!-- update Objectify
                <div class="row">
                  <div class="col">
                    <label>
                      <input type="checkbox" [checked]="upload_options.flags.objectify" (change)="upload_options.flags.objectify = !upload_options.flags.objectify" />
                      Update Objectify (Appengine DataStore)
                    </label>
                  </div>
                </div>
                -->

                <!-- update Weather Service -->
                <div class="row">
                  <div class="col">
                    <label>
                      <input
                        type="checkbox"
                        [checked]="entity.flags.update_redis"
                        (change)="
                          entity.flags.update_redis = !entity.flags.update_redis
                        "
                      />
                      Update Weather Service
                    </label>
                  </div>
                </div>

                <!-- overwrite redis -->
                <div class="row" *ngIf="entity.flags.update_redis">
                  <div class="col">
                    <label>
                      <input
                        type="checkbox"
                        [checked]="entity.flags.overwrite_redis"
                        (change)="
                          entity.flags.overwrite_redis =
                            !entity.flags.overwrite_redis
                        "
                      />
                      Overwrite Weather Service PSK/Category
                    </label>
                  </div>
                </div>
              </div>

              <!-- Only if update Redis -->
              <ng-container *ngIf="entity.flags.update_redis">
                <div
                  class="row"
                  *ngIf="entity?.validation?.messages['weather_category']"
                >
                  <div class="col">
                    <div class="alert alert-danger">
                      {{ entity?.validation?.messages["weather_category"] }}
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-4"><strong>Weather Category: </strong></div>
                  <div class="col-8">
                    <logical-widget
                      (widgetEvent)="eventHandler($event, 'weather_category')"
                      [entity]="weather_category"
                      [options]="options"
                      [layout]="'default'"
                    ></logical-widget>
                  </div>
                </div>
              </ng-container>

              <div
                class="row"
                *ngIf="entity?.validation?.messages['manufacturer']"
              >
                <div class="col">
                  <div class="alert alert-danger">
                    {{ entity?.validation?.messages["manufacturer"] }}
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-4"><strong>Manufacturer: </strong></div>
                <div class="col-8">
                  <logical-widget
                    (widgetEvent)="eventHandler($event, 'manufacturer')"
                    [entity]="manufacturer"
                    [options]="options"
                    [layout]="'default'"
                  ></logical-widget>
                </div>
              </div>

              <div class="row" *ngIf="entity?.validation?.messages['batch']">
                <div class="col">
                  <div class="alert alert-danger">
                    {{ entity?.validation?.messages["batch"] }}
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-4"><strong>Batch: </strong></div>
                <div class="col-8">
                  <logical-widget
                    (widgetEvent)="eventHandler($event, 'batch')"
                    [entity]="batch"
                    [options]="options"
                    [layout]="'default'"
                  ></logical-widget>
                </div>
              </div>

              <div class="row" *ngIf="entity?.validation?.messages['series']">
                <div class="col">
                  <div class="alert alert-danger">
                    {{ entity?.validation?.messages["series"] }}
                  </div>
                </div>
              </div>
              <div class="row mb-4">
                <div class="col-4"><strong>Series: </strong></div>
                <div class="col-8">
                  <logical-widget
                    (widgetEvent)="eventHandler($event, 'series')"
                    [entity]="series"
                    [options]="options"
                    [layout]="'default'"
                  ></logical-widget>
                </div>
              </div>

              <div class="form-group" *ngIf="entity.upload_type == 'csv'">
                <label class="col-md-3 col-form-label" for="csv"
                  >CSV Upload</label
                >
                <input
                  type="file"
                  (change)="updateFile($event)"
                  name="psk_file"
                />
              </div>

              <div
                class="form-group"
                *ngIf="entity.upload_type == 'csv-snippet'"
              >
                <textarea
                  name="csv_snippet"
                  [(ngModel)]="csv_snippet"
                  cols="64"
                  rows="32"
                  placeholder="Copy and Paste CSV entries."
                ></textarea>
              </div>

              <div
                class="form-group"
                *ngIf="entity.upload_type == 'json-snippet'"
              >
                <textarea
                  name="json_snippet"
                  [(ngModel)]="json_snippet"
                  cols="64"
                  rows="32"
                  placeholder="Copy and Paste Json Array"
                ></textarea>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer text-center">
          <button type="submit" class="btn btn-lg btn-danger">
            <i class="fa fa-dot-circle-o"></i> Upload Verification Codes
          </button>
        </div>
      </div>
    </form>
  