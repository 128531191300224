import { Component, Input } from "@angular/core";
import { CmsAlertEntity } from "../../../entities/cms/";
import { CmsAlertRepo } from "../../../repos/cms/";

@Component({
  selector: "cms-show",
  templateUrl: "./show.component.html",
})
export class CmsAlertShowComponent {
  @Input() entry: CmsAlertEntity = null;
  public unmodifiedEntry = null;
  public editEntry: boolean = false;
  public deleted: boolean = false;
  public saving: boolean = false;
  constructor(public repo: CmsAlertRepo) {}

  getVersionHistory(entity) {
    this.repo.getVersionHistory(entity.identifier).then((u: any) => {
      entity.history = u;
    });
  }

  update(e, entity) {
    e.preventDefault();
    this.saving = true;
    entity.update();
    this.editEntry = false;
    this.saving = false;
  }

  edit(e, entity) {
    e.preventDefault();
    this.unmodifiedEntry = this.entry.toJson();
    this.editEntry = true;
  }

  cancel(e) {
    e.preventDefault();
    this.entry.refresh(this.unmodifiedEntry);
    this.editEntry = false;
  }

  delete(e, entity) {
    e.preventDefault();
    this.saving = true;
    this.editEntry = false;
    entity.delete().then((res: any) => {
      if (res) {
        this.deleted = true;
      }
      this.saving = false;
    });
  }
}
