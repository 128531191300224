
    <div class="row mb-3">
      <div class="col border-bottom">
        <h1
          [ngClass]="{
            'display-4': !minimize,
            'font-weight-light h3': minimize
          }"
        >
          <ng-content select="page-title"></ng-content>
          <i
            (click)="toggleMinimization($event)"
            class="fa text-dark"
            [ngClass]="{
              'fa-compress font-sm align-top ': !minimize,
              'align-middle fa-expand font-xs': minimize
            }"
          ></i>
        </h1>
        <p class="lead" *ngIf="!minimize">
          <ng-content select="page-blurb"></ng-content
          ><ng-container *ngIf="wizard"
            ><i class="fa fa-mortar-board"> </i
          ></ng-container>
        </p>
      </div>
    </div>
  