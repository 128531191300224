
    <div class="card border-dark alert-facade-wet-dry-widget">
      <!-- Card Header -->
      <div class="card-header bg-dark text-white">
        <h5 class="mb-0">Wet-Dry Alert</h5>
      </div>

      <!-- Card Body -->
      <div class="card-body">
        <!-- Device Section -->
        <div class="row mb-3 align-items-center">
          <div class="col-4 font-weight-bold">Device</div>
          <div class="col-8">
            <a
              [routerLink]="'/portal/devices/show/' + entity.device"
              class="text-primary text-decoration-none"
            >
              {{ entity.device }}
            </a>
          </div>
        </div>

        <!-- Association Section -->
        <div class="row mb-3 align-items-center">
          <div class="col-4 font-weight-bold">Association</div>
          <div class="col-8">{{ entity.deviceAssociation }}</div>
        </div>

        <!-- Horizontal Line -->
        <hr class="my-3" />

        <!-- Trigger Condition Display (Read-only mode) -->
        <div *ngIf="!options?.edit" class="row">
          <div class="col-12">
            Trigger If:
            <b class="text-primary">{{
              entity.triggerIfWet ? "Wet" : "Dry"
            }}</b>
          </div>
        </div>

        <!-- Trigger Condition Display (Edit Mode) -->
        <div *ngIf="options?.edit" class="row align-items-center">
          <div class="col-4 font-weight-bold">Trigger If</div>
          <div class="col-8">
            <div class="form-check form-switch">
              <div class="d-flex align-items-center">
                <label class="switch switch-3d switch-primary mb-0">
                  <input
                    class="switch-input switch-primary"
                    (change)="facadeChange($event)"
                    [(ngModel)]="entity.triggerIfWet"
                    type="checkbox"
                    id="wetDryToggle"
                  />
                  <span class="switch-slider"></span>
                </label>
                <label
                  class="form-check-label mx-2 ms-2 mb-0"
                  for="wetDryToggle"
                >
                  {{ entity.triggerIfWet ? "Wet" : "Dry" }}
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  