import { HttpClient } from "@angular/common/http";
import { FirebaseAuthService } from "../../noizu/services/firebase-auth.service";
import { ElixirEntity } from "../elixir.entity";
import { WidgetEnum } from "../../enums";
import { DeviceEntity } from "../device.entity";
import { UserEntity } from "../user.entity";
import { DeviceDefinitionEntity } from "../device";

export class PinEntity extends ElixirEntity {
  public name: string;
  public description: string;
  public subject: any;
  public group: any;
  public created_on: Date;
  public modified_on: Date;
  public _singular = "entity-pin";
  public _kind = "entity-pins";

  public toasterService;

  constructor(client: HttpClient, auth: FirebaseAuthService, json) {
    super(client, auth, json);
    this.refresh(json);
  }

  refresh(json) {
    this.identifier = json["identifier"];
    this.name = json["name"];
    this.description = json["description"];
    this.created_on = json["created_on"] && new Date(json["created_on"]);
    this.modified_on = json["modified_on"] && new Date(json["modified_on"]);
    if (json["group"] && this.isString(json["group"])) {
      this.group = json["group"];
    } else if (json["group"]) {
      this.group = "ref.entity-pin-group." + json["group"].identifier;
    }

    if (this.isString(json["subject"])) {
      this.subject = json["subject"];
    } else if (json["subject"]) {
      switch (json["subject"]["kind"]) {
        case "Elixir.GoldenRatio.DeviceEntity":
          this.subject = new DeviceEntity(
            this.client,
            this.auth,
            json["subject"]
          );
          break;
        case "Elixir.GoldenRatio.Device.TypeEntity":
          this.subject = new DeviceDefinitionEntity(
            this.client,
            this.auth,
            json["subject"]
          );
          break;
        case "Elixir.GoldenRatio.UserEntity":
          this.subject = new UserEntity(
            this.client,
            this.auth,
            json["subject"]
          );
          break;
        default:
          this.subject = null;
      }
    }

    return this;
  }

  createEndpoint() {
    return `${this.apiBase()}/admin-tools/user/entity-pin-groups/${
      this.group
    }/entity-pins`;
  }

  updateEndpoint() {
    return `${this.apiBase()}/admin-tools/user/entity-pin-groups/${
      this.group
    }/entity-pins/${this.identifier}`;
  }

  deleteEndpoint() {
    return `${this.apiBase()}/admin-tools/user/entity-pin-groups/${
      this.group
    }/entity-pins/${this.identifier}`;
  }

  widget_type() {
    return WidgetEnum.EMBED_WIDGET__PIN;
  }

  validate() {
    let valid = true;
    let validated = true;
    let error = null;
    let messages: any = {};

    if (!this.group) {
      valid = false;
      error = "Group Required";
      messages["group"] = "PinGroup Required.";
    }

    if (!this.subject) {
      valid = false;
      error = "Subject Required";
      messages["subject"] = "Subject Required.";
      messages["common"] = "Pin has no subject";
    }

    if (!this.name || this.name.length < 3) {
      valid = false;
      error = "Name Required";
      messages["name"] = "Name Required.";
    }

    if (!this.description || this.description.length < 3) {
      valid = false;
      error = "Description Required";
      messages["description"] = "Description Required.";
    }

    this.validation = {
      valid: valid,
      validated: validated,
      error: error,
      messages: messages,
    };
    return this.validation;
  }

  filter(filter: string) {
    if (filter) {
      //filter = filter.toLowerCase();
      if (this.meta["filter"] == filter) return this.meta["filter_result"];

      // Prep Lower Case Data
      if (!this.meta["filter_set"]) {
        this.meta["filter_set"] = {};
        this.meta["filter_set"]["name"] = this.name || "NULL";
        this.meta["filter_set"]["description"] = this.description || "NULL";
        this.meta["filter_set"]["identifier"] = `${this.identifier}`;
      }

      this.meta["filter"] = filter;
      this.meta["filter_result"] = false;

      if (this.meta.filter_set.name.includes(filter))
        this.meta["filter_result"] = true;
      else if (this.meta.filter_set.description.includes(filter))
        this.meta["filter_result"] = true;
      else if (this.meta.filter_set.identifier.includes(filter))
        this.meta["filter_result"] = true;
      else if (filter.includes("name:" + this.meta.filter_set.name))
        this.meta["filter_result"] = true;
      else if (
        filter.includes("description:" + this.meta.filter_set.description)
      )
        this.meta["filter_result"] = true;
      else if (filter.includes("identifier:" + this.meta.filter_set.identifier))
        this.meta["filter_result"] = true;
      else if (filter.includes("id:" + this.meta.filter_set.identifier))
        this.meta["filter_result"] = true;
      else if (this.subject.filter(filter)) this.meta["filter_result"] = true;
      //else if (this.group.filter(filter)) this.meta['filter_result'] = true;
      return this.meta.filter_result;
    } else {
      return true;
    }
  }
} // end Package
