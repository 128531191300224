import { LogicalWidgetEnum } from "../../enums/widget";
import { LogicalWidget } from "../logical-widget";
export class ManufacturerLogicalWidget extends LogicalWidget {
  public selected: any = null;

  logical_widget_type(): LogicalWidgetEnum | null {
    return LogicalWidgetEnum.LOGICAL_WIDGET__MANUFACTURER;
  }

  constructor() {
    super();
  }
}
