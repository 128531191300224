import { EntityLogSet } from "../../entities/log/entity-log.set";
import { AlertRepo } from "../../repos/alert.repo";
import { HttpClient } from "@angular/common/http";
import { FirebaseAuthService } from "../../noizu/services/firebase-auth.service";
import { AppengineEntityList } from "../../noizu/structs/appengine-entity-list";
import { ElixirEntity } from "../../entities/elixir.entity";
import { ExtendedUserSubscriptionDetailsEntity } from "../../entities/user/extended/subscription-details.entity";
import { AlertEntity } from "../../entities";

export class ExtendedUserDetails extends ElixirEntity {
  public loading: boolean;
  public loading_component: { alerts: boolean } = { alerts: false };
  public identifier: string;
  public logs: { user: EntityLogSet } = null;
  public alerts: AppengineEntityList = null;
  public subscriptions: ExtendedUserSubscriptionDetailsEntity | null = null;
  constructor(
    public client: HttpClient,
    public auth: FirebaseAuthService,
    json
  ) {
    super(client, auth, json);
    this.loading = true;
    this.logs = {
      user: null,
    };
    this.meta.subscriptions_loaded = false;
    this.meta.subscriptions_loading = false;
  }

  postAlertsPromise(alertData: object, options = {}) {
    this.loading_component.alerts = true;
    let repo = new AlertRepo(this.client, this.auth);
    return repo
      .postUserAlertPromise(this.identifier, alertData, options)
      .then((alertEntity: AlertEntity) => {
        this.loading_component.alerts = false;
        if (this.alerts) {
          const existingAlertIndex = this.alerts.items.findIndex(
            (alert) => alert.identifier === alertEntity.identifier
          );
          if (existingAlertIndex !== -1) {
            this.alerts.items[existingAlertIndex] = alertEntity;
          } else {
            this.alerts.items.push(alertEntity);
          }
        }
        repo.multiStatusPromise([alertEntity]).then((statuses: any) => {
          alertEntity.extended.status_loaded = statuses.alert_details[
            alertEntity.identifier
          ]
            ? true
            : false;
          alertEntity.extended.status = alertEntity.extended.status_loaded
            ? statuses.alert_details[alertEntity.identifier]
            : {};

          repo.multiDetailsPromise([alertEntity]).then((details: any) => {
            if (details.alert_details[alertEntity.identifier]) {
              alertEntity.setState(
                details.alert_details[alertEntity.identifier]
              );
            }
          });
        });
        return alertEntity;
      })
      .catch((error) => {
        this.loading_component.alerts = false;
        throw error;
      });
  }

  getAlertsPromise(options = {}) {
    this.loading_component.alerts = true;
    let repo = new AlertRepo(this.client, this.auth);
    return repo
      .getUserAlertListPromise(this.identifier, options)
      .then((alert_set: AppengineEntityList) => {
        this.loading_component.alerts = false;
        this.alerts = alert_set;
        repo.multiStatusPromise(this.alerts).then((statuses: any) => {
          this.alerts.items.forEach((v) => {
            v.extended.status_loaded = statuses.alert_details[v.identifier]
              ? true
              : false;
            v.extended.status = v.extended.status_loaded
              ? statuses.alert_details[v.identifier]
              : {};
          });

          repo.multiDetailsPromise(this.alerts).then((details: any) => {
            this.alerts.items.forEach((v: AlertEntity) => {
              if (details.alert_details[v.identifier]) {
                v.setState(details.alert_details[v.identifier]);
              }
            });
          });
        });
      });
  }

  getSubscriptionDetailsPromise(refresh = false, force = false) {
    if (!force && this.meta["subscriptionPromise"]) {
      if (!refresh || this.meta.subscriptions_loading)
        return this.meta["subscriptionPromise"];
    }
    this.meta["subscriptionPromise"] = new Promise((resolve, reject) => {
      this.meta.subscriptions_loading = true;
      this._get(
        this.ingvEndpoint() +
          `/users/${this.identifier}/subscriptions?expand-refs=sub-payment`,
        (response, inner_resolve) => {
          let sd = new ExtendedUserSubscriptionDetailsEntity(
            this.client,
            this.auth,
            `ref.user.${this.identifier}`,
            response["response"]
          );
          inner_resolve(sd);
        },
        {}
      )
        .then((response: ExtendedUserSubscriptionDetailsEntity) => {
          this.subscriptions = response;
          this.meta.subscriptions_loaded = true;
          this.meta.subscriptions_loading = false;
          this.meta.subscriptions_load_error = null;
          resolve(this.subscriptions);
        })
        .catch((r) => {
          this.meta.subscriptions_load_error = r;
          this.meta.subscriptions_loading = false;
          resolve(this.subscriptions);
        });
    });
    return this.meta["subscriptionPromise"];
  }
}
