
    <div class="card shadow" *ngIf="entity">
      <div class="card-header">
        <div class="row">
          <div class="col">
            Permission Widget
            <span *ngIf="options" class="float-right"
              ><i
                (click)="toggleExpand()"
                class="fa fa-sm"
                [ngClass]="{
                  'fa-chevron-down': !options.expand,
                  'fa-chevron-up': options.expand
                }"
              >
              </i
            ></span>
          </div>
        </div>
      </div>
      <div class="card-body p-1 m-0" *ngIf="entity && expandWidget()">
        <div class="row" *ngFor="let perm of effective; let i = index">
          <div class="col-1 d-flex align-items-center">#{{ i + 1 }}</div>
          <div class="col">
            <b>{{ perm.name }}</b>
          </div>
          <div class="col-auto">
            <span
              class="btn-pill pl-2 pr-2"
              [ngClass]="{ 'bg-success': perm.grant == 'granted' }"
              >{{ perm.grant }}</span
            >
          </div>
        </div>
      </div>
    </div>
  