import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { WidgetBase } from "./widget-base";
import { WidgetEventStruct } from "../widget-event.struct";
import { ModalWidgetEnum } from "../../enums";
import { ElixirEntity, ModalWidget } from "../../entities";
import { BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import { DomainObject } from "../../noizu/domain-object";
import { EntitySetWidgetEventStruct } from "./entity-set";
import { ElixirEntityList, NoizuStruct } from "../../noizu/structs";

@Component({
  selector: "generic-set-widget",
  template: `
    <entity-set-header-widget
      [entity]="this"
      (widgetEvent)="entitySetEvent($event)"
      [layout]="layout"
      [options]="options"
    ></entity-set-header-widget>

    <entity-set-list-widget
      [entity]="this"
      (widgetEvent)="forwardEvent($event)"
      [layout]="layout"
      [options]="options"
    ></entity-set-list-widget>

    <entity-set-footer-widget
      [entity]="this"
      (widgetEvent)="entitySetEvent($event)"
      [layout]="layout"
      [options]="options"
    ></entity-set-footer-widget>

    <ng-template #popUpModal>
      <modal-widget
        [entity]="currentModal"
        [layout]="'shadow_box'"
        [options]="options"
      ></modal-widget>
    </ng-template>
  `,
})
export class GenericWidgetSetComponent extends WidgetBase implements OnInit {
  @Input() entity: ElixirEntityList = null;
  @Input() options: any = {};
  @Input() layout: string = null;
  @ViewChild("popUpModal", { read: TemplateRef }) modalWizard: TemplateRef<any>;

  public tableView = false;
  public initialView;
  public has_entries = false;
  public records = 0;
  public not_shown = 0;
  public shown = 0;
  public selected = null;
  public filter = null;
  public newFilter = null;
  public filteredSet: any = null;
  public columns: any = 1;
  public fetching = false;
  public currentModal: any;
  public dpp = 500;
  public odpp = 500;
  public pages = 0;
  public page = 0;
  public pagination = [];

  public newEntity: DomainObject | NoizuStruct | null = null;
  public columnOptions = [
    { label: "One Column", value: "1" },
    { label: "Two Columns", value: "2" },
    { label: "Three Columns", value: "3" },
    { label: "Four Columns", value: "4" },
    { label: "Six Columns", value: "6" },
    { label: "Twelve Columns", value: "12" },
  ];

  @Output() widgetEvent = new EventEmitter<WidgetEventStruct>();
  forwardEvent(widgetEvent: WidgetEventStruct) {
    if (widgetEvent.event_type == "select_entity") {
      this.selected = widgetEvent.event_body;
    }
    console.log("Forwarding event", widgetEvent);
    this.widgetEvent.emit(widgetEvent);
  }

  constructor(public modalService: BsModalService) {
    super();
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.tableView = this.layout == "table";
    this.filter = this.options["filter"] || null;
    this.newFilter = this.filter;
    this.initialView = this.layout == "table" ? "standard" : this.layout;
    this.columns = this.options["columns"] || 1;
    this.columns = `${this.columns}`;
    this.dpp = this.options["dpp"] || 500;
    this.odpp = this.dpp;
    this.filterRecords(this.newFilter);
  }

  entitySetEvent(entitySetEvent: EntitySetWidgetEventStruct) {
    console.log("Got Event", entitySetEvent);
    switch (entitySetEvent.event_type) {
      case "add-modal":
        this.add(this.modalWizard);
        break;
      case "change-view":
        this.changeView();
        break;
      case "to-page":
        this.toPage(null, entitySetEvent.event_body);
        break;
      case "clear-filter":
        this.clearFilter();
        break;
      case "filter":
        this.filterRecords(entitySetEvent.event_body);
        break;
      case "update-pagination":
        this.updatePagination();
        break;
      case "next":
        this.next();
        break;
    }
  }

  public toPage(event, p) {
    if (event) event.preventDefault();
    this.page = p;
  }

  public clearFilter(event = null) {
    this.filterRecords(null, event);
  }

  public filterRecords(newFilter, event = null) {
    if (event) event.preventDefault();
    this.filter = newFilter;
    if (this.filter) {
      this.filteredSet = this.entity.items.filter((el, i, a) => {
        return el.filter(this.filter);
      });
    } else {
      this.filteredSet = this.entity.items;
    }

    this.records = this.entity.items.length;
    this.shown = this.filteredSet.length;
    this.not_shown = this.records - this.shown;
    this.updatePagination();
    this.has_entries = this.filteredSet && this.filteredSet.length > 0;
  }

  public updatePagination() {
    let op = this.pages;
    this.pages = Math.ceil(this.shown / this.dpp);
    if (this.pages != op) {
      if (this.odpp != this.dpp) {
        this.page = Math.trunc((this.page * this.odpp) / this.dpp);
        this.odpp = this.dpp;
      }
      if (this.page > this.pages) this.page = 0;
      let r = [];
      for (let i = 0; i < this.pages; i++) {
        r.push({ page: i });
      }
      this.pagination = r;
    }
  }

  public changeView(event = null) {
    if (event) event.preventDefault();
    this.tableView = !this.tableView;
    this.layout = this.tableView ? "table" : this.initialView;
  }

  public next(event = null) {
    if (event) event.preventDefault();
    this.entity.nextPromise().then((r: ElixirEntityList) => {
      console.log("updated list", this.entity);
      this.filterRecords(this.filter);
    });
  }

  public saveEntity() {
    return true;
  }

  add(modal, e = null) {
    console.log("ADD MODAL", modal);
    if (e) e.preventDefault();

    let widget_options = { edit: this.options["edit"] || false };

    // @ts-ignore
    let new_entity: ElixirEntity = this.entity.repo.entity({});
    new_entity.new = true;
    this.newEntity = new_entity;
    let dialog = new ModalWidget(
      this.options["title"] || "Create",
      ModalWidgetEnum.MODAL_WIDGET__CREATE,
      this.newEntity,
      widget_options,
      "shadowbox"
    );
    this.showModal(dialog, modal);
  }

  showModal(current, modal, clickOut = false) {
    this.currentModal = current;
    if (!clickOut) {
      let config: ModalOptions = {
        backdrop: "static",
        keyboard: false,
        class: "lg-modal",
      };
      this.currentModal.modalRef = this.modalService.show(modal, config);
    } else {
      let config: ModalOptions = {
        class: "lg-modal",
      };
      this.currentModal.modalRef = this.modalService.show(modal, config);
    }
  }
}
