<div class="row mb-5">
  <div class="col-12 text-center">
    <span class="display-3">Wifi Breakdown</span>
  </div>
  <div class="col-12 text-center">
    <span class="display-4">{{ generated_on }}</span>
  </div>
</div>

<div class="row">
  <div class="col-12 d-flex flex-row">
    <div class="col-6">
      <div class="card bg-info">
        <div class="card-header bg-info">Firmware RollUp</div>
        <div class="card-body bg-white">
          <canvas
            baseChart
            class="chart"
            [labels]="firmwareRollUpLabels"
            [data]="firmwareRollUpData"
            [legend]="false"
            [chartType]="'pie'"
            (chartHover)="chartHovered('fimwareRollUp', $event)"
            (chartClick)="chartClicked('fimwareRollUp', $event)"
          ></canvas>
        </div>
      </div>
    </div>

    <div class="col-6">
      <div class="card bg-info">
        <div class="card-header text-body bg-info">
          <div class="row">
            <div class="col-12">
              <ng-select
                placeholder="Select Batch"
                [items]="availableBatches"
                [multiple]="false"
                [(ngModel)]="activeBatch"
                bindLabel="label"
                bindValue="value"
                (ngModelChange)="onChange($event)"
              ></ng-select>
            </div>
          </div>
        </div>
        <div class="card-body bg-white text-body">
          <div class="row">
            <div class="col-12">
              <canvas
                baseChart
                class="chart"
                [labels]="activeFirmwareRollUpByBatchLabels"
                [data]="activeFirmwareRollUpByBatchData"
                [legend]="false"
                [chartType]="'pie'"
                (chartHover)="chartHovered('fimwareRollUpByBatch', $event)"
                (chartClick)="chartClicked('fimwareRollUpByBatch', $event)"
              ></canvas>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
