import { Component, Input, Output, EventEmitter } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { FirebaseAuthService } from "../../../noizu/services/firebase-auth.service";
import { DataStreamRepo } from "../../../repos/data-stream.repo";

@Component({
  selector: "data-stream-item",
  templateUrl: "./data-stream-item.component.html",
})
export class DataStreamItemComponent {
  @Input() entity;
  @Input() id: string = null;
  @Input() i;
  @Output() deleteIndex = new EventEmitter<number>();
  @Output() enableIndex = new EventEmitter<number>();
  isNWS = false;
  extended = false;
  editMode = false;
  editEntry = {};
  isNew = false;
  DSR = null;
  loading = false;
  nwsOptions = [
    { value: "wind_speed", display: "Wind Speed" },
    { value: "wind_direction", display: "Wind Direction" },
    { value: "wind_gust", display: "Wind Gust" },
    { value: "snow_accumulation", display: "Snow Accumulation" },
    { value: "chance_tornado", display: "Tornado Chance" },
    { value: "chance_hail", display: "Hail Chance" },
    {
      value: "chance_damaging_thunderstorms",
      display: "Damaging Thunderstorm Chance",
    },
    { value: "sky_cover", display: "Sky Cover" },
  ];

  constructor(client: HttpClient, auth: FirebaseAuthService) {
    this.DSR = new DataStreamRepo(client, auth);
  }

  ngOnInit() {
    if (this.entity.identifier) {
      if (this.entity.reading) {
        this.isNWS = true;
      }
    } else {
      this.editMode = true;
      this.isNew = true;
    }
    if (this.entity.kind.indexOf("NationalWeatherService") > -1)
      this.isNWS = true;
    this.editEntry = JSON.parse(JSON.stringify(this.entity));
  }

  toggleNWS() {
    if (this.isNWS) {
      this.editEntry["kind"] =
        "Elixir.Ingressor.DataStream.Card.MediaCard.V1_1";
    } else {
      this.editEntry["kind"] =
        "Elixir.Ingressor.DataStream.Card.NationalWeatherServiceCard.V1_1";
      if (!this.editEntry["reading"]) {
        this.editEntry["reading"] = "wind_speed";
      }
    }
    this.isNWS = !this.isNWS;
  }

  save(e) {
    e.preventDefault();
    this.loading = true;
    this.DSR.saveStream(this.id, this.editEntry, this.isNew).then(
      (ret: any) => {
        ret["enabled"] = ret["enabled?"];
        this.entity = ret;
        this.editEntry = JSON.parse(JSON.stringify(ret));
        this.editMode = false;
        this.loading = false;
      }
    );
  }

  delete(e) {
    e.preventDefault();
    this.DSR.deleteStream(this.id, this.entity.identifier).then((ret: any) => {
      this.entity = null;
      this.deleteIndex.emit(this.i);
    });
  }

  cancel(e) {
    e.preventDefault();
    if (this.isNew) {
      this.entity = null;
      this.editEntry = null;
      this.deleteIndex.emit(-1);
    }
    this.editMode = false;
  }

  toggleEnable(e) {
    e.preventDefault();
    this.enableIndex.emit(this.i);
  }
}
