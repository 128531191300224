import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { FirmwareReleaseRepo } from "../../../repos";
import { FirmwareReleaseEntity } from "../../../entities";

@Component({
  template: `
    <div *ngIf="!entity">
      <div class="row">
        <div class="col">
          <new-spinner [size]="'sm'"></new-spinner>
        </div>
      </div>
    </div>
    <ng-container *ngIf="entity">
      <firmware-release-widget
        [entity]="entity"
        [layout]="'full'"
        [options]="{}"
      ></firmware-release-widget>
    </ng-container>
  `,
})
export class FirmwareReleaseShowComponent implements OnInit {
  public entity: FirmwareReleaseEntity = null;
  public subscription: any;

  constructor(
    public activatedRoute: ActivatedRoute,
    public repo: FirmwareReleaseRepo
  ) {}

  ngOnInit() {
    this.subscription = this.activatedRoute.params.subscribe((params: any) => {
      if ("id" in params) {
        this.repo
          .getEntityPromise(params["id"])
          .then((entity: FirmwareReleaseEntity) => {
            this.entity = entity;
          });
      }
    });
  }
}
