import { HttpClient } from "@angular/common/http";
import { FirebaseAuthService } from "./services/firebase-auth.service";
import { EntityReference } from "./structs/entity-reference";
import { NoizuStruct } from "./structs/noizu-struct";
import { environment } from "../../environments/environment";

export class DomainObject extends NoizuStruct {
  public identifier: number | string | null = "nyi";
  public vsn: number;
  public raw: any;
  public strip_from_json = {};
  public _always_strip = {
    _kind: 0,
    strip_from_json: 0,
    raw: 0,
    _always_strip: 0,
    auth: 0,
    client: 0,
    validation: 0,
    meta: 0,
    _sref_module: 0,
    _endpoint: 0,
    _ingv_endpoint: 0,
    _ingv_version: 0,
    _version: 0,
  };
  public _kind = "nyi";
  public _sref_module = "nyi";
  public _endpoint = environment.lax_gateway_url + "/_ah/api/lacrosseAdmin";
  public _ingv_endpoint = environment.ingv2_url + "/api";
  public _ingv_version = "v1.1";
  public _ingv_new_version = "v1.4";
  public _version = "v1.1";

  errorPromise(error) {
    return new Promise((resolve, reject) => {
      reject(error);
    });
  }

  static dataToJson(entity, options = {}) {
    if (entity instanceof DomainObject || entity instanceof NoizuStruct) {
      return entity.toJson(options);
    } else {
      return entity;
    }
  }

  constructor(
    public client: HttpClient,
    public auth: FirebaseAuthService,
    raw: any
  ) {
    super();
    this.strip_from_json["validation"] = 1;
    if (raw) {
      this.refresh(raw);
    }
  }

  validate() {
    this.validation = {
      valid: false,
      validated: false,
      error: "Validation not supported for this types.",
      messages: { common: "Validation Not Supported" },
    };
    return this.validation;
  }

  get srefModule() {
    return this._sref_module;
  }

  set srefModule(s) {
    this._sref_module = s;
  }

  refresh(data) {
    if (data) {
      this.vsn = data["vsn"] || null;
      this.identifier = data["id"] || data["identifier"] || null;
      this.raw = data;
    }
    return this;
  }

  apiBase() {
    return `${this._endpoint}/${this._version}`;
  }

  ingvEndpoint() {
    return `${this._ingv_endpoint}/${this._ingv_version}`;
  }
  ingvNewEndpoint() {
    return `${this._ingv_endpoint}/${this._ingv_new_version}`;
  }
  sref() {
    return `ref.${this.srefModule}.${this.identifier}`;
  }

  ref() {
    return new EntityReference(
      this.client,
      this.auth,
      "ref." + this._kind + "." + this.identifier
    );
  }

  kind() {
    return this._kind;
  }

  id() {
    return this.identifier;
  }

  expand() {
    return this;
  }

  filter(filter: string) {
    return false;
  }

  toJson(options: any = {}) {
    const c: any = {
      identifier: this.identifier,
      kind: this.kind(),
      vsn: this.vsn,
    };
    let strip_level = 3;
    let strip_from_json = this.strip_from_json;
    if ("strip_level" in options) {
      strip_level = options.strip_level;
    }
    if ("strip_from_json" in options) {
      strip_from_json = options.strip_from_json;
    }
    const exclude_fields = options["exclude_fields"] || [];
    const exclude = {};
    for (let f = 0; f < exclude_fields.length; f++) {
      exclude[exclude_fields[f]] = true;
    }

    for (const key in this) {
      const key_str = key.toString();
      if (
        (key_str in strip_from_json &&
          strip_from_json[key_str] < strip_level) ||
        (key_str in this._always_strip &&
          this._always_strip[key_str] < strip_level) ||
        key_str in exclude
      ) {
        // console.log('Strip 1: ', key_str);
      } else {
        if (
          this[key] instanceof DomainObject ||
          this[key] instanceof NoizuStruct
        ) {
          if (key === "category") {
            c[key] = this[key]["enum"];
          } else {
            const p: any = this[key];
            c[key] = p.toJson(options);
          }
        } else {
          if (
            !(this[key] && {}.toString.call(this[key]) === "[object Function]")
          ) {
            if (Array.isArray(this[key])) {
              const o = [];
              const a: any = this[key];
              for (let na = 0; na < a.length; na++) {
                if (a[na] instanceof NoizuStruct) {
                  o.push(a[na].toJson(options));
                } else {
                  o.push(a[na]);
                }
              }
              c[key] = o;
            } else {
              c[key] = this[key];
            }
          } else {
            // console.log('Strip 2: ', key_str);
          }
        }
      }
    }
    return c;
  }

  _put(url, data, init, options = {}) {
    return new Promise((resolve, reject) => {
      const json = DomainObject.dataToJson(data, options);
      this.auth.getTokenPromise().then(
        (token) => {
          const requestOptions = this.auth.request_options(token, options);
          this.client
            .put(url, JSON.stringify(json), requestOptions)
            .toPromise()
            .then((response: any) => {
              init(response, resolve);
            })
            .catch((error) => {
              console.warn(`Request Error . . . ${url}`, error);
              reject({ message: "request error", details: error });
            });
        },
        (error) => {
          console.error("Token Error", error);
          reject({ message: "token error", details: error });
        }
      );
    });
  }

  _post(url, data, init, options = {}) {
    const p = new Promise((resolve, reject) => {
      const json = DomainObject.dataToJson(data, options);
      this.auth.getTokenPromise().then(
        (token) => {
          const requestOptions = this.auth.request_options(token, options);
          this.client
            .post(url, JSON.stringify(json), requestOptions)
            .toPromise()
            .then((response: any) => {
              init(response, resolve);
            })
            .catch((error) => {
              console.warn(`Request Error . . . ${url}`, error);
              reject({ message: "request error", details: error });
            });
        },
        (error) => {
          console.error("Token Error", error);
          reject({ message: "token error", details: error });
        }
      );
    });
    return p;
  }

  _postRaw(url, data, init, options = {}) {
    const p = new Promise((resolve, reject) => {
      this.auth.getTokenPromise().then(
        (token) => {
          const requestOptions = this.auth.request_options(token, options);
          const promise = this.client
            .post(url, data, requestOptions)
            .toPromise()
            .then((response: any) => {
              init(response, resolve);
            })
            .catch((error) => {
              console.warn(`Request Error . . . ${url}`, error);
              reject({ message: "request error", details: error });
            });
        },
        (error) => {
          console.error("Token Error", error);
          reject({ message: "token error", details: error });
        }
      );
    });
    return p;
  }

  _get(url, init, options = {}) {
    return new Promise((resolve, reject) => {
      this.auth.getTokenPromise().then(
        (token) => {
          const requestOptions = this.auth.request_options(token, options);
          this.client
            .get(url, requestOptions)
            .toPromise()
            .then((response: any) => {
              init(response, resolve);
            })
            .catch((error) => {
              console.warn(`Request Error . . . ${url}`, error);
              reject({ message: "request error", details: error });
            });
        },
        (error) => {
          console.error("Token Error", error);
          reject({ message: "token error", details: error });
        }
      );
    });
  }

  _delete(url, init, options = {}) {
    return new Promise((resolve, reject) => {
      this.auth.getTokenPromise().then(
        (token) => {
          const requestOptions = this.auth.request_options(token, options);
          this.client
            .delete(url, requestOptions)
            .toPromise()
            .then((response: any) => {
              init(response, resolve);
            })
            .catch((error) => {
              console.warn(`Request Error . . . ${url}`, error);
              reject({ message: "request error", details: error });
            });
        },
        (error) => {
          console.error("Token Error", error);
          reject({ message: "token error", details: error });
        }
      );
    });
  }
}
