import { ModalWidgetEnum, WidgetEnum } from "../../enums/widget";
import { NoizuStruct } from "../../noizu/structs/noizu-struct";
import { LacrosseEntity } from "../lacrosse.entity";
import { ElixirEntity } from "../elixir.entity";

export class ModalWidget extends NoizuStruct {
  public description: String = "";
  public embed_options: any = {};
  public embed_layout: String = "default";

  public processing = false;
  public modalRef: any = null;

  public title = "Create";
  public title_class = "";
  public message = "";
  public message_class = "";
  public subject: ElixirEntity | LacrosseEntity = null;
  public original: any = null;
  public state: any = {};
  public meta: any = {};
  public callbacks: any = {};
  public modal_type: ModalWidgetEnum = ModalWidgetEnum.MODAL_WIDGET__GENERIC;

  widget_type() {
    return WidgetEnum.EMBED_WIDGET__LOGICAL;
  }

  modal_widget_type(): ModalWidgetEnum | number | string | null {
    return this.modal_type;
  }

  public constructor(
    title,
    type,
    subject: any,
    options: any,
    layout: any,
    method_overrides: any = {}
  ) {
    super();
    this.title = title;
    this.modal_type = type;
    this.original = subject;
    this.subject = this.original ? this.original.deepCopy() : null;
    this.embed_options = options;
    this.embed_layout = layout || "default";

    let confirmName = "Close";
    let confirmAction = "Close";

    if (type == ModalWidgetEnum.MODAL_WIDGET__CREATE) {
      confirmName = "Create";
      confirmAction = "Saving";
    } else if (type == ModalWidgetEnum.MODAL_WIDGET__UPDATE) {
      confirmName = "Edit";
      confirmAction = "Updating";
    } else if (type == ModalWidgetEnum.MODAL_WIDGET__CONFIRM) {
      confirmName = options["confirmName"] || "Confirm";
      confirmAction = options["confirmAction"] || "...";
    }

    this.callbacks = {
      confirm: {
        msg: options["confirmName"] || confirmName,
        cb: (self, event, modalRef, toaster) => {
          if (
            type == ModalWidgetEnum.MODAL_WIDGET__CREATE ||
            type == ModalWidgetEnum.MODAL_WIDGET__UPDATE
          ) {
            toaster.pop("info", confirmAction, "Saving Changes.");
            if (self.subject) {
              let valid = self.subject.validation || self.subject.validate();
              valid.valid = true;
              if (!valid.valid) {
                toaster.pop("error", confirmAction, valid.error);
              } else {
                if (typeof self.subject.save === "function") {
                  self.subject
                    .save()
                    .then(() => {
                      toaster.pop(
                        "success",
                        confirmAction,
                        "Record has been saved."
                      );
                      modalRef.hide();
                    })
                    .catch((error: any) => {
                      console.log(error);
                      if (typeof error === "object")
                        toaster.pop("error", error.details.error.error.message);
                      else if (error.message)
                        toaster.pop("error", error.message);
                      else
                        toaster.pop(
                          "error",
                          "Something went wrong, please check the values or try again later."
                        );
                    });
                } else {
                  this.subject.update().then(() => {
                    toaster.pop(
                      "success",
                      confirmAction,
                      "Record has been saved"
                    );
                    modalRef.hide();
                  });
                }
              }
            } else {
              toaster.pop(
                "warning",
                confirmAction,
                "Null Subject?! - unexpected"
              );
            }
          } else {
            modalRef.hide();
          }
        },
      },
      delete: {
        msg: options["deleteName"] || "Delete",
        cb: (self, event, modalRef, toaster) => {
          let result = confirm("Delete Entity?");
          if (result) {
            toaster.pop("info", "Delete Record", "Removing Entity.");
            self.subject.delete().then(() => {
              toaster.pop("success", "Delete Record", "Complete.");
              modalRef.hide();
            });
          }
        },
      },
      cancel: {
        msg: options["cancelName"] || "Cancel",
        cb: (self, event, modalRef, toaster) => {
          modalRef.hide();
        },
      },
      revert: {
        msg: options["revertName"] || "Revert",
        cb: (self, event, modalRef, toaster) => {
          self.subject = self.original.deepCopy();
          toaster.pop("info", "Restored", "Changes Reverted");
        },
      },
    };

    if (options["confirm"] === false) this.callbacks["confirm"] = null;
    if (options["delete"] === false) this.callbacks["delete"] = null;
    if (options["cancel"] === false) this.callbacks["cancel"] = null;
    if (options["revert"] === false) this.callbacks["revert"] = null;

    for (let j in method_overrides) {
      this.callbacks[j] = method_overrides[j];
    }
  }
}
