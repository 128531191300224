import { Component, Inject, Input, NgZone, OnInit } from "@angular/core";
import { BsModalRef, BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import { AuthService } from "../../../services/auth.service";
import { FirebaseAuthService } from "../../../noizu/services/firebase-auth.service";
import { MCUFirmwareDefinitionRepo } from "../../../repos/mcu/firmware";
import { MCUFirmwareDefinitionEntity } from "../../../entities/mcu/firmware";
import { HttpClient } from "@angular/common/http";
import { ManufacturerListService } from "app/services/manufacturers.service";

@Component({
  templateUrl: "./list.component.html",
})
export class MCUFirmwareDefinitionListComponent {
  public entries: any; //Map<String, Array<FirmwareDefinitionEntity>>;
  public manufacturers: any; // Map<String, String>;
  public loaded = false;
  public selectedFirmware: any;
  public firmwareDefinitionModalRef: BsModalRef;
  public currentModal: any;
  public client: HttpClient;
  public auth: FirebaseAuthService;
  public newFirmwareDefinition: MCUFirmwareDefinitionEntity;
  public manufacturerSelectOptions: any = null;
  public widget_options = { edit: false, modal_form: true };
  public sidebar_options = { edit: false };
  public firmware_admin = false;
  public manufacturerName: any;

  public newFirmwareDefinitionModal = {
    title: "Add Firmware",
    msg: `Add new firmware definition.`,
    errorMsg: null,
    processing: false,
    form: "new-firmware",
    confirmMsg: "Save",
    confirm: () => {
      if (this.saveNewfirmware()) {
        this.firmwareDefinitionModalRef.hide();
      }
    },

    cancelMsg: "Cancel",
    cancel: () => {
      this.newFirmwareDefinitionModal.errorMsg = null;
      this.firmwareDefinitionModalRef.hide();
    },
  };

  constructor(
    public modalService: BsModalService,
    public repo: MCUFirmwareDefinitionRepo,
    public zone: NgZone,
    public authService: AuthService,
    public manufacturerListService: ManufacturerListService,
    @Inject(HttpClient) client: HttpClient,
    @Inject(FirebaseAuthService) auth: FirebaseAuthService
  ) {
    this.client = client;
    this.auth = auth;

    this.manufacturers = {};
    this.entries = {};
    this.manufacturerName = {};
    this.entries["14.V2"] = [];
    this.entries["14.V3"] = [];

    this.zone.run(() => {
      if (this.authService.loadedPromise) {
        this.authService.loadedPromise.then(() => {
          if (this.authService.userHasPermission("firmware_admin")) {
            this.firmware_admin = true;
            this.widget_options.edit = true;
            this.sidebar_options.edit = true;
          }
        });
      }

      this.manufacturerListService.getManufacturers().then((response) => {
        this.manufacturerSelectOptions = response.map((option) => ({
          value: option.identifier,
          label: option.name,
        }));
        response.forEach((manufacturer) => {
          this.manufacturerName[manufacturer.identifier] = manufacturer.name;
        });
      });
      this.repo.getListPromise().then((u: any) => {
        let items = u.items;
        const len = items.length;
        let man = {};
        for (let i = 0; i < len; i++) {
          const m = items[i].manufacturerId.toString() + "." + items[i].series;
          man[m] = true;
          if (this.manufacturers[m] == undefined) {
            this.manufacturers[m] = {
              id: m,
              name: this.manufacturerName[items[i].manufacturerId] || "Unknown",
              man: items[i].manufacturerId.toString(),
              series: items[i].series,
            };
            this.entries[m] = [];
          }
          this.entries[m].push(items[i]);
        }
        this.loaded = true;
        for (let key in man) {
          this.entries[key] = this.entries[key].sort((a, b) => {
            return this.sortVersion(a, b);
          });
        }
      });
    });
  }

  sortVersion(a, b) {
    let av = a.firmwareVersion.split(".");
    let bv = b.firmwareVersion.split(".");
    for (let i = 0; i < 3; i++) {
      let t = av[i].localeCompare(bv[i]);
      if (t) {
        return t;
      }
    }
    return 0;
  }

  selectFirmwareEntry(entry, event) {
    event.preventDefault();
    if (
      this.selectedFirmware &&
      this.selectedFirmware.identifier == entry.identifier
    ) {
      this.selectedFirmware = null;
    } else {
      this.selectedFirmware = entry;
    }
  }

  manufacturerList() {
    let l = [];
    for (let k in this.manufacturers) {
      l.push(this.manufacturers[k]);
    }
    return l;
  }

  saveNewfirmware() {
    this.newFirmwareDefinition
      .update()
      .then((r: MCUFirmwareDefinitionEntity) => {
        this.entries[r.manufacturerId].push(r);
      });
    return true;
  }

  add(modal, manufacturer, series, e) {
    e.preventDefault();
    this.newFirmwareDefinition = new MCUFirmwareDefinitionEntity(
      this.client,
      this.auth,
      {
        identifier: "new",
        manufacturerId: manufacturer,
        firmwareVersion: "0.0.0",
        size: 0,
        checksum: "123",
        series: series,
        fileA: "user1.bin",
        fileB: "user2.bin",
        notes: "New Firmware",
      }
    );
    this.currentModal = this.newFirmwareDefinitionModal;
    let config: ModalOptions = {
      backdrop: "static",
      keyboard: false,
    };
    this.firmwareDefinitionModalRef = this.modalService.show(modal, config);
  }
}
