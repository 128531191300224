import { Component, Input, Output, EventEmitter } from "@angular/core";
import { WidgetEventStruct } from "../../widget-event.struct";
import { ReportWidgetEnum } from "../../../enums";
import { WorkerReportEntity } from "../../../entities/report/worker-report.entity";

@Component({
  selector: "worker-report-widget",
  template: `
    <div class="card" *ngIf="entity">
      <div class="card-header">
        {{ entity.type }}
      </div>
      <div class="card-body m-1 p-1">
        <div class="row">
          <div class="col-2"><b>node:</b></div>
          <div class="col">
            {{ entity.total.online }}/{{ entity.total.nodes }}
          </div>
        </div>

        <div class="row">
          <div class="col-2"><b>workers:</b></div>
          <div class="col">
            {{ entity.total.active }}/{{ entity.total.specs }}
          </div>
        </div>

        <div class="row">
          <div class="col">
            <div class="card">
              <div class="card-body m-1 p-1">
                <div class="row mt-4">
                  <div class="col-3 border-right">
                    <div
                      class="row"
                      *ngFor="let node of objectKeys(entity.nodes)"
                    >
                      <div class="col">
                        <button
                          class="btn"
                          [ngClass]="{
                            'btn-primary': selected == node,
                            'btn-dark': selected != node
                          }"
                          (click)="selectNode($event, node)"
                        >
                          {{ node }}
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <ng-container *ngIf="selected">
                      <div class="row">
                        <div class="col-3"><b>online:</b></div>
                        <div class="col">
                          {{ entity.nodes[selected].online }}
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-3"><b>workers:</b></div>
                        <div class="col">
                          {{ entity.nodes[selected].active }}/{{
                            entity.nodes[selected].specs
                          }}
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-3"><b>response:</b></div>
                        <div class="col">
                          {{ entity.nodes[selected].response_time }}
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
})
export class WorkerReportWidgetComponent {
  @Input() entity: WorkerReportEntity | null = null;
  @Input() templateHint: string = null;
  @Input() options: any = null;
  @Input() layout = "standard";

  @Output() widgetEvent = new EventEmitter<WidgetEventStruct>();
  forwardEvent(widgetEvent: WidgetEventStruct) {
    this.widgetEvent.emit(widgetEvent);
  }

  public objectKeys = Object.keys;
  public selected = null;
  public selectNode(event, node) {
    event.preventDefault();
    this.selected = node;
  }

  public ReportWidgetEnum = ReportWidgetEnum;

  constructor() {}
}
