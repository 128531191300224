<page-header [section]="'entity-archive'">
  <page-title>Entity Archive</page-title>
  <page-blurb>View and manage entity archives.</page-blurb>
</page-header>

<spinner *ngIf="!entries"></spinner>

<div *ngIf="entries">
  <div *ngFor="let entry of entries.items" class="card">
    <div class="card-block">
      <pre>{{ entry.strippedRaw | json }}</pre>
      <div *ngIf="entry.archive" class="card">
        <h5 class="card-title">
          Archive
          <button
            class="btn-sm btn-primary"
            (click)="entry.view.archive = !entry.view.archive"
          >
            <i
              class="fa fa-sm"
              [ngClass]="{
                'fa-chevron-down': !entry.view.archive,
                'fa-chevron-up': entry.view.archive
              }"
            ></i>
          </button>
        </h5>
        <div *ngIf="entry.view.archive" class="card-block">
          <pre>{{ entry.archive | json }}</pre>
          <!-- @todo component -->
        </div>
      </div>
    </div>
  </div>
</div>
