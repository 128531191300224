<page-header [section]="'firmware-approvals'">
  <page-title>Approvals</page-title>
  <page-blurb> </page-blurb>
</page-header>

<spinner *ngIf="!entries"></spinner>

<div *ngIf="entries">
  <div class="m-0 p-0">
    <div class="card">
      <div class="card-block">
        <table class="div-table-th w-100 rounded mb-2 p-1">
          <thead class="w-100 row">
            <div class="col">Approval #</div>
            <div class="col">Reference</div>
            <div class="col">Comment</div>
            <div class="col">Approver</div>
            <div class="col">Approved On</div>
          </thead>
          <tbody class="w-100" *ngFor="let entry of entries">
            <widget-embed [entity]="entry" [options]="{}"> </widget-embed>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
