import { LogicalExtendedDeviceWidgetEnum } from "../../../enums";
import { ExtendedDeviceLogicalWidget } from "./extended-device-logical.widget";
import { HttpClient } from "@angular/common/http";
import { FirebaseAuthService } from "../../../noizu/services/firebase-auth.service";
import { DeviceDefinitionEntity } from "../definition.entity";
import { DeviceDefinitionRepo } from "../../../repos";

export class ChartReading {
  public variant: string | null = null;
  public spot_value: number | null;
  public tally_value: number | null;
  public average_value: number | null;
  public maximum_value: number | null;
  public minimum_value: number | null;
  public time: Date;

  constructor(json) {
    this.time = (json["u"] && new Date(json["u"] * 1000)) || null;
    this.spot_value = "s" in json ? json["s"] : null;
    this.tally_value = "t" in json ? json["t"] : null;
    this.average_value = "a" in json ? json["a"] : null;
    this.maximum_value = "h" in json ? json["h"] : null;
    this.minimum_value = "l" in json ? json["l"] : null;
  }
}

export class ChartFeed {
  public feed_coverage_start: Date;
  public feed_coverage_end: Date;
  public entries = new Array<ChartReading>();
  public variant_entries: boolean = false;
  public units: string | null = null;
  public metric: boolean;
  public fetching: boolean;
  public fetching_type: string;
  private set_cover = true;

  constructor(
    public key,
    public name,
    public device,
    public period,
    public time_zone,
    public field
  ) {
    this.feed_coverage_start = new Date();
    this.feed_coverage_end = this.feed_coverage_start;
    this.fetching = false;
    this.fetching_type = "initial";
  }

  public append_variant(
    variant,
    append_start,
    append_end,
    append_units,
    entries
  ) {
    this.units = this.units || append_units;
    let concat = new Array<ChartReading>();
    for (let i = 0; i < entries.length; i++) {
      let c = new ChartReading(entries[i]);
      c.variant = variant;
      concat.push(c);
    }
    concat = concat.sort((a, b) => b.time.getTime() - a.time.getTime());
    this.entries = this.entries.concat(concat);
    this.entries = this.entries.sort(
      (a, b) => b.time.getTime() - a.time.getTime()
    );
    if (append_start < this.feed_coverage_start || this.set_cover)
      this.feed_coverage_start = append_start;
    if (append_end > this.feed_coverage_end || this.set_cover)
      this.feed_coverage_end = append_end;
    this.set_cover = false;
  }

  public append(append_start, append_end, append_units, entries) {
    this.units = this.units || append_units;
    let concat = new Array<ChartReading>();
    for (let i = 0; i < entries.length; i++) {
      concat.push(new ChartReading(entries[i]));
    }
    concat = concat.sort((a, b) => b.time.getTime() - a.time.getTime());
    this.entries = this.entries.concat(concat);
    this.entries = this.entries.sort(
      (a, b) => b.time.getTime() - a.time.getTime()
    );
    if (append_start < this.feed_coverage_start || this.set_cover)
      this.feed_coverage_start = append_start;
    if (append_end > this.feed_coverage_end || this.set_cover)
      this.feed_coverage_end = append_end;
    this.set_cover = false;
  }
}

export class ExtendedDeviceChartEntity extends ExtendedDeviceLogicalWidget {
  public device_type: DeviceDefinitionEntity | null = null;
  public attributes: any = null;
  public feeds: any = null; //Map<string, ChartFeed> = null;

  constructor(
    public client: HttpClient,
    public auth: FirebaseAuthService,
    json
  ) {
    super();
    this.subject = json["device"];
    this.device_type = json["device_type"];
    if (this.device_type && !this.device_type.fieldEntries) {
      let dr = new DeviceDefinitionRepo(this.client, this.auth);
      dr.getEntityPromise(this.device_type.identifier).then(
        (device_type: DeviceDefinitionEntity) => {
          this.device_type = device_type;
        }
      );
    }

    this.attributes = json["attributes"];
    this.feeds = {}; // new Map<string, ChartFeed>();
  }

  public fetchFrom(range_to: Date, period: string, options) {
    const hour = 3600 * 1000;
    const day = hour * 24;
    const year = day * 365;
    let offset = 0;
    switch (period) {
      case "ai.ticks.1":
        offset = options["more"] ? 4 * hour : hour;
        break;
      case "ai.minutes.1":
        offset = options["more"] ? 4 * hour : hour;
        break;
      case "ai.minutes.5":
        offset = options["more"] ? 24 * hour : 5 * hour;
        break;
      case "ai.minutes.15":
        offset = options["more"] ? 4 * day : day;
        break;
      case "ai.minutes.30":
        offset = options["more"] ? 4 * day : day;
        break;
      case "ai.hours.1":
        offset = options["more"] ? 12 * day : 3 * day;
        break;
      case "ai.days.1":
        offset = options["more"] ? 90 * day : 30 * day;
        break;
      case "ai.weeks.1":
        offset = options["more"] ? 180 * day : 90 * day;
        break;
      case "ai.months.1":
        offset = options["more"] ? year : year;
        break;
      case "ai.years.1":
        offset = options["more"] ? 5 * year : hour;
        break;
    }
    return new Date(
      range_to.getTime() + (options["recent"] ? offset : -offset)
    );
  }

  public feedName(period, tz, metric, field) {
    let units = metric ? "(metric)" : "(imperial)";

    switch (period) {
      case "ai.ticks.1":
      case "ai.minutes.1":
      case "ai.minutes.5":
      case "ai.minutes.15":
      case "ai.minutes.30":
        return `${field} - ${period} ${units}`;
      default:
        return `${field} - ${period}@${tz} ${units}`;
    }
  }

  public feedKey(period, tz, metric, field) {
    let units = metric ? "m" : "i";
    switch (period) {
      case "ai.ticks.1":
      case "ai.minutes.1":
      case "ai.minutes.5":
      case "ai.minutes.15":
      case "ai.minutes.30":
        return `${period}.${field}.${units}.${this.subject}`;
      default:
        return `${period}.${tz}.${field}.${units}.${this.subject}`;
    }
  }

  public fetchFeed(period, tz, metric, field, options = {}) {
    let key = this.feedKey(period, tz, metric, field);
    if (key in this.feeds) {
      return this.extendFeed(period, tz, metric, field, options);
    } else {
      return this.initialFeed(period, tz, metric, field, options);
    }
  }

  public initialFeed(period, tz, metric, field, options = {}) {
    let key = this.feedKey(period, tz, metric, field);
    let name = this.feedName(period, tz, metric, field);
    let feed = new ChartFeed(key, name, this.subject, period, tz, field);
    let range_end = options["to"] || new Date();
    let range_start =
      options["from"] || this.fetchFrom(range_end, period, options);
    feed.fetching = true;
    feed.metric = metric;
    feed.fetching_type = "initial";
    this.feeds[key] = feed;
    return this.fetchEntries(
      range_start,
      range_end,
      period,
      tz,
      metric,
      field,
      this.subject
    );
  }

  public extendFeed(period, tz, metric, field, options = {}) {
    let key = this.feedKey(period, tz, metric, field);
    let feed = this.feeds[key];
    let range_end = null;
    let range_start = null;
    if (options["recent"]) {
      range_start = options["from"] || feed.feed_coverage_end;
      range_end = options["to"] || this.fetchFrom(range_start, period, options);
      let now = new Date();
      range_end = range_end.getTime() < now.getTime() ? range_end : now;
    } else {
      range_end = options["to"] || feed.feed_coverage_start;
      range_start =
        options["from"] || this.fetchFrom(range_end, period, options);
    }
    feed.fetching = true;
    feed.fetching_type = "older";

    return this.fetchEntries(
      range_start,
      range_end,
      period,
      tz,
      metric,
      field,
      this.subject
    );
  }

  public fetchEntries(
    range_start,
    range_end,
    period,
    tz,
    metric,
    field,
    device
  ) {
    let from_ts = Math.ceil(range_start.getTime() / 1000);
    let to_ts = Math.floor(range_end.getTime() / 1000);

    let units = metric ? "m" : "i";

    if (this.attributes["api"] == "2") {
      let url = `${this._ingv_endpoint}/v1.3/active-user/device-association/${device}/feed?tz=${tz}&from=${from_ts}&aggregates=${period}&fields=${field}&to=${to_ts}&null_values=true&units=${units}`;
      return this._get(url, (data, resolve) => {
        let key = this.feedKey(period, tz, metric, field);
        let feed = this.feeds[key];

        // Special Case Variants
        let variant_type = false;
        if (this.device_type.fieldEntriesMap[field]) {
          variant_type =
            this.device_type.fieldEntriesMap[field].field.is_variant_type;
        }

        feed.variant_entries = false;
        feed.fetching = false;
        if (variant_type) {
          let sentinel = false;
          for (let key in data[device][period]["fields"]) {
            if (key.startsWith(field)) {
              sentinel = true;
              feed.append_variant(
                key,
                range_start,
                range_end,
                data[device][period]["fields"][key].unit,
                data[device][period]["fields"][key].values
              );
              feed.variant_entries = true;
            }
          }
          if (!sentinel) {
            feed.append(range_start, range_end, null, []);
          }
        } else {
          if (data[device][period]["fields"][field]) {
            feed.append(
              range_start,
              range_end,
              data[device][period]["fields"][field].unit,
              data[device][period]["fields"][field].values
            );
          } else {
            feed.append(range_start, range_end, null, []);
          }
        }

        resolve(feed);
      });
    } else {
      let url = `${this.ingvEndpoint()}/active-user/device-association/${device}/feed?tz=${tz}&from=${from_ts}&aggregates=${period}&fields=${field}&to=${to_ts}&null_values=true&units=${units}`;
      return this._get(url, (data, resolve) => {
        let key = this.feedKey(period, tz, metric, field);
        let feed = this.feeds[key];

        feed.fetching = false;
        if (data[device][period]["fields"][field]) {
          feed.append(
            range_start,
            range_end,
            data[device][period]["fields"][field].unit,
            data[device][period]["fields"][field].values
          );
        } else {
          feed.append(range_start, range_end, null, []);
        }
        resolve(feed);
      });
    }
  }

  logical_extended_device_widget_type() {
    return LogicalExtendedDeviceWidgetEnum.LOGICAL_EXTENDED_DEVICE_WIDGET__CHART;
  }
}
