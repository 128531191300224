import { HttpClient } from "@angular/common/http";
import { LacrosseEntity } from "./lacrosse.entity";
import { FirebaseAuthService } from "../noizu/services/firebase-auth.service";
import { AlertRepo } from "../repos/alert.repo";
import { GroupRepo } from "../repos/group.repo";
import { UserLocationRepo } from "../repos/user/location.repo";
import { UserDeviceAssociationRepo } from "../repos/user/device-association.repo";
import { UserChannelRepo } from "../repos/user/channel.repo";
import { UserNotificationRepo } from "../repos/user/notification.repo";
import { UserAlertEventRepo } from "../repos/user/alert-event.repo";
import { ApiService } from "../services/api.service";
import { WidgetEnum } from "../enums/widget";
import { EntityLogSet } from "./log";
import { UserSettingsRepo } from "../repos/user/settings.repo";
import { AppengineEntityList } from "../noizu/structs/appengine-entity-list";
import { ExtendedUserDetails } from "../structs/user/extended-user-details";

export class UserEntity extends LacrosseEntity {
  public _kind = "user";
  public _sref_module = "user";
  public api;

  public extended: ExtendedUserDetails = null;

  public display_name: string;
  public email: string;
  public first_name: string;
  public last_name: string;
  public mobile: string;
  public group_id: number;
  public isStaff: boolean = false;
  public deleteable = false;
  public weightTree = {};
  public channelSettings = { raw: null, missing: null };
  public settings;
  public adminPermissions = {};
  public logs: EntityLogSet = null;
  public _nested = {
    group: null,
    locations: null,
    deviceAssociations: null,
    channels: null,
    notifications: { data: null, status: null },
    alerts: null,
    alertEvents: null,
    subs: null,
    subsEffective: null,
  };
  public subscription_level = null;

  constructor(client: HttpClient, public auth: FirebaseAuthService, json) {
    super(client, auth, json);
    this.strip_from_json["_nested"] = 1;
    this.strip_from_json["logs"] = 1;
    this.strip_from_json["extended"] = 1;

    this.api = new ApiService(client);
    this.isStaff = json["isStaff"];
    this.logs = new EntityLogSet(this.client, this.auth, null, []);
    this.extended = new ExtendedUserDetails(client, auth, json);
  }

  clone() {
    return Object.assign(new UserEntity(this.client, this.auth, {}), this);
  }

  filter(filter: string) {
    if (filter) {
      filter = filter.toLowerCase();
      if (this.meta["filter"] == filter) return this.meta["filter_result"];

      // Prep Lower Case Data
      if (!this.meta["filter_set"]) {
        this.meta["filter_set"] = {};
        this.meta["filter_set"]["first_name"] = this.first_name
          ? this.first_name.toLowerCase()
          : "";
        this.meta["filter_set"]["last_name"] = this.last_name
          ? this.last_name.toLowerCase()
          : "";
        this.meta["filter_set"]["display_name"] = this.display_name
          ? this.display_name.toLowerCase()
          : "";
        this.meta["filter_set"]["email"] = this.email
          ? this.email.toLowerCase()
          : "";
      }

      this.meta["filter"] = filter;
      this.meta["filter_result"] = false;
      if (this.meta.filter_set.first_name.includes(filter))
        this.meta["filter_result"] = true;
      else if (this.meta.filter_set.last_name.includes(filter))
        this.meta["filter_result"] = true;
      else if (this.meta.filter_set.display_name.includes(filter))
        this.meta["filter_result"] = true;
      else if (this.meta.filter_set.email.includes(filter))
        this.meta["filter_result"] = true;
      return this.meta.filter_result;
    } else {
      return true;
    }
  }

  refresh(data) {
    let sr = super.refresh(data);
    this.display_name = data["displayName"] || data["display_name"];
    this.email = data["email"];
    this.mobile = data["mobile"];
    this.first_name =
      data["firstName"] ||
      data["first_name"] ||
      (data["name"] && data.name.first);
    this.last_name =
      data["lastName"] || data["last_name"] || (data["name"] && data.name.last);
    this.group_id = data["groupId"] || data["group_id"];
    this.isStaff = data["isStaff"];
    return sr;
  }

  savePromise() {
    let payload = {
      id: this.identifier,
      email: this.email.trim(),
      displayName: this.display_name.trim(),
      firstName: this.first_name.trim(),
      lastName: this.last_name.trim(),
      mobile: this.mobile,
      groupId: this.group_id,
      isStaff: this.isStaff,
    };
    return this._put(
      `${this.apiBase()}/user/${this.identifier}`,
      payload,
      (response, resolve) => {
        resolve(new UserEntity(this.client, this.auth, response));
      },
      {}
    );
  }

  updateEmail() {
    return this.api.updateEmail(this.identifier, this.display_name);
  }

  testChannel(cid) {
    return this._put(
      `${this.ingvEndpoint()}/users/${this.identifier}/channels/f${cid}/test`,
      {},
      (response, resolve) => {
        console.log(response);
        resolve(response);
      }
    ).then((ret) => {
      console.log(ret);
    });
  }

  getLocationsPromise() {
    let locationRepo = new UserLocationRepo(this.client, this.auth);
    // @ts-ignore
    let p = locationRepo.getUserListPromise(this.identifier);
    p.then((entries) => {
      this._nested.locations = entries;
      this.locationAssociation();
    }).catch(() => {
      this._nested.locations = { error: true };
    });
    return p;
  }

  getDeviceAssociationsPromise() {
    let associationRepo = new UserDeviceAssociationRepo(this.client, this.auth);
    // @ts-ignore
    let p = associationRepo.getUserListPromise(this.identifier);
    p.then((entries) => {
      this._nested.deviceAssociations = entries;
      if (entries["items"].length === 0) {
        this.deleteable = true;
      }
      this.locationAssociation();
    }).catch(() => {
      this._nested.deviceAssociations = { error: true };
    });
    return p;
  }

  hasPermission(permission) {
    return this.adminPermissions[permission] === "granted";
  }

  getAdminPermissionsPromise() {
    const url = `${this.ingvEndpoint()}/admin/permissions`;
    return this._get(url, (data, resolve) => {
      if (data["effective_permissions"]) {
        this.adminPermissions = data["effective_permissions"];
      }
      resolve(data);
    });
  }

  getUserSettingsPromise() {
    const url = `${this.apiBase()}/user/${this.identifier}/settings`;
    return this._get(url, (data, resolve) => {
      const settings = {};
      if (data["settings"]) {
        for (const key in data.settings) {
          const value = data.settings[key];
          settings[value.setting] = value;
        }
      }
      this.settings = settings;
      resolve(this.settings);
    });
  }

  getGroupPromise() {
    let groupRepo = new GroupRepo(this.client, this.auth);
    let p = groupRepo.getEntityPromise(this.group_id);
    p.then((group) => {
      this._nested.group = group;
    });
    return p;
  }

  getAlertsPromise() {
    return this.extended.getAlertsPromise();
  }

  getAlertStatePromise(alert) {
    let alertRepo = new AlertRepo(this.client, this.auth);
    let p = alertRepo.getAlertStatePromise(alert.identifier);
    p.then((entries) => {
      alert.state = JSON.parse(JSON.stringify(entries));
      alert.expanded = true;
    });
    return p;
  }

  getNotificationsPromise() {
    let notificationRepo = new UserNotificationRepo(this.client, this.auth);
    // @ts-ignore
    let p = notificationRepo.getUserListPromise(this.identifier);
    p.then((entries) => {
      this._nested.notifications.data = entries;
    });
    return p;
  }

  getNotificationStatus() {
    this._nested.notifications.status = { loading: true };
    let notificationRepo = new UserNotificationRepo(this.client, this.auth);
    // @ts-ignore
    let p = notificationRepo.getNotificationsStatus(this.identifier);
    p.then((entries) => {
      this._nested.notifications.status = { show: true, data: entries };
    }).catch(() => {
      this._nested.notifications.status = null;
    });
    return p;
  }

  deleteNotification(nid) {
    let notificationRepo = new UserNotificationRepo(this.client, this.auth);

    return new Promise((resolve) => {
      // @ts-ignore
      notificationRepo.deleteNotification(this.identifier, nid).then((res) => {
        this.getNotificationsPromise().then();
        resolve(res);
      });
    });
  }

  getUserSettingsPromiseV2() {
    let repo = new UserSettingsRepo(this.client, this.auth);
    this.meta["settings"] = this.meta["settings"] || {
      loading: true,
      list: null,
    };
    // @ts-ignore
    let p = repo.getUserListPromise(this.identifier);
    p.then((set) => {
      this.meta.settings.loading = false;
      this.meta.settings.list = set;
    });
    return p;
  }

  getChannelsPromise() {
    if (!this.meta["settings"] || !this.meta["settings"]["list"]) {
      let p2 = this.getUserSettingsPromiseV2().then(() => {
        let lu = {};
        this.meta.settings.list.settings.forEach((v) => {
          if (v.setting == "DefaultEmailChannel") {
            lu[v.setting] = v.value;
          }
          if (v.setting == "DefaultPushChannel") {
            lu[v.setting] = v.value;
          }
          if (v.setting == "DefaultSmsChannel") {
            lu[v.setting] = v.value;
          }
        });

        let channelRepo = new UserChannelRepo(this.client, this.auth);
        this.meta["channels"] = this.meta["channels"] || {
          loading: true,
          list: null,
        };
        // @ts-ignore
        let p = channelRepo.getUserListPromise(this.identifier);
        p.then((set: AppengineEntityList) => {
          this.meta.channels.loading = false;
          for (let index = 0; index < set.items.length; ++index) {
            if (`${set.items[index].identifier}` == lu["DefaultEmailChannel"]) {
              set.items[index].note = "*DefaultEmailChannel";
            }
            if (`${set.items[index].identifier}` == lu["DefaultPushChannel"]) {
              set.items[index].note = "*DefaultPushChannel";
            }
            if (`${set.items[index].identifier}` == lu["DefaultSmsChannel"]) {
              set.items[index].note = "*DefaultSmsChannel";
            }
          }

          this.meta.channels.list = set;
        });
      });
      return p2;
    } else {
      let lu = {};
      this.meta.settings.list.settings.forEach((v) => {
        if (v.setting == "DefaultEmailChannel") {
          lu[v.setting] = v.value;
        }
        if (v.setting == "DefaultPushChannel") {
          lu[v.setting] = v.value;
        }
        if (v.setting == "DefaultSmsChannel") {
          lu[v.setting] = v.value;
        }
      });

      let channelRepo = new UserChannelRepo(this.client, this.auth);
      this.meta["channels"] = this.meta["channels"] || {
        loading: true,
        list: null,
      };
      // @ts-ignore
      let p = channelRepo.getUserListPromise(this.identifier);
      p.then((set: AppengineEntityList) => {
        this.meta.channels.loading = false;
        for (let index = 0; index < set.items.length; ++index) {
          if (`${set.items[index].identifier}` == lu["DefaultEmailChannel"]) {
            set.items[index].note = "*DefaultEmailChannel";
          }
          if (`${set.items[index].identifier}` == lu["DefaultPushChannel"]) {
            set.items[index].note = "*DefaultPushChannel";
          }
          if (`${set.items[index].identifier}` == lu["DefaultSmsChannel"]) {
            set.items[index].note = "*DefaultSmsChannel";
          }
        }
        this.meta.channels.list = set;
      });
      return p;
    }
  }

  getAlertEventsRecentSimplifiedPromise() {
    let eventRepo = new UserAlertEventRepo(this.client, this.auth);
    // @ts-ignore
    let p = eventRepo.getUserRecentSimplifiedListPromise(this.identifier);
    p.then((entries) => {
      this._nested.alertEvents = entries;
    });
    return p;
  }

  locationAssociation() {
    if (
      this._nested.deviceAssociations &&
      this._nested.deviceAssociations.error == null &&
      this._nested.locations &&
      this._nested.locations.error == null
    ) {
      let tempTree = {};
      let sortTree = [];
      let pos = 1;
      let lastInLoc = null;
      for (let loc of this._nested.locations.items) {
        let node = { pos: pos, devs: [] };
        loc.showExtra = false;
        loc.newPos = null;
        loc.position = pos;
        pos++;
        let pos2 = 1;
        if (lastInLoc != null) {
          lastInLoc.last = true;
        }
        for (let assoc of this._nested.deviceAssociations.items) {
          if (loc.identifier == assoc.raw.locationId) {
            lastInLoc = assoc;
            node.devs.push({ name: assoc.raw.name, pos: pos2 });
            assoc.pos = pos2;
            pos2++;
            assoc.locationName = loc.raw.name;
            loc.linkedDevices.push([assoc.raw.name]);
            assoc.location_id = loc.identifier;
            sortTree.push(assoc);
          }
        }
        tempTree[loc.raw.name] = node;
      }
      if (lastInLoc != null) {
        lastInLoc.last = true;
      }
      this.weightTree = tempTree;
      this._nested.deviceAssociations.items = sortTree;
    }
  }

  addEmptyDeviceAssociation() {
    let assocRepo = new UserDeviceAssociationRepo(this.client, this.auth);
    this._nested.deviceAssociations.items.push(
      assocRepo.entity({ identifier: "new" })
    );
  }

  addEmptyLocation() {
    let locationRepo = new UserLocationRepo(this.client, this.auth);
    this._nested.locations.items.push(
      locationRepo.entity({ identifier: "new", ownerId: this.identifier })
    );
  }

  getSubscriptionDetailsPromise() {
    return this.extended.getSubscriptionDetailsPromise().then(() => {
      this.meta.subscription_details_loaded = true;
    });
  }

  getUserSubcriptionInfo() {
    const url = `${this.ingvEndpoint()}/users/${
      this.identifier
    }/subscriptions?expand-refs=sub-payment`;
    return this._get(url, (data, resolve) => {
      let s = null;
      let subscription = data.response;
      if (subscription.subscriptions.length === 0) {
        this._nested.subs = null;
        resolve("Not Subscribed");
        return;
      }
      for (let sub of subscription.subscriptions) {
        if (sub.subscription_status == "active") {
          s = { ...sub };
        }
      }
      if (!s) {
        this.subscription_level = "Not Subscribed";
        resolve("Not Subscribed");
        return;
      }
      if (s.subscription_status != "active") {
        this._nested.subs = null;
        resolve("Not Subscribed");
        return;
      }
      s["edit"] = false;
      s["isNew"] = false;
      s["highlighted"] = false;
      s["typeName"] = this.getSubscriptionName(s.subscription_type);
      this.subscription_level = s["typeName"];
      s["raw"] = JSON.parse(JSON.stringify(s));
      s["sort"] = Date.parse(s["ufse"]);
      this._nested.subs = s;
      resolve(s["typeName"]);
    });
  }

  getSubscriptionName(subscription_type) {
    switch (subscription_type) {
      case "ref.subscription.5662168018059264":
        return "Trial";
      case "ref.subscription.5707762182914048":
        return "Enhanced";
      case "ref.subscription.4539310847807488":
        return "Enhanced";
      case "ref.subscription.5361034439163904":
        return "SMS";
      case "ref.subscription.5737432106127360":
        return "SMS";
      case "ref.subscription.4620268229873664":
        return "Advanced";
      case "ref.subscription.5749285255643136":
        return "Advanced";
      case "ref.subscription.4520429903138816":
        return "Plus";
      case "ref.subscription.5162562333245440":
        return "Plus";
      default:
        return "Not Subscribed";
    }
  }

  getUserEffectiveSubscription() {
    return this._get(
      this.ingvEndpoint() + `/users/${this.identifier}/subscriptions/effective`,
      (response, resolve) => {
        this._nested.subsEffective = response;
        resolve(true);
      },
      {}
    );
  }

  saveSubscription(sub) {
    return new Promise((resolve) => {
      this.api.postSubscriptions(sub).then((res) => {
        this.getUserEffectiveSubscription();
        resolve(res);
      });
    });
  }

  deleteSubscription(sub) {
    return new Promise((resolve) => {
      this.api
        .deleteSubscription(sub)
        .then((res) => {
          console.log(res);
          this.getUserSubcriptionInfo();
          this.getUserEffectiveSubscription();
          resolve(true);
        })
        .catch((err) => {
          console.log(err);
          this.getUserSubcriptionInfo().then();
          this.getUserEffectiveSubscription().then();
        });
    });
  }

  deleteUser() {
    return new Promise((resolve) => {
      // @ts-ignore
      this.api.deleteAccount(this.identifier).then(() => {
        resolve(
          this._delete(
            `${this.apiBase()}/user/${this.identifier}`,
            (response2, resolve2) => {
              resolve2(response2);
            },
            {}
          )
        );
      });
    });
  }

  searchFirebase(e) {
    return new Promise((resolve) => {
      this.api.checkEmail(e).then((res) => {
        resolve(res);
      });
    });
  }

  widget_type() {
    return WidgetEnum.EMBED_WIDGET__USER_ENTITY;
  }
} // end Package
