
    <ng-container *ngIf="entity">
      <div class="card">
        <div class="card-header">
          [IF-THEN]
          <i class="float-right fa fa-plus" (click)="expand = !expand"></i>
        </div>
        <div class="card-body" *ngIf="expand">
          <ng-container *ngIf="entity.condition_clause">
            <div class="row">
              <div class="col"><strong>IF</strong></div>
            </div>
            <div class="row">
              <div class="col">
                <email-template-section-widget
                  [entity]="entity.condition_clause"
                  [layout]="layout"
                  [options]="options"
                ></email-template-section-widget>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="entity.then_clause">
            <div class="row">
              <div class="col"><strong>THEN</strong></div>
            </div>
            <div class="row">
              <div class="col">
                <email-template-section-widget
                  [entity]="entity.then_clause"
                  [layout]="layout"
                  [options]="options"
                ></email-template-section-widget>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="entity.else_clause">
            <div class="row">
              <div class="col"><strong>ELSE</strong></div>
            </div>
            <div class="row">
              <div class="col">
                <email-template-section-widget
                  [entity]="entity.else_clause"
                  [layout]="layout"
                  [options]="options"
                ></email-template-section-widget>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="entity.selectors && entity.selectors.length > 0">
            <div class="row">
              <div class="col"><strong>Selectors</strong></div>
            </div>
            <div class="row" *ngFor="let selector of entity.selectors">
              <div class="col">
                <email-template-section-widget
                  [entity]="selector"
                  [layout]="layout"
                  [options]="options"
                ></email-template-section-widget>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </ng-container>
  