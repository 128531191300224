import { Component, Input, Output, EventEmitter } from "@angular/core";
import { LogicalDeviceWidgetEnum } from "../../../enums/widget";
import { DeviceLogicalWidget } from "../../../entities/device/logical-widget";
import { LogicalWidgetEventStruct } from "./logical-widget-event.struct";

@Component({
  selector: "device-logical-widget",
  template: `
    <div *ngIf="!entity">
      <widget-spinner></widget-spinner>
    </div>
    <div *ngIf="entity">
      <div [ngSwitch]="entity.logical_device_widget_type()">
        <device-feed-widget
          *ngSwitchCase="WidgetType.LOGICAL_DEVICE_WIDGET__FEED"
          (widgetEvent)="forwardEvent($event)"
          [entity]="entity"
          [options]="options"
          [layout]="layout"
        ></device-feed-widget>
        <device-reading-widget
          *ngSwitchCase="WidgetType.LOGICAL_DEVICE_WIDGET__READINGS"
          (widgetEvent)="forwardEvent($event)"
          [entity]="entity"
          [options]="options"
          [layout]="layout"
        ></device-reading-widget>
        <device-simulator-widget
          *ngSwitchCase="WidgetType.LOGICAL_DEVICE_WIDGET__SIMULATOR"
          (widgetEvent)="forwardEvent($event)"
          [entity]="entity"
          [options]="options"
          [layout]="layout"
        ></device-simulator-widget>
        <device-field-simulator-widget
          *ngSwitchCase="WidgetType.LOGICAL_DEVICE_WIDGET__FIELD_SIMULATOR"
          (widgetEvent)="forwardEvent($event)"
          [entity]="entity"
          [options]="options"
          [layout]="layout"
        ></device-field-simulator-widget>
        <device-set-logical-widget
          *ngSwitchCase="WidgetType.LOGICAL_DEVICE_WIDGET__GROUP"
          (widgetEvent)="forwardEvent($event)"
          [entity]="entity"
          [options]="options"
          [layout]="layout"
        ></device-set-logical-widget>
        <!-- Default -->
        <div
          class="card"
          *ngSwitchDefault
          [ngClass]="{ 'mb-0': options?.nested }"
        >
          <div class="card-header" [ngClass]="{ 'p-2 m-0': options?.nested }">
            LogicalDeviceWidget:
            {{ WidgetType[entity.logical_device_widget_type()] }}
          </div>
          <div class="card-body">
            <pre>{{ entity.toJson() | json }}</pre>
          </div>
        </div>
      </div>
    </div>
  `,
})
export class DeviceLogicalWidgetComponent {
  @Input() entity: DeviceLogicalWidget | null = null;
  @Input() templateHint: string = null;
  @Input() options: any = null;
  @Input() layout = "standard";
  @Output() widgetEvent = new EventEmitter<LogicalWidgetEventStruct>();
  forwardEvent(e, widgetEvent: LogicalWidgetEventStruct) {
    e.preventDefault();
    this.widgetEvent.emit(widgetEvent);
  }
  public WidgetType = LogicalDeviceWidgetEnum;

  constructor() {}
}
