<!-- JS Includes -->
<!-- JS Includes -->
<script src="node_modules/chart.js/src/chart.js"></script>

<!-- Widget Spinner -->

<new-spinner
  *ngIf="(!device || notification?.showLoading) && !devError"
  [size]="'lg'"
></new-spinner>

<!-- Notifications -->
<div *ngIf="notification.message.length > 0" class="row">
  <div class="row">
    <div
      class="alert"
      [ngClass]="{
        'alert-info': !notification.iserror,
        'alert-danger': notification.iserror
      }"
      (click)="hideMessage()"
    >
      {{ notification.message }}. Click to close
    </div>
  </div>
</div>

<!-- Reload -->
<div *ngIf="devError && device?.type" class="row">
  <div class="col-12">
    Device loading failed,
    <button class="btn-sm btn-primary" (click)="reloadDevice()">
      Try Again
    </button>
  </div>
</div>

<!-- Main Component -->
<div *ngIf="device">
  <!-- Back Button @todo should be reusable component -->
  <div class="row my-3">
    <div class="col-1"></div>
    <div class="col-6">
      <button (click)="back($event)" class="btn btn-primary">
        Back to Device Search
      </button>
    </div>
    <div class="col-5" *ngIf="backUserID">
      <button (click)="backUser($event)" class="btn btn-primary float-right">
        Back to previous User Page
      </button>
    </div>
  </div>

  <!-- Rain Reset -->
  <div class="row" *ngIf="showResetRain">
    <div class="col-1"></div>
    <div class="col-11">
      <div class="card">
        <div class="card-header">
          Reset Rain. Select a period of time to reset rain readings
          <button
            (click)="showResetRain = false"
            class="btn btn-primary pull-right"
          >
            Hide
          </button>
        </div>
        <div class="card-block">
          <div class="row">
            <div class="col-12 my-3">
              Start 1 year ago:
              <input
                type="checkbox"
                [checked]="rr.startDefault"
                (change)="rr.startDefault = !rr.startDefault"
              />
            </div>
            <div class="col-12" *ngIf="!rr.startDefault">
              Start from:
              <input
                class=""
                name="rrStartVal"
                placeholder="Number"
                type="number"
                [(ngModel)]="rr.startVal"
              />
              <select
                class="form-control"
                name="rrStartInt"
                [(ngModel)]="rr.startInt"
              >
                <option value="h">Hours</option>
                <option value="d">Days</option>
              </select>
            </div>
            <br />
            <div class="col-12 my-3">
              End at Now:
              <input
                type="checkbox"
                [checked]="rr.endDefault"
                (change)="rr.endDefault = !rr.endDefault"
              />
            </div>
            <div class="col-12" *ngIf="!rr.endDefault">
              End at Custom time:
              <input
                class=""
                name="rrendVal"
                placeholder="Number"
                type="number"
                [(ngModel)]="rr.endVal"
              />
              <select
                class="form-control"
                name="rrEndInt"
                [(ngModel)]="rr.endInt"
              >
                <option value="h">Hours</option>
                <option value="d">Days</option>
              </select>
            </div>
            <div class="col-12 my-3">
              <button
                (click)="resetRain(confirm, $event)"
                class="btn btn-danger pull-left"
              >
                Clear Rain in interval
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Main Card Row -->
  <div class="row">
    <div class="col-1"></div>
    <div class="col-11">
      <div class="card">
        <!-- Admin Toolbar -->
        <div class="card-header">
          <div class="row">
            <div class="col-1">
              <i
                class="fa fa-thumb-tack"
                [ngClass]="{
                  'fa-rotate-135 text-black-50': !pinned,
                  'fa-rotate--45': pinned
                }"
                (click)="pinDevice($event, pageModal)"
              ></i>
            </div>
            <div class="col">
              <strong
                >Device: Serial Number: {{ device.serial }}, Series:
                {{ device.series }}</strong
              >
              <div class="float-right">
                <span
                  (click)="factoryReset(confirm, device, $event)"
                  class="badge badge-danger fakelink"
                  >Factory Reset</span
                >
                <span
                  (click)="permissionReset(confirm, device, $event)"
                  class="badge badge-warning fakelink"
                  >Set Claimable</span
                >
                <span
                  *ngIf="device.hasRain"
                  (click)="showResetRain = true"
                  class="badge badge-danger fakelink"
                  >Reset Rain</span
                >
              </div>
            </div>
          </div>
        </div>

        <!-- Device Show Card -->
        <div class="card-body">
          <!-- Device Status Blurb -->
          <div class="row">
            <div class="col m-1 pl-4 pr-4">
              <widget
                [entity]="device.extended.reading"
                [layout]="'status'"
                [options]="{}"
              ></widget>
            </div>
          </div>
          <!-- end device status row -->

          <!-- New Layout -->
          <div class="row">
            <!-- Layout Control -->
            <div class="col-12 col-md-6 col-lg-6 col-xl-4">
              <div class="row">
                <div
                  class="container min-vh-70"
                  dragula="HANDLES"
                  id="left-drag-section"
                >
                  <div
                    class="col-12"
                    *ngIf="dragula_sections['left-drag-section'] == 0"
                  >
                    <div class="jumbotron vh-70">&nbsp;</div>
                  </div>

                  <!-- Device Card -->
                  <div class="col-12">
                    <div class="card border-primary">
                      <div class="card-header bg-primary">
                        <b>Device:</b> {{ device.serial
                        }}<span *ngIf="device.verificationCode"
                          >-{{ device.verificationCode }}</span
                        >
                        <div class="card-header-actions">
                          <span class="dragula-handle"
                            ><i
                              class="dragula-handle text-black-50 fa fa-lg fa-arrows"
                            ></i
                          ></span>
                        </div>
                      </div>
                      <div class="cord-body p-2">
                        <!-- Serial -->
                        <div class="row mb-2">
                          <div class="col"><b>Serial:</b></div>
                          <div class="col">
                            <div class="float-left">{{ device.serial }}-</div>
                            <input
                              style="width: 6em"
                              class="form-control float-left verCodeFont"
                              id="edit-handle"
                              placeholder="XXX"
                              type="text"
                              [(ngModel)]="device.verificationCode"
                              autocomplete="off"
                            />
                            <button
                              (click)="saveCode(confirm, $event)"
                              class="btn btn-danger float-left"
                            >
                              <i class="fa fa-save"></i>
                            </button>
                          </div>
                        </div>

                        <!-- Device Type Name -->
                        <div class="row mb-2" *ngIf="device?.type">
                          <div class="col-4"><b>Type:</b></div>
                          <div class="col">{{ device.type.internalName }}</div>
                        </div>

                        <!-- Device Category -->
                        <div class="row mb-2">
                          <div class="col-4"><b>Category:</b></div>
                          <div class="col">{{ device.category }}</div>
                        </div>

                        <!-- Appengine Identifier -->
                        <div class="row mb-2">
                          <div class="col-4"><b>Identifier:</b></div>
                          <div class="col">{{ device.identifier }}</div>
                        </div>

                        <!-- Batch -->
                        <div class="row mb-2">
                          <div class="col-4"><b>Batch:</b></div>

                          <div *ngIf="device_admin" class="col">
                            <widget
                              *ngIf="device"
                              [entity]="batchSelect"
                              (widgetEvent)="handleEvent($event)"
                              [options]="{ edit: true }"
                            ></widget>
                            <button
                              *ngIf="device && device.meta?.batch_change"
                              (click)="saveBatch(confirm, $event)"
                              class="btn btn-danger float-left"
                            >
                              <i class="fa fa-save"></i>
                            </button>
                          </div>

                          <div *ngIf="!device_admin" class="col">
                            {{ device.batch }}
                          </div>
                        </div>

                        <!-- Claimable -->
                        <div class="row mb-2" *ngIf="claimable != null">
                          <div class="col-4"><b>Claimable:</b></div>
                          <div class="col">{{ claimable }}</div>
                        </div>

                        <!-- Geo -->
                        <div class="row mb-3" *ngIf="device.geo">
                          <div class="col-4">
                            <b>
                              <ng-container *ngIf="device?.isfact23"
                                >Legacy Geo:</ng-container
                              >
                              <ng-container *ngIf="!device?.isfact23"
                                >Accu. Geo:</ng-container
                              >
                            </b>
                          </div>

                          <div class="col">
                            <ng-container *ngIf="!device.geo?.anonymous">
                              <render-object
                                [object]="device.geo"
                                [exclude]="['anonymous']"
                              ></render-object>
                            </ng-container>
                            <ng-container *ngIf="device.geo?.anonymous">
                              <div>Anonymous</div>
                            </ng-container>
                          </div>
                        </div>

                        <div class="row mb-2" *ngIf="device.internals">
                          <div class="col-4"><b>Created On</b></div>
                          <div class="col">
                            {{
                              device.createdOn | date : "MM/dd/yyyy @ h:mm:ssa"
                            }}
                          </div>
                        </div>

                        <div class="row mb-2" *ngIf="device.internals">
                          <div class="col-4"><b>Modified On</b></div>
                          <div class="col">
                            {{
                              device.modifiedOn | date : "MM/dd/yyyy @ h:mm:ssa"
                            }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- End Device Card -->

                  <!-- Device Attributes -->
                  <div class="col-12">
                    <div class="card border-info">
                      <div class="card-header bg-info">
                        Device Effective Attributes

                        <div class="card-header-actions">
                          <span class=""
                            ><i
                              *ngIf="device_admin"
                              (click)="editAttribute($event)"
                              class="fa fa-sm fa-pencil pr-3"
                              [ngClass]="{
                                'text-dark': attributeEdit,
                                'text-warn': !attributeEdit
                              }"
                            >
                            </i
                          ></span>

                          <span class=""
                            ><i
                              (click)="attributeExpand = !attributeExpand"
                              class="fa fa-sm fa-sm pr-2"
                              [ngClass]="{
                                'fa-chevron-down': !attributeExpand,
                                'fa-chevron-up': attributeExpand
                              }"
                            >
                            </i
                          ></span>

                          <span class="dragula-handle"
                            ><i
                              class="dragula-handle text-black-50 fa fa-lg fa-arrows"
                            ></i
                          ></span>
                        </div>
                      </div>
                      <div class="card-body m-0 p-1" *ngIf="attributeExpand">
                        <div class="row">
                          <div class="col-12">
                            <embed-device-attributes
                              [entity]="clone"
                              [layout]="'table'"
                              [options]="device_attribute_options"
                            ></embed-device-attributes>
                          </div>
                        </div>
                      </div>

                      <div
                        class="card-footer"
                        *ngIf="attributeEdit && device_admin && clone?.pending"
                      >
                        <div class="row">
                          <div class="col">
                            <div
                              *ngIf="clone.pending"
                              class="btn btn-block btn-warning"
                              (click)="updateDevice($event)"
                            >
                              Save
                            </div>
                            <div
                              *ngIf="clone.pending"
                              class="btn btn-dark btn-block"
                              (click)="revertClone($event)"
                            >
                              Revert
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- End Device Attributes -->

                  <!-- Device Fields -->
                  <div class="col-12">
                    <div class="card border-info">
                      <div class="card-header bg-info">
                        Device Fields
                        <div class="card-header-actions">
                          <span
                            ><i
                              (click)="fieldsExpand = !fieldsExpand"
                              class="fa fa-sm pr-2"
                              [ngClass]="{
                                'fa-chevron-down': !fieldsExpand,
                                'fa-chevron-up': fieldsExpand
                              }"
                            >
                            </i
                          ></span>
                          <span class="dragula-handle"
                            ><i
                              class="dragula-handle text-black-50 fa fa-lg fa-arrows"
                            ></i
                          ></span>
                        </div>
                      </div>
                      <div class="card-body m-0 p-1" *ngIf="fieldsExpand">
                        <div
                          class="row"
                          *ngFor="let key of device.fields | keys"
                        >
                          <div class="col-12 mb-1">{{ key }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- End Device Fields -->

                  <!-- Start Streaming Policy -->
                  <div class="col-12" *ngIf="device.series === 'V4'">
                    <div class="card border-info">
                      <div class="card-header bg-info">
                        Streaming Policy

                        <div class="card-header-actions">
                          <span class=""
                            ><i
                              *ngIf="device_admin"
                              (click)="editStreamingPolicy($event)"
                              class="fa fa-sm fa-pencil pr-3"
                              [ngClass]="{
                                'text-dark': streamingPolicyEdit,
                                'text-warn': !streamingPolicyEdit
                              }"
                            >
                            </i
                          ></span>

                          <span class=""
                            ><i
                              (click)="
                                streamingPolicyExpand = !streamingPolicyExpand
                              "
                              class="fa fa-sm pr-2"
                              [ngClass]="{
                                'fa-chevron-down': !streamingPolicyExpand,
                                'fa-chevron-up': streamingPolicyExpand
                              }"
                            >
                            </i
                          ></span>

                          <span class="dragula-handle"
                            ><i
                              class="dragula-handle text-black-50 fa fa-lg fa-arrows"
                            ></i
                          ></span>
                        </div>
                      </div>
                      <div
                        class="card-body m-0 p-1"
                        *ngIf="streamingPolicyExpand"
                      >
                        <div class="row">
                          <div class="col-12">
                            <embed-device-streaming-policy
                              [entity]="clone"
                              [layout]="'table'"
                              [options]="device_streaming_policy_options"
                            ></embed-device-streaming-policy>
                          </div>
                        </div>
                      </div>
                      <div
                        class="card-footer"
                        *ngIf="
                          device_admin && streamingPolicyEdit && clone?.pending
                        "
                      >
                        <div class="row">
                          <div class="col">
                            <div
                              *ngIf="clone.pending"
                              class="btn btn-block btn-warning"
                              (click)="putUpdateDeviceStreamingPolicy(clone)"
                            >
                              Save
                            </div>
                            <div
                              *ngIf="clone.pending"
                              class="btn btn-dark btn-block"
                              (click)="revertCloneStreamingPolicy($event)"
                            >
                              Revert
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- End Streaming Policy -->
                </div>
              </div>
            </div>
            <!-- End Layout Pane 1 -->

            <!-- Layout Pane 2 -->
            <div class="col-12 col-md-6 col-lg-6 col-xl-4">
              <div class="row">
                <div
                  class="container min-vh-70"
                  dragula="HANDLES"
                  id="center-drag-section"
                  style="min-height: 10%"
                >
                  <div
                    class="col-12"
                    *ngIf="dragula_sections['center-drag-section'] == 0"
                  >
                    <div class="jumbotron vh-70">&nbsp;</div>
                  </div>

                  <!-- Device Forecast -->
                  <div
                    class="col-12 d-none d-xl-block"
                    *ngIf="device.attributes?.display == '1'"
                  >
                    <div class="card border-secondary">
                      <div class="card-header bg-secondary">
                        Forecast
                        <div class="card-header-actions">
                          <span class="dragula-handle"
                            ><i
                              class="dragula-handle text-black-50 fa fa-lg fa-arrows"
                            ></i
                          ></span>
                        </div>
                      </div>
                      <div class="card-body p-4 m-0">
                        <div class="row">
                          <div class="col-12">
                            <div class="alert alert-success">
                              <new-spinner
                                *ngIf="
                                  !device?.forecast &&
                                  !device?.forecast?.error &&
                                  !device.isfact23
                                "
                                [size]="'md'"
                              ></new-spinner>

                              <div
                                *ngIf="
                                  device?.forecast && !device.forecast.error
                                "
                                class="row"
                              >
                                <div
                                  class="col-12"
                                  *ngIf="!device.forecast['anonymous']"
                                >
                                  <div class="row">
                                    <div class="col-12" *ngIf="device.isfact23">
                                      Note Henry (Factory 23) devices do not use
                                      this. They use the Legacy geo
                                    </div>
                                    <div
                                      class="col-12 mt-3"
                                      *ngIf="
                                        device.forecast['Geo'] &&
                                        device.forecast['Geo']['zip']
                                      "
                                    >
                                      Postal Code:
                                      {{ device.forecast["Geo"]["zip"] }}
                                    </div>
                                    <div
                                      class="col-12 mt-3"
                                      *ngIf="
                                        device.forecast['Geo'] &&
                                        device.forecast['Geo']['city']
                                      "
                                    >
                                      City: {{ device.forecast["Geo"]["city"] }}
                                    </div>
                                    <div
                                      class="col-12"
                                      *ngIf="
                                        device.forecast['Location'] &&
                                        device.forecast['Location']['Key']
                                      "
                                    >
                                      Location Code:
                                      {{ device.forecast["Location"]["Key"] }}
                                    </div>
                                    <div class="col-12">
                                      Last Updated:
                                      <pre>{{
                                        device.forecast["LastUpdated"] | json
                                      }}</pre>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  class="col-12"
                                  *ngIf="device.forecast['anonymous']"
                                >
                                  Device is Anonymous
                                </div>
                              </div>
                              <div
                                *ngIf="
                                  device?.forecast && device.forecast.error
                                "
                                class="row"
                              >
                                <div class="col-12 alert alert-danger">
                                  {{ device.forecast.msg }}
                                </div>
                              </div>

                              <div
                                class="col-12 text-center"
                                *ngIf="!device?.forecast && device.isfact23"
                              >
                                <span class="display-4">[NWS]</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- End Device Forecast -->

                  <!-- Device Type -->
                  <div class="col-12" *ngIf="device?.type">
                    <widget-embed
                      [entity]="device.type"
                      [options]="type_attribute_options"
                    >
                    </widget-embed>
                  </div>
                  <div class="col-12" *ngIf="!device?.type">
                    <div class="card">
                      <div class="card-header">
                        No Device Definition
                        <div class="card-header-actions">
                          <span class="dragula-handle"
                            ><i
                              class="dragula-handle text-black-50 fa fa-lg fa-arrows"
                            ></i
                          ></span>
                        </div>
                      </div>
                      <div class="card-body">
                        No definition or definition not yet loaded.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- End Layout Pane 2 -->

            <!-- Layout Pane 3 -->
            <div class="col-12 col-md-6 col-lg-6 col-xl-4">
              <div class="row">
                <div
                  class="container min-vh-70"
                  dragula="HANDLES"
                  id="right-drag-section"
                >
                  <div
                    class="col-12"
                    *ngIf="dragula_sections['right-drag-section'] == 0"
                  >
                    <div class="jumbotron vh-70">&nbsp;</div>
                  </div>

                  <!-- Device Target -->
                  <div
                    class="col-12"
                    *ngIf="stageTester && device?.extended?.environment_target"
                  >
                    <widget
                      [entity]="device.extended.environment_target"
                      [layout]="'toggle'"
                      [options]="{ edit: stageTester }"
                    ></widget>
                  </div>

                  <!-- End Device Target -->

                  <!-- Device Firmware -->
                  <div class="col-12" *ngIf="device?.firmwareReport">
                    <widget-embed
                      [entity]="device.firmwareReport"
                      [options]="{}"
                      [layout]="'standard'"
                    ></widget-embed>
                  </div>
                  <!-- End Device Firmware -->

                  <!-- Device Notes -->
                  <div *ngIf="device" class="col-12 mt-5 d-none d-xl-block">
                    <entity-notes-v2
                      [title]="'Device Notes'"
                      [entity]="
                        'ref.device.' + device.serial + '@' + device.series
                      "
                      [placeholder]="
                        'Device Staff Note. e.g. User has reported sensor reports erroneous data.'
                      "
                    ></entity-notes-v2>
                  </div>

                  <!-- Gateway Diagnostics Start-->
                  <div class="col-12" *ngIf="device.series === 'V4'">
                    <div class="card border-info">
                      <div class="card-header bg-info">
                        Gateway Diagnostics

                        <div class="card-header-actions">
                          <span class=""
                            ><i
                              (click)="gatewayDiagExpand = !gatewayDiagExpand"
                              class="fa fa-sm fa-sm pr-2"
                              [ngClass]="{
                                'fa-chevron-down': !gatewayDiagExpand,
                                'fa-chevron-up': gatewayDiagExpand
                              }"
                            >
                            </i
                          ></span>

                          <span class="dragula-handle"
                            ><i
                              class="dragula-handle text-black-50 fa fa-lg fa-arrows"
                            ></i
                          ></span>
                        </div>
                      </div>
                      <div class="card-body m-0 p-1" *ngIf="gatewayDiagExpand">
                        <div class="row">
                          <div class="col-12">
                            <embed-device-gateway-diagnostics
                              [entity]="clone"
                              [layout]="'table'"
                            ></embed-device-gateway-diagnostics>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Gateway Diagnostics Start End -->
                </div>
              </div>
            </div>
            <!--  End Layout Pane 3 -->
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Additional Details Tab Menu -->
  <div class="row">
    <div class="col-1"></div>
    <div class="col-11">
      <div class="card m-0 p-1 mb-2">
        <div class="card-header">Additional Details</div>
        <div class="card-body m-0 p-1">
          <div class="row">
            <tabset #staticTabs class="col-12">
              <tab heading="Feed" class="row">
                <!--                                       -->
                <!--           Device Feed                 -->
                <!--                                       -->
                <ng-container [ngSwitch]="feed_version">
                  <ng-container *ngSwitchCase="'2.0'">
                    <div class="alert alert-info">
                      Go back to classic view
                      <span class="float-right">&nbsp;Beta View</span>
                      <label
                        class="switch switch-3d switch-primary float-right"
                      >
                        <input
                          checked=""
                          class="switch-input switch-primary"
                          (change)="toggleFeedVersion($event)"
                          [(ngModel)]="feed_version_toggle"
                          type="checkbox"
                        />
                        <span class="switch-slider"></span>
                      </label>
                    </div>

                    <div class="col" *ngIf="device?.extended">
                      <widget
                        [entity]="device_feed"
                        [options]="{}"
                        [layout]="'standard'"
                      ></widget>
                    </div>
                  </ng-container>

                  <ng-container *ngSwitchDefault>
                    <div class="alert alert-info">
                      There is a new beta version of this tab available

                      <span class="float-right">&nbsp;Beta View</span>
                      <label
                        class="switch switch-3d switch-primary float-right"
                      >
                        <input
                          checked=""
                          class="switch-input switch-primary"
                          (change)="toggleFeedVersion($event)"
                          [(ngModel)]="feed_version_toggle"
                          type="checkbox"
                        />
                        <span class="switch-slider"></span>
                      </label>
                    </div>

                    <!-- 1.0 -->
                    <div class="col">
                      <div class="row">
                        <!-- Device Reading -->
                        <div class="col-12 col-xl-6">
                          <div class="card border-secondary">
                            <div class="card-header bg-secondary">Feed</div>
                            <div class="card-body p-1 m-0">
                              <!--                                       -->
                              <!--           Load Feed Data              -->
                              <!--                                       -->
                              <div class="col-12" *ngIf="chartTimeValues">
                                <div class="row">
                                  <div
                                    *ngIf="chartTimeValues"
                                    class="col-12 col-md-3"
                                  >
                                    <select
                                      class="form-control"
                                      name="timeValue"
                                      [(ngModel)]="timeValue"
                                      style="color: black"
                                    >
                                      <option
                                        *ngFor="let time of chartTimeValues"
                                        [value]="time"
                                      >
                                        {{ time }}
                                      </option>
                                    </select>
                                  </div>

                                  <div
                                    *ngIf="device?.fields"
                                    class="col-12 col-md-3"
                                  >
                                    <select
                                      class="form-control"
                                      name="dataValue"
                                      [(ngModel)]="dataValue"
                                      style="color: black"
                                    >
                                      <option
                                        *ngFor="let key of device.fields | keys"
                                        [value]="key"
                                      >
                                        {{ key }}
                                      </option>
                                    </select>
                                  </div>

                                  <div class="col-6 col-md-3">
                                    <select
                                      class="form-control"
                                      name="durationValue"
                                      [(ngModel)]="durationValue"
                                      style="color: black"
                                    >
                                      <option value="1d">1 Day</option>
                                      <option value="7d">1 Week</option>
                                      <option value="1M">1 Month</option>
                                    </select>
                                  </div>

                                  <div class="col-6 col-md-3">
                                    <button
                                      (click)="getChartData($event)"
                                      class="btn btn-primary floatLeft"
                                    >
                                      Get Data
                                    </button>
                                  </div>
                                </div>
                              </div>
                              <div class="col-12 my-3 text-center">
                                <button
                                  (click)="
                                    displayToggles.chart = !displayToggles.chart
                                  "
                                  *ngIf="showChart"
                                  class="btn btn-primary"
                                >
                                  <span *ngIf="!displayToggles.chart"
                                    >Show Graph</span
                                  >
                                  <span *ngIf="displayToggles.chart"
                                    >Show Table</span
                                  >
                                </button>
                              </div>
                              <div class="col-12" *ngIf="units">
                                {{ units }}
                              </div>
                              <new-spinner
                                *ngIf="chartLoading"
                                [size]="'lg'"
                              ></new-spinner>

                              <!-- Feed Chart -->
                              <div
                                *ngIf="showChart && displayToggles.chart"
                                style="height: 600px"
                                class="col-12 my-3"
                              >
                                <div class="row col-12">
                                  <div
                                    class="col-12"
                                    class="scrollCanvasWrapper"
                                  >
                                    <div class="scrollCanvas">
                                      <canvas
                                        baseChart
                                        height="400"
                                        id="theCanvas"
                                        [datasets]="lineChartData"
                                        [labels]="lineChartLabels"
                                        [options]="lineChartOptions"
                                        [legend]="lineChartLegend"
                                        [chartType]="lineChartType"
                                        (chartHover)="chartHovered($event)"
                                        (chartClick)="chartClicked($event)"
                                      >
                                      </canvas>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <!-- Feed Table -->
                              <div
                                *ngIf="showChart && !displayToggles.chart"
                                class="col-12 my-3"
                              >
                                <div class="row">
                                  <div style="width: 165px">Date</div>
                                  <div
                                    *ngIf="availableReadings.spot"
                                    style="padding-left: 15px; width: 50px"
                                  >
                                    <span>Plot</span>
                                  </div>
                                  <div
                                    *ngIf="
                                      availableReadings.average ||
                                      availableReadings.tally
                                    "
                                    style="padding-left: 10px; width: 75px"
                                  >
                                    {{ displayToggles.tblVal }}
                                  </div>
                                  <div
                                    *ngIf="availableReadings.low"
                                    style="padding-left: 15px; width: 50px"
                                  >
                                    <span>Low</span>
                                  </div>
                                  <div
                                    *ngIf="availableReadings.high"
                                    style="padding-left: 15px; width: 50px"
                                  >
                                    <span>High</span>
                                  </div>
                                </div>
                                <div
                                  *ngIf="tableData"
                                  style="height: 500px; overflow-y: auto"
                                  class="row"
                                >
                                  <div
                                    *ngFor="let x of tableData"
                                    class="row"
                                    style="
                                      border-bottom: 1px black solid;
                                      margin-left: 0px;
                                      width: 100%;
                                    "
                                  >
                                    <div style="width: 165px">
                                      {{ x.x | date : "MM/dd @ h:mm:ss a" }}
                                    </div>
                                    <div
                                      *ngIf="availableReadings.spot"
                                      style="padding-left: 10px; width: 50px"
                                    >
                                      {{ x.s | json }}
                                    </div>
                                    <div
                                      *ngIf="
                                        availableReadings.average ||
                                        availableReadings.tally
                                      "
                                      style="padding-left: 10px; width: 75px"
                                    >
                                      {{ x.y | json }}
                                    </div>
                                    <div
                                      *ngIf="availableReadings.low"
                                      style="padding-left: 10px; width: 50px"
                                    >
                                      {{ x.l | json }}
                                    </div>
                                    <div
                                      *ngIf="availableReadings.high"
                                      style="padding-left: 10px; width: 50px"
                                    >
                                      {{ x.h | json }}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- End Device Reading -->

                        <!-- Device Reading -->
                        <div class="col-12 col-xl-6">
                          <div class="card border-secondary">
                            <div class="card-header">Readings</div>
                            <div class="cord-body card-body p-1 m-0">
                              <new-spinner
                                *ngIf="!device?.status"
                                [size]="'lh'"
                              ></new-spinner>

                              <div class="row" *ngIf="device?.status">
                                <div class="col-12">
                                  <device-reading
                                    [status]="device.status"
                                  ></device-reading>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- End Device Reading -->
                      </div>
                    </div>

                    <div class="col-12" *ngIf="loadingErrors.status != null">
                      Loading Feed Appears to have failed
                      <button
                        class="btn-sm btn-primary"
                        *ngIf="loadingErrors.status === 0"
                        (click)="getDeviceStatus()"
                      >
                        Try Again
                      </button>
                    </div>
                  </ng-container>
                </ng-container>
              </tab>

              <tab heading="Logs" class="row">
                <tabset #logTabs>
                  <!-- ================ -->
                  <!-- Raw Request Logs -->
                  <!-- ================ -->
                  <tab heading="Request" class="row">
                    <div class="col-12">
                      <widget-embed
                        *ngIf="device?.requestLogs"
                        [entity]="device.requestLogs"
                        [options]="{}"
                        [layout]="'standard'"
                      ></widget-embed>
                    </div>
                  </tab>

                  <!-- ================== -->
                  <!-- Device Entity Logs -->
                  <!-- ================== -->
                  <tab heading="Device" class="row">
                    <div class="col-12">
                      <widget
                        *ngIf="device?.deviceLogs"
                        [entity]="device.deviceLogs"
                        [options]="{
                          subject:
                            'ref.device.' + device.serial + '@' + device.series
                        }"
                        [layout]="'standard'"
                      ></widget>
                    </div>
                  </tab>

                  <!-- =================== -->
                  <!-- Gateway Entity Logs -->
                  <!-- =================== -->
                  <tab
                    heading="Gateway"
                    class="row"
                    *ngIf="device?.attributes?.display === '1'"
                  >
                    <div class="col-12">
                      <widget
                        *ngIf="device?.gatewayLogs"
                        [entity]="device.gatewayLogs"
                        [options]="{
                          subject:
                            'ref.gateway.' + device.serial + '@' + device.series
                        }"
                        [layout]="'standard'"
                      ></widget>
                    </div>
                  </tab>
                </tabset>
              </tab>

              <tab heading="Linked Devices">
                <new-spinner *ngIf="!device" [size]="'md'"></new-spinner>
                <div
                  class="row"
                  *ngIf="
                    device?.linkedSensors && device?.linkedSensors.length == 0
                  "
                >
                  <div class="col">
                    <div class="alert alert-info">No Linked Devices</div>
                  </div>
                </div>
                <div class="row" *ngIf="device?.linkedSensors">
                  <div class="col-12" *ngFor="let link of device.linkedSensors">
                    <widget
                      [entity]="link"
                      [layout]="'brief'"
                      [options]="{}"
                    ></widget>
                  </div>
                </div>
              </tab>

              <tab heading="Permissions">
                <new-spinner
                  *ngIf="!device?.permissionEntries"
                  [size]="'md'"
                ></new-spinner>
                <div *ngIf="device?.permissionEntries">
                  <div
                    *ngFor="let pItem of device.permissionEntries.items"
                    class="col-12"
                  >
                    <div class="card overlayParent">
                      <new-spinner
                        *ngIf="pItem?.loading"
                        [size]="'md'"
                      ></new-spinner>

                      <div class="card-header">{{ pItem.handle }}</div>
                      <div class="card-body">
                        <div class="row mb-3">
                          <div class="col-3">permissionType:</div>
                          <div class="col-9">{{ pItem.permissionType }}</div>
                        </div>
                        <div class="row mb-3">
                          <div class="col-3">group Id:</div>
                          <div class="col-9">{{ pItem.groupId }}</div>
                        </div>
                        <div class="row mb-3">
                          <div class="col-3">modified On:</div>
                          <div class="col-9">{{ pItem.modifiedOn }}</div>
                        </div>
                        <div class="row mb-3" *ngIf="pItem.permissions">
                          <div class="col-3 float-left">Permissions:</div>
                          <div class="col-9 float-left">
                            <div *ngFor="let key of pItem.permissions | keys">
                              <div *ngIf="pItem.permissions[key] === 1">
                                {{ key }}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="!pItem.system" class="float-right">
                          <button
                            type="button"
                            class="btn btn-danger"
                            (click)="
                              deletePermissionEntry(confirm, pItem, $event)
                            "
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--<pre>{{device.permissionEntries| json}}</pre>-->
                </div>
              </tab>

              <tab heading="Users">
                <div class="col-12">
                  <new-spinner
                    *ngIf="!device?.users"
                    [size]="'md'"
                  ></new-spinner>

                  <div *ngIf="device?.users" class="row">
                    <div class="col-12" *ngIf="device.users.items.length == 0">
                      <div class="alert alert-info">No Associated Users.</div>
                    </div>

                    <div *ngFor="let u of device.users.items" class="col-12">
                      <div class="card">
                        <div class="card-header">
                          User: {{ u.display_name }}
                        </div>
                        <div class="card-body row">
                          <label class="col-3 form-control-label"
                            >User Id:</label
                          >
                          <div
                            class="col-9"
                            (click)="toUser($event, u.identifier)"
                          >
                            <a href="/portal/users/show/{{ u.identifier }}">{{
                              u.identifier
                            }}</a>
                          </div>
                          <label class="col-3 form-control-label">Name:</label>
                          <div class="col-9">
                            {{ u.first_name }} {{ u.last_name }}
                          </div>
                          <label class="col-3 form-control-label"
                            >Created on:</label
                          >
                          <div class="col-9">{{ u.createdOn }}</div>
                          <label class="col-3 form-control-label"
                            >Modified on:</label
                          >
                          <div class="col-9">{{ u.modifiedOn }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </tab>

              <tab heading="Definition">
                <div class="col-12">
                  <div class="row">
                    <div class="col">
                      <div *ngIf="!device?.type">
                        <new-spinner [size]="'md'"></new-spinner>

                        <div class="card">
                          <div class="card-header">No Device Definition</div>
                          <div class="card-body">
                            No definition or definition not yet loaded.
                          </div>
                        </div>
                      </div>

                      <div *ngIf="device?.type" class="row">
                        <div class="col-12">
                          <widget-embed
                            [entity]="device.type"
                            [options]="{ expand: true }"
                          >
                          </widget-embed>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </tab>

              <tab heading="Claims Log" (selectTab)="loadInfo('c')">
                <div class="col-12">
                  <new-spinner
                    *ngIf="!device?.claims"
                    [size]="'md'"
                  ></new-spinner>

                  <div *ngIf="device?.claims" class="row">
                    <div class="col-12" *ngIf="device.claims.items.length == 0">
                      <div class="alert alert-info">
                        No Associated User Claims.
                      </div>
                    </div>
                    <div *ngFor="let c of device.claims.items" class="col-12">
                      <div class="card">
                        <div class="card-header">
                          Claim Owner ID: {{ c.ownerId }}
                        </div>
                        <div class="card-body">
                          <div>Claimed on: {{ c.claimedOn }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </tab>

              <tab heading="DataStream" (selectTab)="ensureDataStreamCards()">
                <div class="col-12" *ngIf="device?.dataStreamV2?.cards">
                  <div
                    class="row"
                    dragula="DataStreamSequence"
                    [(dragulaModel)]="device.dataStreamV2.cards"
                    (dragulaModelChange)="reweightCards($event)"
                  >
                    <div
                      class="col-6 col-xl-4"
                      *ngFor="let card of device.dataStreamV2.cards"
                      [ngClass]="{ 'd-none': card.deleted }"
                    >
                      <widget-embed
                        *ngIf="!card.deleted"
                        [entity]="card"
                        [options]="{}"
                        [layout]="'minimized'"
                      ></widget-embed>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <button
                        class="btn btn-lg btn-primary"
                        (click)="device.addCard(authService.user)"
                      >
                        Add Card
                      </button>
                    </div>
                  </div>
                </div>
              </tab>

              <tab heading="Simulator">
                <new-spinner *ngIf="!simulator" [size]="'md'"></new-spinner>
                <div *ngIf="simulator">
                  <div class="row">
                    <div class="col-xl-8 col-xs-12 offset-xl-2">
                      <device-simulator-widget
                        [entity]="simulator"
                        [layout]="'standard'"
                        [options]="{}"
                      ></device-simulator-widget>
                    </div>
                  </div>
                </div>
              </tab>

              <tab heading="Alarm" (selectTab)="loadInfo('a')">
                <ng-container *ngIf="stage">
                  <div class="row">
                    <div class="col">
                      <div class="alert alert-info">
                        Service Unavailable in Stage.
                      </div>
                    </div>
                  </div>
                </ng-container>

                <ng-container *ngIf="!stage">
                  <new-spinner
                    *ngIf="!device?.alarmData"
                    [size]="'md'"
                  ></new-spinner>
                  <div *ngIf="device.alarmData">
                    <render-object [object]="device.alarmData"></render-object>
                  </div>
                </ng-container>
              </tab>

              <tab
                heading="Forecast"
                (selectTab)="loadInfo('f')"
                *ngIf="device.attributes?.display == '1'"
              >
                <div class="col-12">
                  <new-spinner
                    *ngIf="!device?.forecast"
                    [size]="'lg'"
                  ></new-spinner>

                  <div
                    *ngIf="device?.forecast && !device.forecast.error"
                    class="row"
                  >
                    <div class="col-12" *ngIf="!device.forecast['anonymous']">
                      <div class="row">
                        <div class="col-12" *ngIf="device.isfact23">
                          Note Henry (Factory 23) devices do not use this. They
                          use the Legacy geo
                        </div>
                        <div class="col-12 text-center">
                          <button
                            type="button"
                            class="btn btn-primary m-2 pull-right"
                            (click)="device.getForecastData('true')"
                          >
                            Complete Forecast
                          </button>
                          <button
                            type="button"
                            class="btn btn-secondary m-2 pull-right"
                            (click)="device.getForecastData('true', '1.2')"
                          >
                            Gateway Format Forecast
                          </button>
                        </div>

                        <new-spinner
                          *ngIf="device?.meta?.fetching_forecast"
                          [size]="'lg'"
                        ></new-spinner>

                        <div
                          class="col-12 mt-3"
                          *ngIf="
                            device.forecast['Geo'] &&
                            device.forecast['Geo']['zip']
                          "
                        >
                          Postal Code: {{ device.forecast["Geo"]["zip"] }}
                        </div>
                        <div
                          class="col-12 mt-3"
                          *ngIf="
                            device.forecast['Geo'] &&
                            device.forecast['Geo']['city']
                          "
                        >
                          City: {{ device.forecast["Geo"]["city"] }}
                        </div>
                        <div
                          class="col-12"
                          *ngIf="
                            device.forecast['Location'] &&
                            device.forecast['Location']['Key']
                          "
                        >
                          Location Code:
                          {{ device.forecast["Location"]["Key"] }}
                        </div>
                        <div class="col-12">
                          Last Updated:
                          <pre>{{ device.forecast["LastUpdated"] | json }}</pre>
                        </div>

                        <div class="col-12" *ngIf="device.forecast['Forecast']">
                          Forecast: <br />
                          <pre>{{ device.forecast | json }}</pre>
                        </div>
                      </div>
                    </div>
                    <div class="col-12" *ngIf="device.forecast['anonymous']">
                      Device is Anonymous
                    </div>
                  </div>

                  <div
                    *ngIf="device?.forecast && device.forecast.error"
                    class="row"
                  >
                    <div class="col-12">
                      <div class="alert alert-info">
                        {{ device.forecast.msg }}
                      </div>
                    </div>
                  </div>
                </div>
              </tab>
            </tabset>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- End Tab Menu -->
</div>

<!--                                                        -->
<!--                    Pop-Up Menu                         -->
<!--                                                        -->
<ng-template #confirm>
  <div class="modal-header">
    <h4 class="modal-title pull-left">{{ currentModal.title }}</h4>
    <button
      type="button"
      class="close pull-right"
      aria-label="Close"
      (click)="modalRef.hide()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    {{ currentModal.msg }}
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-secondary"
      (click)="currentModal.cancel()"
      data-dismiss="modal"
    >
      {{ currentModal.cancelMsg }}
    </button>
    <button
      type="button"
      class="btn btn-danger"
      (click)="currentModal.confirm()"
    >
      {{ currentModal.confirmMsg }}
    </button>
  </div>
</ng-template>

<!--                                                        -->
<!--                   Custom CSS                           -->
<!--                                                        -->
<!-- @todo get rid of this, move into css file.             -->
<style>
  .scrollCanvasWrapper {
    overflow-x: scroll;
    max-width: 900px;
  }
  .scrollCanvasWrapper > canvas {
    left: 0;
    top: 0;
    pointer-events: none;
    max-height: 600px;
  }
  .scrollCanvas {
    width: 5000px;
    height: 600px;
  }
  .fakelink:hover {
    cursor: pointer;
  }
</style>

<ng-template #pageModal>
  <modal-widget
    [entity]="currentModal"
    (widgetEvent)="handleEvent($event)"
    [layout]="'shadowbox'"
    [options]="{}"
  ></modal-widget>
</ng-template>
