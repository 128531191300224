
    <ng-container [ngSwitch]="layout">
      <ng-container *ngSwitchCase="'table'">
        <div class="pin-group-widget row">
          <div class="col">
            <h2>Pin Group: #{{ entity.identifier }} - {{ entity.name }}</h2>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <generic-set-widget
              [entity]="entity.device_pins"
              [layout]="'table'"
              [options]="pin_options"
            ></generic-set-widget>
          </div>
        </div>
      </ng-container>

      <ng-container *ngSwitchCase="'standard'">
        <div class="pin-group-widget row b-b-1">
          <div class="col-2 b-r-1">
            #{{ entity.identifier }} {{ entity.name }}
          </div>
          <div class="col">
            <div class="row">
              <div class="col-4" *ngFor="let pin of entity.pins">
                <widget
                  [entity]="pin"
                  [layout]="'thumb'"
                  [options]="{}"
                ></widget>
              </div>
            </div>
          </div>
        </div>
      </ng-container>

      <ng-container *ngSwitchCase="'shadowbox'">
        <div class="pin-group-widget row">
          <div class="col">
            <div class="row" *ngIf="entity?.validation?.messages['common']">
              <div class="col">
                <div class="alert alert-warning alert-dismissible">
                  {{ entity?.validation?.messages["common"] }}
                </div>
              </div>
            </div>

            <div
              class="row p-0 mt-3 "
              *ngIf="entity?.validation?.messages['name']"
            >
              <div class="col">
                <div class="alert alert-danger alert-dismissible p-1 m-0">
                  {{ entity?.validation?.messages["name"] }}
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-3">
                <i
                  class="fa fa-exclamation text-danger"
                  *ngIf="entity?.validation?.messages['name']"
                >
                </i
                ><b>Name:</b>
              </div>
              <div class="col">
                <div class="col">
                  <input
                    type="text"
                    [(ngModel)]="entity.name"
                    (ngModelChange)="recordChange()"
                    size="32"
                  />
                </div>
              </div>
            </div>

            <div
              class="row p-0 mt-3 "
              *ngIf="entity?.validation?.messages['description']"
            >
              <div class="col">
                <i
                  class="fa fa-exclamation text-danger"
                  *ngIf="entity?.validation?.messages['description']"
                >
                </i>
                <div class="alert alert-danger alert-dismissible p-1 m-0">
                  {{ entity?.validation?.messages["description"] }}
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-3">
                <b>Description:</b>
              </div>
              <div class="col">
                <div class="col">
                  <input
                    type="text"
                    [(ngModel)]="entity.description"
                    (ngModelChange)="recordChange()"
                    size="32"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>

      <div class="pin-group-widget" *ngSwitchDefault>
        Pin Group {{ layout }}
      </div>
    </ng-container>
  