import { Component, Input } from "@angular/core";
import { EmailTemplateDynamicSectionEntity } from "../../entities";

@Component({
  selector: "email-template-section-section-widget",
  template: `
    <ng-container *ngIf="entity">
      <div class="card">
        <div class="card-header">
          [SECTION]
          <i class="float-right fa fa-plus" (click)="expand = !expand"></i>
        </div>
        <div class="card-body" *ngIf="expand">
          <div class="row">
            <div class="col"><strong>Section</strong></div>
            <div class="col">{{ entity.section }}</div>
          </div>

          <ng-container *ngIf="entity.current_selector">
            <div class="row">
              <div class="col"><strong>Current Selector</strong></div>
            </div>
            <div class="row">
              <div class="col">
                <email-template-section-widget
                  [entity]="entity.current_selector"
                  [layout]="layout"
                  [options]="options"
                ></email-template-section-widget>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="entity.clause">
            <div class="row">
              <div class="col"><strong>Clause</strong></div>
            </div>
            <div class="row">
              <div class="col">
                <email-template-section-widget
                  [entity]="entity.clause"
                  [layout]="layout"
                  [options]="options"
                ></email-template-section-widget>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="entity.match">
            <div class="row">
              <div class="col"><strong>Match</strong></div>
            </div>
            <div class="row">
              <div class="col">
                <pre>{{ entity.match | json }}</pre>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="entity.errors && entity.errors.length > 0">
            <div class="row">
              <div class="col"><strong>Errors</strong></div>
            </div>
            <div class="row">
              <div class="col">
                <pre>{{ entity.errors | json }}</pre>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="entity.bind && entity.bind.length > 0">
            <div class="row">
              <div class="col"><strong>Bind</strong></div>
            </div>
            <div class="row" *ngFor="let item of entity.bind">
              <div class="col">
                <email-template-section-widget
                  [entity]="item"
                  [layout]="layout"
                  [options]="options"
                ></email-template-section-widget>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="entity.children && entity.children.length > 0">
            <div class="row">
              <div class="col"><strong>Children</strong></div>
            </div>
            <div class="row" *ngFor="let item of entity.children">
              <div class="col">
                <email-template-section-widget
                  [entity]="item"
                  [layout]="layout"
                  [options]="options"
                ></email-template-section-widget>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </ng-container>
  `,
})
export class EmailTemplateSectionSectionWidgetComponent {
  @Input() entity: EmailTemplateDynamicSectionEntity | null = null;
  @Input() options: any = null;
  @Input() layout = "standard";
  expand = false;
  constructor() {}
}
