
    <div class="card card-accent-dark alert-facade-humidity-widget">
      <!-- Card Header -->
      <div class="card-header bg-dark text-white">
        <div class="d-flex justify-content-between align-items-center">
          <h5 class="mb-0">Humidity Alert</h5>
        </div>
      </div>

      <!-- Card Body -->
      <div class="card-body">
        <!-- Device and Association Section -->
        <div class="mb-3">
          <div class="row">
            <div class="col-4 font-weight-bold">Device</div>
            <div class="col-8">
              <a
                [routerLink]="'/portal/devices/show/' + entity.device"
                class="text-primary"
                >{{ entity.device }}</a
              >
            </div>
          </div>
          <div class="row">
            <div class="col-4 font-weight-bold">Association</div>
            <div class="col-8">{{ entity.deviceAssociation }}</div>
          </div>
        </div>

        <hr class="my-3" />

        <!-- Humidity Above Section -->
        <div class="mb-3">
          <div class="row">
            <div class="col-5 font-weight-bold">
              Humidity Above (20 to 99 RelativeHumidity)
            </div>
            <div *ngIf="!options?.edit" class="col-7 text-muted">
              <b>{{ entity?.humidityAbove?.value }}</b>
              <span>{{ entity?.humidityAbove?.unit?.name }}</span>
            </div>
            <div *ngIf="options?.edit" class="col-7">
              <div class="d-flex" style="gap:5%;">
                <input
                  type="range"
                  class="form-control-range"
                  [(ngModel)]="entity.humidityAbove.value"
                  (input)="inputChanges($event.target.value, 'humidityAbove')"
                  [min]="20"
                  [max]="99"
                  step="1"
                  name="humidity_above"
                />
                <div>
                  <input
                    name="humidity_above"
                    style="margin: 0 15px;"
                    type="number"
                    class="form-control"
                    [(ngModel)]="entity.humidityAbove.value"
                    (input)="inputChanges($event.target.value, 'humidityAbove')"
                    [min]="20"
                    [max]="99"
                  />
                </div>
              </div>
              <span class="ml-2">
                {{ entity?.humidityAbove?.value }} -
                {{ entity?.humidityAbove?.unit?.name }}
              </span>
            </div>
          </div>
        </div>

        <!-- Humidity Below Section -->
        <div class="mb-3">
          <div class="row">
            <div class="col-5 font-weight-bold">
              Humidity Below (19 to 98 RelativeHumidity)
            </div>
            <div *ngIf="!options?.edit" class="col-7 text-muted">
              <b>{{ entity?.humidityBelow?.value }}</b>
              <span>{{ entity?.humidityBelow?.unit?.name }}</span>
            </div>
            <div *ngIf="options?.edit" class="col-7">
              <div class="d-flex" style="gap:5%;">
                <input
                  type="range"
                  class="form-control-range"
                  [(ngModel)]="entity.humidityBelow.value"
                  (input)="inputChanges($event.target.value, 'humidityBelow')"
                  [min]="19"
                  [max]="98"
                  step="1"
                  name="humidity_below"
                />
                <div>
                  <input
                    name="humidity_below"
                    style="margin: 0 15px;"
                    type="number"
                    class="form-control"
                    [(ngModel)]="entity.humidityBelow.value"
                    (input)="inputChanges($event.target.value, 'humidityBelow')"
                    [min]="19"
                    [max]="98"
                  />
                </div>
              </div>

              <span class="ml-2">
                {{ entity?.humidityBelow?.value }} -
                {{ entity?.humidityBelow?.unit?.name }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  