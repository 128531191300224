import { Component, Input, Output, EventEmitter } from "@angular/core";
import { WidgetEnum_Alert_Facade } from "../../../../enums/widget";
import { AlertFacadeEntity } from "../../../../entities/alert";
import { AlertFacadeWidgetEventStruct } from "./alert-facade-widget-event.struct";
import { AlertEntity } from "app/entities";

@Component({
  selector: "alert-facade-widget",
  template: `
    <div *ngIf="!entity">
      <widget-spinner></widget-spinner>
    </div>

    <ng-container *ngIf="entity">
      <ng-container [ngSwitch]="entity.alert_facade_widget()">
        <alert-facade-contact-widget
          *ngSwitchCase="WidgetEnum.ALERT_FACADE_WIDGET__CONTACT"
          (widgetEvent)="forwardEvent($event)"
          [entity]="entity"
          [layout]="layout"
          [options]="options"
          [data]="data"
          [deviceData]="deviceData"
        ></alert-facade-contact-widget>
        <alert-facade-humidity-widget
          *ngSwitchCase="WidgetEnum.ALERT_FACADE_WIDGET__HUMIDITY"
          (widgetEvent)="forwardEvent($event)"
          [entity]="entity"
          [layout]="layout"
          [options]="options"
          [data]="data"
          [deviceData]="deviceData"
        ></alert-facade-humidity-widget>
        <alert-facade-lightning-widget
          *ngSwitchCase="WidgetEnum.ALERT_FACADE_WIDGET__LIGHTNING"
          (widgetEvent)="forwardEvent($event)"
          [entity]="entity"
          [layout]="layout"
          [options]="options"
          [data]="data"
          [deviceData]="deviceData"
        ></alert-facade-lightning-widget>
        <alert-facade-motion-widget
          *ngSwitchCase="WidgetEnum.ALERT_FACADE_WIDGET__MOTION"
          (widgetEvent)="forwardEvent($event)"
          [entity]="entity"
          [layout]="layout"
          [options]="options"
          [data]="data"
          [deviceData]="deviceData"
        ></alert-facade-motion-widget>
        <alert-facade-on-off-widget
          *ngSwitchCase="WidgetEnum.ALERT_FACADE_WIDGET__ON_OFF"
          (widgetEvent)="forwardEvent($event)"
          [entity]="entity"
          [layout]="layout"
          [options]="options"
          [data]="data"
          [deviceData]="deviceData"
        ></alert-facade-on-off-widget>
        <alert-facade-probe-widget
          *ngSwitchCase="WidgetEnum.ALERT_FACADE_WIDGET__PROBE"
          (widgetEvent)="forwardEvent($event)"
          [entity]="entity"
          [layout]="layout"
          [options]="options"
          [data]="data"
          [deviceData]="deviceData"
        ></alert-facade-probe-widget>
        <alert-facade-rainfall-widget
          *ngSwitchCase="WidgetEnum.ALERT_FACADE_WIDGET__RAINFALL"
          (widgetEvent)="forwardEvent($event)"
          [entity]="entity"
          [layout]="layout"
          [options]="options"
          [data]="data"
          [deviceData]="deviceData"
        ></alert-facade-rainfall-widget>
        <alert-facade-temperature-widget
          *ngSwitchCase="WidgetEnum.ALERT_FACADE_WIDGET__TEMPERATURE"
          (widgetEvent)="forwardEvent($event)"
          [entity]="entity"
          [layout]="layout"
          [options]="options"
          [data]="data"
          [deviceData]="deviceData"
        ></alert-facade-temperature-widget>
        <alert-facade-wet-dry-widget
          *ngSwitchCase="WidgetEnum.ALERT_FACADE_WIDGET__WET_DRY"
          (widgetEvent)="forwardEvent($event)"
          [entity]="entity"
          [layout]="layout"
          [options]="options"
          [data]="data"
          [deviceData]="deviceData"
        ></alert-facade-wet-dry-widget>
        <alert-facade-wind-widget
          *ngSwitchCase="WidgetEnum.ALERT_FACADE_WIDGET__WIND"
          (widgetEvent)="forwardEvent($event)"
          [entity]="entity"
          [layout]="layout"
          [options]="options"
          [data]="data"
          [deviceData]="deviceData"
        ></alert-facade-wind-widget>

        <!-- Default -->
        <alert-facade-default-widget
          *ngSwitchDefault
          (widgetEvent)="forwardEvent($event)"
          [entity]="entity"
          [layout]="layout"
          [options]="options"
          [data]="data"
          [deviceData]="deviceData"
        ></alert-facade-default-widget>
      </ng-container>
    </ng-container>
  `,
})
export class AlertFacadeWidgetComponent {
  @Input() entity: AlertFacadeEntity | null = null;
  @Input() templateHint: string = null;
  @Input() options: any = null;
  @Input() layout = "standard";
  @Input() data: AlertEntity | null = null;
  @Input() deviceData: any = null;

  @Output() widgetEvent = new EventEmitter<AlertFacadeWidgetEventStruct>();
  forwardEvent(widgetEvent: AlertFacadeWidgetEventStruct) {
    this.widgetEvent.emit(widgetEvent);
  }
  public WidgetEnum = WidgetEnum_Alert_Facade;

  constructor() {}
  ngOnInit() {}
}
