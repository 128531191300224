import { Component, NgZone, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { UserRepo } from "../../repos/user.repo";
import { FirebaseAuthService } from "../../noizu/services/firebase-auth.service";
import { BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import { BsModalRef } from "ngx-bootstrap/modal";
import { UserPreferencesService } from "../../services/user-preferences.service";

@Component({
  templateUrl: "./list.component.html",
})
export class UserListComponent implements OnInit {
  public searchTerm = {
    lastName: null,
    email: null,
    id: null,
    displayName: null,
  };
  public rawResults: any;
  public searchResults: any;
  public currentModal: any;
  public resultsPerPage = 500;
  public pagination = {
    next: null,
    page: 0,
    cursors: { 0: "" },
    cursorArray: [""],
  };
  public state = {
    loading: false,
    hasResults: false,
    error: false,
    errorMsg: "",
  };
  public modalRef: BsModalRef;
  public noResultsMessage = "Search returned no matching entries";
  public trimInput = true;
  public firebaseEmail: string;
  public FBSearchResult: string = "";
  public fnf = "";
  public typingTimer: any;
  public listUsersToggle = false;
  public listUsersParams = { cursor: "", resultsPerPage: 1000 };
  public usersList = { cursor: "", data: null, loading: false };
  public resultSet = null;
  public version = "1.0";
  public version_toggle = false;

  constructor(
    public userRepo: UserRepo,
    public router: Router,
    public modalService: BsModalService,
    public fAuth: FirebaseAuthService,
    public preferences: UserPreferencesService,
    public zone: NgZone
  ) {
    this.zone.run(() => {});
  }

  loadVersion() {
    if (this.preferences) {
      this.preferences.session.readyPromise.then(() => {
        this.version = this.preferences.featureVersion("user-list", "2.0");
        this.version_toggle = this.version == "1.0" ? false : true;
      });
    }
  }

  ngOnInit() {
    this.loadVersion();
  }

  toggleVersion(event) {
    event.preventDefault();
    this.version = this.version_toggle ? "2.0" : "1.0";
    this.preferences.setFeatureVersion("user-list", this.version);
  }

  viewUser(user, event) {
    event.preventDefault();
    this.router.navigateByUrl(`/portal/users/show/${user.identifier}`);
  }

  clearSearchResults() {
    this.state.error = false;
    this.state.loading = true;
    this.searchResults = null;
    this.rawResults = null;
  }

  toggleTrim() {
    this.trimInput = !this.trimInput;
  }

  //After not typing for 400 miliseconds, update results to filter
  filterTextUpdate() {
    clearTimeout(this.typingTimer);
    this.typingTimer = setTimeout(this.setDisplayResults.bind(this), 400);
  }

  setDisplayResults() {
    if (this.fnf.length > 0) {
      this.noResultsMessage =
        "No results matching the filter found on this page";
      let filtered = [];
      for (let usr of this.rawResults.items) {
        if (
          usr["first_name"].toLowerCase().indexOf(this.fnf.toLowerCase()) > -1
        ) {
          filtered.push(usr);
        }
      }
      this.searchResults = { items: filtered };
    } else {
      this.noResultsMessage = "Search returned no matching entries";
      this.searchResults = this.rawResults;
    }
    if (this.rawResults.items.length < this.resultsPerPage) {
      this.pagination.next = null;
    }
  }

  listUsers(event) {
    event.preventDefault();
    if (this.version == "2.0") {
      this.state.loading = true;
      this.userRepo.getListPromise({ resultsPerPage: 1000 }).then((r) => {
        this.searchResults = r;
        this.state.loading = false;
      });
      this.listUsersToggle = false;
    } else {
      this.listUsersToggle = true;
    }
  }

  getListUsers(event) {
    event.preventDefault();
    this.usersList.loading = true;
    let o = { resultsPerPage: this.listUsersParams.resultsPerPage };
    if (this.listUsersParams.cursor && this.listUsersParams.cursor.length > 5) {
      o["cursor"] = this.listUsersParams.cursor;
    }
    this.userRepo
      .getRawListPromise(o)
      .then((results) => {
        this.usersList.cursor = results["cursor"];
        let s = "FirebaseID,createdOn,firstName,lastName,email,displayName\n";
        for (var i = 0; i < results["items"].length; i++) {
          let r = results["items"][i];
          s +=
            r.id +
            "," +
            r.createdOn +
            "," +
            r.firstName +
            "," +
            r.lastName +
            "," +
            r.email +
            "," +
            r.displayName +
            "\n";
        }
        this.usersList.data = s;
        this.usersList.loading = false;
      })
      .catch((e) => {
        this.usersList.loading = false;
        this.usersList.data = "Error loading Data";
      });
  }

  searchUsers(searchTerm, searchType, event) {
    event.preventDefault();
    this.clearSearchResults();
    this.pagination = {
      next: null,
      page: 0,
      cursors: { 0: "" },
      cursorArray: [""],
    };
    if (this.trimInput) {
      searchTerm = searchTerm.trim();
      if (searchType == "displayName" || searchType == "email")
        searchTerm = searchTerm.toLowerCase();
    }
    let options = {};
    options[searchType] = searchTerm;
    if (searchType == "lastName") {
      this.resultsPerPage = 750;
    } else {
      this.resultsPerPage = 500;
    }
    options["resultsPerPage"] = this.resultsPerPage;
    this.userRepo
      .getListPromise(options)
      .then((results) => {
        this.rawResults = results;
        this.pagination.next = this.rawResults.cursor;
        this.rawResults.options = options;
        this.state.loading = false;
        this.setDisplayResults();
      })
      .catch((err) => {
        this.state.loading = false;
        this.state.errorMsg = err.msg;
        this.state.error = true;
      });
  }

  searchAt(index, event) {
    event.preventDefault();
    let options = this.rawResults.options;
    options.cursor = this.pagination.cursors[index];
    options["resultsPerPage"] = this.resultsPerPage;
    this.state.loading = true;
    this.userRepo.getListPromise(options).then((results) => {
      this.pagination.page = index;
      this.rawResults = results;
      this.pagination.cursorArray[this.pagination.page] = options.cursor;
      this.pagination.cursors[this.pagination.page] = options.cursor;
      this.rawResults.options = options;
      this.state.loading = false;
      this.setDisplayResults();
    });
  }

  searchNext(event) {
    if (this.pagination.cursors[this.pagination.page + 1]) {
      this.searchAt(this.pagination.page + 1, event);
    } else {
      event.preventDefault();
      let options = this.rawResults.options;
      options.cursor = this.pagination.next;
      options["resultsPerPage"] = this.resultsPerPage;
      this.state.loading = true;
      this.userRepo.getListPromise(options).then((results) => {
        this.pagination.page++;
        this.rawResults = results;
        this.pagination.next = this.rawResults.cursor;
        this.pagination.cursorArray[this.pagination.page] = options.cursor;
        this.pagination.cursors[this.pagination.page] = options.cursor;
        this.rawResults.options = options;
        this.state.loading = false;
        this.setDisplayResults();
      });
    }
  }

  searchFirebase(e) {
    e.preventDefault();
    this.FBSearchResult = "L";
    this.userRepo
      .entity({})
      .searchFirebase(this.firebaseEmail)
      .then((res) => {
        if (res["success"] == true) {
          this.FBSearchResult = "Username exists";
        } else if (
          res["success"] == false &&
          res["error"] == "auth/user-not-found"
        ) {
          this.FBSearchResult = "User not found";
        } else {
          this.FBSearchResult = "Unknown error";
        }
      });
  }

  passwordReset(confirmModal, user, event) {
    event.preventDefault();
    this.currentModal = {
      title: "Confirm Password Reset",
      msg: `This action will send a user a password reset email.`,
      confirmMsg: "Yes, Proceed",
      confirm: () => {
        console.log(user);
        this.fAuth.resetPassword(user.email);
        this.modalRef.hide();
      },
      cancelMsg: "No, Cancel",
      cancel: () => {
        this.modalRef.hide();
      },
    };
    let config: ModalOptions = {
      backdrop: "static",
      keyboard: false,
    };
    this.modalRef = this.modalService.show(confirmModal, config);
  }
}
