import { Component, NgZone } from "@angular/core";
import { AuthService } from "../../services/auth.service";
import { PSKUploadEntity, VerificationCodeUploadEntity } from "../../entities";
import { FirebaseAuthService } from "../../noizu/services/firebase-auth.service";
import { HttpClient } from "@angular/common/http";
import { WidgetEventStruct } from "../../widgets";
import { DataManagementLogicalWidgetEnum } from "../../enums";
import { DataManagementWidgetEventStruct } from "../../widgets/widget/data-management";
import { ToasterService } from "angular2-toaster";

@Component({
  template: `
    <page-header [section]="'psk-verification-code-upload'">
      <page-title>PSK & Verification Code Upload</page-title>
      <page-blurb
        >Upload hard-coded versions of different PSK and Verification
        codes.</page-blurb
      >
    </page-header>

    <div class="row" *ngIf="!overrideAccess()">
      <div class="col">
        <div class="alert alert-danger">
          <p class="font-3xl">
            To access PSK and +3 Verification Code Upload you must have
            firmware_admin permissions.
          </p>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="overrideAccess()">
      <!-- Verification Code Upload -->
      <div class="col">
        <data-management-widget
          (widgetEvent)="eventHandler($event, 'verificationCode')"
          [entity]="verificationCodeUpload"
          [options]="{ edit: true }"
          [layout]="'standard'"
        ></data-management-widget>
      </div>
      <!-- PSK Upload -->
      <div class="col">
        <data-management-widget
          (widgetEvent)="eventHandler($event, 'psk')"
          [entity]="pskUpload"
          [options]="{ edit: true }"
          [layout]="'standard'"
        ></data-management-widget>
      </div>
    </div>
  `,
})
export class DataManagementUploadComponent {
  public pskUpload: PSKUploadEntity;
  public verificationCodeUpload: VerificationCodeUploadEntity;

  constructor(
    public zone: NgZone,
    public authService: AuthService,
    public toasterService: ToasterService,
    private httpClient: HttpClient,
    private auth: FirebaseAuthService
  ) {
    this.pskUpload = new PSKUploadEntity();
    this.verificationCodeUpload = new VerificationCodeUploadEntity();
  }

  eventHandler(event: WidgetEventStruct, source) {
    console.log("Event", event);
    if (event instanceof DataManagementWidgetEventStruct) {
      if (
        (event.event_data_management_widget_type =
          DataManagementLogicalWidgetEnum.DATA_MANAGEMENT_LOGICAL_WIDGET__PSK)
      ) {
        if (event.event_type == "upload_success") {
          this.toasterService.pop(
            "success",
            "PSK Upload Queued",
            `Job: ${event.event_body["response"]["job"]}`
          );
        }
        if (event.event_type == "upload_failure") {
          this.toasterService.pop(
            "warning",
            "PSK Upload Failed",
            "Unexpected Error"
          );
        }
        return;
      }

      if (
        (event.event_data_management_widget_type =
          DataManagementLogicalWidgetEnum.DATA_MANAGEMENT_LOGICAL_WIDGET__VERIFICATION_CODE)
      ) {
        if (event.event_type == "upload_success") {
          this.toasterService.pop(
            "success",
            "VerificationCode Upload Queued",
            `Job: #${event.event_body["response"]["job"]}`
          );
        }
        if (event.event_type == "upload_failure") {
          this.toasterService.pop(
            "warning",
            "VerificationCode Upload Failed",
            "Unexpected Error"
          );
        }
        return;
      }
    }
    console.log("unhandled event", source, event);
  }

  overrideAccess() {
    return this.authService.userHasPermission("firmware_admin");
  }
}
