import { NoizuStruct } from "../noizu/structs";

export class MaintenanceEntity extends NoizuStruct {
  public maintenance_mode = false;
  public reason = "";
  public from: Date = null;
  public to: Date = null;
  public user: string = null;

  constructor(json = null) {
    super();
    this.maintenance_mode = json ? true : false;
    if (json) {
      this.to = json.to && new Date(json.to);
      this.from = json.from && new Date(json.from);
      this.reason = json.reason;
      this.user = json.context.caller;
    }
  }
}
