import { Input, OnInit } from '@angular/core';

export class WidgetBase implements OnInit {
  @Input() options: any = null;
  public expand_settings = { toggle: false, lg: false };
  public expand_on_load = false;
  public lg_expand_icon = false;

  constructor() {}

  public ngOnInit(): void {
    if (this.options && 'expand_on_load' in this.options) {
      this.expand_settings.toggle = this.options.expand_on_load;
    } else {
      this.expand_settings.toggle = this.expand_on_load;
    }

    if (this.options && 'lg_expand_icon' in this.options) {
      this.expand_settings.lg = this.options.lg_expand_icon;
    } else {
      this.expand_settings.lg = this.lg_expand_icon;
    }
  }

  toggleExpand() {
    this.expand_settings.toggle = !this.expand_settings.toggle;
  }

  expandWidget() {
    return this.expand_settings.toggle;
  }

  isString(value) {
    return typeof value === 'string';
  }
}
