
    <div class="card card-accent-dark alert-facade-wind-widget">
      <!-- Card Header -->
      <div class="card-header bg-dark text-white">
        <h5 class="mb-0">Wind Alert</h5>
      </div>

      <!-- Card Body -->
      <div class="card-body">
        <!-- Device and Association Section -->
        <div class="mb-3">
          <div class="row">
            <div class="col-4 font-weight-bold">Device</div>
            <div class="col-8">
              <a
                [routerLink]="'/portal/devices/show/' + entity.device"
                class="text-primary"
                >{{ entity.device }}</a
              >
            </div>
          </div>
          <div class="row">
            <div class="col-4 font-weight-bold">Association</div>
            <div class="col-8">{{ entity.deviceAssociation }}</div>
          </div>
        </div>

        <hr class="my-3" />

        <div [ngSwitch]="entity.subType">
          <div *ngIf="entity?.identifier == null" class="mb-3">
            <div class="d-flex justify-content-between align-items-center">
              <div class="font-weight-bold">MPH</div>
              <div *ngIf="options?.edit" class="ml-2">
                <label class="switch switch-3d switch-primary">
                  <input
                    [checked]="selectedUnit === 'mph'"
                    class="switch-input switch-primary"
                    (change)="toggleUnit('mph')"
                    type="checkbox"
                  />
                  <span class="switch-slider"></span>
                </label>
              </div>
              <div class="font-weight-bold">M/S</div>
              <div *ngIf="options?.edit" class="ml-2">
                <label class="switch switch-3d switch-primary">
                  <input
                    [checked]="selectedUnit === 'm/s'"
                    class="switch-input switch-primary"
                    (change)="toggleUnit('m/s')"
                    type="checkbox"
                  />
                  <span class="switch-slider"></span>
                </label>
              </div>
              <div class="font-weight-bold">KM/H</div>
              <div *ngIf="options?.edit" class="ml-2">
                <label class="switch switch-3d switch-primary">
                  <input
                    [checked]="selectedUnit === 'km/h'"
                    class="switch-input switch-primary"
                    (change)="toggleUnit('km/h')"
                    type="checkbox"
                  />
                  <span class="switch-slider"></span>
                </label>
              </div>
            </div>
          </div>

          <hr class="my-3" />

          <!-- Wind Above Section -->
          <div class="mb-3">
            <div class="row">
              <div class="col-4 font-weight-bold">
                Wind Above (0 to
                {{
                  entity.windAbove.unit?.name === "MilesPerHour"
                    ? 75
                    : entity.windAbove.unit?.name === "MetersPerSecond"
                    ? 33
                    : 120
                }}
                {{
                  entity.windAbove.unit?.name === "MilesPerHour"
                    ? "MPH"
                    : entity.windAbove.unit?.name === "MetersPerSecond"
                    ? "MPS"
                    : "KMPH"
                }})
              </div>
              <div *ngIf="!options?.edit" class="col-8 text-muted">
                <b>{{ entity?.windAbove?.value }}</b>
                <span>{{ entity?.windAbove?.unit?.name }}</span>
              </div>
              <div *ngIf="options?.edit" class="col-8">
                <div class="d-flex" style="gap:5%;">
                  <input
                    name="wind_above"
                    type="range"
                    class="form-control-range"
                    (input)="inputChanges($event.target.value, 'windAbove')"
                    [(ngModel)]="entity.windAbove.value"
                    [min]="0"
                    [max]="
                      selectedUnit === 'mph'
                        ? 75
                        : selectedUnit === 'm/s'
                        ? 33
                        : 120
                    "
                    step="1"
                  />
                  <div>
                    <input
                      style="margin: 0 15px;"
                      name="wind_above"
                      type="number"
                      class="form-control"
                      (input)="inputChanges($event.target.value, 'windAbove')"
                      [(ngModel)]="entity.windAbove.value"
                      [min]="0"
                      [max]="
                        selectedUnit === 'mph'
                          ? 75
                          : selectedUnit === 'm/s'
                          ? 33
                          : 120
                      "
                      step="1"
                    />
                  </div>
                </div>
                <span class="ml-2">
                  {{ entity.windAbove.value }} -
                  {{ entity.windAbove.unit?.name }} {{ selectedUnit }}
                </span>
              </div>
            </div>
          </div>

          <!-- Wind Level Section 
        <div class="mb-3">
          <div class="row">
            <div class="col-4 font-weight-bold">Wind Level</div>
            <div *ngIf="!options?.edit" class="col-8 text-muted">
              <b>{{ entity.windLevel }}</b>
            </div>
            <div *ngIf="options?.edit" class="col-8">
              <ng-select
                placeholder="Wind Level"
                [items]="wind_levels"
                bindLabel="label"
                bindValue="value"
                [multiple]="false"
                [(ngModel)]="entity.windLevel"
                (ngModelChange)="facadeChange($event)"
              ></ng-select>
            </div>
          </div>
        </div>

        -->

          <hr class="my-3" />

          <div *ngIf="entity">
            <div class="row">
              <div
                *ngFor="let heading of headingKeys; let i = index"
                class="col-6 mb-3"
              >
                <div class="d-flex justify-content-between align-items-center">
                  <div class="font-weight-bold">{{ heading.label }}</div>
                  <div>
                    <div
                      *ngIf="options?.edit"
                      class="d-flex align-items-center"
                    >
                      <label class="switch switch-3d switch-primary">
                        <input
                          class="switch-input switch-primary"
                          (ngModelChange)="facadeChange($event)"
                          [(ngModel)]="entity[heading.key]"
                          type="checkbox"
                        />
                        <span class="switch-slider"></span>
                      </label>
                    </div>
                    <div *ngIf="!options?.edit" class="text-muted">
                      <b>{{ entity[heading.key] | json }}</b>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  