import { Component, NgZone, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Location } from "@angular/common";
import { FormGroup, Validators, FormBuilder } from "@angular/forms";
import { DeviceEntity } from "../../entities/device.entity";
import { DeviceRepo } from "../../repos/device.repo";
import { FirebaseAuthService } from "../../noizu/services/firebase-auth.service";
import { BsModalService } from "ngx-bootstrap/modal";
import { BsModalRef } from "ngx-bootstrap/modal";
import { AuthService } from "../../services/auth.service";
import { DeviceSet } from "../../entities/device/device.set";
import { DeviceDefinitionEntity, PinGroupEntity } from "../../entities";
import { DeviceDefinitionRepo, PinGroupRepo } from "../../repos";

@Component({
  selector: "device-dashboard",
  templateUrl: "./device-dashboard.component.html",
})
export class DeviceDashboardComponent implements OnInit {
  public deviceForm: FormGroup;
  public searchTerm: string;
  public state = {
    loading: false,
    hasResults: false,
    error: false,
    errorMsg: "",
  };
  public notification = { showLoading: false, message: "", iserror: false };
  public searchResults = null;
  public deviceGroup = null;
  public basicUserColumns = [
    "serial-with-verification",
    "type",
    "last_update",
    "actions",
  ];
  public advancedUserColumns = [
    "identifier",
    "serial-with-verification",
    "type",
    "last_update",
    "actions",
  ];
  public searchOptions = {};
  public pinOptions = {};

  public pinGroups: Array<PinGroupEntity> = [];
  public deviceEntity: DeviceEntity;
  public currentModal: any;
  public modalRef: BsModalRef;
  public pinnedDevices: Array<DeviceEntity> = [];

  constructor(
    public _fb: FormBuilder,
    public router: Router,
    public route: ActivatedRoute,
    public location: Location,
    public deviceRepo: DeviceRepo,
    public definitionRepo: DeviceDefinitionRepo,
    public modalService: BsModalService,
    public zone: NgZone,
    public activatedRoute: ActivatedRoute,
    public authHack: FirebaseAuthService,
    public authService: AuthService,
    public pinGroupRepo: PinGroupRepo
  ) {
    this.deviceForm = this._fb.group({
      searchTerm: [
        "",
        [<any>Validators.required, <any>Validators.minLength(6)],
      ],
    });

    this.zone.run(() => {
      this.pinGroupRepo
        .getListPromise({ pins: true })
        .then((r: Array<PinGroupEntity>) => {
          this.pinGroups = r;
          this.pinGroups.sort((a: PinGroupEntity, b: PinGroupEntity) => {
            return a.identifier >= b.identifier ? 1 : -1;
          });
        });
    });
  }

  hideMessage() {
    this.notification.message = "";
    this.notification.iserror = false;
  }

  clearSearchResults() {
    this.state.error = false;
    this.state.loading = true;
    this.searchResults = null;
  }

  public updateSearchOptions(no_results) {
    this.searchOptions = {
      header: true,
      columns:
        this.authHack.permissionLevel < 7
          ? this.advancedUserColumns
          : this.basicUserColumns,
      "allowed-actions": {
        "factory-reset": this.authHack.permissionLevel <= 7,
        "permission-reset": this.authHack.permissionLevel < 7,
      },
      msgs: { "no-results-msg": no_results },
    };
  }

  public updatePinOptions() {
    this.pinOptions = {
      "restrict-to": "devices",
      header: true,
      columns:
        this.authHack.permissionLevel < 7
          ? this.advancedUserColumns
          : this.basicUserColumns,
      "allowed-actions": {
        "factory-reset": this.authHack.permissionLevel <= 7,
        "permission-reset": this.authHack.permissionLevel < 7,
      },
      msgs: { "no-results-msg": "No Device Pins" },
    };
  }

  ngOnInit(): void {
    this.updatePinOptions();
  }

  searchDevice(term, event) {
    event.preventDefault();
    this.clearSearchResults();
    if (!term) {
      this.showError("You must provide a search term.");
    } else {
      this.deviceRepo
        .getBySerialPromise(term, { "return-null": true })
        .then((entity: DeviceEntity | null) => {
          if (entity) {
            if (entity["success"] === false) {
              this.showError(entity["msg"]);
              this.updateSearchOptions(entity["msg"]);
              this.deviceGroup = new DeviceSet([]);
              this.state.loading = false;
            } else {
              entity.getStatusPromise();
              this.definitionRepo
                .getEntityPromise(entity.sensorTypeEntityId)
                .then((definition: DeviceDefinitionEntity) => {
                  entity.type = definition;
                });
              this.updateSearchOptions(
                `No results found for search term [${term}]`
              );
              this.deviceGroup = new DeviceSet([entity]);
              this.state.loading = false;
            }
          } else {
            this.updateSearchOptions(
              `No results found for search term [${term}]`
            );
            this.deviceGroup = new DeviceSet([]);
            this.state.loading = false;
          }
        })
        .catch((err) => {
          console.log(err);
          this.deviceGroup = null;
          this.showError(err);
        });
    }
  }

  showError(error) {
    let msg = "unknown error or device not found, try again";
    if (error.length > 2) msg = error;
    console.log(error);
    this.state.loading = false;
    this.state.errorMsg = msg;
    this.state.error = true;
  }
}
