import { HttpClient } from "@angular/common/http";
import { FirebaseAuthService } from "../../../noizu/services/firebase-auth.service";
import { UserExtendedWidgetEnum, WidgetEnum } from "../../../enums";
import { UserExtendedEntity } from "./user-extended.entity";

export class ExtendedUserEffectiveSubscriptionEntity extends UserExtendedEntity {
  public view: any = { max: "none" };
  public export: any = { max: "none" };
  public notifications: any = {
    webhook: "none",
    sms: "none",
    push: "none",
    in_app: "none",
    email: "none",
  };

  constructor(client: HttpClient, auth: FirebaseAuthService, json) {
    super(client, auth, json);
    this.view = json["view"];
    this.export = json["export"];
    this.notifications = json["notifications"];
    this.raw = null;
  }

  widget_type(): any {
    return WidgetEnum.EMBED_WIDGET__USER_EXTENDED;
  }

  user_extended_widget_type(): any {
    return UserExtendedWidgetEnum.USER_EXTENDED_WIDGET__EFFECTIVE_SUBSCRIPTION;
  }
}
