import { Inject, Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { FirebaseAuthService } from "../noizu/services/firebase-auth.service";
import { ElixirRepo } from "./elixir.repo";
import { DataStreamCardEntity } from "../entities/data-stream-card.entity";

@Injectable()
export class DataStreamCardRepo extends ElixirRepo {
  public _kind = "card";
  public _singular = "card";
  public _version = "v1.2";

  constructor(
    @Inject(HttpClient) client: HttpClient,
    @Inject(FirebaseAuthService) auth: FirebaseAuthService
  ) {
    super(client, auth);
  }

  entity(record) {
    return new DataStreamCardEntity(this.client, this.auth, record);
  }

  putCardAfter(cardA: DataStreamCardEntity, cardB: DataStreamCardEntity) {
    const url = `${cardA.apiBase()}/displays/${
      cardA.feed
    }/data-stream/${cardA.sref()}/after/${cardB.sref()}`;
    return this._put(
      url,
      {},
      (json, resolve) => {
        resolve(json);
      },
      {}
    );
  }

  putCardBefore(cardA: DataStreamCardEntity, cardB: DataStreamCardEntity) {
    const url = `${cardA.apiBase()}/displays/${
      cardA.feed
    }/data-stream/${cardA.sref()}/before/${cardB.sref()}`;
    return this._put(
      url,
      {},
      (json, resolve) => {
        resolve(json);
      },
      {}
    );
  }

  getEntityPromise(display, id, options = {}) {
    return this._getEntityPromise(
      `${this.apiBase()}/displays/${display}/data-stream/${id}`,
      (record, resolve) => {
        resolve(this.entity(record));
      },
      options
    );
  }

  getListPromise(display, options = {}) {
    return this._get(
      `${this.apiBase()}/displays/${display}/data-stream`,
      (json, resolve) => {
        const response = []; // @todo DataStreamCardSet
        if (json && json["cards"]) {
          for (let i = 0; i < json.cards.length; i++) {
            response.push(
              new DataStreamCardEntity(this.client, this.auth, json.cards[i])
            );
          }
        } else {
          // error handling.
        }
        resolve(response);
      },
      options
    );
  }
}
