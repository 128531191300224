
    <div class="alert alert-info bg-primary">
      <div class="row align-items-center" *ngFor="let entry of listFormat">
        <div class="col-auto d-flex align-items-center justify-content-center">
          <b>{{ entry.key }}</b>
        </div>
        <div class="col-auto d-flex align-items-center justify-content-center">
          <ng-container *ngIf="entry.is_object">
            <render-object
              [object]="entry.value"
              [exclude]="exclude"
            ></render-object>
          </ng-container>
          <ng-container *ngIf="!entry.is_object">
            {{ entry.value | json }}
          </ng-container>
        </div>
      </div>
    </div>
  