
    <div *ngIf="entity">
      <ng-template #approvalDetails>
        <div class="row">
          <div class="col">{{ entity.identifier }}</div>
          <div
            class="col"
            *ngIf="
              !entity?.validation?.valid &&
              entity?.validation?.messages['common']
            "
          >
            <div class="alert alert-warning">
              {{ entity?.validation?.messages["common"] }}
            </div>
          </div>
          <div
            class="col"
            *ngIf="
              !entity?.validation?.valid &&
              entity?.validation?.messages['reference']
            "
          >
            <div class="alert danger">
              {{ entity?.validation?.messages["reference"] }}
            </div>
          </div>
          <div class="col">{{ entity.reference }}</div>
          <div
            class="col"
            *ngIf="
              !entity?.validation?.valid &&
              entity?.validation?.messages['comment']
            "
          >
            <div class="alert danger">
              {{ entity?.validation?.messages["comment"] }}
            </div>
          </div>

          <div class="col">
            <ng-container *ngIf="options?.create_approval">
              <inline-editor
                type="text"
                [(ngModel)]="entity.comment"
                size="64"
              ></inline-editor>
            </ng-container>
            <ng-container *ngIf="!options?.create_approval">
              {{ entity.comment }}
            </ng-container>
          </div>
          <div
            class="col"
            *ngIf="
              !entity?.validation?.valid &&
              entity?.validation?.messages['approver']
            "
          >
            <div class="alert danger">
              {{ entity?.validation?.messages["approver"] }}
            </div>
          </div>
          <div class="col">{{ entity.approver }}</div>
          <div class="col">{{ entity.approved_on || "(null)" }}</div>
        </div>
      </ng-template>
      <div [ngSwitch]="layout">
        <div *ngSwitchCase="'thumbnail'">
          <ng-container *ngTemplateOutlet="approvalDetails"></ng-container>
        </div>
        <div *ngSwitchCase="'sidebar'">
          <ng-container *ngTemplateOutlet="approvalDetails"></ng-container>
        </div>
        <div *ngSwitchCase="'shadowbox'">
          <ng-container *ngTemplateOutlet="approvalDetails"></ng-container>
        </div>
        <div *ngSwitchDefault>
          <div class="card p-1 mb-1 rounded">
            <div class="card-body">
              <ng-container *ngTemplateOutlet="approvalDetails"></ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  