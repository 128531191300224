
    <div class="device-link-widget" *ngIf="!entity?.device">
      <widget-spinner></widget-spinner>
    </div>
    <div class="device-link-widget" *ngIf="entity?.device">
      <device-entity-widget
        (widgetEvent)="forwardEvent($event)"
        [entity]="entity.device"
        [layout]="layout"
        [options]="options"
      ></device-entity-widget>
    </div>
  