import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { HttpHeaders } from "@angular/common/http";

@Injectable()
export class FirebaseAuthService {
  public tokenPromise: Promise<any>;
  public _resolve;
  public _reject;
  public _token: any;
  public _fba: any = null;
  public _fbs: any = null;
  public isLoggedIn = false;
  public permissionLevel = 11; // Used for generic permissions
  public obsPL = new Subject<number>();
  public permissionsList = {}; // Specific permissions for when permissionLevel is too generic
  // simple observable/observer. Auth Subscribes to this for is logged in checks
  public permissionStatus = new Subject<number>();

  constructor() {
    this.tokenPromise = new Promise((resolve, reject) => {
      this._resolve = resolve;
      this._reject = reject;
    });
    this.permissionStatus.subscribe((val) => {
      if (val === 1) {
        this.isLoggedIn = true;
      } else {
        this.isLoggedIn = false;
      }
    });
  }

  resetPassword(email: any) {
    // Super Hackey.
    if (this._fba) {
      this._fba.auth.sendPasswordResetEmail(email);
    } else {
      alert("Error Auth not yet available");
    }
  }

  activeUser() {
    if (this._fba && this._fba.auth) {
      return this._fba.auth.currentUser;
    } else {
      return null;
    }
  }

  // Firebase authentication stuff
  setFBS(fba: any) {
    this._fba = fba;
    this._fbs = fba.auth.currentUser;
    this._fbs.getIdToken().then((token) => {
      // backwards compatibility hack.
      this.setToken(token);
    });
  }

  // Get the bearer token if available (used for verification of user)
  getTokenPromise() {
    if (this._fbs) {
      // hack to work with lacrosse
      return this._fbs.getIdToken();
    } else {
      return this.tokenPromise;
    }
  }

  clearAdmin() {
    this.permissionsList = {};
    this.permissionLevel = 10;
    this.isLoggedIn = false;
    return;
  }

  // Somewhat arbitrary numbers, but I chose 0 = full access, 5 = support, 10 = no access
  // Great for allowing many permissions with one change,
  setAdmin(clear = false) {
    if (clear) {
      this.permissionsList = {};
      this.permissionLevel = 10;
      this.isLoggedIn = false;
      return;
    }
    if (this.permissionsList["super-user"]) {
      this.permissionLevel = 0;
    } else if (this.permissionsList["support-admin"]) {
      this.permissionLevel = 5;
    } else if (this.permissionsList["call-center"]) {
      this.permissionLevel = 7;
    } else {
      this.permissionLevel = 10;
    }
    this.obsPL.next(this.permissionLevel);
  }

  // Compare user permissions to the requested parameters
  hasPermission(perm = "null", level = 1) {
    if (perm == null) {
      perm = "null";
    }
    if (
      this.permissionLevel <= level ||
      (perm != "null" && this.permissionsList[perm] == 1)
    ) {
      return true;
    }
    return false;
  }

  setToken(token) {
    this._resolve(token);
  }

  // Generate headers for a REST call, use Options to customize
  headers(token, options): HttpHeaders {
    let expand_all_refs = options["expand_all_refs"] || false;
    let expand_refs = options["expand_refs"] || false;

    let authHeader = options["authorizationHeader"] === "false" ? false : true;
    let contentH = options["contentTypeHeader"] === "false" ? false : true;
    let addhmac =
      options["X-Hmac-Token"] && options["X-Hmac-Token"].length > 0
        ? true
        : false;

    let headers = new HttpHeaders();
    if (contentH) {
      headers = headers.append("content-type", "application/json");
    } else {
      headers = headers.append("content-type", "multipart/form-data");
    }
    //For the special requests that need Hmac, pretty sure this is no longer needed
    //Simulate device required it, but should be exclusively in the Node server now.
    if (addhmac) {
      headers = headers.append("x-hmac-token", options["X-Hmac-Token"]);
    }
    //Auth header is used for most requests, but some require it to not be there
    if (authHeader === false) {
      headers = headers.append("authorization", "false");
    } else {
      headers = headers.append("authorization", "Bearer " + token);
    }

    if (expand_all_refs) {
      headers = headers.append("x-expand-all-refs", "true");
    }
    if (expand_refs) {
      headers = headers.append("x-expand-refs", expand_refs);
    }

    return headers;
  }

  request_options(token, options) {
    let headers = this.headers(token, options);
    let requestOptions = {
      headers: headers,
    };
    return requestOptions;
  }
}
