import { Component, NgZone } from "@angular/core";
import { BatchEntity } from "../../entities";
import { BatchRepo } from "../../repos";
import { AuthService } from "../../services/auth.service";

@Component({
  template: `
    <page-header [section]="'batches'">
      <page-title>Batches</page-title>
      <page-blurb
        >View and manage the current list of batches. Add new batch entries to
        the list.</page-blurb
      >
    </page-header>

    <spinner *ngIf="!entries"></spinner>
    <div class="row">
      <div class="col">
        <widget
          [entity]="entries"
          [options]="{ edit: edit, columns: 2, sideBarWidth: 5 }"
          [layout]="'table'"
        ></widget>
      </div>
    </div>
  `,
})
export class DataManagementBatchesComponent {
  public entries: Array<BatchEntity>;
  public edit = false;

  constructor(
    public repo: BatchRepo,
    public zone: NgZone,
    public authService: AuthService
  ) {
    this.zone.run(() => {
      if (this.authService.loadedPromise) {
        this.authService.loadedPromise.then(() => {
          if (this.authService.userHasPermission("firmware_admin")) {
            this.edit = true;
          }
        });
      }

      this.repo.getListPromise().then((u: any) => {
        this.entries = u;
      });
    });
  }
}
