import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ToasterService } from "angular2-toaster";
import { AlertRainfallFacadeEntity } from "../../../../entities/alert";
import { AlertFacadeDefaultWidgetComponent } from "./default.widget.component";
import { AlertFacadeWidgetEventStruct } from "./alert-facade-widget-event.struct";
import { UnitEnum } from "app/enums";

@Component({
  selector: "alert-facade-rainfall-widget",
  template: `
    <div class="card card-accent-dark alert-facade-rainfall-widget">
      <div class="card-header bg-dark text-white">
        <h5 class="mb-0">Rainfall Alert</h5>
      </div>
      <div class="card-body">
        <!-- Device and Association Section -->
        <div class="mb-3">
          <div class="row">
            <div class="col-4 font-weight-bold">Device</div>
            <div class="col-8">
              <a
                [routerLink]="'/portal/devices/show/' + entity.device"
                class="text-primary"
                >{{ entity.device }}</a
              >
            </div>
          </div>
          <div class="row">
            <div class="col-4 font-weight-bold">Association</div>
            <div class="col-8">{{ entity.deviceAssociation }}</div>
          </div>
        </div>

        <hr class="my-3" />

        <!-- Unit Toggle Section -->
        <div *ngIf="entity?.identifier == null" class="mb-3">
          <div class="d-flex justify-content-between align-items-center">
            <div class="font-weight-bold">
              Choose Millimeters / Inches (keep off for Millimeters)
            </div>
            <div *ngIf="options?.edit" class="ml-2">
              <label class="switch switch-3d switch-primary">
                <input
                  checked=""
                  class="switch-input switch-primary"
                  (change)="toggleMillimetersToInches($event)"
                  [(ngModel)]="unitValue"
                  type="checkbox"
                />
                <span class="switch-slider"></span>
              </label>
            </div>
          </div>
        </div>

        <hr class="my-3" />

        <!-- Precipitation Level Section -->
        <div *ngIf="entity.hasPrecipitationLevel || options?.edit" class="mb-3">
          <div class="d-flex justify-content-between align-items-center">
            <div class="font-weight-bold">Precipitation Level</div>
            <div *ngIf="options?.edit" class="ml-2">
              <label class="switch switch-3d switch-primary">
                <input
                  checked=""
                  class="switch-input switch-primary"
                  (change)="toggleOptional($event)"
                  [(ngModel)]="entity.hasPrecipitationLevel"
                  type="checkbox"
                />
                <span class="switch-slider"></span>
              </label>
            </div>
            <div *ngIf="!options?.edit" class="text-muted">
              <b>{{ entity.precipitationLevel }}</b>
            </div>
            <div *ngIf="options?.edit" class="w-50">
              <ng-container *ngIf="entity.hasPrecipitationLevel">
                <ng-select
                  placeholder="Precipitation Level"
                  [items]="precipitation_levels"
                  bindLabel="label"
                  bindValue="value"
                  [multiple]="false"
                  [(ngModel)]="entity.precipitationLevel"
                  (ngModelChange)="facadeChange($event)"
                ></ng-select>
              </ng-container>
            </div>
          </div>
        </div>

        <!-- No Rain Section -->
        <div *ngIf="entity.hasNoRain || options?.edit" class="mb-3">
          <div class="d-flex justify-content-between align-items-center">
            <div *ngIf="!options?.edit" class="col-12 text-muted">
              No rain for [<b>{{ entity.noRainForXDays }}</b
              >] days.
            </div>
            <ng-container *ngIf="options?.edit">
              <div class="font-weight-bold">No rain for (0 to 30 Days.)</div>
              <div class="ml-2">
                <label class="switch switch-3d switch-primary">
                  <input
                    checked=""
                    class="switch-input switch-primary"
                    (change)="toggleOptional($event)"
                    [(ngModel)]="entity.hasNoRain"
                    type="checkbox"
                  />
                  <span class="switch-slider"></span>
                </label>
              </div>
              <div class="w-50">
                <ng-container *ngIf="entity.hasNoRain">
                  <div class="d-flex" style="gap:5%;">
                    <input
                      name="no_rain"
                      type="range"
                      class="form-control-range"
                      (ngModelChange)="facadeChange($event)"
                      [(ngModel)]="entity.noRainForXDays"
                      [min]="0"
                      [max]="30"
                      step="1"
                    />
                    <div>
                      <input
                        style="margin: 0 15px;"
                        name="no_rain"
                        type="number"
                        class="form-control"
                        (ngModelChange)="facadeChange($event)"
                        [(ngModel)]="entity.noRainForXDays"
                        [min]="0"
                        [max]="30"
                      />
                    </div>
                  </div>
                  <span class="ml-2">{{ entity.noRainForXDays }}</span> days.
                </ng-container>
              </div>
            </ng-container>
          </div>
        </div>

        <!-- Hourly Accumulation Section -->
        <div
          *ngIf="entity.hasHourlyAccumulationAbove || options?.edit"
          class="mb-3"
        >
          <div class="d-flex justify-content-between align-items-center">
            <div class="font-weight-bold">
              Hourly Above ({{
                entity.hourlyAccumulationAbove.unit?.name === "Inches" ? 0.1 : 1
              }}
              to
              {{
                entity.hourlyAccumulationAbove.unit?.name === "Inches"
                  ? 24
                  : 600
              }}
              {{
                entity.hourlyAccumulationAbove.unit?.name === "Inches"
                  ? "Inches"
                  : "Millimeters"
              }})
            </div>
            <div *ngIf="options?.edit" class="ml-2">
              <label class="switch switch-3d switch-primary">
                <input
                  checked=""
                  class="switch-input switch-primary"
                  (change)="toggleFrequency('hourly', $event)"
                  [(ngModel)]="entity.hasHourlyAccumulationAbove"
                  type="checkbox"
                />
                <span class="switch-slider"></span>
              </label>
            </div>
            <div *ngIf="!options?.edit" class="text-muted">
              <b>{{ entity.hourlyAccumulationAbove.value }}</b>
              <span>{{ entity.hourlyAccumulationAbove.unit.name }}</span>
            </div>
            <div *ngIf="options?.edit" class="w-50">
              <ng-container *ngIf="entity.hasHourlyAccumulationAbove">
                <div class="d-flex" style="gap:5%;">
                  <input
                    name="temp_above"
                    type="range"
                    class="form-control-range"
                    (ngModelChange)="facadeChange($event)"
                    [(ngModel)]="entity.hourlyAccumulationAbove.value"
                    [min]="
                      entity.hourlyAccumulationAbove.unit?.name === 'Inches' ||
                      unitValue
                        ? 0.1
                        : 1
                    "
                    [max]="
                      entity.hourlyAccumulationAbove.unit?.name === 'Inches' ||
                      unitValue
                        ? 24
                        : 600
                    "
                    [step]="
                      entity.hourlyAccumulationAbove.unit?.name === 'Inches' ||
                      unitValue
                        ? 0.1
                        : 1
                    "
                  />
                  <div>
                    <input
                      style="margin: 0 15px;"
                      name="temp_above"
                      type="number"
                      class="form-control"
                      (ngModelChange)="facadeChange($event)"
                      [(ngModel)]="entity.hourlyAccumulationAbove.value"
                      [min]="
                        entity.hourlyAccumulationAbove.unit?.name ===
                          'Inches' || unitValue
                          ? 0.1
                          : 1
                      "
                      [max]="
                        entity.hourlyAccumulationAbove.unit?.name ===
                          'Inches' || unitValue
                          ? 24
                          : 600
                      "
                      [step]="
                        entity.hourlyAccumulationAbove.unit?.name ===
                          'Inches' || unitValue
                          ? 0.1
                          : 1
                      "
                    />
                  </div>
                </div>
                <span class="ml-2">
                  {{ entity.hourlyAccumulationAbove.value }} -
                  {{ entity.hourlyAccumulationAbove.unit?.name }}
                </span>
              </ng-container>
            </div>
          </div>
        </div>

        <!-- Daily Accumulation Section -->
        <div
          *ngIf="entity.hasDailyAccumulationAbove || options?.edit"
          class="mb-3"
        >
          <div class="d-flex justify-content-between align-items-center">
            <div class="font-weight-bold">
              Daily Above ({{
                entity.hourlyAccumulationAbove.unit?.name === "Inches" ? 0.1 : 1
              }}
              to
              {{
                entity.hourlyAccumulationAbove.unit?.name === "Inches"
                  ? 24
                  : 600
              }}
              {{
                entity.hourlyAccumulationAbove.unit?.name === "Inches"
                  ? "Inches"
                  : "Millimeters"
              }})
            </div>
            <div *ngIf="options?.edit" class="ml-2">
              <label class="switch switch-3d switch-primary">
                <input checked="" class="switch-input switch-primary"
                (change)=toggleFrequency('daily', $event)
                [(ngModel)]="entity.hasDailyAccumulationAbove" type="checkbox"
                />
                <span class="switch-slider"></span>
              </label>
            </div>
            <div *ngIf="!options?.edit" class="text-muted">
              <b>{{ entity.dailyAccumulationAbove.value }}</b>
              <span>{{ entity.dailyAccumulationAbove.unit.name }}</span>
            </div>
            <div *ngIf="options?.edit" class="w-50">
              <ng-container *ngIf="entity.hasDailyAccumulationAbove">
                <div class="d-flex" style="gap:5%;">
                  <input
                    name="temp_above"
                    type="range"
                    class="form-control-range"
                    (ngModelChange)="facadeChange($event)"
                    [(ngModel)]="entity.dailyAccumulationAbove.value"
                    [min]="
                      entity.dailyAccumulationAbove.unit?.name === 'Inches' ||
                      unitValue
                        ? 0.1
                        : 1
                    "
                    [max]="
                      entity.dailyAccumulationAbove.unit?.name === 'Inches' ||
                      unitValue
                        ? 24
                        : 600
                    "
                    [step]="
                      entity.dailyAccumulationAbove.unit?.name === 'Inches' ||
                      unitValue
                        ? 0.1
                        : 1
                    "
                  />
                  <div>
                    <input
                      name="temp_above"
                      type="number"
                      style="margin: 0 15px;"
                      class="form-control"
                      (ngModelChange)="facadeChange($event)"
                      [(ngModel)]="entity.dailyAccumulationAbove.value"
                      [min]="
                        entity.dailyAccumulationAbove.unit?.name === 'Inches' ||
                        unitValue
                          ? 0.1
                          : 1
                      "
                      [max]="
                        entity.dailyAccumulationAbove.unit?.name === 'Inches' ||
                        unitValue
                          ? 24
                          : 600
                      "
                      [step]="
                        entity.dailyAccumulationAbove.unit?.name === 'Inches' ||
                        unitValue
                          ? 0.1
                          : 1
                      "
                    />
                  </div>
                </div>
                <span class="ml-2">
                  {{ entity.dailyAccumulationAbove.value }} -
                  {{ entity.dailyAccumulationAbove.unit?.name }}
                </span>
              </ng-container>
            </div>
          </div>
        </div>

        <!-- Weekly Accumulation Section -->
        <div
          *ngIf="entity.hasWeeklyAccumulationAbove || options?.edit"
          class="mb-3"
        >
          <div class="d-flex justify-content-between align-items-center">
            <div class="font-weight-bold">
              Weekly Above ({{
                entity.hourlyAccumulationAbove.unit?.name === "Inches" ? 0.1 : 1
              }}
              to
              {{
                entity.hourlyAccumulationAbove.unit?.name === "Inches"
                  ? 24
                  : 600
              }}
              {{
                entity.hourlyAccumulationAbove.unit?.name === "Inches"
                  ? "Inches"
                  : "Millimeters"
              }})
            </div>
            <div *ngIf="options?.edit" class="ml-2">
              <label class="switch switch-3d switch-primary">
                <input checked="" class="switch-input switch-primary"
                (change)=toggleFrequency('weekly', $event)
                [(ngModel)]="entity.hasWeeklyAccumulationAbove" type="checkbox"
                />
                <span class="switch-slider"></span>
              </label>
            </div>
            <div *ngIf="!options?.edit" class="text-muted">
              <b>{{ entity.weeklyAccumulationAbove.value }}</b>
              <span>{{ entity.weeklyAccumulationAbove.unit.name }}</span>
            </div>
            <div *ngIf="options?.edit" class="w-50">
              <ng-container *ngIf="entity.hasWeeklyAccumulationAbove">
                <div class="d-flex" style="gap:5%;">
                  <input
                    name="temp_above"
                    type="range"
                    class="form-control-range"
                    (ngModelChange)="facadeChange($event)"
                    [(ngModel)]="entity.weeklyAccumulationAbove.value"
                    [min]="
                      entity.weeklyAccumulationAbove.unit?.name === 'Inches' ||
                      unitValue
                        ? 0.1
                        : 1
                    "
                    [max]="
                      entity.weeklyAccumulationAbove.unit?.name === 'Inches' ||
                      unitValue
                        ? 24
                        : 600
                    "
                    [step]="
                      entity.weeklyAccumulationAbove.unit?.name === 'Inches' ||
                      unitValue
                        ? 0.1
                        : 1
                    "
                  />
                  <div>
                    <input
                      type="number"
                      style="margin: 0 15px;"
                      name="temp_above"
                      class="form-control"
                      (ngModelChange)="facadeChange($event)"
                      [(ngModel)]="entity.weeklyAccumulationAbove.value"
                      [min]="
                        entity.weeklyAccumulationAbove.unit?.name ===
                          'Inches' || unitValue
                          ? 0.1
                          : 1
                      "
                      [max]="
                        entity.weeklyAccumulationAbove.unit?.name ===
                          'Inches' || unitValue
                          ? 24
                          : 600
                      "
                      [step]="
                        entity.weeklyAccumulationAbove.unit?.name ===
                          'Inches' || unitValue
                          ? 0.1
                          : 1
                      "
                    />
                  </div>
                </div>
                <span class="ml-2">
                  {{ entity.weeklyAccumulationAbove.value }} -
                  {{ entity.weeklyAccumulationAbove.unit?.name }}
                </span>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
})
export class EmbedAlertRainfallFacadeComponent extends AlertFacadeDefaultWidgetComponent {
  public editMode = false;
  @Input() layout: string = null;
  @Input() entity: AlertRainfallFacadeEntity;
  @Input() deviceData: any = null;
  @Input() options: any;
  @Output() widgetEvent = new EventEmitter<AlertFacadeWidgetEventStruct>();

  public unitValue: boolean = false;

  public precipitation_levels: any = [
    { value: 1, label: "Light" },
    { value: 2, label: "Moderate" },
    { value: 3, label: "Heavy" },
  ];

  toggleMillimetersToInches(e: any) {
    if (this.unitValue) {
      let inches = {
        name: "Inches",
        enum: UnitEnum.Inches,
      };
      if (this.entity.hourlyAccumulationAbove)
        this.entity.hourlyAccumulationAbove.value = 0.1;
      if (this.entity.dailyAccumulationAbove)
        this.entity.dailyAccumulationAbove.value = 0.1;
      if (this.entity.weeklyAccumulationAbove)
        this.entity.weeklyAccumulationAbove.value = 0.1;
      if (this.entity?.editDailyAccumulationAbove?.unit)
        Object.assign(this.entity.editDailyAccumulationAbove.unit, inches);
      if (this.entity?.editHourlyAccumulationAbove?.unit)
        Object.assign(this.entity.editHourlyAccumulationAbove.unit, inches);
      if (this.entity?.editWeeklyAccumulationAbove?.unit)
        Object.assign(this.entity.editWeeklyAccumulationAbove.unit, inches);
      if (this.entity?.dailyAccumulationAbove?.unit)
        Object.assign(this.entity.dailyAccumulationAbove.unit, inches);
      if (this.entity?.weeklyAccumulationAbove?.unit)
        Object.assign(this.entity.weeklyAccumulationAbove.unit, inches);
      if (this.entity?.hourlyAccumulationAbove?.unit)
        Object.assign(this.entity.hourlyAccumulationAbove.unit, inches);
    } else {
      let millimeters = {
        name: "Millimeters",
        enum: UnitEnum.Millimeters,
      };
      if (this.entity.hourlyAccumulationAbove)
        this.entity.hourlyAccumulationAbove.value = 1;
      if (this.entity.dailyAccumulationAbove)
        this.entity.dailyAccumulationAbove.value = 1;
      if (this.entity.weeklyAccumulationAbove)
        this.entity.weeklyAccumulationAbove.value = 1;
      if (this.entity?.editDailyAccumulationAbove?.unit)
        Object.assign(this.entity.editDailyAccumulationAbove.unit, millimeters);
      if (this.entity?.editHourlyAccumulationAbove?.unit)
        Object.assign(
          this.entity.editHourlyAccumulationAbove.unit,
          millimeters
        );
      if (this.entity?.editWeeklyAccumulationAbove?.unit)
        Object.assign(
          this.entity.editWeeklyAccumulationAbove.unit,
          millimeters
        );
      if (this.entity?.dailyAccumulationAbove?.unit)
        Object.assign(this.entity.dailyAccumulationAbove.unit, millimeters);
      if (this.entity?.weeklyAccumulationAbove?.unit)
        Object.assign(this.entity.weeklyAccumulationAbove.unit, millimeters);
      if (this.entity?.hourlyAccumulationAbove?.unit)
        Object.assign(this.entity.hourlyAccumulationAbove.unit, millimeters);
    }
  }

  toggleFrequency(freq, e) {
    switch (freq) {
      case "hourly":
        this.entity.hasHourlyAccumulationAbove = true;
        this.entity.hasDailyAccumulationAbove = false;
        this.entity.hasWeeklyAccumulationAbove = false;
        break;

      case "daily":
        this.entity.hasHourlyAccumulationAbove = false;
        this.entity.hasDailyAccumulationAbove = true;
        this.entity.hasWeeklyAccumulationAbove = false;
        break;

      case "weekly":
        this.entity.hasHourlyAccumulationAbove = false;
        this.entity.hasDailyAccumulationAbove = false;
        this.entity.hasWeeklyAccumulationAbove = true;
        break;

      default:
        this.entity.hasHourlyAccumulationAbove = true;
        this.entity.hasDailyAccumulationAbove = false;
        this.entity.hasWeeklyAccumulationAbove = false;
    }
  }

  setDeviceValues() {
    if (!this.options.edit) return;
    this.entity.device = this.deviceData.deviceId;
    this.entity.deviceAssociation = this.deviceData.associationId;
  }
  ngOnInit() {
    this.entity.hasHourlyAccumulationAbove = true;
    if (
      this.deviceData.deviceId !== undefined &&
      this.deviceData.associationId !== undefined
    ) {
      this.setDeviceValues();
    }
  }

  forwardEvent(e, widgetEvent: AlertFacadeWidgetEventStruct) {
    e.preventDefault();
    this.widgetEvent.emit(widgetEvent);
  }
  constructor(public toasterService: ToasterService) {
    super();
  }
}
