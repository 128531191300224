import { NoizuStruct } from "./noizu-struct";
import { WidgetEnum } from "../../enums";

export class EntityList extends NoizuStruct {
  public kind: string;
  public repo: any = null;
  public items: Array<any>;
  public cursor: string = "";
  public options: any = {};
  public containerContents: WidgetEnum = null;
  public endOfList = false;
  public queryable = true;

  constructor(
    kind,
    items,
    cursor,
    containerContents: WidgetEnum = null,
    repo = null,
    options
  ) {
    super();
    this.kind = kind;
    this.items = items;
    this.cursor = cursor;
    this.containerContents = containerContents;
    this.options = options;
  } // end constructor

  widget_type() {
    return this.containerContents || WidgetEnum.EMBED_WIDGET__GENERIC_SET;
  }

  hasEntities() {
    return (this.items && this.items.length > 0) || false;
  }

  nextPromise(options = {}) {
    return new Promise((r, f) => {
      r(true);
    });
  }
}
