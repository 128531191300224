import { Component, Input, Output, OnInit, EventEmitter } from "@angular/core";
import { Observable } from "rxjs";

@Component({
  selector: "alert-facade-selector-logical-widget",
  template: `
    <ng-container *ngIf="!entity">
      <div class="row">
        <div class="col">
          <new-spinner [size]="'sm'"></new-spinner>
        </div>
      </div>
    </ng-container>
    <ng-container class="alert-facade-selector-logical-widget" *ngIf="entity">
      <div *ngIf="options?.edit">
        <div *ngIf="valueSubscriber | async as values; else loading">
          <ng-select
            placeholder="Alert Template"
            [items]="values"
            bindLabel="label"
            bindValue="value"
            [multiple]="false"
            [(ngModel)]="entity.selected"
            (ngModelChange)="updateOutput()"
          ></ng-select>
        </div>

        <ng-template #loading>
          <div class="row">
            <div class="col">
              <new-spinner [size]="'sm'"></new-spinner>
            </div>
          </div>
        </ng-template>
      </div>
      <div *ngIf="!options?.edit" class="align-middle text-center">
        {{ facade_types[entity.selected] }}
      </div>
    </ng-container>
  `,
})
export class AlertFacadeSelectorLogicalWidgetComponent implements OnInit {
  @Input() entity: any = null;
  @Input() templateHint: string = null;
  @Input() options: any = null;
  @Input() layout = "standard";
  @Input() deviceData: any = null;

  @Output() widgetEvent = new EventEmitter<any>();

  public facade_types: any;
  public graphTypesForCategory: number[] = [];
  public valueSubscriber: any;

  constructor() {
    this.facade_types = {
      ALERT_FACADE_WIDGET__CONTACT: "Contact",
      ALERT_FACADE_WIDGET__HUMIDITY: "Humidity",
      ALERT_FACADE_WIDGET__LIGHTNING: "Lightning",
      ALERT_FACADE_WIDGET__MOTION: "Motion",
      ALERT_FACADE_WIDGET__ON_OFF: "On Off",
      ALERT_FACADE_WIDGET__PROBE: "Probe",
      ALERT_FACADE_WIDGET__RAINFALL: "Rainfall",
      ALERT_FACADE_WIDGET__TEMPERATURE: "Temperature",
      ALERT_FACADE_WIDGET__THERMOMETER: "Thermometer",
      ALERT_FACADE_WIDGET__WET_DRY: "Wet/Dry",
      ALERT_FACADE_WIDGET__WIND: "Wind",
      ALERT_FACADE_WIDGET__DEFAULT: "Raw",
    };
  }

  ngOnInit() {
    this.entity.selected = this.entity.subject;

    if (this.deviceData) {
      this.graphTypesForCategory = this.getGraphTypesForCategory(
        this.deviceData.deviceCategory
      );
    }

    this.valueSubscriber = new Observable<any>((observer) => {
      observer.next(this.getFormattedResponse());
    });
  }

  getGraphTypesForCategory(categoryId: number | null): number[] {
    return categoryId !== null && sensorCategoryToGraphTypeMapping[categoryId]
      ? sensorCategoryToGraphTypeMapping[categoryId]
      : [];
  }

  getFormattedResponse(): any[] {
    let response = [];

    if (this.graphTypesForCategory.includes(WDSGraphType.Temperature)) {
      response.push({
        value: "ALERT_FACADE_WIDGET__TEMPERATURE",
        label: "Temperature",
      });
    }
    if (this.graphTypesForCategory.includes(WDSGraphType.Humidity)) {
      response.push({
        value: "ALERT_FACADE_WIDGET__HUMIDITY",
        label: "Humidity",
      });
    }
    if (this.graphTypesForCategory.includes(WDSGraphType.Wind)) {
      response.push({ value: "ALERT_FACADE_WIDGET__WIND", label: "Wind" });
    }
    if (this.graphTypesForCategory.includes(WDSGraphType.Rain)) {
      response.push({
        value: "ALERT_FACADE_WIDGET__RAINFALL",
        label: "Rainfall",
      });
    }
    if (this.graphTypesForCategory.includes(WDSGraphType.Probe)) {
      response.push({ value: "ALERT_FACADE_WIDGET__PROBE", label: "Probe" });
    }
    if (this.graphTypesForCategory.includes(WDSGraphType.ProbeA)) {
      response.push({ value: "ALERT_FACADE_WIDGET__PROBE", label: "Probe A" });
    }
    if (this.graphTypesForCategory.includes(WDSGraphType.ProbeB)) {
      response.push({ value: "ALERT_FACADE_WIDGET__PROBE", label: "Probe B" });
    }
    if (this.graphTypesForCategory.includes(WDSGraphType.Water)) {
      response.push({
        value: "ALERT_FACADE_WIDGET__WET_DRY",
        label: "Wet/Dry",
      });
    }

    if (response.length === 0) {
      response.push({
        value: "ALERT_FACADE_WIDGET__DEFAULT",
        label: "Raw Format",
      });
    }

    return response;
  }

  updateOutput() {
    let event = {
      event_widget_type: this.entity.widget_type(),
      event_logical_widget_type: this.entity.logical_widget_type(),
      event_alert_widget_type: this.entity.alert_widget_type(),
      event_type: "alert_facade_selection_made",
      event_body: {
        sref: this.entity.selected,
        graphTypes: this.getFormattedResponse(),
      },
    };
    this.widgetEvent.emit(event);
  }
}

const WDSGraphType = {
  Temperature: 1,
  Humidity: 2,
  Probe: 3,
  ProbeA: 4,
  ProbeB: 5,
  Water: 6,
  Wind: 7,
  Rain: 8,
};

const sensorCategoryToGraphTypeMapping = {
  10: [WDSGraphType.Temperature, WDSGraphType.Humidity], // DisplayTempHumi
  2: [WDSGraphType.Temperature, WDSGraphType.Humidity], // TempHumi
  99: [WDSGraphType.Wind], // Wind
  4: [WDSGraphType.Temperature, WDSGraphType.Humidity, WDSGraphType.Wind], // WindTempHumi
  20: [WDSGraphType.Wind, WDSGraphType.Rain], // WindRainLight
  5: [
    WDSGraphType.Temperature,
    WDSGraphType.Humidity,
    WDSGraphType.Wind,
    WDSGraphType.Rain,
  ], // WindRainLightTempHumi
  11: [WDSGraphType.Rain], // Rain
  3: [WDSGraphType.Temperature, WDSGraphType.Rain], // RainTempHumi
  21: [WDSGraphType.ProbeA, WDSGraphType.ProbeB], // Barbeque
  12: [WDSGraphType.Temperature, WDSGraphType.Water], // WaterLeak
  22: [WDSGraphType.Temperature], // Pool
  23: [WDSGraphType.Temperature], // Link
  24: [WDSGraphType.Temperature, WDSGraphType.Humidity], // DisplayTempHumiPressure
  1001: [WDSGraphType.Temperature, WDSGraphType.Humidity, WDSGraphType.Probe], // TempHumiProbeHeatIndex
  7: [WDSGraphType.Wind, WDSGraphType.Temperature, WDSGraphType.Humidity], // WindnTemp
  25: [WDSGraphType.Temperature, WDSGraphType.Humidity], // V50
  60: [WDSGraphType.Temperature, WDSGraphType.Humidity], // TempHumiProbe
};
