import { NoizuStruct } from "../../../../noizu/structs/noizu-struct";
import { FieldTypeEnum } from "../../../../enums/field-type.enum";

export class DeviceFieldDefinitionType extends NoizuStruct {
  public enum: any;
  public name: string;
  constructor(json: any) {
    super();
    this.name = FieldTypeEnum[json];
    this.enum = FieldTypeEnum[this.name];
  } // end constructor

  toJson(options = {}) {
    return { name: this.name, enum: this.enum };
  }
}
