import { HttpClient } from "@angular/common/http";
import { FirebaseAuthService } from "../../../noizu/services/firebase-auth.service";
import { UserSubscriptionEntity } from "./subscription.entity";
import { ElixirEntityList } from "../../../noizu/structs/elixir-entity-list";
import { UserSubscriptionRepo } from "../../../repos/user/subscription.repo";

export class UserSubscriptionEntitySet extends ElixirEntityList {
  public loaded = false;
  public subject: any = null;
  constructor(client: HttpClient, auth: FirebaseAuthService, subject, json) {
    super("user-subscription", [], null, null, null, []);
    let repo = new UserSubscriptionRepo(client, auth);
    repo.user = subject;
    this.repo = repo;
    this.subject = subject;
    this.items = new Array();
    for (const snippet in json) {
      this.loaded = true;
      let entry = new UserSubscriptionEntity(client, auth, json[snippet]);
      if (entry) this.items.push(entry);
    }
  }
}
