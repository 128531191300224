import { AlertWidgetEnum } from "../../../enums/widget";
import { LogicalAlertWidget } from "./logical-alert-widget";

export class AlertFacadeSelectorLogicalWidget extends LogicalAlertWidget {
  public selected: any = null;

  alert_widget_type(): AlertWidgetEnum | null {
    return AlertWidgetEnum.ALERT_WIDGET__ALERT_FACADE_SELECTOR;
  }

  constructor() {
    super();
  }
}
