import { Component, Input } from "@angular/core";
import { SubscriptionsEntity } from "../../entities/subscriptions.entity";
import { SubscriptionsRepo } from "../../repos/data/subscriptions.repo";
import { ToasterService } from "angular2-toaster";

@Component({
  selector: "subscription-entry",
  templateUrl: "./subscription-entry.component.html",
})
export class SubscriptionEntryComponent {
  @Input() entry: SubscriptionsEntity = null;
  public unmodifiedEntry = null;
  public editEntry: boolean = false;
  public deleted: boolean = false;
  constructor(
    public repo: SubscriptionsRepo,
    public toasterService: ToasterService
  ) {}

  allowOnlyNumbers(event: KeyboardEvent) {
    const allowedKeys = [
      "Backspace",
      "Delete",
      "ArrowLeft",
      "ArrowRight",
      "Tab",
    ];
    if (allowedKeys.includes(event.key)) {
      return;
    }
    if (!/^\d$/.test(event.key)) {
      event.preventDefault();
      this.toasterService.pop(
        "error",
        "Validation Error",
        "Please enter a number."
      );
    }
  }

  toggleTrial(e: string) {
    if (!this.entry.features) {
      this.entry.features = { attributes: {} };
    }
    if (!this.entry.features.attributes) {
      this.entry.features.attributes = {};
    }
    if (typeof this.entry.features.attributes[e] === "undefined") {
      this.entry.features.attributes[e] = true;
    } else {
      this.entry.features.attributes[e] = !this.entry.features.attributes[e];
    }
  }

  isAttributeChecked(type: string): boolean {
    return this.entry?.features?.attributes?.[type] === "true";
  }

  update(e, entity, bool = 0) {
    e.preventDefault();
    this.editEntry = false;

    if (this.exportMaxCheck(entity)) {
      const max = entity.features.export.max;
      if (max) {
        const factor = max.startsWith("ai.days.") ? "ai.days." : "ai.years.";
        entity.features.export.max = max.startsWith(factor)
          ? entity.exportMaxUnit + Number(max.replace(factor, ""))
          : entity.exportMaxUnit + max;
      }
    }

    if (this.viewMaxCheck(entity)) {
      const max = entity.features.view.max;
      if (max) {
        const factor = max.startsWith("ai.days.") ? "ai.days." : "ai.years.";
        entity.features.view.max = max.startsWith(factor)
          ? entity.viewMaxUnit + Number(max.replace(factor, ""))
          : entity.viewMaxUnit + max;
      }
    }
    // if (Number(entity.features.export.max.split("ai.years.")[1]) > 0) {
    //   entity.features.export.available = true;
    // } else {
    //   entity.features.export.available = false;
    // }
    // if (Number(entity.features.view.max.split("ai.days.")[1]) > 0) {
    //   entity.features.view.available = true;
    // } else {
    //   entity.features.view.available = false;
    // }

    if (
      entity.features.notifications &&
      typeof entity.features.notifications === "object"
    ) {
      for (const key in entity.features.notifications) {
        if (entity.features.notifications[key] == null) {
          delete entity.features.notifications[key];
        }
      }
    }
    if (
      entity.features.attributes &&
      typeof entity.features.attributes === "object"
    ) {
      for (const key in entity.features.attributes) {
        if (entity.features.attributes[key] == false) {
          delete entity.features.attributes[key];
        }
      }
    }
    entity.update();
    if (bool == 1) {
      this.toasterService.pop(
        "info",
        "Subscription Added",
        "Subscription added successfully"
      );
      return;
    }
    this.toasterService.pop(
      "info",
      "Subscription Updated",
      "Subscription updated successfully"
    );
  }

  exportMaxCheck(entry) {
    return entry.exportMaxUnit === "ai.years.";
  }

  toggleExportMax(entry) {
    if (this.exportMaxCheck(entry)) {
      entry.exportMaxUnit = "ai.days.";
    } else {
      entry.exportMaxUnit = "ai.years.";
    }
  }

  viewMaxCheck(entry) {
    return entry.viewMaxUnit === "ai.years.";
  }

  toggleViewMax(entry) {
    if (this.viewMaxCheck(entry)) {
      entry.viewMaxUnit = "ai.days.";
    } else {
      entry.viewMaxUnit = "ai.years.";
    }
  }

  edit(e, entity) {
    e.preventDefault();
    this.unmodifiedEntry = this.entry.toJson();
    this.editEntry = true;
  }

  cancel(e) {
    e.preventDefault();
    this.entry.refresh(this.unmodifiedEntry);
    this.editEntry = false;
  }

  delete(e, entity) {
    e.preventDefault();
    this.editEntry = false;
    entity.delete().then((res: any) => {
      if (res) {
        this.deleted = true;
      }
      this.toasterService.pop(
        "error",
        "Subscription Deleted",
        "Subscription deleted successfully"
      );
    });
  }

  get viewMax(): string {
    return this.entry.features.view.max
      ? this.entry.features.view.max.split(".").pop()
      : "";
  }

  set viewMax(value: string) {
    this.entry.features.view.max = `ai.days.${value}`;
  }

  get exportMax(): string {
    return this.entry.features.view.max
      ? this.entry.features.export.max.split(".").pop()
      : "";
  }

  set exportMax(value: string) {
    this.entry.features.export.max = `ai.years.${value}`;
  }

  onSelectionChange(type: string, event?: any) {
    type = type.toLowerCase();
    if (event?.target?.type === "checkbox") {
      if (type === "sms") {
        this.entry.sms_checked = !this.entry.sms_checked;
        if (this.entry.sms_checked) {
          if (
            !this.entry.features.notifications[type] ||
            this.entry.features.notifications[type] === null
          ) {
            this.entry.features.notifications[type] = "unlimited";
          }
        } else {
          this.entry.features.notifications[type] = null;
        }
      } else {
        if (
          !this.entry.features.notifications[type] ||
          this.entry.features.notifications[type] === null
        ) {
          this.entry.features.notifications[type] = "unlimited";
        } else {
          this.entry.features.notifications[type] = null;
        }
      }
    } else if (event?.target?.type === "number") {
      if (type === "sms") {
        if (!event.target.value) {
          if (this.entry.sms_checked) {
            this.entry.features.notifications[type] = "unlimited";
          } else {
            this.entry.features.notifications[type] = null;
          }
        } else {
          this.entry.features.notifications[type] = Number(event.target.value);
        }
      }
    }
  }
}
