
    <form
      action=""
      class="data-management-verification-code-upload-widget form-horizontal"
      [formGroup]="uploadFile"
      (ngSubmit)="onSubmit($event)"
    >
      <div class="card">
        <div class="card-header bg-danger">
          <h1>VerificationCode Upload</h1>
        </div>
        <div class="card-body m-2 p-0" *ngIf="entity?.initialized">
          <div class="row mb-3">
            <div class="col text-center">
              <div class="btn-group" role="group" aria-label="Upload Type">
                <button
                  class="btn"
                  [ngClass]="{
                    'btn-primary': entity?.upload_type == 'csv',
                    'btn-secondary': entity?.upload_type != 'csv'
                  }"
                  (click)="setUploadType('csv', $event)"
                  type="button"
                >
                  CSV File
                </button>
                <button
                  class="btn"
                  [ngClass]="{
                    'btn-primary': entity?.upload_type == 'csv-snippet',
                    'btn-secondary': entity?.upload_type != 'csv-snippet'
                  }"
                  (click)="setUploadType('csv-snippet', $event)"
                  type="button"
                >
                  CSV Text
                </button>
                <button
                  class="btn"
                  [ngClass]="{
                    'btn-primary': entity?.upload_type == 'json-snippet',
                    'btn-secondary': entity?.upload_type != 'json-snippet'
                  }"
                  (click)="setUploadType('json-snippet', $event)"
                  type="button"
                >
                  Json
                </button>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <div class="alert alert-success">
                <!-- has header -->
                <div
                  class="row"
                  *ngIf="
                    entity?.upload_type == 'csv' ||
                    entity?.upload_type == 'csv-snippet'
                  "
                >
                  <div class="col">
                    <label>
                      <input
                        type="checkbox"
                        [checked]="entity.flags.has_header"
                        (change)="
                          entity.flags.has_header = !entity.flags.has_header
                        "
                      />
                      CSV Has Header?
                    </label>
                  </div>
                </div>

                <!-- over write? -->
                <div class="row">
                  <div class="col">
                    <label>
                      <input
                        type="checkbox"
                        [checked]="entity.flags.overwrite"
                        (change)="
                          entity.flags.overwrite = !entity.flags.overwrite
                        "
                      />
                      Overwrite Existing?
                    </label>
                  </div>
                </div>
              </div>

              <div class="row" *ngIf="entity?.validation?.messages['series']">
                <div class="col">
                  <div class="alert alert-danger">
                    {{ entity?.validation?.messages["series"] }}
                  </div>
                </div>
              </div>
              <div class="row mb-4">
                <div class="col-4"><strong>Series: </strong></div>
                <div class="col-8">
                  <logical-widget
                    (widgetEvent)="eventHandler($event, 'series')"
                    [entity]="series"
                    [options]="options"
                    [layout]="'default'"
                  ></logical-widget>
                </div>
              </div>

              <div class="form-group" *ngIf="entity.upload_type == 'csv'">
                <label class="col-md-3 col-form-label" for="csv"
                  ><strong>CSV Upload</strong></label
                >
                <input
                  type="file"
                  (change)="updateFile($event)"
                  name="code_file"
                />
              </div>

              <div
                class="form-group"
                *ngIf="entity.upload_type == 'csv-snippet'"
              >
                <textarea
                  name="csv_snippet"
                  [(ngModel)]="csv_snippet"
                  cols="64"
                  rows="32"
                  placeholder="Copy and Paste CSV entries."
                ></textarea>
              </div>

              <div
                class="form-group"
                *ngIf="entity.upload_type == 'json-snippet'"
              >
                <textarea
                  name="json_snippet"
                  [(ngModel)]="json_snippet"
                  cols="64"
                  rows="32"
                  placeholder="Copy and Paste Json Array"
                ></textarea>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer text-center">
          <button type="submit" class="btn btn-lg btn-danger">
            <i class="fa fa-dot-circle-o"></i> Upload Verification Codes
          </button>
        </div>
      </div>
    </form>
  