import { Component, NgZone } from "@angular/core";
import { SynchQueueEntryEntity } from "../../entities/synch-queue-entry.entity";
import { SynchQueueRepo } from "../../repos/synch-queue.repo";

@Component({
  templateUrl: "./synch-queue.component.html",
})
export class DataManagementSynchQueueComponent {
  public entries: Array<SynchQueueEntryEntity>;
  public searchResults: any;
  public searchTerm = { id: null };
  public pagination = {
    next: null,
    page: 0,
    cursors: { 0: "" },
    cursorArray: [""],
  };
  public state = {
    loading: true,
    hasResults: false,
    error: false,
    errorMsg: "",
  };
  public sort = 0;
  public rpp = 500;

  constructor(public repo: SynchQueueRepo, public zone: NgZone) {
    this.zone.run(() => {
      this.repo.getListPromise({ resultsPerPage: this.rpp }).then((u: any) => {
        this.state.loading = false;
        for (let val of u["items"]) {
          if (val["environment"] == undefined) val["environment"] = -1;
        }
        this.entries = u;
      });
    });
  }

  sortEntries(int) {
    let direction = "";
    if (this.sort == int) {
      this.sort = int * -1;
      direction = "-";
    } else {
      this.sort = int;
    }
    switch (int) {
      case 1:
        this.entries["items"].sort(this.dynamicSort(direction + "entityClass"));
        break;
      case 2:
        this.entries["items"].sort(this.dynamicSort(direction + "environment"));
        break;
      case 3:
        this.entries["items"].sort(this.dynamicSort(direction + "status"));
        break;
    }
  }

  dynamicSort(property) {
    var sortOrder = 1;
    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a, b) {
      var result =
        a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
      return result * sortOrder;
    };
  }
}
