<page-header [section]="'subscriptions'">
  <page-title>Subscriptions</page-title>
  <page-blurb
    >View and edit your own account’s active subscription. Manage other users’
    subscriptions.</page-blurb
  >
</page-header>

<spinner *ngIf="!entries"></spinner>

<div *ngIf="entries" class="row mx-0">
  <subscription-entry
    *ngFor="let entry of entries.items"
    [entry]="entry"
    class="col-md-3"
  ></subscription-entry>
</div>
<div class="d-flex justify-content-center my-4">
  <button (click)="add($event)" class="btn btn-sm btn-primary" type="submit">
    <i class="fa fa-plus-circle"></i> New
  </button>
</div>
