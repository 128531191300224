<div
  *ngIf="!user || notification.showLoading"
  class="overlayParent spinner-holder"
>
  <div class="overlay">
    <widget-spinner></widget-spinner>
  </div>
</div>
<div *ngIf="notification.message.length > 0" class="row">
  <div
    class="alert col-12"
    [ngClass]="{
      'alert-info': !notification.iserror,
      'alert-danger': notification.iserror
    }"
    (click)="setNotification('', false, true)"
  >
    {{ notification.message }}. Click to close
  </div>
</div>
<div *ngIf="user">
  <div class="row my-3">
    <div class="col-1"></div>
    <div class="col-11 halfColNMargin">
      <button (click)="back($event)" class="btn btn-primary">Back</button>
    </div>
  </div>

  <div class="row">
    <div class="col-1"></div>
    <div class="col-11 halfColNMargin">
      <div class="card">
        <div class="card-header">Tool Bar</div>
        <div class="card-body card-block">
          <div class="row">
            <div *ngIf="!state.edit" class="col-6">
              <button (click)="edit($event, 1)" class="btn btn-info">
                Edit Account Details
              </button>
              <button (click)="edit($event, 2)" class="btn btn-info mx-3">
                Edit Email
              </button>
            </div>
            <div *ngIf="state.accountEdit" class="col-6">
              <button
                *ngIf="state.edit"
                (click)="save(confirm, $event)"
                class="btn btn-danger"
              >
                Save
              </button>
              <button
                *ngIf="state.edit"
                (click)="cancel($event)"
                class="btn btn-primary"
              >
                Cancel
              </button>
            </div>
            <div *ngIf="state.emailEdit" class="col-6">
              <button
                *ngIf="state.edit"
                (click)="saveEmail(confirm, $event)"
                class="btn btn-danger"
              >
                Save
              </button>
              <button
                *ngIf="state.edit"
                (click)="cancel($event)"
                class="btn btn-primary"
              >
                Cancel
              </button>
            </div>
            <div class="pull-right col-6">
              <button
                (click)="showNotes = !showNotes"
                class="btn btn-info mx-3 pull-right"
              >
                Toggle Notes
              </button>
              <button
                (click)="deleteThisUser(confirm, $event)"
                *ngIf="user.deleteable"
                class="btn btn-danger pull-right"
              >
                Delete User
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="error.error">
    <div class="col-1"></div>
    <div class="col-11 alert alert-danger halfColNMargin" (click)="setError()">
      {{ error.msg }} (click to hide)
    </div>
  </div>

  <div class="row" *ngIf="showNotes">
    <div class="col-1"></div>
    <div class="col-11 halfColNMargin">
      <div class="col-12">
        <entity-notes-v2
          [entity]="'ref.user.' + user.identifier"
        ></entity-notes-v2>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-1"></div>
    <div class="col-11 halfColNMargin">
      <div class="card">
        <div class="card-header">
          <div class="row">
            <div class="col-1">
              <i
                class="fa fa-thumb-tack"
                [ngClass]="{
                  'fa-rotate-135 text-black-50': !pinned,
                  'fa-rotate--45': pinned
                }"
                (click)="pinDevice($event, pageModal)"
              ></i>
            </div>
            <div class="col">
              <strong>{{ user.identifier }}</strong> | {{ user.email }}
            </div>
          </div>
        </div>
        <div class="card-body card-block">
          <div class="alert alert-success">
            <div *ngIf="!state.edit">
              <div class="row" *ngIf="user.handle">
                <div class="col-3">Handle</div>
                <div class="col-9">{{ user.handle }}</div>
              </div>
              <div class="row">
                <div class="col-3">Account Name</div>
                <div class="col-9">{{ user.display_name }}</div>
              </div>
              <div class="row">
                <div class="col-3">Notification Email</div>
                <div class="col-9">{{ user.email }}</div>
              </div>
              <div class="row">
                <div class="col-3">First Name</div>
                <div class="col-9">{{ user.first_name }}</div>
              </div>
              <div class="row">
                <div class="col-3">Last Name</div>
                <div class="col-9">{{ user.last_name }}</div>
              </div>
              <div class="row" *ngIf="user.isStaff">
                <div class="col-3">Is Staff</div>
                <div class="col-9">{{ user.isStaff }}</div>
              </div>
            </div>

            <div *ngIf="state.emailEdit">
              <form action="" class="form-horizontal">
                <div class="form-group row">
                  <label
                    class="col-3 form-control-label"
                    for="edit-display-name"
                    >Account Name</label
                  >
                  <div class="col-9">
                    <input
                      class="form-control"
                      id="edit-display-name"
                      name="user[display_name]"
                      placeholder="Display Name"
                      type="text"
                      [(ngModel)]="userEdit.display_name"
                      autocomplete="off"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-3 form-control-label" for="edit-email"
                    >Notification Email</label
                  >
                  <div class="col-9">
                    <input
                      class="form-control"
                      id="edit-email"
                      name="user[email]"
                      placeholder="Email"
                      type="email"
                      [(ngModel)]="userEdit.email"
                      autocomplete="off"
                    />
                  </div>
                </div>
              </form>
            </div>

            <div *ngIf="state.accountEdit">
              <form action="" class="form-horizontal">
                <div class="form-group row">
                  <label class="col-3 form-control-label" for="edit-handle"
                    >Handle</label
                  >
                  <div class="col-9">
                    <input
                      class="form-control"
                      id="edit-handle"
                      name="user[handle]"
                      placeholder="Unique Internal Handle"
                      type="text"
                      [(ngModel)]="userEdit.handle"
                      autocomplete="off"
                    />
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-3 form-control-label" for="edit-email"
                    >Notification Email</label
                  >
                  <div class="col-9">
                    <input
                      class="form-control"
                      id="edit-email"
                      name="user[email]"
                      placeholder="Email"
                      type="email"
                      [(ngModel)]="userEdit.email"
                      autocomplete="off"
                    />
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-3 form-control-label" for="edit-first-name"
                    >First Name</label
                  >
                  <div class="col-9">
                    <input
                      class="form-control"
                      id="edit-first-name"
                      name="user[first_name]"
                      placeholder="First Name"
                      type="text"
                      [(ngModel)]="userEdit.first_name"
                      autocomplete="off"
                    />
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-3 form-control-label" for="edit-last-name"
                    >Last Name</label
                  >
                  <div class="col-9">
                    <input
                      class="form-control"
                      id="edit-last-name"
                      name="user[last_name]"
                      placeholder="Last Name"
                      type="text"
                      [(ngModel)]="userEdit.last_name"
                      autocomplete="off"
                    />
                  </div>
                </div>

                <div class="form-group row">
                  <div class="col-3">Group</div>
                  <div class="col-9">{{ user.group_id }}</div>
                </div>
                <div
                  class="form-group row"
                  *ngIf="user.auth.permissionLevel <= 1"
                >
                  <label class="col-3 form-control-label" for="edit-isStaff"
                    >Is Staff</label
                  >
                  <div class="col-9">
                    <input
                      type="checkbox"
                      [checked]="userEdit.isStaff"
                      (change)="userEdit.isStaff = !userEdit.isStaff"
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>

          <div class="alert alert-info">
            <div class="row">
              <div class="col-3">Created On</div>
              <div class="col-9">{{ user.createdOn }}</div>
            </div>
            <div class="row">
              <div class="col-3">Modified On</div>
              <div class="col-9">{{ user.modifiedOn }}</div>
            </div>
          </div>

          <div class="alert alert-warning">
            <div class="row">
              <div class="col-3">Flagged For Synch</div>
              <div class="col-9">{{ user.flagged_for_synch }}</div>
            </div>
            <div class="row">
              <div class="col-3">Last Synched</div>
              <div class="col-9">{{ user.last_synched }}</div>
            </div>
          </div>

          <div class="alert alert-info">
            <div class="row">
              <div class="col-3">Subscription Level</div>
              <div class="col-9">
                {{ subscription_level }}
                <span *ngIf="subscription_level !== 'Not Subscribed'"
                  >- Ends on <{{ sub_end_date }}></span
                >
              </div>
            </div>
            <div class="row">
              <div class="col-3">No of Devices</div>
              <div class="col-9">{{ no_of_devices }}</div>
            </div>
            <div class="row">
              <div class="col-3">No of Alert Sets</div>
              <div class="col-9">{{ no_of_alerts }}</div>
            </div>
            <div class="row">
              <div class="col-3">No of Locations</div>
              <div class="col-9">{{ no_of_locations }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-1"></div>
    <div class="col-11 halfColNMargin">
      <div *ngIf="notification.message.length > 0" class="row">
        <div
          class="alert col-12"
          [ngClass]="{
            'alert-info': !notification.iserror,
            'alert-danger': notification.iserror
          }"
        >
          {{ notification.message }}.
        </div>
      </div>

      <tabset #staticTabs>
        <tab heading="Devices">
          <new-spinner
            *ngIf="!user._nested.deviceAssociations"
            [size]="'lg'"
          ></new-spinner>

          <div
            *ngIf="
              user._nested.deviceAssociations &&
              user._nested.deviceAssociations.error == null
            "
            class="row"
          >
            <div
              *ngFor="let device of user._nested.deviceAssociations['items']"
              class="col-6 overlayParent"
            >
              <div class="overlay" *ngIf="device.saving">
                <widget-spinner></widget-spinner>
              </div>
              <div *ngIf="!(device.identifier == 'new')">
                <div *ngIf="!device.editMode" class="card mb-3">
                  <div
                    class="card-header d-flex justify-content-between align-items-center"
                  >
                    <h5 class="card-title mb-0">{{ device.raw.name }}</h5>
                    <div>
                      <button
                        *ngIf="!device.editMode"
                        class="btn btn-sm btn-primary mr-2"
                        (click)="toggleDeviceDetails(device)"
                      >
                        <i
                          class="fa fa-sm"
                          [ngClass]="{
                            'fa-chevron-down': !device.expanded,
                            'fa-chevron-up': device.expanded
                          }"
                        ></i>
                      </button>
                      <button
                        *ngIf="!device.editMode"
                        class="btn btn-sm btn-primary"
                        (click)="device.showReloc = !device.showReloc"
                      >
                        Reposition
                      </button>
                    </div>
                  </div>

                  <div class="card-body">
                    <!-- Reposition Section -->
                    <div *ngIf="device.showReloc" class="row my-3">
                      <div class="col-12">
                        <button
                          class="btn btn-sm btn-warning mr-2"
                          (click)="reposDevice($event, device, 0)"
                          *ngIf="device.pos != 1"
                        >
                          Move Forward
                        </button>
                        <button
                          class="btn btn-sm btn-warning float-right"
                          (click)="reposDevice($event, device, 1)"
                          *ngIf="device.last == false"
                        >
                          Move Backward
                        </button>
                        <div
                          class="alert alert-info mt-2"
                          *ngIf="device.pos == 1 && device.last == true"
                        >
                          Only Device in Location
                        </div>
                      </div>
                    </div>

                    <!-- Device Details -->
                    <div class="mb-3">
                      <label class="font-weight-bold">Device ID:</label>
                      <span class="ml-2">{{ device.sensor_serial }}</span>
                      <a
                        class="float-right"
                        (click)="viewDevice(device, $event)"
                        href="/portal/devices/show/{{
                          device.sensor_identifier
                        }}"
                      >
                        Go To Device Page
                      </a>
                    </div>

                    <div class="mb-3">
                      <label class="font-weight-bold">Model Number:</label>
                      <span class="ml-2">{{ device.model_number }}</span>
                    </div>

                    <div class="mb-3">
                      <label class="font-weight-bold">Device Type:</label>
                      <span class="ml-2">{{ device.type_name }}</span>
                    </div>

                    <!-- Expanded Details -->
                    <div *ngIf="device.expanded">
                      <div class="mb-3">
                        <label class="font-weight-bold">Location:</label>
                        <span class="ml-2">{{ device.locationName }}</span>
                      </div>

                      <div *ngIf="device.isStation">
                        <div class="overlayParent row" *ngIf="device.geo">
                          <new-spinner
                            *ngIf="!(device.geo.pc || device.geo.anonymous)"
                            [size]="'lg'"
                          ></new-spinner>

                          <div *ngIf="device.isfact23" class="col-12">
                            <div *ngIf="!device.geo.anonymous">
                              <div class="mb-2">
                                <label class="font-weight-bold"
                                  >Device Timezone:</label
                                >
                                <span class="ml-2">{{
                                  device.geo.timezone
                                }}</span>
                              </div>
                              <div class="mb-2">
                                <label class="font-weight-bold"
                                  >Postal Code:</label
                                >
                                <span class="ml-2">{{ device.geo.pc }}</span>
                              </div>
                            </div>
                            <div *ngIf="device.geo.anonymous">
                              <div class="mb-2">
                                <label class="font-weight-bold"
                                  >Device Timezone:</label
                                >
                                <span class="ml-2">Anonymous</span>
                              </div>
                            </div>
                          </div>

                          <div *ngIf="!device.isfact23" class="col-12">
                            <div *ngIf="device.geo.pc">
                              <div class="mb-2">
                                <label class="font-weight-bold"
                                  >Postal Code:</label
                                >
                                <span class="ml-2">{{ device.geo.pc }}</span>
                              </div>
                            </div>
                            <div *ngIf="device.geo.pc == ''">
                              <div class="mb-2">
                                <label class="font-weight-bold"
                                  >Device Timezone:</label
                                >
                                <span class="ml-2">Anonymous</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="mb-3">
                        <label class="font-weight-bold">Device Created:</label>
                        <span class="ml-2">{{
                          device.createdOn | date : "MM/dd/yyyy @ h:mm:ssa"
                        }}</span>
                      </div>

                      <div class="mb-3">
                        <label class="font-weight-bold">Device Modified:</label>
                        <span class="ml-2">{{
                          device.modifiedOn | date : "MM/dd/yyyy @ h:mm:ssa"
                        }}</span>
                      </div>
                    </div>

                    <!-- Association ID -->
                    <div class="mb-3">
                      <label class="font-weight-bold">Association ID:</label>
                      <span class="ml-2">{{ device.identifier }}</span>
                    </div>

                    <!-- Last Seen -->
                    <div *ngIf="device.status" class="mb-3">
                      <div *ngIf="device.status.lastUpdate != null">
                        <label class="font-weight-bold">Last Seen:</label>
                        <span class="ml-2">{{
                          device.status.lastUpdate * 1000 | date : "medium"
                        }}</span>
                      </div>
                      <div *ngIf="device.status.lastUpdate == null">
                        <label class="font-weight-bold">Last Seen:</label>
                        <span class="ml-2">Unknown/Never</span>
                      </div>
                    </div>

                    <!-- Action Buttons -->
                    <div
                      class="d-flex justify-content-between align-items-center"
                    >
                      <div>
                        <button
                          type="button"
                          class="btn btn-info btn-sm mr-2"
                          (click)="getDeviceStatus(device, $event)"
                        >
                          Get Status
                        </button>
                        <button
                          type="button"
                          class="btn btn-success btn-sm"
                          (click)="editDevice(device, $event)"
                        >
                          Edit
                        </button>
                      </div>
                      <button
                        type="button"
                        class="btn btn-danger btn-sm"
                        (click)="deleteAssoc(confirm, device, $event)"
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                </div>

                <div *ngIf="device.editMode" class="card">
                  <div class="card-header">
                    <h5 class="card-title mb-0">{{ device.raw.name }}</h5>
                  </div>
                  <div class="card-body">
                    <!-- Device Name Input -->
                    <div class="form-group row">
                      <label for="edit-Name" class="col-sm-3 col-form-label"
                        >Name</label
                      >
                      <div class="col-sm-9">
                        <input
                          id="edit-Name"
                          class="form-control"
                          name="name"
                          placeholder="Device Name"
                          type="text"
                          [(ngModel)]="device.device_name"
                          autocomplete="off"
                        />
                      </div>
                    </div>

                    <!-- Location Dropdown -->
                    <div class="form-group row">
                      <label for="edit-location" class="col-sm-3 col-form-label"
                        >Location</label
                      >
                      <div class="col-sm-9">
                        <select
                          id="edit-location"
                          class="form-control"
                          name="location"
                          [(ngModel)]="device.location_id"
                        >
                          <option
                            *ngFor="let loc of user._nested.locations.items"
                            [value]="loc.identifier"
                          >
                            {{ loc.name }}
                          </option>
                        </select>
                      </div>
                    </div>

                    <!-- Update Button -->
                    <div class="form-group row">
                      <div class="col-sm-12">
                        <button
                          type="button"
                          class="btn btn-info"
                          (click)="updateDeviceAssociation(device, $event)"
                        >
                          Update Details
                        </button>
                      </div>
                    </div>

                    <!-- Geo Section for Non-Fact23 Stations -->
                    <div
                      *ngIf="device.isStation && !device.isfact23"
                      class="mt-3"
                    >
                      <h6>Geo Information</h6>
                      <div *ngIf="geoData.search">
                        <div *ngIf="!device.geo.pc" class="alert alert-warning">
                          Device is Anonymous
                        </div>
                        <div class="form-group row">
                          <label
                            for="edit-postal-code"
                            class="col-sm-3 col-form-label"
                            >Postal Code</label
                          >
                          <div class="col-sm-9">
                            <input
                              id="edit-postal-code"
                              class="form-control"
                              name="deviceZip"
                              placeholder="Postal Code"
                              type="text"
                              [(ngModel)]="device.geo.pc"
                              autocomplete="off"
                            />
                          </div>
                        </div>
                        <div class="form-group row">
                          <label class="col-sm-3 col-form-label"
                            >Country Code</label
                          >
                          <div class="col-sm-9">
                            <ngx-select-dropdown
                              style="width: 100%"
                              (change)="selectionChanged($event, device)"
                              [(ngModel)]="ddr"
                              [config]="ddc"
                              [options]="clist"
                            ></ngx-select-dropdown>
                          </div>
                        </div>
                        <div class="form-group row">
                          <div class="col-sm-12">
                            <button
                              type="button"
                              class="btn btn-info"
                              (click)="getGeoCode(device, $event)"
                            >
                              Search for Location
                            </button>
                            <button
                              type="button"
                              class="btn btn-warning float-right"
                              (click)="confirmSetAnon(confirm, device, $event)"
                            >
                              Set Anonymous
                            </button>
                          </div>
                        </div>
                      </div>

                      <!-- Location Selection -->
                      <div *ngIf="!geoData.search">
                        <div class="row">
                          <div class="col-12">
                            <p>Choose the location:</p>
                          </div>
                          <div
                            *ngFor="let l of geoData.locs"
                            class="col-12 my-2"
                          >
                            <button
                              type="button"
                              class="btn btn-sm btn-info"
                              (click)="assignGeo(device, l, $event)"
                            >
                              Choose
                            </button>
                            {{ l.city_name }}, {{ l.administrative_area }}
                          </div>
                          <div *ngIf="geoData.locs.length === 0" class="col-12">
                            <p>No Locations Found</p>
                          </div>
                          <div class="col-12">
                            <button
                              type="button"
                              class="btn btn-warning float-right"
                              (click)="geoData.search = true"
                            >
                              Clear Location Search
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- Geo Section for Fact23 Stations -->
                    <div
                      *ngIf="device.isStation && device.isfact23"
                      class="mt-3"
                    >
                      <h6>Geo Information</h6>
                      <div class="form-group">
                        <label>
                          <input
                            type="checkbox"
                            [checked]="device.geo.anonymous"
                            (change)="
                              device.geo.anonymous = !device.geo.anonymous
                            "
                          />
                          Check for no Timezone information
                        </label>
                      </div>
                      <div *ngIf="!device.geo.anonymous">
                        <div class="form-group row">
                          <label
                            for="edit-zip-code"
                            class="col-sm-3 col-form-label"
                            >Zip Code</label
                          >
                          <div class="col-sm-9">
                            <input
                              id="edit-zip-code"
                              class="form-control"
                              name="deviceZip"
                              placeholder="Zip Code"
                              type="text"
                              [(ngModel)]="device.geo.pc"
                              autocomplete="off"
                            />
                          </div>
                        </div>
                        <div class="form-group row">
                          <label
                            for="edit-timezone"
                            class="col-sm-3 col-form-label"
                            >Timezone</label
                          >
                          <div class="col-sm-9">
                            <select
                              id="edit-timezone"
                              class="form-control"
                              name="timezone"
                              [(ngModel)]="device.geo.timezone"
                            >
                              <option
                                *ngFor="let tz of timezones.current"
                                [value]="tz"
                              >
                                {{ tz }}
                              </option>
                              <option
                                *ngFor="let tz of timezones.defaults | keys"
                                [value]="tz"
                              >
                                {{ timezones.defaults[tz] }}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div class="form-group row">
                        <div class="col-sm-12">
                          <button
                            type="button"
                            class="btn btn-info"
                            (click)="updateDeviceGeoLegacy(device, $event)"
                          >
                            Update Geo Info
                          </button>
                        </div>
                      </div>
                    </div>

                    <!-- Cancel Button -->
                    <div class="mt-3">
                      <button
                        type="button"
                        class="btn btn-info"
                        (click)="cancelEditDevice(device, $event)"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div *ngIf="device.identifier === 'new'" class="card">
                <div class="card-header">
                  <h5 class="card-title mb-0">New Device</h5>
                </div>
                <div class="card-body">
                  <!-- Display if locations exist -->
                  <div *ngIf="user._nested.locations?.items.length > 0">
                    <form class="form-horizontal">
                      <!-- Serial Input (if device status is not available) -->
                      <div class="form-group row" *ngIf="!device.status">
                        <label for="edit-serial" class="col-sm-3 col-form-label"
                          >Serial</label
                        >
                        <div class="col-sm-9">
                          <input
                            id="edit-serial"
                            class="form-control"
                            name="deviceID"
                            placeholder="6-digit Serial"
                            type="text"
                            [(ngModel)]="device.sensor_serial"
                            autocomplete="off"
                          />
                        </div>
                      </div>

                      <!-- Serial Display (if device status is available) -->
                      <div class="form-group row" *ngIf="device.status">
                        <label for="edit-serial" class="col-sm-3 col-form-label"
                          >Serial</label
                        >
                        <div class="col-sm-9">
                          <p class="form-control-static">
                            {{ device.sensor_serial }}
                          </p>
                        </div>
                      </div>

                      <!-- Location Dropdown -->
                      <div class="form-group row">
                        <label
                          for="edit-location"
                          class="col-sm-3 col-form-label"
                          >Location</label
                        >
                        <div class="col-sm-9">
                          <select
                            id="edit-location"
                            class="form-control"
                            name="location"
                            [(ngModel)]="device.location_id"
                          >
                            <option
                              *ngFor="let loc of user._nested.locations.items"
                              [value]="loc.identifier"
                            >
                              {{ loc.name }}
                            </option>
                          </select>
                        </div>
                      </div>

                      <!-- Device Name Input -->
                      <div class="form-group row">
                        <label
                          for="edit-device-name"
                          class="col-sm-3 col-form-label"
                          >Device Name</label
                        >
                        <div class="col-sm-9">
                          <input
                            id="edit-device-name"
                            class="form-control"
                            name="device[name]"
                            placeholder="Device Name"
                            type="text"
                            [(ngModel)]="device.device_name"
                            autocomplete="off"
                          />
                        </div>
                      </div>

                      <!-- Action Buttons -->
                      <div class="form-group row">
                        <div class="col-sm-12">
                          <button
                            *ngIf="!device.status"
                            type="button"
                            class="btn btn-info"
                            (click)="checkDevice(device, $event)"
                          >
                            Check Device
                          </button>
                          <button
                            *ngIf="device.status"
                            type="button"
                            class="btn btn-info"
                            (click)="createDevice(device, $event)"
                          >
                            Link Device to Account
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>

                  <!-- Display if no locations exist -->
                  <div
                    *ngIf="!(user._nested.locations?.items.length > 0)"
                    class="alert alert-warning"
                  >
                    Create Locations First
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            *ngIf="
              user._nested.deviceAssociations &&
              user._nested.deviceAssociations.error === true
            "
            class="row mt-3"
          >
            <div class="col-12">
              <div
                class="alert alert-danger d-flex justify-content-between align-items-center"
              >
                <span>Error loading device associations.</span>
                <button
                  type="button"
                  class="btn btn-info btn-sm"
                  (click)="reloadAssocDevices()"
                >
                  Try Again
                </button>
              </div>
            </div>
          </div>
          <div class="row mt-3 mb-3">
            <div class="col-12 text-center">
              <button
                type="button"
                class="btn btn-info btn-lg"
                (click)="addDevice()"
              >
                <i class="fa fa-plus mr-2"></i>Add Device
              </button>
            </div>
          </div>
        </tab>
        <tab heading="Locations">
          <div
            *ngIf="!user._nested.locations"
            class="overlayParent spinner-holder"
          >
            <div class="overlay">
              <widget-spinner></widget-spinner>
            </div>
          </div>

          <div
            *ngIf="user._nested.locations?.error"
            class="card-block text-center p-4"
          >
            <div class="alert alert-danger" role="alert">
              <strong>Error Loading Locations</strong>
              <p class="mb-0">
                There was an issue loading the locations. Please try again.
              </p>
            </div>
            <button
              type="button"
              class="btn btn-info mt-3"
              (click)="reloadLocations($event)"
            >
              <i class="fas fa-sync-alt me-2"></i> Retry
            </button>
          </div>

          <div *ngIf="user._nested.locations?.items" class="row">
            <div *ngFor="let l of user._nested.locations.items" class="col-6">
              <div class="overlayParent">
                <div class="overlay" *ngIf="l.saving">
                  <widget-spinner></widget-spinner>
                </div>

                <div *ngIf="!(l.identifier == 'new')" class="card mb-3">
                  <!-- Card Header -->
                  <div
                    class="card-header d-flex justify-content-between align-items-center"
                  >
                    <span>{{ l.name }}</span>
                    <button
                      class="btn btn-primary btn-sm"
                      (click)="l.showExtra = !l.showExtra"
                    >
                      Reposition
                    </button>
                  </div>

                  <!-- Card Body -->
                  <div class="card-body">
                    <!-- Created and Modified Dates -->
                    <div class="mb-2">
                      <strong>Created On:</strong>
                      {{ l.raw.createdOn | date : "MM/dd/yyyy @ h:mm:ssa" }}
                    </div>
                    <div class="mb-2">
                      <strong>Modified:</strong>
                      {{ l.raw.createdOn | date : "MM/dd/yyyy @ h:mm:ssa" }}
                    </div>
                    <div class="mb-3">
                      <strong>ID:</strong> {{ l.identifier }}
                    </div>

                    <!-- Reposition Section -->
                    <div *ngIf="l.showExtra" class="mb-3">
                      <!-- Label Above Input and Button -->
                      <label class="form-label"
                        ><strong>Move In Front Of:</strong></label
                      >

                      <!-- Input and Button in One Line -->
                      <div class="d-flex align-items-center" style="gap: 2%">
                        <select
                          name="move"
                          [(ngModel)]="l.newPos"
                          class="form-control flex-grow-1"
                          style="color: black"
                        >
                          <ng-container
                            *ngFor="let itm of user._nested.locations.items"
                          >
                            <option
                              *ngIf="
                                itm.identifier != l.identifier &&
                                itm.position != l.position + 1
                              "
                              [value]="itm.identifier"
                            >
                              {{ itm.position }}: {{ itm.name }}
                            </option>
                          </ng-container>
                        </select>
                        <button
                          style="padding: 8px 45px; gap: 2%"
                          class="btn btn-sm d-flex btn-warning"
                          (click)="reposLocation($event, l)"
                        >
                          <i class="fas fa-arrows-alt me-1"></i> Move
                        </button>
                      </div>
                    </div>
                    <!-- Delete or Linked Devices Section -->
                    <div class="d-flex justify-content-end">
                      <div *ngIf="l.linkedDevices.length === 0">
                        <button
                          type="button"
                          class="btn btn-danger btn-sm"
                          (click)="deleteLocation(confirm, l, $event)"
                        >
                          Delete
                        </button>
                      </div>
                      <div *ngIf="!(l.linkedDevices.length === 0)">
                        <div
                          class="alert alert-warning alert-sm mb-0"
                          role="alert"
                          (click)="listLinkedDevices(l)"
                        >
                          Devices linked to Location
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div *ngIf="l.identifier == 'new'" class="card mb-3">
                  <!-- Card Header -->
                  <div class="card-header">
                    <strong>{{ l.identifier }}</strong>
                  </div>

                  <!-- Card Body -->
                  <div class="card-body">
                    <!-- Name Input Field -->
                    <div class="mb-3">
                      <label for="edit-name" class="form-label">Name</label>
                      <input
                        class="form-control"
                        id="edit-name"
                        name="l[name]"
                        placeholder="Enter name"
                        type="text"
                        [(ngModel)]="l.name"
                        autocomplete="off"
                      />
                    </div>

                    <!-- Create Button -->
                    <div class="d-flex justify-content-end">
                      <button
                        type="button"
                        class="btn btn-info"
                        (click)="createLocation(l, $event)"
                      >
                        <i class="fas fa-plus me-2"></i> Create
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 text-center mt-4">
              <button
                type="button"
                class="btn btn-info btn-lg"
                (click)="addLocation()"
              >
                <i class="fas fa-plus-circle me-2"></i> Add Location
              </button>
            </div>
          </div>
        </tab>

        <tab heading="Channels" (selectTab)="loadUserChannels()">
          <new-spinner
            *ngIf="user.meta?.channels?.loading"
            [size]="'lg'"
          ></new-spinner>
          <widget
            *ngIf="user.meta?.channels?.list"
            [entity]="user.meta.channels.list"
            [layout]="'standard'"
            [options]="{ columns: 2, complete: true }"
          ></widget>
        </tab>

        <tab
          heading="Group"
          (selectTab)="loadInfo('g')"
          *ngIf="
            user.auth.permissionLevel <= 1 ||
            user.auth.permissionsList['read-groups'] == 1
          "
        >
          <new-spinner *ngIf="!user._nested.group" [size]="'lg'"></new-spinner>
          <div *ngIf="user._nested.group">
            <group-entry
              [group]="user._nested.group"
              [uid]="user.identifier"
              [source]="1"
            ></group-entry>
          </div>
        </tab>
        <tab heading="Alerts" (selectTab)="loadAlerts()">
          <widget
            (widgetEvent)="handleAlertEvent($event)"
            [entity]="user.extended.alerts"
            [layout]="'standard'"
            [options]="{
              sideBarWidth: 5,
              dpp: 250,
              expand: true,
              columns: 2,
              headers: [
                'identifier',
                'name',
                'type',
                'status',
                'modified_on',
                'last_event',
                'actions'
              ],
              edit: true
            }"
          ></widget>
        </tab>
        <tab heading="Events" (selectTab)="loadInfo('e')">
          <new-spinner
            *ngIf="!user._nested.alertEvents"
            [size]="'lg'"
          ></new-spinner>
          <div *ngIf="user._nested.alertEvents">
            <pre>{{ user._nested.alertEvents | json }}</pre>
          </div>
        </tab>
        <tab heading="Notifications" (selectTab)="loadInfo('n')">
          <!-- Spinner for loading state -->
          <new-spinner
            *ngIf="!user._nested.notifications.data"
            [size]="'lg'"
          ></new-spinner>

          <!-- Main content when data is loaded -->
          <div *ngIf="user._nested.notifications.data" class="row g-4">
            <!-- Notification Status Card -->
            <div class="col-12">
              <div class="card shadow-sm">
                <div
                  class="card-header bg-light d-flex justify-content-between align-items-center"
                >
                  <h5 class="card-title mb-0">Notification Status</h5>
                  <div>
                    <button
                      *ngIf="user._nested.notifications.status"
                      (click)="
                        user._nested.notifications.status.show =
                          !user._nested.notifications.status.show
                      "
                      class="btn btn-sm btn-outline-primary me-2"
                    >
                      <i
                        class="fas fa-sm"
                        [ngClass]="{
                          'fa-chevron-down':
                            !user._nested.notifications.status.show,
                          'fa-chevron-up':
                            user._nested.notifications.status.show
                        }"
                      ></i>
                    </button>
                    <button
                      (click)="getNotificationStatus()"
                      class="btn btn-sm btn-primary"
                    >
                      Refresh Status
                    </button>
                  </div>
                </div>
                <div
                  class="card-body"
                  *ngIf="
                    user._nested.notifications.status?.loading ||
                    user._nested.notifications.status?.show
                  "
                >
                  <new-spinner
                    *ngIf="user._nested.notifications.status?.loading"
                    [size]="'lg'"
                  ></new-spinner>
                  <div
                    *ngIf="user._nested.notifications.status?.show"
                    class="mt-3"
                  >
                    <div class="row">
                      <div class="col-md-6">
                        <p>
                          <strong>Worker Reference:</strong>
                          {{
                            user._nested.notifications.status.data.worker_ref
                          }}
                        </p>
                        <p>
                          <strong>Last Activity:</strong>
                          {{
                            user._nested.notifications.status.data.last_activity
                              | date : "MM/dd/yyyy @ h:mm:ssa"
                          }}
                        </p>
                        <p>
                          <strong>Initialized:</strong>
                          {{
                            user._nested.notifications.status.data.initialized
                              ? "Yes"
                              : "No"
                          }}
                        </p>
                        <p>
                          <strong>Migrating:</strong>
                          {{
                            user._nested.notifications.status.data.migrating
                              ? "Yes"
                              : "No"
                          }}
                        </p>
                        <p>
                          <strong>Identifier:</strong>
                          {{
                            user._nested.notifications.status.data.inner_state
                              .identifier
                          }}
                        </p>
                      </div>
                      <div class="col-md-6">
                        <p><strong>User Notification Settings:</strong></p>
                        <ul>
                          <li>
                            <strong>Enabled:</strong>
                            {{
                              user._nested.notifications.status.data.inner_state
                                .user_notification_settings.enabled
                                ? "Yes"
                                : "No"
                            }}
                          </li>
                          <li>
                            <strong>Last Check:</strong>
                            {{
                              user._nested.notifications.status.data.inner_state
                                .user_notification_settings.enable_check
                                | date : "MM/dd/yyyy @ h:mm:ssa"
                            }}
                          </li>
                        </ul>
                        <p><strong>Runtime Configuration:</strong></p>
                        <ul>
                          <li>
                            <strong>Expires On:</strong>
                            {{
                              user._nested.notifications.status.data.inner_state
                                .runtime_configuration.expires_on
                            }}
                          </li>
                          <li>
                            <strong>Cache Index:</strong>
                            {{
                              user._nested.notifications.status.data.inner_state
                                .runtime_configuration.cache_index
                            }}
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="mt-3">
                      <p><strong>Meta:</strong></p>
                      <ul>
                        <li>
                          <strong>Last Notification Sent:</strong>
                          {{
                            user._nested.notifications.status.data.inner_state
                              .meta.last_send_notification
                              | date : "MM/dd/yyyy @ h:mm:ssa"
                          }}
                        </li>
                      </ul>
                    </div>
                    <div class="mt-3">
                      <p><strong>Extended Data:</strong></p>
                      <ul>
                        <li>
                          <strong>Tuple:</strong>
                          {{
                            user._nested.notifications.status.data.extended
                              .t_ref.tuple[0]
                          }},
                          {{
                            user._nested.notifications.status.data.extended
                              .t_ref.tuple[1]
                          }}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Notifications List -->
            <div
              *ngFor="let n of user._nested.notifications.data.items"
              class="col-md-6"
            >
              <div class="card shadow-sm h-100">
                <div
                  class="card-header bg-light d-flex justify-content-between align-items-center"
                >
                  <h6 class="card-title mb-0">
                    Identifier: {{ n.identifier }}
                  </h6>
                  <button
                    class="btn btn-sm btn-outline-primary"
                    (click)="toggleNotificationRaw(n)"
                  >
                    <i
                      class="fas fa-sm"
                      [ngClass]="{
                        'fa-chevron-down': !n.showRaw,
                        'fa-chevron-up': n.showRaw
                      }"
                    ></i>
                  </button>
                </div>
                <div class="card-body">
                  <div class="mb-3">
                    <p>
                      <strong>Record Updated:</strong>
                      {{ n.raw.modifiedOn | date : "MM/dd/yyyy @ h:mm:ssa" }}
                    </p>
                    <p>
                      <strong>User Responded:</strong>
                      {{ n.raw.respondedOn | date : "MM/dd/yyyy @ h:mm:ssa" }}
                    </p>
                    <p>
                      <strong>Sensor Name:</strong> {{ n.raw.suggestedName }}
                    </p>
                    <p *ngIf="n.raw.sensor">
                      <strong>Sensor Serial:</strong> {{ n.raw.sensor.serial }}
                    </p>
                    <p *ngIf="n.raw.sender">
                      <strong>Sender:</strong> {{ n.raw.sender.firstName }}
                      {{ n.raw.sender.lastName }}
                    </p>
                    <p>
                      <strong>Notify Accept:</strong>
                      {{ n.raw.notifyAccept ? "Yes" : "No" }}
                    </p>
                  </div>
                  <div *ngIf="n.showRaw" class="mt-3">
                    <pre class="bg-light p-3 rounded">{{ n.raw | json }}</pre>
                  </div>
                  <div class="d-flex justify-content-end mt-3">
                    <button
                      (click)="n.loading = true; deleteNotification($event, n)"
                      class="btn btn-sm btn-danger"
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </tab>
        <tab heading="Settings" (selectTab)="loadSettings()">
          <user-settings-widget
            [entity]="user"
            [options]="{}"
          ></user-settings-widget>
        </tab>

        <tab heading="Subscription" (selectTab)="loadSubscriptions()">
          <ng-container [ngSwitch]="user_sub_version">
            <ng-container *ngSwitchDefault>
              <div class="row">
                <div class="col">
                  <ng-container *ngIf="!user?.extended?.subscriptions">
                    <new-spinner
                      *ngIf="user?.extended?.meta?.subscriptions_loading"
                      [size]="'sm'"
                    ></new-spinner>
                    <div
                      class="alert alert-warning"
                      *ngIf="!user?.extended?.meta?.subscriptions_loading"
                    >
                      Subscription Request Failure:
                      <pre>{{
                        user?.extended?.meta?.subscriptions_load_error | json
                      }}</pre>
                    </div>
                  </ng-container>

                  <div *ngIf="user?.extended?.subscriptions">
                    <widget
                      [entity]="user.extended.subscriptions"
                      [options]="{
                        edit: true,
                        header: true,
                        headers: [
                          'identifier',
                          'handle',
                          'type',
                          'start',
                          'end',
                          'actions'
                        ]
                      }"
                      [layout]="'standard'"
                    ></widget>
                  </div>
                </div>
              </div>
            </ng-container>
          </ng-container>
        </tab>

        <tab heading="Logs">
          <div class="row">
            <div class="col-12">
              <widget
                *ngIf="user?.logs"
                [entity]="user.logs"
                [options]="{ subject: 'ref.user.' + user.identifier }"
                [layout]="'standard'"
              ></widget>
            </div>
          </div>
        </tab>
      </tabset>
    </div>
  </div>
</div>

<div *ngIf="notification.message.length > 0" class="row">
  <div
    class="alert col-12"
    [ngClass]="{
      'alert-info': !notification.iserror,
      'alert-danger': notification.iserror
    }"
    (click)="setNotification('', false, true)"
  >
    {{ notification.message }}. Click to close
  </div>
</div>

<ng-template #confirm>
  <div class="modal-header">
    <h4 class="modal-title pull-left">{{ currentModal.title }}</h4>
    <button
      type="button"
      class="close pull-right"
      aria-label="Close"
      (click)="modalRef.hide()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    {{ currentModal.msg }}
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-secondary"
      (click)="currentModal.cancel()"
      data-dismiss="modal"
    >
      {{ currentModal.cancelMsg }}
    </button>
    <button
      type="button"
      class="btn btn-danger"
      (click)="currentModal.confirm()"
    >
      {{ currentModal.confirmMsg }}
    </button>
  </div>
</ng-template>

<ng-template #pageModal>
  <modal-widget
    [entity]="currentModal"
    (widgetEvent)="handleEvent($event)"
    [layout]="'shadowbox'"
    [options]="{}"
  ></modal-widget>
</ng-template>
