<page-header [section]="'device-search'">
  <page-title>Devices</page-title>
  <page-blurb
    >View pinned devices of own account, and search for a specific device with a
    valid 6 digit serial. View all device information, and make device updates
    and actions. Initiate factory reset request and set claimable which are
    irreversible and will clear all historic sensor data, geo settings,
    permissions, and sensor owner/subscriber permissions.</page-blurb
  >
</page-header>

<div class="row">
  <div class="col-12">
    <div *ngIf="notification.showLoading" class="overlayParent spinner-holder">
      <div class="overlay">
        <widget-spinner></widget-spinner>
      </div>
    </div>
    <div *ngIf="notification.message.length > 0" class="row">
      <div class="col">
        <div
          class="alert"
          [ngClass]="{
            'alert-info': !notification.iserror,
            'alert-danger': notification.iserror
          }"
          (click)="hideMessage()"
        >
          {{ notification.message }}. Click to close
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">Device Search</div>
          <div class="card-body">
            <div class="row">
              <div class="col">
                <form class="form-horizontal" [formGroup]="deviceForm">
                  <div class="form-group mb-0">
                    <div class="input-group">
                      <span class="input-group-btn">
                        <button
                          (click)="searchDevice(searchTerm, $event)"
                          type="button"
                          class="btn btn-primary"
                        >
                          <i class="fa fa-search"></i> Search
                        </button>
                      </span>
                      <input
                        (keyup.enter)="searchDevice(searchTerm, $event)"
                        type="text"
                        name="searchTerm"
                        formControlName="searchTerm"
                        class="form-control"
                        placeholder="6 Digit Serial"
                        [(ngModel)]="searchTerm"
                      />
                    </div>
                  </div>
                </form>
                <div
                  *ngIf="state?.error"
                  role="alert"
                  class="mt-2 mb-0 alert alert-danger"
                >
                  {{ state.errorMsg }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div *ngIf="state?.loading || deviceGroup" class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-header">
                <i class="fa fa-align-justify"></i> Search Results
              </div>
              <div class="card-body">
                <!-- todo switch to a circle chase spinner -->

                <new-spinner *ngIf="state?.loading" [size]="'sm'"></new-spinner>

                <div *ngIf="!state?.loading">
                  <widget
                    [entity]="deviceGroup"
                    [options]="searchOptions"
                    [layout]="'table'"
                  ></widget>
                </div>
              </div>
            </div>
            <!-- end search results card -->
          </div>
        </div>
      </div>
    </div>
    <!-- end search results row -->

    <div class="row mt-5">
      <div class="col-12">
        <div class="row">
          <div class="col-12">
            <div class="card border-warning">
              <div class="card-header bg-warning">
                <i class="fa fa-align-justify"></i>
                {{ authService?.user?.email }}: Pinned Entities
              </div>
              <div class="card-body p-2">
                <div class="row" *ngIf="!pinGroups || pinGroups.length == 0">
                  <div class="col-12">
                    <div class="alert alert-danger">No Pinned Entities</div>
                  </div>
                </div>
                <ng-container *ngIf="pinGroups && pinGroups.length > 0">
                  <!-- TODO Group Pins -->
                  <div class="row" *ngFor="let group of pinGroups">
                    <div class="col m-2">
                      <widget
                        [entity]="group"
                        [layout]="'table'"
                        [options]="pinOptions"
                      ></widget>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
            <!-- end pinned results card -->
          </div>
        </div>
      </div>
    </div>
    <!-- end pinned results row -->
  </div>
</div>
