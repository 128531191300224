import { Inject, Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { FirebaseAuthService } from "../noizu/services/firebase-auth.service";
import { ElixirRepo } from "./elixir.repo";
import { NoteEntity } from "../entities/note.entity";

@Injectable()
export class NotesRepo extends ElixirRepo {
  constructor(
    @Inject(HttpClient) client: HttpClient,
    @Inject(FirebaseAuthService) auth: FirebaseAuthService
  ) {
    super(client, auth);
  }
  getNotes(entity) {
    //
    return this._getEntityPromise(
      `${this.apiBase()}/admin-tools/entity-notes/${entity}`,
      (record, resolve) => {
        if (record && record.response) {
          let response = [];
          for (let i = 0; i < record.response.length; i++) {
            response.push(
              new NoteEntity(this.client, this.auth, record.response[i])
            );
          }
          resolve(response);
        } else {
          resolve([]);
        }
      },
      {}
    );
  }

  saveNote(entity, note) {
    //extendedInfo
    return this._post(
      `${this.apiBase()}/admin-tools/entity-notes/${entity}`,
      { note: note },
      (record, resolve) => {
        let response = null;
        if (record.response) {
          response = new NoteEntity(this.client, this.auth, record.response);
        }
        resolve(response);
      },
      {}
    );
  }

  updateNote(note) {
    let update = {
      identifier: note.identifier,
      created_on: note.created_on,
      subject: note.subject,
      modified_on: note.modified_on,
      owner: `ref.user.${note.owner.identifier}`,
      note: note.note,
    };
    return this._put(
      `${this.apiBase()}/admin-tools/entity-notes/${note.subject}/note/${
        note.identifier
      }`,
      update,
      (record, resolve) => {
        let response = null;
        if (record.response) {
          response = new NoteEntity(this.client, this.auth, record.response);
        }
        resolve(response);
      },
      {}
    );
  }
  deleteNote(note) {
    return this._delete(
      `${this.apiBase()}/admin-tools/entity-notes/${note.subject}/note/${
        note.identifier
      }`,
      (record, resolve) => {
        let response = null;
        if (record.response && record.deleted) {
          response = new NoteEntity(this.client, this.auth, record.response);
        }
        resolve(response);
      },
      {}
    );
  }
}
