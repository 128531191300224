import { Component, Input, OnInit } from "@angular/core";
import { MCUFirmwareDefinitionEntity } from "../../../entities/mcu/firmware";

@Component({
  selector: "embed-mcu-firmware-definition",
  template: `
    <div [ngSwitch]="layout">
      <!-- ========================================================================== -->
      <!-- Table Layout                                                               -->
      <!-- ========================================================================== -->
      <div *ngSwitchCase="'table'">
        <div
          [ngClass]="{
            'div-table-tr': options?.index % 2 == 1,
            'div-table-tr-alt': options?.index % 2 == 0
          }"
        >
          <div class="row">
            <div class="col-3 text-left">#{{ entity?.identifier }}</div>
            <div class="col-3 text-left">{{ entity?.manufacturerId }}</div>
            <div class="col-3 text-left">{{ entity?.series }}</div>
            <div class="col-3 text-left">{{ entity?.firmwareVersion }}</div>
          </div>
        </div>
      </div>

      <!-- ========================================================================== -->
      <!-- Default                                                                    -->
      <!-- ========================================================================== -->
      <div *ngSwitchDefault>
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div *ngIf="entity">
                <!-- put link to manufacturer -->
                <div class="card-header">
                  <strong>Firmware Definition:</strong>
                  <span> Version ({{ entity?.firmwareVersion }}) </span>
                  <span>
                    {{ getManufacturerDescription(entity?.manufacturerId) }}
                  </span>
                </div>
                <div class="card-body m-1 p-0">
                  <div class="row">
                    <div class="col-4">Identifier:</div>
                    <div class="col-8">{{ entity?.identifier }}</div>
                  </div>

                  <div class="row mb-2">
                    <div class="col-4">Version:</div>
                    <div *ngIf="!options?.edit" class="col-8">
                      <b>{{ entity?.firmwareVersion }}</b>
                    </div>
                    <div *ngIf="options?.edit" class="col-8">
                      <input
                        type="text"
                        [(ngModel)]="entity.firmwareVersion"
                        (ngModelChange)="updateDefinition()"
                        size="16"
                      />
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-4">Series:</div>
                    <div *ngIf="!options?.edit" class="col-8">
                      <b>{{ entity?.series }}</b>
                    </div>
                    <div *ngIf="options?.edit" class="col-8">
                      <input
                        type="text"
                        [(ngModel)]="entity.series"
                        (ngModelChange)="updateDefinition()"
                        size="16"
                      />
                    </div>
                  </div>

                  <div class="row mb-3">
                    <div class="col-4">Manufacturer:</div>
                    <div *ngIf="options?.edit" class="col-8">
                      <ng-select
                        [(ngModel)]="entity.mid"
                        (ngModelChange)="updateManufacturer($event)"
                        [items]="manufacturerSelectOptions"
                        bindLabel="label"
                        bindValue="value"
                      >
                      </ng-select>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-12">
                      <div class="card mt-1">
                        <div class="card-header bg-dark">Files</div>
                        <div class="card-body m-0 p-0">
                          <div class="row m-1">
                            <div class="col-4">Checksum:</div>
                            <div *ngIf="!options?.edit" class="col-8">
                              <b>{{ entity?.checksum }}</b>
                            </div>
                            <div *ngIf="options?.edit" class="col-8">
                              <input
                                type="text"
                                [(ngModel)]="entity.checksum"
                                (ngModelChange)="updateDefinition()"
                                size="28"
                              />
                            </div>
                          </div>
                          <div class="row m-1">
                            <div class="col-4">Size:</div>
                            <div *ngIf="!options?.edit" class="col-8">
                              <b>{{ entity?.size }}</b>
                            </div>
                            <div *ngIf="options?.edit" class="col-8">
                              <input
                                type="text"
                                [(ngModel)]="entity.size"
                                (ngModelChange)="updateDefinition()"
                                size="16"
                              />
                            </div>
                          </div>
                          <div class="div-table-tr-alt">
                            <div class="row">
                              <div class="col-12 text-center">File</div>
                            </div>
                            <div class="row">
                              <div class="col-12 text-center">
                                <div *ngIf="!options?.edit">
                                  <b>{{ entity?.file }}</b>
                                </div>
                                <div *ngIf="options?.edit">
                                  <input
                                    style="width:100%;"
                                    type="text"
                                    [(ngModel)]="entity.file"
                                    (ngModelChange)="updateDefinition()"
                                    size="64"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div *ngIf="entity?.manufacturerId == '15'">
                            <div class="div-table-tr-alt">
                              <div class="row">
                                <div class="col-12 text-center">
                                  Security Storage Certificate
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-12 text-center">
                                  <div *ngIf="!options?.edit">
                                    <b>{{ entity?.security_certificate }}</b>
                                  </div>
                                  <div *ngIf="options?.edit">
                                    <input
                                      style="width:100%;"
                                      type="text"
                                      [(ngModel)]="entity.security_certificate"
                                      (ngModelChange)="updateDefinition($event)"
                                      size="128"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-12">
                      <div class="card">
                        <div class="card-header">Notes</div>
                        <div class="card-body m-0 p-0">
                          <div class="row">
                            <div class="col-12">
                              <div *ngIf="!options?.edit">
                                <b>{{ entity?.notes }}</b>
                              </div>
                              <div *ngIf="options?.edit">
                                <textarea
                                  style="width:100%;"
                                  [(ngModel)]="entity.notes"
                                  (ngModelChange)="updateDefinition()"
                                  rows="8"
                                  cols="60"
                                ></textarea>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="card-footer"
                  *ngIf="entity?.pending && !options?.modal_form"
                >
                  <div class="row">
                    <div class="col-12">
                      <div class="alert alert-dark">
                        Caution: Saving changes to a firmware definition will
                        clear it's approval flag, blocking units from OTAing to
                        it if currently active!
                      </div>
                    </div>
                    <div class="col-12">
                      <button
                        (click)="save(entity, $event)"
                        class="btn btn-sm btn-danger"
                        type="submit"
                      >
                        <i class="fa fa-plus-circle"></i> Save Changes
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
})
export class EmbedMCUFirmwareDefinitionComponent implements OnInit {
  @Input() entity: MCUFirmwareDefinitionEntity = null;
  @Input() options: any = null;
  @Input() layout: string = null;
  @Input() manufacturerSelectOptions: any;

  public expand = false;
  constructor() {}

  manufacturerId: string;

  save(record: MCUFirmwareDefinitionEntity, event) {
    event.preventDefault();
    record.update();
  }

  updateManufacturer(e) {
    this.entity.manufacturerId = Number(this.entity.mid);
    this.updateDefinition(e);
  }

  ngOnInit() {
    this.entity.mid = String(this.entity.manufacturerId);
  }

  getManufacturerDescription(manufacturerId: number): string | null {
    const option = this.manufacturerSelectOptions.find(
      (opt) => opt.value === manufacturerId
    );
    return option ? option.description : null;
  }

  updateDefinition(event) {
    this.entity.pending = true;
  }
}
