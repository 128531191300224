import { NoizuStruct } from "../../noizu/structs/noizu-struct";
import { AggregationLevelEnum } from "../../enums/aggregation-level.enum";

export class AggregationLevel extends NoizuStruct {
  public enum: any;
  public name: string;
  constructor(json: any) {
    super();
    this.name = AggregationLevelEnum[json];
    this.enum = AggregationLevelEnum[this.name];
  } // end constructor

  toJson(options = {}) {
    return { name: this.name, enum: this.enum };
  }
}
