
    <div class="device-type" [ngSwitch]="layout">
      <!-- Thumbnail -->

      <!-- Large -->

      <div *ngSwitchCase="'brief'">
        <div class="card border-info" *ngIf="entity">
          <div class="card-header bg-info m-0 p-1">
            <strong>Device Type:</strong>
            <a
              class="text-dark"
              [routerLink]="['/portal/device/definitions/' + entity.identifier]"
            >
              {{ entity.internalName }}</a
            >
            <div class="card-header-actions">
              <span
                ><i
                  *ngIf="collapsible"
                  (click)="expandCard = !expandCard"
                  class="fa fa-sm pr-2"
                  [ngClass]="{
                    'fa-chevron-down': !expandCard,
                    'fa-chevron-up': expandCard
                  }"
                >
                </i
              ></span>
            </div>
          </div>
          <div class="card-body p-1">
            <!-- Device Image -->
            <div class="row mb-2">
              <div class="col-12 text-center" *ngIf="entity.image">
                <img
                  [src]="entity.image"
                  style="max-width: 90%; max-height: 10vh"
                  alt="{{ entity.name }}"
                />
              </div>
            </div>

            <div *ngIf="expandCard">
              <!-- Name -->
              <div class="row mb-1">
                <div class="col-12"><b>Name:</b></div>
                <div class="col-12">{{ entity.name }}</div>
              </div>

              <!-- Internal Name -->
              <div class="row mb-1">
                <div class="col-12"><b>Internal:</b></div>
                <div class="col-12">{{ entity.internalName }}</div>
              </div>

              <!-- Definition -->
              <div class="row mb-1">
                <div class="col-12"><b>Definition:</b></div>
                <div class="col-12">
                  <a
                    [routerLink]="[
                      '/portal/device/definitions/' + entity.identifier
                    ]"
                    >{{ entity.identifier }}</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Default -->
      <div *ngSwitchDefault>
        <!-- Not Loaded -->
        <div class="card" *ngIf="!entity">
          <div class="card-header">
            <strong>Device Type:</strong> Loading
            <div class="card-header-actions">
              <span class="dragula-handle"
                ><i class="dragula-handle text-black-50 fa fa-lg fa-arrows"></i
              ></span>
            </div>
          </div>
          <div class="card-body">
            <widget-spinner></widget-spinner>
          </div>
        </div>
        <!-- Loaded -->
        <div class="card border-info" *ngIf="entity">
          <div class="card-header bg-info">
            <strong>Device Type:</strong> {{ entity.internalName }}
            <button
              *ngIf="entity.pending"
              (click)="showSaveChanges(deviceDefinitionModal, $event)"
              class="button btn-danger"
            >
              Save Changes
            </button>
            <button
              *ngIf="entity.pending"
              (click)="showRevertChanges(deviceDefinitionModal, $event)"
              class="button btn-secondary"
            >
              Revert Changes
            </button>

            <div class="card-header-actions">
              <span>
                <i
                  *ngIf="options?.optional_edit"
                  (click)="toggleEdit($event)"
                  class="fa fa-pencil pr-3"
                  [ngClass]="{
                    'text-dark': optionalEdit,
                    'text-warn': !optionalEdit
                  }"
                >
                </i
              ></span>
              <span
                ><i
                  *ngIf="collapsible"
                  (click)="expandCard = !expandCard"
                  class="fa fa-sm pr-2"
                  [ngClass]="{
                    'fa-chevron-down': !expandCard,
                    'fa-chevron-up': expandCard
                  }"
                >
                </i
              ></span>
              <span class="dragula-handle"
                ><i class="dragula-handle text-black-50 fa fa-lg fa-arrows"></i
              ></span>
            </div>
          </div>
          <div class="card-body p-2" *ngIf="expandCard">
            <!-- Device Image -->
            <div class="row mb-4">
              <div class="col-12 text-center" *ngIf="entity.image">
                <img
                  [src]="entity.image"
                  style="max-width: 90%; max-height: 20vh"
                  alt="{{ entity.name }}"
                />
              </div>
              <div *ngIf="options?.edit" class="col-12 text-center">
                <input
                  type="text"
                  [(ngModel)]="entity.image"
                  (ngModelChange)="updateType(entity)"
                  class="form-control"
                />
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <div class="card">
                  <div class="card-header bg-secondary">Details</div>
                  <div class="card-body m-0 p-1">
                    <!-- Definition @todo link-->
                    <div class="row mb-1">
                      <div class="col-3"><b>Definition:</b></div>
                      <div class="col-9">
                        <a
                          routerLinkActive="active"
                          [routerLink]="[
                            '/portal/device/definitions/' + entity.identifier
                          ]"
                          >{{ entity.identifier }}</a
                        >
                      </div>
                    </div>

                    <!-- Name -->
                    <div class="row mb-1">
                      <div class="col-3"><b>Name:</b></div>
                      <div class="col-9" *ngIf="!options?.edit">
                        {{ entity.name }}
                      </div>
                      <div class="col-9" *ngIf="options?.edit">
                        <input
                          type="text"
                          [(ngModel)]="entity.name"
                          (ngModelChange)="updateType(entity)"
                          class="form-control"
                        />
                      </div>
                    </div>

                    <!-- Internal Name -->
                    <div class="row mb-1">
                      <div class="col-3"><b>Internal:</b></div>
                      <div class="col-9" *ngIf="!options?.edit">
                        {{ entity.internalName }}
                      </div>
                      <div class="col-9" *ngIf="options?.edit">
                        <input
                          type="text"
                          [(ngModel)]="entity.internalName"
                          (ngModelChange)="updateType(entity)"
                          class="form-control"
                        />
                      </div>
                    </div>

                    <!-- Description -->
                    <div class="row mb-3">
                      <div class="col-3"><b>Description:</b></div>
                      <div class="col-9" *ngIf="!options?.edit">
                        {{ entity.description }}
                      </div>
                      <div class="col-9" *ngIf="options?.edit">
                        <input
                          type="text"
                          [(ngModel)]="entity.description"
                          (ngModelChange)="updateType(entity)"
                          class="form-control"
                        />
                      </div>
                    </div>

                    <!-- Default Series -->
                    <div class="row mb-3">
                      <div class="col-3"><b>Default Series:</b></div>
                      <div class="col-9" *ngIf="!options?.edit">
                        {{ entity.defaultSeries }}
                      </div>
                      <div class="col-9" *ngIf="options?.edit">
                        <input
                        class="form-control"
                          type="text"
                          [(ngModel)]="entity.defaultSeries"
                          (ngModelChange)="updateType(entity)"
                        />
                      </div>
                    </div>

                    <!-- Category -->
                    <div class="row mb-3">
                      <div class="col-3"><b>Device Category:</b></div>
                      <div class="col-9" *ngIf="!options?.edit">
                        {{ entity.category?.name }}
                      </div>
                      <div class="col-9" *ngIf="options?.edit">
                        <ng-select
                          *ngIf="this.category.enum"
                          placeholder="Select Category"
                          [items]="categories"
                          bindLabel="label"
                          bindValue="value"
                          [multiple]="false"
                          handleInputChanges="true"
                          [(ngModel)]="this.category.enum"
                          (ngModelChange)="updateCategory($event)"
                        ></ng-select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <embed-device-attributes
                  [entity]="entity"
                  [layout]="'card'"
                  [options]="
                    options?.attributes || { title: 'Device Type Attributes' }
                  "
                ></embed-device-attributes>
              </div>
            </div>

            <!-- Field Entries -->
            <div class="card ">
              <div class="card-header bg-dark">
                <div class="row mt-3">
                  <div class="col-12 text-center">
                    <b>Field Entries</b>
                  </div>
                </div>
              </div>

              <div class="card-body m-0 p-0">
                <div class="row" *ngFor="let field of entity.fieldEntries">
                  <div class="col-12 mb-2">
                    <embed-device-type-field-entry
                      [entity]="field"
                      [options]="options"
                      [layout]="layout"
                    ></embed-device-type-field-entry>
                  </div>
                </div>
              </div>
            </div>
            <!-- End of Field Entries -->
          </div>
        </div>
      </div>
    </div>

    <!--                                                        -->
    <!--                    Pop-Up Menu                         -->
    <!--                                                        -->
    <ng-template #deviceDefinitionModal>
      <div class="modal-header">
        <h4 class="modal-title pull-left">{{ currentModal?.title }}</h4>
        <button
          type="button"
          class="close pull-right"
          aria-label="Close"
          (click)="deviceDefinitionModalRef.hide()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <widget-spinner *ngIf="currentModal?.processing"></widget-spinner>
        <div class="row" *ngIf="!currentModal?.processing">
          <div class="col-12">
            {{ currentModal?.msg }}
          </div>
          <div class="col-12">
            <div class="alert alert-success mt-4">
              {{ _entity.toJson() | json }}
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button
          *ngIf="currentModal?.cancelMsg"
          type="button"
          class="btn btn-secondary"
          (click)="currentModal?.cancel()"
          data-dismiss="modal"
        >
          {{ currentModal?.cancelMsg }}
        </button>
        <button
          *ngIf="currentModal?.deleteMsg"
          type="button"
          class="btn btn-warning"
          (click)="currentModal?.delete()"
          data-dismiss="modal"
        >
          {{ currentModal?.deleteMsg }}
        </button>
        <button
          *ngIf="currentModal?.confirmMsg"
          type="button"
          class="btn btn-danger"
          (click)="currentModal?.confirm()"
        >
          {{ currentModal?.confirmMsg }}
        </button>
      </div>
    </ng-template>
  