import { Component, NgZone, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { DeviceDefinitionEntity } from "../../../entities/device/definition.entity";
import { DeviceDefinitionRepo } from "../../../repos/device/definition.repo";
import { DeviceFieldDefinitionEntity } from "../../../entities/device/field/definition.entity";
import { AuthService } from "../../../services/auth.service";

@Component({
  template: `
    <div>
      <spinner *ngIf="!entity"></spinner>
      <widget-embed
        *ngIf="entity"
        [entity]="entity"
        [layout]="'full'"
        [options]="sidebar_options"
      ></widget-embed>
    </div>
  `,
})
export class DeviceDefinitionViewComponent implements OnInit {
  public identifier: Number = null;
  public entity: DeviceDefinitionEntity = null;
  public fields: Array<DeviceFieldDefinitionEntity>;
  public subscription: any;
  public sidebar_options = {
    expand: true,
    collapsible: false,
    attributes: { expand: true, edit: false },
    edit: false,
  };

  constructor(
    public repo: DeviceDefinitionRepo,
    public zone: NgZone,
    public activatedRoute: ActivatedRoute,
    public authService: AuthService
  ) {
    this.zone.run(() => {
      if (this.authService.loadedPromise) {
        this.authService.loadedPromise.then(() => {
          if (
            this.authService.userHasPermission("device_type_admin") ||
            this.authService.userHasPermission("firmware_admin")
          ) {
            this.sidebar_options.edit = true;
            this.sidebar_options.attributes.edit = true;
          }
        });
      }
    });
  }

  ngOnInit() {
    this.subscription = this.activatedRoute.params.subscribe((params: any) => {
      console.log("params", params);
      if ("id" in params) {
        let id = this.repo.srefToIdentifier("firmware", params["id"]);
        id = new Number(id);
        this.identifier = id;
        this.loadDefinition(id);
      }
    });
  }

  loadDefinition(id) {
    this.repo.getEntityPromise(id).then((entity: DeviceDefinitionEntity) => {
      this.entity = entity;
    });
  }
}
