import { NoizuStruct } from "../../noizu/structs/noizu-struct";
import {
  AlertFormatEnum,
  AlertWidgetEnum,
  WidgetEnum,
  WidgetEnum_Alert_Facade,
} from "../../enums";
import { AlertStatusEntity } from "./status.entity";

export class AlertFacadeEntity extends NoizuStruct {
  public deviceAssociation: any;
  public device: any;
  public identifier = null;
  public meta: any = { refreshing: false, hasChange: false, status: null };
  public subType: AlertFormatEnum = AlertFormatEnum.Unknown;
  public constructor(alert, json) {
    super();
    this._always_strip["subType"] = 0;
    this.device = json["sensor"];
    this.deviceAssociation = json["deviceAssociation"];
    this.identifier = alert;
  }

  public is_triggered() {
    return (this.status && this.status.trigger_state.triggered) || false;
  }

  public get template(): string | null {
    return null;
  }

  public get status(): AlertStatusEntity {
    return this.meta.status;
  }
  public set status(value: AlertStatusEntity) {
    this.meta.status = value;
  }

  public updateStatus(value: any) {
    this.meta.status = new AlertStatusEntity(this.identifier, value);
  }

  widget_type() {
    return WidgetEnum.EMBED_WIDGET__ALERT_FACADE_ENTITY;
  }

  alert_widget_type() {
    return AlertWidgetEnum.ALERT_WIDGET__ALERT_FACADE;
  }

  alert_facade_widget(): WidgetEnum_Alert_Facade {
    return WidgetEnum_Alert_Facade.ALERT_FACADE_WIDGET__DEFAULT;
  }

  public updateValues() {}
}
