import { Inject, Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { FirebaseAuthService } from "../noizu/services/firebase-auth.service";
import { AppengineRepo } from "./appengine.repo";
import { EntityArchiveEntity } from "../entities/entity-archive.entity";

@Injectable()
export class EntityArchiveRepo extends AppengineRepo {
  public _kind = "entity-archives";
  public _singular = "entity-archive";

  constructor(
    @Inject(HttpClient) client: HttpClient,
    @Inject(FirebaseAuthService) auth: FirebaseAuthService
  ) {
    super(client, auth);
  }

  entity(record) {
    return new EntityArchiveEntity(this.client, this.auth, record);
  }
}
