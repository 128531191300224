import { Component, Input, Output, EventEmitter } from "@angular/core";
import { WidgetEventStruct } from "../../widget-event.struct";
import { ReportEntity } from "../../../entities";
import { ReportWidgetEnum } from "../../../enums";

@Component({
  selector: "report-widget",
  template: `
    <ng-container *ngIf="entity">
      <ng-container [ngSwitch]="entity.widget_report_type()">
        <!-- Report -->
        <health-report-widget
          *ngSwitchCase="ReportWidgetEnum.REPORT_WIDGET__HEALTH_REPORT"
          (widgetEvent)="forwardEvent($event)"
          [entity]="entity"
          [layout]="layout"
          [options]="options"
        ></health-report-widget>
        <worker-report-widget
          *ngSwitchCase="ReportWidgetEnum.REPORT_WIDGET__WORKER_REPORT"
          (widgetEvent)="forwardEvent($event)"
          [entity]="entity"
          [layout]="layout"
          [options]="options"
        ></worker-report-widget>

        <!-- Default -->
        <div *ngSwitchDefault>
          <div [ngSwitch]="layout">
            <div *ngSwitchCase="'shadowbox'">
              <div class="row mb-5">
                <div class="col text-center border-bottom">
                  <span class="display-4"
                    >Report
                    {{ ReportWidgetEnum[entity.widget_report_type()] }}</span
                  >
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <pre>{{ entity.toJson() | json }}</pre>
                </div>
              </div>
            </div>
            <div *ngSwitchDefault>
              <div class="card" [ngClass]="{ 'mb-0': options?.nested }">
                <div
                  class="card-header"
                  [ngClass]="{ 'p-2 m-0': options?.nested }"
                >
                  Report {{ ReportWidgetEnum[entity.widget_report_type()] }}
                </div>
                <div class="card-body">
                  <pre>{{ entity.toJson() | json }}</pre>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
  `,
})
export class ReportWidgetComponent {
  @Input() entity: ReportEntity | null = null;
  @Input() templateHint: string = null;
  @Input() options: any = null;
  @Input() layout = "standard";

  @Output() widgetEvent = new EventEmitter<WidgetEventStruct>();
  forwardEvent(widgetEvent: WidgetEventStruct) {
    this.widgetEvent.emit(widgetEvent);
  }

  public ReportWidgetEnum = ReportWidgetEnum;

  constructor() {}
}
