<page-header [section]="'duplicate-devices'">
  <page-title>Duplicate Devices</page-title>
  <page-blurb
    >View and manage logs of duplicate devices. Initiate actions to migrate,
    purge, and set / get / clear lookup entries.</page-blurb
  >
</page-header>

<div class="row">
  <div class="col-12">
    <div class="row">
      <div class="col-md-12">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-header">
                <i class="fa fa-align-justify"></i>
              </div>
              <div class="card-body card-block">
                <div>
                  <div>
                    <div *ngIf="searchButton.state">
                      <input
                        type="text"
                        name="searchSerial"
                        class="form-control"
                        placeholder="Device Serial"
                        [(ngModel)]="searchSerial"
                      />
                    </div>
                    <button
                      (click)="search($event)"
                      type="button"
                      class="btn btn-info"
                    >
                      <i class="fa fa-list"></i>
                      {{ searchButton.text[searchButton.state] }}
                    </button>
                    <div>
                      Search for Device:
                      <input
                        type="checkbox"
                        [checked]="searchButton.state"
                        (change)="toggleSearchState($event)"
                      />
                    </div>
                  </div>
                  <div
                    *ngIf="state?.loading"
                    class="overlayParent spinner-holder"
                  >
                    <div class="overlay">
                      <widget-spinner></widget-spinner>
                    </div>
                  </div>
                  <div *ngIf="state?.loading === false">
                    <div *ngIf="!entries?.items">No Matching Entries Found</div>
                    <div *ngIf="entries?.items" class="row">
                      <div *ngIf="pagination.show" class="col-12">
                        <ul class="pagination">
                          <li class="page-item" *ngIf="pagination.page > 0">
                            <a
                              (click)="
                                searchAt(entries, pagination.page - 1, $event)
                              "
                              class="page-link"
                              >Previous</a
                            >
                          </li>

                          <li
                            class="page-item"
                            *ngFor="
                              let cursor of pagination.cursorArray;
                              let cursorIndex = index
                            "
                            [ngClass]="{
                              active: cursorIndex == pagination.page
                            }"
                          >
                            <a
                              (click)="searchAt(cursorIndex, $event)"
                              class="page-link"
                              >{{ cursorIndex + 1 }}</a
                            >
                          </li>

                          <li
                            class="page-item"
                            *ngIf="pagination.next && pagination.next != ''"
                          >
                            <a (click)="searchNext($event)" class="page-link"
                              >Next</a
                            >
                          </li>
                        </ul>
                      </div>

                      <div *ngFor="let entry of entries.items" class="col-12">
                        <div class="card">
                          <div class="card-header">
                            {{ entry.identifier }} [{{ entry.lookup }}]
                          </div>
                          <div class="card-block">
                            <table
                              class="table table-bordered table-striped table-sm"
                            >
                              <thead>
                                <tr>
                                  <th>Device</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  *ngFor="
                                    let x of entry.sensorIds;
                                    let i = index
                                  "
                                >
                                  <td>
                                    <a
                                      class="nav-link"
                                      routerLinkActive="active"
                                      [routerLink]="'/portal/devices/show/' + x"
                                      >{{ x }}</a
                                    >
                                  </td>
                                  <td>
                                    <!-- TODO provide option to delete or not delete while migrating -->
                                    <button
                                      (click)="entry.migrate(x, false)"
                                      type="button"
                                      class="btn btn-sm btn-warning"
                                    >
                                      Migrate
                                    </button>
                                    <button
                                      (click)="entry.migrate(x, true)"
                                      type="button"
                                      class="btn btn-sm btn-danger"
                                    >
                                      W/Purge
                                    </button>

                                    <button
                                      (click)="entry.setLookup(x)"
                                      type="button"
                                      class="btn btn-sm btn-primary"
                                    >
                                      Set Lookup
                                    </button>
                                    <button
                                      (click)="entry.getLookup()"
                                      type="button"
                                      class="btn btn-sm btn-default"
                                    >
                                      Get Lookup
                                    </button>
                                    <button
                                      (click)="entry.clearLookup()"
                                      type="button"
                                      class="btn btn-sm btn-primary"
                                    >
                                      Clear Lookup
                                    </button>
                                  </td>
                                </tr>
                              </tbody>
                              <div *ngIf="entry.purgedSensorIds">
                                Purged Sensor Ids: <br />
                                <div *ngFor="let x of entry.purgedSensorIds">
                                  {{ x }}
                                </div>
                              </div>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div *ngIf="pagination.show" class="col-12">
                        <ul class="pagination">
                          <li class="page-item" *ngIf="pagination.page > 0">
                            <a
                              (click)="searchAt(pagination.page - 1, $event)"
                              class="page-link"
                              >Previous</a
                            >
                          </li>

                          <li
                            class="page-item"
                            *ngFor="
                              let cursor of pagination.cursorArray;
                              let cursorIndex = index
                            "
                            [ngClass]="{
                              active: cursorIndex == pagination.page
                            }"
                          >
                            <a
                              (click)="searchAt(cursorIndex, $event)"
                              class="page-link"
                              >{{ cursorIndex + 1 }}</a
                            >
                          </li>

                          <li
                            class="page-item"
                            *ngIf="pagination.next && pagination.next != ''"
                          >
                            <a (click)="searchNext($event)" class="page-link"
                              >Next</a
                            >
                          </li>
                        </ul>
                      </div>
                    </div>
                    <!-- end *ngIf="entries" -->
                  </div>
                </div>
              </div>
            </div>
            <!-- end search results card -->
          </div>
        </div>
      </div>
    </div>
    <!-- end search results row -->
  </div>
</div>
