
    <ng-container *ngIf="entity">
      <ng-container [ngSwitch]="layout">
        <!-- Mini Layout -->
        <div *ngSwitchCase="'mini'" class="alert-widget">
          <div
            class="alert text-white leading"
            [ngClass]="{
              'alert-danger': entity.isTriggered(),
              'alert-success': !entity.isGreen()
            }"
          >
            <div class="row align-items-center">
              <div class="col-4"><b>Alerts: </b></div>
              <div class="col">
                <a [routerLink]="'/portal/alerts/show/' + entity.identifier"
                  >{{ entity.identifier }} [{{ entity.name }}]</a
                >
              </div>
            </div>
            <div class="row align-items-center mt-2">
              <div class="col-4"><b>Owner:</b></div>
              <div class="col" *ngIf="entity.owner">
                <a [routerLink]="'/portal/users/show/' + entity.owner">{{
                  entity.owner
                }}</a>
              </div>
            </div>
          </div>
        </div>

        <!-- Brief Layout -->
        <div *ngSwitchCase="'brief'" class="alert-widget">
          <div class="card mb-4">
            <div
              class="card-header p-2 d-flex justify-content-between align-items-center"
            >
              <span
                >Alert
                <a [routerLink]="'/portal/alerts/show/' + entity.identifier">{{
                  entity.identifier
                }}</a></span
              >
            </div>
            <div class="card-body p-2">
              <div
                class="alert"
                [ngClass]="{
                  'alert-danger': entity.isTriggered(),
                  'alert-success': !entity.isGreen()
                }"
              >
                <div class="row align-items-center">
                  <div class="col-4"><b>Alert: </b></div>
                  <div class="col">
                    <a [routerLink]="'/portal/alerts/show/' + entity.identifier"
                      >{{ entity.identifier }} [{{ entity.name }}]</a
                    >
                  </div>
                </div>
                <div class="row align-items-center mt-2">
                  <div class="col-4"><b>Owner:</b></div>
                  <div class="col" *ngIf="entity.owner">
                    <a [routerLink]="'/portal/users/show/' + entity.owner">{{
                      entity.owner
                    }}</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Table Layout -->
        <ng-container *ngSwitchCase="'table'">
          <div
            class="alert-widget div-table-th"
            *ngIf="options['index'] == 0 && options['header']"
          >
            <div class="row">
              <div class="col" *ngFor="let column of options.columns">
                <ng-container [ngSwitch]="column">
                  <ng-container *ngSwitchCase="'identifier'">Id</ng-container>
                  <ng-container *ngSwitchCase="'name'">Name</ng-container>
                  <ng-container *ngSwitchCase="'type'">Type</ng-container>
                  <ng-container *ngSwitchCase="'owner'">Owner</ng-container>
                  <ng-container *ngSwitchCase="'status'">Status</ng-container>
                  <ng-container *ngSwitchCase="'last_event'"
                    >Last Event</ng-container
                  >
                  <ng-container *ngSwitchCase="'modified_on'"
                    >Modified On</ng-container
                  >
                  <ng-container *ngSwitchCase="'actions'">Actions</ng-container>
                </ng-container>
              </div>
            </div>
          </div>

          <div
            [ngClass]="{
              'div-table-tr': options['index'] % 2 == 1,
              'div-table-tr-alt': options['index'] % 2 == 0
            }"
          >
            <div class="row align-items-center">
              <ng-container *ngFor="let column of options.columns">
                <ng-container [ngSwitch]="column">
                  <div *ngSwitchCase="'identifier'" class="col text-truncate">
                    <a
                      [routerLink]="'/portal/alerts/show/' + entity.identifier"
                      title="{{ entity.identifier }}"
                    >
                      <i class="pr-1 text-dark fa fa-link"></i>
                      {{ entity.identifier }}
                    </a>
                  </div>
                  <div
                    *ngSwitchCase="'name'"
                    class="col-2 text-truncate"
                    title="{{ entity.name }}"
                  >
                    {{ entity.name }}
                  </div>
                  <div
                    *ngSwitchCase="'type'"
                    class="col-2 text-truncate"
                    title="{{
                      facade_types[entity.facade.alert_facade_widget()]
                    }}"
                  >
                    {{ facade_types[entity.facade.alert_facade_widget()] }}
                  </div>
                  <div
                    *ngSwitchCase="'owner'"
                    class="col-2 text-truncate"
                    title="{{ entity.owner }}"
                  >
                    <a [routerLink]="'/portal/users/show/' + entity.owner">{{
                      entity.owner
                    }}</a>
                  </div>
                  <div
                    *ngSwitchCase="'status'"
                    class="col-2 text-truncate"
                    title="{{ entity.statusLabel() }}"
                  >
                    <span
                      class="badge"
                      [ngClass]="{
                        'badge-danger': entity.isTriggered(),
                        'badge-success': entity.isGreen(),
                        'badge-warning':
                          !entity.isTriggered() && !entity.isGreen()
                      }"
                    >
                      {{ entity.statusLabel() }}
                    </span>
                  </div>
                  <div
                    *ngSwitchCase="'modified_on'"
                    class="col text-truncate"
                    title="{{ entity.modifiedOn }}"
                  >
                    {{ entity.modifiedOn | date : "MM/dd/yyyy @ h:mm:ssa" }}
                  </div>
                  <div
                    *ngSwitchCase="'last_event'"
                    class="col text-truncate"
                    title="[FEATURE PENDING]"
                  >
                    [FEATURE PENDING]
                  </div>
                  <div *ngSwitchCase="'actions'" class="col">
                    <label class="switch switch-label switch-success">
                      <input
                        class="switch-input"
                        type="checkbox"
                        [(ngModel)]="entity.enabled"
                        (change)="toggleAlert($event)"
                      />
                      <span
                        class="switch-slider"
                        data-checked="On"
                        data-unchecked="Off"
                      ></span>
                    </label>
                  </div>
                </ng-container>
              </ng-container>
            </div>
          </div>
        </ng-container>

        <!-- Default Layout -->
        <div *ngSwitchDefault class="alert-widget">
          <div
            class="card shadow"
            [ngClass]="{
              'card-accent-danger border-danger': entity.isTriggered(),
              'border-primary card-accent-primary': !entity.isGreen()
            }"
          >
            <div class="card-header">
              <div class="row align-items-center">
                <div class="col-4">
                  <b>Alert #{{ entity?.identifier || "New" }}</b>
                  <input
                    type="text"
                    [(ngModel)]="entity.name"
                    (ngModelChange)="alertChange()"
                    size="16"
                    placeholder="Enter alert name"
                    class="form-control mt-2"
                    *ngIf="!entity?.meta?.new"
                  />
                </div>
                <div class="col-4" *ngIf="entity?.meta?.new">
                  <div
                    *ngIf="
                      user.deviceAssociationPairs &&
                        user.deviceAssociationPairs.length > 0;
                      else loading
                    "
                  >
                    <ng-select
                      placeholder="Select device"
                      [items]="user.deviceAssociationPairs"
                      [multiple]="false"
                      bindLabel="deviceName"
                      bindValue="deviceId"
                      (change)="onDeviceSelect($event)"
                    ></ng-select>
                  </div>

                  <ng-template #loading>
                    <div
                      class="d-flex justify-content-center align-items-center"
                    >
                      <div class="spinner-border text-primary" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                    </div>
                  </ng-template>
                </div>
                <div class="col-4">
                  <logical-widget
                    *ngIf="!entity?.meta?.new"
                    [entity]="facade_selector"
                    [layout]="layout"
                    [options]="{}"
                    (widgetEvent)="alertTemplateEvent($event)"
                  ></logical-widget>
                  <logical-widget
                    *ngIf="entity?.meta?.new && selectedDevice"
                    [entity]="facade_selector"
                    [layout]="layout"
                    [deviceData]="selectedDevice"
                    [options]="{ edit: true }"
                    (widgetEvent)="alertTemplateEvent($event)"
                  ></logical-widget>
                </div>
                <div class="col-4 text-right">
                  <div class="row px-0" *ngIf="!entity?.meta?.new">
                    <div class="col-auto">
                      <button
                        *ngIf="entity?.meta?.hasChanges"
                        (click)="revert($event)"
                        class="btn btn-primary btn-pill"
                      >
                        Revert
                      </button>
                      <button
                        *ngIf="entity?.meta?.hasChanges"
                        (click)="save($event)"
                        class="btn btn-warning btn-pill ml-1"
                      >
                        Save
                      </button>
                    </div>
                    <div
                      class="col pt-1 d-flex align-items-center"
                      *ngIf="!entity?.meta?.new"
                    >
                      <i
                        (click)="deleteAlert($event)"
                        class="fa fa-minus-circle text-danger fa-2x"
                        style="cursor: pointer;"
                      ></i>
                      <label class="switch switch-3d switch-primary ml-2">
                        <input
                          type="checkbox"
                          class="switch-input"
                          [(ngModel)]="entity.enabled"
                          (change)="toggleAlert($event)"
                        />
                        <span class="switch-slider"></span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body p-1 m-0">
              <div class="row">
                <div class="col-12 text-center">
                  <i
                    *ngIf="!entity.meta.new"
                    class="fa fa-lg"
                    [ngClass]="{
                      'spinner sm': cloning || !entity?.hasStatus(),
                      'fa-chevron-down':
                        !cloning && entity.hasStatus() && !options?.expandAlert,
                      'fa-chevron-up':
                        !cloning && entity.hasStatus() && options?.expandAlert
                    }"
                    (click)="entity.hasStatus() && toggleAlertExpand()"
                  ></i>
                  <i
                    *ngIf="entity?.meta?.new"
                    class="fa fa-lg"
                    [ngClass]="{ 'fa-chevron-down': true }"
                    (click)="toggleAlertExpand()"
                  ></i>
                </div>
              </div>
              <div
                *ngIf="expanded || entity?.meta?.new"
                [ngClass]="{ 'd-none': !options.expandAlert }"
              >
                <div class="row">
                  <div class="col">
                    <div class="card">
                      <!-- Card Header -->
                      <div class="card-header bg-dark text-white">
                        <div class="row align-items-center">
                          <div class="col">
                            <h5 class="mb-0">
                              Details: #{{ entity.identifier || "New" }}
                            </h5>
                          </div>
                          <div class="col-2 text-right p-0">
                            <toggle-widget
                              [options]="detail_expand_settings"
                            ></toggle-widget>
                          </div>
                        </div>
                      </div>

                      <!-- Card Body -->
                      <div
                        class="card-body"
                        [ngClass]="{ 'd-none': !detail_expand_settings.toggle }"
                      >
                        <!-- ID -->
                        <div class="row mb-3">
                          <div class="col-4 font-weight-bold">ID:</div>
                          <div class="col-8 text-muted">
                            {{ entity.identifier || "New" }}
                          </div>
                        </div>

                        <!-- Owner -->
                        <div class="row mb-3">
                          <div class="col-4 font-weight-bold">Owner:</div>
                          <div class="col-8">
                            <a
                              [routerLink]="
                                '/portal/users/show/' + entity.owner
                              "
                              class="text-primary"
                              >{{ entity.owner }}</a
                            >
                          </div>
                        </div>

                        <!-- Enabled -->
                        <div class="row mb-3">
                          <div class="col-4 font-weight-bold">Enabled:</div>
                          <div class="col-8 text-muted">
                            {{ entity.enabled }}
                          </div>
                        </div>

                        <!-- Name -->
                        <div class="row mb-3">
                          <div class="col-4 font-weight-bold">Name:</div>
                          <div class="col-8">
                            <input
                              type="text"
                              class="form-control"
                              [(ngModel)]="entity.name"
                              (ngModelChange)="alertChange()"
                              placeholder="Enter name"
                            />
                          </div>
                        </div>

                        <!-- Weight -->
                        <div class="row mb-3">
                          <div class="col-4 font-weight-bold">Weight:</div>
                          <div class="col-8">
                            <input
                              type="number"
                              class="form-control"
                              [(ngModel)]="entity.weight"
                              (ngModelChange)="alertChange()"
                              placeholder="Enter weight"
                            />
                          </div>
                        </div>

                        <!-- TimeZone -->
                        <div class="row mb-3">
                          <div class="col-4 font-weight-bold">TimeZone:</div>
                          <div class="col-8 text-muted">
                            {{ entity.timeZone || "New" }}
                          </div>
                        </div>

                        <!-- Language -->
                        <div class="row mb-3">
                          <div class="col-4 font-weight-bold">Language:</div>
                          <div class="col-8">
                            <ng-select
                              placeholder="Alert Language"
                              [items]="alert_language"
                              [multiple]="false"
                              bindLabel="label"
                              bindValue="value"
                              [(ngModel)]="entity.language"
                              (ngModelChange)="alertChange()"
                            ></ng-select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div *ngIf="entity.facade" class="row mt-3">
                  <div class="col">
                    <alert-facade-widget
                      *ngIf="!entity?.meta?.new"
                      [entity]="entity.facade"
                      [options]="options"
                      [layout]="layout"
                      [data]="entity"
                      [deviceData]="{}"
                      (widgetEvent)="alertTemplateEvent($event)"
                    ></alert-facade-widget>
                    <alert-facade-widget
                      *ngIf="entity?.meta?.new && selectedDevice"
                      [entity]="entity.facade"
                      [options]="options"
                      [layout]="layout"
                      [data]="entity"
                      [deviceData]="selectedDevice"
                      (widgetEvent)="alertTemplateEvent($event)"
                    ></alert-facade-widget>
                  </div>
                </div>
                <!-- Notification Settings Widget -->
                <div class="row mt-4 mb-0 pb-0">
                  <div class="col-12">
                    <notification-settings-widget
                      *ngIf="entity.notificationSettings"
                      [entity]="entity.notificationSettings"
                      [options]="options"
                      [layout]="layout"
                      [subscriptionTypeName]="user.subscriptionTypeName"
                      (widgetEvent)="notificationEvent($event)"
                    ></notification-settings-widget>
                  </div>
                </div>

                <div class="row mt-4" *ngIf="!entity?.meta?.new">
                  <div class="col">
                    <div class="card">
                      <div class="card-header">
                        <div class="row align-items-center">
                          <div class="col"><b>Alert Worker</b></div>
                          <div class="col-2 text-right p-0">
                            <toggle-widget
                              [options]="worker_expand_settings"
                            ></toggle-widget>
                          </div>
                        </div>
                      </div>
                      <div
                        class="card-body"
                        [ngClass]="{ 'd-none': !worker_expand_settings.toggle }"
                      >
                        <div class="row">
                          <div class="col" *ngIf="entity?.facade?.status">
                            <widget
                              [entity]="entity.facade.status.trigger_state"
                              [options]="{ id: entity.identifier }"
                              [layout]="layout"
                            ></widget>
                            <widget
                              [entity]="entity.facade.status.subscribers"
                              [options]="{ id: entity.identifier }"
                              [layout]="layout"
                            ></widget>
                            <widget
                              [entity]="entity.facade.status.callbacks"
                              [options]="{ id: entity.identifier }"
                              [layout]="layout"
                            ></widget>
                            <widget
                              [entity]="
                                entity.facade.status.runtime_configuration
                              "
                              [options]="{ id: entity.identifier }"
                              [layout]="layout"
                            ></widget>
                            <widget
                              [entity]="entity.facade.status.facade"
                              [options]="{ id: entity.identifier }"
                              [layout]="layout"
                            ></widget>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row" *ngIf="expanded && !entity?.meta?.new">
                  <div class="col">
                    <log-set-widget
                      *ngIf="entity?.extended?.logs?.alert"
                      [entity]="entity?.extended?.logs?.alert"
                      [options]="{
                        subject: 'ref.alert.' + entity.identifier,
                        expand: false
                      }"
                      [layout]="'standard'"
                    ></log-set-widget>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
  