
    <div class="card card-accent-dark alert-facade-lightning-widget">
      <!-- Card Header -->
      <div class="card-header bg-dark text-white">
        <h5 class="mb-0">Lightning Alert</h5>
      </div>

      <!-- Card Body -->
      <div class="card-body">
        <!-- Device Section -->
        <div class="row mb-3">
          <div class="col-4 font-weight-bold">Device</div>
          <div class="col-8">
            <a
              [routerLink]="'/portal/devices/show/' + entity.device"
              class="text-primary"
              >{{ entity.device }}</a
            >
          </div>
        </div>

        <!-- Association Section -->
        <div class="row mb-3">
          <div class="col-4 font-weight-bold">Association</div>
          <div class="col-8">{{ entity.deviceAssociation }}</div>
        </div>

        <hr class="my-3" />

        <!-- JSON Data Section -->
        <div class="row">
          <div class="col-12">
            <div class="card bg-light">
              <div class="card-body">
                <pre class="mb-0">{{ entity | json }}</pre>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  