export const CountriesList = Object.freeze({
  list: [
    { description: "Afghanistan", value: "AF" },
    { description: "Albania", value: "AL" },
    { description: "Algeria", value: "DZ" },
    { description: "American Samoa", value: "AS" },
    { description: "Andorra", value: "AD" },
    { description: "Angola", value: "AO" },
    { description: "Anguilla", value: "AI" },
    { description: "Antarctica", value: "AQ" },
    { description: "Antigua and Barbuda", value: "AG" },
    { description: "Argentina", value: "AR" },
    { description: "Armenia", value: "AM" },
    { description: "Aruba", value: "AW" },
    { description: "Australia", value: "AU" },
    { description: "Austria", value: "AT" },
    { description: "Azerbaijan", value: "AZ" },
    { description: "The Bahamas", value: "BS" },
    { description: "Bahrain", value: "BH" },
    { description: "Bangladesh", value: "BD" },
    { description: "Barbados", value: "BB" },
    { description: "Belarus", value: "BY" },
    { description: "Belgium", value: "BE" },
    { description: "Belize", value: "BZ" },
    { description: "Benin", value: "BJ" },
    { description: "Bermuda", value: "BM" },
    { description: "Bhutan", value: "BT" },
    { description: "Bolivia", value: "BO" },
    { description: "Bonaire", value: "BQ" },
    { description: "Bosnia and Herzegovina", value: "BA" },
    { description: "Botswana", value: "BW" },
    { description: "Bouvet Island", value: "BV" },
    { description: "Brazil", value: "BR" },
    { description: "British Indian Ocean Territory", value: "IO" },
    { description: "British Virgin Islands", value: "VG" },
    { description: "Brunei", value: "BN" },
    { description: "Bulgaria", value: "BG" },
    { description: "Burkina Faso", value: "BF" },
    { description: "Burundi", value: "BI" },
    { description: "Cambodia", value: "KH" },
    { description: "Cameroon", value: "CM" },
    { description: "Canada", value: "CA" },
    { description: "Cape Verde", value: "CV" },
    { description: "Cayman Islands", value: "KY" },
    { description: "Central African Republic", value: "CF" },
    { description: "Chad", value: "TD" },
    { description: "Chile", value: "CL" },
    { description: "China", value: "CN" },
    { description: "Christmas Island", value: "CX" },
    { description: "Cocos (Keeling) Islands", value: "CC" },
    { description: "Colombia", value: "CO" },
    { description: "Comoros", value: "KM" },
    { description: "Cook Islands", value: "CK" },
    { description: "Costa Rica", value: "CR" },
    { description: "Cote D'Ivoire", value: "CI" },
    { description: "Croatia", value: "HR" },
    { description: "Cuba", value: "CU" },
    { description: "Curacao", value: "CW" },
    { description: "Cyprus", value: "CY" },
    { description: "Czech Republic", value: "CZ" },
    { description: "Democratic Republic of the Congo", value: "CD" },
    { description: "Denmark", value: "DK" },
    { description: "Djibouti", value: "DJ" },
    { description: "Dominica", value: "DM" },
    { description: "Dominican Republic", value: "DO" },
    { description: "Ecuador", value: "EC" },
    { description: "Egypt", value: "EG" },
    { description: "El Salvador", value: "SV" },
    { description: "Equatorial Guinea", value: "GQ" },
    { description: "Eritrea", value: "ER" },
    { description: "Estonia", value: "EE" },
    { description: "Ethiopia", value: "ET" },
    { description: "Falkland Islands", value: "FK" },
    { description: "Faroe Islands", value: "FO" },
    { description: "Federated States of Micronesia", value: "FM" },
    { description: "Fiji", value: "FJ" },
    { description: "Finland", value: "FI" },
    { description: "France", value: "FR" },
    { description: "French Guiana", value: "GF" },
    { description: "French Polynesia", value: "PF" },
    { description: "French Southern Territories", value: "TF" },
    { description: "Gabon", value: "GA" },
    { description: "Gambia", value: "GM" },
    { description: "Georgia", value: "GE" },
    { description: "Germany", value: "DE" },
    { description: "Ghana", value: "GH" },
    { description: "Gibraltar", value: "GI" },
    { description: "Greece", value: "GR" },
    { description: "Greenland", value: "GL" },
    { description: "Grenada", value: "GD" },
    { description: "Guadeloupe", value: "GP" },
    { description: "Guam", value: "GU" },
    { description: "Guatemala", value: "GT" },
    { description: "Guernsey", value: "GG" },
    { description: "Guinea", value: "GN" },
    { description: "Guinea-Bissau", value: "GW" },
    { description: "Guyana", value: "GY" },
    { description: "Haiti", value: "HT" },
    { description: "Heard Island and McDonald Islands", value: "HM" },
    { description: "Honduras", value: "HN" },
    { description: "Hong Kong", value: "HK" },
    { description: "Hungary", value: "HU" },
    { description: "Iceland", value: "IS" },
    { description: "India", value: "IN" },
    { description: "Indonesia", value: "ID" },
    { description: "Iran", value: "IR" },
    { description: "Iraq", value: "IQ" },
    { description: "Ireland", value: "IE" },
    { description: "Isle of Man", value: "IM" },
    { description: "Israel", value: "IL" },
    { description: "Italy", value: "IT" },
    { description: "Jamaica", value: "JM" },
    { description: "Japan", value: "JP" },
    { description: "Jersey", value: "JE" },
    { description: "Jordan", value: "JO" },
    { description: "Kazakhstan", value: "KZ" },
    { description: "Kenya", value: "KE" },
    { description: "Kiribati", value: "KI" },
    { description: "Kosovo", value: "XK" },
    { description: "Kuwait", value: "KW" },
    { description: "Kyrgyzstan", value: "KG" },
    { description: "Laos", value: "LA" },
    { description: "Latvia", value: "LV" },
    { description: "Lebanon", value: "LB" },
    { description: "Lesotho", value: "LS" },
    { description: "Liberia", value: "LR" },
    { description: "Libya", value: "LY" },
    { description: "Liechtenstein", value: "LI" },
    { description: "Lithuania", value: "LT" },
    { description: "Luxembourg", value: "LU" },
    { description: "Macau", value: "MO" },
    { description: "Macedonia", value: "MK" },
    { description: "Madagascar", value: "MG" },
    { description: "Malawi", value: "MW" },
    { description: "Malaysia", value: "MY" },
    { description: "Maldives", value: "MV" },
    { description: "Mali", value: "ML" },
    { description: "Malta", value: "MT" },
    { description: "Marshall Islands", value: "MH" },
    { description: "Martinique", value: "MQ" },
    { description: "Mauritania", value: "MR" },
    { description: "Mauritius", value: "MU" },
    { description: "Mayotte", value: "YT" },
    { description: "Mexico", value: "MX" },
    { description: "Moldova", value: "MD" },
    { description: "Monaco", value: "MC" },
    { description: "Mongolia", value: "MN" },
    { description: "Montenegro", value: "ME" },
    { description: "Montserrat", value: "MS" },
    { description: "Morocco", value: "MA" },
    { description: "Mozambique", value: "MZ" },
    { description: "Myanmar", value: "MM" },
    { description: "Namibia", value: "NA" },
    { description: "Nauru", value: "NR" },
    { description: "Nepal", value: "NP" },
    { description: "Netherlands", value: "NL" },
    { description: "New Caledonia", value: "NC" },
    { description: "New Zealand", value: "NZ" },
    { description: "Nicaragua", value: "NI" },
    { description: "Niger", value: "NE" },
    { description: "Nigeria", value: "NG" },
    { description: "Niue", value: "NU" },
    { description: "Norfolk Island", value: "NF" },
    { description: "North Korea", value: "KP" },
    { description: "Northern Mariana Islands", value: "MP" },
    { description: "Norway", value: "NO" },
    { description: "Oman", value: "OM" },
    { description: "Pakistan", value: "PK" },
    { description: "Palau", value: "PW" },
    { description: "Palestine", value: "PS" },
    { description: "Panama", value: "PA" },
    { description: "Papua New Guinea", value: "PG" },
    { description: "Paraguay", value: "PY" },
    { description: "Peru", value: "PE" },
    { description: "Philippines", value: "PH" },
    { description: "Pitcairn Islands", value: "PN" },
    { description: "Poland", value: "PL" },
    { description: "Portugal", value: "PT" },
    { description: "Puerto Rico", value: "PR" },
    { description: "Qatar", value: "QA" },
    { description: "Republic of the Congo", value: "CG" },
    { description: "Reunion", value: "RE" },
    { description: "Romania", value: "RO" },
    { description: "Russia", value: "RU" },
    { description: "Rwanda", value: "RW" },
    { description: "Saint Barthelemy", value: "BL" },
    { description: "Saint Helena", value: "SH" },
    { description: "Saint Kitts and Nevis", value: "KN" },
    { description: "Saint Lucia", value: "LC" },
    { description: "Saint Martin", value: "MF" },
    { description: "Saint Pierre and Miquelon", value: "PM" },
    { description: "Saint Vincent and the Grenadines", value: "VC" },
    { description: "Samoa", value: "WS" },
    { description: "San Marino", value: "SM" },
    { description: "Sao Tome and Principe", value: "ST" },
    { description: "Saudi Arabia", value: "SA" },
    { description: "Senegal", value: "SN" },
    { description: "Serbia", value: "RS" },
    { description: "Seychelles", value: "SC" },
    { description: "Sierra Leone", value: "SL" },
    { description: "Singapore", value: "SG" },
    { description: "Sint Maarten", value: "SX" },
    { description: "Slovakia", value: "SK" },
    { description: "Slovenia", value: "SI" },
    { description: "Solomon Islands", value: "SB" },
    { description: "Somalia", value: "SO" },
    { description: "South Africa", value: "ZA" },
    { description: "South Georgia and South Sandwich Islands", value: "GS" },
    { description: "South Korea", value: "KR" },
    { description: "South Sudan", value: "SS" },
    { description: "Spain", value: "ES" },
    { description: "Spratly Islands", value: "SP" },
    { description: "Sri Lanka", value: "LK" },
    { description: "Sudan", value: "SD" },
    { description: "Suriname", value: "SR" },
    { description: "Svalbard And Jan Mayen", value: "SJ" },
    { description: "Swaziland", value: "SZ" },
    { description: "Sweden", value: "SE" },
    { description: "Switzerland", value: "CH" },
    { description: "Syria", value: "SY" },
    { description: "Taiwan", value: "TW" },
    { description: "Tajikistan", value: "TJ" },
    { description: "Tanzania", value: "TZ" },
    { description: "Thailand", value: "TH" },
    { description: "Timor-Leste", value: "TL" },
    { description: "Togo", value: "TG" },
    { description: "Tokelau", value: "TK" },
    { description: "Tonga", value: "TO" },
    { description: "Trinidad and Tobago", value: "TT" },
    { description: "Tunisia", value: "TN" },
    { description: "Turkey", value: "TR" },
    { description: "Turkmenistan", value: "TM" },
    { description: "Turks and Caicos Islands", value: "TC" },
    { description: "Tuvalu", value: "TV" },
    { description: "Uganda", value: "UG" },
    { description: "Ukraine", value: "UA" },
    { description: "United Arab Emirates", value: "AE" },
    { description: "United Kingdom", value: "GB" },
    { description: "United States", value: "US" },
    { description: "United States Minor Outlying Islands", value: "UM" },
    { description: "Uruguay", value: "UY" },
    { description: "US Virgin Islands", value: "VI" },
    { description: "Uzbekistan", value: "UZ" },
    { description: "Vanuatu", value: "VU" },
    { description: "Vatican City", value: "VA" },
    { description: "Venezuela", value: "VE" },
    { description: "Vietnam", value: "VN" },
    { description: "Wallis and Futuna", value: "WF" },
    { description: "Western Sahara", value: "EH" },
    { description: "Yemen", value: "YE" },
    { description: "Zambia", value: "ZM" },
    { description: "Zimbabwe", value: "ZW" },
  ],
});
