
    <ng-container [ngSwitch]="layout">
      <ng-container *ngSwitchCase="'table'">
        <div
          class="firmware-generic-widget mb-3"
          [ngClass]="{
            'div-table-tr': options?.index % 2 == 1,
            'div-table-tr-alt': options?.index % 2 == 0
          }"
        >
          <div class="row align-items-center">
            <div class="col text-left py-2">
              <a class="btn btn-link" href="#" role="button"
                >#{{ entity?.identifier }}</a
              >
            </div>
            <div class="col text-left py-2">{{ entity?.name }}</div>
            <div class="col text-left py-2">
              <b
                class="btn-pill pl-2 pr-2"
                [ngClass]="{
                  'bg-success':
                    entity.status == 'approved' || entity.status == 'managed',
                  'bg-danger': entity.status == 'pending'
                }"
              >
                {{ entity?.status }}
              </b>
            </div>
            <div class="col text-left py-2">
              {{ firmwareName(entity?.firmware) }}
            </div>
            <div class="col text-left py-2">
              <embed-firmware-target
                [entity]="entity.target"
                [layout]="'mini'"
                [options]="options"
              ></embed-firmware-target>
            </div>
            <div class="col text-left py-2">{{ entity?.approval }}</div>
          </div>
        </div>
      </ng-container>
      <div class="firmware-generic-widget mb-3" *ngSwitchCase="'shadowbox'">
        <ng-container *ngTemplateOutlet="defaultView"></ng-container>
      </div>
      <div class="firmware-generic-widget mb-3" *ngSwitchCase="'modal'">
        <ng-container *ngTemplateOutlet="defaultView"></ng-container>
      </div>
      <div class="firmware-generic-widget mb-3" *ngSwitchDefault>
        <div class="card m-0">
          <div class="card-header bg-dark text-white p-2">
            #{{ entity?.identifier }}
          </div>
          <div class="card-body p-2">
            <ng-container *ngTemplateOutlet="defaultView"></ng-container>
          </div>
          <div
            class="card-footer p-2"
            *ngIf="
              options.edit &&
              !options?.modal_form &&
              (showApproveForm() || entity?.pending)
            "
          >
            <div class="row">
              <div class="col-4">
                <button
                  *ngIf="entity?.pending"
                  (click)="saveLink(linkModal, $event)"
                  class="btn btn-success btn-block"
                  type="submit"
                >
                  <i class="fa fa-plus-circle"></i> Save Changes
                </button>
              </div>
              <div class="col-4">
                <button
                  *ngIf="showApprove()"
                  (click)="approveLink(linkModal, $event)"
                  class="btn btn-primary btn-block"
                >
                  Approve
                </button>
              </div>
              <div class="col-4">
                <button
                  *ngIf="entity?.userMayDelete()"
                  (click)="deleteLink(linkModal, $event)"
                  class="btn btn-warning btn-block"
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <!-- ================== -->
    <!-- Default Template   -->
    <!-- ================== -->
    <ng-template #defaultView>
      <div class="row mb-2">
        <div class="col">
          <span
            ><strong>Identifier: </strong
            ><span>{{ entity.identifier }}</span></span
          >
        </div>
      </div>
      <div class="row mb-2 align-items-center">
        <div class="col-auto">
          <strong>Name: </strong>
        </div>
        <div class="col">
          <span *ngIf="!options?.edit">{{ entity.name }}</span>
          <input
            *ngIf="options?.edit"
            type="text"
            [(ngModel)]="entity.name"
            (ngModelChange)="updateDefinition()"
            class="form-control w-75 d-inline-block"
          />
        </div>
      </div>

      <div class="row mb-2">
        <div class="col">
          <span
            ><strong>Status: </strong><span>{{ entity.status }}</span></span
          >
        </div>
      </div>
      <div class="row mb-2">
        <div class="col">
          <span *ngIf="!options?.edit"
            ><strong>Release: </strong><span>{{ entity.release }}</span></span
          >
          <span *ngIf="options?.edit"
            ><strong>Release: </strong><span>{{ entity.release }}</span></span
          >
        </div>
      </div>
      <div class="row mb-2">
        <div class="col">
          <span *ngIf="!options?.edit"
            ><strong>Approval: </strong><span>{{ entity.approval }}</span></span
          >
        </div>
      </div>
      <div class="row mb-2">
        <div class="col">
          <span *ngIf="options?.edit"
            ><strong>Final Approval: </strong
            ><span>{{ entity.finalization }}</span></span
          >
        </div>
      </div>
      <div class="row mb-2">
        <div class="col">
          <span *ngIf="!options?.edit"
            ><strong>Firmware: </strong><span>{{ entity.firmware }}</span></span
          >
          <div *ngIf="options?.edit">
            <strong>Firmware: </strong>
            <div *ngIf="available.firmware | async as versions">
              <ng-select
                placeholder="Select Firmware"
                [items]="firmware_list"
                [multiple]="false"
                [(ngModel)]="entity.firmware"
                bindLabel="label"
                bindValue="value"
                (change)="onChange($event)"
              ></ng-select>
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col">
          <span *ngIf="!options?.edit"
            ><strong>Owner: </strong><span>{{ entity.owner }}</span></span
          >
        </div>
      </div>
      <div class="row mb-2">
        <div class="col">
          <span *ngIf="!options?.edit"
            ><strong>Description: </strong><br /><span>{{
              entity.description
            }}</span></span
          >
          <span *ngIf="options?.edit"
            ><strong>Description: </strong><br /><span>
              <textarea
                [(ngModel)]="entity.description"
                (ngModelChange)="updateDefinition()"
                class="form-control"
                rows="4"
              ></textarea></span
          ></span>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col">
          <span *ngIf="!options?.edit"
            ><strong>Notes: </strong><br /><span>{{ entity.notes }}</span></span
          >
          <span *ngIf="options?.edit"
            ><strong>Notes: </strong><br /><span>
              <textarea
                [(ngModel)]="entity.notes"
                (ngModelChange)="updateDefinition()"
                class="form-control"
                rows="4"
              ></textarea></span
          ></span>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col">
          <span *ngIf="!options?.edit"
            ><strong>Criteria: </strong
            ><span
              ><embed-firmware-target
                [entity]="entity.target"
                [layout]="'mini'"
                [options]="options"
              ></embed-firmware-target></span
          ></span>
          <div *ngIf="options?.edit">
            <ng-container *ngTemplateOutlet="editCriteria"></ng-container>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template #editCriteria>
      <div class="row mb-2">
        <div class="col mx-auto">
          <strong>Criteria</strong>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col">
          <div class="card m-0">
            <div class="card-header card-accent-dark p-2">
              <ng-select
                placeholder="Select Criteria"
                [items]="criteria_types"
                [multiple]="false"
                [(ngModel)]="entity.target.type"
                bindLabel="label"
                bindValue="value"
                (ngModelChange)="onChangeCriteria($event)"
              ></ng-select>
            </div>
            <div *ngIf="entity.target.type" class="card-body p-2">
              <!-- Series -->
              <div
                *ngIf="entity.target.typeHasConstraint('series')"
                class="row mb-2"
              >
                <div class="col-4">
                  <strong>Series: </strong>
                </div>
                <div class="col-8">
                  <div *ngIf="available.series">
                    <ng-select
                      placeholder="Select Series"
                      (ngModelChange)="refreshFirmwareSeries($event)"
                      [items]="available.series"
                      bindLabel="label"
                      bindValue="value"
                      [multiple]="false"
                      [(ngModel)]="entity.target.constraints.series"
                    ></ng-select>
                  </div>
                </div>
              </div>

              <!-- Manufacturer -->
              <div
                *ngIf="entity.target.typeHasConstraint('manufacturer')"
                class="row mb-2"
              >
                <div class="col-4">
                  <strong>Manufacturer: </strong>
                </div>
                <div class="col-8">
                  <div
                    *ngIf="
                      manufacturerSelectOptions &&
                      manufacturerSelectOptions.length
                    "
                  >
                    <ng-select
                      placeholder="Select Manufacturer"
                      (ngModelChange)="refreshFirmwareManufacturer($event)"
                      [items]="manufacturerSelectOptions"
                      bindLabel="label"
                      bindValue="value"
                      [multiple]="false"
                      [(ngModel)]="entity.target.constraints.manufacturer"
                    ></ng-select>
                  </div>
                </div>
              </div>

              <!-- Gateway -->
              <div
                *ngIf="entity.target.typeHasConstraint('gateway')"
                class="row mb-2"
              >
                <div class="col-4"><strong>Gateway:</strong></div>
                <div class="col-8">
                  <input
                    type="text"
                    [(ngModel)]="entity.target.constraints.gateway"
                    (ngModelChange)="updateDefinition()"
                    class="form-control"
                  />
                </div>
              </div>

              <!-- Appengine -->
              <div
                *ngIf="entity.target.typeHasConstraint('appengine')"
                class="row mb-2"
              >
                <div class="col-4">
                  <strong>Appengine Device: </strong>
                </div>
                <div class="col-8">
                  <input
                    type="text"
                    [(ngModel)]="entity.target.constraints.appengine"
                    (ngModelChange)="updateDefinition()"
                    class="form-control"
                  />
                </div>
              </div>

              <!-- Gateway Type -->
              <div
                *ngIf="entity.target.typeHasConstraint('type')"
                class="row mb-2"
              >
                <div class="col-4">
                  <strong>Type: </strong>
                </div>
                <div class="col-8">
                  <div *ngIf="available.types | async as types">
                    <ng-select
                      placeholder="Select Device Type"
                      [items]="types"
                      bindLabel="label"
                      bindValue="value"
                      [multiple]="false"
                      [(ngModel)]="entity.target.constraints.type"
                    ></ng-select>
                  </div>
                </div>
              </div>

              <!-- Batch -->
              <div
                *ngIf="entity.target.typeHasConstraint('batch')"
                class="row mb-2"
              >
                <div class="col-4">
                  <strong>Batch: </strong>
                </div>
                <div class="col-8">
                  <div *ngIf="available.batches | async as batches">
                    <ng-select
                      placeholder="Select Batch"
                      [items]="batches"
                      bindLabel="label"
                      bindValue="value"
                      [multiple]="false"
                      [(ngModel)]="entity.target.constraints.batch"
                    ></ng-select>
                  </div>
                </div>
              </div>

              <!-- Range -->
              <div
                *ngIf="entity.target.typeHasConstraint('range')"
                class="row mb-2"
              >
                <div class="col-4">
                  <strong>Range: </strong>
                </div>
                <div class="col-8">
                  <input
                    type="text"
                    [(ngModel)]="entity.target.constraints.range_from"
                    (ngModelChange)="updateDefinition()"
                    class="form-control d-inline-block w-25"
                  />
                  -
                  <input
                    type="text"
                    [(ngModel)]="entity.target.constraints.range_to"
                    (ngModelChange)="updateDefinition()"
                    class="form-control d-inline-block w-25"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>

    <!-- Pop-Up Menu -->
    <ng-template #linkModal>
      <div class="modal-header">
        <h4 class="modal-title pull-left">{{ currentModal.title }}</h4>
        <button
          type="button"
          class="close pull-right"
          aria-label="Close"
          (click)="modalRef.hide()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div *ngIf="currentModal.form == 'delete-link'">
          <div class="row mb-2">
            <div class="col">
              <div class="alert alert-danger">Delete Link?</div>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col">
              <widget-embed
                [entity]="entity"
                [layout]="'sidebar'"
                [options]="{}"
              ></widget-embed>
            </div>
          </div>
        </div>

        <div *ngIf="currentModal.form == 'save-changes'">
          <div class="row mb-2" *ngIf="entity?.pending">
            <div class="col-12">
              <div class="alert alert-danger">
                Caution: Saving changes to a firmware link will clear its
                approval flag, once cleared firmware will downgrade to the
                previous next highest weight link.
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="currentModal.form == 'approve-link'">
          <div class="row mb-2">
            <div class="col">
              <div class="alert alert-danger">Approve Firmware Link?</div>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col">
              <span
                ><strong>Approval Note: </strong
                ><span>
                  <textarea
                    [(ngModel)]="currentModal.approvalMessage"
                    class="form-control"
                    rows="4"
                    placeholder="Required Comment"
                  ></textarea></span
              ></span>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button
          *ngIf="currentModal?.cancelMsg"
          type="button"
          class="btn btn-secondary"
          (click)="currentModal?.cancel()"
          data-dismiss="modal"
        >
          {{ currentModal?.cancelMsg }}
        </button>
        <button
          *ngIf="currentModal?.deleteMsg"
          type="button"
          class="btn btn-warning"
          (click)="currentModal?.delete()"
          data-dismiss="modal"
        >
          {{ currentModal?.deleteMsg }}
        </button>
        <button
          *ngIf="currentModal?.confirmMsg"
          type="button"
          class="btn btn-danger"
          (click)="currentModal?.confirm()"
        >
          {{ currentModal?.confirmMsg }}
        </button>
      </div>
    </ng-template>
  