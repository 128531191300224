import { Component, Input } from "@angular/core";
import { FormulaTreeNode } from "../../../../structs/formula-tree/node";
import { FormulaTreeNodeContentProcessedField } from "../../../../structs/formula-tree/node/content/processed-field";
import { NodeContentTypeEnum } from "../../../../enums/node-content-type.enum";
import { NodeTypeEnum } from "../../../../enums/node-type.enum";
import { UnitEnum } from "../../../../enums/unit.enum";
import { AggregationTypeEnum } from "../../../../enums/aggregation-type.enum";
import { AggregationLevelEnum } from "../../../../enums/aggregation-level.enum";

@Component({
  selector: "formula-node-edit",
  templateUrl: "./edit-def-encoding.component.html",
})
export class FormulaNodeEditComponent {
  @Input() node: FormulaTreeNode = null;

  public nodeTypes = [];
  public nodeContentTypes = [];
  public units = [];
  public aggLevel = [];
  public aggType = [];
  public nodeTypevalue = 2;

  constructor() {
    let keys = Object.keys(NodeContentTypeEnum);
    let array = keys.slice(keys.length / 2);
    let array2 = keys.splice(0, keys.length / 2);
    array.forEach((item, index) => {
      this.nodeContentTypes.push({ val: array2[index], display: item });
    });
    keys = Object.keys(NodeTypeEnum);
    array = keys.slice(keys.length / 2);
    array2 = keys.splice(0, keys.length / 2);
    array.forEach((item, index) => {
      this.nodeTypes.push({ val: array2[index], display: item });
    });
    keys = Object.keys(UnitEnum);
    array = keys.slice(keys.length / 2);
    array2 = keys.splice(0, keys.length / 2);
    array.forEach((item, index) => {
      this.units.push({ val: array2[index], display: item });
    });
  }

  ngOnInit() {
    if (this.node.content && !isNaN(parseInt(this.node.content.type))) {
      this.nodeTypevalue = parseInt(this.node.content.type);
      if (this.nodeTypevalue == 3) {
        this.initAggrigates();
      }
    }
  }

  addChild() {
    this.node.children.push(new FormulaTreeNode(this.newNodeJson()));
  }

  removeChild(i) {
    this.node.children.splice(i, 1);
    let x = 1;
    this.node.children.forEach((item, index) => {
      item.materialized_path = this.node.materialized_path + "." + x;
      x++;
    });
  }

  newNodeJson() {
    return {
      nodeType: 1,
      materialized_path:
        this.node.materialized_path + "." + (this.node.children.length + 1),
      content: {},
    };
  }

  typeUpdate(v) {
    if (v == 1) {
      this.node.content.rawValue = null;
      this.node.content.inputValue = null;
      this.node.content.scalarValue = { unit: 256, value: 0 };
      this.node.content.type = "1";
    } else if (v == 2) {
      this.node.content.rawValue = true;
      this.node.content.type = "2";
      this.node.content.inputValue = null;
      this.node.content.scalarValue = null;
    } else if (v == 3) {
      this.initAggrigates();
      this.node.content.rawValue = null;
      this.node.content.scalarValue = null;
      this.node.content.type = "3";
      this.node.content.inputValue = new FormulaTreeNodeContentProcessedField({
        nodeIdentifier: "",
        aggregationLevel: "0",
        aggregationCount: "1",
        aggregationType: "0",
        asUnit: 256,
      });
    } else {
      //TODO, actually implement the others.
      this.typeUpdate(2);
    }
  }

  initAggrigates() {
    if (this.aggLevel.length == 0) {
      let arr1 = [];
      let arr2 = [];
      let keys = Object.keys(AggregationLevelEnum);
      let array = keys.slice(keys.length / 2);
      let array2 = keys.splice(0, keys.length / 2);
      array.forEach((item, index) => {
        arr1.push({ val: array2[index], display: item });
      });
      keys = Object.keys(AggregationTypeEnum);
      array = keys.slice(keys.length / 2);
      array2 = keys.splice(0, keys.length / 2);
      array.forEach((item, index) => {
        arr2.push({ val: array2[index], display: item });
      });
      this.aggLevel = arr1;
      this.aggType = arr2;
    }
  }
}
