export enum DeviceCategoryEnum {
  Temperature = 1,
  TemperatureAndHumidity,
  TemperatureAndRain,
  WindTemperatureAndHumidity,
  AllInOne,
  TX60,
  TX70,
  TX71,
  WS2816,
  DisplayTemperatureAndHumidity,
  Rain,
  Wetness,
  WindRainAndLightning = 20,
  Bbq,
  Pool,
  Link,
  NewType25 = 25,
  Composite = 30,
  IndoorTempHumi,
  Bridge,
  WindSpeed = 99,
}
