import { Component, Input } from "@angular/core";

@Component({
  selector: "render-object",
  template: `
    <div class="alert alert-info bg-primary">
      <div class="row align-items-center" *ngFor="let entry of listFormat">
        <div class="col-auto d-flex align-items-center justify-content-center">
          <b>{{ entry.key }}</b>
        </div>
        <div class="col-auto d-flex align-items-center justify-content-center">
          <ng-container *ngIf="entry.is_object">
            <render-object
              [object]="entry.value"
              [exclude]="exclude"
            ></render-object>
          </ng-container>
          <ng-container *ngIf="!entry.is_object">
            {{ entry.value | json }}
          </ng-container>
        </div>
      </div>
    </div>
  `,
})
export class RenderObjectComponent {
  @Input() object = {};
  @Input() exclude = [];
  public listFormat = [];

  constructor() {}

  ngOnInit(): void {
    let list = [];
    Object.keys(this.object).forEach((key) => {
      if (!this.exclude.includes(key)) {
        let value = this.object[key];
        let isObject = value !== null && typeof value == "object";
        list.push({ key: key, value: value, is_object: isObject });
      }
    });
    this.listFormat = list;
  }

  isObj(v) {
    return this.object[v] !== null && typeof this.object[v] === "object";
  }
}
