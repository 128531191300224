import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ToasterService } from "angular2-toaster";
import { AlertTemperatureFacadeEntity } from "../../../../entities/alert";
import { AlertFacadeDefaultWidgetComponent } from "./default.widget.component";
import { AlertFacadeWidgetEventStruct } from "./alert-facade-widget-event.struct";
import { UnitEnum } from "app/enums";

@Component({
  selector: "alert-facade-temperature-widget",
  template: `
    <div class="card card-accent-dark alert-facade-temperature-widget">
      <div class="card-header bg-dark text-white">
        <div class="d-flex justify-content-between align-items-center">
          <h5 class="mb-0">Temperature Alert</h5>
        </div>
      </div>
      <div class="card-body">
        <div class="mb-3">
          <div class="row">
            <div class="col-4 font-weight-bold">Device</div>
            <div class="col-8">
              <a
                [routerLink]="'/portal/devices/show/' + entity.device"
                class="text-primary"
                >{{ entity.device }}</a
              >
            </div>
          </div>
          <div class="row">
            <div class="col-4 font-weight-bold">Association</div>
            <div class="col-8">{{ entity.deviceAssociation }}</div>
          </div>
        </div>

        <hr class="my-3" />

        <div *ngIf="entity?.identifier == null" class="mb-3">
          <div class="d-flex justify-content-between align-items-center">
            <div class="font-weight-bold">
              Choose DegreeCelcius / Farenheit (keep off for DegreeCelcius)
            </div>
            <div *ngIf="options?.edit" class="ml-2">
              <label class="switch switch-3d switch-primary">
                <input
                  checked=""
                  class="switch-input switch-primary"
                  (change)="toggleDegreeCelciusToFarenheit($event)"
                  [(ngModel)]="degreeValue"
                  type="checkbox"
                />
                <span class="switch-slider"></span>
              </label>
            </div>
          </div>
        </div>

        <hr class="my-3" />

        <div class="mb-3">
          <div class="d-flex justify-content-between align-items-center">
            <div class="font-weight-bold">
              Temperature Between (-40 to
              {{
                degreeValue ||
                entity.editTemperatureAbove.unit?.name === "DegreesFahrenheit"
                  ? 140
                  : 60
              }}
              {{
                entity.editTemperatureAbove.unit?.name === "DegreesFahrenheit"
                  ? "°F"
                  : "°C"
              }})
            </div>
            <div *ngIf="!options?.edit" class="text-muted">
              <b>{{ entity.editTemperatureAbove.value }}</b>
              <span> {{ entity.editTemperatureAbove.unit?.name }}</span> and
              <b>{{ entity.editTemperatureBelow.value }}</b>
              <span> {{ entity.editTemperatureBelow.unit?.name }}</span>
            </div>
            <div *ngIf="options?.edit" class="w-50">
              <div class="d-flex" style="gap:5%;">
                <input
                  type="range"
                  class="form-control-range"
                  [(ngModel)]="entity.editTemperatureAbove.value"
                  (input)="
                    inputChanges($event.target.value, 'temperatureAbove')
                  "
                  [min]="-40"
                  [max]="
                    degreeValue ||
                    entity.editTemperatureAbove.unit?.name ===
                      'DegreesFahrenheit'
                      ? 140
                      : 60
                  "
                  step="1"
                  name="temp_above"
                />
                <div>
                  <input
                    style="margin: 0 15px;"
                    type="number"
                    class="form-control"
                    [(ngModel)]="entity.editTemperatureAbove.value"
                    (input)="
                      inputChanges($event.target.value, 'temperatureAbove')
                    "
                    [min]="-40"
                    [max]="
                      degreeValue ||
                      entity.editTemperatureAbove.unit?.name ===
                        'DegreesFahrenheit'
                        ? 140
                        : 60
                    "
                    name="temp_above"
                  />
                </div>
              </div>
              <span class="ml-2">
                {{ entity.editTemperatureAbove.value }} -
                {{ entity.editTemperatureAbove.unit?.name }}</span
              >
              and
              <div class="d-flex" style="gap:5%;">
                <input
                  name="temp_below"
                  type="range"
                  class="form-control-range"
                  [(ngModel)]="entity.editTemperatureBelow.value"
                  (input)="
                    inputChanges($event.target.value, 'temperatureBelow')
                  "
                  [min]="-40"
                  [max]="
                    degreeValue ||
                    entity.editTemperatureBelow.unit?.name ===
                      'DegreesFahrenheit'
                      ? 140
                      : 60
                  "
                  step="1"
                />
                <div>
                  <input
                    style="margin: 0 15px;"
                    name="temp_below"
                    type="number"
                    class="form-control"
                    [(ngModel)]="entity.editTemperatureBelow.value"
                    (input)="
                      inputChanges($event.target.value, 'temperatureBelow')
                    "
                    [min]="-40"
                    [max]="
                      degreeValue ||
                      entity.editTemperatureBelow.unit?.name ===
                        'DegreesFahrenheit'
                        ? 140
                        : 60
                    "
                  />
                </div>
              </div>
              <span class="ml-2">
                {{ entity.editTemperatureBelow.value }} -
                {{ entity.editTemperatureBelow.unit?.name }}</span
              >
            </div>
          </div>
        </div>

        <!-----  <div [ngSwitch]="entity.subType">

          <div *ngSwitchCase="FormatEnum.ValueAbove" class="mb-3">
            <div class="d-flex justify-content-between align-items-center">
              <div class="font-weight-bold">Temperature Above</div>
              <div *ngIf="!options?.edit" class="text-muted">
                <b>{{ entity.editTemperatureAbove.value }}</b>
                <span> {{ entity.editTemperatureAbove.unit?.name }}</span>
              </div>
              <div *ngIf="options?.edit" class="w-50">
                <input
                  type="range"
                  class="form-control-range"
                  [(ngModel)]="entity.editTemperatureAbove.value"
                  (input)="
                    inputChanges($event.target.value, 'temperatureAbove')
                  "
                  [min]="-40"
                  [max]="
                    degreeValue ||
                    entity.editTemperatureAbove.unit?.name ===
                      'DegreesFahrenheit'
                      ? 140
                      : 60
                  "
                  step="1"
                  name="temp_above"
                />
                <span class="ml-2">
                  {{ entity.editTemperatureAbove.value }} -
                  {{ entity.editTemperatureAbove.unit?.name }}</span
                >
              </div>
            </div>
          </div>

          <div *ngSwitchCase="FormatEnum.ValueBelow" class="mb-3">
            <div class="d-flex justify-content-between align-items-center">
              <div class="font-weight-bold">Temperature Below</div>
              <div *ngIf="!options?.edit" class="text-muted">
                <b>{{ entity.editTemperatureBelow.value }}</b>
                <span> {{ entity.editTemperatureBelow.unit?.name }}</span>
              </div>
              <div *ngIf="options?.edit" class="w-50">
                <input
                  name="temp_below"
                  type="range"
                  class="form-control-range"
                  [(ngModel)]="entity.editTemperatureBelow.value"
                  (input)="
                    inputChanges($event.target.value, 'temperatureBelow')
                  "
                  [min]="-40"
                  [max]="
                    degreeValue ||
                    entity.editTemperatureBelow.unit?.name ===
                      'DegreesFahrenheit'
                      ? 140
                      : 60
                  "
                  step="1"
                />
                <span class="ml-2">
                  {{ entity.editTemperatureBelow?.value }} -
                  {{ entity.editTemperatureBelow.unit?.name }}</span
                >
              </div>
            </div>
          </div>

          <div *ngSwitchCase="FormatEnum.ValueBetween" class="mb-3">
            <div class="d-flex justify-content-between align-items-center">
              <div class="font-weight-bold">Temperature Between</div>
              <div *ngIf="!options?.edit" class="text-muted">
                <b>{{ entity.editTemperatureAbove.value }}</b>
                <span> {{ entity.editTemperatureAbove.unit?.name }}</span> and
                <b>{{ entity.editTemperatureBelow.value }}</b>
                <span> {{ entity.editTemperatureBelow.unit?.name }}</span>
              </div>
              <div *ngIf="options?.edit" class="w-50">
                <input
                  type="range"
                  class="form-control-range"
                  [(ngModel)]="entity.editTemperatureAbove.value"
                  (input)="
                    inputChanges($event.target.value, 'temperatureAbove')
                  "
                  [min]="-40"
                  [max]="
                    degreeValue ||
                    entity.editTemperatureAbove.unit?.name ===
                      'DegreesFahrenheit'
                      ? 140
                      : 60
                  "
                  step="1"
                  name="temp_above"
                />
                <span class="ml-2">
                  {{ entity.editTemperatureAbove.value }} -
                  {{ entity.editTemperatureAbove.unit?.name }}</span
                >
                and
                <input
                  name="temp_below"
                  type="range"
                  class="form-control-range"
                  [(ngModel)]="entity.editTemperatureBelow.value"
                  (input)="
                    inputChanges($event.target.value, 'temperatureBelow')
                  "
                  [min]="-40"
                  [max]="
                    degreeValue ||
                    entity.editTemperatureBelow.unit?.name ===
                      'DegreesFahrenheit'
                      ? 140
                      : 60
                  "
                  step="1"
                />
                <span class="ml-2">
                  {{ entity.editTemperatureBelow.value }} -
                  {{ entity.editTemperatureBelow.unit?.name }}</span
                >
              </div>
            </div>
          </div>

          <div *ngSwitchCase="FormatEnum.ValueOutside" class="mb-3">
            <div class="d-flex justify-content-between align-items-center">
              <div class="font-weight-bold">Temperature Outside</div>
              <div *ngIf="!options?.edit" class="text-muted">
                <b>{{ entity.editTemperatureBelow.value }}</b>
                <span> {{ entity.editTemperatureBelow.unit?.name }}</span> and
                <b>{{ entity.editTemperatureAbove.value }}</b>
                <span> {{ entity.editTemperatureAbove.unit?.name }}</span>
              </div>
              <div *ngIf="options?.edit" class="w-50">
                <input
                  name="temp_below"
                  type="range"
                  class="form-control-range"
                  [(ngModel)]="entity.editTemperatureBelow.value"
                  (input)="
                    inputChanges($event.target.value, 'temperatureBelow')
                  "
                  [min]="-40"
                  [max]="
                    degreeValue ||
                    entity.editTemperatureBelow.unit?.name ===
                      'DegreesFahrenheit'
                      ? 140
                      : 60
                  "
                  step="1"
                />
                <span class="ml-2">
                  {{ entity.editTemperatureBelow.value }} -
                  {{ entity.editTemperatureBelow.unit?.name }}</span
                >
                and
                <input
                  name="temp_below"
                  type="range"
                  class="form-control-range"
                  [(ngModel)]="entity.editTemperatureAbove.value"
                  (input)="
                    inputChanges($event.target.value, 'temperatureAbove')
                  "
                  [min]="-40"
                  [max]="
                    degreeValue ||
                    entity.editTemperatureAbove.unit?.name ===
                      'DegreesFahrenheit'
                      ? 140
                      : 60
                  "
                  step="1"
                />
                <span class="ml-2">
                  {{ entity.editTemperatureAbove.value }} -
                  {{ entity.editTemperatureAbove.unit?.name }}</span
                >
              </div>
            </div>
          </div>

          <div *ngSwitchCase="FormatEnum.IndoorValueAbove" class="mb-3">
            <div class="d-flex justify-content-between align-items-center">
              <div class="font-weight-bold">Indoor Temperature Above</div>
              <div *ngIf="!options?.edit" class="text-muted">
                <b>{{ entity.editIndoorTemperatureAbove.value }}</b>
                <span> {{ entity.editIndoorTemperatureAbove.unit?.name }}</span>
              </div>
              <div *ngIf="options?.edit" class="w-50">
                <input
                  name="temp_above"
                  type="range"
                  class="form-control-range"
                  [(ngModel)]="entity.editIndoorTemperatureAbove.value"
                  (input)="
                    inputChanges($event.target.value, 'indoorTemperatureAbove')
                  "
                  [min]="-40"
                  [max]="
                    degreeValue ||
                    entity.editIndoorTemperatureAbove.unit?.name ===
                      'DegreesFahrenheit'
                      ? 140
                      : 60
                  "
                  step="1"
                />
                <span class="ml-2">
                  {{ entity.editIndoorTemperatureAbove.value }} -
                  {{ entity.editIndoorTemperatureAbove.unit?.name }}</span
                >
              </div>
            </div>
          </div>

          <div *ngSwitchCase="FormatEnum.IndoorValueBelow" class="mb-3">
            <div class="d-flex justify-content-between align-items-center">
              <div class="font-weight-bold">Indoor Temperature Below</div>
              <div *ngIf="!options?.edit" class="text-muted">
                <b>{{ entity.editIndoorTemperatureBelow.value }}</b>
                <span> {{ entity.editIndoorTemperatureBelow.unit?.name }}</span>
              </div>
              <div *ngIf="options?.edit" class="w-50">
                <input
                  name="temp_below"
                  type="range"
                  class="form-control-range"
                  [(ngModel)]="entity.editIndoorTemperatureBelow.value"
                  (input)="
                    inputChanges($event.target.value, 'indoorTemperatureBelow')
                  "
                  [min]="-40"
                  [max]="
                    degreeValue ||
                    entity.editIndoorTemperatureBelow.unit?.name ===
                      'DegreesFahrenheit'
                      ? 140
                      : 60
                  "
                  step="1"
                />
                <span class="ml-2">
                  {{ entity.editIndoorTemperatureBelow.value }} -
                  {{ entity.editIndoorTemperatureBelow.unit?.name }}</span
                >
              </div>
            </div>
          </div>

          <div *ngSwitchCase="FormatEnum.IndoorValueBetween" class="mb-3">
            <div class="d-flex justify-content-between align-items-center">
              <div class="font-weight-bold">Indoor Temperature Between</div>
              <div *ngIf="!options?.edit" class="text-muted">
                <b>{{ entity.editIndoorTemperatureAbove.value }}</b>
                <span> {{ entity.editIndoorTemperatureAbove.unit?.name }}</span>
                and <b>{{ entity.editIndoorTemperatureBelow.value }}</b>
                <span> {{ entity.editIndoorTemperatureBelow.unit?.name }}</span>
              </div>
              <div *ngIf="options?.edit" class="w-50">
                <input
                  name="temp_above"
                  type="range"
                  class="form-control-range"
                  [(ngModel)]="entity.editIndoorTemperatureAbove.value"
                  (input)="
                    inputChanges($event.target.value, 'indoorTemperatureAbove')
                  "
                  [min]="-40"
                  [max]="
                    degreeValue ||
                    entity.editIndoorTemperatureAbove.unit?.name ===
                      'DegreesFahrenheit'
                      ? 140
                      : 60
                  "
                  step="1"
                />

                <span class="ml-2">
                  {{ entity.editIndoorTemperatureAbove.value }} -
                  {{ entity.editIndoorTemperatureAbove.unit?.name }}</span
                >
                and

                <input
                  name="temp_below"
                  type="range"
                  class="form-control-range"
                  [(ngModel)]="entity.editIndoorTemperatureBelow.value"
                  (input)="
                    inputChanges($event.target.value, 'indoorTemperatureBelow')
                  "
                  [min]="-40"
                  [max]="
                    entity.editIndoorTemperatureBelow.unit?.name ===
                    'DegreesFahrenheit'
                      ? 140
                      : 60
                  "
                  step="1"
                />
                <span class="ml-2">
                  {{ entity.editIndoorTemperatureBelow.value }} -
                  {{ entity.editIndoorTemperatureBelow.unit?.name }}</span
                >
              </div>
            </div>
          </div>

          <div *ngSwitchCase="FormatEnum.IndoorValueOutside" class="mb-3">
            <div class="d-flex justify-content-between align-items-center">
              <div class="font-weight-bold">Indoor Temperature Outside</div>
              <div *ngIf="!options?.edit" class="text-muted">
                <b>{{ entity.editIndoorTemperatureBelow.value }}</b>
                <span> {{ entity.editIndoorTemperatureBelow.unit?.name }}</span>
                and <b>{{ entity.editIndoorTemperatureAbove.value }}</b>
                <span> {{ entity.editIndoorTemperatureAbove.unit?.name }}</span>
              </div>
              <div *ngIf="options?.edit" class="w-50">
                <input
                  name="temp_below"
                  type="range"
                  class="form-control-range"
                  [(ngModel)]="entity.editIndoorTemperatureBelow.value"
                  (input)="
                    inputChanges($event.target.value, 'indoorTemperatureBelow')
                  "
                  [min]="-40"
                  [max]="
                    entity.editIndoorTemperatureBelow.unit?.name ===
                    'DegreesFahrenheit'
                      ? 140
                      : 60
                  "
                  step="1"
                />
                <span class="ml-2">
                  {{ entity.editIndoorTemperatureBelow.value }} -
                  {{ entity.editIndoorTemperatureBelow.unit?.name }}</span
                >
                and

                <input
                  name="temp_above"
                  type="range"
                  class="form-control-range"
                  [(ngModel)]="entity.editIndoorTemperatureAbove.value"
                  (input)="
                    inputChanges($event.target.value, 'indoorTemperatureAbove')
                  "
                  [min]="-40"
                  [max]="
                    entity.editIndoorTemperatureAbove.unit?.name ===
                    'DegreesFahrenheit'
                      ? 140
                      : 60
                  "
                  step="1"
                />
                <span class="ml-2">
                  {{ entity.editIndoorTemperatureAbove.value }} -
                  {{ entity.editIndoorTemperatureAbove.unit?.name }}</span
                >
              </div>
            </div>
          </div>

          <div *ngSwitchDefault></div>
        </div> ----->

        <hr class="my-3" />

        <div *ngIf="options?.edit || entity.hasHeatIndex" class="mb-3">
          <div class="d-flex justify-content-between align-items-center">
            <div class="font-weight-bold">
              HeatIndex Above ({{
                entity.editHeatIndexAbove.unit?.name === "DegreesFahrenheit"
                  ? 80
                  : 27
              }}
              to
              {{
                entity.editHeatIndexAbove.unit?.name === "DegreesFahrenheit"
                  ? 140
                  : 60
              }}
              {{
                entity.editHeatIndexAbove.unit?.name === "DegreesFahrenheit"
                  ? "°F"
                  : "°C"
              }})
            </div>
            <div *ngIf="options?.edit" class="ml-2">
              <label class="switch switch-3d switch-primary">
                <input
                  checked=""
                  class="switch-input switch-primary"
                  (change)="toggleOptional($event)"
                  [(ngModel)]="entity.hasHeatIndex"
                  type="checkbox"
                />
                <span class="switch-slider"></span>
              </label>
            </div>
            <div *ngIf="!options?.edit" class="text-muted">
              <b>{{ entity.editHeatIndexAbove.value }}</b
              ><span> {{ entity.editHeatIndexAbove.unit?.name }}</span>
            </div>
            <div *ngIf="options?.edit" class="w-50">
              <ng-container *ngIf="entity.hasHeatIndex">
                <div class="d-flex" style="gap:5%;">
                  <input
                    name="heat_index"
                    type="range"
                    class="form-control-range"
                    [(ngModel)]="entity.editHeatIndexAbove.value"
                    (input)="
                      inputChanges($event.target.value, 'heatIndexAbove')
                    "
                    [min]="
                      entity.editHeatIndexAbove.unit?.name ===
                      'DegreesFahrenheit'
                        ? 80
                        : 27
                    "
                    [max]="
                      entity.editHeatIndexAbove.unit?.name ===
                      'DegreesFahrenheit'
                        ? 140
                        : 60
                    "
                    step="1"
                  />
                  <div>
                    <input
                      style="margin: 0 15px;"
                      name="heat_index"
                      type="number"
                      class="form-control"
                      [(ngModel)]="entity.heatIndexAbove.value"
                      (input)="
                        inputChanges($event.target.value, 'heatIndexAbove')
                      "
                      [min]="
                        entity.editHeatIndexAbove.unit?.name ===
                        'DegreesFahrenheit'
                          ? 80
                          : 27
                      "
                      [max]="
                        entity.editHeatIndexAbove.unit?.name ===
                        'DegreesFahrenheit'
                          ? 140
                          : 60
                      "
                    />
                  </div>
                </div>

                <span class="ml-2">
                  {{ entity.editHeatIndexAbove.value }} -
                  {{ entity.editHeatIndexAbove.unit?.name }}</span
                >
              </ng-container>
            </div>
          </div>
        </div>

        <div *ngIf="options?.edit || entity.hasWindChill" class="mb-3">
          <div class="d-flex justify-content-between align-items-center">
            <div class="font-weight-bold">
              WindChill Below (-40 to
              {{
                entity.editWindChillBelow.unit?.name === "DegreesFahrenheit"
                  ? 10
                  : 50
              }}
              {{
                entity.editWindChillBelow.unit?.name === "DegreesFahrenheit"
                  ? "°F"
                  : "°C"
              }})
            </div>
            <div *ngIf="options?.edit" class="ml-2">
              <label class="switch switch-3d switch-primary">
                <input
                  checked=""
                  class="switch-input switch-primary"
                  (change)="toggleOptional($event)"
                  [(ngModel)]="entity.hasWindChill"
                  type="checkbox"
                />
                <span class="switch-slider"></span>
              </label>
            </div>
            <div *ngIf="!options?.edit" class="text-muted">
              <b>{{ entity.editWindChillBelow.value }}</b
              ><span> {{ entity.editWindChillBelow.unit?.name }}</span>
            </div>
            <div *ngIf="options?.edit" class="w-50">
              <ng-container *ngIf="entity.hasWindChill">
                <div class="d-flex" style="gap:5%;">
                  <input
                    name="wind_chill"
                    type="range"
                    class="form-control-range"
                    [(ngModel)]="entity.editWindChillBelow.value"
                    (input)="
                      inputChanges($event.target.value, 'windChillBelow')
                    "
                    [min]="-40"
                    [max]="
                      entity.editWindChillBelow.unit?.name ===
                      'DegreesFahrenheit'
                        ? 10
                        : 50
                    "
                    step="1"
                  />
                  <div>
                    <input
                      name="wind_chill"
                      style="margin: 0 15px;"
                      type="number"
                      class="form-control"
                      [(ngModel)]="entity.editWindChillBelow.value"
                      (input)="
                        inputChanges($event.target.value, 'windChillBelow')
                      "
                      [min]="-40"
                      [max]="
                        entity.editWindChillBelow.unit?.name ===
                        'DegreesFahrenheit'
                          ? 10
                          : 50
                      "
                    />
                  </div>
                </div>

                <span class="ml-2">
                  {{ entity.editWindChillBelow.value }} -
                  {{ entity.editWindChillBelow.unit?.name }}</span
                >
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
})
export class EmbedAlertTemperatureFacadeComponent
  extends AlertFacadeDefaultWidgetComponent
  implements OnInit
{
  public editMode = false;
  @Input() layout: string = null;
  @Input() entity: AlertTemperatureFacadeEntity;
  @Input() options: any;
  @Input() deviceData: any = null;

  public degreeValue: boolean = false;

  @Output() widgetEvent = new EventEmitter<AlertFacadeWidgetEventStruct>();
  forwardEvent(e, widgetEvent: AlertFacadeWidgetEventStruct) {
    e.preventDefault();
    this.widgetEvent.emit(widgetEvent);
  }

  inputChanges(value: string, field: string) {
    if (!this.entity[field]) {
      this.entity[field] = { value: 0 };
    }
    this.entity[field].value = Number(value);
    this.inputChange(value, field);
  }

  toggleDegreeCelciusToFarenheit(e: any) {
    if (this.degreeValue) {
      let fahrenheit = {
        name: "DegreesFahrenheit",
        enum: UnitEnum.DegreesFahrenheit,
      };

      if (this.entity?.editHeatIndexAbove?.unit)
        Object.assign(this.entity.editHeatIndexAbove.unit, fahrenheit);

      if (this.entity?.editTemperatureAbove?.unit)
        Object.assign(this.entity.editTemperatureAbove.unit, fahrenheit);

      if (this.entity?.editTemperatureBelow?.unit)
        Object.assign(this.entity.editTemperatureBelow.unit, fahrenheit);

      if (this.entity?.editWindChillBelow?.unit)
        Object.assign(this.entity.editWindChillBelow.unit, fahrenheit);

      if (this.entity?.temperatureAbove?.unit)
        Object.assign(this.entity.temperatureAbove.unit, fahrenheit);

      if (this.entity?.temperatureBelow?.unit)
        Object.assign(this.entity.temperatureBelow.unit, fahrenheit);

      if (this.entity?.windChillBelow?.unit)
        Object.assign(this.entity.windChillBelow.unit, fahrenheit);
    } else {
      let celsius = {
        name: "DegreesCelsius",
        enum: UnitEnum.DegreesCelsius,
      };

      if (this.entity?.editHeatIndexAbove?.unit)
        Object.assign(this.entity.editHeatIndexAbove.unit, celsius);

      if (this.entity?.editTemperatureAbove?.unit)
        Object.assign(this.entity.editTemperatureAbove.unit, celsius);

      if (this.entity?.editTemperatureBelow?.unit)
        Object.assign(this.entity.editTemperatureBelow.unit, celsius);

      if (this.entity?.editWindChillBelow?.unit)
        Object.assign(this.entity.editWindChillBelow.unit, celsius);

      if (this.entity?.temperatureAbove?.unit)
        Object.assign(this.entity.temperatureAbove.unit, celsius);

      if (this.entity?.temperatureBelow?.unit)
        Object.assign(this.entity.temperatureBelow.unit, celsius);

      if (this.entity?.windChillBelow?.unit)
        Object.assign(this.entity.windChillBelow.unit, celsius);
    }
  }

  constructor(public toasterService: ToasterService) {
    super();
  }

  setDeviceValues() {
    this.entity.device = this.deviceData.deviceId;
    this.entity.deviceAssociation = this.deviceData.associationId;
  }

  ngOnInit() {
    if (
      this.deviceData.deviceId !== undefined &&
      this.deviceData.associationId !== undefined
    ) {
      this.setDeviceValues();
    }
  }
}
