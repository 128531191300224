import { Component, Input, Output, OnInit, EventEmitter } from "@angular/core";
import { ManufacturerLogicalWidget } from "../../../entities/";
import { Observable } from "rxjs";
import { LogicalWidgetEventStruct } from "./logical-widget-event.struct";
import { ManufacturerListService } from "app/services/manufacturers.service";

@Component({
  selector: "manufacturer-logical-widget",
  template: `
    <div *ngIf="!entity">
      <div class="row">
        <div class="col">
          <new-spinner [size]="'sm'"></new-spinner>
        </div>
      </div>
    </div>
    <div *ngIf="entity">
      <div *ngIf="options?.edit">
        <div *ngIf="valueSubscriber | async as values; else loading">
          <ng-select
            placeholder="Select Manufacturer"
            [items]="values"
            bindLabel="label"
            bindValue="value"
            [multiple]="false"
            [(ngModel)]="entity.selected"
            (ngModelChange)="updateOutput()"
          ></ng-select>
        </div>

        <ng-template #loading>
          <div class="row">
            <div class="col">
              <new-spinner [size]="'sm'"></new-spinner>
            </div>
          </div>
        </ng-template>
      </div>
      <div *ngIf="!options?.edit">
        {{ entity.selected | json }}
      </div>
    </div>
  `,
})
export class ManufacturerLogicalWidgetComponent implements OnInit {
  @Input() entity: ManufacturerLogicalWidget | null = null;
  @Input() templateHint: string = null;
  @Input() options: any = null;
  @Input() layout = "standard";

  @Output() widgetEvent = new EventEmitter<LogicalWidgetEventStruct>();
  forwardEvent(e, widgetEvent: LogicalWidgetEventStruct) {
    e.preventDefault();
    this.widgetEvent.emit(widgetEvent);
  }

  updateOutput() {
    let event = new LogicalWidgetEventStruct();
    event.event_widget_type = this.entity.widget_type();
    event.event_logical_widget_type = this.entity.logical_widget_type();
    event.event_type = "manufacturer_selection_made";
    event.event_body = { sref: this.entity.selected };
    this.widgetEvent.emit(event);
  }

  public valueSubscriber: any = null;
  public manufacturerSelectOptions: any = [];

  constructor(private manufacturerService: ManufacturerListService) {}

  ngOnInit() {
    this.manufacturerService.getManufacturers().then((dataValues) => {
      this.manufacturerSelectOptions = dataValues.map((option) => {
        const key = option.identifier;
        return {
          value: `ref.manufacturer.${key}`,
          label: `(${key}) ${option.name}`,
        };
      });

      this.entity.selected = this.entity.subject;

      this.valueSubscriber = new Observable<any>((observer) => {
        observer.next(this.manufacturerSelectOptions);
      });
    });
  }
}
