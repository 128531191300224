import { Inject, Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { FirebaseAuthService } from "../noizu/services/firebase-auth.service";
import { AppengineRepo } from "./appengine.repo";
import { PermissionsEntity } from "../entities/permissions.entity";

@Injectable()
export class PermissionsRepo extends AppengineRepo {
  public _kind = "permissions";
  public _singular = "permission";
  public _appengine = true;

  constructor(
    @Inject(HttpClient) client: HttpClient,
    @Inject(FirebaseAuthService) auth: FirebaseAuthService
  ) {
    super(client, auth);
  }

  entity(record) {
    return new PermissionsEntity(this.client, this.auth, record);
  }

  getGroupPermissionsPromise(id, options: any = {}) {
    this._appengine = false;
    let url = `${this.apiBase()}/group/${id}/admin-permissions`;
    return this._getEntityPromise(
      url,
      (record, resolve) => {
        let ret = record["items"] ? this.entity(record["items"][0]) : null;
        resolve(ret);
      },
      options
    );
  }
}
