import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ToasterService } from "angular2-toaster";
import { NotificationSettings } from "../../../entities/alert";
import { WidgetBase } from "../widget-base";
import { NotificationSettingsWidgetEventStruct } from "./notification-settings-widget-event.struct";

@Component({
  selector: "notification-settings-widget",
  template: `
    <div class="card" *ngIf="entity">
      <!-- Card Header -->
      <div class="card-header bg-dark text-white">
        <div class="row align-items-center">
          <div class="col">
            <h5 class="mb-0">Notification Settings</h5>
          </div>
          <div class="col-2 text-right p-0">
            <toggle-widget [options]="expand_settings"></toggle-widget>
          </div>
        </div>
      </div>

      <!-- Card Body -->
      <div class="card-body" [ngClass]="{ 'd-none': !expand_settings.toggle }">
        <!-- Push Notifications -->
        <div class="row mb-3">
          <div class="col-3 font-weight-bold">Push Notifications</div>
          <div class="col">
            <label class="switch switch-3d switch-success">
              <input
                class="switch-input switch-primary"
                (change)="togglePush($event)"
                [(ngModel)]="entity.pushEnabled"
                type="checkbox"
              />
              <span class="switch-slider"></span>
            </label>
          </div>
          <div class="col">
            <input
              type="text"
              class="form-control"
              (change)="notificationChange()"
              [(ngModel)]="entity.pushUserChannel"
              placeholder="Channel: Leave Blank for Default"
            />
          </div>
        </div>

        <!-- Email Alerts -->
        <div class="row mb-3">
          <div class="col-3 font-weight-bold">Email Alerts</div>
          <div class="col">
            <ng-container
              *ngIf="
                subscriptionTypeName === 'Plus' ||
                  subscriptionTypeName === 'Advanced' ||
                  subscriptionTypeName === 'Trial' ||
                  subscriptionTypeName === 'SMS' ||
                  subscriptionTypeName === 'Enhanced';
                else notSubscribed
              "
            >
              <label class="switch switch-3d switch-success">
                <input
                  class="switch-input switch-primary"
                  (change)="toggleEmail($event)"
                  [(ngModel)]="entity.emailEnabled"
                  type="checkbox"
                />
                <span class="switch-slider"></span>
              </label>
            </ng-container>

            <ng-template #notSubscribed>
              <div>User Not Subscribed</div>
            </ng-template>
          </div>
          <div class="col">
            <input
              type="text"
              class="form-control"
              (change)="notificationChange()"
              [(ngModel)]="entity.emailUserChannel"
              placeholder="Channel: Leave Blank for Default"
            />
          </div>
        </div>

        <!-- SMS Alerts -->
        <div class="row mb-3">
          <div class="col-3 font-weight-bold">Text / SMS Alerts</div>
          <div class="col">
            <ng-container
              *ngIf="
                subscriptionTypeName === 'Plus' ||
                  subscriptionTypeName === 'Advanced' ||
                  subscriptionTypeName === 'Trial' ||
                  subscriptionTypeName === 'SMS';
                else notSubscribed
              "
            >
              <label class="switch switch-3d switch-success">
                <input
                  class="switch-input switch-primary"
                  (change)="toggleSms($event)"
                  [(ngModel)]="entity.smsEnabled"
                  type="checkbox"
                />
                <span class="switch-slider"></span>
              </label>
            </ng-container>

            <ng-template #notSubscribed>
              <div>User Not Subscribed</div>
            </ng-template>
          </div>
          <div class="col">
            <input
              type="text"
              class="form-control"
              (change)="notificationChange()"
              [(ngModel)]="entity.smsUserChannel"
              placeholder="Channel: Leave Blank for Default"
            />
          </div>
        </div>

        <hr class="my-3" />

        <!-- Critical Alerts -->
        <div class="row mb-3">
          <div class="col-3 font-weight-bold">Set As Critical Alert</div>
          <div class="col">
            <ng-container
              *ngIf="
                subscriptionTypeName === 'Plus' ||
                  subscriptionTypeName === 'Trial';
                else notSubscribed
              "
            >
              <label class="switch switch-3d switch-success">
                <input
                  class="switch-input switch-primary"
                  (change)="toggleCriticalAlerts($event)"
                  [(ngModel)]="entity.criticalEnabled"
                  type="checkbox"
                />
                <span class="switch-slider"></span>
              </label>
            </ng-container>
            <p
              style="margin-left:-165px;font-size:13px;height:20px;"
              class="text-left mt-2"
            >
              Reserved for the most important alerts. Alerts set as critical
              will attempt to send with a more urgent priority or designation.
            </p>

            <ng-template #notSubscribed>
              <div>User Not Subscribed</div>
            </ng-template>
          </div>
          <div class="col">
            <input
              type="text"
              class="form-control"
              (change)="notificationChange()"
              [(ngModel)]="entity.smsUserChannel"
              placeholder="Channel: Leave Blank for Default"
            />
          </div>
        </div>

        <hr class="my-3" />

        <!-- Frequency Settings (Single Row) -->
        <div class="row mb-3">
          <div class="col-4 font-weight-bold">Frequency Settings</div>
          <div class="d-flex mr-2">
            <label class="switch switch-3d switch-warning">
              <input
                class="switch-input switch-primary"
                (change)="toggleFrequency('daily', $event)"
                [(ngModel)]="entity.onceDailyEnabled"
                type="checkbox"
              />
              <span class="switch-slider"></span>
            </label>
            <span style="margin-top:4px;" class="ml-2">Daily</span>
          </div>
          <div class="d-flex mr-2">
            <label class="switch switch-3d switch-warning">
              <input
                class="switch-input switch-primary"
                (change)="toggleFrequency('hourly', $event)"
                [(ngModel)]="entity.onceHourlyEnabled"
                type="checkbox"
              />
              <span class="switch-slider"></span>
            </label>
            <span style="margin-top:4px;" class="ml-2">Hourly</span>
          </div>
        </div>
      </div>
    </div>
  `,
})
export class EmbedNotificationSettingsComponent
  extends WidgetBase
  implements OnInit
{
  public editMode = false;
  @Input() layout: string = null;
  @Input() entity: NotificationSettings;
  @Input() options: any = null;
  @Input() subscriptionTypeName: any = null;

  @Output() widgetEvent =
    new EventEmitter<NotificationSettingsWidgetEventStruct>();
  forwardEvent(widgetEvent: NotificationSettingsWidgetEventStruct) {
    this.widgetEvent.emit(widgetEvent);
  }

  constructor(public toasterService: ToasterService) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.entity.pushEnabled = true;
    this.entity.onceHourlyEnabled = true;
  }

  toggleFrequency(freq, e) {
    switch (freq) {
      case "hourly":
        this.entity.onceHourlyEnabled = true;
        this.entity.onceDailyEnabled = false;
        this.entity.continuousEnabled = false;
        break;

      case "daily":
        this.entity.onceHourlyEnabled = false;
        this.entity.onceDailyEnabled = true;
        this.entity.continuousEnabled = false;
        break;

      case "continuous":
        this.entity.onceHourlyEnabled = false;
        this.entity.onceDailyEnabled = false;
        this.entity.continuousEnabled = true;
        break;

      default:
        this.entity.onceHourlyEnabled = true;
        this.entity.onceDailyEnabled = false;
        this.entity.continuousEnabled = false;
    }
    this.notificationChange();
  }

  notificationChange() {
    let f = new NotificationSettingsWidgetEventStruct();
    f.event_type = "notification_settings_change";
    f.event_body = {};
    f.event_widget_type = this.entity.widget_type();
    this.widgetEvent.emit(f);
  }
  toggleCriticalAlerts(e) {
    e.preventDefault();
    this.notificationChange();
  }
  toggleSms(e) {
    e.preventDefault();
    this.notificationChange();
  }
  toggleEmail(e) {
    e.preventDefault();
    this.notificationChange();
  }
  togglePush(e) {
    e.preventDefault();
    this.notificationChange();
  }
  toggleWebHook(e) {
    e.preventDefault();
    this.notificationChange();
  }
}
