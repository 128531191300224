import { ValueAndUnit } from "../../../structs/value-and-unit";
import { AlertFacadeEntity } from "../facade.entity";
import { WidgetEnum_Alert_Facade } from "../../../enums/widget/alert";
import { AlertFormatEnum, UnitEnum } from "../../../enums";
import { cloneDeep } from "lodash";

export class AlertTemperatureFacadeEntity extends AlertFacadeEntity {
  public temperatureAbove: ValueAndUnit;
  public temperatureBelow: ValueAndUnit;

  public hasHeatIndex: boolean;
  public heatIndexAbove: ValueAndUnit;
  public hasWindChill: boolean;
  public windChillBelow: ValueAndUnit;

  public editTemperatureAbove: ValueAndUnit;
  public editTemperatureBelow: ValueAndUnit;
  public editHeatIndexAbove: ValueAndUnit;
  public editWindChillBelow: ValueAndUnit;

  public subType: AlertFormatEnum = AlertFormatEnum.ValueBetween;

  public updateValues() {
    this.temperatureAbove = null;
    this.temperatureBelow = null;
    this.heatIndexAbove = null;
    this.windChillBelow = null;

    if (this.subType == AlertFormatEnum.ValueAbove) {
      this.temperatureAbove = cloneDeep(this.editTemperatureAbove);
    } else if (this.subType == AlertFormatEnum.ValueBelow) {
      this.temperatureBelow = cloneDeep(this.editTemperatureBelow);
    } else if (
      this.subType == AlertFormatEnum.ValueBetween ||
      this.subType == AlertFormatEnum.ValueOutside
    ) {
      this.temperatureAbove = cloneDeep(this.editTemperatureAbove);
      this.temperatureBelow = cloneDeep(this.editTemperatureBelow);
    }

    if (this.hasWindChill) {
      this.windChillBelow = cloneDeep(this.editWindChillBelow);
    }
    if (this.hasHeatIndex) {
      this.heatIndexAbove = cloneDeep(this.editHeatIndexAbove);
    }
  }

  public constructor(alert, json) {
    super(alert, json);

    this.temperatureAbove = null;
    if (json["temperatureAbove"]) {
      this.temperatureAbove = new ValueAndUnit(json["temperatureAbove"]);
    }
    this.temperatureBelow = null;
    if (json["temperatureBelow"])
      this.temperatureBelow = new ValueAndUnit(json["temperatureBelow"]);

    this.heatIndexAbove = null;
    if (json["heatIndexAbove"])
      this.heatIndexAbove = new ValueAndUnit(json["heatIndexAbove"]);

    this.windChillBelow = null;
    if (json["windChillBelow"])
      this.windChillBelow = new ValueAndUnit(json["windChillBelow"]);
    if (json["device"]) this.device = json["device"];
    if (json["deviceAssociation"])
      this.deviceAssociation = json["deviceAssociation"];

    this.hasHeatIndex = this.heatIndexAbove ? true : false;
    this.hasWindChill = this.windChillBelow ? true : false;
    this.editTemperatureAbove = this.temperatureAbove
      ? cloneDeep(this.temperatureAbove)
      : new ValueAndUnit({ value: 0.0, enum: UnitEnum.DegreesCelsius });
    this.editTemperatureBelow = this.temperatureBelow
      ? cloneDeep(this.temperatureBelow)
      : new ValueAndUnit({ value: 0.0, enum: UnitEnum.DegreesCelsius });

    this.editHeatIndexAbove = this.heatIndexAbove
      ? cloneDeep(this.heatIndexAbove)
      : new ValueAndUnit({ value: 0.0, enum: UnitEnum.DegreesCelsius });
    this.editWindChillBelow = this.windChillBelow
      ? cloneDeep(this.windChillBelow)
      : new ValueAndUnit({ value: 0.0, enum: UnitEnum.DegreesCelsius });
    this.subType = AlertFormatEnum.ValueBetween;
  }

  public get template(): string | null {
    return "temperature";
  }

  alert_facade_widget() {
    return WidgetEnum_Alert_Facade.ALERT_FACADE_WIDGET__TEMPERATURE;
  }
}
