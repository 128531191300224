import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
} from "@angular/core";
import { DeviceSet } from "../../../../entities/device";
import { DeviceWidgetEventStruct } from "../../device/device-widget-event.struct";
import { LogicalDeviceWidgetEventStruct } from "./logical-device-widget-event.struct";
import { ModalWidget } from "../../../../entities";
import { ModalWidgetEnum } from "../../../../enums";
import { ConfirmationLogicalWidget } from "../../../../entities/logical/confirmation-logical.entity";
import { BsModalService, ModalOptions } from "ngx-bootstrap/modal";

@Component({
  selector: "device-set-logical-widget",
  template: `
    <ng-template #deviceModal>
      <modal-widget
        [entity]="currentModal"
        [layout]="'shadowbox'"
        [options]="{}"
      ></modal-widget>
    </ng-template>

    <div class="device-set-logical-widget" *ngIf="!entity">
      <widget-spinner></widget-spinner>
    </div>
    <ng-container *ngIf="entity">
      <ng-container [ngSwitch]="layout">
        <!-- =========================================== -->
        <!-- Table                                       -->
        <!-- =========================================== -->
        <div class="device-set-logical-widget" *ngSwitchCase="'table'">
          <div *ngIf="!entity.set || entity.set.length == 0">
            <div class="alert alert-warning mb-0">
              <p>{{ no_results_msg() }}</p>
            </div>
          </div>
          <div *ngIf="entity.set && entity.set.length > 0">
            <div
              class="row shadow div-table-border div-table div-table-classic div-table-bg m-1"
            >
              <div class="col">
                <device-entity-widget
                  *ngFor="let device of entity.set; let i = index"
                  (widgetEvent)="handleEvent($event)"
                  [entity]="device"
                  [options]="{
                    index: i,
                    header: options['header'],
                    columns: options['columns'],
                    'allowed-actions': options['allowed-actions']
                  }"
                  [layout]="'table'"
                ></device-entity-widget>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
  `,
})
export class DeviceSetLogicalWidgetComponent implements OnInit {
  @Input() entity: DeviceSet | null = null;
  @Input() options: any = {};
  @Input() layout = "standard";
  @Output() widgetEvent = new EventEmitter<LogicalDeviceWidgetEventStruct>();

  public child_options = {};
  public defaultColumns = [
    "identifier",
    "serial-with-verification",
    "series",
    "serial",
    "type",
    "last_update",
    "actions",
  ];
  public currentModal: any;
  @ViewChild("deviceModal") modal: any;

  handleEvent(widgetEvent: DeviceWidgetEventStruct) {
    switch (widgetEvent.event_type) {
      case "permission-reset":
        this.permissionReset(widgetEvent.event_body["device"]);
        break;

      case "factory-reset":
        this.factoryReset(widgetEvent.event_body["device"]);
        break;
    }
  }

  forwardEvent(e, widgetEvent: LogicalDeviceWidgetEventStruct) {
    e.preventDefault();
    this.widgetEvent.emit(widgetEvent);
  }

  no_results_msg() {
    if (
      this.options &&
      this.options["msgs"] &&
      this.options["msgs"]["no-results-msg"]
    ) {
      return this.options["msgs"]["no-results-msg"];
    } else {
      return "No Devices";
    }
  }

  constructor(public modalService: BsModalService) {}

  changeValue(e) {}

  ngOnInit(): void {
    if (!this.options) this.options = {};
    if (!this.options["columns"]) {
      this.options["columns"] = this.defaultColumns;
    }
  }

  factoryReset(device) {
    let dialog = new ModalWidget(
      "Confirm Factory Reset",
      ModalWidgetEnum.MODAL_WIDGET__CONFIRM,
      null,
      { confirmName: "Yes, Proceed", cancelName: "No, cancel" },
      "mini",

      {
        confirm: {
          msg: "Yes, Proceed",
          cb: (self, event, modalRef, toaster) => {
            toaster.pop("info", "Factory Reset", "Attempting Factory Reset.");
            device
              .factoryReset()
              .then((result: any) => {
                if (result === true) {
                  toaster.pop(
                    "success",
                    "Factory Reset",
                    "Request Queued Successfully"
                  );
                  modalRef.hide();
                } else {
                  toaster.pop("error", "Factory Reset", "An Error Occurred");
                }
              })
              .catch((error: any) => {
                console.log(error);
                toaster.pop("error", "Factory Reset", "An Error Occurred");
              });
          },
        },
      }
    );
    dialog.title_class = "bg-danger";
    dialog.message = `This step is irreversible and will clear all permissions and remove the device from all user accounts. Are you sure you wish to factory reset ${device.serial}-${device.verificationCode}?`;
    dialog.message_class = "p";
    this.showModal(dialog, this.modal);
  }
  permissionReset(device) {
    let subject = new ConfirmationLogicalWidget();
    let dialog = new ModalWidget(
      "Confirm Permission Reset",
      ModalWidgetEnum.MODAL_WIDGET__CONFIRM,
      null,
      { confirmName: "Yes, Proceed", cancelName: "No, cancel" },
      "mini",
      {
        confirm: {
          msg: "Yes, Proceed",
          cb: (self, event, modalRef, toaster) => {
            toaster.pop("info", "Permission Reset", "Resetting Permissions.");
            device
              .permissionReset()
              .then((result: any) => {
                if (result === true) {
                  toaster.pop(
                    "success",
                    "Permission Reset",
                    "Request Queued Successfully"
                  );
                  modalRef.hide();
                } else {
                  toaster.pop("error", "Permission Reset", "An Error Occurred");
                }
              })
              .catch((error: any) => {
                console.log(error);
                toaster.pop("error", "Permission Reset", "An Error Occurred");
              });
          },
        },
      }
    );
    dialog.title_class = "bg-danger";
    dialog.message = `This step will allow anyone to claim this device without removing it from existing user accounts. Are you sure you wish to reset the permissions on ${device.serial}-${device.verificationCode}?`;
    dialog.message_class = "p";
    this.showModal(dialog, this.modal);
  }

  showModal(current, modal, clickOut = false) {
    this.currentModal = current;
    if (!clickOut) {
      let config: ModalOptions = {
        backdrop: "static",
        keyboard: false,
      };
      this.currentModal.modalRef = this.modalService.show(modal, config);
    } else {
      let config: ModalOptions = {};
      this.currentModal.modalRef = this.modalService.show(modal, config);
    }
  }
}
