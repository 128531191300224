import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { WidgetBase } from "../../widget-base";
import {
  DateTimeIntervalEntity,
  ModalWidget,
  UserSubscriptionEntity,
} from "../../../../entities";
import { UserWidgetEventStruct } from "../user-widget-event.struct";
import { SubscriptionDefinitionRepo } from "../../../../repos";
import { WidgetEventStruct } from "../../../widget-event.struct";
import { ModalWidgetEnum, WidgetEnum } from "../../../../enums";
import { BsModalRef, BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import { ToasterService } from "angular2-toaster";

@Component({
  selector: "extended-user-subscription",
  template: `
    <ng-container [ngSwitch]="layout">
      <!-- Table Layout -->
      <ng-container *ngSwitchCase="'table'">
        <div class="table-responsive">
          <table
            class="table table-hover table-striped table-bordered align-middle"
          >
            <thead class="table-dark">
              <tr>
                <th scope="col">Id</th>
                <th scope="col">Handle</th>
                <th scope="col">Type</th>
                <th scope="col">Status</th>
                <th scope="col">Source</th>
                <th scope="col">SMS Cap</th>
                <th scope="col">SMS Refresh On</th>
                <th scope="col">Start</th>
                <th scope="col">End</th>
                <th scope="col" class="text-center">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngIf="entity">
                <td>{{ entity?.identifier }}</td>
                <td>{{ entity.handle }}</td>
                <td>{{ entity.subscription_type }}</td>
                <td>
                  <span
                    class="badge bg-{{
                      entity.subscription_status === 'active'
                        ? 'success'
                        : 'danger'
                    }}"
                  >
                    {{ entity.subscription_status }}
                  </span>
                </td>
                <td>{{ entity.subscription_payment?.source || "Internal" }}</td>
                <td>
                  <span *ngIf="entity.subscription_start">
                    {{ entity.sms_cap }}
                  </span>
                </td>
                <td>
                  <span *ngIf="entity.subscription_start">
                    {{ entity.sms_cap_refresh_on | date : "mediumDate" }}
                  </span>
                </td>
                <td>
                  <span *ngIf="entity.subscription_start">
                    {{ entity.subscription_start | date : "mediumDate" }}
                  </span>
                </td>
                <td>
                  <span *ngIf="entity.subscription_end">
                    {{ entity.subscription_end | date : "mediumDate" }}
                  </span>
                </td>
                <td class="text-center">
                  <button
                    class="btn btn-sm btn-outline-danger"
                    (click)="delete($event)"
                  >
                    <i class="fas fa-trash"></i> Delete
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </ng-container>

      <!-- Default View (Card) -->
      <ng-container *ngSwitchDefault>
        <ng-container *ngIf="entity?.new">
          <div class="card mb-4 shadow-sm">
            <div class="card-header bg-primary text-white">
              <h5 class="card-title mb-0">New Subscription</h5>
            </div>
            <div class="card-body">
              <!-- Handle -->
              <div class="form-group row">
                <label class="col-sm-2 col-form-label font-weight-bold"
                  >Handle</label
                >
                <div class="col-sm-10">
                  <input
                    type="text"
                    class="form-control"
                    [(ngModel)]="entity.handle"
                    (ngModelChange)="editEntity()"
                  />
                </div>
              </div>
              <hr />

              <!-- Owner -->
              <div class="form-group row">
                <label class="col-sm-2 col-form-label font-weight-bold"
                  >Owner</label
                >
                <div class="col-sm-10">
                  <p class="form-control-static">{{ entity.owner }}</p>
                </div>
              </div>
              <hr />

              <!-- Type -->
              <div
                class="form-group row"
                *ngIf="entity.validation?.messages['subscription_type']"
              >
                <div class="col-sm-12">
                  <div class="alert alert-warning">
                    {{ entity.validation.messages.subscription_type }}
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-2 col-form-label font-weight-bold"
                  >Type</label
                >
                <div class="col-sm-10">
                  <widget
                    [entity]="typeRepo"
                    (widgetEvent)="widgetEventHandler($event)"
                    [options]="{
                      edit: options['edit'],
                      selected: entity.subscription_type
                    }"
                    [layout]="'select'"
                  ></widget>
                </div>
              </div>
              <hr />

              <!-- Status -->
              <div
                class="form-group row"
                *ngIf="entity.validation?.messages['subscription_status']"
              >
                <div class="col-sm-12">
                  <div class="alert alert-warning">
                    {{ entity.validation.messages.subscription_status }}
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-2 col-form-label font-weight-bold"
                  >Status</label
                >
                <div class="col-sm-10">
                  <ng-select
                    [name]="'subscription_status'"
                    [multiple]="false"
                    [(ngModel)]="entity.subscription_status"
                    bindLabel="text"
                    bindValue="value"
                    (ngModelChange)="setStatus($event)"
                    [items]="status_options"
                  ></ng-select>
                </div>
              </div>
              <hr />

              <!-- Period -->
              <div
                class="form-group row"
                *ngIf="entity.validation?.messages['subscription_start']"
              >
                <div class="col-sm-12">
                  <div class="alert alert-warning">
                    {{ entity.validation.messages.subscription_start }}
                  </div>
                </div>
              </div>
              <div
                class="form-group row"
                *ngIf="entity.validation?.messages['subscription_end']"
              >
                <div class="col-sm-12">
                  <div class="alert alert-warning">
                    {{ entity.validation.messages.subscription_end }}
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-2 col-form-label font-weight-bold"
                  >Period</label
                >
                <div class="col-sm-10">
                  <ng-select
                    placeholder="Select TimeSpan"
                    [items]="time_span_options"
                    [multiple]="false"
                    [(ngModel)]="time_span"
                    bindLabel="text"
                    bindValue="value"
                    (ngModelChange)="selectSpan($event)"
                  ></ng-select>
                </div>
              </div>
            </div>
          </div>
        </ng-container>

        <!-- Existing Subscription -->
        <ng-container *ngIf="!entity?.new">
          <div class="d-flex">
            <div class="card mb-4 shadow-sm" style="width: 70%;">
              <div
                class="card-header bg-primary text-white d-flex align-items-center justify-content-between"
              >
                <div class="d-flex align-items-center">
                  <h5 class="card-title mb-0 mr-2">
                    Subscription #{{ entity?.identifier }}
                  </h5>
                  <div
                    class="rounded-circle"
                    [ngStyle]="{
                      width: '35px',
                      height: '35px',
                      'background-color':
                        entity.subscription_status == 'active' ? 'green' : 'red'
                    }"
                  ></div>
                </div>

                <div *ngIf="!inAppPurchase() && options['edit']">
                  <button
                    class="btn btn-danger btn-lg"
                    (click)="delete($event)"
                  >
                    <i class="fa fa-minus-circle"></i> Delete
                  </button>
                </div>
              </div>
              <div class="card-body">
                <!-- Type -->
                <div class="form-group row">
                  <label class="col-sm-2 col-form-label font-weight-bold"
                    >Type</label
                  >
                  <div class="col-sm-10">
                    <widget
                      [entity]="typeRepo"
                      (widgetEvent)="widgetEventHandler($event)"
                      [options]="{
                        edit: !inAppPurchase() && (options['edit'] || false),
                        selected: entity.subscription_type
                      }"
                      [layout]="'select'"
                    ></widget>
                  </div>
                </div>
                <hr />

                <!-- Handle -->
                <div class="form-group row">
                  <label class="col-sm-2 col-form-label font-weight-bold"
                    >Handle</label
                  >
                  <div class="col-sm-10">
                    <input
                      *ngIf="entity && options && options['edit']"
                      type="text"
                      class="form-control"
                      [(ngModel)]="entity.handle"
                      (ngModelChange)="editEntity()"
                    />
                    <span *ngIf="!(entity && options && options['edit'])">{{
                      entity.handle
                    }}</span>
                  </div>
                </div>
                <hr />

                <!-- Owner -->
                <div class="form-group row align-items-center">
                  <label class="col-sm-2 col-form-label font-weight-bold"
                    >Owner</label
                  >
                  <div class="col-sm-10">
                    <p class="form-control-static">{{ entity.owner }}</p>
                  </div>
                </div>
                <hr />

                <!-- Status -->
                <div class="form-group row">
                  <label class="col-sm-2 col-form-label font-weight-bold"
                    >Status</label
                  >
                  <div class="col-sm-10">
                    <ng-container *ngIf="!inAppPurchase() && options['edit']">
                      <ng-select
                        [name]="'subscription_status'"
                        [multiple]="false"
                        [(ngModel)]="entity.subscription_status"
                        bindLabel="text"
                        bindValue="value"
                        (ngModelChange)="setStatus($event)"
                        [items]="status_options"
                      ></ng-select>
                    </ng-container>
                    <ng-container
                      *ngIf="!(!inAppPurchase() && options['edit'])"
                    >
                      {{ entity.subscription_status }}
                    </ng-container>
                  </div>
                </div>
                <hr />

                <!-- Payment Details -->
                <div class="form-group row" *ngIf="entity.subscription_payment">
                  <label class="col-sm-2 col-form-label font-weight-bold"
                    >Payment Details</label
                  >
                  <div class="col-sm-10">
                    <widget
                      (widgetEvent)="forwardEvent($event)"
                      [entity]="entity.subscription_payment"
                      [layout]="layout"
                      [options]="options"
                    ></widget>
                  </div>
                </div>
                <hr *ngIf="entity.subscription_payment" />

                <!-- Period -->
                <div class="form-group row">
                  <label class="col-sm-2 col-form-label font-weight-bold"
                    >Period</label
                  >
                  <div class="col-sm-10">
                    <span *ngIf="entity.subscription_start">{{
                      entity.subscription_start | date : "medium"
                    }}</span>
                    -
                    <span *ngIf="entity.subscription_end">{{
                      entity.subscription_end | date : "medium"
                    }}</span>
                    <button
                      *ngIf="!inAppPurchase() && options['edit']"
                      class="btn btn-link btn-sm"
                      (click)="editPeriod($event, pickerModal)"
                    >
                      <i class="fa fa-pencil"></i>
                    </button>
                  </div>
                </div>
                <hr />

                <!-- SMS Cap -->
                <div class="form-group row">
                  <label class="col-sm-2 col-form-label font-weight-bold"
                    >SMS Cap</label
                  >
                  <div class="col-sm-10">
                    <p class="form-control-static">
                      {{ entity.sms_cap || "Null" }}
                    </p>
                  </div>
                </div>
                <hr />

                <!-- SMS Cap Refresh On -->
                <div class="form-group row">
                  <label class="col-sm-2 col-form-label font-weight-bold"
                    >SMS Cap Refresh On</label
                  >
                  <div class="col-sm-10">
                    <p class="form-control-static">
                      {{ entity.sms_cap_refresh_on || "Null" }}
                    </p>
                  </div>
                </div>
              </div>
              <div
                class="card-footer text-center"
                *ngIf="entity?.meta?.hasChanges"
              >
                <button
                  (click)="save($event)"
                  type="button"
                  class="btn btn-primary"
                >
                  <i class="fa fa-save"></i> Update
                </button>
              </div>
            </div>
            <div class="col">
              <entity-notes-v2
                [title]="'Subscription Notes'"
                [entity]="entity.sref()"
                [options]="{ expand: true, dragula: false }"
                [placeholder]="'Subscription Note.'"
              ></entity-notes-v2>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>

    <ng-template #pickerModal>
      <modal-widget
        *ngIf="currentModal"
        (widgetEvent)="eventHandler($event, 'pop-up', pickerModal)"
        [entity]="currentModal"
        [layout]="'shadowbox'"
        [options]="{}"
      ></modal-widget>
    </ng-template>
  `,
})
export class UserExtendedSubscriptionWidgetComponent
  extends WidgetBase
  implements OnInit
{
  @Input() layout: string = null;
  @Input() entity: UserSubscriptionEntity;
  @Input() options: any;
  @Output() widgetEvent = new EventEmitter<UserWidgetEventStruct>();
  forwardEvent(e, widgetEvent: UserWidgetEventStruct) {
    e.preventDefault();
    this.widgetEvent.emit(widgetEvent);
  }
  widgetEventHandler(event: WidgetEventStruct) {
    switch (event.event_widget_type) {
      case WidgetEnum.EMBED_WIDGET__SUBSCRIPTION_DEFINITION_REPO:
        switch (event.event_type) {
          case "select":
            this.entity.subscription_type = event.event_body;
            this.entity.meta.hasChanges = true;
            break;
        }
        break;
    }
  }

  public editMode = false;
  public modalRef: BsModalRef;
  public currentModal: any = null;
  public status_options = [
    { value: "pending", text: "Pending" },
    { value: "active", text: "Active" },
    { value: "expired", text: "Expired" },
    { value: "cancelled", text: "Cancelled" },
    { value: "error", text: "Error" },
    { value: "trial", text: "Trial" },
    { value: "complimentary", text: "Complimentary" },
    { value: "refunded", text: "Refunded" },
  ];

  time_span = "year";
  time_span_options = [
    { value: "week", text: "1 Week" },
    { value: "month", text: "1 Month" },
    { value: "ninety_days", text: "90 Days" },
    { value: "year", text: "1 Year" },
    { value: "two_years", text: "2 Years" },
    { value: "three_years", text: "3 Years" },
    { value: "five_years", text: "5 years" },
  ];

  constructor(
    public typeRepo: SubscriptionDefinitionRepo,
    public modalService: BsModalService,
    public toasterService: ToasterService
  ) {
    super();
  }

  eventHandler(event, field, modal) {
    console.log("Release Unhandled Event", field, event);
  }

  inAppPurchase() {
    if (!this.entity.subscription_payment) return false;
    if (this.entity.subscription_payment.source == "ios") return true;
    if (this.entity.subscription_payment.source == "android") return true;
    return false;
  }

  setStatus(event) {
    this.entity.meta.hasChanges = true;
  }

  selectSpan() {
    switch (this.time_span) {
      case "week":
        this.entity.subscription_end = new Date(
          this.entity.subscription_start.getTime() + 1000 * 60 * 60 * 24 * 7
        );
        break;
      case "month":
        this.entity.subscription_end = new Date(
          this.entity.subscription_start.getTime() + 1000 * 60 * 60 * 24 * 32
        );
        break;
      case "ninety_days":
        this.entity.subscription_end = new Date(
          this.entity.subscription_start.getTime() + 1000 * 60 * 60 * 24 * 60
        );
        break;
      case "year":
        this.entity.subscription_end = new Date(
          this.entity.subscription_start.getTime() + 1000 * 60 * 60 * 24 * 365
        );
        break;
      case "two_years":
        this.entity.subscription_end = new Date(
          this.entity.subscription_start.getTime() +
            1000 * 60 * 60 * 24 * 365 * 2
        );
        break;
      case "three_years":
        this.entity.subscription_end = new Date(
          this.entity.subscription_start.getTime() +
            1000 * 60 * 60 * 24 * 365 * 3
        );
        break;
      case "five_years":
        this.entity.subscription_end = new Date(
          this.entity.subscription_start.getTime() +
            1000 * 60 * 60 * 24 * 365 * 5
        );
        break;
    }
    this.entity.meta.hasChanges = true;
  }

  editEntity() {
    this.entity.meta.hasChanges = true;
  }

  editPeriod(e, modal) {
    let to = this.entity.subscription_end;
    let from = this.entity.subscription_start;
    let time_range = new DateTimeIntervalEntity(from, to);
    let dialog = new ModalWidget(
      `Pull Range`,
      ModalWidgetEnum.MODAL_WIDGET__CONFIRM,
      time_range,
      { edit: true },
      "shadowbox",
      time_range.overrides()
    );
    this.showModal(dialog, modal, false, "md-modal");
    time_range.promise
      .then((r) => {
        this.entity.subscription_end = r.to;
        this.entity.subscription_start = r.from;
        this.entity.meta.hasChanges = true;
      })
      .catch(() => {
        // swallow
      });
  }

  showModal(current, modal, clickOut = false, customClass = "") {
    this.currentModal = current;
    if (!clickOut) {
      let config: ModalOptions = {
        backdrop: "static",
        keyboard: false,
        class: customClass,
      };
      this.currentModal.modalRef = this.modalService.show(modal, config);
    } else {
      let config: ModalOptions = {
        class: customClass,
      };
      this.currentModal.modalRef = this.modalService.show(modal, config);
    }
  }

  delete(e) {
    e.preventDefault();
    if (confirm("Delete Subscription Record?")) {
      this.entity
        .delete()
        .then(() => {
          this.toasterService.pop(
            "info",
            "Delete Subscription",
            `Record Removed`
          );
          let a = new UserWidgetEventStruct();
          a.event_body = this.entity;
          a.event_type = "delete";
          a.event_widget_type = WidgetEnum.EMBED_WIDGET__USER_EXTENDED;
          this.widgetEvent.emit(a);
        })
        .catch(() => {
          this.toasterService.pop(
            "warning",
            "Delete Subscription",
            `Request Failed`
          );
        });
    }
  }

  save(e) {
    e.preventDefault();
    this.entity
      .update()
      .then((r) => {
        this.toasterService.pop("success", "Success", `Subscription Updated`);
        this.entity.meta.hasChanges = false;
      })
      .catch((r) => {
        this.toasterService.pop(
          "warning",
          "Error",
          `Subscription Update Failed`
        );
      });
  }

  ngOnInit(): void {}
}
