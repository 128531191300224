
import {switchMap} from 'rxjs/operators';
import {Component} from '@angular/core';

import { BrowserModule } from '@angular/platform-browser';
import { NgModule }     from '@angular/core';
import { AppComponent } from '../../app.component';
import { AuthService } from '../../services/auth.service';
import { Router, ActivatedRoute, Params}       from '@angular/router';

@Component({
  selector: 'login',
  template: `
      <div class="app flex-row align-items-center">
          <div class="container">
              <div class="row justify-content-center">
                  <div class="col-md-8 overlayParent">
                      <div class="overlay" *ngIf="authService.redundancyPrevent == -1">
                          <widget-spinner></widget-spinner>
                      </div>
                      <div class="card-group mb-0">
                          <div class="card p-4">
                              <div class="card-block">
                                  <h1>Login</h1>
                                  <p class="text-muted">Sign In to your account</p>
                                  <div class="input-group mb-3">
                                      <span class="input-group-addon"><i class="icon-user"></i></span>
                                      <input type="text" class="form-control" [(ngModel)]="user" placeholder="Username">
                                  </div>
                                  <div class="input-group mb-4">
                                      <span class="input-group-addon"><i class="icon-lock"></i></span>
                                      <input (keyup.enter)="login($event)" type="password" class="form-control" [(ngModel)]="password"
                                             placeholder="Password">
                                  </div>
                                  <div class="row">
                                      <div class="col-6">
                                          <button type="button" [disabled]="loading" (click)="login($event)" class="btn btn-primary px-4">
                                              Login
                                          </button>
                                      </div>
                                  </div>
                              </div>
                              <div *ngIf="err" class="alert alert-danger"> {{msg}}
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>

  `,
  styles: [``]
})

export class LoginComponent {
  user: string = "";
  password: string = "";
  err: boolean = false;
  code: string = "";
  msg: string = "";
  loading:boolean = false;
  //loggedIn;
  constructor( public authService: AuthService, public router: Router, public route: ActivatedRoute,){
      //this.loggedIn = authService.isSignedIn;
  }


  login(event) {
    if( !(/(.+)@(.+){2,}\.(.+){2,}/.test(this.user)) ){
      this.msg = "Not an Email";
      this.err = true;
      return;
    }
    this.code = "";
    this.msg = "";
    this.err = false;
    this.loading=true;
    this.route.params.pipe(
    switchMap((params: Params) => this.authService.loginWithCreds(this.user,this.password)))
    .subscribe(data => this.loginProcess(data));
    return false;
  }

  loginProcess(data) {
    this.loading=false;
    if (data && data.code && data.code == "auth/wrong-password"|| data.code == "auth/user-not-found") {
      this.err = true;
      this.msg = "Invalid Credentials";
    }
    /*  //we have stopped caring about this At the moment, handeled elsewhere
    if(data.uid && data.uid.length > 5){
      this.router.navigateByUrl('portal/users/search');
    } else {
      this.code = data.code.substring(data.code.indexOf("/")+1);
      this.msg = data.msg;
      this.err = true;
    } */
  }

  logout(event) {
    this.authService.logout();
    return false;
  }
}
