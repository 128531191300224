
    <div [ngSwitch]="layout">
      <div *ngSwitchCase="'card'">
        <div class="card border-info">
          <div class="card-header bg-info m-0 p-1">
            <div class="row">
              <div class="col-9">
                {{ options.title || "Attributes" }}
              </div>
              <div class="col-3 text-right">
                <i
                  (click)="attributeExpand = !attributeExpand"
                  class="fa fa-sm pr-2"
                  [ngClass]="{
                    'fa-chevron-down': !attributeExpand,
                    'fa-chevron-up': attributeExpand
                  }"
                >
                </i>
              </div>
            </div>
          </div>
          <div class="card-body m-0 p-1" *ngIf="attributeExpand">
            <div class="row">
              <div class="col-12">
                <embed-device-attributes
                  [entity]="entity"
                  [layout]="'table'"
                  [options]="options"
                ></embed-device-attributes>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngSwitchCase="'table'">
        <div *ngIf="entity?.attributes">
          <div class="div-table-th" *ngIf="options?.header != false">
            <div class="row" *ngIf="!options?.edit">
              <div class="col-6">Attribute</div>
              <div class="col-6">Value</div>
            </div>
            <div class="row" *ngIf="options?.edit">
              <div class="col-5">Attribute</div>
              <div class="col-5">Value</div>
              <div class="col-2"><i class="fa fa-trash"></i></div>
            </div>
          </div>

          <div class="row" *ngIf="entity?.attributeList.length == 0">
            <div class="col">
              <div class="alert-info">
                No Attributes Currently Defined For This Entity.
              </div>
            </div>
          </div>
          <div
            *ngFor="let attribute of entity?.attributeList; let i = index"
            [ngClass]="{
              'div-table-tr': i % 2 == 1,
              'div-table-tr-alt': i % 2 == 0
            }"
          >
            <div class="row" *ngIf="!options?.edit">
              <div class="col-6">
                <b>{{ attribute.key }}:</b>
              </div>
              <div class="col-6">
                <span
                  [ngSwitch]="attribute.type"
                  [ngClass]="{
                    'text-danger':
                      isDevice && entity['internalAttributes'][attribute.key]
                  }"
                >
                  <span *ngSwitchCase="attributeFieldType.BooleanField">{{
                    attribute.value == 1 | json
                  }}</span>
                  <span *ngSwitchCase="attributeFieldType.ManufactureField">
                    <span *ngIf="attribute.value == 23">(Fos) 23</span>
                    <span *ngIf="attribute.value == 14">(Sino) 14</span>
                    <span *ngIf="attribute.value != 14 && attribute.value != 23"
                      >(Unknown) {{ attribute.value }}</span
                    >
                  </span>
                  <span *ngSwitchDefault>{{ attribute.value }}</span>
                </span>
              </div>
            </div>

            <div class="row" *ngIf="options?.edit">
              <div class="col-5">
                <input
                  type="text"
                  [(ngModel)]="attribute.key"
                  (change)="updateAttribute($event, attribute)"
                  size="18"
                />
              </div>
              <div [ngSwitch]="attribute.type" class="col-5">
                <span *ngSwitchDefault>
                  <input
                    type="text"
                    [(ngModel)]="attribute.value"
                    (change)="updateAttribute($event, attribute)"
                    size="18"
                  />
                </span>
              </div>
              <div
                class="col-2"
                *ngIf="
                  !isDevice ||
                  (isDevice && entity['internalAttributes'][attribute.key])
                "
              >
                <i
                  (click)="deleteAttribute($event, attribute)"
                  class="text-danger fa fa-trash"
                ></i>
              </div>
            </div>
          </div>
        </div>

        <div
          *ngIf="options?.edit"
          [ngClass]="{
            'div-table-tr': entity?.attributeList.length % 2 == 1,
            'div-table-tr-alt': entity?.attributeList.length % 2 == 0
          }"
        >
          <div class="row">
            <div class="col-12 text-right">
              <button
                (click)="showNewAttribute(attributeModal, $event)"
                class="button btn-primary"
              >
                Add Attribute
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--                                                        -->
    <!--                    Pop-Up Menu                         -->
    <!--                                                        -->
    <ng-template #attributeModal>
      <div class="modal-header">
        <h4 class="modal-title pull-left">{{ currentModal?.title }}</h4>
        <button
          type="button"
          class="close pull-right"
          aria-label="Close"
          (click)="attributeModalRef.hide()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <widget-spinner *ngIf="currentModal?.processing"></widget-spinner>
        <div class="row" *ngIf="!currentModal?.processing">
          <div class="col-6">
            <input type="text" [(ngModel)]="newAttribute.key" size="8" />
          </div>
          <div
            [ngSwitch]="entity.attributeType(newAttribute.key)"
            class="col-6"
          >
            <span *ngSwitchCase="attributeFieldType.BooleanField">
              <div class="col-6">
                <ng-select
                  [multiple]="false"
                  [(ngModel)]="newAttribute.value"
                  bindLabel="label"
                  bindValue="value"
                  [items]="booleanSelectOptions"
                ></ng-select>
              </div>
            </span>
            <span *ngSwitchCase="attributeFieldType.ManufactureField">
              <div class="col-6">
                <ng-select
                  [multiple]="false"
                  [(ngModel)]="newAttribute.value"
                  bindLabel="label"
                  bindValue="value"
                  [items]="manufacturerSelectOptions"
                ></ng-select>
              </div>
            </span>
            <span *ngSwitchDefault>
              <div class="col-6">
                <input type="text" [(ngModel)]="newAttribute.value" size="8" />
              </div>
            </span>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button
          *ngIf="currentModal?.cancelMsg"
          type="button"
          class="btn btn-secondary"
          (click)="currentModal?.cancel()"
          data-dismiss="modal"
        >
          {{ currentModal?.cancelMsg }}
        </button>
        <button
          *ngIf="currentModal?.deleteMsg"
          type="button"
          class="btn btn-warning"
          (click)="currentModal?.delete()"
          data-dismiss="modal"
        >
          {{ currentModal?.deleteMsg }}
        </button>
        <button
          *ngIf="currentModal?.confirmMsg"
          type="button"
          class="btn btn-danger"
          (click)="currentModal?.confirm()"
        >
          {{ currentModal?.confirmMsg }}
        </button>
      </div>
    </ng-template>
  