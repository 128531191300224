import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  TemplateRef,
} from "@angular/core";
import { EntityLogSet } from "../../../entities/log/entity-log.set";
import { EntityLogRepo } from "../../../repos/log/entity-log.repo";
import { ToasterService } from "angular2-toaster";
import { WidgetEventStruct } from "../../widget-event.struct";

import { GenericWidgetSetComponent } from "../generic-set-widget";
import { BsModalService } from "ngx-bootstrap/modal";

@Component({
  selector: "log-set-widget",
  template: `
    <div class="log-set-widget row">
      <div class="col">
        <div class="card p-0 m-0">
          <div class="card-header">
            <strong>Entity Logs: {{ options?.subject }}</strong
            ><span></span>
            <span *ngIf="options" class="float-right"
              ><i
                (click)="toggleExpand()"
                class="fa fa-sm"
                [ngClass]="{
                  'fa-chevron-down': !options.expand,
                  'fa-chevron-up': options.expand
                }"
              >
              </i
            ></span>
            <span *ngIf="options" class="float-right mr-2"
              ><i
                (click)="toggleEdit()"
                class="fa fa-sm fa-pencil"
                [ngClass]="{ 'text-success': options.showSettings }"
              >
              </i
            ></span>
          </div>
          <div
            class="card-body p-0 m-0"
            style="min-height:20vh;"
            [ngClass]="{ 'd-none': !options.expand }"
          >
            <div class="row">
              <div
                class="col"
                [ngClass]="{
                  'pr-0': options.showSettings || !settings?.enabled
                }"
              >
                <div
                  class="row"
                  *ngIf="!settings?.enabled"
                  (click)="toggleEdit()"
                >
                  <div class="col-12 text-center">
                    <div class="alert alert-warning mb-0">
                      Logging Not Enabled.
                    </div>
                  </div>
                </div>

                <div class="row mb-3">
                  <div class="col">
                    <new-spinner
                      *ngIf="this.fetching"
                      [size]="'md'"
                    ></new-spinner>
                  </div>
                </div>

                <ng-container *ngIf="settings?.enabled">
                  <entity-set-header-widget
                    [entity]="this"
                    (widgetEvent)="entitySetEvent($event)"
                    [layout]="layout"
                    [options]="options"
                  ></entity-set-header-widget>
                  <entity-set-list-widget
                    [entity]="this"
                    (widgetEvent)="forwardEvent($event)"
                    [layout]="layout"
                    [options]="options"
                  ></entity-set-list-widget>
                  <entity-set-footer-widget
                    [entity]="this"
                    (widgetEvent)="entitySetEvent($event)"
                    [layout]="layout"
                    [options]="options"
                  ></entity-set-footer-widget>
                </ng-container>
              </div>

              <div
                class="col-auto pl-0"
                *ngIf="options?.showSettings || !settings?.enabled"
              >
                <div class="card m-0">
                  <div class="card-header">Log Settings</div>
                  <div class="card-body p-1 m-0">
                    <new-spinner *ngIf="!settings" [size]="'md'"></new-spinner>
                    <div *ngIf="settings">
                      <div class="row">
                        <div class="col-12">
                          <div class="form-group">
                            <label for="retention_length">Max Entries</label>
                            <input
                              class="form-control"
                              id="retention_length"
                              type="number"
                              min="10"
                              max="500"
                              placeholder="100"
                              [(ngModel)]="settings.retention_length"
                            />
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-12">
                          <div class="form-group">
                            <label for="retention_period"
                              >Retention (Seconds)</label
                            >
                            <input
                              class="form-control"
                              id="retention_period"
                              type="number"
                              min="0"
                              placeholder="604800"
                              [(ngModel)]="settings.retention_period"
                            />
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-12">
                          <div class="form-group">
                            <label for="persistence">Persist Settings</label>
                            <select
                              class="form-control"
                              [(ngModel)]="settings.persist"
                            >
                              <option [value]="'none'">Disabled</option>
                              <option [value]="'settings'">Settings</option>
                              <option [value]="'history'">History</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12">
                          <div class="form-group">
                            <div class="checkbox">
                              <label>
                                <input
                                  type="checkbox"
                                  name="error_level"
                                  [(ngModel)]="settings.level.error"
                                />
                                Error
                              </label>
                            </div>
                            <div class="checkbox">
                              <label>
                                <input
                                  type="checkbox"
                                  name="warn_level"
                                  [(ngModel)]="settings.level.warn"
                                />
                                Warn
                              </label>
                            </div>
                            <div class="checkbox">
                              <label>
                                <input
                                  type="checkbox"
                                  name="info_level"
                                  [(ngModel)]="settings.level.info"
                                />
                                Info
                              </label>
                            </div>
                            <div class="checkbox">
                              <label>
                                <input
                                  type="checkbox"
                                  name="debug_level"
                                  [(ngModel)]="settings.level.debug"
                                />
                                Debug
                              </label>
                            </div>

                            <hr />

                            <div class="checkbox">
                              <label>
                                <input
                                  type="checkbox"
                                  name="trace_level"
                                  [(ngModel)]="settings.level.trace"
                                />
                                Trace
                              </label>
                            </div>
                            <div class="checkbox">
                              <label>
                                <input
                                  type="checkbox"
                                  name="life_cycle_level"
                                  [(ngModel)]="settings.level.life_cycle"
                                />
                                Life Cycle
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer p-1" *ngIf="settings">
                    <div class="row">
                      <div class="col-12">
                        <button
                          (click)="enableLogs($event)"
                          class="btn btn-sm btn-primary"
                          type="submit"
                        >
                          <i class="fa fa-save"></i>
                          <span *ngIf="settings.enabled">Update</span>
                          <span *ngIf="!settings.enabled">Enable</span>
                        </button>
                        <button
                          (click)="disableLogs($event)"
                          class="btn btn-sm btn-seconday float-right"
                          type="submit"
                        >
                          <i class="fa fa-remove"></i> Disable
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
})
export class LogSetWidgetComponent
  extends GenericWidgetSetComponent
  implements OnInit
{
  @Input() entity: EntityLogSet = null;
  @Input() options: any = {};
  @Input() layout: string = null;
  @ViewChild("popUpModal", { read: TemplateRef }) modalWizard: TemplateRef<any>;
  @Output() widgetEvent = new EventEmitter<WidgetEventStruct>();
  public settings: any = null;
  public _expand = false;

  forwardEvent(widgetEvent: WidgetEventStruct) {
    if (widgetEvent.event_type == "select_entity") {
      this.selected = widgetEvent.event_body;
    }
    this.widgetEvent.emit(widgetEvent);
  }

  constructor(
    public modalService: BsModalService,
    public repo: EntityLogRepo,
    public toasterService: ToasterService
  ) {
    super(modalService);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.repo.getSettingsPromise(this.options.subject).then((settings) => {
      this.settings = settings;
      if (this.settings.enabled) {
        this.next();
      }
    });

    if (!this.options) this.options = {};

    if (this.options) {
      if (!("showSettings" in this.options)) {
        this.options["showSettings"] = false;
      }
      if (!("expand" in this.options)) {
        this.options["expand"] = true;
      }

      this._expand = this.options.expand;

      this.options["card_margins"] = "mb-0";
      this.options["headers"] = this.options["headers"] || [
        "time",
        "level",
        "topic",
        "subtopic",
        "type",
        "subject",
        "brief",
        "actions",
      ];
    }
  }

  public next(event = null) {
    if (event) event.preventDefault();
    this.fetching = true;
    this.repo.refresh(this.options.subject).then((v: EntityLogSet) => {
      this.entity = v;
      this.filterRecords(this.filter);
      this.fetching = false;
    });
  }
  refreshLogs(event) {
    this.next(event);
  }

  enableLogs(event) {
    event.preventDefault();
    if (this.options.subject && this.settings) {
      this.settings.updating = true;
      if (this.settings.retention_length > 500) {
        this.settings.retention_length = 500;
      }
      if (this.settings.retention_length < 0) {
        this.settings.retention_length = 0;
      }
      this.repo.enable(this.options.subject, this.settings).then((data) => {
        this.toasterService.pop(
          "info",
          "Logs Settings",
          `Logs settings have been updated.`
        );

        data["settings"]["enabled"] =
          data["settings"]["level"]["error"] ||
          data["settings"]["level"]["warn"] ||
          data["settings"]["level"]["info"] ||
          data["settings"]["level"]["trace"] ||
          data["settings"]["level"]["debug"] ||
          data["settings"]["level"]["life_cycle"];

        data["settings"]["updating"] = false;
        this.settings = data["settings"];
      });
    }
  }

  disableLogs(event) {
    event.preventDefault();
    if (this.options.subject && this.settings) {
      this.settings.updating = true;
      if (this.settings.retention_length > 500) {
        this.settings.retention_length = 500;
      }
      if (this.settings.retention_length < 0) {
        this.settings.retention_length = 0;
      }
      this.repo.disable(this.options.subject, this.settings).then((data) => {
        this.toasterService.pop(
          "info",
          "Logs Settings",
          `Logs have been disabled.`
        );
        data["settings"]["enabled"] =
          data["settings"]["level"]["error"] ||
          data["settings"]["level"]["warn"] ||
          data["settings"]["level"]["info"] ||
          data["settings"]["level"]["trace"] ||
          data["settings"]["level"]["debug"] ||
          data["settings"]["level"]["life_cycle"];
        data["settings"]["updating"] = false;
        this.settings = data["settings"];
      });
    }
  }

  toggleEdit() {
    this.options.showSettings = !this.options.showSettings;
    if (this.options.showSettings) {
      this.options.expand = true;
    } else {
      this.options.expand = this._expand;
    }
  }

  toggleExpand() {
    this._expand = !this._expand;
    if (this._expand) {
      this.options.expand = true;
    } else {
      this.options.expand = false;
    }
  }
}
