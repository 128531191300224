import { Component, Input } from "@angular/core";
import { FormulaTreeNode } from "../../../../structs/formula-tree/node";

@Component({
  selector: "formula-node",
  templateUrl: "./formula-node.component.html",
})
export class FormulaNodeComponent {
  @Input() node: FormulaTreeNode = null;

  constructor() {
    // <-- pass the D3 Service into the constructor
  }
}
