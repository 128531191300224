
    <div>
      <spinner *ngIf="!entity"></spinner>
      <widget
        *ngIf="entity"
        [entity]="entity"
        [layout]="'standard'"
        [options]="options"
      ></widget>
    </div>
  