import { Injectable } from "@angular/core";

@Injectable()
export class CacheService {
  public cache = {};
  public entries = 0;
  public maxEntries = 1024;

  constructor() {}

  getCache(key: string, set: any | undefined = undefined) {
    console.log("GetCache", key);
    const now = Date();
    if (this.cache[key]) {
      if (+this.cache[key].expiration > +now) {
        console.log("GetCache - hit");
        this.cache[key].hits++;
        this.cache[key].lastAccess = now;
        return this.cache[key];
      } else {
        console.log("GetCache - expired");
        delete this.cache[key];
        this.entries--;
      }
    }
    if (set === undefined) {
      console.log("GetCache - no set");
      return null;
    } else {
      console.log("GetCache - has set");
      const v = typeof set === "function" ? set() : set;
      console.log("GetCache - has set", v);
      return this.setCache(key, v);
    }
  }

  setCache(
    key: string,
    value: any,
    expire: Date | number | undefined | null = undefined
  ) {
    console.log("SetCache", key, value, expire);
    const now = new Date();
    let expiration = now;
    if (expire === undefined || expire == null) {
      expiration.setSeconds(expiration.getSeconds() + 600);
    } else if (expire instanceof Date) {
      expiration = expire;
    } else if (Number.isInteger(expire)) {
      expiration.setSeconds(expiration.getSeconds() + expire);
    } else {
      expiration.setSeconds(expiration.getSeconds() + 600);
    }
    if (!this.cache[key]) {
      console.log("SetCache - new");
      if (this.entries > this.maxEntries) {
        this.garbageCollect();
      }
      this.entries++;
      this.cache[key] = {
        creation: now,
        expiration: expiration,
        hits: 0,
        lastAccess: now,
        value: value,
      };
    } else {
      console.log("SetCache - existing entry");
      this.cache[key] = {
        creation: now,
        expiration: expiration,
        hits: this.cache[key].hits,
        lastAccess: now,
        value: value,
      };
    }
    return this.cache[key];
  }

  garbageCollect() {
    console.log("Garbage Collect");
    const now = new Date();
    let keys = Object.keys(this.cache);

    // 1. remove expired entries.
    for (const key in keys) {
      if (+this.cache[key].expiration <= +now) {
        console.log("Garbage Collect: Expired", key);
        this.entries--;
        delete this.cache[key];
      }
    }

    if (this.entries <= this.maxEntries) {
      console.log("Garbage Collect: Early Finish");
      return;
    }

    // 2. Remove oldest entries with fewest hits.
    // - Calculate Range oldest access and most recent access.
    // - Calculate Range lowest hits and most hits.
    // - Convert each value to float [0,1], and add.
    // - remove oldest values until maxEntries met.
    keys = Object.keys(this.cache);
    let oldestAccess = null;
    let newestAccess = null;
    let fewestHits = null;
    let mostHits = null;

    // tslint:disable-next-line:forin
    for (const key in keys) {
      if (oldestAccess == null || oldestAccess > +this.cache[key].lastAccess) {
        oldestAccess = +this.cache[key].lastAccess;
      }
      if (newestAccess == null || newestAccess < +this.cache[key].lastAccess) {
        newestAccess = +this.cache[key].lastAccess;
      }
      if (fewestHits == null || fewestHits > this.cache[key].hits) {
        fewestHits = this.cache[key].hits;
      }
      if (mostHits == null || mostHits < this.cache[key].hits) {
        mostHits = this.cache[key].hits;
      }
    }

    // Calculate Weights
    let toSort = [];
    const accessDelta = newestAccess - oldestAccess;
    const hitDelta = mostHits - fewestHits;

    // tslint:disable-next-line:forin
    for (const key in keys) {
      let accessWeight = 0;
      let hitWeight = 0;
      if (accessDelta !== 0) {
        accessWeight =
          (+this.cache[key].lastAccess - oldestAccess) / accessDelta;
      }
      if (hitDelta !== 0) {
        hitWeight = (+this.cache[key].hits - fewestHits) / hitDelta;
      }
      toSort.push({ key: key, weight: hitWeight + accessWeight });
    }
    toSort = toSort.sort((a, b) => {
      return a.weight > b.weight ? 1 : a.weight > b.weight ? -1 : 0;
    });
    // tslint:disable-next-line:forin
    for (const entry in toSort) {
      console.log(
        "Garbage Collect: Low Weight",
        toSort[entry].key,
        toSort[entry].weight
      );
      delete this.cache[toSort[entry].key];
      this.entries--;
      if (this.entries <= this.maxEntries && toSort[entry].weight > 0.25) {
        console.log(
          "Garbage Collect: Finished",
          this.entries,
          toSort[entry].weight
        );
        return;
      }
    }
  }
}
