
    <page-header [section]="'admin-home'">
      <page-title
        >Admin Home:
        <b>{{ user?.last_name }}, {{ user?.first_name }}</b></page-title
      >
      <page-blurb
        >View and manage your admin user details and user settings. View your
        permission levels and manage pin groups.</page-blurb
      >
    </page-header>

    <div class="row">
      <div class="col-4">
        <div *ngIf="permissions | async as p">
          <widget [entity]="p" [options]="{}"></widget>
        </div>
        <widget [entity]="user" [options]="{}"></widget>

        <user-preferences-widget
          [entity]="user"
          [options]="{}"
        ></user-preferences-widget>
      </div>

      <div class="col-8">
        <!-- Widget ! -->
        <div class="card">
          <div class="card-header bg-primary">Pins</div>
          <div class="card-body">
            <div class="row">
              <div class="col">
                <p>Pinned Entities</p>
              </div>
            </div>
          </div>
          <div class="card-footer text-center">
            <button
              (click)="createGroup($event, pageModal)"
              class="btn btn-primary"
            >
              Create Pin Group
            </button>
          </div>
          <div *ngIf="pinGroups">
            <div class="row" *ngFor="let group of pinGroups">
              <div class="col">
                <widget
                  [entity]="group"
                  [layout]="'standard'"
                  [options]="{}"
                ></widget>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ng-template #pageModal>
      <modal-widget
        [entity]="currentModal"
        [layout]="'shadowbox'"
        [options]="{}"
      ></modal-widget>
    </ng-template>
  