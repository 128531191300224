
    <div [ngSwitch]="layout">
      <div *ngSwitchCase="'card'">
        <div class="card border-info">
          <div class="card-header bg-info m-0 p-1">
            <div class="row">
              <div class="col-9">
                {{ options.title || "StreamingPolicy" }}
              </div>
              <div class="col-3 text-right">
                <i
                  (click)="streamingPolicyExpand = !streamingPolicyExpand"
                  class="fa fa-sm pr-2"
                  [ngClass]="{
                    'fa-chevron-down': !streamingPolicyExpand,
                    'fa-chevron-up': streamingPolicyExpand
                  }"
                >
                </i>
              </div>
              <div class="col-3 text-right">
                <i
                  (click)="streamingPolicyExpand = !streamingPolicyExpand"
                  class="fa fa-sm pr-2"
                  [ngClass]="{
                    'fa-chevron-down': !streamingPolicyExpand,
                    'fa-chevron-up': streamingPolicyExpand
                  }"
                >
                </i>
              </div>
            </div>
          </div>

          <div class="card-body m-0 p-1" *ngIf="streamingPolicyExpand">
            <div class="row">
              <div class="col-12">
                <embed-device-streaming-policy
                  [entity]="entity"
                  [layout]="'table'"
                  [options]="options"
                ></embed-device-streaming-policy>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngSwitchCase="'table'">
        <div>
          <div class="div-table-th">
            <div class="row" *ngIf="!options?.edit">
              <div class="col-5">Policy</div>
              <div class="col-5">Value</div>
              <button
                style="padding: 1px; margin-left: -20px;"
                class="col-2 btn btn-info"
                (click)="resetPolicy()"
              >
                Reset
              </button>
            </div>
            <div class="row" *ngIf="options?.edit">
              <div class="col-5">Policy</div>
              <div class="col-5">Value</div>
              <div class="col-2"><i class="fa fa-trash"></i></div>
            </div>
          </div>

          <div class="row" *ngIf="entity?.policyList.length === 0">
            <div class="col">
              <div class="alert-info">
                <widget-spinner></widget-spinner>
              </div>
            </div>
          </div>

          <div
            *ngFor="let pol of entity?.policyList; let i = index"
            [ngClass]="{
              'div-table-tr': i % 2 == 1,
              'div-table-tr-alt': i % 2 == 0
            }"
          >
            <div class="row" *ngIf="!options?.edit">
              <div class="col-5">
                <b>{{ pol.key }}:</b>
              </div>
              <div class="col-5">
                <span>{{ pol.value }}</span>
              </div>
            </div>

            <div class="row" *ngIf="options?.edit">
              <div class="col-5">
                <input
                  type="text"
                  [(ngModel)]="pol.key"
                  (change)="updatePolicy($event, pol)"
                  size="18"
                />
              </div>
              <div [ngSwitch]="pol.type" class="col-5">
                <span *ngSwitchDefault>
                  <input
                    type="text"
                    [(ngModel)]="pol.value"
                    (change)="updatePolicy($event, pol)"
                    size="18"
                  />
                </span>
              </div>
            </div>
          </div>

          <div
            *ngIf="options?.edit"
            [ngClass]="{
              'div-table-tr': entity?.policyList.length % 2 == 1,
              'div-table-tr-alt': entity?.policyList.length % 2 == 0
            }"
          ></div>
        </div>
      </div>

      <ng-template #policyModal>
        <div class="modal-header">
          <h4 class="modal-title pull-left">{{ currentModal?.title }}</h4>
          <button
            type="button"
            class="close pull-right"
            aria-label="Close"
            (click)="policyModalRef.hide()"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <widget-spinner *ngIf="currentModal?.processing"></widget-spinner>
          <div class="row" *ngIf="!currentModal?.processing">
            <span>
              <div class="col-5">
                <input type="text" [(ngModel)]="newPolicy.key" size="18" />
              </div>
            </span>
            <span>
              <div class="col-5">
                <input type="text" [(ngModel)]="newPolicy.value" size="18" />
              </div>
            </span>
          </div>
        </div>
        <div class="modal-footer">
          <button
            *ngIf="currentModal?.cancelMsg"
            type="button"
            class="btn btn-secondary"
            (click)="currentModal?.cancel()"
            data-dismiss="modal"
          >
            {{ currentModal?.cancelMsg }}
          </button>
          <button
            *ngIf="currentModal?.deleteMsg"
            type="button"
            class="btn btn-warning"
            (click)="currentModal?.delete()"
            data-dismiss="modal"
          >
            {{ currentModal?.deleteMsg }}
          </button>
          <button
            *ngIf="currentModal?.confirmMsg"
            type="button"
            class="btn btn-danger"
            (click)="currentModal?.confirm()"
          >
            {{ currentModal?.confirmMsg }}
          </button>
        </div>
      </ng-template>
    </div>
  