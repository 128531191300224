import { Inject, Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { FirebaseAuthService } from "../noizu/services/firebase-auth.service";
import { AppengineRepo } from "./appengine.repo";

@Injectable()
export class DataStreamRepo extends AppengineRepo {
  _appengine = false;

  constructor(
    @Inject(HttpClient) client: HttpClient,
    @Inject(FirebaseAuthService) auth: FirebaseAuthService
  ) {
    super(client, auth);
  }

  getdataStream(id) {
    return this._getListPromise(
      `${this.ingvEndpoint()}/displays/${id}/data-stream`,
      (record) => {
        return record;
      },
      {}
    );
  }

  saveStream(id, stream, isNew) {
    stream["enabled?"] = stream["enabled"];
    stream["enabled"] = null;
    if (isNew) {
      return this._post(
        `${this.ingvEndpoint()}/displays/${id}/data-stream`,
        stream,
        (record, resolve) => {
          resolve(record);
          return record;
        },
        {}
      );
    } else {
      return this._put(
        `${this.ingvEndpoint()}/displays/${id}/data-stream/${
          stream.identifier
        }`,
        stream,
        (record, resolve) => {
          resolve(record);
          return record;
        },
        {}
      );
    }
  }

  deleteStream(did, id) {
    return this._delete(
      `${this.ingvEndpoint()}/displays/${did}/data-stream/${id}`,
      (record, resolve) => {
        resolve(record);
        return record;
      },
      {}
    );
  }
}
