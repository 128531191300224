import { HttpClient } from "@angular/common/http";
import { LacrosseEntity } from "../lacrosse.entity";
import { FirebaseAuthService } from "../../noizu/services/firebase-auth.service";
import { FirmwareWidgetEnum, WidgetEnum } from "../../enums";
import { AttributeFieldType } from "../device";

export class FirmwareDefinitionEntity extends LacrosseEntity {
  public _kind = "firmware-definitions";
  public _singular = "firmware-definition";
  public notes: String;
  public firmwareVersion: String;
  public checksum: String;
  public size: Number;
  public fileA: String;
  public fileB: String;
  public file: String;
  public series: String;
  public manufacturerId: number;
  public _sref_module = "firmware";
  public pending = false;
  public attributes: Object = null;
  public _attributeList: any = null;
  public mid: string;
  public security_certificate: string;

  constructor(client: HttpClient, auth: FirebaseAuthService, json) {
    super(client, auth, json);
    this.strip_from_json["pending"] = 1;
    this.strip_from_json["_attributeList"] = 1;
    this.strip_from_json["attributeList"] = 1;
    this.refresh(json);
  }

  refresh(data) {
    this.checksum = data.checksum;
    this.firmwareVersion = data.firmwareVersion;
    this.size = data.size;
    this.fileA = data.fileA;
    this.fileB = data.fileB;
    this.file = data.file;
    this.series = data.series;
    this.manufacturerId = parseInt(data.manufacturerId);
    this.notes = data.notes;
    this.pending = false;
    this.security_certificate = data.security_certificate;

    this.attributes = {};
    if (data["attributes"]) {
      for (const key in data["attributes"]) {
        let value = data["attributes"][key];
        this.attributes[key] = parseInt(value);
      }
    }

    return super.refresh(data);
  }

  public attributeType(key) {
    return AttributeFieldType.NumericField;
  }

  updateAttribute(attribute) {
    this.meta.hasChanges = true;
    if (attribute.originalKey != attribute.key) {
      delete this.attributes[attribute.originalKey];
    }

    this.attributes[attribute.key] = attribute.value;
    this.attributeList = null;
  }

  addAttribute(attribute) {
    this.meta.hasChanges = true;
    this.attributes[attribute.key] = attribute.value;
    this.attributeList = null;
  }

  dropAttribute(attribute) {
    this.meta.hasChanges = true;
    delete this.attributes[attribute.key];
    this.attributeList = null;
  }

  set attributeList(v) {
    this._attributeList = v;
  }

  get attributeList() {
    if (this._attributeList) return this._attributeList;
    this._attributeList = [];
    if (this.attributes) {
      for (const key in this.attributes) {
        let value = this.attributes[key];
        this._attributeList.push({
          key: key,
          originalKey: key,
          value: value,
          type: this.attributeType(key),
        });
      }
      this._attributeList = this._attributeList.sort((a, b) => a.key < b.key);
    }
    return this._attributeList;
  }

  widget_type() {
    return WidgetEnum.EMBED_WIDGET__FIRMWARE;
  }

  firmware_widget_type() {
    return FirmwareWidgetEnum.FIRMWARE_WIDGET__DEFINITION;
  }
}
