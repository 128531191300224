import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { WidgetBase } from "../widget-base";
import { WidgetEventStruct } from "../../widget-event.struct";
import { GenericWidgetSetComponent } from "../generic-set-widget";
import { UserRepo } from "app/repos";
import { UserEntity } from "app/entities";

@Component({
  selector: "entity-set-list-widget",
  template: `
    <ng-container [ngSwitch]="layout">
      <ng-container *ngSwitchCase="'table'">
        <ng-container *ngIf="entity.has_entries">
          <div class="row">
            <div class="col ml-3 mr-3">
              <ng-container *ngFor="let e of entity.filteredSet; let i = index">
                <div
                  class="row"
                  *ngIf="
                    i >= entity.page * entity.dpp &&
                    i < (entity.page + 1) * entity.dpp
                  "
                >
                  <div class="col">
                    <widget
                      *ngIf="user"
                      [user]="user"
                      (widgetEvent)="forwardEvent($event)"
                      [entity]="e"
                      [options]="{
                        nested: options['nested'],
                        id: e.identifier,
                        columns: options['headers'],
                        edit: options['edit'],
                        index: i,
                        header: i == entity.page * entity.dpp
                      }"
                      [layout]="layout"
                    ></widget>
                  </div>
                </div>
              </ng-container>
            </div>

            <div
              [ngClass]="{
                'col-6': options?.sideBarWidth == 6,
                'col-5': options?.sideBarWidth == 5,
                'col-4': options?.sideBarWidth == 4,
                'col-3': options?.sideBarWidth == 3,
                'col-3': !options?.sideBarWidth
              }"
              class="col-3"
              *ngIf="entity.selected"
            >
              <widget
                *ngIf="user"
                [user]="user"
                (widgetEvent)="forwardEvent($event)"
                [entity]="entity.selected"
                [options]="{
                  nested: options['nested'],
                  edit: options['edit'],
                  expand_on_load: true
                }"
                [layout]="'sidebar'"
              ></widget>
            </div>
          </div>
        </ng-container>
      </ng-container>

      <ng-container *ngSwitchDefault>
        <div *ngIf="entity.has_entries">
          <div class="row">
            <ng-container [ngSwitch]="entity.columns">
              <ng-container *ngSwitchCase="'1'">
                <ng-container
                  *ngFor="let e of entity.filteredSet; let i = index"
                >
                  <div
                    class="{{ card_margins }} col-12"
                    *ngIf="
                      i >= entity.page * entity.dpp &&
                      i < (entity.page + 1) * entity.dpp
                    "
                  >
                    <widget
                      *ngIf="user"
                      [user]="user"
                      (widgetEvent)="forwardEvent($event)"
                      [entity]="e"
                      [options]="{
                        nested: options['nested'],
                        id: e.identifier,
                        edit: options['edit'],
                        expand_on_load: true
                      }"
                      [layout]="layout"
                    ></widget>
                  </div>
                </ng-container>
              </ng-container>

              <ng-container *ngSwitchCase="'2'">
                <ng-container
                  *ngFor="let e of entity.filteredSet; let i = index"
                >
                  <div
                    class="{{ card_margins }} col-lg-6 col-12"
                    *ngIf="
                      i >= entity.page * entity.dpp &&
                      i < (entity.page + 1) * entity.dpp
                    "
                  >
                    <widget
                      *ngIf="user"
                      [user]="user"
                      (widgetEvent)="forwardEvent($event)"
                      [entity]="e"
                      [options]="{
                        nested: options['nested'],
                        id: e.identifier,
                        edit: options['edit'],
                        expand_on_load: true
                      }"
                      [layout]="layout"
                    ></widget>
                  </div>
                </ng-container>
              </ng-container>

              <ng-container *ngSwitchCase="'3'">
                <ng-container
                  *ngFor="let e of entity.filteredSet; let i = index"
                >
                  <div
                    class="{{ card_margins }} col-lg-4 col-12"
                    *ngIf="
                      i >= entity.page * entity.dpp &&
                      i < (entity.page + 1) * entity.dpp
                    "
                  >
                    <widget
                      *ngIf="user"
                      [user]="user"
                      (widgetEvent)="forwardEvent($event)"
                      [entity]="e"
                      [options]="{
                        nested: options['nested'],
                        id: e.identifier,
                        edit: options['edit'],
                        expand_on_load: true
                      }"
                      [layout]="layout"
                    ></widget>
                  </div>
                </ng-container>
              </ng-container>

              <ng-container *ngSwitchCase="'4'">
                <ng-container
                  *ngFor="let e of entity.filteredSet; let i = index"
                >
                  <div
                    class="{{ card_margins }} col-lg-3 col-12"
                    *ngIf="
                      i >= entity.page * entity.dpp &&
                      i < (entity.page + 1) * entity.dpp
                    "
                  >
                    <widget
                      *ngIf="user"
                      [user]="user"
                      (widgetEvent)="forwardEvent($event)"
                      [entity]="e"
                      [options]="{
                        nested: options['nested'],
                        id: e.identifier,
                        edit: options['edit'],
                        expand_on_load: true
                      }"
                      [layout]="layout"
                    ></widget>
                  </div>
                </ng-container>
              </ng-container>

              <ng-container *ngSwitchCase="'6'">
                <ng-container
                  *ngFor="let e of entity.filteredSet; let i = index"
                >
                  <div
                    class="{{ card_margins }} col-lg-2 col-12"
                    *ngIf="
                      i >= entity.page * entity.dpp &&
                      i < (entity.page + 1) * entity.dpp
                    "
                  >
                    <widget
                      *ngIf="user"
                      [user]="user"
                      (widgetEvent)="forwardEvent($event)"
                      [entity]="e"
                      [options]="{
                        nested: options['nested'],
                        id: e.identifier,
                        edit: options['edit'],
                        expand_on_load: true
                      }"
                      [layout]="layout"
                    ></widget>
                  </div>
                </ng-container>
              </ng-container>

              <ng-container *ngSwitchCase="'12'">
                <ng-container
                  *ngFor="let e of entity.filteredSet; let i = index"
                >
                  <div
                    class="{{ card_margins }} col-lg-1 col-12"
                    *ngIf="
                      i >= entity.page * entity.dpp &&
                      i < (entity.page + 1) * entity.dpp
                    "
                  >
                    <widget
                      *ngIf="user"
                      [user]="user"
                      (widgetEvent)="forwardEvent($event)"
                      [entity]="e"
                      [options]="{
                        nested: options['nested'],
                        id: e.identifier,
                        edit: options['edit'],
                        expand_on_load: true
                      }"
                      [layout]="layout"
                    ></widget>
                  </div>
                </ng-container>
              </ng-container>

              <ng-container *ngSwitchDefault>
                <ng-container
                  *ngFor="let e of entity.filteredSet; let i = index"
                >
                  <div
                    class="{{ card_margins }} col-lg-6 col-12"
                    *ngIf="
                      i >= entity.page * entity.dpp &&
                      i < (entity.page + 1) * entity.dpp
                    "
                  >
                    <widget
                      *ngIf="user"
                      [user]="user"
                      (widgetEvent)="forwardEvent($event)"
                      [entity]="e"
                      [options]="{
                        nested: options['nested'],
                        id: e.identifier,
                        edit: options['edit'],
                        expand_on_load: true
                      }"
                      [layout]="layout"
                    ></widget>
                  </div>
                </ng-container>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </ng-container>
  `,
})
export class EntitySetListWidgetComponent extends WidgetBase implements OnInit {
  public editMode = false;
  @Input() layout: string = null;
  @Input() entity: GenericWidgetSetComponent;
  @Input() options: any;
  @Output() widgetEvent = new EventEmitter<WidgetEventStruct>();
  forwardEvent(widgetEvent: WidgetEventStruct) {
    this.widgetEvent.emit(widgetEvent);
  }

  public card_margins = "";

  public userId;
  public user: any;

  constructor(public userRepo: UserRepo) {
    super();
  }

  ngOnInit(): void {
    this.card_margins = this.options["card_margins"] || "mb-4";
    this.userId = this.entity?.entity?.repo?.auth?._fba?.auth?.O;
    if (this.userId) {
      this.userRepo.getEntityPromise(this.userId).then((user: UserEntity) => {
        this.user = user;
        this.user.getUserSubcriptionInfo().then((data: any) => {
          this.user.subscriptionTypeName = data;
        });

        this.user.getDeviceAssociationsPromise().then((devices: any) => {
          this.user.deviceAssociationPairs = devices.items.map((device) => {
            device.deviceCategory = device.raw.sensor.type.category;
            device.deviceId = device.sensor_identifier;
            device.deviceName = device.device_name;
            device.associationId = device.identifier;
            return device;
          });
        });
      });
    }
  }
}
