import { ValueAndUnit } from "../../../structs/value-and-unit";
import { AlertFacadeEntity } from "../facade.entity";
import { WidgetEnum_Alert_Facade } from "../../../enums/widget/alert";
import { cloneDeep } from "lodash";
import { UnitEnum } from "app/enums";

export class AlertWindFacadeEntity extends AlertFacadeEntity {
  public windAbove: ValueAndUnit;
  public windLevel: any;
  public editWindLevel: any;
  public headingOne: any;
  public headingTwo: any;
  public headingThree: any;
  public headingFour: any;
  public headingFive: any;
  public headingSix: any;
  public headingSeven: any;
  public headingEight: any;

  public constructor(alert, json) {
    super(alert, json);
    this.windAbove = null;
    if (json["windAbove"]) {
      this.windAbove = new ValueAndUnit(json["windAbove"]);
    }
    if (this.windAbove == null) {
      this.windAbove = new ValueAndUnit({
        value: 0.0,
        enum: UnitEnum.MilesPerHour,
      });
    }
    this.windLevel = json.windLevel || null;
    this.editWindLevel = this.windLevel ? this.windLevel : 0;
    this.headingOne = json["headingOne"] || false;
    this.headingTwo = json["headingTwo"] || false;
    this.headingThree = json["headingThree"] || false;
    this.headingFour = json["headingFour"] || false;
    this.headingFive = json["headingFive"] || false;
    this.headingSix = json["headingSix"] || false;
    this.headingSeven = json["headingSeven"] || false;
    this.headingEight = json["headingEight"] || false;
  }

  public get template(): string | null {
    return "wind";
  }

  alert_facade_widget() {
    return WidgetEnum_Alert_Facade.ALERT_FACADE_WIDGET__WIND;
  }
}
