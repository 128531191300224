
    <div class="device-simulator-widget" *ngIf="!entity">
      <widget-spinner></widget-spinner>
    </div>
    <ng-container *ngIf="entity">
      <div class="device-simulator-widget card m-0">
        <div class="card-header bg-dark text-center" style="z-index: 600;">
          <span class="font-xl">Device Simulator</span>
          <span class=" float-right pt-1"
            ><i
              (click)="showSimulatorExtension($event)"
              class="font-xl fa fa-wrench"
            ></i
          ></span>
        </div>

        <ng-container
          *ngIf="showExtended"
          class="device-simulator-widget-extension"
        >
          <div
            class="bg-gray-500 mr-4 align-middle table-align-middle"
            style="width: 100%; opacity: 0.9; height: 100%; position: absolute; z-index: 500; display:flex; align-items: center; justify-content: center; "
          >
            <div class="row my-auto" style="width: 100%">
              <div class="col-2"></div>
              <div class="col">
                <div class="card">
                  <div class="card-header">
                    <div class="row">
                      <div class="col">Extended Gateway Simulator</div>
                      <div class="col-auto">
                        <span>Direct</span>
                        <label class="switch switch-3d switch-info ml-1">
                          <input
                            checked=""
                            class="switch-input"
                            [(ngModel)]="extendedGatewayDirect"
                            type="checkbox"
                          />
                          <span class="switch-slider"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="card-body">
                    <div class="row mb-2">
                      <div class="col-xl-4 col-6  text-right">
                        <b>Gateway:</b>
                      </div>
                      <div class="col text-left">
                        <input
                          type="text"
                          [(ngModel)]="displaySerial"
                          (ngModelChange)="changeDisplay($event)"
                          name="device"
                          size="10"
                        />
                        @ {{ displaySeries }}
                      </div>
                    </div>
                    <div *ngIf="extendedGatewayDirect" class="row mb-2">
                      <div class="col-xl-4 col-6  text-right"><b>PSK:</b></div>
                      <div class="col text-left">
                        <input
                          type="text"
                          [(ngModel)]="displaySecret"
                          name="secret"
                          size="10"
                        />
                      </div>
                    </div>

                    <div class="row">
                      <div class="col">
                        <div
                          class="container mt-3"
                          style="display: flex; align-items: center; justify-content: center"
                        >
                          <button
                            class="btn btn-primary"
                            (click)="gatewayFosGeo($event)"
                          >
                            FOS-GEO
                          </button>
                          <button
                            class="btn btn-primary"
                            (click)="gatewayGeo($event)"
                          >
                            GEO
                          </button>
                          <button
                            class="btn btn-primary"
                            (click)="gatewayNotification($event)"
                          >
                            Notifications
                          </button>
                          <button
                            class="btn btn-primary"
                            (click)="gatewayGetAlarm($event)"
                          >
                            Alarms
                          </button>
                        </div>
                      </div>
                    </div>

                    <ng-container>
                      <div class="row">
                        <div class="col">
                          <div class="alert alert-info">Details</div>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </div>
              <div class="col-2"></div>
            </div>
          </div>
        </ng-container>
        <div class="card-body">
          <div class="row">
            <div class="col-5">
              <div class="row mb-2">
                <div class="col-xl-4 col-6 text-right"><b>Sensor:</b></div>
                <div class="col text-left">
                  <input
                    type="text"
                    [(ngModel)]="deviceSerial"
                    (ngModelChange)="changeDevice($event)"
                    name="device"
                    size="10"
                  />
                </div>
              </div>
              <div class="row mb-2">
                <div class="col-xl-4 col-6  text-right"><b>Gateway:</b></div>
                <div class="col text-left">
                  <input
                    type="text"
                    [(ngModel)]="displaySerial"
                    (ngModelChange)="changeDisplay($event)"
                    name="device"
                    size="10"
                  />
                  @ {{ displaySeries }}
                </div>
              </div>
              <div class="row mb-2">
                <div class="col-xl-4 col-6  text-right"><b>Firmware:</b></div>
                <div class="col text-left">
                  <input
                    type="text"
                    [(ngModel)]="firmware"
                    name="device"
                    size="10"
                  />
                </div>
              </div>
            </div>
            <div class="col">
              <div *ngIf="deviceReady">
                <div class="row mb-2">
                  <div class="col-xl-4 col-6  text-right">
                    <b>Battery Low:</b>
                  </div>
                  <div class="col text-left ">
                    <label class="switch switch-3d switch-danger">
                      <input
                        checked=""
                        class="switch-input"
                        [(ngModel)]="batteryLow"
                        type="checkbox"
                      />
                      <span class="switch-slider"></span>
                    </label>
                  </div>
                </div>

                <div class="row mb-2">
                  <div class="col-xl-4 col-6  text-right">
                    <b>On Display:</b>
                  </div>
                  <div class="col text-left ">
                    <label class="switch switch-3d switch-primary">
                      <input
                        checked=""
                        class="switch-input"
                        [(ngModel)]="onDisplay"
                        type="checkbox"
                      />
                      <span class="switch-slider"></span>
                    </label>
                  </div>
                </div>

                <div class="row mb-2">
                  <div class="col-xl-4 col-6  text-right"><b>Event:</b></div>
                  <div class="col  text-left">
                    <span class="font-xl">
                      <span class="mr-4">{{ event }}</span>
                      <button
                        class="btn btn-dark btn-pill mr-2"
                        (click)="incrementEvent($event)"
                      >
                        +
                      </button>
                      <button
                        class="btn btn-light btn-pill"
                        (click)="decrementEvent($event)"
                      >
                        -
                      </button>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row b-t-1 pt-4" *ngIf="deviceReady">
            <div class="col">
              <div class="row mb-2">
                <div class="col-xl-4 col-6  text-right">
                  <b>Mark As Simulated:</b>
                </div>
                <div class="col text-left">
                  <label class="switch switch-3d switch-success">
                    <input
                      checked=""
                      class="switch-input"
                      [(ngModel)]="tagSimulation"
                      type="checkbox"
                    />
                    <span class="switch-slider"></span>
                  </label>
                </div>
              </div>

              <div class="row mb-2">
                <div class="col-xl-4 col-6  text-right"><b>Force Tx:</b></div>
                <div class="col text-left ">
                  <label class="switch switch-3d switch-danger">
                    <input
                      checked=""
                      class="switch-input"
                      [(ngModel)]="transaction"
                      type="checkbox"
                    />
                    <span class="switch-slider"></span>
                  </label>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="row mb-2">
                <div class="col-xl-4 col-6  text-right"><b>Channel:</b></div>
                <div class="col text-left ">
                  <label class="switch switch-3d switch-danger">
                    <input
                      checked=""
                      class="switch-input"
                      [(ngModel)]="channel"
                      type="checkbox"
                    />
                    <span class="switch-slider"></span>
                  </label>
                </div>
              </div>

              <div class="row mb-2">
                <div class="col-xl-4 col-6  text-right">
                  <b>PassThruBit:</b>
                </div>
                <div class="col text-left ">
                  <label class="switch switch-3d switch-danger">
                    <input
                      checked=""
                      class="switch-input"
                      [(ngModel)]="ptb"
                      type="checkbox"
                    />
                    <span class="switch-slider"></span>
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="!deviceReady">
            <div class="row">
              <div class="col">
                <new-spinner [size]="'md'"></new-spinner>
              </div>
            </div>
          </div>
          <div *ngIf="deviceReady">
            <hr />
            <div class="row mt-2" *ngIf="fields">
              <div class="col-xl-3 col-6  text-right"><b>Fields</b></div>
              <div class="col">
                <div class="row" *ngFor="let field of fields">
                  <div class="col">
                    <device-field-simulator-widget
                      [entity]="field"
                      [layout]="'row'"
                      [options]="{ identifier: field.identifier, edit: true }"
                    ></device-field-simulator-widget>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col text-right">
                <button class="btn btn-primary" (click)="binaryReport($event)">
                  Send
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  