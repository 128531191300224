import { Component, Input, Output, EventEmitter } from "@angular/core";
import { WidgetBase } from "../widget-base";
import { AlertWidgetEventStruct } from "./alert-widget-event.struct";
import { AlertTriggerEventSet } from "app/entities/alert";

@Component({
  selector: "alert-trigger-event-set-widget",
  template: `
    <set-widget
      [entity]="this.entity"
      [layout]="this.layout"
      [options]="options"
      [title]="'Trigger Events'"
    ></set-widget>
  `,
})
export class AlertTriggerEventSetWidgetComponent extends WidgetBase {
  @Input() entity: AlertTriggerEventSet = null;
  @Input() options: any = null;
  @Input() layout: string = null;
  @Output() widgetEvent = new EventEmitter<AlertWidgetEventStruct>();
  forwardEvent(e, widgetEvent: AlertWidgetEventStruct) {
    e.preventDefault();
    this.widgetEvent.emit(widgetEvent);
  }

  public has_entries = false;
  constructor() {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.has_entries =
      Array.isArray(this.entity.entities) && this.entity.entities.length === 0;
  }
}
