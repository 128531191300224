
    <ng-container *ngIf="!entity">
      <div class="row">
        <div class="col">
          <new-spinner [size]="'sm'"></new-spinner>
        </div>
      </div>
    </ng-container>
    <ng-container class="alert-facade-selector-logical-widget" *ngIf="entity">
      <div *ngIf="options?.edit">
        <div *ngIf="valueSubscriber | async as values; else loading">
          <ng-select
            placeholder="Alert Template"
            [items]="values"
            bindLabel="label"
            bindValue="value"
            [multiple]="false"
            [(ngModel)]="entity.selected"
            (ngModelChange)="updateOutput()"
          ></ng-select>
        </div>

        <ng-template #loading>
          <div class="row">
            <div class="col">
              <new-spinner [size]="'sm'"></new-spinner>
            </div>
          </div>
        </ng-template>
      </div>
      <div *ngIf="!options?.edit" class="align-middle text-center">
        {{ facade_types[entity.selected] }}
      </div>
    </ng-container>
  