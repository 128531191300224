import { Component, NgZone } from "@angular/core";

@Component({
  templateUrl: "./synch-queue.component.html",
})
export class DataManagementWipfliSynchQueueComponent {
  public entries: Array<any>;

  constructor(/*public repo: DeviceDefinitionRepo,*/ public zone: NgZone) {
    this.zone.run(() => {
      //this.repo.getListPromise().then((u: any) => {this.entries = u;});
    });
  }
}
