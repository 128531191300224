<form action="" class="form-horizontal">
  <div class="col" *ngIf="!deleted">
    <!-- View Mode -->
    <div
      class="card shadow-lg"
      style="width: 22rem"
      *ngIf="!(entry.new || editEntry)"
    >
      <div class="card-header bg-info text-white font-weight-bold">
        {{ entry.name }} (ID: {{ entry.identifier }})
      </div>
      <div class="card-body bg-light">
        <p class="card-text"><strong>VSN:</strong> {{ entry.vsn || "N/A" }}</p>
        <p class="card-text">
          <strong>ingv New Version:</strong> {{ entry._ingv_new_version }}
        </p>
        <p class="card-text">
          <strong>Last Synched:</strong>
          {{ entry.last_synched || "Not Synched" }}
        </p>
        <p class="card-text">
          <strong>Created On:</strong>
          {{ entry.createdOn | date : "MMM d, y h:mm a" }}
        </p>
        <p class="card-text">
          <strong>Modified On:</strong>
          {{ entry.modifiedOn | date : "MMM d, y h:mm a" }}
        </p>
        <p class="card-text">
          <strong>Flagged for Sync:</strong>
          <span
            [class.text-success]="entry.flagged_for_synch"
            [class.text-danger]="!entry.flagged_for_synch"
          >
            {{ entry.flagged_for_synch ? "Yes" : "No" }}
          </span>
        </p>
        <p class="card-text">
          <strong>Flagged for Next Sync:</strong>
          <span
            [class.text-success]="entry.flagged_for_synch_vnext"
            [class.text-danger]="!entry.flagged_for_synch_vnext"
          >
            {{ entry.flagged_for_synch_vnext ? "Yes" : "No" }}
          </span>
        </p>
      </div>
      <div class="card-footer">
        <button (click)="edit($event, entry)" class="btn btn-sm btn-primary">
          <i class="fa fa-pencil"></i> Edit
        </button>
      </div>
    </div>

    <!-- Edit / Add Mode -->
    <div *ngIf="entry.new || editEntry" class="card shadow-lg">
      <div class="card-header bg-info text-white font-weight-bold">
        Manufacturer Entry
      </div>
      <div class="card-body bg-light">
        <!-- Identifier -->
        <div class="form-group">
          <label for="identifier">ID</label>
          <input
            class="form-control"
            required
            [(ngModel)]="entry.identifier"
            id="identifier"
            name="identifier"
            placeholder="Manufacturer ID"
            type="text"
          />
        </div>

        <!-- Name -->
        <div class="form-group">
          <label for="manufacturer-name">Name</label>
          <input
            class="form-control"
            required
            [(ngModel)]="entry.name"
            id="manufacturer-name"
            name="manufacturer-name"
            placeholder="Manufacturer Name"
            type="text"
          />
        </div>

        <!-- Version -->
        <div class="form-group">
          <label for="vsn">Version</label>
          <input
            class="form-control"
            [(ngModel)]="entry.vsn"
            id="vsn"
            name="vsn"
            placeholder="Version"
            type="text"
          />
        </div>

        <!-- New Version -->
        <div class="form-group">
          <label for="new-version">New Version</label>
          <input
            class="form-control"
            [(ngModel)]="entry._ingv_new_version"
            id="new-version"
            name="new-version"
            type="text"
            readonly
          />
        </div>

        <!-- Last Synched -->
        <div class="form-group">
          <label for="last-synched">Last Synched</label>
          <input
            class="form-control"
            [(ngModel)]="entry.last_synched"
            id="last-synched"
            name="last-synched"
            type="datetime-local"
          />
        </div>

        <!-- Flags -->
        <div class="form-group">
          <div class="form-check">
            <input
              class="form-check-input"
              [(ngModel)]="entry.flagged_for_synch"
              id="flagged-for-synch"
              type="checkbox"
            />
            <label class="form-check-label" for="flagged-for-synch"
              >Flagged for Sync</label
            >
          </div>
          <div class="form-check">
            <input
              class="form-check-input"
              [(ngModel)]="entry.flagged_for_synch_vnext"
              id="flagged-for-synch-next"
              type="checkbox"
            />
            <label class="form-check-label" for="flagged-for-synch-next"
              >Flagged for Next Sync</label
            >
          </div>
        </div>

        <!-- Notes -->
        <div class="form-group">
          <label for="notes">Notes</label>
          <textarea
            class="form-control"
            [(ngModel)]="entry.notes"
            id="notes"
            rows="3"
            placeholder="Enter additional notes"
          ></textarea>
        </div>
      </div>

      <!-- Buttons -->
      <div class="card-footer d-flex justify-content-between">
        <button (click)="cancel($event)" class="btn btn-sm btn-secondary">
          <i class="fa fa-times"></i> Cancel
        </button>
        <div>
          <button
            (click)="update($event, entry)"
            class="btn btn-sm btn-success"
          >
            <i class="fa fa-save"></i> Save
          </button>
          <button (click)="delete($event, entry)" class="btn btn-sm btn-danger">
            <i class="fa fa-trash"></i> Delete
          </button>
        </div>
      </div>
    </div>
  </div>
</form>
