import { Component, Input } from "@angular/core";
import { FirmwareLinkEntity } from "../../../entities/firmware/link.entity";

@Component({
  selector: "firmware-link",
  templateUrl: "./show.component.html",
})
export class FirmwareLinkShowComponent {
  @Input() entry: FirmwareLinkEntity = null;
  public unmodifiedEntry = null;
  public editEntry: boolean = false;
  public deleted: boolean = false;
  public saving: boolean = false;
  public type = 0;
  public notificationMessage = "";

  constructor() {}

  setAssignment(v) {
    this.type = v;
  }

  update(e, entity) {
    e.preventDefault();
    this.saving = true;
    if (
      entity.series == "" ||
      entity.manufacturerId == "" ||
      entity.firmwareDefinitionId == "" ||
      entity.series == null ||
      entity.manufacturerId == null ||
      entity.firmwareDefinitionId == null
    ) {
      this.alarm("all fields are required");
      return;
    }
    if (this.type == 0) {
      if (entity.serial == "" || entity.serial == null) {
        this.alarm("serial chosen but empty");
        return;
      }
      entity.batchId = null;
    } else {
      if (entity.batchId == "" || entity.batchId == null) {
        this.alarm("batch chosen but empty");
        return;
      }
      entity.serial = null;
    }
    this.alarm("");
    entity.update().then((ret) => {
      this.editEntry = false;
      this.saving = false;
    });
  }

  alarm(m) {
    this.notificationMessage = m;
  }

  edit(e, entity) {
    e.preventDefault();
    this.unmodifiedEntry = this.entry.toJson();
    this.editEntry = true;
  }

  cancel(e) {
    e.preventDefault();
    this.entry.refresh(this.unmodifiedEntry);
    this.editEntry = false;
  }

  delete(e, entity) {
    e.preventDefault();
    this.saving = true;
    this.editEntry = false;
    entity.delete().then((res: any) => {
      if (res) {
        this.deleted = true;
      }
      this.saving = false;
    });
  }
}
