import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { FirebaseAuthService } from "../../../noizu/services/firebase-auth.service";
import { environment } from "../../../../environments/environment";
import {
  SeriesLogicalWidget,
  VerificationCodeUploadEntity,
} from "../../../entities";
import { DataManagementWidgetEventStruct } from "./data-management-widget-event.struct";
import { WidgetEventStruct } from "../../widget-event.struct";
import { LogicalWidgetEventStruct } from "../logical";
import { LogicalWidgetEnum } from "../../../enums";

@Component({
  selector: "data-management-verification-code-upload-widget",
  template: `
    <form
      action=""
      class="data-management-verification-code-upload-widget form-horizontal"
      [formGroup]="uploadFile"
      (ngSubmit)="onSubmit($event)"
    >
      <div class="card">
        <div class="card-header bg-danger">
          <h1>VerificationCode Upload</h1>
        </div>
        <div class="card-body m-2 p-0" *ngIf="entity?.initialized">
          <div class="row mb-3">
            <div class="col text-center">
              <div class="btn-group" role="group" aria-label="Upload Type">
                <button
                  class="btn"
                  [ngClass]="{
                    'btn-primary': entity?.upload_type == 'csv',
                    'btn-secondary': entity?.upload_type != 'csv'
                  }"
                  (click)="setUploadType('csv', $event)"
                  type="button"
                >
                  CSV File
                </button>
                <button
                  class="btn"
                  [ngClass]="{
                    'btn-primary': entity?.upload_type == 'csv-snippet',
                    'btn-secondary': entity?.upload_type != 'csv-snippet'
                  }"
                  (click)="setUploadType('csv-snippet', $event)"
                  type="button"
                >
                  CSV Text
                </button>
                <button
                  class="btn"
                  [ngClass]="{
                    'btn-primary': entity?.upload_type == 'json-snippet',
                    'btn-secondary': entity?.upload_type != 'json-snippet'
                  }"
                  (click)="setUploadType('json-snippet', $event)"
                  type="button"
                >
                  Json
                </button>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <div class="alert alert-success">
                <!-- has header -->
                <div
                  class="row"
                  *ngIf="
                    entity?.upload_type == 'csv' ||
                    entity?.upload_type == 'csv-snippet'
                  "
                >
                  <div class="col">
                    <label>
                      <input
                        type="checkbox"
                        [checked]="entity.flags.has_header"
                        (change)="
                          entity.flags.has_header = !entity.flags.has_header
                        "
                      />
                      CSV Has Header?
                    </label>
                  </div>
                </div>

                <!-- over write? -->
                <div class="row">
                  <div class="col">
                    <label>
                      <input
                        type="checkbox"
                        [checked]="entity.flags.overwrite"
                        (change)="
                          entity.flags.overwrite = !entity.flags.overwrite
                        "
                      />
                      Overwrite Existing?
                    </label>
                  </div>
                </div>
              </div>

              <div class="row" *ngIf="entity?.validation?.messages['series']">
                <div class="col">
                  <div class="alert alert-danger">
                    {{ entity?.validation?.messages["series"] }}
                  </div>
                </div>
              </div>
              <div class="row mb-4">
                <div class="col-4"><strong>Series: </strong></div>
                <div class="col-8">
                  <logical-widget
                    (widgetEvent)="eventHandler($event, 'series')"
                    [entity]="series"
                    [options]="options"
                    [layout]="'default'"
                  ></logical-widget>
                </div>
              </div>

              <div class="form-group" *ngIf="entity.upload_type == 'csv'">
                <label class="col-md-3 col-form-label" for="csv"
                  ><strong>CSV Upload</strong></label
                >
                <input
                  type="file"
                  (change)="updateFile($event)"
                  name="code_file"
                />
              </div>

              <div
                class="form-group"
                *ngIf="entity.upload_type == 'csv-snippet'"
              >
                <textarea
                  name="csv_snippet"
                  [(ngModel)]="csv_snippet"
                  cols="64"
                  rows="32"
                  placeholder="Copy and Paste CSV entries."
                ></textarea>
              </div>

              <div
                class="form-group"
                *ngIf="entity.upload_type == 'json-snippet'"
              >
                <textarea
                  name="json_snippet"
                  [(ngModel)]="json_snippet"
                  cols="64"
                  rows="32"
                  placeholder="Copy and Paste Json Array"
                ></textarea>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer text-center">
          <button type="submit" class="btn btn-lg btn-danger">
            <i class="fa fa-dot-circle-o"></i> Upload Verification Codes
          </button>
        </div>
      </div>
    </form>
  `,
})
export class DataManagementVerificationCodeUploadWidget implements OnInit {
  @Input() entity: VerificationCodeUploadEntity = null;
  @Input() options: any = null;
  @Input() layout: string = null;
  @Output() widgetEvent = new EventEmitter<DataManagementWidgetEventStruct>();
  forwardEvent(widgetEvent: DataManagementWidgetEventStruct) {
    this.widgetEvent.emit(widgetEvent);
  }

  // Upload
  public series: SeriesLogicalWidget;
  public csv_snippet: string = "";
  public json_snippet: string = "";
  uploadFile: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private httpClient: HttpClient,
    private auth: FirebaseAuthService
  ) {}

  ngOnInit() {
    this.uploadFile = this.formBuilder.group({
      code_file: [""],
    });

    this.series = new SeriesLogicalWidget();
    this.series.subject = this.entity.series;

    this.entity.initialized = true;
  }

  setUploadType(type, event) {
    event.preventDefault();
    this.entity.upload_type = type;
  }

  updateFile(e: any) {
    // @ts-ignore
    if (event.target.files.length > 0) {
      // @ts-ignore
      const file = event.target.files[0];
      this.uploadFile.get("code_file").setValue(file);
    }
  }

  revalidate() {
    if (!this.entity.validation.valid && this.entity.validation.validated) {
      this.entity.validate();
    }
  }

  eventHandler(event: WidgetEventStruct, source) {
    if (event instanceof LogicalWidgetEventStruct) {
      if (
        event.event_logical_widget_type ==
        LogicalWidgetEnum.LOGICAL_WIDGET__SERIES
      ) {
        if (event.event_type == "series_selection_made") {
          this.entity.series = event.event_body["series"];
          this.entity.meta.pending = true;
          this.revalidate();
        }
        return;
      }
    }
    console.log("unhandled event", source, event);
  }

  uploadSuccessEvent(res) {
    console.log("Upload Success", res);
    let e = new DataManagementWidgetEventStruct();
    e.event_widget_type = this.entity.widget_type();
    e.event_logical_widget_type = this.entity.logical_widget_type();
    e.event_data_management_widget_type =
      this.entity.data_management_logical_widget_type();
    e.event_type = "upload_success";
    e.event_body = { response: res };
    this.widgetEvent.emit(e);
  }

  uploadFailureEvent(res) {
    console.log("Upload Error", res);
    let e = new DataManagementWidgetEventStruct();
    e.event_widget_type = this.entity.widget_type();
    e.event_logical_widget_type = this.entity.logical_widget_type();
    e.event_data_management_widget_type =
      this.entity.data_management_logical_widget_type();
    e.event_type = "upload_failure";
    e.event_body = { response: res };
    this.widgetEvent.emit(e);
  }

  onSubmit(e) {
    e.preventDefault();
    let fd = new FormData();
    fd.append("file_content", this.uploadFile.get("code_file").value);

    const series = `series=${this.entity.series}`;
    const has_header = `has_header=${
      this.entity.flags.has_header ? "true" : "false"
    }`;
    const overwrite = `overwrite=${
      this.entity.flags.overwrite ? "true" : "false"
    }`;

    const url = `${
      environment.ingv2_url + "/api"
    }/v1.1/admin-tools/upload/checksums?${series}&${has_header}&${overwrite}`;

    this.auth.getTokenPromise().then((token) => {
      let headers = new HttpHeaders();
      headers = headers.set("Authorization", `bearer ${token}`);

      // TODO on success message should be raised for displaying toast in parent page.
      if (this.entity.upload_type == "csv") {
        this.httpClient.post<any>(url, fd, { headers: headers }).subscribe(
          (res) => {
            if (res["outcome"] == "success") {
              this.uploadSuccessEvent(res);
            } else {
              this.uploadFailureEvent(res);
            }
          },
          (err) => this.uploadFailureEvent(err)
        );
      } else if (this.entity.upload_type == "csv-snippet") {
        this.httpClient
          .post<any>(
            url,
            { csv_content: this.csv_snippet },
            { headers: headers }
          )
          .subscribe(
            (res) => {
              if (res["outcome"] == "success") {
                this.uploadSuccessEvent(res);
              } else {
                this.uploadFailureEvent(res);
              }
            },
            (err) => this.uploadFailureEvent(err)
          );
      } else if (this.entity.upload_type == "json-snippet") {
        this.httpClient
          .post<any>(
            url,
            { json_content: this.json_snippet },
            { headers: headers }
          )
          .subscribe(
            (res) => {
              if (res["outcome"] == "success") {
                this.uploadSuccessEvent(res);
              } else {
                this.uploadFailureEvent(res);
              }
            },
            (err) => this.uploadFailureEvent(err)
          );
      } else {
        console.log("unsupported upload option");
      }
    });
    return true;
  }
}
