import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ToasterService } from "angular2-toaster";
import { WidgetBase } from "./widget-base";
import { WidgetEventStruct } from "../widget-event.struct";
import { ManufacturerEntity } from "../../entities/manufacturer.entity";
import { WidgetEnum } from "../../enums";
import { DataManufacturersEntity } from "../../entities";
import { DataManufacturersRepo } from "../../repos";

@Component({
  selector: "manufacturer-widget",
  template: `
    <ng-container class="row" *ngIf="entity">
      <ng-container [ngSwitch]="layout">
        <ng-container *ngSwitchCase="'table'">
          <div class="div-table-th" *ngIf="options['header']">
            <div class="row">
              <div class="col-1">
                <span class="pl-1">#Identifier</span>
              </div>
              <div class="col-2">Name</div>
              <div class="col">Notes</div>
              <div class="col-2">Created On</div>
              <div class="col-2">Modified On</div>
            </div>
          </div>

          <div
            [ngClass]="{
              'div-table-tr': options['index'] % 2 == 1,
              'div-table-tr-alt': options['index'] % 2 == 0
            }"
          >
            <div class="row">
              <div class="col-1 text-truncate" title="{{ entry.identifier }}">
                <span class="pl-1"
                  ><a href="#" (click)="select($event)"
                    >#{{ entity.identifier }}</a
                  ></span
                >
              </div>
              <div class="col-2 text-truncate" title="{{ entity.name }}">
                <ng-container *ngIf="options && options['edit']">
                  <input
                    (ngModelChange)="saveManufacturer()"
                    type="text"
                    [(ngModel)]="entity.name"
                  />
                </ng-container>
                <ng-container *ngIf="!(options && options['edit'])">
                  {{ entity.name }}
                </ng-container>
              </div>
              <div class="col text-truncate" title="{{ entity.notes }}">
                <ng-container *ngIf="options && options['edit']">
                  <input
                    (ngModelChange)="saveManufacturer()"
                    type="text"
                    [(ngModel)]="entity.notes"
                  />
                </ng-container>
                <ng-container *ngIf="!(options && options['edit'])">
                  {{ entity.notes }}
                </ng-container>
              </div>
              <div
                class="col-2 text-truncate"
                title="{{ entity.createdOn | date : 'longDate' }}"
              >
                {{ entity.createdOn | date : "mediumDate" }}
              </div>
              <div
                class="col-2 text-truncate"
                title="{{ entity.modifiedOn | date : 'longDate' }}"
              >
                {{ entity.modifiedOn | date : "mediumDate" }}
              </div>
            </div>
          </div>
        </ng-container>

        <ng-container *ngSwitchCase="'shadowbox'">
          <ng-container *ngIf="this.entity.meta['new']">
            <div class="row">
              <div class="col"><b>Identifier:</b></div>
              <div class="col">
                <ng-container *ngIf="options && options['edit']">
                  <input
                    (ngModelChange)="updateManufacturer()"
                    type="number"
                    [(ngModel)]="entity.identifier"
                  />
                </ng-container>
                <ng-container *ngIf="!(options && options['edit'])">
                  {{ entity.identifier }}
                </ng-container>
              </div>
            </div>

            <div class="row">
              <div class="col"><b>Name:</b></div>
              <div class="col">
                <ng-container *ngIf="options && options['edit']">
                  <input
                    (ngModelChange)="updateManufacturer()"
                    type="text"
                    [(ngModel)]="entity.name"
                  />
                </ng-container>
                <ng-container *ngIf="!(options && options['edit'])">
                  {{ entity.name }}
                </ng-container>
              </div>
            </div>
            <div class="row">
              <div class="col"><b>Notes:</b></div>
              <div class="col">
                <ng-container *ngIf="options && options['edit']">
                  <input
                    (ngModelChange)="updateManufacturer()"
                    type="text"
                    [(ngModel)]="entity.notes"
                  />
                </ng-container>
                <ng-container *ngIf="!(options && options['edit'])">
                  {{ entity.notes }}
                </ng-container>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="!this.entity.meta['new']">
            <div class="row">
              <div class="col"><b>Identifier:</b></div>
              <div class="col  text-truncate">{{ entity.identifier }}</div>
            </div>
            <div class="row">
              <div class="col"><b>Created On:</b></div>
              <div class="col">
                {{ entity.createdOn | date : "mediumDate" }}
              </div>
            </div>
            <div class="row">
              <div class="col"><b>Modified On:</b></div>
              <div class="col">
                {{ entity.modifiedOn | date : "mediumDate" }}
              </div>
            </div>
            <div class="row">
              <div class="col"><b>Name:</b></div>
              <div class="col">
                <ng-container *ngIf="options && options['edit']">
                  <input
                    (ngModelChange)="updateManufacturer()"
                    type="text"
                    [(ngModel)]="entity.name"
                  />
                </ng-container>
                <ng-container *ngIf="!(options && options['edit'])">
                  {{ entity.name }}
                </ng-container>
              </div>
            </div>
            <div class="row">
              <div class="col"><b>Notes:</b></div>
              <div class="col">
                <ng-container *ngIf="options && options['edit']">
                  <input
                    (ngModelChange)="updateManufacturer()"
                    type="text"
                    [(ngModel)]="entity.notes"
                  />
                </ng-container>
                <ng-container *ngIf="!(options && options['edit'])">
                  {{ entity.notes }}
                </ng-container>
              </div>
            </div>
          </ng-container>
        </ng-container>

        <ng-container *ngSwitchDefault>
          <div
            class="card"
            [ngStyle]="layout != 'sidebar' ? { height: '100%' } : null"
          >
            <div class="card-header">
              Manufacturer #{{ entity.identifier }} - {{ entity.name }}
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col"><b>Identifier:</b></div>
                <div class="col">{{ entity.identifier }}</div>
              </div>
              <div class="row">
                <div class="col"><b>Created On:</b></div>
                <div class="col">
                  {{ entity.createdOn | date : "mediumDate" }}
                </div>
              </div>
              <div class="row">
                <div class="col"><b>Modified On:</b></div>
                <div class="col">
                  {{ entity.modifiedOn | date : "mediumDate" }}
                </div>
              </div>
              <div class="row">
                <div class="col"><b>Name:</b></div>
                <div class="col">
                  <ng-container *ngIf="options && options['edit']">
                    <input
                      (ngModelChange)="updateManufacturer()"
                      type="text"
                      [(ngModel)]="entity.name"
                    />
                  </ng-container>
                  <ng-container *ngIf="!(options && options['edit'])">
                    {{ entity.name }}
                  </ng-container>
                </div>
              </div>
              <div class="row">
                <div class="col"><b>Notes:</b></div>
                <div class="col">
                  <ng-container *ngIf="options && options['edit']">
                    <input
                      (ngModelChange)="updateManufacturer()"
                      type="text"
                      [(ngModel)]="entity.notes"
                    />
                  </ng-container>
                  <ng-container *ngIf="!(options && options['edit'])">
                    {{ entity.notes }}
                  </ng-container>
                </div>
              </div>
            </div>
            <div class="card-footer" *ngIf="options['edit']">
              <div
                *ngIf="entity.meta['hasChanges']"
                class="btn btn-warning btn-lg mr-2"
                (click)="saveManufacturer($event)"
              >
                Save
              </div>
              <div
                class="btn btn-danger btn-lg"
                (click)="deleteManufacturer($event)"
              >
                Delete
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  `,
})
export class ManufacturerWidgetComponent extends WidgetBase implements OnInit {
  public editMode = false;
  @Input() entry: DataManufacturersEntity = null;
  @Input() layout: string = null;
  @Input() entity: ManufacturerEntity;
  @Input() options: any;
  @Output() widgetEvent = new EventEmitter<WidgetEventStruct>();
  forwardEvent(e, widgetEvent: WidgetEventStruct) {
    e.preventDefault();
    this.widgetEvent.emit(widgetEvent);
  }

  constructor(
    public repo: DataManufacturersRepo,
    public toasterService: ToasterService
  ) {
    super();
  }

  public select(event) {
    let a = new WidgetEventStruct();
    a.event_body = this.entity;
    a.event_type = "select_entity";
    a.event_widget_type = WidgetEnum.EMBED_WIDGET__MANUFACTURER_ENTITY;
    this.forwardEvent(event, a);
  }

  deleteManufacturer(event = null) {
    if (event) {
      event.preventDefault();
    }
    this.entity
      .delete()
      .then((r) => {
        this.toasterService.pop("success", "Success", `Manufacturer Deleted`);
        this.entity.meta.hasChanges = false;
      })
      .catch((r) => {
        this.toasterService.pop(
          "warning",
          "Error",
          `Manufacturer Delete Failed`
        );
      });
  }

  saveManufacturer(event = null) {
    if (event) {
      event.preventDefault();
    }
    this.entity
      .update()
      .then((r) => {
        this.toasterService.pop("success", "Success", `Manufacturer Updated`);
        this.entity.meta.hasChanges = false;
      })
      .catch((r) => {
        this.toasterService.pop(
          "warning",
          "Error",
          `Manufacturer Update Failed`
        );
      });
  }

  updateManufacturer() {
    this.entity.meta.hasChanges = true;
  }

  ngOnInit(): void {}
}
