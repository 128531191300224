<page-header [section]="'wifi-firmware-versions'">
  <page-title>WifiModule Firmware Versions</page-title>
  <page-blurb> </page-blurb>
</page-header>

<ng-container *ngIf="pageVersion == '1.0'">
  <div class="alert alert-info">
    Switch to Beta View
    <span class="float-right">&nbsp;Old View</span>
    <label class="switch switch-3d switch-primary float-right">
      <input
        checked=""
        class="switch-input switch-primary"
        (change)="togglePageVersion($event)"
        [(ngModel)]="pageVersionToggle"
        type="checkbox"
      />
      <span class="switch-slider"></span>
    </label>
  </div>

  <spinner *ngIf="!loaded"></spinner>
  <div *ngIf="loaded">
    <div class="row">
      <div
        [ngClass]="{ 'col-12': !selectedFirmware, 'col-7': selectedFirmware }"
      >
        <div class="row" *ngFor="let manufacturer of manufacturerList()">
          <div class="col-12">
            <div class="card">
              <div class="card-header">
                <span>{{ manufacturer.name }}</span>
                <span>({{ manufacturer.id }})</span>
              </div>
              <div class="card-block card-accent-dark">
                <div class="row">
                  <div class="col-3 text-left">#ID</div>
                  <div class="col-3 text-left">Manufacturer</div>
                  <div class="col-3 text-left">Series</div>
                  <div class="col-3 text-left">Version</div>
                </div>

                <div
                  *ngFor="let entry of entries[manufacturer.id]; let i = index"
                >
                  <widget-embed
                    [entity]="entry"
                    [layout]="'table'"
                    [options]="{ index: i }"
                    (click)="selectFirmwareEntry(entry, $event)"
                    [manufacturerSelectOptions]="manufacturerSelectOptions"
                  >
                  </widget-embed>
                </div>
              </div>
              <div class="card-footer" *ngIf="firmware_admin">
                <div class="row">
                  <div class="col-12 text-center">
                    <button
                      (click)="
                        add(
                          firmwareDefinitionModal,
                          manufacturer.man,
                          manufacturer.series,
                          $event
                        )
                      "
                      class="btn btn-sm btn-primary"
                      type="submit"
                    >
                      <i class="fa fa-plus-circle"></i> New
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="selectedFirmware" class="col-5">
        <widget-embed
          [entity]="selectedFirmware"
          [layout]="'sidebar'"
          [options]="sidebar_options"
          [manufacturerSelectOptions]="manufacturerSelectOptions"
        >
        </widget-embed>
      </div>
    </div>
  </div>
  <ng-template #firmwareDefinitionModal>
    <div class="modal-header">
      <h4 class="modal-title pull-left">{{ currentModal?.title }}</h4>
      <button
        type="button"
        class="close pull-right"
        aria-label="Close"
        (click)="firmwareDefinitionModalRef.hide()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <widget-spinner *ngIf="currentModal?.processing"></widget-spinner>
      <div class="row" *ngIf="!currentModal?.processing">
        <div class="col-12">
          <div class="alert alert-info">
            {{ currentModal?.msg }}
          </div>
        </div>
        <div class="col-12">
          <widget-embed
            [entity]="newFirmwareDefinition"
            [layout]="'sidebar'"
            [options]="widget_options"
            [manufacturerSelectOptions]="manufacturerSelectOptions"
          >
          </widget-embed>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button
        *ngIf="currentModal?.cancelMsg"
        type="button"
        class="btn btn-secondary"
        (click)="currentModal?.cancel()"
        data-dismiss="modal"
      >
        {{ currentModal?.cancelMsg }}
      </button>
      <button
        *ngIf="currentModal?.deleteMsg"
        type="button"
        class="btn btn-warning"
        (click)="currentModal?.delete()"
        data-dismiss="modal"
      >
        {{ currentModal?.deleteMsg }}
      </button>
      <button
        *ngIf="currentModal?.confirmMsg"
        type="button"
        class="btn btn-danger"
        (click)="currentModal?.confirm()"
      >
        {{ currentModal?.confirmMsg }}
      </button>
    </div>
  </ng-template>
</ng-container>

<ng-container *ngIf="pageVersion == '2.0'">
  <div class="alert alert-info">
    Go back to classic view
    <span class="float-right">&nbsp;Beta View</span>
    <label class="switch switch-3d switch-primary float-right">
      <input
        checked=""
        class="switch-input switch-primary"
        (change)="togglePageVersion($event)"
        [(ngModel)]="pageVersionToggle"
        type="checkbox"
      />
      <span class="switch-slider"></span>
    </label>
  </div>

  <ng-container *ngIf="liveViewLink | safe">
    <embed class="liveView" [src]="liveViewLink | safe" />
  </ng-container>
</ng-container>
