import { Component, HostListener, Inject, NgZone, OnInit } from "@angular/core";
import { Location } from "@angular/common";
import { FirmwareDefinitionEntity } from "../../../entities/firmware/definition.entity";
import { FirmwareDefinitionRepo } from "../../../repos/firmware/definition.repo";
import { BsModalRef, BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import { AuthService } from "../../../services/auth.service";
import { FirebaseAuthService } from "../../../noizu/services/firebase-auth.service";
import { HttpClient } from "@angular/common/http";
import { SafeResourceUrl } from "@angular/platform-browser";
import { UserPreferencesService } from "../../../services/user-preferences.service";
import { environment } from "../../../../environments/environment";
import { ManufacturerListService } from "app/services/manufacturers.service";

@Component({
  templateUrl: "./list.component.html",
})
export class FirmwareDefinitionListComponent implements OnInit {
  public liveViewLink: SafeResourceUrl | boolean = false;
  public pageVersionToggle = false;
  public pageVersion = "1.0";
  public entries: any;
  public manufacturers: any;
  public manufacturerName: any;
  public loaded = false;
  public selectedFirmware: any;
  public firmwareDefinitionModalRef: BsModalRef;
  public currentModal: any;
  public client: HttpClient;
  public auth: FirebaseAuthService;
  public newFirmwareDefinition: FirmwareDefinitionEntity;
  public widget_options = { edit: false, modal_form: true };
  public sidebar_options = { edit: false };
  public firmware_admin = false;
  public manufacturerSelectOptions: any = null;
  public message_debug: any = false;
  public newFirmwareDefinitionModal = {
    title: "Add Firmware",
    msg: `Add new firmware definition.`,
    errorMsg: null,
    processing: false,
    form: "new-firmware",
    confirmMsg: "Save",
    confirm: () => {
      if (this.saveNewfirmware()) {
        this.firmwareDefinitionModalRef.hide();
      }
    },

    cancelMsg: "Cancel",
    cancel: () => {
      this.newFirmwareDefinitionModal.errorMsg = null;
      this.firmwareDefinitionModalRef.hide();
    },
  };

  constructor(
    public modalService: BsModalService,
    public repo: FirmwareDefinitionRepo,
    public zone: NgZone,
    public authService: AuthService,
    public preferences: UserPreferencesService,
    public manufacturerListService: ManufacturerListService,
    private location: Location,

    @Inject(HttpClient) client: HttpClient,
    @Inject(FirebaseAuthService) auth: FirebaseAuthService
  ) {
    this.client = client;
    this.auth = auth;
    this.manufacturers = {};
    this.entries = {};
    this.manufacturerName = {};

    this.zone.run(() => {
      if (this.authService.loadedPromise) {
        this.authService.loadedPromise.then(() => {
          if (this.authService.userHasPermission("firmware_admin")) {
            this.firmware_admin = true;
            this.widget_options.edit = true;
            this.sidebar_options.edit = true;
          }
        });
      }
      this.manufacturerListService.getManufacturers().then((response) => {
        this.manufacturerSelectOptions = response.map((option) => ({
          value: option.identifier,
          label: option.name,
        }));
        response.forEach((manufacturer) => {
          this.manufacturerName[manufacturer.identifier] = manufacturer.name;
        });
      });
      this.repo.getListPromise().then((u: any) => {
        let items = u.items;
        const len = items.length;
        let man = {};
        for (let i = 0; i < len; i++) {
          const m = items[i].manufacturerId.toString() + "." + items[i].series;
          man[m] = true;
          if (this.manufacturers[m] == undefined) {
            this.manufacturers[m] = {
              id: m,
              name: this.manufacturerName[items[i].manufacturerId] || "Unknown",
              man: items[i].manufacturerId.toString(),
              series: items[i].series,
            };
            this.entries[m] = [];
          }
          this.entries[m].push(items[i]);
        }
        this.loaded = true;
        for (let key in man) {
          this.entries[key] = this.entries[key].sort((a, b) => {
            return this.sortVersion(a, b);
          });
        }
      });
    });
  }

  @HostListener("window:message", ["$event"])
  onMessage(event: MessageEvent) {
    if (event.origin !== window.location.origin) {
      if (
        event.origin !== "http://localhost:5520" &&
        window.location.origin !== "http://localhost:4200"
      ) {
        console.error(
          `HostListener: ORIGIN MISMATCH: ${event.origin} != ${window.location.origin}`
        );
        return;
      }
    }
    if (
      event.data &&
      typeof event.data === "object" &&
      !Array.isArray(event.data) &&
      "embedded_live_view" in event.data
    ) {
      if (
        event.data.embedded_live_view &&
        typeof event.data.embedded_live_view === "object" &&
        "message_type" in event.data.embedded_live_view
      ) {
        this.processEmbeddedMessage(event.data.embedded_live_view);
      }
    }
  }

  ngOnInit() {
    this.loadVersion();
    this.setLiveViewLink();
  }

  setLiveViewLink() {
    this.auth.getTokenPromise().then((token) => {
      let currentPath = this.location.path(true); // 'true' to include the hash in the path
      let options = currentPath.split("#");
      if (options.length > 1) {
        const regex = /[?&]?firmware=(ref\.firmware\.[a-zA-Z0-9\.]+)/;
        const match = options[1].match(regex);
        if (match) {
          // Use the matched firmware value in the new URL format
          const firmwareVersion = match[1];
          let link = `${environment.live_view}/firmware/releases/${firmwareVersion}?jwt=${token}&${options[1]}`;
          this.liveViewLink = link; //this.domSanitizer.bypassSecurityTrustResourceUrl(link);
        } else {
          // Use the default URL format
          let link = `${environment.live_view}/firmware/releases?jwt=${token}&${options[1]}`;
          this.liveViewLink = link; //this.domSanitizer.bypassSecurityTrustResourceUrl(link);
        }
      } else {
        let link = environment.live_view + `/firmware/releases?jwt=${token}`;
        this.liveViewLink = link; //this.domSanitizer.bypassSecurityTrustResourceUrl(link);
      }
    });
  }

  processEmbeddedMessage(message) {
    this.message_debug = message;
    if (message.message_type == "route_update") {
      this.processEmbeddedMessageRouteUpdate(message);
    }
  }

  processEmbeddedMessageRouteUpdate(message) {
    const currentPath = this.location.path(true); // 'true' to include the hash in the path
    const newPath = currentPath.split("#")[0] + "#" + message.route;
    this.location.replaceState(newPath);
  }

  liveViewLinkSanitized() {
    return this.liveViewLink;
  }

  loadVersion() {
    if (this.preferences) {
      this.preferences.session.readyPromise.then(() => {
        this.pageVersion = this.preferences.featureVersion(
          "firmware-definitions",
          "1.0"
        );
        this.pageVersionToggle = this.pageVersion == "2.0";
      });
    }
  }

  togglePageVersion(event) {
    event.preventDefault();
    if (this.pageVersion == "1.0") {
      this.pageVersion = "2.0";
      this.liveViewLink = false;
      this.setLiveViewLink();
    } else {
      this.pageVersion = "1.0";
    }
    this.pageVersionToggle = this.pageVersion == "2.0";
    this.preferences.setFeatureVersion(
      "firmware-definitions",
      this.pageVersion
    );
  }

  public sortVersion(a, b) {
    let av = a.firmwareVersion.split(".");
    let bv = b.firmwareVersion.split(".");
    for (let i = 0; i < 3; i++) {
      const an = av[i] + 0;
      const bn = bv[i] + 0;
      if (an != bn) return an - bn;
    }
    return 0;
  }

  selectFirmwareEntry(entry, event) {
    event.preventDefault();
    if (
      this.selectedFirmware &&
      this.selectedFirmware.identifier == entry.identifier
    ) {
      this.selectedFirmware = null;
    } else {
      this.selectedFirmware = entry;
    }
  }

  manufacturerList() {
    let l = [];
    for (let k in this.manufacturers) {
      l.push(this.manufacturers[k]);
    }
    return l;
  }

  saveNewfirmware() {
    this.newFirmwareDefinition.update().then((r: FirmwareDefinitionEntity) => {
      this.entries[r.manufacturerId].push(r);
    });
    return true;
  }

  add(modal, manufacturer, series, e) {
    e.preventDefault();
    this.newFirmwareDefinition = new FirmwareDefinitionEntity(
      this.client,
      this.auth,
      {
        identifier: "new",
        manufacturerId: manufacturer,
        firmwareVersion: "0.0.0",
        size: 0,
        checksum: "123",
        series: series,
        fileA: "user1.bin",
        fileB: "user2.bin",
        notes: "New Firmware",
      }
    );
    this.currentModal = this.newFirmwareDefinitionModal;
    let config: ModalOptions = {
      backdrop: "static",
      keyboard: false,
    };
    this.firmwareDefinitionModalRef = this.modalService.show(modal, config);
  }
}
