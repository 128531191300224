import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { FieldSimulatorWidget } from "../../../../entities/device";
import { LogicalDeviceWidgetEventStruct } from "./logical-device-widget-event.struct";
import { ToasterService } from "angular2-toaster";

@Component({
  selector: "device-field-simulator-widget",
  template: `
    <div class="device-field-simulator-widget" *ngIf="!entity">
      <widget-spinner></widget-spinner>
    </div>
    <div class="device-field-simulator-widget" *ngIf="entity?.subject">
      <div class="row">
        <div class="col-2 text-right">{{ entity.subject.identifier }}</div>
        <div class="col text-center">
          <div *ngIf="!entity.subject.field?.is_derived_type">
            <div class="alert alert-success">
              <ng-container *ngIf="entity.subject.field.meta.include_previous">
                <input
                  type="number"
                  min="{{ rangeLow }}"
                  max="{{ rangeHigh }}"
                  [(ngModel)]="entity.previous_value"
                  (blur)="changeValue(entity)"
                  name="device"
                  size="10"
                />
                <span> - </span>
                <input
                  type="number"
                  min="{{ rangeLow }}"
                  max="{{ rangeHigh }}"
                  [(ngModel)]="entity.value"
                  (blur)="changeValue(entity)"
                  name="device"
                  size="10"
                />
              </ng-container>

              <ng-container *ngIf="!entity.subject.field.meta.include_previous">
                <input
                  type="number"
                  min="{{ rangeLow }}"
                  max="{{ rangeHigh }}"
                  [(ngModel)]="entity.value"
                  (blur)="changeValue(entity)"
                  name="device"
                  size="10"
                />
              </ng-container>
            </div>
          </div>
          <div *ngIf="entity.subject.field?.is_derived_type">
            <div class="alert alert-info">[Derived Field]</div>
          </div>
        </div>
        <div class="col text-left">
          <b>Range</b>: [{{ rangeLow }}, {{ rangeHigh }}], <b>Precision</b>:
          {{ entity.subject.field?.precision }}, <b>Unit</b>:
          {{ entity.subject.field?.unit }}
        </div>
      </div>
    </div>
  `,
})
export class DeviceFieldSimulatorWidgetComponent implements OnInit {
  @Input() entity: FieldSimulatorWidget | null = null;
  @Input() templateHint: string = null;
  @Input() options: any = null;
  @Input() layout = "standard";
  @Output() widgetEvent = new EventEmitter<LogicalDeviceWidgetEventStruct>();
  forwardEvent(e, widgetEvent: LogicalDeviceWidgetEventStruct) {
    e.preventDefault();
    this.widgetEvent.emit(widgetEvent);
  }

  public rangeLow: number = 0;
  public rangeHigh: number = 0;

  constructor(public toasterService: ToasterService) {}

  changeValue(e: FieldSimulatorWidget) {
    if (!(this.rangeLow <= e.value && e.value <= this.rangeHigh)) {
      e.value = 0;
      this.toasterService.pop(
        "error",
        "Validation error",
        `Value must be in between ${this.rangeLow} - ${this.rangeHigh}.`
      );
    }
  }

  ngOnInit(): void {
    this.rangeLow = this.entity.subject.field.rangeLow || 0;
    this.rangeHigh = this.entity.subject.field.rangeHigh || 0;
  }
}
