export const environment = {
  production: true,
  stage: true,
  project: "lax-gateway-stage",
  host_name: "lax-gateway-stage.appspot.com",
    //live_view: 'https://ingv2-stage-admin.lacrossetechnology.com/live-view',
  live_view: "http://localhost:5520/live-view",
  firebase: {
    apiKey: "AIzaSyBpZfdOO3loqdJPZdiW0NSUDSbXx1t_Skk",
    authDomain: "lax-gateway-stage.firebaseapp.com",
    databaseURL: "https://lax-gateway-stage.firebaseio.com",
    projectId: "lax-gateway-stage",
    storageBucket: "lax-gateway-stage.appspot.com",
    messagingSenderId: "748874225897",
    service_account: "./lax-gateway-stage-13d513d81c03.json",
  },
  view_admin_url: "https://view-admin.appspot.com",
  lax_gateway_url: "https://lax-gateway-stage.appspot.com",
  ingv2_url: "https://ingv2-stage.lacrossetechnology.com",
};
