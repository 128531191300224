import { HttpClient } from "@angular/common/http";
import { FirebaseAuthService } from "../../noizu/services/firebase-auth.service";
import { WidgetEnum } from "../../enums/widget";
import { ElixirEntityList } from "../../noizu/structs";
import { EntityLogFactory } from "./entity-log.factory";

export class EntityLogSet extends ElixirEntityList {
  public loaded = false;
  public subject: any = null;
  constructor(
    client: HttpClient,
    auth: FirebaseAuthService,
    subject,
    json,
    repo = null
  ) {
    super("entity-log", [], null, null, null, []);
    this.subject = subject;
    this.items = new Array();
    this.repo = repo;
    for (const snippet in json) {
      this.loaded = true;
      let entry = EntityLogFactory.factory(client, auth, json[snippet]);
      if (entry) this.items.push(entry);
    }
  } 

  widget_type(): any {
    return WidgetEnum.EMBED_WIDGET__LOG_SET_ENTITY;
  }
} 
