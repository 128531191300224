import { MetricUnit, ImperialUnit, UnitTypeEnum } from "./unit-type.enum";

export enum UnitEnum {
  None = 0 + UnitTypeEnum.Scalar,

  Millimeters = MetricUnit + UnitTypeEnum.UnitOfDistance + 1,
  Centimeters = MetricUnit + UnitTypeEnum.UnitOfDistance + 2,
  Meters = MetricUnit + UnitTypeEnum.UnitOfDistance + 3,
  Kilometers = MetricUnit + UnitTypeEnum.UnitOfDistance + 4,
  Inches = ImperialUnit + UnitTypeEnum.UnitOfDistance + 5,
  Miles = ImperialUnit + UnitTypeEnum.UnitOfDistance + 6,
  Steps = ImperialUnit + UnitTypeEnum.UnitOfDistance + 7,

  MetersPerSecond = MetricUnit + UnitTypeEnum.UnitOfVelocity + 1,
  KilometersPerHour = MetricUnit + UnitTypeEnum.UnitOfVelocity + 2,
  FeetPerSecond = ImperialUnit + UnitTypeEnum.UnitOfVelocity + 3,
  MilesPerHour = ImperialUnit + UnitTypeEnum.UnitOfVelocity + 4,
  Knots = ImperialUnit + UnitTypeEnum.UnitOfVelocity + 5,

  Seconds = MetricUnit + UnitTypeEnum.UnitOfTime + 1,
  Minutes = MetricUnit + UnitTypeEnum.UnitOfTime + 2,
  Hours = MetricUnit + UnitTypeEnum.UnitOfTime + 3,
  Days = MetricUnit + UnitTypeEnum.UnitOfTime + 4,

  DegreesCelsius = MetricUnit + UnitTypeEnum.UnitOfTemperature + 1,
  DegreesFahrenheit = ImperialUnit + UnitTypeEnum.UnitOfTemperature + 2,

  Radians = MetricUnit + UnitTypeEnum.UnitOfAngularMeasure + 1,
  Degrees = ImperialUnit + UnitTypeEnum.UnitOfAngularMeasure + 2,

  RelativeHumidity = MetricUnit + UnitTypeEnum.UnitOfHumidity + 1,

  Millibarrs = 32897,
}
