import { Component, Input } from "@angular/core";
import { DeviceLookupTableEntryEntity } from "../../../entities/device/lookup-table-entry.entity";
import { DeviceLookupTableRepo } from "../../../repos/device/lookup-table.repo";

@Component({
  selector: "device-lookup-entry",
  templateUrl: "./lookup-table-entry.component.html",
})
export class DeviceAutoLoadLookupTableEntryComponent {
  @Input() entry: DeviceLookupTableEntryEntity = null;
  constructor(public repo: DeviceLookupTableRepo) {}

  update(e, entity) {
    e.preventDefault();
    entity.update();
  }

  delete(e, entity) {
    e.preventDefault();
    // todo remove from parent / to-way binding, emit event.
    entity.delete();
  }
}
