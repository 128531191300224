export const MetricUnit = 128;
export const ImperialUnit = 0;

export enum UnitTypeEnum {
  Error = 0,
  Scalar = 256,
  UnitOfDistance = 512,
  UnitOfVelocity = 1024,
  UnitOfTime = 2048,
  UnitOfTemperature = 4096,
  UnitOfAngularMeasure = 8192,
  UnitOfHumidity = 16384,
}
