
    <div class="row">
      <div class="col-2">
        <widget
          *ngIf="entity?.effective"
          [entity]="entity.effective"
          [options]="options"
          [layout]="layout"
        ></widget>
      </div>
      <div class="col-10">
        <widget
          *ngIf="entity?.subscriptions"
          [entity]="entity.subscriptions"
          [options]="options"
          [layout]="layout"
        ></widget>
      </div>
    </div>
  