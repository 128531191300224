import { Injectable } from "@angular/core";
import { CookieService } from "ngx-cookie";
import { AuthService } from "./auth.service";
import { AngularFireDatabase } from "@angular/fire/database";
@Injectable()
export class UserPreferencesService {
  public feature_version: any = {};
  public minimize_section: any = {};
  public minimize_default = false;

  constructor(
    public cookieService: CookieService,
    public db: AngularFireDatabase,
    public session: AuthService
  ) {
    this.session.readyPromise
      .then(() => {
        if (this.session.user) {
          this.feature_version =
            this.cookieService.getObject(
              `user-preferences-${this.session.user.identifier}`
            ) || {};
          let t = this.cookieService.getObject(
            `user-sections-${this.session.user.identifier}`
          );
          if (t) {
            this.minimize_section = t["section"];
            this.minimize_default = t["minimize_default"];
          }
        } else {
          console.log("no user");
        }
      })
      .catch(() => {
        console.log("catch");
        // nothing to be done.
      });
  }

  public featureVersion(feature: string, or_default: string | null = null) {
    return this.feature_version[feature] || or_default;
  }

  public setFeatureVersion(feature: string, version: string) {
    this.feature_version[feature] = version;
    this.saveFeatureVersions();
  }

  public saveFeatureVersions() {
    if (this.session.user) {
      this.cookieService.putObject(
        `user-preferences-${this.session.user.identifier}`,
        this.feature_version
      );
    }
  }

  public sectionMinimization(section: string) {
    return section in this.minimize_section
      ? this.minimize_section[section]
      : this.minimize_default;
  }

  public setSectionMinimization(section: string, value: boolean) {
    this.minimize_section[section] = value;
    this.saveSectionPreferences();
  }

  public toggleSectionMinimization(section: string) {
    this.minimize_section[section] = !this.sectionMinimization(section);
    this.saveSectionPreferences();
  }

  public setDefaultMinimize(value: boolean) {
    this.minimize_default = value;
    this.saveSectionPreferences();
  }

  public saveSectionPreferences() {
    if (this.session.user) {
      this.cookieService.putObject(
        `user-sections-${this.session.user.identifier}`,
        {
          minimize_default: this.minimize_default,
          section: this.minimize_section,
        }
      );
    }
  }
}
