import { Component, Inject, Input, NgZone } from "@angular/core";
import { DeviceDefinitionEntity } from "../../../entities/device/definition.entity";
import { DeviceSimulatorEntity } from "../../../entities/device-simulator.entity";
import { DeviceDefinitionRepo } from "../../../repos/device/definition.repo";
import { FirebaseAuthService } from "../../../noizu/services/firebase-auth.service";
import { HttpClient } from "@angular/common/http";

/*
Device Simulator
Calls an admin variant of the api devices call to post databaseURL
useful for ensuring data can go through the system among other things

WIP: working on adding call to the actual live url. Requires Checksum and Hmac generation

Does a lot of the work manually/Hard coded.
*/
@Component({
  selector: "device-simulator",
  templateUrl: "./list.component.html",
})
export class DeviceSimulatorComponent {
  public entries: Array<DeviceDefinitionEntity>;
  public auth;
  @Input() inputid?: string = "";
  @Input() deviceType?: string = "TX60";
  @Input() devSeries?: string = "V2";
  //The number in front is not part of the model, but is used for something important. TODO figure that out
  public selected = "0TX60";
  public fields = "";
  public simData = {};
  public error = { show: false, message: "" };
  public success = { show: false, message: "" };
  public entity;
  public loaded = false;
  public gateway = "20022E";
  public onDisplay = false;
  public saving = false;
  public useAdmin = true;
  public psk = "";
  public pskFound = false;

  constructor(
    public repo: DeviceDefinitionRepo,
    public zone: NgZone,
    @Inject(HttpClient) client: HttpClient,
    @Inject(FirebaseAuthService) auth: FirebaseAuthService
  ) {
    this.entity = new DeviceSimulatorEntity(client, auth, {});
    this.auth = auth;
    //Default Data
    this.simData = {
      w: 1, // Wetness (truthy or falsy values)
      r: 1, // In inches. To binary requires *100
      e: 1, // 24 bit Rain, is special
      t: 40, // Celcius. To binary requires (val+40)*10      as binary 0 = -40 C
      h: 90, // %, no change
      i: 10, // Probably ignoreable
      f: 10, // Probably ignoreable
      p: 10, // Unknown what to set for this.
      s: 25, // km/h. To binary requires *10
      c: 10, // Probably ignoreable
      g: 30, // Presumably km/h.
      d: 359, // 0-359 degrees,
      a: 10, // Legacy device only
      b: 10, // Legacy device only
    };
    /*
      (w)et (r)ain (t)emp (h)umidity
      heat(i)ndex (f)eelsLike (p)robe
      wind(s)peed wind(c)hill wind(g)ust windhea(d)ing
      (a)IndoorTemperature (b)IndoorHumidity

      //Convert Category numbers to names
      //Copied from elsewhere, may need tweaking if used
      let keys = Object.keys(DeviceCategoryEnum);
      let array = keys.slice(keys.length/2);
      let array2= keys.splice(0,keys.length/2)
      array.forEach((item,index) => {
        this.categories.push({"val":array2[index],"display":item});
      }); */
    this.zone.run(() => {
      this.repo.getListPromise().then((u: any) => {
        u.items.forEach((item: any) => {
          this.createFieldsString(item);
        });
        this.sortDefinitions(u);
        this.entries = u;
        this.onChange(this.selected);
        this.initIfChild();
      });
    });
  }

  ngOnInit() {
    if (this.deviceType.length > 1) {
      this.initIfChild();
    }
  }
  //Simulator can be placed anywhere, if a child flag is set, it should have a device to autofill in.
  initIfChild() {
    if (this.loaded == true) {
      let x = 0;
      this.entries["items"].forEach((item: any) => {
        if (item.internalName == this.deviceType) {
          this.selected = ("0" + x.toString()).slice(-2) + this.deviceType;
        }
        x++;
      });
      this.onChange(this.selected);
      this.simData["id"] = this.inputid;
    } else {
      this.loaded = true;
    }
  }

  onChange(selected) {
    this.fields =
      this.entries["items"][parseInt(selected.substring(0, 2))].fields;
  }

  //Process the simulate device request, send to
  simDevice() {
    if (
      this.simData["id"] &&
      this.simData["id"].length === 6 &&
      this.gateway.length === 6
    ) {
      if (this.useAdmin) {
        this.simulateDeviceAdmin();
      } else {
        this.simulateDeviceLive();
      }
    } else {
      this.error["show"] = true;
      this.error["message"] = "Id or Gateway is not 6 digits long";
    }
  }

  //Sim request is to be as if sent from an actual device, Send request to the Node server with the details
  simulateDeviceLive() {
    this.saving = true;
    this.success.show = false;
    this.error["show"] = false;
    let serial = this.simData["id"].toUpperCase();
    let formattedData = serial + "00" + this.formatData();

    let simurl =
      "https://ingv2.lacrossetechnology.com/api/v1.1/gateways/report/binary";
    let payload = {
      DisplaySerial: serial,
      Data: formattedData,
      GatewaySerial: serial,
      OnDisplay: this.onDisplay.toString(),
      Firmware: "2.1.16",
      psk: this.psk,
    };

    this.entity
      .sendSimData(payload)
      .then((ret) => {
        this.saving = false;
        this.success.show = true;
        this.success.message = ret;
      })
      .catch((err: any) => {
        console.log(err);
        this.saving = false;
        this.error.show = true;
        this.error.message = err;
      });
  }

  //Send fake reading to admin api (system deals with it differently, still shows up as a reading)
  simulateDeviceAdmin() {
    this.saving = true;
    this.success.show = false;
    this.error["show"] = false;
    let formattedData = this.formatData();
    //Very important functionality delegated sendSimDataAdmin, check that entity out (probably should move it here)
    this.entity
      .sendSimDataAdmin(
        this.simData["id"].toUpperCase(),
        formattedData,
        this.gateway,
        this.onDisplay
      )
      .then((ret) => {
        this.saving = false;
        this.success.show = true;
        this.success.message = ret;
      })
      .catch((err: any) => {
        console.log(err);
        this.saving = false;
        this.error.show = true;
        this.error.message = err;
      });
  }

  //Get fields for type and convert fields into data to create chain of Hex values that the devices send
  //Types provide a chain of letters that signifies data in order. loop through that and get appropriate fields.
  formatData() {
    let returnData = "";
    let f = this.fields.split("");

    for (var i = 0; i < f.length; i++) {
      //For each field, in order, get the data in its textbox, convert units and remove decimal according to docs,
      //ensure its 3 Hex digits long, and append to the data string. (exception, 6 Hex digits for 24bit rain)
      switch (f[i]) {
        case "w":
          //Wet parsing (wet or dry values)
          let val = parseInt(this.simData["w"]);
          if (val) {
            returnData += "F0F";
          } else {
            returnData += "0F0";
          }

          break;
        case "t":
          returnData += (
            "000" + ((40 + parseInt(this.simData["t"])) * 10).toString(16)
          ).slice(-3);
          break;
        case "h":
          returnData += (
            "000" + parseInt(this.simData["h"]).toString(16)
          ).slice(-3);
          break;
        case "s":
          returnData += (
            "000" + (parseInt(this.simData["s"]) * 10).toString(16)
          ).slice(-3);
          break;
        case "d":
          returnData += (
            "000" + parseInt(this.simData["d"]).toString(16)
          ).slice(-3);
          break;
        case "r":
          //Rain
          returnData += (
            "000" + Math.round(parseFloat(this.simData["r"]) * 100).toString(16)
          ).slice(-3);
          break;
        case "e":
          //24 bit Rain (2 hex characters first half of binary, + AA + last 2 Hex characters, second half of binary)
          var n = parseFloat(this.simData["e"]) * 100;
          var b = ("0000000000000000" + n.toString(2)).slice(-16);
          var s = (
            "00" + parseInt(b.slice(0, 8), 2).toString(16).toUpperCase()
          ).slice(-2);
          s += "AA";
          s += (
            "00" + parseInt(b.slice(8), 2).toString(16).toUpperCase()
          ).slice(-2);
          returnData += s;
          break;
        case "n":
          returnData += "AAA";
          break;
      }
    }
    return returnData.toUpperCase();
  }

  //Give the definitions list some basic sorting
  sortDefinitions(u) {
    u.items = u.items.sort(function (a, b) {
      var reA = /[^a-zA-Z]/g;
      var reN = /[^0-9]/g;
      var aA = a["handle"].replace(reA, "");
      var bA = b["handle"].replace(reA, "");
      if (aA === bA) {
        var aN = parseInt(a["handle"].replace(reN, ""), 10);
        var bN = parseInt(b["handle"].replace(reN, ""), 10);
        return aN === bN ? 0 : aN > bN ? 1 : -1;
      } else {
        return aA > bA ? 1 : -1;
      }
    });
  }

  //create a string which has letters corrisponding to the fields
  createFieldsString(item) {
    item["fields"] = "";
    item.fieldEntries.forEach((entry: any) => {
      //Custom and arbitraty way to identify all the fields an entry has in one string
      //GOAL: Parse the string, each character defines a field.

      //(w)et (r)ain (t)emp (h)umidity
      //heat(i)ndex (f)eelsLike (p)robe
      //wind(s)peed wind(c)hill wind(g)ust windhea(d)ing
      //(a)IndoorTemperature (b)IndoorHumidity
      //(e)rain24bit
      //(n)ot supported

      switch (entry.identifier.toLowerCase()) {
        case "notsupported":
          item["fields"] += "n";
          break;
        case "wetdry":
          item["fields"] += "w";
          break;
        case "rain":
          //THe LTV's have no identifier for rain being 24 bit, so if its rain, we check the field id
          if (entry.sensorFieldId == 5705814031466496) {
            item["fields"] += "e";
          } else {
            item["fields"] += "r";
          }
          break;
        case "rain24bit":
          item["fields"] += "e";
          break;
        case "temperature":
          item["fields"] += "t";
          break;
        case "humidity":
          item["fields"] += "h";
          break;
        case "heatindex":
          item["fields"] += "i";
          break;
        case "feelslike":
          item["fields"] += "f";
          break;
        case "probe":
          item["fields"] += "p";
          break;
        case "indoortemperature":
          item["fields"] += "a";
          break;
        case "indoorhumidity":
          item["fields"] += "b";
          break;
        case "windspeed":
          item["fields"] += "s";
          break;
        case "windchill":
          item["fields"] += "c";
          break;
        case "windgust":
          item["fields"] += "g";
          break;
        case "windheading":
          item["fields"] += "d";
          break;
      }
    });
  }

  toggleUseAdmin(useAdmin) {
    this.useAdmin = !useAdmin;
    this.getPSK();
  }

  //Live API requires Hmac code, which requires PSK to generate
  getPSK() {
    if (this.psk.length < 2) {
      this.entity
        .getgateway(this.devSeries, this.simData["id"].toUpperCase())
        .then((ret) => {
          //console.log(ret)
          this.psk = ret.secret;
          if (ret.secret.length && ret.secret.length > 6) {
            this.pskFound = true;
          }
        })
        .catch((err: any) => {
          console.log(err);
          this.error.show = true;
          this.error.message = "failed to load PSK";
        });
    }
  }

  public handleError(error: any): Promise<any> {
    console.error("A caught error occurred", error); // for demo purposes only
    if (
      error.toString().includes("Authorization Header Missing or Malformed")
    ) {
      return new Promise((resolve, reject) => {
        resolve({ id: "authFail", success: false });
      });
    } else {
      //Generic error
      return new Promise((resolve, reject) => {
        resolve({ success: "false", msg: error });
      });
    }
    //return Promise.reject(error.message || error);
  }
}
