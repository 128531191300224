
    <div class="card border-danger">
      <div class="card-header bg-danger">
        Gateway Firmware ({{ entity?.last_reported }})
        <div class="card-header-actions">
          <span class="pr-2"
            ><i
              (click)="expandCard = !expandCard"
              class="fa fa-sm"
              [ngClass]="{
                'fa-chevron-down': !expandCard,
                'fa-chevron-up': expandCard
              }"
            ></i
          ></span>
          <span class="dragula-handle"
            ><i class="dragula-handle text-black-50 fa fa-lg fa-arrows"></i
          ></span>
        </div>
      </div>
      <div class="card-body p-1" *ngIf="expandCard">
        <div class="row">
          <div class="col-6">
            <strong>Batch:</strong>
          </div>
          <div class="col-6">
            {{ entity.gateway_entity?.batch }}
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <strong>Manufacturer:</strong>
          </div>
          <div class="col-6">
            {{ entity.gateway_entity?.manufacturer }}
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <strong>Last Firmware:</strong>
          </div>
          <div class="col-6">
            {{ entity.last_reported }}
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <strong>Assigned Firmware:</strong>
          </div>
          <div class="col-6" *ngIf="entity.assigned">
            {{ entity.assigned?.firmware_version }}
          </div>
          <div class="col-6" *ngIf="!entity.assigned">
            <b>None</b>
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <strong>Pending Firmware:</strong>
          </div>
          <div class="col-6" *ngIf="entity.pending">
            {{ entity.pending?.firmware_version }}
          </div>
          <div class="col-6" *ngIf="!entity.pending">
            <b>None</b>
          </div>
        </div>

        <div class="row" *ngIf="entity.roll_out && entity.pending">
          <div class="col-6">
            <strong>Roll Out Group:</strong>
          </div>
          <div class="col-6">{{ entity.roll_out?.roll_out_group }}</div>
        </div>

        <div class="row">
          <div class="col-12 text-center">
            <strong>Gateway Internal State</strong>
          </div>
        </div>

        <div class="row">
          <div class="col-6"><strong>*assigned</strong></div>
          <div class="col-6" *ngIf="entity.worker_state?.assigned_link">
            {{ entity.worker_state?.assigned_link }}
          </div>
          <div class="col-6" *ngIf="!entity.worker_state?.assigned_link">
            None
          </div>
        </div>
        <div class="row">
          <div class="col-6"><strong>*manager</strong></div>
          <div class="col-6" *ngIf="entity.worker_state?.pending_release">
            {{ entity.worker_state?.pending_release }}
          </div>
          <div class="col-6" *ngIf="!entity.worker_state?.pending_release">
            None
          </div>
        </div>
        <div class="row" *ngIf="entity.worker_state?.pending_release">
          <div class="col-6"><strong>*managed_link</strong></div>
          <div class="col-6">{{ entity.worker_state?.pending_link }}</div>
        </div>
        <div class="row" *ngIf="entity.worker_state?.pending_release">
          <div class="col-6"><strong>*managed_phase</strong></div>
          <div class="col-6">{{ entity.worker_state?.pending_phase }}</div>
        </div>
        <div class="row" *ngIf="entity.worker_state?.pending_release">
          <div class="col-6"><strong>*managed_step</strong></div>
          <div class="col-6">{{ entity.worker_state?.meta?.pending_step }}</div>
        </div>
        <div class="row" *ngIf="entity.roll_out">
          <div class="col-12">
            <div class="row">
              <div class="col-12 text-center">
                <strong>Release RollOut Record</strong>
              </div>
            </div>

            <div class="row">
              <div class="col-6"><strong>*phase</strong></div>
              <div class="col-6">{{ entity.roll_out?.phase }}</div>
            </div>
            <div class="row">
              <div class="col-6"><strong>*step</strong></div>
              <div class="col-6">{{ entity.roll_out?.step }}</div>
            </div>
            <div class="row">
              <div class="col-6"><strong>*status</strong></div>
              <div class="col-6">{{ entity.roll_out?.status }}</div>
            </div>

            <div class="row">
              <div class="col-6"><strong>*roll_out_group</strong></div>
              <div class="col-6">{{ entity.roll_out?.roll_out_group }}</div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col text-center">
            <button (click)="forceRefresh($event)" class="button btn-primary">
              Link Check
            </button>
          </div>
          <div class="col text-center">
            <button
              (click)="forceMcuRefresh($event)"
              class="button btn-primary"
            >
              Mota Check
            </button>
          </div>
          <div class="col text-center">
            <button (click)="forceIAP($event)" class="button btn-primary">
              Force IAP
            </button>
          </div>
          <div class="col text-center">
            <button (click)="forceRef($event)" class="button btn-primary">
              Ref Override
            </button>
          </div>
        </div>

        <hr />

        <div class="row">
          <div class="col-12 text-center">
            <strong>Cycle Override</strong>
          </div>
          <div class="col-12" *ngIf="!entity.cycle_override">[NONE]</div>
          <div class="col-12" *ngIf="entity.cycle_override">
            <div class="row">
              <div class="col-5">
                <strong>Enabled</strong>
              </div>
              <div class="col-7">
                {{ entity.cycle_override.enabled | json }}
              </div>
            </div>
            <div class="row">
              <div class="col-5">
                <strong>Interval</strong>
              </div>
              <div class="col-7">
                {{ entity.cycle_override.interval }} (Seconds)
              </div>
            </div>
            <div class="row">
              <div class="col-5">
                <strong>VerString</strong>
              </div>
              <div class="col-7">
                <span *ngIf="entity.cycle_override.firmware_version">{{
                  entity.cycle_override.firmware_version
                }}</span>
                <span *ngIf="!entity.cycle_override.firmware_version"
                  >[No Override]</span
                >
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12 text-center">
            <strong>Script Override</strong>
          </div>
          <div class="col-12" *ngIf="!entity.script_override">
            [No Firmware Script Enabled]
          </div>
          <div class="col-12" *ngIf="entity.script_override">
            <div class="row">
              <div class="col-5">
                <strong>Enabled</strong>
              </div>
              <div class="col-7">
                {{ entity.script_override.enabled | json }}
              </div>
            </div>

            <div class="row">
              <div class="col-5">
                <strong>Script</strong>
              </div>
              <div class="col-7">
                <pre>{{ entity.script_override.script | json }}</pre>
              </div>
            </div>

            <div class="row">
              <div class="col-5">
                <strong>Script Sequence Position</strong>
              </div>
              <div class="col-7">
                <pre>{{
                  entity.script_override.sequencer_info?.entity?.position
                }}</pre>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="overrideAccess()">
          <hr />
          <div class="row">
            <div class="col-12 text-justify text-center">
              <button
                (click)="showSetCycle(reportModal, $event)"
                class="button btn-warning ml-1"
              >
                Assign Cycle
              </button>
              <button
                (click)="showSetScript(reportModal, $event)"
                class="button btn-danger ml-1"
              >
                Assign Script
              </button>
              <button
                (click)="showSetBatch(reportModal, $event)"
                class="button btn-primary ml-1"
              >
                Assign Batch
              </button>
              <button
                (click)="showSetBatch(reportModal, $event)"
                class="button btn-danger ml-1"
              >
                Force MOTA
              </button>
              <button
                (click)="showSetBatch(reportModal, $event)"
                class="button btn-success ml-1"
              >
                Set Ref
              </button>
            </div>
          </div>
        </div>

        <hr />

        <div class="row">
          <div class="col-12 text-center pr-4">
            <strong>Firmware Links</strong>
            <span class="float-right">
              <i
                (click)="expandLinks = !expandLinks"
                class="fa fa-sm"
                [ngClass]="{
                  'fa-chevron-down': !expandLinks,
                  'fa-chevron-up': expandLinks
                }"
              >
              </i>
            </span>
          </div>
          <div class="col-12" *ngIf="expandLinks">
            <pre>{{ entity.firmware_links | json }}</pre>
          </div>
        </div>

        <div class="row">
          <div class="col-12 text-center pr-4">
            <strong>Firmware Report</strong>
            <span class="float-right">
              <i
                (click)="expandReport = !expandReport"
                class="fa fa-sm"
                [ngClass]="{
                  'fa-chevron-down': !expandReport,
                  'fa-chevron-up': expandReport
                }"
              >
              </i>
            </span>
          </div>
          <div class="col-12" *ngIf="expandReport">
            <pre>{{ entity.report | json }}</pre>
          </div>
        </div>

        <div class="row">
          <div class="col-12 text-center pr-4">
            <strong>Extended History</strong>
            <span class="float-right">
              <i
                (click)="expandHistory = !expandHistory"
                class="fa fa-sm"
                [ngClass]="{
                  'fa-chevron-down': !expandHistory,
                  'fa-chevron-up': expandHistory
                }"
              >
              </i>
            </span>
          </div>
          <div class="col-12" *ngIf="expandHistory">
            <pre>{{ entity.history | json }}</pre>
          </div>
        </div>

        <div class="row">
          <div class="col-12 text-center pr-4">
            <strong>Raw Internal State</strong>
            <span class="float-right">
              <i
                (click)="expandInternalState = !expandInternalState"
                class="fa fa-sm"
                [ngClass]="{
                  'fa-chevron-down': !expandInternalState,
                  'fa-chevron-up': expandInternalState
                }"
              >
              </i>
            </span>
          </div>
          <div class="col-12" *ngIf="expandInternalState">
            <pre>{{ entity.worker_state | json }}</pre>
          </div>
        </div>

        <div class="row">
          <div class="col-12 text-center pr-4">
            <strong>Raw RollOut State</strong>
            <span class="float-right">
              <i
                (click)="expandRollOutState = !expandRollOutState"
                class="fa fa-sm"
                [ngClass]="{
                  'fa-chevron-down': !expandRollOutState,
                  'fa-chevron-up': expandRollOutState
                }"
              >
              </i>
            </span>
          </div>
          <div class="col-12" *ngIf="expandRollOutState">
            <pre>{{ entity.roll_out | json }}</pre>
          </div>
        </div>
      </div>
    </div>

    <!--                                                        -->
    <!--                    Pop-Up Menu                         -->
    <!--                                                        -->
    <ng-template #reportModal>
      <div class="modal-header">
        <h4 class="modal-title pull-left">{{ currentModal?.title }}</h4>
        <button
          type="button"
          class="close pull-right"
          aria-label="Close"
          (click)="reportModalRef.hide()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <widget-spinner *ngIf="currentModal?.processing"></widget-spinner>
        <div class="row">
          <div class="col-12">
            {{ currentModal?.msg }}
          </div>
        </div>
        <div class="row" *ngIf="currentModal?.errorMsg">
          <div class="col-12">
            <div class="alert alert-warning">{{ currentModal?.errorMsg }}</div>
          </div>
        </div>
        <div class="row" [ngSwitch]="currentModal?.form">
          <div class="col-12" *ngSwitchCase="'batch'">
            <div class="form-group">
              <div class="row">
                <div class="col-4"><strong>Assign Batch</strong></div>
                <div class="col-8" *ngIf="available.batches | async as batches">
                  <ng-select
                    class="form-control font-2xl pb-0 pt-0"
                    placeholder="Select Batch"
                    [items]="batches"
                    [multiple]="false"
                    bindLabel="label"
                    bindValue="value"
                    [(ngModel)]="editBatch.batch"
                  ></ng-select>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12" *ngSwitchCase="'cycle'">
            <div class="form-group">
              <div class="row">
                <div class="col-4"><strong>Enabled</strong></div>
                <div class="col-4">
                  <input
                    type="checkbox"
                    class="form-control"
                    [(ngModel)]="editCycle.enabled"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-4"><strong>Interval</strong></div>
                <div class="col-4">
                  <input
                    type="text"
                    class="form-control"
                    [(ngModel)]="editCycle.interval"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-4"><strong>Spoof Version</strong></div>
                <div class="col-4">
                  <input
                    type="text"
                    class="form-control"
                    [(ngModel)]="editCycle.firmware_version"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="col-12" *ngSwitchCase="'script'">
            <div class="form-group">
              <div class="row">
                <div class="col-4"><strong>Enabled</strong></div>
                <div class="col-4">
                  <input
                    type="checkbox"
                    class="form-control"
                    [(ngModel)]="editScript.enabled"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-4"><strong>Script</strong></div>
                <div class="col-4">
                  <select
                    class="form-control font-xl pb-0 pt-0"
                    [(ngModel)]="editScript.script"
                  >
                    <option [value]="'alpha_group'">Alpha Group</option>
                    <option [value]="'beta_group'">Beta Group</option>
                    <option [value]="'dev_group'">Dev Group</option>
                    <option [value]="'noizu_group'">Noizu Group</option>
                    <option [value]="'v3_group'">V3 1024kb+ Beta Group</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12" *ngSwitchDefault>[Unsupported Modal Type]</div>
        </div>
      </div>
      <div class="modal-footer">
        <button
          *ngIf="currentModal?.cancelMsg"
          type="button"
          class="btn btn-secondary"
          (click)="currentModal?.cancel()"
          data-dismiss="modal"
        >
          {{ currentModal?.cancelMsg }}
        </button>
        <button
          *ngIf="currentModal?.deleteMsg"
          type="button"
          class="btn btn-warning"
          (click)="currentModal?.delete()"
          data-dismiss="modal"
        >
          {{ currentModal?.deleteMsg }}
        </button>
        <button
          *ngIf="currentModal?.confirmMsg"
          type="button"
          class="btn btn-danger"
          (click)="currentModal?.confirm()"
        >
          {{ currentModal?.confirmMsg }}
        </button>
      </div>
    </ng-template>
  