<page-header [section]="'device-types'">
  <page-title>Device Types</page-title>
  <page-blurb
    >View detailed information of available device types. Manage device types by
    initiating actions to edit, clone and save existing and new device type
    entries.</page-blurb
  >
</page-header>

<spinner *ngIf="!entries"></spinner>

<div *ngIf="entries">
  <widget [entity]="entries" [layout]="'table'" [options]="options"></widget>
</div>

<ng-template #deviceTypeListModal>
  <div class="modal-header">
    <h4 class="modal-title pull-left">{{ currentModal?.title }}</h4>
    <button
      type="button"
      class="close pull-right"
      aria-label="Close"
      (click)="deviceTypeListModalRef.hide()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <widget-spinner *ngIf="currentModal?.processing"></widget-spinner>
    <div class="row" *ngIf="!currentModal?.processing">
      <div class="col-12">{{ currentModal?.msg }}</div>
      <div class="col-12">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-header">Updated Fields</div>
              <div class="card-body">
                <!-- image -->
                <div class="row mb-1">
                  <div class="col-3"><b>Image:</b></div>
                  <div class="col-9">
                    <input type="text" [(ngModel)]="clone.image" size="64" />
                  </div>
                </div>

                <!-- Name -->
                <div class="row mb-1">
                  <div class="col-3"><b>Name:</b></div>
                  <div class="col-9">
                    <input type="text" [(ngModel)]="clone.name" size="8" />
                  </div>
                </div>

                <!-- Internal Name -->
                <div class="row mb-1">
                  <div class="col-3"><b>Internal:</b></div>
                  <div class="col-9">
                    <input
                      type="text"
                      [(ngModel)]="clone.internalName"
                      size="8"
                    />
                  </div>
                </div>

                <!-- Description -->
                <div class="row mb-3">
                  <div class="col-3"><b>Description:</b></div>
                  <div class="col-9">
                    <input
                      type="text"
                      [(ngModel)]="clone.description"
                      size="8"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <widget-embed
              [entity]="clone"
              [layout]="'aside'"
              [options]="{
                expand: true,
                collapsible: false,
                attributes: { expand: true }
              }"
            ></widget-embed>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      *ngIf="currentModal?.cancelMsg"
      type="button"
      class="btn btn-secondary"
      (click)="currentModal?.cancel()"
      data-dismiss="modal"
    >
      {{ currentModal?.cancelMsg }}
    </button>
    <button
      *ngIf="currentModal?.deleteMsg"
      type="button"
      class="btn btn-warning"
      (click)="currentModal?.delete()"
      data-dismiss="modal"
    >
      {{ currentModal?.deleteMsg }}
    </button>
    <button
      *ngIf="currentModal?.confirmMsg"
      type="button"
      class="btn btn-danger"
      (click)="currentModal?.confirm()"
    >
      {{ currentModal?.confirmMsg }}
    </button>
  </div>
</ng-template>
