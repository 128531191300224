<page-header [section]="'manufacturers'">
  <page-title>Manufacturers</page-title>
  <page-blurb>
    View and manage the current list of manufacturers. Add new manufacturers to
    the list.
  </page-blurb>
</page-header>
<div>
  <button (click)="add($event)" class="btn btn-sm btn-primary">
    <i class="fa fa-plus-circle"></i> New
  </button>
</div>

<spinner *ngIf="!entries"></spinner>

<div *ngIf="entries" class="row">
  <div class="row" *ngFor="let entry of entries.items">
    <div class="p-1" flex="grow">
      <manufacturers-entry [entry]="entry" class="col-6"></manufacturers-entry>
    </div>
  </div>
</div>
