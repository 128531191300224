
    <ng-container *ngIf="!inline">
      <div class="row">
        <div class="col text-center">
          <div class="new-spinner {{ spinner }} {{ size }}" role="status"></div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="inline">
      <div class="new-spinner {{ spinner }} {{ size }}" role="status"></div>
    </ng-container>
  