<div class="row">
  <div class="col-12">
    <div class="card">
      <div class="card-header">Device Category Notifications</div>
      <div class="card-body">
        <div class="row" style="margin-left: 0px; margin-right: 0px">
          <div class="col-12">
            Set messages that show up at the bottom of devices in the app that
            are of the specified device category (update to an empty message to
            clear)
          </div>
          <div class="col-12">
            Messages: <br />
            <div *ngIf="notifications" class="col-12">
              <render-object [object]]="notifications"></render-object>
            </div>
          </div>
          <div class="col-12">
            <h2>Update Messages</h2>
            <p class="card-text">
              Category to update:
              <select
                name="category"
                [(ngModel)]="selectedCategory"
                name="category"
              >
                <option *ngFor="let cat of categories" [value]="cat.val">
                  {{ cat.display }}
                </option>
              </select>
            </p>

            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <select
                  name="color"
                  [(ngModel)]="selectedColor"
                  name="color"
                  style="padding: 8px; width: 100px"
                >
                  <option value="white">White</option>
                  <option value="green">Green</option>
                  <option value="yellow">Yellow</option>
                  <option value="red">Red</option>
                </select>
              </div>
              <input
                type="text"
                class="form-control"
                [(ngModel)]="message"
                placeholder="Message"
                aria-label="Message"
                aria-describedby="basic-addon2"
              />
              <div class="input-group-append">
                <button
                  (click)="postCategoryMessage($event)"
                  class="btn btn-primary"
                  type="button"
                >
                  Update Message
                </button>
              </div>
            </div>
            <br />
          </div>
        </div>
      </div>
      <div class="card-footer">
        <button
          (click)="listDevices($event)"
          class="btn btn-sm btn-primary"
          type="submit"
        >
          List Device Types in Categories
        </button>
        <br />
        <div *ngIf="dc">
          <render-object [object]="catArrayObj"></render-object>
        </div>
      </div>
    </div>
  </div>
</div>
