import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ToasterService } from "angular2-toaster";
import { AlertLightningFacadeEntity } from "../../../../entities/alert";
import { AlertFacadeDefaultWidgetComponent } from "./default.widget.component";
import { AlertFacadeWidgetEventStruct } from "./alert-facade-widget-event.struct";

@Component({
  selector: "alert-facade-lightning-widget",
  template: `
    <div class="card card-accent-dark alert-facade-lightning-widget">
      <!-- Card Header -->
      <div class="card-header bg-dark text-white">
        <h5 class="mb-0">Lightning Alert</h5>
      </div>

      <!-- Card Body -->
      <div class="card-body">
        <!-- Device Section -->
        <div class="row mb-3">
          <div class="col-4 font-weight-bold">Device</div>
          <div class="col-8">
            <a
              [routerLink]="'/portal/devices/show/' + entity.device"
              class="text-primary"
              >{{ entity.device }}</a
            >
          </div>
        </div>

        <!-- Association Section -->
        <div class="row mb-3">
          <div class="col-4 font-weight-bold">Association</div>
          <div class="col-8">{{ entity.deviceAssociation }}</div>
        </div>

        <hr class="my-3" />

        <!-- JSON Data Section -->
        <div class="row">
          <div class="col-12">
            <div class="card bg-light">
              <div class="card-body">
                <pre class="mb-0">{{ entity | json }}</pre>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
})
export class EmbedAlertLightningFacadeComponent extends AlertFacadeDefaultWidgetComponent {
  public editMode = false;
  @Input() layout: string = null;
  @Input() entity: AlertLightningFacadeEntity;
  @Input() options: any;
  @Input() deviceData: any = null;
  @Output() widgetEvent = new EventEmitter<AlertFacadeWidgetEventStruct>();
  forwardEvent(e, widgetEvent: AlertFacadeWidgetEventStruct) {
    e.preventDefault();
    this.widgetEvent.emit(widgetEvent);
  }

  setDeviceValues() {
    if (!this.options.edit) return;
    this.entity.device = this.deviceData.deviceId;
    this.entity.deviceAssociation = this.deviceData.associationId;
  }

  constructor(public toasterService: ToasterService) {
    super();
  }

  ngOnInit(): void {
    if (
      this.deviceData.deviceId !== undefined &&
      this.deviceData.associationId !== undefined
    ) {
      this.setDeviceValues();
    }
  }
}
