import { WidgetEnum } from "../../enums";
import { ElixirRepo } from "../../repos";
import { EntityList } from "./entity-list";

export class ElixirEntityList extends EntityList {
  public repo: ElixirRepo = null;

  constructor(
    kind,
    items,
    cursor,
    containerContents: WidgetEnum = null,
    repo: any = null,
    options
  ) {
    super(kind, items, cursor, containerContents, repo, options);
    this.repo = repo;
  } // end constructor

  nextPromise(options = {}) {
    if (this.repo) {
      let o = this.options || {};
      o["cursor"] = this.cursor;

      if (o["resultsPerPage"]) o["resultsPerPage"] = o["resultsPerPage"];
      else if (o["rpp"]) o["resultsPerPage"] = o["rpp"];

      o["pg"] = ++this.repo.page;
      console.log(this.repo);
      let r = this.repo.getListPromise(o);
      r.then((u: ElixirEntityList) => {
        if (u) {
          this.items = this.items.concat(u.items);
          if (u.cursor == this.cursor) this.endOfList = true;
          this.cursor = u.cursor;
          this.options = u.options;
          if (this.repo._sort) this.items = this.items.sort(this.repo._sort);
          u.items = this.items;
        }
      });
      return r;
    }
    return new Promise((resolve, reject) => {
      reject(null);
    });
  }

  widget_type() {
    return this.containerContents || WidgetEnum.EMBED_WIDGET__ELIXIR_SET;
  }
}
