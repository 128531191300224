import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";

@Pipe({ name: "safe" })
export class SafePipe implements PipeTransform {
  constructor(private domSanitizer: DomSanitizer) {}
  transform(url) {
    // todo get sanitize working.
    return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
    //return this.domSanitizer.sanitize(SecurityContext.RESOURCE_URL, url);
  }
}
