
    <ng-template #dataSource let-source>
      <div class="btn-group mr-1 ml-1">
        <button
          class="btn"
          type="button"
          [ngClass]="{
            'btn-success': selectedFeed && selectedFeed.key == source.key,
            'btn-info': !(selectedFeed && selectedFeed.key == source.key)
          }"
          (click)="select($event, source.key)"
        >
          {{ source.name }}
        </button>
        <button
          class="btn"
          type="button"
          [ngClass]="{
            'btn-success': selectedFeed && selectedFeed.key == source.key,
            'btn-info': !(selectedFeed && selectedFeed.key == source.key)
          }"
          (click)="remove($event, source.key)"
        >
          <i class="fa fa-minus-circle"></i>
        </button>
      </div>
    </ng-template>

    <ng-template #graphSource let-source>
      <div class="btn-group mr-1 ml-1">
        <button
          class="btn"
          type="button"
          [ngClass]="{
            'btn-success': selected[source.key],
            'btn-info': !selected[source.key]
          }"
          (click)="toggleGraph($event, source.key)"
        >
          {{ source.name }}
        </button>
        <button
          class="btn btn-lg btn-primary"
          (click)="fetchAndRefresh($event, source.key)"
        >
          <i class="fa fa-plus-circle"></i>
        </button>
      </div>
    </ng-template>

    <ng-container [ngSwitch]="layout">
      <!-- ===================================== -->
      <!-- Default (Card)                        -->
      <!-- ===================================== -->
      <ng-container *ngSwitchDefault>
        <div class="extended-device-chart-logical-widget card">
          <div class="card-header">Feed</div>
          <div class="card-body">
            <div class="row mb-4">
              <div class="col-auto">
                <select
                  class="form-control"
                  name="aggregate"
                  [(ngModel)]="time_period"
                >
                  <option
                    *ngFor="let entry of time_options"
                    value="{{ entry.value }}"
                  >
                    {{ entry.text }}
                  </option>
                </select>
              </div>
              <div class="col-auto" *ngIf="entity">
                <select
                  class="form-control"
                  name="aggregate"
                  [(ngModel)]="time_zone"
                >
                  <option
                    *ngFor="let entry of time_zone_options"
                    value="{{ entry.value }}"
                  >
                    {{ entry.text }}
                  </option>
                </select>
              </div>
              <div class="col-auto" *ngIf="entity">
                <select
                  class="form-control"
                  name="aggregate"
                  [(ngModel)]="for_field"
                >
                  <option
                    *ngFor="let entry of field_options"
                    value="{{ entry.value }}"
                  >
                    {{ entry.text }}
                  </option>
                </select>
              </div>

              <div class="col-auto">
                <label class="switch switch-3d switch-primary float-right">
                  <input
                    checked=""
                    class="switch-input switch-primary"
                    [(ngModel)]="metric_units"
                    type="checkbox"
                  />
                  <span class="switch-slider"></span>
                </label>
                <span class="pl-2" *ngIf="metric_units">Metric</span>
                <span class="pl-2" *ngIf="!metric_units">Imperial</span>
              </div>

              <div class="col-1 text-center pt-1">
                <i
                  class="fa fa-lg fa-2x fa-plus-circle text-success"
                  (click)="addRange($event)"
                ></i>
              </div>
              <div class="col-1 text-center pt-1">
                <i
                  class="fa fa-lg fa-2x fa-calendar text-success"
                  (click)="customRange($event, chartModal)"
                ></i>
              </div>
            </div>

            <div class="row" *ngIf="entity">
              <tabset #view class="col">
                <tab heading="Table View">
                  <div class="row" *ngIf="entity">
                    <div class="col">
                      <ng-container
                        *ngFor="let source of objectValues(entity.feeds)"
                      >
                        <ng-container
                          *ngTemplateOutlet="
                            dataSource;
                            context: { $implicit: source }
                          "
                        ></ng-container>
                      </ng-container>
                    </div>
                  </div>

                  <ng-container *ngIf="selectedFeed">
                    <div class="row mt-4">
                      <div class="col">
                        <h1>
                          {{ selectedFeed.name }} ({{ selectedFeed.units }})
                        </h1>
                      </div>
                    </div>

                    <div
                      *ngIf="selectedFeed.variant_entries"
                      class="row mt-4 border-bottom bg-dark text-white"
                    >
                      <div class="col-3">Date</div>
                      <div class="col">Variant</div>
                      <div class="col">Spot</div>
                      <div class="col">Average</div>
                      <div class="col">High</div>
                      <div class="col">Low</div>
                      <div class="col">Tally</div>
                    </div>
                    <div
                      *ngIf="!selectedFeed.variant_entries"
                      class="row mt-4 border-bottom bg-dark text-white"
                    >
                      <div class="col-3">Date</div>
                      <div class="col">Spot</div>
                      <div class="col">Average</div>
                      <div class="col">High</div>
                      <div class="col">Low</div>
                      <div class="col">Tally</div>
                    </div>

                    <div class="row">
                      <div class="col text-center">
                        <new-spinner
                          *ngIf="selectedFeed.fetching"
                          [size]="'sm'"
                        ></new-spinner>
                        <div class="btn-group" *ngIf="!selectedFeed.fetching">
                          <button
                            class="btn btn-primary p-1"
                            (click)="
                              fetchRecent(
                                $event,
                                false,
                                selectedFeed.period,
                                selectedFeed.time_zone,
                                selectedFeed.metric,
                                selectedFeed.field
                              )
                            "
                          >
                            Fetch Newer
                          </button>
                          <button
                            class="btn btn-primary p-1"
                            (click)="
                              fetchRecent(
                                $event,
                                true,
                                selectedFeed.period,
                                selectedFeed.time_zone,
                                selectedFeed.metric,
                                selectedFeed.field
                              )
                            "
                          >
                            <i class="fa fa-plus-circle"></i>
                          </button>
                        </div>
                      </div>
                    </div>

                    <ng-container *ngIf="selectedFeed.variant_entries">
                      <div
                        class="row border-bottom"
                        *ngFor="let entry of selectedFeed.entries | values"
                      >
                        <div class="col-3">
                          {{ entry.time | date : "short" }}
                        </div>
                        <div class="col">{{ entry.variant }}</div>
                        <div class="col">{{ entry.spot_value }}</div>
                        <div class="col">{{ entry.average_value }}</div>
                        <div class="col">{{ entry.maximum_value }}</div>
                        <div class="col">{{ entry.minimum_value }}</div>
                        <div class="col">{{ entry.tally_value }}</div>
                      </div>
                    </ng-container>

                    <ng-container *ngIf="!selectedFeed.variant_entries">
                      <div
                        class="row border-bottom"
                        *ngFor="let entry of selectedFeed.entries | values"
                      >
                        <pre>{{ entry | json }}</pre>

                        <div class="col-3">
                          {{ entry.time | date : "short" }}
                        </div>
                        <div class="col">
                          {{ entry.spot_value || "Not found" }}
                        </div>
                        <div class="col">{{ entry.average_value }}</div>
                        <div class="col">{{ entry.maximum_value }}</div>
                        <div class="col">{{ entry.minimum_value }}</div>
                        <div class="col">{{ entry.tally_value }}</div>
                      </div>
                    </ng-container>

                    <div class="row mt-2">
                      <div class="col text-center">
                        <new-spinner
                          *ngIf="selectedFeed.fetching"
                          [size]="'sm'"
                        ></new-spinner>
                        <div class="btn-group" *ngIf="!selectedFeed.fetching">
                          <button
                            class="btn btn-primary p-1"
                            (click)="
                              fetch(
                                $event,
                                false,
                                selectedFeed.period,
                                selectedFeed.time_zone,
                                selectedFeed.metric,
                                selectedFeed.field
                              )
                            "
                          >
                            Fetch More|
                            {{
                              selectedFeed.feed_coverage_start | date : "short"
                            }}
                          </button>
                          <button
                            class="btn btn-primary p-1"
                            (click)="
                              fetch(
                                $event,
                                true,
                                selectedFeed.period,
                                selectedFeed.time_zone,
                                selectedFeed.metric,
                                selectedFeed.field
                              )
                            "
                          >
                            <i class="fa fa-plus-circle"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </tab>
                <tab heading="Graph">
                  <div class="row" *ngIf="entity">
                    <div class="col">
                      <ng-container
                        *ngFor="let source of objectValues(entity.feeds)"
                      >
                        <ng-container
                          *ngTemplateOutlet="
                            graphSource;
                            context: { $implicit: source }
                          "
                        ></ng-container>
                      </ng-container>
                    </div>
                  </div>

                  <div style="height:600px;" class="col-12 my-3">
                    <div class="row col-12">
                      <div class="col-12" class="scrollCanvasWrapper">
                        <div class="scrollCanvas">
                          <plotly-plot
                            [data]="graph.data"
                            [layout]="graph.layout"
                            [useResizeHandler]="true"
                            [style]="{
                              position: 'relative',
                              width: '100%',
                              height: '100%'
                            }"
                          >
                          </plotly-plot>
                        </div>
                      </div>
                    </div>
                  </div>
                </tab>
              </tabset>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>

    <!--                                                        -->
    <!--                    Pop-Up Menu                         -->
    <!--                                                        -->
    <ng-template #chartModal>
      <modal-widget
        *ngIf="currentModal"
        (widgetEvent)="eventHandler($event, 'pop-up', chartModal)"
        [entity]="currentModal"
        [layout]="'shadowbox'"
        [options]="{}"
      ></modal-widget>
    </ng-template>
  