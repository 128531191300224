export const basicNav = [
  {
    title: true,
    name: "View-Admin Account",
    wrapper: {
      // optional wrapper object
      element: "span", // required valid HTML5 element tag
      attributes: {}, // optional valid JS object with JS API naming ex: { className: 'my-class', style: { fontFamily: 'Verdana' }, id: 'my-id'}
    },
    class: "text-center", // optional class names space delimited list for title item ex: 'text-center'
  },
  {
    name: "Log Out",
    url: "/logout",
    icon: "icon-logout",
  },
];

export const fullNav = [
  {
    title: true,
    name: "Core",
    wrapper: {
      // optional wrapper object
      element: "span", // required valid HTML5 element tag
      attributes: {}, // optional valid JS object with JS API naming ex: { className: 'my-class', style: { fontFamily: 'Verdana' }, id: 'my-id'}
    },
    class: "text-center", // optional class names space delimited list for title item ex: 'text-center'
  },

  {
    name: "Home",
    url: "/portal/admin/home",
    icon: "icon-home",
    constraint: { level: 7 },
  },

  {
    name: "Devices",
    url: "/portal/devices",
    icon: "icon-screen-tablet",
    constraint: { level: 7 },
  },
  {
    name: "Users",
    url: "/portal/users/list",
    icon: "icon-people",
    constraint: { level: 5 },
  },
  {
    name: "Gateways",
    url: "/portal/gateways",
    icon: "icon-grid",
    constraint: { level: 5 },
  },
  {
    name: "Groups",
    url: "/portal/groups/list",
    icon: "icon-lock",
    constraint: { or: [{ level: 1 }, { permission: "read-groups" }] },
  },
  {
    title: true,
    name: "Device Configuration",
    wrapper: {
      // optional wrapper object
      element: "span", // required valid HTML5 element tag
      attributes: {}, // optional valid JS object with JS API naming ex: { className: 'my-class', style: { fontFamily: 'Verdana' }, id: 'my-id'}
    },
    class: "text-center", // optional class names space delimited list for title item ex: 'text-center'
    constraint: { level: 1 },
  },
  {
    name: "Device Types",
    url: "/portal/device/definitions",
    icon: "icon-grid",
    constraint: { level: 1 },
  },
  {
    name: "Device Fields",
    url: "/portal/device/field/definitions",
    icon: "icon-grid",
    constraint: { level: 1 },
  },
  {
    name: "Device Type Mapping",
    url: "/portal/device/auto-load/sensor-type-mapping",
    icon: "icon-map",
    constraint: { level: 1 },
  },
  {
    name: "Manufacturers",
    url: "/portal/data-management/manufacturers",
    icon: "icon-people",
    constraint: { level: 1 },
  },
  {
    name: "Batches",
    url: "/portal/data-management/batches",
    icon: "icon-folder-alt",
    constraint: { level: 1 },
  },

  {
    title: true,
    name: "Gateway Firmware",
    wrapper: {
      // optional wrapper object
      element: "span", // required valid HTML5 element tag
      attributes: {}, // optional valid JS object with JS API naming ex: { className: 'my-class', style: { fontFamily: 'Verdana' }, id: 'my-id'}
    },
    class: "text-center", // optional class names space delimited list for title item ex: 'text-center'
    constraint: { level: 1 },
  },

  {
    name: "WIFI Firmware",
    url: "#",
    icon: "icon-folder",
    constraint: { level: 1 },
    open: false,
    children: [
      {
        name: "Overview",
        url: "/portal/firmware/overview",
        icon: "icon-screen-tablet",
        constraint: { level: 1 },
      },
      {
        name: "Versions",
        url: "/portal/firmware/definitions",
        icon: "icon-screen-tablet",
        constraint: { level: 1 },
      },
      {
        name: "Associations",
        url: "/portal/firmware/links",
        icon: "icon-map",
        constraint: { level: 1 },
      },
      {
        name: "Release Managers",
        url: "/portal/firmware/releases",
        icon: "icon-map",
        constraint: { level: 1 },
      },
      {
        name: "Approvals",
        url: "/portal/firmware/approvals",
        icon: "icon-map",
        constraint: { level: 1 },
      },
    ],
  },

  {
    title: true,
    name: "MCU Firmware",
    wrapper: {
      // optional wrapper object
      element: "span", // required valid HTML5 element tag
      attributes: {}, // optional valid JS object with JS API naming ex: { className: 'my-class', style: { fontFamily: 'Verdana' }, id: 'my-id'}
    },
    class: "text-center", // optional class names space delimited list for title item ex: 'text-center'
    constraint: { level: 1 },
  },

  {
    name: "MCU Firmware",
    url: "#",
    icon: "icon-folder",
    constraint: { level: 1 },
    open: false,
    children: [
      {
        name: "Overview",
        url: "/portal/mcu/overview",
        icon: "icon-screen-tablet",
        constraint: { level: 1 },
      },
      {
        name: "Versions",
        url: "/portal/mcu/definitions",
        icon: "icon-screen-tablet",
        constraint: { level: 1 },
      },
      {
        name: "Associations",
        url: "/portal/mcu/links",
        icon: "icon-map",
        constraint: { level: 1 },
      },
      {
        name: "Release Managers",
        url: "/portal/mcu/releases",
        icon: "icon-map",
        constraint: { level: 1 },
      },
      {
        name: "Approvals",
        url: "/portal/mcu/approvals",
        icon: "icon-map",
        constraint: { level: 1 },
      },
    ],
  },

  {
    name: "CMS",
    url: "#",
    icon: "icon-book-open",
    constraint: { level: 1 },
    children: [
      {
        name: "Alerts",
        url: "/portal/cms/alerts",
        icon: "icon-alert",
        constraint: { level: 1 },
      },
      {
        name: "Data Stream",
        url: "/portal/cms/data-stream",
        icon: "icon-tablet",
        constraint: { level: 1 },
      },
    ],
  },

  {
    title: true,
    name: "Devops",
    wrapper: {
      // optional wrapper object
      element: "span", // required valid HTML5 element tag
      attributes: {}, // optional valid JS object with JS API naming ex: { className: 'my-class', style: { fontFamily: 'Verdana' }, id: 'my-id'}
    },
    class: "text-center", // optional class names space delimited list for title item ex: 'text-center'
    constraint: { level: 1 },
  },

  {
    name: "System Health",
    url: "#",
    icon: "icon-folder",
    constraint: { level: 1 },
    children: [
      {
        name: "Overview",
        url: "/portal/system-health/overview",
        icon: "icon-map",
        constraint: { level: 1 },
      },

      {
        name: "TimeScale",
        url: "/portal/system-health/timescale",
        icon: "icon-map",
        constraint: { level: 1 },
      },

      {
        name: "Nodes",
        url: "/portal/system-health/nodes",
        icon: "icon-map",
        constraint: { level: 1 },
      },

      {
        name: "Services",
        url: "/portal/system-health/services",
        icon: "icon-map",
        constraint: { level: 1 },
      },

      {
        name: "Workers",
        url: "/portal/system-health/workers",
        icon: "icon-map",
        constraint: { level: 1 },
      },

      {
        name: "Telemetry",
        url: "/portal/system-health/telemetry",
        icon: "icon-map",
        constraint: { level: 1 },
      },

      {
        name: "User Reports",
        url: "/portal/system-health/user-reports",
        icon: "icon-map",
        constraint: { level: 1 },
      },
    ],
  },

  {
    name: "Email",
    url: "#",
    icon: "icon-list",
    constraint: { level: 1 },
    children: [
      {
        name: "Email Templates",
        url: "/portal/email/templates",
        icon: "icon-book-open",
        constraint: { level: 1 },
      },
      {
        name: "Email Queue",
        url: "/portal/email/queue",
        icon: "icon-list",
        constraint: { level: 1 },
      },
    ],
  },

  {
    name: "Reports and Logs",
    url: "#",
    icon: "icon-magnifier",
    constraint: { level: 1 },
    children: [
      {
        name: "General Reporting",
        url: "/portal/reporting/general",
        icon: "icon-magnifier",
        constraint: { level: 1 },
      },
      {
        name: "Audit History",
        url: "/portal/reporting/audit-history",
        icon: "icon-magnifier",
        constraint: { level: 1 },
      },
      {
        name: "Duplicate Devices",
        url: "/portal/devices/duplicates",
        icon: "icon-reload",
        constraint: { level: 1 },
      },
    ],
  },

  {
    title: true,
    name: "Data Management",
    wrapper: {
      // optional wrapper object
      element: "span", // required valid HTML5 element tag
      attributes: {}, // optional valid JS object with JS API naming ex: { className: 'my-class', style: { fontFamily: 'Verdana' }, id: 'my-id'}
    },
    class: "text-center", // optional class names space delimited list for title item ex: 'text-center'
    constraint: { level: 1 },
  },

  {
    name: "Data Management",
    url: "#",
    icon: "icon-folder",
    constraint: { level: 1 },
    children: [
      {
        name: "Sync Queue",
        url: "/portal/data-management/synch-queue",
        icon: "icon-options",
        constraint: { level: 1 },
      },
      {
        name: "Entity Archive",
        url: "/portal/data-management/entity-archive",
        icon: "icon-list",
        constraint: { level: 1 },
      },
      {
        name: "Wipfli Sync Queue",
        url: "/portal/data-management/wipfli/synch-queue",
        icon: "icon-verticle",
        constraint: { level: 1 },
      },
      {
        name: "Subscriptions",
        url: "/portal/data-management/subscriptions",
        icon: "icon-verticle",
        constraint: { level: 1 },
      },
      {
        name: "Verification & PSK Upload",
        url: "/portal/data-management/upload",
        icon: "icon-cloud-upload",
        constraint: { level: 1 },
      },
    ],
  },

  {
    name: "Auto Load",
    url: "#",
    icon: "icon-puzzle",
    constraint: { level: 1 },
    children: [
      {
        name: "Device Lookup Table",
        url: "/portal/device/auto-load/lookup-table",
        icon: "icon-map",
        constraint: { level: 1 },
      },
      {
        name: "Gateway Lookup Table",
        url: "/portal/gateway/auto-load/lookup-table",
        icon: "icon-map",
        constraint: { level: 1 },
      },
    ],
  },

  {
    name: "Tools",
    url: "/portal/tools/utility",
    icon: "icon-paper-clip",
    constraint: { level: 1 },
  },
  {
    name: "App Notifications",
    url: "/portal/app-notifications",
    icon: "icon-bubbles",
    constraint: { permission: "read-appNotifications" },
  },
  {
    title: true,
    name: "View-Admin Account",
    wrapper: {
      // optional wrapper object
      element: "span", // required valid HTML5 element tag
      attributes: {}, // optional valid JS object with JS API naming ex: { className: 'my-class', style: { fontFamily: 'Verdana' }, id: 'my-id'}
    },
    class: "text-center", // optional class names space delimited list for title item ex: 'text-center'
  },
  {
    name: "Log Out",
    url: "/logout",
    icon: "icon-logout",
  },
];

export const navItems = [
  {
    title: true,
    name: "Dashboard",
    wrapper: {
      // optional wrapper object
      element: "span", // required valid HTML5 element tag
      attributes: {}, // optional valid JS object with JS API naming ex: { className: 'my-class', style: { fontFamily: 'Verdana' }, id: 'my-id'}
    },
    class: "text-center", // optional class names space delimited list for title item ex: 'text-center'
  },
  {
    name: "Devices",
    url: "/portal/devices",
    icon: "icon-speedometer",
  },
  {
    title: true,
    name: "Theme",
    wrapper: {
      element: "span",
    },
    class: "text-center",
  },
  {
    name: "Colors",
    url: "/theme/colors",
    icon: "icon-drop",
  },
  {
    name: "Typography",
    url: "/theme/typography",
    icon: "icon-pencil",
  },
  {
    title: true,
    name: "Components",
    wrapper: {
      element: "span",
    },
    class: "text-center",
  },
  {
    name: "Base",
    url: "/base",
    icon: "icon-puzzle",
    children: [
      {
        name: "Cards",
        url: "/base/cards",
        icon: "icon-puzzle",
      },
      {
        name: "Carousels",
        url: "/base/carousels",
        icon: "icon-puzzle",
      },
      {
        name: "Collapses",
        url: "/base/collapses",
        icon: "icon-puzzle",
      },
      {
        name: "Pagination",
        url: "/base/paginations",
        icon: "icon-puzzle",
      },
      {
        name: "Popovers",
        url: "/base/popovers",
        icon: "icon-puzzle",
      },
      {
        name: "Progress",
        url: "/base/progress",
        icon: "icon-puzzle",
      },
      {
        name: "Switches",
        url: "/base/switches",
        icon: "icon-puzzle",
      },
      {
        name: "Tabs",
        url: "/base/tabs",
        icon: "icon-puzzle",
      },
      {
        name: "Tooltips",
        url: "/base/tooltips",
        icon: "icon-puzzle",
      },
    ],
  },
  {
    name: "Buttons",
    url: "/buttons",
    icon: "icon-cursor",
    children: [
      {
        name: "Buttons",
        url: "/buttons/buttons",
        icon: "icon-cursor",
      },
      {
        name: "Dropdowns",
        url: "/buttons/dropdowns",
        icon: "icon-cursor",
      },
      {
        name: "Loading Buttons",
        url: "/buttons/loading-buttons",
        icon: "icon-cursor",
      },
      {
        name: "Social Buttons",
        url: "/buttons/social-buttons",
        icon: "icon-cursor",
      },
    ],
  },
  {
    name: "Charts",
    url: "/charts",
    icon: "icon-pie-chart",
  },
  {
    name: "Editors",
    url: "/editors",
    icon: "fa fa-code",
    children: [
      {
        name: "Text Editors",
        url: "/editors/text-editors",
        icon: "icon-note",
      },
      {
        name: "Code Editors",
        url: "/editors/code-editors",
        icon: "fa fa-code",
      },
    ],
  },
  {
    name: "Forms",
    url: "/forms",
    icon: "icon-note",
    children: [
      {
        name: "Basic Forms",
        url: "/forms/basic-forms",
        icon: "icon-note",
      },
      {
        name: "Advanced Forms",
        url: "/forms/advanced-forms",
        icon: "icon-note",
      },
      {
        name: "Validation",
        url: "/forms/validation-forms",
        icon: "icon-note",
        badge: {
          variant: "danger",
          text: "PRO",
        },
      },
    ],
  },
  {
    name: "Google Maps",
    url: "/google-maps",
    icon: "icon-map",
    badge: {
      variant: "info",
      text: "NEW",
    },
  },
  {
    name: "Icons",
    url: "/icons",
    icon: "icon-star",
    children: [
      {
        name: "Flags",
        url: "/icons/flags",
        icon: "icon-star",
        badge: {
          variant: "success",
          text: "NEW",
        },
      },
      {
        name: "Font Awesome",
        url: "/icons/font-awesome",
        icon: "icon-star",
        badge: {
          variant: "secondary",
          text: "4.7",
        },
      },
      {
        name: "Simple Line Icons",
        url: "/icons/simple-line-icons",
        icon: "icon-star",
      },
    ],
  },
  {
    name: "Notifications",
    url: "/notifications",
    icon: "icon-bell",
    children: [
      {
        name: "Alerts",
        url: "/notifications/alerts",
        icon: "icon-bell",
      },
      {
        name: "Modals",
        url: "/notifications/modals",
        icon: "icon-bell",
      },
      {
        name: "Toastr",
        url: "/notifications/toastr",
        icon: "icon-bell",
      },
    ],
  },
  {
    name: "Plugins",
    url: "/plugins",
    icon: "icon-energy",
    children: [
      {
        name: "Calendar",
        url: "/plugins/calendar",
        icon: "icon-calendar",
      },

      {
        name: "Draggable Cards",
        url: "/plugins/draggable-cards",
        icon: "icon-cursor-move",
      },
      {
        name: "Spinners",
        url: "/plugins/spinners",
        icon: "fa fa-spinner",
      },
    ],
  },
  {
    name: "Tables",
    url: "/tables",
    icon: "icon-list",
    children: [
      {
        name: "DataTable",
        url: "/tables/datatable",
        icon: "icon-list",
      },
      {
        name: "Standard Tables",
        url: "/tables/tables",
        icon: "icon-list",
      },
    ],
  },
  {
    name: "Widgets",
    url: "/widgets",
    icon: "icon-calculator",
    badge: {
      variant: "info",
      text: "NEW",
    },
  },
  {
    divider: true,
  },
  {
    title: true,
    name: "Extras",
    wrapper: {
      element: "span",
    },
    class: "text-center",
  },
  {
    name: "Pages",
    url: "/pages",
    icon: "icon-star",
    children: [
      {
        name: "Login",
        url: "/pages/login",
        icon: "icon-star",
      },
      {
        name: "Register",
        url: "/pages/register",
        icon: "icon-star",
      },
      {
        name: "Error 404",
        url: "/pages/404",
        icon: "icon-star",
      },
      {
        name: "Error 500",
        url: "/pages/500",
        icon: "icon-star",
      },
    ],
  },
  {
    name: "UI Kits",
    url: "/uikits",
    icon: "icon-layers",
    children: [
      {
        name: "Invoicing",
        url: "/uikits/invoicing",
        icon: "icon-speech",
        children: [
          {
            name: "Invoice",
            url: "/uikits/invoicing/invoice",
            icon: "icon-speech",
          },
        ],
      },
      {
        name: "Email",
        url: "/uikits/email",
        icon: "icon-speech",
        children: [
          {
            name: "Inbox",
            url: "/uikits/email/inbox",
            icon: "icon-speech",
          },
          {
            name: "Message",
            url: "/uikits/email/message",
            icon: "icon-speech",
          },
          {
            name: "Compose",
            url: "/uikits/email/compose",
            icon: "icon-speech",
          },
        ],
      },
    ],
  },
];
