import { HttpClient } from "@angular/common/http";
import { LacrosseEntity } from "./lacrosse.entity";
import { FirebaseAuthService } from "../noizu/services/firebase-auth.service";
import {
  AttributeFieldType,
  StreamingPolicyFieldType,
  DeviceDefinitionEntity,
  GatewayDiagnosticFieldType,
} from "./device/definition.entity";
import { UserRepo } from "../repos/user.repo";
import { DeviceStatusEntity } from "./device-status.entity";
import { DataStreamRepo } from "../repos/data-stream.repo";
import { DeviceAlarmRepo } from "../repos/device/alarm.repo";
import { EntityLogSet } from "./log/entity-log.set";
import { RequestLogSet } from "./log/request-log.set";
import { DataStreamCardRepo } from "../repos/data-stream-card.repo";
import { DataStreamCardEntity } from "./data-stream-card.entity";
import { GatewayWifiAndMcuFirmwareReport } from "./gateway/wifi-and-mcu-firmware.report.entity";
import { DeviceWidgetEnum, WidgetEnum } from "../enums";
import { DeviceLinkStruct } from "./device";
import { ExtendedDeviceDetails } from "../structs/device/extended-device-details";
import { environment } from "../../environments/environment";

export class DeviceEntity extends LacrosseEntity {
  public _kind = "device";
  public _sref_module = "appengine.device";
  public extended: ExtendedDeviceDetails = null;
  public deviceLogs: EntityLogSet = null;
  public gatewayLogs: EntityLogSet = null;
  public requestLogs: RequestLogSet = null;

  public permissionEntries = null;
  public users = null;
  protected claimsLoaded = false;
  public claims = null;
  public forecast = null;
  public isfact23;
  public type: DeviceDefinitionEntity;
  public series: string;
  public serial: string;
  public batch: number;
  public manufacturer: number;
  public geo: any;
  public attributes: any;
  public policy: any;
  public gateway: any;
  public internalAttributes: any;
  public fields: any;
  public permissions: any;
  public category: any;
  public sensorTypeEntityId: number;
  public verificationCode: string;
  public linkedSensors: any;
  public hasRain = false;
  public internals: any;
  public dataStream = { enabled: 0, cards: null };
  public dsRepo = null;

  public dataStreamV2 = { enabled: 0, cards: null };
  public dataStreamRepo: DataStreamCardRepo = null;

  public alarmData = null;
  public firmwareReport: GatewayWifiAndMcuFirmwareReport = null;
  protected _attributeList: any;
  public _policyList: any;
  public _gatewayDiagList: any;
  public pending = false;

  public get status(): DeviceStatusEntity | null {
    return this.extended.status;
  }
  public set status(value: DeviceStatusEntity | null) {
    this.extended.status = value;
  }

  private fieldTypes = {
    factory: AttributeFieldType.ManufactureField,
    display: AttributeFieldType.BooleanField,
    "data-stream": AttributeFieldType.BooleanField,
    "device-glyph": AttributeFieldType.NumericField,
  };

  private policyFieldTypes = {
    web_debug_level: StreamingPolicyFieldType.NumericField,
    wakeup_level: StreamingPolicyFieldType.NumericField,
    sp_op_size: StreamingPolicyFieldType.NumericField,
    sp_level: StreamingPolicyFieldType.NumericField,
    relay_level: StreamingPolicyFieldType.NumericField,
    op_level: StreamingPolicyFieldType.NumericField,
    diagnostics_level: StreamingPolicyFieldType.NumericField,
  };

  constructor(client: HttpClient, auth: FirebaseAuthService, json) {
    super(client, auth, json);
    this.initialize(client, auth, json);
  }

  async initialize(client: HttpClient, auth: FirebaseAuthService, json) {
    this.requestLogs = new RequestLogSet(client, auth, []);
    if (this.attributes["display"] == "1") {
      this.requestLogs.type = "gateway";
      this.requestLogs.subject = `ref.gateway.${this.serial}@${this.series}`;
    } else {
      this.requestLogs.type = "device";
      this.requestLogs.subject = this.elixirSref();
    }

    this.gatewayLogs = new EntityLogSet(client, auth, null, []);
    this.deviceLogs = new EntityLogSet(client, auth, null, []);

    this.verificationCode =
      json["verificationCode"] || json["verification_code"];
    if (json && json.fields && json.fields["Rain"]) {
      this.hasRain = true;
    }
    this.strip_from_json["type"] = 1;
    this.strip_from_json["status"] = 1;
    this.strip_from_json["permissionEntries"] = 1;
    this.strip_from_json["logs"] = 1;
    this.strip_from_json["users"] = 1;
    this.strip_from_json["_attributeList"] = 1;
    this.strip_from_json["attributeList"] = 1;
    this.strip_from_json["_attributeList"] = 1;
    this.strip_from_json["claims"] = 1;
    this.strip_from_json["claimsLoaded"] = 1;
    this.strip_from_json["dataStream"] = 1;
    this.strip_from_json["dataStreamRepo"] = 1;
    this.strip_from_json["dataStreamV2"] = 1;
    this.strip_from_json["deviceLogs"] = 1;
    this.strip_from_json["dsRepo"] = 1;
    this.strip_from_json["gatewayLogs"] = 1;
    this.strip_from_json["internals"] = 1;
    this.strip_from_json["extended"] = 1;

    if (json["type"] && !this.isString(json["type"])) {
      this.type = new DeviceDefinitionEntity(client, auth, json["type"]);
    } else if (json["device_type"] && !this.isString(json["device_type"])) {
      this.type = new DeviceDefinitionEntity(client, auth, json["device_type"]);
    } else {
      this.type = null;
    }
    this.extended = new ExtendedDeviceDetails(client, auth, {
      identifier: this.identifier,
      is_gateway: this.attributes["display"] == "1",
      device: this.elixirSref(),
      device_type: this.type,
      attributes: json["attributes"],
    });
    this.meta.attribute_changes = {};
    this.dsRepo = new DataStreamRepo(this.client, this.auth); // deprecated
    this.dataStreamRepo = new DataStreamCardRepo(this.client, this.auth);
  }

  elixirSref() {
    return `ref.device.${this.serial}@${this.series}`;
  }

  addCard(activeUser) {
    const feed = this.elixirSref();
    if (this.claims && this.claims.items && this.claims.items.length > 0) {
      const owner = `ref.user.${this.claims.items[0].ownerId}`;
      const card = new DataStreamCardEntity(this.client, this.auth, {
        kind: "GoldenRatio.DataStream.Card.MediaCard.V1_1",
        "enabled?": true,
        feed: feed,
        owner: owner,
      });
      card.new = true;
      this.dataStreamV2.cards.push(card);
    } else {
      if (this.claimsLoaded) {
        const owner = activeUser ? activeUser.sref() : null;
        const card = new DataStreamCardEntity(this.client, this.auth, {
          kind: "GoldenRatio.DataStream.Card.MediaCard.V1_1",
          "enabled?": true,
          feed: feed,
          owner: owner,
        });
        card.new = true;
        this.dataStreamV2.cards.push(card);
      } else {
        this.getClaimsPromise().then((r) => {
          if (
            this.claims &&
            this.claims.items &&
            this.claims.items.length > 0
          ) {
            const owner = `ref.user.${this.claims.items[0].ownerId}`;
            const card = new DataStreamCardEntity(this.client, this.auth, {
              kind: "GoldenRatio.DataStream.Card.MediaCard.V1_1",
              "enabled?": true,
              feed: feed,
              owner: owner,
            });
            card.new = true;
            this.dataStreamV2.cards.push(card);
          } else {
            const owner = activeUser ? activeUser.sref() : null;
            const card = new DataStreamCardEntity(this.client, this.auth, {
              kind: "GoldenRatio.DataStream.Card.MediaCard.V1_1",
              "enabled?": true,
              feed: feed,
              owner: owner,
            });
            card.new = true;
            this.dataStreamV2.cards.push(card);
          }
        });
      }
    }
  }

  refreshCardWeights() {
    const ref = this.elixirSref();
    return this.dataStreamRepo.getListPromise(ref).then((cards: any) => {
      if (cards) {
        const lookup: any = {};
        for (const index in cards) {
          const card: DataStreamCardEntity = cards[index];
          lookup[card.identifier] = card;
        }
        for (const index in this.dataStreamV2.cards) {
          if (this.dataStreamV2.cards[index].identifier in lookup) {
            if (this.dataStreamV2.cards[index].has_changes) {
              this.dataStreamV2.cards[index].weight =
                lookup[this.dataStreamV2.cards[index].identifier].weight;
            } else {
              this.dataStreamV2.cards[index].weight =
                lookup[this.dataStreamV2.cards[index].identifier].weight;
            }
          }
        }
      }
    });
  }

  putCardAfter(cardA: DataStreamCardEntity, cardB: DataStreamCardEntity) {
    const p = this.dataStreamRepo.putCardAfter(cardA, cardB);
    p.then((r) => {
      this.refreshCardWeights();
    });
    return p;
  }

  putCardBefore(cardA: DataStreamCardEntity, cardB: DataStreamCardEntity) {
    const p = this.dataStreamRepo.putCardBefore(cardA, cardB);
    p.then((r) => {
      this.refreshCardWeights();
    });
    return p;
  }

  getPermissionEntriesPromise() {
    return this._get(
      `${this.apiBase()}/sensor/${this.identifier}/permissions`,
      (data, resolve) => {
        data.items.reverse();
        let res = false;
        for (const item of data["items"]) {
          item["loading"] = false;
          const everyone =
            item["handle"].indexOf("everyone") !== -1 ? true : false;
          if (item["handle"].indexOf("admin") !== -1 || everyone) {
            item["system"] = true;
          } else {
            item["system"] = false;
          }
          if (everyone) {
            if (item["permissions"]["claim"] === 1) {
              res = true;
            }
          }
        }
        this.permissionEntries = data;
        resolve(res);
      },
      {}
    );
  }

  getEnvironmentTargetPromise() {
    return this._get(
      `${this.ingvEndpoint()}/admin-tools/gateway/ref.gateway.${this.serial}@${
        this.series
      }/target-stage`,
      (data, resolve) => {
        this.extended.environment_target.gateway = `ref.gateway.${this.serial}@${this.series}`;
        if (data["outcome"]) {
          this.extended.environment_target.loading = false;
          this.extended.environment_target.error = false;
          this.extended.environment_target.target_stage =
            data["value"] == "stage";
        } else {
          this.extended.environment_target.loading = false;
          this.extended.environment_target.error = true;
        }
        resolve(true);
      }
    );
  }

  getFirmwareReportPromise() {
    return this._get(
      `${this.ingvEndpoint()}/admin-tools/gateway/ref.gateway.${this.serial}@${
        this.series
      }/firmware-info`,
      (data, resolve) => {
        this.firmwareReport = new GatewayWifiAndMcuFirmwareReport(
          this.client,
          this.auth,
          data
        );

        if (
          this.firmwareReport &&
          this.firmwareReport.wifi &&
          !this.firmwareReport.wifi.gateway
        ) {
          this.firmwareReport.wifi.gateway = `ref.gateway.${this.serial}@${this.series}`;
        }
        if (
          this.firmwareReport &&
          this.firmwareReport.mcu &&
          !this.firmwareReport.mcu.gateway
        ) {
          this.firmwareReport.mcu.gateway = `ref.gateway.${this.serial}@${this.series}`;
        }

        resolve(this.firmwareReport);
      }
    );
  }

  getUsersPromise() {
    const userRepo = new UserRepo(this.client, this.auth);
    const p = userRepo.listForSensorPromise(this.identifier);
    p.then((response) => {
      this.users = response;
    });
    return p;
  }

  getChartDataPromise(field, time) {
    const url = `${this.ingvEndpoint()}/active-user/device-association/ref.device.${
      this.identifier
    }/feed`;
    const query_params = `tz=America/Chicago&from=-${time.length}&aggregates=${time.interval}&fields=${field}&to=+1h&null_values=true`;
    return this._get(
      `${url}?${query_params}`,
      (data, resolve) => {
        resolve(data);
      },
      {}
    );
  }

  deletePermissionEntry(id, gid) {
    return this._delete(
      `${this.apiBase()}/group/${gid}/permissions?delete=${id}`,
      (data, resolve) => {
        resolve(data);
      },
      {}
    );
  }

  getGatewayDiagList() {
    const gatewayApiUrl = `${this.ingvNewEndpoint()}/admin-tools/gateway/diagnostics/${
      this.serial
    }/${this.series}`;
    this.auth
      .getTokenPromise()
      .then((token) => {
        const headers = {
          Authorization: `Bearer ${token}`,
        };
        return new Promise((resolve) => {
          this._get(
            gatewayApiUrl,
            (response) => {
              this.gateway = response;
              this.updateGatewayDiagList();
              resolve(response);
            },
            { headers }
          ).catch((error) => {
            this.gateway = error.details.error;
            this.updateGatewayDiagList();
            resolve(error);
          });
        });
      })
      .catch((error) => {
        this.gateway = error.details.error;
        this.updateGatewayDiagList();
        return Promise.resolve(error);
      });
  }

  getPolicyList() {
    const policyApiUrl = `${this.ingvNewEndpoint()}/admin-tools/gateway/sp_op_settings/${
      this.serial
    }/${this.series}`;
    this.auth
      .getTokenPromise()
      .then((token) => {
        const headers = {
          Authorization: `Bearer ${token}`,
        };
        return new Promise((resolve) => {
          this._get(
            policyApiUrl,
            (response) => {
              this.policy = response;
              this.updatePolicyList();
              resolve(response);
            },
            { headers }
          ).catch((error) => {
            this.policy = error;
            this.updatePolicyList();
            resolve(error);
          });
        });
      })
      .catch((error) => {
        this.policy = error;
        this.updatePolicyList();
        return Promise.resolve(error);
      });
  }

  putUpdatePolicy(data) {
    return new Promise((resolve) => {
      const resultObject = data?._policyList.reduce((acc, { key, value }) => {
        acc[key] = value;
        return acc;
      }, {});

      const policyApiUrl = `${this.ingvNewEndpoint()}/admin-tools/gateway/sp_op_settings/${
        this.serial
      }/${this.series}`;

      this.auth
        .getTokenPromise()
        .then((token) => {
          const headers = {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          };

          this._put(
            policyApiUrl,
            resultObject,
            (response) => {
              this.policy = response;
              resolve({ success: true, data: response });
            },
            { headers }
          ).catch((error) => {
            resolve({ success: false, error });
          });
        })
        .catch((error) => {
          resolve({ success: false, error });
        });
    });
  }

  putResetPolicy(data = {}) {
    return new Promise((resolve) => {
      const policyApiUrl = `${this.ingvNewEndpoint()}/admin-tools/gateway/reset/sp_op_settings/${
        this.serial
      }/${this.series}`;
      this.auth
        .getTokenPromise()
        .then((token) => {
          const headers = {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          };
          this._put(
            policyApiUrl,
            data,
            (response) => {
              this.getPolicyList();
              resolve({ success: true, data: response });
            },
            { headers }
          ).catch((error) => {
            resolve({ success: false, error });
          });
        })
        .catch((error) => {
          resolve({ success: false, error });
        });
    });
  }

  private updatePolicyList() {
    this._policyList = [];
    if (this.policy) {
      for (const key in this.policy) {
        let value = this.policy[key];
        this._policyList.push({
          key: key,
          originalKey: key,
          value: value,
          type: this.policyType(),
        });
      }
      this._policyList = this._policyList.sort((a, b) =>
        a.key < b.key ? -1 : 1
      );
    }
  }

  updateGatewayDiagList() {
    this._gatewayDiagList = [];
    if (this.gateway) {
      for (const key in this.gateway) {
        let value = this.gateway[key];
        this._gatewayDiagList.push({
          key: key,
          originalKey: key,
          value: value,
          type: this.gatewayType(),
        });
      }
      this._gatewayDiagList = this._gatewayDiagList.sort((a, b) =>
        a.key < b.key ? -1 : 1
      );
    }
  }

  getStatusPromise() {
    this.meta.fetching_status = true;
    return new Promise((resolve, reject) => {
      this._get(
        `${this.ingvEndpoint()}/active-user/device-association/${this.elixirSref()}/status?tz=America/Chicago`,
        (data, r2) => {
          this.extended.status = new DeviceStatusEntity(data);
          this.meta.fetching_status = false;
          r2(data);
        }
      )
        .then((r) => {
          this.meta.fetching_status = false;
          resolve(r);
        })
        .catch((e) => {
          this._get(
            `${this.ingvEndpoint()}/admin-tools/device/${this.elixirSref()}/last-update`,
            (data, r2) => {
              this.extended.status = new DeviceStatusEntity(data["value"]);
              this.extended.status.fall_back = true;
              this.meta.fetching_status = false;
              r2(data["value"]);
            }
          )
            .then((r) => {
              this.meta.fetching_status = false;
              resolve(r);
            })
            .catch((e) => {
              this.meta.fetching_status = false;
              this.extended.status = new DeviceStatusEntity();
              reject(e);
            });
        });
    });
  }

  getLogsPromise(type) {
    this[type + "Logs"] = null;
    return this._get(
      `${this.ingvEndpoint()}/admin-tools/logs/ref.${type}.${this.serial}@${
        this.series
      }`,
      (data, resolve) => {
        this[type + "Logs"] = data;
        resolve(data);
      },
      {}
    );
  }

  getAlarmData() {
    const r = new DeviceAlarmRepo(this.client, this.auth);
    return new Promise((resolve, reject) => {
      r.getAlarm(this.serial).then((res: any) => {
        this.alarmData = res;
        resolve(true);
      });
    });
  }

  orderCards(cards) {
    return cards.sort((a, b) => {
      if (a.weight === b.weight) {
        return 0;
      }
      if (a.weight === null) {
        return 1;
      }
      if (b.weight === null) {
        return -1;
      }
      if (a.weight < b.weight) {
        return -1;
      }
      if (b.weight < a.weight) {
        return 1;
      }
      return 0;
    });
  }

  getDataStreamV2() {
    const ref = this.elixirSref();
    return this.dataStreamRepo.getListPromise(ref).then((cards: any) => {
      if (cards) {
        cards = this.orderCards(cards);
      }
      this.dataStreamV2.cards = cards;
      this.dataStreamV2.enabled = 0; // wip
    });
  }

  /* Due to a critical variable being named kind a function in domainObject ,it is 1000x easier to use a regular object instead of a domainObject */
  getDataStream() {
    return this.dsRepo.getdataStream(this.identifier).then((res: any) => {
      const cards = [];
      for (let i = 0; i < res[0]["cards"].length; i++) {
        const obj = res[0]["cards"][i];
        obj["enabled"] = obj["enabled?"];
        if (obj["enabled"]) {
          this.dataStream.enabled++;
        }
        cards.push(obj);
      }
      this.dataStream.cards = cards;
    });
  }

  getForecastData(i, version = "1.1") {
    if (this.isfact23) {
      return new Promise((resolve, reject) => {
        this.forecast = { error: true, msg: "NWS Display" };
        this.meta.fetching_forecast = false;
        resolve(null);
      });
    }

    this.meta.fetching_forecast = true;
    return this._get(
      `${this._ingv_endpoint}/v2.2/forecast/admin/id/${this.serial}?details=${i}&version=${version}`,
      (data, resolve) => {
        this.forecast = data;
        if (!this.isfact23) {
          if (data["Geo"] && data["Geo"]["anonymous"]) {
            this.geo = { anonymous: true };
          } else if (
            data["Location"] &&
            data["Location"]["PrimaryPostalCode"] &&
            data["Location"]["PrimaryPostalCode"] !== ""
          ) {
            this.geo = { zip: data["Location"]["PrimaryPostalCode"] };
          } else if (
            data["Geo"] &&
            data["Geo"]["zip"] &&
            data["Geo"]["zip"] !== ""
          ) {
            this.geo = { zip: data["Geo"]["zip"] };
          } else {
            this.geo = { anonymous: true };
          }
        }
        this.meta.fetching_forecast = false;
        resolve(data);
      },
      {}
    ).catch((e) => {
      this.meta.fetching_forecast = false;
      if (
        e &&
        e["details"] &&
        e.details["error"] &&
        this.isString(e.details.error)
      ) {
        if (e.details.error.indexOf("<anonymous>") !== -1) {
          // Todo populate faux forecast.
          this.forecast = { error: true, msg: "<anonymous>" };
        } else if (e.details.error.indexOf("Access Denied") !== -1) {
          if (environment.stage) {
            this.forecast = {
              error: true,
              msg: "Service Unavailable In Stage",
            };
          } else {
            this.forecast = { error: true, msg: "Forecast Access Denied" };
          }
        } else if (e.details.error.indexOf("Unexpected token N") > -1) {
          this.forecast = {
            error: true,
            msg: "Geo search error, most likely an invalid combination of Zip and Country",
          };
        } else {
          this.forecast = { error: true, msg: "Forecast Error" };
        }
      } else {
        this.forecast = { error: true, msg: "Forecast Parse Error" };
      }
    });
  }

  addNewDataStream() {
    const obj = {
      enabled: "false",
      kind: "Elixir.Ingressor.DataStream.Card.MediaCard.V1_1",
    };
    if (this.dataStream) {
      this.dataStream.cards.push(obj);
    } else {
      this.dataStream.cards = [obj];
    }
  }

  toggleDataStream(i) {
    return new Promise((resolve, reject) => {
      const st = this.dataStream.cards[i];
      st.enabled = !st.enabled;
      if (st.enabled) {
        if (this.dataStream.enabled < 3) {
          this.dsRepo
            .saveStream(this.identifier, st, false)
            .then((ret: any) => {
              st.enabled = ret["enabled?"];
              if (st.enabled) {
                this.dataStream.enabled++;
              }
              resolve({ success: true });
            });
        } else {
          st.enabled = false;
          resolve({ success: false, code: 3 });
        }
      } else {
        this.dsRepo.saveStream(this.identifier, st, false).then((ret: any) => {
          st.enabled = ret["enabled?"];
          if (!st.enabled) {
            this.dataStream.enabled--;
          }
          resolve({ success: true });
        });
      }
      resolve(true);
    });
  }

  enableLogsPromise(type, options: any = {}) {
    let url = `${this.ingvEndpoint()}/admin-tools/logs/ref.${type}.${
      this.serial
    }@${this.series}/enable-logs`;
    url =
      "retention_length" in options
        ? url +
          `?retention_length=${encodeURIComponent(options.retention_length)}`
        : url + "?retention_length=50";
    if ("retention_period" in options) {
      url =
        url +
        `&retention_period=${encodeURIComponent(options.retention_period)}`;
    }
    if ("persist" in options) {
      url = url + `&persist=${encodeURIComponent(options.persist)}`;
    }
    if ("level" in options) {
      url = url + `&level=${encodeURIComponent(options.level)}`;
    }

    return this._put(url, {}, (data, resolve) => {
      this[type + "Logs"] = data;
      resolve(data);
    });
  }

  disableLogsPromise(type) {
    return this._put(
      `${this.ingvEndpoint()}/admin-tools/logs/ref.${type}.${this.serial}@${
        this.series
      }/disable-logs`,
      {},
      (data, resolve) => {
        let anyEnabled = false;
        // tslint:disable-next-line:forin
        for (const t in data.settings.level) {
          anyEnabled = data.settings.level[t] ? true : anyEnabled;
        }
        this[type + "Logs"] = anyEnabled ? "Still active" : "Disabled";
        resolve(anyEnabled);
      }
    );
  }

  factoryReset(): Promise<Object> {
    return this._post(
      `${this.apiBase()}/admin.tools.factoryReset/${this.series}/${
        this.serial
      }`,
      {},
      (data, resolve) => {
        resolve(true);
      }
    );
  }

  permissionReset() {
    return this._post(
      `${this.apiBase()}/admin.tools.resetPermissions/${this.series}/${
        this.serial
      }`,
      {},
      (data, resolve) => {
        resolve(true);
      }
    );
  }

  resetRain(f, t) {
    let url =
      this.ingvEndpoint() +
      `/admin-tools/devices/${this.elixirSref()}/clear-rain?from=${f}`;
    if (t) {
      url += `&to=${t}`;
    }
    return this._put(url, {}, (data, resolve) => {
      resolve(true);
    });
  }

  getClaimsPromise() {
    return this._get(
      `${this.apiBase()}/sensor/${this.identifier}/claims`,
      (data, resolve) => {
        this.claims = data;
        this.claims.items = this.claims.items || [];

        this.claimsLoaded = true;
        resolve(true);
      },
      {}
    );
  }

  refresh(data) {
    const sr = super.refresh(data);
    if (data["type"]) {
      this.type = new DeviceDefinitionEntity(this.client, this.auth, data.type);
    } else if (data["device_type"]) {
      this.type = new DeviceDefinitionEntity(
        this.client,
        this.auth,
        data.device_type
      );
    } else {
      this.type = null;
    }
    this.series = data.series;
    this.serial = data.serial;
    this.batch = data.batch;
    this.manufacturer = data.manufacturer;
    this.geo = data.geo;
    this.permissions = data.permissions;
    this.category = data.category;
    this.sensorTypeEntityId = data.sensorTypeEntityId;
    this.verificationCode = data.verificationCode;

    const int = {};
    int["flagged_for_synch"] = data.flaggedForSynch;
    int["flagged_for_synch_vnext"] = data.flaggedForSynchVNext;
    int["last_synched"] = data.lastSynched;
    int["createdOn"] = data.createdOn;
    int["modifiedOn"] = data.modifiedOn;
    this.internals = int;
    if (
      data.attributes &&
      (data.attributes.factory === 23 || data.attributes.factory === "23")
    ) {
      this.isfact23 = true;
      this.forecast = { error: true, msg: "NWS Display" };
      this.meta.fetching_forecast = false;
    } else {
      this.isfact23 = false;
      // This should be done inside of a generic DeviceEntity load method!?!!!
      if (data.attributes && data.attributes["display"] == "1") {
        this.getForecastData(false);
      }
    }
    this.attributes = data.attributes || {};
    this.internalAttributes =
      data.internalAttributes || data.internal_attributes || {};

    const t = {};
    if (data.fields) {
      for (const f in data.fields) {
        if (f !== "NotSupported") {
          t[f] = data.fields[f];
        }
      }
      this.fields = t;
    }
    if (data["linkedSensors"]) {
      const ls = [];
      for (const x of data["linkedSensors"]) {
        if (x.sensorId !== this.identifier) {
          ls.push(new DeviceLinkStruct(x));
        }
      }
      this.linkedSensors = ls.length > 0 ? ls : [];
    } else {
      this.linkedSensors = [];
    }
    return sr;
  }

  saveAttributes(options = {}) {
    const payload = {
      internalAttributes: this.internalAttributes,
    };
    return this._put(
      `${this.apiBase()}/sensor/${this.identifier}`,
      payload,
      (response, resolve) => {
        resolve(new DeviceEntity(this.client, this.auth, response));
      },
      {}
    ).then((update: any) => {
      this.attributes = update.attributes || {};
      this.internalAttributes = update.internalAttributes || {};
      this.attributeList = null;
    });
  }

  saveVerificationCodePromise(options = {}) {
    const payload = {
      verificationCode: this.verificationCode,
    };
    return this._put(
      `${this.apiBase()}/sensor/${this.identifier}`,
      payload,
      (response, resolve) => {
        resolve(new DeviceEntity(this.client, this.auth, response));
      },
      {}
    );
  }

  saveBatchPromise(options = {}) {
    let payload = {
      batch: this.batch,
    };
    if (this.batch == null) {
      payload["unsetFields"] = ["batch"];
    }

    return this._put(
      `${this.apiBase()}/sensor/${this.identifier}`,
      payload,
      (response, resolve) => {
        resolve(new DeviceEntity(this.client, this.auth, response));
      },
      {}
    );
  }

  savePromise(options = {}) {
    const payload = {
      verificationCode: this.verificationCode,
    };
    return this._put(
      `${this.apiBase()}/sensor/${this.identifier}`,
      payload,
      (response, resolve) => {
        resolve(new DeviceEntity(this.client, this.auth, response));
      },
      {}
    );
  }

  set attributeList(v) {
    this._attributeList = v;
  }

  get attributeList() {
    if (this._attributeList) return this._attributeList;
    this._attributeList = [];
    if (this.attributes) {
      for (const key in this.attributes) {
        let value = this.attributes[key];
        this._attributeList.push({
          key: key,
          originalKey: key,
          value: value,
          type: this.attributeType(key),
        });
      }
      this._attributeList = this._attributeList.sort((a, b) => a.key < b.key);
    }
    return this._attributeList;
  }

  public attributeType(key) {
    return this.fieldTypes[key] || AttributeFieldType.NumericField;
  }

  public policyType() {
    return StreamingPolicyFieldType.NumericField;
  }

  public gatewayType() {
    return GatewayDiagnosticFieldType.NumericField;
  }

  updateAttribute(attribute) {
    this.pending = true;
    delete this.attributes[attribute.originalKey];
    delete this.internalAttributes[attribute.originalKey];

    this.attributes[attribute.key] = attribute.value;
    this.internalAttributes[attribute.key] = attribute.value;

    this._attributeList = null;
    this.attributeList;
  }

  addAttribute(attribute) {
    this.pending = true;
    this.attributes[attribute.key] = attribute.value;
    this.internalAttributes[attribute.key] = attribute.value;
    this._attributeList = null;
    this.attributeList;
  }

  dropAttribute(attribute) {
    this.pending = true;
    delete this.attributes[attribute.key];
    delete this.internalAttributes[attribute.key];
    this._attributeList = null;
    this.attributeList;
  }

  set policyList(p) {
    this._policyList = p;
  }

  get policyList() {
    if (this._policyList) return this._policyList;
    this._policyList = [];
    if (this.policy == undefined) {
      this.getPolicyList();
    }
    if (this.policy) {
      for (const key in this.policy) {
        let value = this.policy[key];
        this._policyList.push({
          key: key,
          originalKey: key,
          value: value,
          type: this.policyType(),
        });
      }
      this._policyList = this._policyList.sort((a, b) => a.key < b.key);
    }
    return this._policyList;
  }

  set gatewayList(p) {
    this._gatewayDiagList = p;
  }

  get gatewayList() {
    if (this._gatewayDiagList) return this._gatewayDiagList;
    this._gatewayDiagList = [];
    if (this.gateway == undefined) {
      this.getGatewayDiagList();
    }
    if (this.gateway) {
      for (const key in this.gateway) {
        let value = this.gateway[key];
        this._gatewayDiagList.push({
          key: key,
          originalKey: key,
          value: value,
          type: this.gatewayType(),
        });
      }
      this._gatewayDiagList = this._gatewayDiagList.sort(
        (a, b) => a.key < b.key
      );
    }
    return this._gatewayDiagList;
  }

  updatePolicy(p) {
    this.pending = true;
    delete this.policy[p.originalKey];
    this.policy[p.key] = p.value;
    if (this._policyList) {
      const index = this._policyList.findIndex(
        (item) => item.originalKey === p.originalKey
      );
      if (index !== -1) {
        this._policyList[index].value = p.value;
        this._policyList[index].key = p.key;
      } else {
        this._policyList.push({
          key: p.key,
          originalKey: p.key,
          value: p.value,
          type: this.policyType(),
        });
      }
    }
  }

  addPolicy(p) {
    this.pending = true;
    this.attributes[p.key] = p.value;
    this._policyList = null;
    this._policyList;
  }

  dropPolicy(p) {
    this.pending = true;
    delete this.attributes[p.key];
    this._policyList = null;
    this._policyList;
  }

  widget_type() {
    return WidgetEnum.EMBED_WIDGET__DEVICE;
  }

  device_widget_type() {
    return DeviceWidgetEnum.DEVICE_WIDGET__ENTITY;
  }

  filter(filter: string) {
    if (filter) {
      if (this.meta["filter"] == filter) return this.meta["filter_result"];
      if (!this.meta["filter_set"]) {
        this.meta["filter_set"] = {};
        this.meta["filter_set"]["series"] = this.series || "NULL";
        this.meta["filter_set"]["serial"] = this.serial || "NULL";
        this.meta["filter_set"]["name"] =
          this.meta["filter_set"]["serial"] +
          "@" +
          this.meta["filter_set"]["series"];
        this.meta["filter_set"]["identifier"] = `${this.identifier || "NULL"}`;
        this.meta["filter_set"]["batch"] = `${this.batch || "NULL"}`;
        this.meta["filter_set"]["manufacturer"] =
          this.manufacturer == 14 ? "sino" : "fos";
        this.meta["filter_set"]["category"] = `${this.category || "NULL"}`;
        this.meta["filter_set"]["sensorTypeEntityId"] = `${
          this.sensorTypeEntityId || "NULL"
        }`;
        this.meta["filter_set"]["verificationCode"] = `${
          this.verificationCode || "NULL"
        }`;
      }

      this.meta["filter"] = filter;
      this.meta["filter_result"] = false;
      if (this.meta.filter_set.series.includes(filter))
        this.meta["filter_result"] = true;
      else if (this.meta.filter_set.serial.includes(filter))
        this.meta["filter_result"] = true;
      else if (this.meta.filter_set.name.includes(filter))
        this.meta["filter_result"] = true;
      else if (filter.includes("series:" + this.meta.filter_set.series))
        this.meta["filter_result"] = true;
      else if (filter.includes("serial:" + this.meta.filter_set.serial))
        this.meta["filter_result"] = true;
      else if (filter.includes("name:" + this.meta.filter_set.name))
        this.meta["filter_result"] = true;
      else if (filter.includes("name:" + this.meta.filter_set.serial))
        this.meta["filter_result"] = true;
      else if (filter.includes("identifier:" + this.meta.filter_set.serial))
        this.meta["filter_result"] = true;
      else if (filter.includes("id:" + this.meta.filter_set.serial))
        this.meta["filter_result"] = true;
      else if (filter.includes("identifier:" + this.meta.filter_set.identifier))
        this.meta["filter_result"] = true;
      else if (filter.includes("id:" + this.meta.filter_set.identifier))
        this.meta["filter_result"] = true;
      else if (filter.includes("batch:" + this.meta.filter_set.batch))
        this.meta["filter_result"] = true;
      else if (
        filter.includes("manufacturer:" + this.meta.filter_set.manufacturer)
      )
        this.meta["filter_result"] = true;
      else if (filter.includes("man:" + this.meta.filter_set.manufacturer))
        this.meta["filter_result"] = true;
      else if (filter.includes("category:" + this.meta.filter_set.category))
        this.meta["filter_result"] = true;
      else if (
        filter.includes("type:" + this.meta.filter_set.sensorTypeEntityId)
      )
        this.meta["filter_result"] = true;
      else if (filter.includes("vfc:" + this.meta.filter_set.verificationCode))
        this.meta["filter_result"] = true;
      else if (this.type && this.type.filter(filter))
        this.meta["filter_result"] = true;

      return this.meta.filter_result;
    } else {
      return true;
    }
  }
}
