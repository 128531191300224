
    <ng-container *ngIf="entity">
      <ng-container [ngSwitch]="entity.kind">
        <email-template-section-each-widget
          *ngSwitchCase="
            'Elixir.Noizu.EmailService.Email.Binding.Substitution.Dynamic.Formula.Each'
          "
          [entity]="entity"
          [layout]="layout"
          [options]="options"
        ></email-template-section-each-widget>
        <email-template-section-if-then-widget
          *ngSwitchCase="
            'Elixir.Noizu.EmailService.Email.Binding.Substitution.Dynamic.Formula.IfThen'
          "
          [entity]="entity"
          [layout]="layout"
          [options]="options"
        ></email-template-section-if-then-widget>
        <email-template-section-section-widget
          *ngSwitchCase="
            'Elixir.Noizu.EmailService.Email.Binding.Substitution.Dynamic.Section'
          "
          [entity]="entity"
          [layout]="layout"
          [options]="options"
        ></email-template-section-section-widget>
        <email-template-section-selector-widget
          *ngSwitchCase="
            'Elixir.Noizu.EmailService.Email.Binding.Substitution.Dynamic.Selector'
          "
          [entity]="entity"
          [layout]="layout"
          [options]="options"
        ></email-template-section-selector-widget>

        <ng-container *ngSwitchDefault>
          <div>[Unsupported: {{ entity.kind }}]</div>
        </ng-container>
      </ng-container>
    </ng-container>
  