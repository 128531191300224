import { Inject, Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { FirebaseAuthService } from "../noizu/services/firebase-auth.service";
import { AppengineRepo } from "./appengine.repo";
import { DeviceEntity } from "../entities/device.entity";

@Injectable()
export class DeviceRepo extends AppengineRepo {
  public _kind = "sensors";
  public _singular = "sensor";

  constructor(
    @Inject(HttpClient) client: HttpClient,
    @Inject(FirebaseAuthService) auth: FirebaseAuthService
  ) {
    super(client, auth);
  }

  entity(record) {
    return new DeviceEntity(this.client, this.auth, record);
  }

  getBySerialPromise(serial: string, options: any = {}, series = "V2") {
    if (this.auth.hasPermission("read-sensor", 7)) {
      return this._getEntityPromise(
        `${this.apiBase()}/sensorBySerial/${series.toUpperCase()}/${serial.toUpperCase()}`,
        (record, resolve) => {
          resolve(this.entity(record));
        },
        options
      );
    } else {
      return new Promise((reject) =>
        reject({ success: false, msg: "You do not have permission" })
      );
    }
  }
}
