import { NoizuStruct } from "../../../noizu/structs/noizu-struct";
import { DeviceCategoryEnum } from "../../../enums/device-category.enum";

export class DeviceDefinitionCategory extends NoizuStruct {
  public enum: any;
  public name: string;
  constructor(json: any) {
    super();
    this.name = DeviceCategoryEnum[json];
    this.enum = DeviceCategoryEnum[this.name];
  } // end constructor

  toJson(options = {}) {
    return { name: this.name, enum: this.enum };
  }
}
