import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ToasterService } from "angular2-toaster";
import { AlertProbeFacadeEntity } from "../../../../entities/alert";
import { AlertFormatEnum } from "../../../../enums";
import { AlertFacadeDefaultWidgetComponent } from "./default.widget.component";
import { AlertFacadeWidgetEventStruct } from "./alert-facade-widget-event.struct";

@Component({
  selector: "alert-facade-probe-widget",
  template: `
    <div class="card border-dark alert-facade-probe-widget">
      <!-- Card Header -->
      <div class="card-header bg-dark text-white">
        <h5 class="mb-0">Probe Alert</h5>
      </div>

      <!-- Card Body -->
      <div class="card-body">
        <!-- Device Section -->
        <div class="row mb-3 align-items-center">
          <div class="col-4 fw-bold">Device</div>
          <div class="col-8">
            <a
              [routerLink]="'/portal/devices/show/' + entity.device"
              class="text-primary text-decoration-none"
            >
              {{ entity.device }}
            </a>
          </div>
        </div>

        <!-- Association Section -->
        <div class="row mb-3 align-items-center">
          <div class="col-4 fw-bold">Association</div>
          <div class="col-8">{{ entity.deviceAssociation }}</div>
        </div>

        <!-- Field Section -->
        <div class="row mb-3 align-items-center">
          <div class="col-4 fw-bold">Field</div>
          <div class="col-8">{{ entity.field }}</div>
        </div>

        <!-- Divider -->
        <hr class="my-3" />

        <!-- Temperature Settings Section -->
        <div [ngSwitch]="temperatureFormat">
          <!-- Temperature Above Section -->
          <div *ngSwitchCase="FormatEnum.ValueAbove" class="row mb-3">
            <div class="col-5"><b>Temperature Above</b></div>
            <div *ngIf="!options?.edit" class="col-7">
              <b>{{ entity.temperatureAbove.value }}</b>
              <span> {{ entity.temperatureAbove.unit?.name }}</span>
            </div>
            <div *ngIf="options?.edit" class="col-7">
              <input
                type="number"
                [(ngModel)]="entity.temperatureAbove.value"
                (ngModelChange)="facadeChange($event)"
                class="form-control"
                name="temp_above"
              />
              <span> {{ entity.temperatureAbove.unit?.name }}</span>
            </div>
          </div>

          <!-- Temperature Below Section -->
          <div *ngSwitchCase="FormatEnum.ValueBelow" class="row mb-3">
            <div class="col-5"><b>Temperature Below</b></div>
            <div *ngIf="!options?.edit" class="col-7">
              <b>{{ entity.temperatureBelow.value }}</b>
              <span> {{ entity.temperatureBelow.unit?.name }}</span>
            </div>
            <div *ngIf="options?.edit" class="col-7">
              <input
                type="number"
                [(ngModel)]="entity.temperatureBelow.value"
                (ngModelChange)="facadeChange($event)"
                class="form-control"
                name="temp_below"
              />
              <span> {{ entity.temperatureBelow.unit?.name }}</span>
            </div>
          </div>

          <!-- Temperature Between Section -->
          <div *ngSwitchCase="FormatEnum.ValueBetween" class="row mb-3">
            <div class="col-5"><b>Temperature Between</b></div>
            <div *ngIf="!options?.edit" class="col-7">
              <b>{{ entity.temperatureAbove.value }}</b>
              <span> {{ entity.temperatureAbove.unit?.name }}</span> and
              <b>{{ entity.temperatureBelow.value }}</b>
              <span> {{ entity.temperatureBelow.unit?.name }}</span>
            </div>
            <div *ngIf="options?.edit" class="col-7">
              <input
                type="number"
                [(ngModel)]="entity.temperatureAbove.value"
                (ngModelChange)="facadeChange($event)"
                class="form-control mb-2"
                name="temp_above"
              />
              <span> {{ entity.temperatureAbove.unit?.name }}</span>
              and
              <input
                type="number"
                [(ngModel)]="entity.temperatureBelow.value"
                (ngModelChange)="facadeChange($event)"
                class="form-control mt-2"
                name="temp_below"
              />
              <span> {{ entity.temperatureBelow.unit?.name }}</span>
            </div>
          </div>

          <!-- Temperature Outside Section -->
          <div *ngSwitchCase="FormatEnum.ValueOutside" class="row mb-3">
            <div class="col-5"><b>Temperature Outside</b></div>
            <div *ngIf="!options?.edit" class="col-7">
              <b>{{ entity.temperatureBelow.value }}</b>
              <span> {{ entity.temperatureBelow.unit?.name }}</span> and
              <b>{{ entity.temperatureAbove.value }}</b>
              <span> {{ entity.temperatureAbove.unit?.name }}</span>
            </div>
            <div *ngIf="options?.edit" class="col-7">
              <input
                type="number"
                [(ngModel)]="entity.temperatureBelow.value"
                (ngModelChange)="facadeChange($event)"
                class="form-control mb-2"
                name="temp_below"
              />
              <span> {{ entity.temperatureBelow.unit?.name }}</span>
              and
              <input
                type="number"
                [(ngModel)]="entity.temperatureAbove.value"
                (ngModelChange)="facadeChange($event)"
                class="form-control mt-2"
                name="temp_above"
              />
              <span> {{ entity.temperatureAbove.unit?.name }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
})
export class EmbedAlertProbeFacadeComponent
  extends AlertFacadeDefaultWidgetComponent
  implements OnInit
{
  public editMode = false;
  @Input() layout: string = null;
  @Input() entity: AlertProbeFacadeEntity;
  @Input() options: any;
  @Input() deviceData: any = null;

  @Output() widgetEvent = new EventEmitter<AlertFacadeWidgetEventStruct>();
  forwardEvent(e, widgetEvent: AlertFacadeWidgetEventStruct) {
    e.preventDefault();
    this.widgetEvent.emit(widgetEvent);
  }
  temperatureFormat: AlertFormatEnum = AlertFormatEnum.Unknown;
  FormatEnum = AlertFormatEnum;

  constructor(public toasterService: ToasterService) {
    super();
  }
  setDeviceValues() {
    if (!this.options.edit) return;
    this.entity.device = this.deviceData.deviceId;
    this.entity.deviceAssociation = this.deviceData.associationId;
  }

  ngOnInit() {
    if (
      this.deviceData.deviceId !== undefined &&
      this.deviceData.associationId !== undefined
    ) {
      this.setDeviceValues();
    }
    this.setFormat();
  }

  setFormat() {
    if (this.entity.temperatureAbove && this.entity.temperatureBelow) {
      if (
        this.entity.temperatureAbove.value < this.entity.temperatureBelow.value
      ) {
        this.temperatureFormat = AlertFormatEnum.ValueBetween;
      } else {
        this.temperatureFormat = AlertFormatEnum.ValueOutside;
      }
    } else if (this.entity.temperatureAbove) {
      this.temperatureFormat = AlertFormatEnum.ValueAbove;
    } else if (this.entity.temperatureBelow) {
      this.temperatureFormat = AlertFormatEnum.ValueBelow;
    }
  }
}
