import { Component } from "@angular/core";
import { AuthService } from "../services/auth.service";
import { FirebaseAuthService } from "../noizu/services/firebase-auth.service";
import { ToasterConfig, ToasterService } from "angular2-toaster";
import { AdminRepo } from "../repos";
import { MaintenanceEntity } from "../entities";
import packageInfo from "../../../package.json";

@Component({
  selector: "authenticated-user",
  templateUrl: "./authenticated-user.component.html",
})
export class AuthenticatedUserComponent {
  private changes: MutationObserver;

  public timer: any = null;
  public sidebarMinimized = true;
  public element: HTMLElement = document.body;
  public disabled: false;
  public status: { isopen: boolean } = { isopen: false };
  public maintenance_mode: MaintenanceEntity | null = null;
  public currentYear = new Date().getFullYear();
  public toasterConfig = new ToasterConfig({
    tapToDismiss: true,
    timeout: 5000,
  });
  public version: string = packageInfo.version;

  constructor(
    public fAuth: FirebaseAuthService,
    public authService: AuthService,
    public toasterService: ToasterService,
    public admin: AdminRepo
  ) {
    this.maintenance_mode = new MaintenanceEntity();
    this.changes = new MutationObserver((mutations) => {
      this.sidebarMinimized =
        document.body.classList.contains("sidebar-minimized");
    });

    this.changes.observe(<Element>this.element, {
      attributes: true,
    });

    this.admin
      .getMaintenanceMode()
      .then((m: MaintenanceEntity) => (this.maintenance_mode = m));

    this.timer = setInterval(() => {
      this.admin
        .getMaintenanceMode()
        .then((m: MaintenanceEntity) => (this.maintenance_mode = m));
    }, 60000);
  }

  public toggled(open: boolean): void {
    console.log("Dropdown is now: ", open);
  }

  public toggleDropdown($event: MouseEvent): void {
    $event.preventDefault();
    $event.stopPropagation();
    this.status.isopen = !this.status.isopen;
  }

  logout() {
    this.authService.logout();
  }

  ngOnInit() {}
}
