import { Component, Input } from "@angular/core";

@Component({
  selector: "new-spinner",
  template: `
    <ng-container *ngIf="!inline">
      <div class="row">
        <div class="col text-center">
          <div class="new-spinner {{ spinner }} {{ size }}" role="status"></div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="inline">
      <div class="new-spinner {{ spinner }} {{ size }}" role="status"></div>
    </ng-container>
  `,
})
export class SpinnerComponent {
  @Input() spinner = "spinner-border";
  @Input() size = "md";
  @Input() inline = false;
  constructor() {}
}
