import { Component, NgZone } from "@angular/core";
import { DeviceLookupTableRepo } from "../../../repos/device/lookup-table.repo";
import { AppengineEntityList } from "../../../noizu/structs/appengine-entity-list";

@Component({
  templateUrl: "./lookup-table.component.html",
})
export class DeviceAutoLoadLookupTableComponent {
  public entries: AppengineEntityList;

  constructor(public repo: DeviceLookupTableRepo, public zone: NgZone) {
    this.zone.run(() => {
      this.repo.getListPromise().then((u: any) => {
        this.entries = u;
      });
    });
  }

  add(e) {
    e.preventDefault();
    this.entries.items.push(this.repo.entity({ id: "new" }));
  }
}
