<div *ngIf="!deleted">
  <div *ngIf="!(entry.identifier == 'new') && !editEntry">
    <div class="col-md-6 mb-4">
      <div class="card" style="width: 250%">
        <div class="card-header bg-light">
          <h5 class="card-title mb-1">{{ entry.name }}</h5>
          <p class="card-title card-text mb-3 mt-2">
            ID: {{ entry.identifier }}
          </p>
        </div>
        <div class="">
          <p class="card-header card-text">
            <strong>Handle:</strong> {{ entry.handle }}
          </p>
          <p class="card-header card-text">
            <strong>Description:</strong> {{ entry.description }}
          </p>
          <p class="card-header card-text">
            <strong>Code:</strong> {{ entry.code }}
          </p>
          <p class="card-header card-text">
            <strong>Features:</strong>
            <render-object [object]="entry.features"></render-object>
          </p>
          <p class="card-header card-text">
            <strong>Created On:</strong> {{ entry.createdOn | date : "medium" }}
          </p>
          <p class="card-header card-text">
            <strong>Modified On:</strong>
            {{ entry.modifiedOn | date : "medium" }}
          </p>
          <div class="d-flex justify-content-start">
            <button
              (click)="edit($event, entry)"
              class="btn btn-primary mt-2 mb-2 mx-3 mr-2"
            >
              <i class="fa fa-edit mx-1"></i>Edit
            </button>
            <button
              (click)="delete($event, entry)"
              class="btn btn-danger mt-2 mb-2 mr-2"
            >
              <i class="fa fa-trash"></i> Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="entry.identifier == 'new' || editEntry" class="card-body">
    <form class="card">
      <div class="form-group">
        <label for="name" class="mt-2 mx-3"><strong>Name</strong></label>
        <div class="col-12">
          <input
            type="text"
            class="form-control"
            id="name"
            [(ngModel)]="entry.name"
            name="name"
          />
        </div>
      </div>
      <div class="form-group">
        <label for="handle" class="mt-2 mx-3"><strong>Handle</strong></label>
        <div class="col-12">
          <input
            type="text"
            class="form-control"
            id="handle"
            [(ngModel)]="entry.handle"
            name="handle"
          />
        </div>
      </div>
      <div class="form-group">
        <label for="description" class="mt-2 mx-3"
          ><strong>Description</strong></label
        >
        <div class="col-12">
          <input
            type="text"
            class="form-control"
            id="description"
            [(ngModel)]="entry.description"
            name="description"
          />
        </div>
      </div>
      <div class="form-group">
        <label for="code" class="mt-2 mx-3"><strong>Code</strong></label>
        <div class="col-12">
          <input
            type="text"
            class="form-control"
            id="code"
            [(ngModel)]="entry.code"
            name="code"
          />
        </div>
      </div>
      <div class="form-group shadow-sm p-4">
        <div class="card-header bg-primary text-white">
          <h5 class="mb-0">Features</h5>
        </div>
        <div class="card-body">
          <div>
            <div class="col-12">
              <div class="mb-3">
                <div class="d-flex justify-content-between">
                  <label class="form-label fw-bold"
                    ><strong>Export Limit</strong></label
                  >
                  <div class="d-flex justify-content-start">
                    <input
                      type="checkbox"
                      style="margin-top: 2px"
                      class="form-check-input"
                      [checked]="exportMaxCheck(entry)"
                      (change)="toggleExportMax(entry)"
                      name="exportMaxUnit"
                      id="exportMaxUnit"
                    />
                    <label class="form-check-label" for="exportMaxUnit">
                      {{ exportMaxCheck(entry) ? "Years" : "Days" }}
                    </label>
                  </div>
                </div>
                <input
                  type="number"
                  class="form-control"
                  [(ngModel)]="exportMax"
                  name="exportMax"
                  placeholder="Enter max export limit"
                  (keypress)="allowOnlyNumbers($event)"
                  inputmode="numeric"
                />
              </div>
            </div>
            <div class="col-12">
              <div class="mb-3">
                <div class="d-flex justify-content-between">
                  <label class="form-label fw-bold"
                    ><strong>View Limit</strong></label
                  >
                  <div class="d-flex justify-content-start">
                    <input
                      type="checkbox"
                      style="margin-top: 2px"
                      class="form-check-input"
                      [checked]="viewMaxCheck(entry)"
                      (change)="toggleViewMax(entry)"
                      name="viewMaxUnit"
                      id="viewMaxUnit"
                    />
                    <label class="form-check-label" for="viewMaxUnit">
                      {{ viewMaxCheck(entry) ? "Years" : "Days" }}
                    </label>
                  </div>
                </div>
                <input
                  type="number"
                  class="form-control"
                  [(ngModel)]="viewMax"
                  name="viewMax"
                  placeholder="Enter max view limit"
                  (keypress)="allowOnlyNumbers($event)"
                  inputmode="numeric"
                />
              </div>
            </div>
          </div>

          <div class="mt-4">
            <h6 class="fw-bold"><strong>Attributes</strong></h6>
            <div class="row">
              <div class="col-md-6" *ngFor="let type of ['trial']">
                <div class="form-check d-flex align-items-center">
                  <input
                    type="checkbox"
                    style="margin-top: 0"
                    class="form-check-input"
                    [checked]="isAttributeChecked(type)"
                    (change)="toggleTrial(type)"
                    [name]="type"
                    [id]="type"
                  />
                  <label class="form-check-label" [for]="type">
                    {{ type | titlecase }}
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div class="mt-4">
            <h6 class="fw-bold"><strong>Notifications</strong></h6>
            <div class="row">
              <div
                class="col-md-6"
                *ngFor="let type of ['webhook', 'email', 'in-app', 'push']"
              >
                <div class="form-check d-flex align-items-center">
                  <input
                    type="checkbox"
                    style="margin-top: 0"
                    class="form-check-input"
                    [checked]="entry.features.notifications[type]"
                    (change)="onSelectionChange(type)"
                    [name]="type"
                    id="{{ type }}"
                  />
                  <label class="form-check-label" for="{{ type }}">
                    {{ type | titlecase }}
                  </label>
                </div>
              </div>
              <div
                *ngFor="let type of ['sms']"
                [ngClass]="
                  entry.sms_checked ? 'col-md-12 mt-3' : 'col-md-6 mt-1'
                "
              >
                <div
                  style="gap: 5%"
                  class="form-check d-flex align-items-center"
                >
                  <div class="d-flex justify-content-between">
                    <input
                      type="checkbox"
                      style="margin-top: 0"
                      class="form-check-input"
                      [checked]="entry.sms_checked"
                      (change)="onSelectionChange(type, $event)"
                      [name]="type"
                      [id]="type"
                    />
                    <label class="form-check-label" [for]="type">
                      {{ type | titlecase }}
                    </label>
                  </div>
                  <input
                    *ngIf="entry.sms_checked"
                    (keypress)="allowOnlyNumbers($event)"
                    inputmode="numeric"
                    class="form-control"
                    type="number"
                    (change)="onSelectionChange(type, $event)"
                    [(ngModel)]="entry.features.notifications[type]"
                    placeholder="Enter SMS Limit"
                  />
                </div>
                <p *ngIf="entry.sms_checked" class="text-center mt-2">
                  To allow unlimited sms, check the sms option and leave the
                  input empty.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <button
          *ngIf="editEntry"
          (click)="update($event, entry)"
          class="btn btn-success btn-sm mr-2"
        >
          <i class="fa fa-check"></i> Save
        </button>
        <button
          *ngIf="!editEntry"
          (click)="update($event, entry, 1)"
          class="btn btn-success btn-sm mr-2"
        >
          <i class="fa fa-check"></i> Save
        </button>
        <button
          *ngIf="editEntry"
          (click)="cancel($event)"
          class="btn btn-secondary btn-sm mr-2"
        >
          <i class="fa fa-times"></i> Cancel
        </button>
      </div>
    </form>
  </div>
</div>
