import { NoizuStruct } from "../../../../noizu/structs/noizu-struct";
import { ValueAndUnitUnit } from "../../../value-and-unit/unit";
import { AggregationLevel } from "../../../aggregation/level";
import { AggregationType } from "../../../aggregation/type";

export class FormulaTreeNodeContentProcessedField extends NoizuStruct {
  public nodeIdentifier: string;
  public aggregationCount: number;
  public aggregationLevel: string; // enum
  public aggregationType: string; // enum
  public asUnit: number;

  public ufaggregation_level: AggregationLevel; // enum
  public ufaggregation_type: AggregationType; // enum
  public ufas_unit: ValueAndUnitUnit;

  constructor(json: any) {
    super();
    this.strip_from_json["ufaggregation_level"] = 1;
    this.strip_from_json["ufaggregation_type"] = 1;
    this.strip_from_json["ufas_unit"] = 1;
    let al = json["aggregationLevel"] || json["aggregation_level"];
    let at = json["aggregationType"] || json["aggregation_type"];
    this.nodeIdentifier = json["nodeIdentifier"] || json["node_identifier"];
    this.ufaggregation_level = al ? new AggregationLevel(al) : null;
    this.aggregationCount =
      json["aggregationCount"] || json["aggregation_count"];
    this.ufaggregation_type = at ? new AggregationType(at) : null;
    this.aggregationType = at;
    this.aggregationLevel = al;
    this.asUnit = json["asUnit"];
  } // end constructor
}

/*

    public static class ProcessedField {

        public String nodeIdentifier;
        public Long aggregationLevel; // Tick, 1m, 5m, 15m, 30m, 60m, 24, 1 day, etc.  [required if aggregate]
        public Long aggregationCount; // 1,2,3 ... {:ticks, 1} == spot
        public Long aggregationType; // Low, High, Average, Tally  [ required if aggregate]
        public Long asUnit; // Cast to value. [optional]
    }
  */
