
    <page-header [section]="'psk-verification-code-upload'">
      <page-title>PSK & Verification Code Upload</page-title>
      <page-blurb
        >Upload hard-coded versions of different PSK and Verification
        codes.</page-blurb
      >
    </page-header>

    <div class="row" *ngIf="!overrideAccess()">
      <div class="col">
        <div class="alert alert-danger">
          <p class="font-3xl">
            To access PSK and +3 Verification Code Upload you must have
            firmware_admin permissions.
          </p>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="overrideAccess()">
      <!-- Verification Code Upload -->
      <div class="col">
        <data-management-widget
          (widgetEvent)="eventHandler($event, 'verificationCode')"
          [entity]="verificationCodeUpload"
          [options]="{ edit: true }"
          [layout]="'standard'"
        ></data-management-widget>
      </div>
      <!-- PSK Upload -->
      <div class="col">
        <data-management-widget
          (widgetEvent)="eventHandler($event, 'psk')"
          [entity]="pskUpload"
          [options]="{ edit: true }"
          [layout]="'standard'"
        ></data-management-widget>
      </div>
    </div>
  