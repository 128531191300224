import { Component, Input } from "@angular/core";
import { ChannelDefinitionEntity } from "../../entities/channel-definition.entity";
import { ChannelDefinitionRepo } from "../../repos/channel-definition.repo";

@Component({
  templateUrl: "./show.component.html",
})
export class ChannelDefinitionShowComponent {
  @Input() channelDefinition: ChannelDefinitionEntity = null;
  constructor(public repo: ChannelDefinitionRepo) {}
}
