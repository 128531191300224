import { Component, Input, Output, EventEmitter } from "@angular/core";
import { AlertConstraintStatusSet } from "../../../entities/alert";
import { WidgetBase } from "../widget-base";
import { AlertWidgetEventStruct } from "./alert-widget-event.struct";

@Component({
  selector: "alert-constraint-status-set-widget",
  template: `
    <set-widget
      [entity]="this.entity"
      [layout]="this.layout"
      [options]="options"
      [title]="'Alert Constraints'"
    ></set-widget>
  `,
})
export class AlertConstraintStatusSetWidgetComponent extends WidgetBase {
  @Input() entity: AlertConstraintStatusSet = null;
  @Input() options: any = null;
  @Input() layout: string = null;
  @Output() widgetEvent = new EventEmitter<AlertWidgetEventStruct>();
  forwardEvent(e, widgetEvent: AlertWidgetEventStruct) {
    e.preventDefault();
    this.widgetEvent.emit(widgetEvent);
  }

  public has_entries = false;
  constructor() {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.has_entries = this.hasEntries();
  }

  hasEntries() {
    if (this.entity) {
      return !!Object.keys(this.entity.entities).length;
    } else {
      return false;
    }
  }
}
