import { AlertFacadeEntity } from "../facade.entity";
import { ValueAndUnit } from "../../../structs/value-and-unit";
import { WidgetEnum_Alert_Facade } from "../../../enums/widget/alert";
import { cloneDeep } from "lodash";
import { AlertFormatEnum, UnitEnum } from "../../../enums";

export class AlertHumidityFacadeEntity extends AlertFacadeEntity {
  public humidityAbove: ValueAndUnit;
  public humidityBelow: ValueAndUnit;

  public constructor(alert, json) {
    super(alert, json);

    this.humidityAbove = null;
    if (json["humidityAbove"]) {
      this.humidityAbove = new ValueAndUnit(json["humidityAbove"]);
    }
    if (this.humidityAbove == null) {
      this.humidityAbove = new ValueAndUnit({
        value: 0.0,
        enum: UnitEnum.RelativeHumidity,
      });
    }

    this.humidityBelow = null;
    if (json["humidityBelow"]) {
      this.humidityBelow = new ValueAndUnit(json["humidityBelow"]);
    }
    if (this.humidityBelow == null) {
      this.humidityBelow = new ValueAndUnit({
        value: 0.0,
        enum: UnitEnum.RelativeHumidity,
      });
    }

    if (this.humidityAbove && this.humidityBelow) {
      if (this.humidityAbove.value < this.humidityBelow.value)
        this.subType = AlertFormatEnum.ValueBetween;
      else this.subType = AlertFormatEnum.ValueOutside;
    } else if (this.humidityAbove) this.subType = AlertFormatEnum.ValueAbove;
    else if (this.humidityBelow) this.subType = AlertFormatEnum.ValueBelow;
  }

  public updateValues() {
    if (this.subType == AlertFormatEnum.ValueAbove) {
      this.humidityAbove = cloneDeep(this.humidityAbove);
    } else if (this.subType == AlertFormatEnum.ValueBelow) {
      this.humidityBelow = cloneDeep(this.humidityBelow);
    } else if (
      this.subType == AlertFormatEnum.ValueBetween ||
      this.subType == AlertFormatEnum.ValueOutside
    ) {
      this.humidityAbove = cloneDeep(this.humidityAbove);
      this.humidityBelow = cloneDeep(this.humidityBelow);
    }
  }

  public get template(): string | null {
    return "humidity";
  }

  alert_facade_widget() {
    return WidgetEnum_Alert_Facade.ALERT_FACADE_WIDGET__HUMIDITY;
  }
}
