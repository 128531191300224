import { Inject, Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { FirebaseAuthService } from "../noizu/services/firebase-auth.service";
import { AppengineRepo } from "./appengine.repo";
import { UserEntity } from "../entities/user.entity";

@Injectable()
export class UserRepo extends AppengineRepo {
  public _kind = "users";
  public _singular = "user";

  constructor(
    @Inject(HttpClient) client: HttpClient,
    @Inject(FirebaseAuthService) auth: FirebaseAuthService
  ) {
    super(client, auth);
  }

  entity(record) {
    return new UserEntity(this.client, this.auth, record);
  }

  filterOptions() {
    return "filter term will be matched against first_name, last_name, display_name, email";
  }

  getListPromise(options: any = {}) {
    let url = `${this.apiBase()}/${this._kind}?embed=light`;

    url =
      "resultsPerPage" in options
        ? url + `&resultsPerPage=${encodeURIComponent(options.resultsPerPage)}`
        : url + "&resultsPerPage=500";
    if ("displayName" in options)
      url = url + `&displayName=${encodeURIComponent(options.displayName)}`;
    if ("lastName" in options)
      url = url + `&lastName=${encodeURIComponent(options.lastName)}`;
    if ("email" in options)
      url = url + `&email=${encodeURIComponent(options.email)}`;
    if ("id" in options) url = url + `&id=${encodeURIComponent(options.id)}`;
    if ("cursor" in options) {
      if (url.indexOf("?") > -1) {
        url = url + `&cursor=${options.cursor}`;
      } else {
        url = url + `?cursor=${options.cursor}`;
      }
    }
    return this._getListPromise(
      url,
      (record) => {
        return this.entity(record);
      },
      options
    );
  }

  getRawListPromise(options: any = {}) {
    let url = `${this.apiBase()}/${this._kind}?embed=light`;

    url =
      "resultsPerPage" in options
        ? url + `&resultsPerPage=${encodeURIComponent(options.resultsPerPage)}`
        : url + "&resultsPerPage=500";
    if ("displayName" in options)
      url = url + `&displayName=${encodeURIComponent(options.displayName)}`;
    if ("lastName" in options)
      url = url + `&lastName=${encodeURIComponent(options.lastName)}`;
    if ("email" in options)
      url = url + `&email=${encodeURIComponent(options.email)}`;
    if ("id" in options) url = url + `&id=${encodeURIComponent(options.id)}`;
    if ("cursor" in options) {
      if (url.indexOf("?") > -1) {
        url = url + `&cursor=${options.cursor}`;
      } else {
        url = url + `?cursor=${options.cursor}`;
      }
    }
    return new Promise((resolve, reject) => {
      this._get(
        url,
        (record) => {
          resolve(record);
          return record;
        },
        options
      );
    });
  }

  listForSensorPromise(sensor: any, options = {}) {
    return this._getListPromise(
      `${this.apiBase()}/sensors/${sensor}/users`,
      (record) => {
        return this.entity(record);
      },
      options
    );
  }
}
