import { HttpClient } from "@angular/common/http";
import { FirebaseAuthService } from "../../noizu/services/firebase-auth.service";
import { RequestLogEntity } from "./request-log.entity";
import { ElixirEntity } from "../elixir.entity";

export class RequestLogSet extends ElixirEntity {
  public entries: Array<RequestLogEntity> = null;
  public loaded = false;
  public enabled = false;
  public type = "gateway";
  public serial;
  public subject = "";
  public error = false;
  public error_code = 0;
  public error_message = "";

  constructor(client: HttpClient, auth: FirebaseAuthService, json) {
    super(client, auth, json);
    this.entries = new Array();
    if (json["tuple"]) {
      if (
        json["tuple"] &&
        json["tuple"][1] &&
        json["tuple"][1]["tuple"] &&
        json["tuple"][1]["tuple"][0] == 1022
      ) {
        this.error = true;
        this.error_code = 1022;
        this.error_message = `Internal Error Try Requesting a Smaller Period: ${json["tuple"][1]["tuple"][1]}`;
      }
    } else {
      if (json["outcome"] && json["records"]) {
        for (const snippet in json["records"]) {
          this.loaded = true;
          this.entries.push(
            new RequestLogEntity(client, auth, json["records"][snippet])
          );
        }
      } else {
        this.error = true;
        this.error_code = 1023;
        this.error_message = `No Records Returned`;
      }
    }
  } // end constructor

  public filterOptions() {
    return 'request_type contains filter or message body includes substring (example: "2022-05-08T23:01"';
  }

  public toggleEnabled() {
    if (this.enabled) {
      return this.disable();
    } else {
      return this.enable();
    }
  }

  public enable() {
    let url =
      this.type == "gateway"
        ? `${this.ingvEndpoint()}/admin-tools/gateway/${
            this.subject
          }/request-logs/enable`
        : `${this.ingvEndpoint()}/admin-tools/device/${
            this.subject
          }/request-logs/enable`;
    return this._put(url, {}, (v, r) => {
      if (v["outcome"] == true) {
        this.enabled = true;
      }
      r(this.enabled);
    });
  }

  public disable() {
    let url =
      this.type == "gateway"
        ? `${this.ingvEndpoint()}/admin-tools/gateway/${
            this.subject
          }/request-logs/disable`
        : `${this.ingvEndpoint()}/admin-tools/device/${
            this.subject
          }/request-logs/disable`;
    return this._put(url, {}, (v, r) => {
      if (v["outcome"] == true) {
        this.enabled = false;
      }
      r(this.enabled);
    });
  }

  public isLoaded() {
    let url =
      this.type == "gateway"
        ? `${this.ingvEndpoint()}/admin-tools/gateways/request-log-list`
        : `${this.ingvEndpoint()}/admin-tools/devices/request-log-list`;
    return this._get(url, (v, r) => {
      this.enabled = false;
      if (v["outcome"] == true) {
        for (let i in v["value"]) {
          if (v["value"][i] == this.serial) {
            this.enabled = true;
            break;
          }
        }
      }
      r(this.enabled);
    });
  }

  public widget() {
    return "embed-request-logs";
  }
} // end Package
