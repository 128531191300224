import { Component, Input, Inject, OnInit } from "@angular/core";
import { GatewayFirmwareReportEntity } from "../../../entities/gateway/firmeware-report.entity";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { GatewayCycleOverrideEntity } from "../../../entities/gateway/cycle-override.entity";
import { GatewayScriptOverrideEntity } from "../../../entities/gateway/script-override.entity";
import { AuthService } from "../../../services/auth.service";
import { BatchRepo } from "../../../repos";
import { TargetStruct } from "../../../entities/firmware";
import { Observable } from "rxjs";
import { AppengineEntityList } from "../../../noizu/structs/appengine-entity-list";
import { BatchEntity } from "../../../entities";
import { ModalOptions } from "ngx-bootstrap/modal";
import { ManufacturerListService } from "app/services/manufacturers.service";

@Component({
  selector: "embed-firmware-report",
  template: `
    <div class="card border-danger">
      <div class="card-header bg-danger">
        Gateway Firmware ({{ entity?.last_reported }})
        <div class="card-header-actions">
          <span class="pr-2"
            ><i
              (click)="expandCard = !expandCard"
              class="fa fa-sm"
              [ngClass]="{
                'fa-chevron-down': !expandCard,
                'fa-chevron-up': expandCard
              }"
            ></i
          ></span>
          <span class="dragula-handle"
            ><i class="dragula-handle text-black-50 fa fa-lg fa-arrows"></i
          ></span>
        </div>
      </div>
      <div class="card-body p-1" *ngIf="expandCard">
        <div class="row">
          <div class="col-6">
            <strong>Batch:</strong>
          </div>
          <div class="col-6">
            {{ entity.gateway_entity?.batch }}
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <strong>Manufacturer:</strong>
          </div>
          <div class="col-6">
            {{ entity.gateway_entity?.manufacturer }}
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <strong>Last Firmware:</strong>
          </div>
          <div class="col-6">
            {{ entity.last_reported }}
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <strong>Assigned Firmware:</strong>
          </div>
          <div class="col-6" *ngIf="entity.assigned">
            {{ entity.assigned?.firmware_version }}
          </div>
          <div class="col-6" *ngIf="!entity.assigned">
            <b>None</b>
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <strong>Pending Firmware:</strong>
          </div>
          <div class="col-6" *ngIf="entity.pending">
            {{ entity.pending?.firmware_version }}
          </div>
          <div class="col-6" *ngIf="!entity.pending">
            <b>None</b>
          </div>
        </div>

        <div class="row" *ngIf="entity.roll_out && entity.pending">
          <div class="col-6">
            <strong>Roll Out Group:</strong>
          </div>
          <div class="col-6">{{ entity.roll_out?.roll_out_group }}</div>
        </div>

        <div class="row">
          <div class="col-12 text-center">
            <strong>Gateway Internal State</strong>
          </div>
        </div>

        <div class="row">
          <div class="col-6"><strong>*assigned</strong></div>
          <div class="col-6" *ngIf="entity.worker_state?.assigned_link">
            {{ entity.worker_state?.assigned_link }}
          </div>
          <div class="col-6" *ngIf="!entity.worker_state?.assigned_link">
            None
          </div>
        </div>
        <div class="row">
          <div class="col-6"><strong>*manager</strong></div>
          <div class="col-6" *ngIf="entity.worker_state?.pending_release">
            {{ entity.worker_state?.pending_release }}
          </div>
          <div class="col-6" *ngIf="!entity.worker_state?.pending_release">
            None
          </div>
        </div>
        <div class="row" *ngIf="entity.worker_state?.pending_release">
          <div class="col-6"><strong>*managed_link</strong></div>
          <div class="col-6">{{ entity.worker_state?.pending_link }}</div>
        </div>
        <div class="row" *ngIf="entity.worker_state?.pending_release">
          <div class="col-6"><strong>*managed_phase</strong></div>
          <div class="col-6">{{ entity.worker_state?.pending_phase }}</div>
        </div>
        <div class="row" *ngIf="entity.worker_state?.pending_release">
          <div class="col-6"><strong>*managed_step</strong></div>
          <div class="col-6">{{ entity.worker_state?.meta?.pending_step }}</div>
        </div>
        <div class="row" *ngIf="entity.roll_out">
          <div class="col-12">
            <div class="row">
              <div class="col-12 text-center">
                <strong>Release RollOut Record</strong>
              </div>
            </div>

            <div class="row">
              <div class="col-6"><strong>*phase</strong></div>
              <div class="col-6">{{ entity.roll_out?.phase }}</div>
            </div>
            <div class="row">
              <div class="col-6"><strong>*step</strong></div>
              <div class="col-6">{{ entity.roll_out?.step }}</div>
            </div>
            <div class="row">
              <div class="col-6"><strong>*status</strong></div>
              <div class="col-6">{{ entity.roll_out?.status }}</div>
            </div>

            <div class="row">
              <div class="col-6"><strong>*roll_out_group</strong></div>
              <div class="col-6">{{ entity.roll_out?.roll_out_group }}</div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col text-center">
            <button (click)="forceRefresh($event)" class="button btn-primary">
              Link Check
            </button>
          </div>
          <div class="col text-center">
            <button
              (click)="forceMcuRefresh($event)"
              class="button btn-primary"
            >
              Mota Check
            </button>
          </div>
          <div class="col text-center">
            <button (click)="forceIAP($event)" class="button btn-primary">
              Force IAP
            </button>
          </div>
          <div class="col text-center">
            <button (click)="forceRef($event)" class="button btn-primary">
              Ref Override
            </button>
          </div>
        </div>

        <hr />

        <div class="row">
          <div class="col-12 text-center">
            <strong>Cycle Override</strong>
          </div>
          <div class="col-12" *ngIf="!entity.cycle_override">[NONE]</div>
          <div class="col-12" *ngIf="entity.cycle_override">
            <div class="row">
              <div class="col-5">
                <strong>Enabled</strong>
              </div>
              <div class="col-7">
                {{ entity.cycle_override.enabled | json }}
              </div>
            </div>
            <div class="row">
              <div class="col-5">
                <strong>Interval</strong>
              </div>
              <div class="col-7">
                {{ entity.cycle_override.interval }} (Seconds)
              </div>
            </div>
            <div class="row">
              <div class="col-5">
                <strong>VerString</strong>
              </div>
              <div class="col-7">
                <span *ngIf="entity.cycle_override.firmware_version">{{
                  entity.cycle_override.firmware_version
                }}</span>
                <span *ngIf="!entity.cycle_override.firmware_version"
                  >[No Override]</span
                >
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12 text-center">
            <strong>Script Override</strong>
          </div>
          <div class="col-12" *ngIf="!entity.script_override">
            [No Firmware Script Enabled]
          </div>
          <div class="col-12" *ngIf="entity.script_override">
            <div class="row">
              <div class="col-5">
                <strong>Enabled</strong>
              </div>
              <div class="col-7">
                {{ entity.script_override.enabled | json }}
              </div>
            </div>

            <div class="row">
              <div class="col-5">
                <strong>Script</strong>
              </div>
              <div class="col-7">
                <pre>{{ entity.script_override.script | json }}</pre>
              </div>
            </div>

            <div class="row">
              <div class="col-5">
                <strong>Script Sequence Position</strong>
              </div>
              <div class="col-7">
                <pre>{{
                  entity.script_override.sequencer_info?.entity?.position
                }}</pre>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="overrideAccess()">
          <hr />
          <div class="row">
            <div class="col-12 text-justify text-center">
              <button
                (click)="showSetCycle(reportModal, $event)"
                class="button btn-warning ml-1"
              >
                Assign Cycle
              </button>
              <button
                (click)="showSetScript(reportModal, $event)"
                class="button btn-danger ml-1"
              >
                Assign Script
              </button>
              <button
                (click)="showSetBatch(reportModal, $event)"
                class="button btn-primary ml-1"
              >
                Assign Batch
              </button>
              <button
                (click)="showSetBatch(reportModal, $event)"
                class="button btn-danger ml-1"
              >
                Force MOTA
              </button>
              <button
                (click)="showSetBatch(reportModal, $event)"
                class="button btn-success ml-1"
              >
                Set Ref
              </button>
            </div>
          </div>
        </div>

        <hr />

        <div class="row">
          <div class="col-12 text-center pr-4">
            <strong>Firmware Links</strong>
            <span class="float-right">
              <i
                (click)="expandLinks = !expandLinks"
                class="fa fa-sm"
                [ngClass]="{
                  'fa-chevron-down': !expandLinks,
                  'fa-chevron-up': expandLinks
                }"
              >
              </i>
            </span>
          </div>
          <div class="col-12" *ngIf="expandLinks">
            <pre>{{ entity.firmware_links | json }}</pre>
          </div>
        </div>

        <div class="row">
          <div class="col-12 text-center pr-4">
            <strong>Firmware Report</strong>
            <span class="float-right">
              <i
                (click)="expandReport = !expandReport"
                class="fa fa-sm"
                [ngClass]="{
                  'fa-chevron-down': !expandReport,
                  'fa-chevron-up': expandReport
                }"
              >
              </i>
            </span>
          </div>
          <div class="col-12" *ngIf="expandReport">
            <pre>{{ entity.report | json }}</pre>
          </div>
        </div>

        <div class="row">
          <div class="col-12 text-center pr-4">
            <strong>Extended History</strong>
            <span class="float-right">
              <i
                (click)="expandHistory = !expandHistory"
                class="fa fa-sm"
                [ngClass]="{
                  'fa-chevron-down': !expandHistory,
                  'fa-chevron-up': expandHistory
                }"
              >
              </i>
            </span>
          </div>
          <div class="col-12" *ngIf="expandHistory">
            <pre>{{ entity.history | json }}</pre>
          </div>
        </div>

        <div class="row">
          <div class="col-12 text-center pr-4">
            <strong>Raw Internal State</strong>
            <span class="float-right">
              <i
                (click)="expandInternalState = !expandInternalState"
                class="fa fa-sm"
                [ngClass]="{
                  'fa-chevron-down': !expandInternalState,
                  'fa-chevron-up': expandInternalState
                }"
              >
              </i>
            </span>
          </div>
          <div class="col-12" *ngIf="expandInternalState">
            <pre>{{ entity.worker_state | json }}</pre>
          </div>
        </div>

        <div class="row">
          <div class="col-12 text-center pr-4">
            <strong>Raw RollOut State</strong>
            <span class="float-right">
              <i
                (click)="expandRollOutState = !expandRollOutState"
                class="fa fa-sm"
                [ngClass]="{
                  'fa-chevron-down': !expandRollOutState,
                  'fa-chevron-up': expandRollOutState
                }"
              >
              </i>
            </span>
          </div>
          <div class="col-12" *ngIf="expandRollOutState">
            <pre>{{ entity.roll_out | json }}</pre>
          </div>
        </div>
      </div>
    </div>

    <!--                                                        -->
    <!--                    Pop-Up Menu                         -->
    <!--                                                        -->
    <ng-template #reportModal>
      <div class="modal-header">
        <h4 class="modal-title pull-left">{{ currentModal?.title }}</h4>
        <button
          type="button"
          class="close pull-right"
          aria-label="Close"
          (click)="reportModalRef.hide()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <widget-spinner *ngIf="currentModal?.processing"></widget-spinner>
        <div class="row">
          <div class="col-12">
            {{ currentModal?.msg }}
          </div>
        </div>
        <div class="row" *ngIf="currentModal?.errorMsg">
          <div class="col-12">
            <div class="alert alert-warning">{{ currentModal?.errorMsg }}</div>
          </div>
        </div>
        <div class="row" [ngSwitch]="currentModal?.form">
          <div class="col-12" *ngSwitchCase="'batch'">
            <div class="form-group">
              <div class="row">
                <div class="col-4"><strong>Assign Batch</strong></div>
                <div class="col-8" *ngIf="available.batches | async as batches">
                  <ng-select
                    class="form-control font-2xl pb-0 pt-0"
                    placeholder="Select Batch"
                    [items]="batches"
                    [multiple]="false"
                    bindLabel="label"
                    bindValue="value"
                    [(ngModel)]="editBatch.batch"
                  ></ng-select>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12" *ngSwitchCase="'cycle'">
            <div class="form-group">
              <div class="row">
                <div class="col-4"><strong>Enabled</strong></div>
                <div class="col-4">
                  <input
                    type="checkbox"
                    class="form-control"
                    [(ngModel)]="editCycle.enabled"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-4"><strong>Interval</strong></div>
                <div class="col-4">
                  <input
                    type="text"
                    class="form-control"
                    [(ngModel)]="editCycle.interval"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-4"><strong>Spoof Version</strong></div>
                <div class="col-4">
                  <input
                    type="text"
                    class="form-control"
                    [(ngModel)]="editCycle.firmware_version"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="col-12" *ngSwitchCase="'script'">
            <div class="form-group">
              <div class="row">
                <div class="col-4"><strong>Enabled</strong></div>
                <div class="col-4">
                  <input
                    type="checkbox"
                    class="form-control"
                    [(ngModel)]="editScript.enabled"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-4"><strong>Script</strong></div>
                <div class="col-4">
                  <select
                    class="form-control font-xl pb-0 pt-0"
                    [(ngModel)]="editScript.script"
                  >
                    <option [value]="'alpha_group'">Alpha Group</option>
                    <option [value]="'beta_group'">Beta Group</option>
                    <option [value]="'dev_group'">Dev Group</option>
                    <option [value]="'noizu_group'">Noizu Group</option>
                    <option [value]="'v3_group'">V3 1024kb+ Beta Group</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12" *ngSwitchDefault>[Unsupported Modal Type]</div>
        </div>
      </div>
      <div class="modal-footer">
        <button
          *ngIf="currentModal?.cancelMsg"
          type="button"
          class="btn btn-secondary"
          (click)="currentModal?.cancel()"
          data-dismiss="modal"
        >
          {{ currentModal?.cancelMsg }}
        </button>
        <button
          *ngIf="currentModal?.deleteMsg"
          type="button"
          class="btn btn-warning"
          (click)="currentModal?.delete()"
          data-dismiss="modal"
        >
          {{ currentModal?.deleteMsg }}
        </button>
        <button
          *ngIf="currentModal?.confirmMsg"
          type="button"
          class="btn btn-danger"
          (click)="currentModal?.confirm()"
        >
          {{ currentModal?.confirmMsg }}
        </button>
      </div>
    </ng-template>
  `,
})
export class EmbedGatewayFirmwareReportComponent implements OnInit {
  @Input() entity: GatewayFirmwareReportEntity = null;
  @Input() options: any = null;
  @Input() layout: string = null;

  public expandCard = false;
  public editCycle = new GatewayCycleOverrideEntity(null);
  public editScript = new GatewayScriptOverrideEntity(null);
  public editBatch = { batch: 12 };

  public reportModalRef: BsModalRef;
  public currentModal: any;

  public available = {
    batches: null,
    types: null,
    firmware: null,
    manufacturers: [],
    series: [
      { value: "V2", label: "V2" },
      { value: "V3", label: "V3" },
      { value: "V4", label: "V4" },
    ],
  };

  public setCycleModal = {
    title: "Set Cycle Override",
    msg: `This will force the Display to regularly check for Firmware Updates.`,
    errorMsg: null,
    processing: false,
    form: "cycle",
    confirmMsg: "Assign",
    confirm: () => {
      if (this.setCycle()) {
        this.reportModalRef.hide();
      }
    },
    deleteMsg: "Clear",
    delete: () => {
      if (this.clearCycle()) {
        this.reportModalRef.hide();
      }
    },
    cancelMsg: "Cancel",
    cancel: () => {
      this.setCycleModal.errorMsg = null;
      this.reportModalRef.hide();
    },
  };

  public setBatchModal = {
    title: "Set Batch",
    msg: `Update Display Assigned Batch and Force Firmware Check`,
    errorMsg: null,
    processing: false,
    form: "batch",
    confirmMsg: "Set",
    confirm: () => {
      if (this.setBatch()) {
        this.reportModalRef.hide();
      }
    },
    cancelMsg: "Cancel",
    cancel: () => {
      this.setBatchModal.errorMsg = null;
      this.reportModalRef.hide();
    },
  };

  public setScriptModal = {
    title: "Set Script Override",
    msg: `This will assign the display to an OTA sequence script.`,
    errorMsg: null,
    processing: false,
    form: "script",
    confirmMsg: "Assign",
    confirm: () => {
      if (this.setScript()) {
        this.reportModalRef.hide();
      }
    },
    deleteMsg: "Clear",
    delete: () => {
      if (this.clearScript()) {
        this.reportModalRef.hide();
      }
    },
    cancelMsg: "Cancel",
    cancel: () => {
      this.setScriptModal.errorMsg = null;
      this.reportModalRef.hide();
    },
  };

  public expandHistory = false;
  public expandLinks = false;
  public expandReport = false;
  public expandInternalState = false;
  public expandRollOutState = false;
  manufacturerSelectOptions = [];
  constructor(
    public modalService: BsModalService,
    public authService: AuthService,
    public repoBatches: BatchRepo,
    private manufacturerService: ManufacturerListService
  ) {}

  //-----------------------------
  //
  //-----------------------------
  ngOnInit(): void {
    this.manufacturerService.getManufacturers().then((dataValues) => {
      this.manufacturerSelectOptions = dataValues.map((option) => ({
        value: option.identifier,
        description: "(" + option.identifier + ") " + option.name,
      }));
    });
    this.available.manufacturers = this.manufacturerSelectOptions;
    this.available.batches = new Observable<any>((observer) => {
      this.repoBatches.getListPromise().then((v: AppengineEntityList) => {
        const response = [];
        v.items.forEach((i: BatchEntity) => {
          const entry = {
            value: i.identifier,
            label: `${i.identifier} - ${i.name}`,
          };
          response.push(entry);
        });
        observer.next(response);
      });
    });
  }

  overrideAccess() {
    return (
      this.authService.userHasPermission("firmware_admin") ||
      this.authService.userHasPermission("firmware_assign")
    );
  }

  showSetCycle(reportModal, event) {
    event.preventDefault();
    this.currentModal = this.setCycleModal;
    let config: ModalOptions = {
      backdrop: "static",
      keyboard: false,
    };
    this.reportModalRef = this.modalService.show(reportModal, config);
  }

  setCycle() {
    this.setCycleModal.errorMsg = null;
    if (typeof this.editCycle.interval === "string") {
      this.editCycle.interval = this.editCycle.interval.trim();
      const t = Number(this.editCycle.interval);
      if (t.toString() !== this.editCycle.interval) {
        this.setCycleModal.errorMsg = "Interval Must Be Whole Number";
        return false;
      }
      this.editCycle.interval = t;
    }

    if (Number.isInteger(this.editCycle.interval)) {
      if (this.editCycle.enabled) {
        this.editCycle.enabled = true;
      } else {
        this.editCycle.enabled = false;
      }
      if (typeof this.editCycle.firmware_version === "string") {
        if (this.editCycle.firmware_version.length > 10) {
          this.setCycleModal.errorMsg =
            "Firmware String Must Be 10 chars or less.";
          return false;
        }
        if (this.editCycle.firmware_version === "") {
          this.editCycle.firmware_version = null;
        }
      }
    } else {
      this.setCycleModal.errorMsg = "Interval Must Be Whole Number";
      return false;
    }

    this.setCycleModal.processing = true;
    this.entity.setCycle(this.editCycle).then((d) => {
      this.setCycleModal.processing = false;
      this.reportModalRef.hide();
    });
    return false;
  }

  clearCycle() {
    this.setScriptModal.errorMsg = null;
    this.setCycleModal.processing = true;
    this.entity.clearCycle().then((d) => {
      this.setCycleModal.processing = false;
      this.reportModalRef.hide();
    });
    return false;
  }

  showSetScript(reportModal, event) {
    event.preventDefault();
    this.currentModal = this.setScriptModal;
    let config: ModalOptions = {
      backdrop: "static",
      keyboard: false,
    };
    this.reportModalRef = this.modalService.show(reportModal, config);
  }

  setScript() {
    if (this.editScript.script) {
      if (this.editScript.enabled) {
        this.editScript.enabled = true;
      } else {
        this.editScript.enabled = false;
      }
      this.setScriptModal.processing = true;
      this.entity.setScript(this.editScript).then((d) => {
        this.setScriptModal.processing = false;
        this.reportModalRef.hide();
      });
    } else {
      this.setScriptModal.errorMsg = "Must select script";
    }
    return false;
  }

  clearScript() {
    this.setScriptModal.errorMsg = null;
    this.setScriptModal.processing = true;
    this.entity.clearScript().then((d) => {
      this.setScriptModal.processing = false;
      this.reportModalRef.hide();
    });
    return false;
  }

  showSetBatch(reportModal, event) {
    event.preventDefault();
    this.currentModal = this.setBatchModal;
    let config: ModalOptions = {
      backdrop: "static",
      keyboard: false,
    };
    this.reportModalRef = this.modalService.show(reportModal, config);
  }

  forceRefresh(event) {
    event.preventDefault();
    this.entity.forceRefresh();
  }

  forceMcuRefresh(event) {
    event.preventDefault();
    this.entity.forceMcuRefresh();
  }

  forceIAP(event) {
    event.preventDefault();
    alert("pending");
  }

  forceRef(event) {
    event.preventDefault();
    alert("pending - pop up");
  }

  setBatch() {
    this.setBatchModal.errorMsg = null;
    if (this.editBatch.batch) {
      this.setBatchModal.processing = true;
      this.entity.setBatch(this.editBatch).then((d) => {
        this.setBatchModal.processing = false;
        this.reportModalRef.hide();
      });
    } else {
      this.setBatchModal.errorMsg = "You must select batch";
    }
    return false;
  }
}
