export enum AlertFormatEnum {
  HumidityInside,
  HumidityOutside,
  HumidityAbove,
  HumidityBelow,
  IndoorHumidityInside,
  IndoorHumidityOutside,
  IndoorHumidityAbove,
  IndoorHumidityBelow,

  IndoorValueBetween,
  IndoorValueOutside,
  IndoorValueAbove,
  IndoorValueBelow,

  ValueBetween,
  ValueOutside,
  ValueAbove,
  ValueBelow,

  Unknown,
}
