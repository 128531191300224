import { Component, Input } from "@angular/core";

@Component({
  selector: "widget-spinner",
  template: ` <div class="spinner-border {{ size }}" role="status"></div> `,
})
export class WidgetSpinnerComponent {
  @Input() size = "md";
  constructor() {}
}
