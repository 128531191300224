import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ToasterService } from "angular2-toaster";
import { AlertThermometerFacadeEntity } from "../../../../entities/alert";
import { AlertFacadeDefaultWidgetComponent } from "./default.widget.component";
import { AlertFacadeWidgetEventStruct } from "./alert-facade-widget-event.struct";

@Component({
  selector: "alert-facade-thermometer-widget",
  template: `
    <div class="card border-dark alert-facade-thermometer-widget">
      <!-- Card Header -->
      <div class="card-header bg-dark text-white">
        <h5 class="mb-0">Thermometer Alert</h5>
      </div>

      <!-- Card Body -->
      <div class="card-body">
        <!-- Device Section -->
        <div class="row mb-3 align-items-center">
          <div class="col-4 fw-bold">Device</div>
          <div class="col-8">
            <a
              [routerLink]="'/portal/devices/show/' + entity.device"
              class="text-primary text-decoration-none"
            >
              {{ entity.device }}
            </a>
          </div>
        </div>

        <!-- Association Section -->
        <div class="row mb-3 align-items-center">
          <div class="col-4 fw-bold">Association</div>
          <div class="col-8">{{ entity.deviceAssociation }}</div>
        </div>

        <!-- Divider -->
        <hr class="my-3" />

        <!-- JSON Data Display Section -->
        <div class="row mb-3">
          <div class="col-12">
            <h6 class="text-secondary">Entity Data</h6>
            <pre class="bg-light p-3 rounded border">{{ entity | json }}</pre>
          </div>
        </div>
      </div>
    </div>
  `,
})
export class EmbedAlertThermometerFacadeComponent extends AlertFacadeDefaultWidgetComponent {
  public editMode = false;
  @Input() layout: string = null;
  @Input() entity: AlertThermometerFacadeEntity;
  @Input() options: any;
  @Input() deviceData: any = null;
  @Output() widgetEvent = new EventEmitter<AlertFacadeWidgetEventStruct>();
  forwardEvent(e, widgetEvent: AlertFacadeWidgetEventStruct) {
    e.preventDefault();
    this.widgetEvent.emit(widgetEvent);
  }
  setDeviceValues() {
    if (!this.options.edit) return;
    this.entity.device = this.deviceData.deviceId;
    this.entity.deviceAssociation = this.deviceData.associationId;
  }
  ngOnInit() {
    if (
      this.deviceData.deviceId !== undefined &&
      this.deviceData.associationId !== undefined
    ) {
      this.setDeviceValues();
    }
  }
  constructor(public toasterService: ToasterService) {
    super();
  }
}
