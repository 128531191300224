import { NoizuStruct } from "../noizu/structs/noizu-struct";
import { ValueAndUnitUnit } from "./value-and-unit/unit";

export class ValueAndUnit extends NoizuStruct {
  public value: number;
  public unit: ValueAndUnitUnit;

  constructor(json: any) {
    super();

    this.value = json["value"] || 0;
    this.unit = new ValueAndUnitUnit(json);
  }

  public toJson() {
    return {
      value: this.value,
      unit: this.unit.enum,
    };
  }
}
