import { Component, Inject, NgZone } from "@angular/core";
import { FirmwareLinkV2Entity } from "../../../entities/firmware/link-v2.entity";
import { FirmwareLinkV2Repo } from "../../../repos/firmware/link-v2.repo";
import { BsModalRef, BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import { AuthService } from "../../../services/auth.service";
import { FirebaseAuthService } from "../../../noizu/services/firebase-auth.service";
import { HttpClient } from "@angular/common/http";
import { ManufacturerListService } from "app/services/manufacturers.service";

@Component({
  templateUrl: "./list.component.html",
})
export class FirmwareLinkListComponent {
  public entries: { any: Array<FirmwareLinkV2Entity> } | {} = {};
  public categories: any = [];
  public newLink: FirmwareLinkV2Entity;
  public modalRef: BsModalRef;
  public currentModal: any;
  public client: HttpClient;
  public auth: FirebaseAuthService;
  public widget_options = { edit: false, modal_form: true };
  public sidebar_options = { edit: false, firmware_admin: false };
  public list_options = { edit: false };
  public firmware_admin = false;
  public manufacturerSelectOptions: any = [];
  public selectedLink: any = {};

  public linkCreateModal = {
    title: "Add WifiModule Firmware Link",
    errorMsg: null,
    processing: false,
    form: "new-firmware",
    confirmMsg: "Save",
    confirm: () => {
      if (this.newLink.valid()) {
        this.repo.createPromise(this.newLink).then((v) => {
          this.newLink = null;
          this.modalRef.hide();
        });
      } else {
        alert("invalid link");
      }
    },
    cancelMsg: "Cancel",
    cancel: () => {
      this.newLink = null;
      this.modalRef.hide();
    },
  };

  constructor(
    public modalService: BsModalService,
    public repo: FirmwareLinkV2Repo,
    public zone: NgZone,
    public authService: AuthService,
    private manufacturerService: ManufacturerListService,
    @Inject(HttpClient) client: HttpClient,
    @Inject(FirebaseAuthService) auth: FirebaseAuthService
  ) {
    this.client = client;
    this.auth = auth;
    this.zone.run(() => {
      if (this.authService.loadedPromise) {
        this.authService.loadedPromise.then(() => {
          if (this.authService.userHasPermission("firmware_admin")) {
            this.firmware_admin = true;
            this.widget_options.edit = true;
            this.sidebar_options.edit = true;
            this.sidebar_options.firmware_admin = true;
            this.list_options.edit = true;
          }
        });
      }
      this.entries = {};
      this.repo.getListPromise().then((u: any) => {
        this.categories = {};
        if (u) {
          for (let j in u) {
            let i = u[j];
            this.categories[i.target.type] = i.target.type;
            if (this.entries[i.target.type]) {
              this.entries[i.target.type].push(i);
            } else {
              this.entries[i.target.type] = Array<FirmwareLinkV2Entity>();
              this.entries[i.target.type].push(i);
            }
          }
        }

        let t = [];
        for (let cat in this.categories) {
          t.push(cat);
          this.entries[cat] = this.entries[cat].sort((a, b) => {
            return a.identifier - b.identifier;
          });
        }
        this.categories = t;
      });
    });
  }

  ngOnInit() {
    this.manufacturerService.getManufacturers().then((dataValues) => {
      this.manufacturerSelectOptions = dataValues.map((option) => ({
        value: "ref.manufacturer." + option.identifier,
        label: option.name,
      }));
    });
  }

  selectLink(entity, cat, event) {
    event.preventDefault();
    if (
      this.selectedLink[cat] &&
      this.selectedLink[cat].identifier == entity.identifier
    ) {
      this.selectedLink[cat] = null;
    } else {
      this.selectedLink[cat] = entity;
    }
  }

  add(modal, cat, e) {
    e.preventDefault();
    this.newLink = new FirmwareLinkV2Entity(this.client, this.auth, {
      identifier: "new",
      target: { type: cat },
    });
    this.currentModal = this.linkCreateModal;
    let config: ModalOptions = {
      backdrop: "static",
      keyboard: false,
    };
    this.modalRef = this.modalService.show(modal, config);
  }
}
