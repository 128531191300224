import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { WidgetBase } from "../widget-base";
import { EntitySetWidgetEventStruct } from "./entity-set-widget-event.struct";
import { GenericWidgetSetComponent } from "../generic-set-widget";

@Component({
  selector: "entity-set-header-widget",
  template: `
    <div class="entity-set-header-widget container-fluid p-3">
      <!-- First Row: View Toggle, Column Selector, and Filter Input -->
      <div class="row mb-4 align-items-center">
        <div class="col-lg-6 col-md-12 mb-3 mb-md-0">
          <div class="d-flex align-items-center">
            <!-- View Toggle Button -->
            <button
              class="btn btn-info btn-pill mr-3"
              (click)="changeView($event)"
            >
              <ng-container *ngIf="!entity.tableView">Card View</ng-container>
              <ng-container *ngIf="entity.tableView">Table View</ng-container>
            </button>

            <!-- Column Selector -->
            <ng-container *ngIf="!entity.tableView">
              <ng-select
                class="form-control-lg ng-select-inline mr-3"
                placeholder="Columns"
                bindLabel="label"
                bindValue="value"
                [items]="entity.columnOptions"
                [multiple]="false"
                [(ngModel)]="entity.columns"
              ></ng-select>
            </ng-container>

            <!-- Filter Input -->
            <input
              (change)="filterRecords($event)"
              [(ngModel)]="newFilter"
              type="text"
              placeholder="Filter"
              class="form-control-lg flex-grow-1"
            />
          </div>
        </div>

        <!-- Filter Options Alert -->
        <div class="col-lg-6 col-md-12">
          <div
            class="alert alert-warning mb-0"
            *ngIf="entity?.entity?.repo?.filterOptions()"
          >
            Filter Options: {{ entity.entity.repo.filterOptions() }}
          </div>
        </div>
      </div>

      <!-- Second Row: Entries Info and Action Buttons -->
      <div class="row mb-4 align-items-center">
        <div class="col-md-6 mb-3 mb-md-0">
          <!-- No Entries Alert -->
          <div *ngIf="!entity.has_entries" class="alert alert-warning m-0">
            No Entries
            <ng-container *ngIf="entity.filter">
              For Filter [{{ entity.filter }}]
              <i
                class="text-danger fa fa-minus-circle cursor-pointer"
                (click)="clearFilter($event)"
              ></i>
              , Not Shown {{ entity.not_shown }}
            </ng-container>
          </div>

          <!-- Entries Info Alert -->
          <div *ngIf="entity.has_entries" class="alert alert-info m-0">
            <ng-container *ngIf="!entity.filter">
              Records {{ entity.records }}
            </ng-container>
            <ng-container *ngIf="entity.filter">
              Filter [{{ entity.filter }}]
              <i
                class="text-danger fa fa-minus-circle cursor-pointer"
                (click)="clearFilter($event)"
              ></i>
              , Matches {{ entity.shown }}, Hidden {{ entity.not_shown }}
            </ng-container>
          </div>
        </div>

        <!-- Action Buttons -->
        <div class="col-md-6 d-flex justify-content-end">
          <div
            *ngIf="entity.entity.queryable"
            class="d-flex align-items-center"
          >
            <button class="btn btn-primary btn-lg mr-3" (click)="next($event)">
              More Results
              <new-spinner
                *ngIf="entity.fetching"
                [inline]="true"
                [size]="'sm'"
              ></new-spinner>
            </button>
          </div>
          <button
            *ngIf="newButtonActive && options['edit']"
            (click)="add($event)"
            class="btn btn-lg btn-warning"
            type="submit"
          >
            <i class="fa fa-plus-circle"></i> New
          </button>
        </div>
      </div>

      <!-- Third Row: Pagination -->
      <div class="row mb-4" *ngIf="entity.pages > 1">
        <div class="col">
          <div class="alert alert-success">
            <div class="d-flex align-items-center flex-wrap">
              <span class="mr-3">Pagination:</span>
              <input
                (ngModelChange)="updatePagination()"
                type="number"
                min="5"
                max="1000"
                [(ngModel)]="entity.dpp"
                placeholder="Records Per Page"
                class="form-control-lg mr-3"
              />
              <span class="mr-3">(Results Per Page)</span>

              <!-- Pagination Controls -->
              <ng-container *ngIf="entity.pages > 15">
                <ul class="pagination mb-0">
                  <ng-container
                    *ngFor="let p of entity.pagination; let i = index"
                  >
                    <li
                      class="page-item"
                      *ngIf="
                        i == 0 ||
                        i == entity.pages - 1 ||
                        i % 100 == 0 ||
                        (entity.page > i - 100 &&
                          entity.page < i + 100 &&
                          i % 10 == 0) ||
                        (entity.page > i - 5 && entity.page < i + 5)
                      "
                      [ngClass]="{ active: i == entity.page }"
                    >
                      <a (click)="toPage($event, p)" class="page-link">
                        <span *ngIf="i == 0">First</span>
                        <span *ngIf="i == entity.pages - 1">Last</span>
                        <span *ngIf="i > 0 && i < entity.pages - 1">{{
                          i
                        }}</span>
                      </a>
                    </li>
                  </ng-container>
                </ul>
              </ng-container>

              <ng-container *ngIf="entity.pages <= 15">
                <ul class="pagination mb-0">
                  <li
                    class="page-item"
                    *ngFor="let p of entity.pagination; let i = index"
                    [ngClass]="{ active: i == entity.page }"
                  >
                    <a (click)="toPage($event, p)" class="page-link">{{ i }}</a>
                  </li>
                </ul>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
})
export class EntitySetHeaderWidgetComponent
  extends WidgetBase
  implements OnInit
{
  public editMode = false;
  @Input() layout: string = null;
  @Input() entity: GenericWidgetSetComponent;
  @Input() options: any;
  @Output() widgetEvent = new EventEmitter<EntitySetWidgetEventStruct>();

  public newFilter = null;
  public newButtonActive = true;

  changeView(event) {
    this.forwardEvent(event, new EntitySetWidgetEventStruct("change-view"));
  }

  next(event) {
    this.forwardEvent(event, new EntitySetWidgetEventStruct("next"));
  }

  add(event) {
    this.forwardEvent(event, new EntitySetWidgetEventStruct("add-modal"));
  }

  updatePagination(event = null) {
    this.forwardEvent(event, new EntitySetWidgetEventStruct("add-modal"));
  }

  toPage(event, page) {
    this.forwardEvent(
      event,
      new EntitySetWidgetEventStruct("to-page", page["page"])
    );
  }

  clearFilter(event) {
    this.forwardEvent(event, new EntitySetWidgetEventStruct("clear-filter"));
  }

  filterRecords(event) {
    this.forwardEvent(
      event,
      new EntitySetWidgetEventStruct("filter", this.newFilter)
    );
  }

  forwardEvent(e, widgetEvent: EntitySetWidgetEventStruct) {
    if (e) e.preventDefault();
    this.widgetEvent.emit(widgetEvent);
  }

  constructor() {
    super();
  }

  ngOnInit(): void {
    if (this.entity.entity.kind == "user-subscription") {
      this.entity.filteredSet.map((record) => {
        if (record.subscription_status == "active") {
          this.newButtonActive = false;
          return;
        }
      });
    }
  }
}
