import { Component, NgZone } from "@angular/core";
import { DataManufacturersEntity } from "../../entities/data-manufacturers.entity";
import { DataManufacturersRepo } from "../../repos/data/manufacturers.repo";

@Component({
  templateUrl: "./manufacturers.component.html",
})
export class DataManagementManufacturersComponent {
  public entries: Array<DataManufacturersEntity>;
  public edit = false;

  constructor(public repo: DataManufacturersRepo, public zone: NgZone) {
    this.zone.run(() => {
      this.repo.getListPromise().then((u: any) => {
        this.entries = u;
      });
    });
  }

  add(e) {
    e.preventDefault();
    this.entries["items"].push(this.repo.entity({ id: 0, new: true }));
  }
}
