
    <page-header [section]="'email-queue'">
      <page-title>Email Queue</page-title>
      <page-blurb
        >View email queue status if active / inactive / pending, and initiate an
        action to manage / send / resend email queue requests.</page-blurb
      >
    </page-header>

    <new-spinner *ngIf="!queue" [size]="'sm'"></new-spinner>
    <div *ngIf="queue" class="row">
      <div class="col">
        <generic-set-widget
          [entity]="queue"
          [layout]="'table'"
          [options]="queue_list_options"
        ></generic-set-widget>
      </div>
    </div>
  