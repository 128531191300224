  /**
   * augmentations.ts
   * Placeholder for any Module Augmentation needed to work around type issues.
   * @see https://www.typescriptlang.org/docs/handbook/declaration-merging.html
   */

  //---------------------------------------
  // @RXJS
  // @see https://github.com/ReactiveX/rxjs/issues/2539
  //---------------------------------------

  /**
    import {Operator, Observable} from 'rxjs'

    declare module 'rxjs/Subject' {
      interface Subject<T> {
        lift<R>(operator: Operator<T, R>): Observable<R>
      }
    }
    */
