
    <ng-container [ngSwitch]="layout">
      <!-- Table Layout -->
      <ng-container *ngSwitchCase="'table'">
        <div class="table-responsive">
          <table
            class="table table-hover table-striped table-bordered align-middle"
          >
            <thead class="table-dark">
              <tr>
                <th scope="col">Id</th>
                <th scope="col">Handle</th>
                <th scope="col">Type</th>
                <th scope="col">Status</th>
                <th scope="col">Source</th>
                <th scope="col">SMS Cap</th>
                <th scope="col">SMS Refresh On</th>
                <th scope="col">Start</th>
                <th scope="col">End</th>
                <th scope="col" class="text-center">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngIf="entity">
                <td>{{ entity?.identifier }}</td>
                <td>{{ entity.handle }}</td>
                <td>{{ entity.subscription_type }}</td>
                <td>
                  <span
                    class="badge bg-{{
                      entity.subscription_status === 'active'
                        ? 'success'
                        : 'danger'
                    }}"
                  >
                    {{ entity.subscription_status }}
                  </span>
                </td>
                <td>{{ entity.subscription_payment?.source || "Internal" }}</td>
                <td>
                  <span *ngIf="entity.subscription_start">
                    {{ entity.sms_cap }}
                  </span>
                </td>
                <td>
                  <span *ngIf="entity.subscription_start">
                    {{ entity.sms_cap_refresh_on | date : "mediumDate" }}
                  </span>
                </td>
                <td>
                  <span *ngIf="entity.subscription_start">
                    {{ entity.subscription_start | date : "mediumDate" }}
                  </span>
                </td>
                <td>
                  <span *ngIf="entity.subscription_end">
                    {{ entity.subscription_end | date : "mediumDate" }}
                  </span>
                </td>
                <td class="text-center">
                  <button
                    class="btn btn-sm btn-outline-danger"
                    (click)="delete($event)"
                  >
                    <i class="fas fa-trash"></i> Delete
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </ng-container>

      <!-- Default View (Card) -->
      <ng-container *ngSwitchDefault>
        <ng-container *ngIf="entity?.new">
          <div class="card mb-4 shadow-sm">
            <div class="card-header bg-primary text-white">
              <h5 class="card-title mb-0">New Subscription</h5>
            </div>
            <div class="card-body">
              <!-- Handle -->
              <div class="form-group row">
                <label class="col-sm-2 col-form-label font-weight-bold"
                  >Handle</label
                >
                <div class="col-sm-10">
                  <input
                    type="text"
                    class="form-control"
                    [(ngModel)]="entity.handle"
                    (ngModelChange)="editEntity()"
                  />
                </div>
              </div>
              <hr />

              <!-- Owner -->
              <div class="form-group row">
                <label class="col-sm-2 col-form-label font-weight-bold"
                  >Owner</label
                >
                <div class="col-sm-10">
                  <p class="form-control-static">{{ entity.owner }}</p>
                </div>
              </div>
              <hr />

              <!-- Type -->
              <div
                class="form-group row"
                *ngIf="entity.validation?.messages['subscription_type']"
              >
                <div class="col-sm-12">
                  <div class="alert alert-warning">
                    {{ entity.validation.messages.subscription_type }}
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-2 col-form-label font-weight-bold"
                  >Type</label
                >
                <div class="col-sm-10">
                  <widget
                    [entity]="typeRepo"
                    (widgetEvent)="widgetEventHandler($event)"
                    [options]="{
                      edit: options['edit'],
                      selected: entity.subscription_type
                    }"
                    [layout]="'select'"
                  ></widget>
                </div>
              </div>
              <hr />

              <!-- Status -->
              <div
                class="form-group row"
                *ngIf="entity.validation?.messages['subscription_status']"
              >
                <div class="col-sm-12">
                  <div class="alert alert-warning">
                    {{ entity.validation.messages.subscription_status }}
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-2 col-form-label font-weight-bold"
                  >Status</label
                >
                <div class="col-sm-10">
                  <ng-select
                    [name]="'subscription_status'"
                    [multiple]="false"
                    [(ngModel)]="entity.subscription_status"
                    bindLabel="text"
                    bindValue="value"
                    (ngModelChange)="setStatus($event)"
                    [items]="status_options"
                  ></ng-select>
                </div>
              </div>
              <hr />

              <!-- Period -->
              <div
                class="form-group row"
                *ngIf="entity.validation?.messages['subscription_start']"
              >
                <div class="col-sm-12">
                  <div class="alert alert-warning">
                    {{ entity.validation.messages.subscription_start }}
                  </div>
                </div>
              </div>
              <div
                class="form-group row"
                *ngIf="entity.validation?.messages['subscription_end']"
              >
                <div class="col-sm-12">
                  <div class="alert alert-warning">
                    {{ entity.validation.messages.subscription_end }}
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-2 col-form-label font-weight-bold"
                  >Period</label
                >
                <div class="col-sm-10">
                  <ng-select
                    placeholder="Select TimeSpan"
                    [items]="time_span_options"
                    [multiple]="false"
                    [(ngModel)]="time_span"
                    bindLabel="text"
                    bindValue="value"
                    (ngModelChange)="selectSpan($event)"
                  ></ng-select>
                </div>
              </div>
            </div>
          </div>
        </ng-container>

        <!-- Existing Subscription -->
        <ng-container *ngIf="!entity?.new">
          <div class="d-flex">
            <div class="card mb-4 shadow-sm" style="width: 70%;">
              <div
                class="card-header bg-primary text-white d-flex align-items-center justify-content-between"
              >
                <div class="d-flex align-items-center">
                  <h5 class="card-title mb-0 mr-2">
                    Subscription #{{ entity?.identifier }}
                  </h5>
                  <div
                    class="rounded-circle"
                    [ngStyle]="{
                      width: '35px',
                      height: '35px',
                      'background-color':
                        entity.subscription_status == 'active' ? 'green' : 'red'
                    }"
                  ></div>
                </div>

                <div *ngIf="!inAppPurchase() && options['edit']">
                  <button
                    class="btn btn-danger btn-lg"
                    (click)="delete($event)"
                  >
                    <i class="fa fa-minus-circle"></i> Delete
                  </button>
                </div>
              </div>
              <div class="card-body">
                <!-- Type -->
                <div class="form-group row">
                  <label class="col-sm-2 col-form-label font-weight-bold"
                    >Type</label
                  >
                  <div class="col-sm-10">
                    <widget
                      [entity]="typeRepo"
                      (widgetEvent)="widgetEventHandler($event)"
                      [options]="{
                        edit: !inAppPurchase() && (options['edit'] || false),
                        selected: entity.subscription_type
                      }"
                      [layout]="'select'"
                    ></widget>
                  </div>
                </div>
                <hr />

                <!-- Handle -->
                <div class="form-group row">
                  <label class="col-sm-2 col-form-label font-weight-bold"
                    >Handle</label
                  >
                  <div class="col-sm-10">
                    <input
                      *ngIf="entity && options && options['edit']"
                      type="text"
                      class="form-control"
                      [(ngModel)]="entity.handle"
                      (ngModelChange)="editEntity()"
                    />
                    <span *ngIf="!(entity && options && options['edit'])">{{
                      entity.handle
                    }}</span>
                  </div>
                </div>
                <hr />

                <!-- Owner -->
                <div class="form-group row align-items-center">
                  <label class="col-sm-2 col-form-label font-weight-bold"
                    >Owner</label
                  >
                  <div class="col-sm-10">
                    <p class="form-control-static">{{ entity.owner }}</p>
                  </div>
                </div>
                <hr />

                <!-- Status -->
                <div class="form-group row">
                  <label class="col-sm-2 col-form-label font-weight-bold"
                    >Status</label
                  >
                  <div class="col-sm-10">
                    <ng-container *ngIf="!inAppPurchase() && options['edit']">
                      <ng-select
                        [name]="'subscription_status'"
                        [multiple]="false"
                        [(ngModel)]="entity.subscription_status"
                        bindLabel="text"
                        bindValue="value"
                        (ngModelChange)="setStatus($event)"
                        [items]="status_options"
                      ></ng-select>
                    </ng-container>
                    <ng-container
                      *ngIf="!(!inAppPurchase() && options['edit'])"
                    >
                      {{ entity.subscription_status }}
                    </ng-container>
                  </div>
                </div>
                <hr />

                <!-- Payment Details -->
                <div class="form-group row" *ngIf="entity.subscription_payment">
                  <label class="col-sm-2 col-form-label font-weight-bold"
                    >Payment Details</label
                  >
                  <div class="col-sm-10">
                    <widget
                      (widgetEvent)="forwardEvent($event)"
                      [entity]="entity.subscription_payment"
                      [layout]="layout"
                      [options]="options"
                    ></widget>
                  </div>
                </div>
                <hr *ngIf="entity.subscription_payment" />

                <!-- Period -->
                <div class="form-group row">
                  <label class="col-sm-2 col-form-label font-weight-bold"
                    >Period</label
                  >
                  <div class="col-sm-10">
                    <span *ngIf="entity.subscription_start">{{
                      entity.subscription_start | date : "medium"
                    }}</span>
                    -
                    <span *ngIf="entity.subscription_end">{{
                      entity.subscription_end | date : "medium"
                    }}</span>
                    <button
                      *ngIf="!inAppPurchase() && options['edit']"
                      class="btn btn-link btn-sm"
                      (click)="editPeriod($event, pickerModal)"
                    >
                      <i class="fa fa-pencil"></i>
                    </button>
                  </div>
                </div>
                <hr />

                <!-- SMS Cap -->
                <div class="form-group row">
                  <label class="col-sm-2 col-form-label font-weight-bold"
                    >SMS Cap</label
                  >
                  <div class="col-sm-10">
                    <p class="form-control-static">
                      {{ entity.sms_cap || "Null" }}
                    </p>
                  </div>
                </div>
                <hr />

                <!-- SMS Cap Refresh On -->
                <div class="form-group row">
                  <label class="col-sm-2 col-form-label font-weight-bold"
                    >SMS Cap Refresh On</label
                  >
                  <div class="col-sm-10">
                    <p class="form-control-static">
                      {{ entity.sms_cap_refresh_on || "Null" }}
                    </p>
                  </div>
                </div>
              </div>
              <div
                class="card-footer text-center"
                *ngIf="entity?.meta?.hasChanges"
              >
                <button
                  (click)="save($event)"
                  type="button"
                  class="btn btn-primary"
                >
                  <i class="fa fa-save"></i> Update
                </button>
              </div>
            </div>
            <div class="col">
              <entity-notes-v2
                [title]="'Subscription Notes'"
                [entity]="entity.sref()"
                [options]="{ expand: true, dragula: false }"
                [placeholder]="'Subscription Note.'"
              ></entity-notes-v2>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>

    <ng-template #pickerModal>
      <modal-widget
        *ngIf="currentModal"
        (widgetEvent)="eventHandler($event, 'pop-up', pickerModal)"
        [entity]="currentModal"
        [layout]="'shadowbox'"
        [options]="{}"
      ></modal-widget>
    </ng-template>
  