import { cloneDeep } from "lodash";
import { WidgetEnum } from "../../enums";

export class NoizuStruct {
  public strip_from_json: any = {};
  public _always_strip: any = {
    strip_from_json: 0,
    _always_strip: 0,
    validation: 0,
    meta: 0,
    srefModule: 0,
  };
  public meta: any = {
    fetching_forecast: false,
    fetching_status: false,
    editMode: false,
    hasChanges: false,
    saving: false,
  };
  public validation: any = {
    valid: false,
    validated: false,
    error: "Pending",
    messages: { common: null },
  };

  constructor() {}

  public deepCopy() {
    return cloneDeep(this);
  }

  isObject(value) {
    return value === Object(value);
  }

  isSRef(value) {
    return this.isString(value) && value.startsWith("ref.");
  }

  isString(value) {
    return typeof value === "string";
  }

  identifierToSref(srefModule, json, provider: (any) => string = null) {
    if (!this.isSRef(json)) {
      let raw = provider ? provider(json) : json;
      return `ref.${srefModule}.${raw}`;
    } else {
      return json;
    }
  }

  srefToIdentifier(srefModule, json, castInt = false) {
    if (this.isSRef(json)) {
      console.log("is sref", json);
      let prefix = `ref.${srefModule}.`;
      if (json && this.isString(json) && json.startsWith(prefix)) {
        let raw = json.substring(prefix.length);
        if (castInt) {
          return Number.parseInt(raw);
        } else {
          return raw;
        }
      } else {
        console.log("is not prefix", json, prefix);
        return json || null;
      }
    } else {
      console.log("is not sref", json);
      return json;
    }
  }

  toJson(options: any = {}) {
    let c: any = {};
    let strip_level = 3;
    let strip_from_json = this.strip_from_json;

    // Overrides
    if ("strip_level" in options) {
      strip_level = options.strip_level;
    }
    if ("strip_from_json" in options) {
      strip_from_json = options.strip_from_json;
    }

    for (let key in this) {
      if (!Object.hasOwnProperty(key)) {
        let key_str = key.toString();
        if (
          (key_str in strip_from_json &&
            strip_from_json[key_str] < strip_level) ||
          (key_str in this._always_strip &&
            this._always_strip[key_str] < strip_level)
        ) {
          // skip
        } else {
          if (this[key] instanceof NoizuStruct) {
            let p: any = this[key];
            c[key] = p.toJson(options);
          } else {
            if (
              !(
                this[key] && {}.toString.call(this[key]) === "[object Function]"
              )
            ) {
              if (Array.isArray(this[key])) {
                let o = [];
                let a: any = this[key];
                for (let na = 0; na < a.length; na++) {
                  // todo recursive array support;
                  if (a[na] instanceof NoizuStruct) {
                    o.push(a[na].toJson(options));
                  } else {
                    o.push(a[na]);
                  }
                }
                c[key] = o;
              } else {
                c[key] = this[key];
              }
            } else {
              //  console.warn("SKIP", key, this[key]);
            }
          }
        }
      }
    }
    return c;
  }

  widget() {
    return "embed-default";
  }

  // todo setup type declarations.
  widget_type(): any {
    return WidgetEnum.EMBED_WIDGET__LEGACY;
  }

  validate() {
    this.validation = {
      valid: false,
      validated: false,
      error: "Validation not supported for this type.",
      messages: { common: "Validation Not Supported" },
    };
    return this.validation;
  }
}
