import { Component, Input, Output, EventEmitter } from "@angular/core";
import { AlertTriggerStateObject } from "../../../entities/alert";
import { WidgetBase } from "../widget-base";
import { AlertWidgetEventStruct } from "./alert-widget-event.struct";

@Component({
  selector: "alert-trigger-state-widget",
  template: `
    <div
      class="alert-trigger-state-widget card "
      [ngClass]="{
        'card-accent-danger': entity.triggered,
        'card-accent-success': !entity.triggered
      }"
    >
      <div class="card-header">
        <div class="row">
          <div class="col"><b class="text-left">Trigger State</b></div>
          <div class="col-2 text-right p-0 text-middle">
            <toggle-widget [options]="expand_settings"></toggle-widget>
          </div>
        </div>
      </div>
      <div
        class="card-body m-0 p-0"
        [ngClass]="{ 'd-none': !expand_settings.toggle }"
      >
        <div
          class="alert m-0"
          [ngClass]="{
            'alert-danger': entity.triggered,
            'alert-success': !entity.triggered
          }"
        >
          <p class="text-center">
            {{ (entity?.triggered && "triggered") || "green" }}
          </p>
        </div>
      </div>
    </div>
  `,
})
export class AlertTriggerStateObjectWidgetComponent extends WidgetBase {
  @Input() entity: AlertTriggerStateObject = null;
  @Input() options: any = null;
  @Input() layout: string = null;
  @Output() widgetEvent = new EventEmitter<AlertWidgetEventStruct>();
  forwardEvent(e, widgetEvent: AlertWidgetEventStruct) {
    e.preventDefault();
    this.widgetEvent.emit(widgetEvent);
  }

  public expand_on_load = true;
  constructor() {
    super();
  }
}
