import { Inject, Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { FirebaseAuthService } from "../noizu/services/firebase-auth.service";
import { ElixirRepo } from "./elixir.repo";
import { HealthReportEntity } from "../entities/report/health-report.entity";
import { WorkerReportEntity } from "../entities/report/worker-report.entity";

@Injectable()
export class HealthReportRepo extends ElixirRepo {
  constructor(
    @Inject(HttpClient) client: HttpClient,
    @Inject(FirebaseAuthService) auth: FirebaseAuthService
  ) {
    super(client, auth);
  }

  public components = [
    "stage1",
    "stage2",
    "stage3",
    "alerts",
    "notifications",
    "datastreams",
    "monitors",
    "export",
    "feed",
    "log",
    "pubsub",
  ];

  public clearMaintenance() {
    return this._delete(
      `${this.apiBase()}/admin-tools/maintenance-mode`,
      (record, resolve) => {
        resolve(true);
      },
      {}
    );
  }

  public scheduleMaintenance(reason: string = "Maintenance Scheduled") {
    return this._put(
      `${this.apiBase()}/admin-tools/maintenance-mode`,
      { reason: reason },
      (record, resolve) => {
        resolve(true);
      },
      {}
    );
  }

  getNodes() {
    //
    return this._get(
      `${this.apiBase()}/admin-tools/nodes`,
      (record, resolve) => {
        resolve(record);
      },
      {}
    );
  }

  getNodeReport(node) {
    return this._get(
      `${this.apiBase()}/admin-tools/${node}/health-check`,
      (record, resolve) => {
        record["identifier"] = node;
        let report = new HealthReportEntity(this.client, this.auth, record);
        resolve(report);
      },
      {}
    );
  }

  getWorkerTally(component) {
    return this._get(
      `${this.apiBase()}/admin-tools/worker_tally/${component}`,
      (record, resolve) => {
        let response = new WorkerReportEntity(this.client, this.auth, record);
        resolve(response);
      },
      {}
    );
  }

  getWorkerReport(type) {
    return this._get(
      `${this.apiBase()}/admin-tools/worker/${type}/report`,
      (record, resolve) => {
        let response = record;
        resolve(response);
      },
      {}
    );
  }

  getUserRegistrationReport() {
    return this._get(
      `${this.apiBase()}/admin-tools/user-registration/report?format=json`,
      (record, resolve) => {
        let response = record;
        resolve(response);
      },
      {}
    );
  }

  getUserRegistrationPlot(period) {
    return this._get(
      `${this.apiBase()}/admin-tools/user-registration/${period}/plot`,
      (record, resolve) => {
        let response = record;
        resolve(response);
      },
      {}
    );
  }

  getSystemStatus() {
    return this._get(
      `${this.apiBase()}/admin-tools/system-status`,
      (record, resolve) => {
        let response = record;
        resolve(response);
      },
      {}
    );
  }

  getDailyMetrics(metric) {
    return this._get(
      `${this.apiBase()}/metrics/${metric}/daily`,
      (record, resolve) => {
        let response = record;
        resolve(response);
      },
      {}
    );
  }

  getHourlyMetrics(metric) {
    return this._get(
      `${this.apiBase()}/metrics/${metric}/hourly`,
      (record, resolve) => {
        let response = record;
        resolve(response);
      },
      {}
    );
  }

  getMetricPlot(metric) {
    return this._get(
      `${this.apiBase()}/metrics/${metric}/counters`,
      (record, resolve) => {
        let response = record;
        resolve(response);
      },
      {}
    );
  }
}
