import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { FirmwareLinkV2Repo } from "../../../repos";
import { FirmwareLinkV2Entity } from "../../../entities";

@Component({
  template: `
    <div *ngIf="entity">
      <firmware-link-widget
        [entity]="entity"
        [layout]="'full'"
        [options]="{}"
      ></firmware-link-widget>
    </div>
  `,
})
export class FirmwareLinkViewComponent implements OnInit {
  public entity: FirmwareLinkV2Entity = null;
  public subscription: any;

  constructor(
    public activatedRoute: ActivatedRoute,
    public repo: FirmwareLinkV2Repo
  ) {}

  ngOnInit() {
    this.subscription = this.activatedRoute.params.subscribe((params: any) => {
      if ("id" in params) {
        this.repo
          .getEntityPromise(params["id"])
          .then((entity: FirmwareLinkV2Entity) => (this.entity = entity));
      }
    });
  }
}
