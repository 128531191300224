import { HttpClient } from "@angular/common/http";
import { LacrosseEntity } from "./lacrosse.entity";
import { FirebaseAuthService } from "../noizu/services/firebase-auth.service";

export class SubscriptionsEntity extends LacrosseEntity {
  public notes: String = "";
  public name: String;
  public new: boolean = false;
  public description = "";
  public code = "";
  public features;
  public exportMaxUnit;
  public viewMaxUnit;
  public sms_checked: boolean = false;

  constructor(client: HttpClient, auth: FirebaseAuthService, json) {
    super(client, auth, json);
    this._kind = "subscriptions";
    this._singular = "subscription";
    this.name = json.name;
    if (json.new) {
      this.new = json.new;
    }
    this.refresh(json);
  }

  refresh(data) {
    let res = super.refresh(data);
    this.identifier = data.id || data.identifier;
    this.name = data.name;
    this.description = data.description;
    this.code = data.code;
    this.features = {};
    if (data.features) {
      if (data.features.attributes) {
        this.features["attributes"] = data.features.attributes;
      }

      if (data.features.export) {
        this.features["export"] = data.features.export;
      } else {
        this.features["export"] = { available: false };
      }

      if (data.features.view) {
        this.features["view"] = data.features.view;
      } else {
        this.features["view"] = { available: false };
      }
      if (
        this.features.export?.max &&
        this.features.export.max.startsWith("ai.days.")
      ) {
        this.exportMaxUnit = "ai.days.";
      } else {
        this.exportMaxUnit = "ai.years.";
      }
      if (
        this.features.view?.max &&
        this.features.view.max.startsWith("ai.days.")
      ) {
        this.viewMaxUnit = "ai.days.";
      } else {
        this.viewMaxUnit = "ai.years.";
      }

      if (data.features.notifications) {
        this.features["notifications"] = data.features.notifications;
        this.sms_checked = data.features.notifications.sms ? true : false;
      }
    } else {
      this.features = {
        attributes: { trial: false },
        export: {},
        view: {},
        notifications: {},
      };
    }
    return res;
  }
}
