<page-header [section]="'user-search'">
  <page-title>User Search</page-title>
  <page-blurb
    >Get a list of users to view their Firebase ID and names, or search a
    specific user by account email, notification email, last name, Firebase ID
    and Firebase email. Search a specific user to initiate actions to send
    password or reset email requests, and update user account
    details.</page-blurb
  >
</page-header>

<div class="row">
  <div class="col-12">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            Search User List

            <span class="float-right">Beta View</span>
            <label class="switch switch-3d switch-primary float-right">
              <input
                checked=""
                class="switch-input switch-primary"
                (change)="toggleVersion($event)"
                [(ngModel)]="version_toggle"
                type="checkbox"
              />
              <span class="switch-slider"></span>
            </label>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <form class="form-horizontal">
                  <div class="form-group row">
                    <div class="col-md-12">
                      <div class="input-group">
                        <div class="pull-left" style="width: 25%">
                          <div
                            class="input-group-btn"
                            *ngIf="
                              fAuth.permissionLevel <= 1 ||
                              fAuth.permissionsList['user-list']
                            "
                          >
                            <button
                              (click)="listUsers($event)"
                              type="button"
                              class="btn btn-info"
                            >
                              <i class="fa fa-list"></i> List All Users
                            </button>
                          </div>
                        </div>
                        <div style="width: 75%" class="pull-right">
                          <div class="pull-right">
                            Format Input (Trim WhiteSpace, Lowercase Email)
                            <input
                              type="checkbox"
                              [checked]="trimInput"
                              (change)="toggleTrim()"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="form-group row">
                    <div class="col-md-12">
                      <div class="input-group">
                        <span class="input-group-btn">
                          <button
                            (click)="
                              searchUsers(
                                searchTerm.displayName,
                                'displayName',
                                $event
                              )
                            "
                            type="button"
                            class="btn btn-primary"
                          >
                            <i class="fa fa-search"></i> Search Account Email
                          </button>
                        </span>
                        <input
                          type="text"
                          (keyup.enter)="
                            searchUsers(
                              searchTerm.displayName,
                              'displayName',
                              $event
                            )
                          "
                          name="searchTerm[displayName]"
                          class="form-control"
                          placeholder="User Account Email"
                          [(ngModel)]="searchTerm.displayName"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="form-group row">
                    <div class="col-md-12">
                      <div class="input-group">
                        <span class="input-group-btn">
                          <button
                            (click)="
                              searchUsers(searchTerm.email, 'email', $event)
                            "
                            type="button"
                            class="btn btn-primary"
                          >
                            <i class="fa fa-search"></i> Search Notification
                            Email
                          </button>
                        </span>
                        <input
                          type="text"
                          name="searchTerm[email]"
                          (keyup.enter)="
                            searchUsers(searchTerm.email, 'email', $event)
                          "
                          class="form-control"
                          placeholder="User Notification Email"
                          [(ngModel)]="searchTerm.email"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="form-group row">
                    <div class="col-md-12">
                      <div class="input-group">
                        <span class="input-group-btn">
                          <button
                            (click)="
                              searchUsers(
                                searchTerm.lastName,
                                'lastName',
                                $event
                              )
                            "
                            type="button"
                            class="btn btn-primary"
                          >
                            <i class="fa fa-search"></i> Search Last Name
                          </button>
                        </span>
                        <input
                          type="text"
                          name="searchTerm[lastName]"
                          (keyup.enter)="
                            searchUsers(searchTerm.lastName, 'lastName', $event)
                          "
                          class="form-control"
                          placeholder="User Last Name (Case Sensitive)"
                          [(ngModel)]="searchTerm.lastName"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="form-group row">
                    <div class="col-md-12">
                      <div class="input-group">
                        <span class="input-group-btn">
                          <button
                            (click)="searchUsers(searchTerm.id, 'id', $event)"
                            type="button"
                            class="btn btn-primary"
                          >
                            <i class="fa fa-search"></i> Search Firebase Id
                          </button>
                        </span>
                        <input
                          type="text"
                          name="searchTerm[id]"
                          (keyup.enter)="
                            searchUsers(searchTerm.id, 'id', $event)
                          "
                          class="form-control"
                          placeholder="User Firebase Id"
                          [(ngModel)]="searchTerm.id"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="form-group row">
                    <div class="col-md-12 mt-3">
                      <div class="input-group">
                        <span class="input-group-btn">
                          <button
                            (click)="searchFirebase($event)"
                            type="button"
                            class="btn btn-primary"
                          >
                            <i class="fa fa-search"></i> Search Firebase for
                            Email
                          </button>
                        </span>
                        <input
                          type="text"
                          name="searchFirebase"
                          (keyup.enter)="searchFirebase($event)"
                          class="form-control"
                          placeholder="User Firebase Email"
                          [(ngModel)]="firebaseEmail"
                        />
                      </div>
                    </div>
                  </div>

                  <div>
                    <div
                      *ngIf="FBSearchResult.length == 1"
                      (click)="FBSearchResult = ''"
                      role="alert"
                      class="alert alert-info"
                    >
                      <widget-spinner></widget-spinner>
                    </div>
                    <div
                      *ngIf="FBSearchResult.length > 1"
                      (click)="FBSearchResult = ''"
                      role="alert"
                      class="alert alert-info"
                    >
                      {{ FBSearchResult }}. Click to Clear
                    </div>
                  </div>
                </form>
                <div
                  *ngIf="state.error"
                  role="alert"
                  class="alert alert-danger"
                  (click)="state.error = false"
                >
                  {{ state.errorMsg }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="version == '2.0'">
      <div class="col-12">
        <div *ngIf="state?.loading || searchResults" class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-header">
                <i class="fa fa-align-justify"></i> Search Results
              </div>
              <div class="card-body">
                <new-spinner *ngIf="state?.loading" [size]="'lg'"></new-spinner>
                <div *ngIf="!state?.loading">
                  <widget
                    [entity]="searchResults"
                    [layout]="'table'"
                    [options]="{ dpp: 250, expand: true, columns: 2 }"
                  ></widget>
                </div>
              </div>
            </div>
            <!-- end search results card -->
          </div>
        </div>
      </div>
    </div>
    <!-- end search results row -->

    <div class="row" *ngIf="version == '1.0'">
      <div class="col-12">
        <div *ngIf="state?.loading || searchResults" class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-header">
                <i class="fa fa-align-justify"></i> Search Results
                <div class="float-right">
                  Filter First Name on this page:
                  <input
                    type="text"
                    placeholder="First Name"
                    [(ngModel)]="fnf"
                    (keyup)="filterTextUpdate()"
                  />
                </div>
              </div>
              <div class="card-body card-block">
                <div
                  *ngIf="state?.loading"
                  class="overlayParent spinner-holder"
                >
                  <div class="overlay">
                    <widget-spinner></widget-spinner>
                  </div>
                </div>
                <div *ngIf="!state?.loading">
                  <div *ngIf="searchResults?.items.length == 0">
                    {{ noResultsMessage }}
                  </div>
                  <div *ngIf="!(searchResults?.items.length == 0)">
                    <ul class="pagination">
                      <li class="page-item" *ngIf="pagination.page > 0">
                        <a
                          (click)="searchAt(pagination.page - 1, $event)"
                          class="page-link"
                          >Previous</a
                        >
                      </li>
                      <li
                        class="page-item"
                        *ngFor="
                          let cursor of pagination.cursorArray;
                          let cursorIndex = index
                        "
                        [ngClass]="{ active: cursorIndex == pagination.page }"
                      >
                        <a
                          (click)="searchAt(cursorIndex, $event)"
                          class="page-link"
                          >{{ cursorIndex + 1 }}</a
                        >
                      </li>
                      <li
                        class="page-item"
                        *ngIf="pagination.next && pagination.next != ''"
                      >
                        <a (click)="searchNext($event)" class="page-link"
                          >Next</a
                        >
                      </li>
                    </ul>

                    <table class="table table-bordered table-striped table-sm">
                      <thead>
                        <tr>
                          <th>Id</th>
                          <th>First</th>
                          <th>Last</th>
                          <th>displayName</th>
                          <th>Email</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let user of searchResults.items">
                          <td>
                            <a
                              (click)="viewUser(user, $event)"
                              href="/portal/users/show/{{ user.identifier }}"
                              >{{ user.identifier }}</a
                            >
                          </td>
                          <td>{{ user.first_name }}</td>
                          <td>{{ user.last_name }}</td>
                          <td>{{ user.display_name }}</td>
                          <td>{{ user.email }}</td>
                          <td>
                            <span
                              (click)="passwordReset(confirm, user, $event)"
                              class="badge badge-info"
                              >Send Password Reset Email</span
                            >
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <ul class="pagination">
                      <li class="page-item" *ngIf="pagination.page > 0">
                        <a
                          (click)="searchAt(pagination.page - 1, $event)"
                          class="page-link"
                          >Previous</a
                        >
                      </li>
                      <li
                        class="page-item"
                        *ngFor="
                          let cursor of pagination.cursorArray;
                          let cursorIndex = index
                        "
                        [ngClass]="{ active: cursorIndex == pagination.page }"
                      >
                        <a
                          (click)="searchAt(cursorIndex, $event)"
                          class="page-link"
                          >{{ cursorIndex + 1 }}</a
                        >
                      </li>
                      <li
                        class="page-item"
                        *ngIf="pagination.next && pagination.next != ''"
                      >
                        <a (click)="searchNext($event)" class="page-link"
                          >Next</a
                        >
                      </li>
                    </ul>
                  </div>
                  <!-- end *ngIf="searchResults" -->
                </div>
              </div>
            </div>
            <!-- end search results card -->
          </div>
        </div>
      </div>
    </div>
    <!-- end search results row -->

    <div class="row" *ngIf="listUsersToggle">
      <div class="col-12">
        <div class="card overlayParent">
          <div class="overlay" *ngIf="usersList.loading">
            <widget-spinner></widget-spinner>
          </div>
          <div class="card-header">List Users</div>
          <div class="card-block">
            <div class="row">
              <div class="col-12">
                <div class="row">
                  <div class="col-3">
                    Rows: <br /><input
                      type="number"
                      placeholder="Results Per Page"
                      class="form-control"
                      [(ngModel)]="listUsersParams.resultsPerPage"
                    />
                  </div>
                  <div class="col-9">
                    Start Location (Cursor): <br /><input
                      type="text"
                      placeholder="Cursor"
                      class="form-control"
                      [(ngModel)]="listUsersParams.cursor"
                    />
                  </div>
                </div>
                <br />
                <button
                  (click)="getListUsers($event)"
                  type="button"
                  class="btn btn-primary"
                >
                  <i class="fa fa-search"></i>Get List
                </button>
              </div>
              <div class="col-12">
                <div style="word-wrap: break-word">
                  Next Cursor: {{ usersList.cursor }}
                </div>
                <br /><br />

                <pre>{{ usersList.data }}</pre>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #confirm>
  <div class="modal-header">
    <h4 class="modal-title pull-left">{{ currentModal.title }}</h4>
    <button
      type="button"
      class="close pull-right"
      aria-label="Close"
      (click)="modalRef.hide()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    {{ currentModal.msg }}
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-secondary"
      (click)="currentModal.cancel()"
      data-dismiss="modal"
    >
      {{ currentModal.cancelMsg }}
    </button>
    <button
      type="button"
      class="btn btn-danger"
      (click)="currentModal.confirm()"
    >
      {{ currentModal.confirmMsg }}
    </button>
  </div>
</ng-template>
