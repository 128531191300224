import { Inject, Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { FirebaseAuthService } from "../noizu/services/firebase-auth.service";
import { AppengineRepo } from "./appengine.repo";
import { GroupEntity } from "../entities/group.entity";

@Injectable()
export class GroupRepo extends AppengineRepo {
  public _kind = "groups";
  public _singular = "group";
  public _appengine = true;

  constructor(
    @Inject(HttpClient) client: HttpClient,
    @Inject(FirebaseAuthService) auth: FirebaseAuthService
  ) {
    super(client, auth);
  }

  entity(record) {
    return new GroupEntity(this.client, this.auth, record);
  }

  getSingularPromise(id, options: any = {}) {
    this._appengine = false;
    let url = `${this.apiBase()}/${this._singular}/${id}`;
    return this._getListPromise(
      url,
      (record) => {
        return this.entity(record);
      },
      options
    );
  }

  getListPromise(options: any = {}) {
    this._appengine = true;
    let url = `${this.apiBase()}/${this._kind}?resultsPerPage=500`;
    if ("id" in options) url = url + `&id=${encodeURIComponent(options.id)}`;
    if ("system" in options && options["system"] == true)
      url = url + "&system=true";
    if ("cursor" in options) {
      url = url + `&cursor=${options.cursor}`;
    }
    return this._getListPromise(
      url,
      (record) => {
        return this.entity(record);
      },
      options
    );
  }
}
