import { Component, Input } from "@angular/core";
import { SensorTypeRangeEntity } from "../../../entities/sensor-type-range.entity";
import { SensorTypeRangeRepo } from "../../../repos/sensor-type-range.repo";

@Component({
  selector: "sensor-type-entry",
  templateUrl: "./sensor-type-entry.component.html",
})
export class SensorTypeRangeEntryComponent {
  @Input() entry: SensorTypeRangeEntity = null;
  public unmodifiedEntry = null;
  public editEntry: boolean = false;
  constructor(public repo: SensorTypeRangeRepo) {}

  update(e, entity) {
    e.preventDefault();
    this.editEntry = false;
    entity.update();
  }

  edit(e, entity) {
    e.preventDefault();
    this.unmodifiedEntry = this.entry.toJson();
    this.editEntry = true;
  }

  cancel(e) {
    e.preventDefault();
    this.entry.refresh(this.unmodifiedEntry);
    this.editEntry = false;
  }

  delete(e, entity) {
    e.preventDefault();
    // todo remove from parent so it vanishes after success
    this.editEntry = false;
    entity.delete();
  }
}
