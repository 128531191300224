import {Component, Input, Inject, Output, EventEmitter} from '@angular/core';
import {NoizuStruct} from '../../../noizu/structs/noizu-struct';
import {EmailWidgetEnum, WidgetEnum} from '../../../enums/widget';
import {EmailWidgetEventStruct} from './email-widget-event.struct';
import {EmailEntity} from '../../../entities/data/email.entity';
import {EmailQueueEntity} from '../../../entities';

@Component({
  selector: 'email-queue-entry-widget',
  template: `

    <ng-container [ngSwitch]="layout" *ngIf="entity">
      <ng-container *ngSwitchCase="'table'">
        <div class="email-queue-entry-widget div-table-th" *ngIf="options['index'] == 0 && options['header']">
          <div class="row" >
            <ng-container *ngFor="let column of options.columns">
              <ng-container [ngSwitch]="column">
                <div class="col-1" *ngSwitchCase="'identifier'">#ID</div>
                <div class="col-2" *ngSwitchCase="'created_on'">Created</div>
                <div class="col-2" *ngSwitchCase="'state'">State</div>
                <div class="col-3" *ngSwitchCase="'template'">Template</div>
                <div class="col-2" *ngSwitchCase="'email'">Email</div>
                <div class="col" *ngSwitchCase="'actions'">Actions</div>
              </ng-container>
            </ng-container>
          </div>
        </div>
        <div class="email-queue-entry-widget" [ngClass]="{'div-table-tr': (options['index'] % 2) == 1, 'div-table-tr-alt': (options['index'] % 2) == 0}">
          <div class="row">
            <ng-container *ngFor="let column of options.columns">

              <ng-container [ngSwitch]="column">
                <div class="col-1 text-truncate" *ngSwitchCase="'identifier'" title="{{entity.identifier}}" >{{entity.identifier}}</div>
                <div class="col-2 text-truncate" *ngSwitchCase="'created_on'" title="{{entity.created_on}}">{{entity.created_on| date: 'short'}}</div>
                <div class="col-2 text-truncate" *ngSwitchCase="'state'" title="{{entity.state}}">{{entity.state}}</div>
                <div class="col-3 text-truncate" *ngSwitchCase="'template'" title="{{entity.template}}">{{entity.template}}</div>
                <div class="col-2 text-truncate" *ngSwitchCase="'email'" title="{{entity.binding.recipient_email}}">{{entity.binding.recipient_email}}</div>
                <div *ngSwitchCase="'actions'" class="col text-left">
                  <i
                    (click)="openRow = !openRow"
                    class="fa fa-sm"
                    [ngClass]="{'fa-chevron-down': !openRow, 'fa-chevron-up': openRow}"> </i>
                </div>
              </ng-container>

            </ng-container>
          </div>
          <div class="row" style="margin-left: unset; width:100%;" *ngIf="openRow">
            <div class="col-12 p-2">
                <email-queue-entry-widget [entity]="entity" [options]="options" [layout]="'standard'"></email-queue-entry-widget>
            </div>
          </div>
        </div>
      </ng-container>

      <div class="card" *ngSwitchCase="'standard'">
        <div class="card-header">{{entity.identifier}} - {{entity.binding.recipient_email}} - {{entity.template}} </div>
        <div class="card-body">

          <div class="row">
            <div class="col">Template</div>
            <div class="col">{{entity.template}}</div>
          </div>
          <div class="row">
            <div class="col">Created</div>
            <div class="col">{{entity.created_on| date: 'short'}}</div>
          </div>
          <div class="row">
            <div class="col">State</div>
            <div class="col">{{entity.state}}</div>
          </div>
          <div class="row">
            <div class="col">Recipient</div>
            <div class="col">{{entity.recipient}}</div>
          </div>
          <div class="row">
            <div class="col">Email</div>
            <div class="col">{{entity.binding.recipient_email}}</div>
          </div>
          <div class="row">
            <div class="col">Retry After</div>
            <div class="col">{{entity.retry_on}}</div>
          </div>


          <div class="card">
            <div class="card-header">Extended Details <i class="float-right fa fa-plus" (click)="expand = !expand"></i></div>
            <div class="card-body" *ngIf="expand">


              <div class="row">
                <div class="col-3">Sender</div>
                <div class="col">{{entity.sender}}</div>
              </div>
              <div class="row">
                <div class="col-3">Template</div>
                <div class="col">{{entity.template}}</div>
              </div>
              <div class="row">
                <div class="col-3">Version</div>
                <div class="col">{{entity.version | json}}</div>
              </div>

              <div class="row">
                <div class="col"><strong>Binding</strong></div>
              </div>
              <div class="row">
                <div class="col-3">vsn</div>
                <div class="col">{{entity.binding.vsn}}</div>
              </div>

              <div class="row">
                <div class="col-3">Subject</div>
                <div class="col">{{entity.binding.subject}}</div>
              </div>
              <div class="row">
                <div class="col-3">State</div>
                <div class="col">{{entity.binding.state}}</div>
              </div>

              <div class="row">
                <div class="col-3">Sender</div>
                <div class="col">{{entity.binding.sender}}</div>
              </div>
              <div class="row">
                <div class="col-3">Sender Name</div>
                <div class="col">{{entity.binding.sender_name}}</div>
              </div>
              <div class="row">
                <div class="col-3">Sender Email</div>
                <div class="col">{{entity.binding.sender_email}}</div>
              </div>

              <div class="row">
                <div class="col-3">Recipient</div>
                <div class="col">{{entity.binding.recipient}}</div>
              </div>
              <div class="row">
                <div class="col-3">Recipient Name</div>
                <div class="col">{{entity.binding.recipient_name}}</div>
              </div>
              <div class="row">
                <div class="col-3">Recipient Email</div>
                <div class="col">{{entity.binding.recipient_email}}</div>
              </div>

              <div class="row">
                <div class="col-3">Template Version</div>
                <div class="col">{{entity.binding.template_version | json}}</div>
              </div>
              <div class="row">
                <div class="col-3">Unbound</div>
                <div class="col"><pre>{{entity.binding.unbound | json}}</pre></div>
              </div>
              <div class="row">
                <div class="col"><strong>Effective Binding</strong></div>
              </div>
              <div class="row" *ngIf="entity.binding.effective_binding">
                <div class="col-3">Effective Binding - Bound</div>
                <div class="col">
                  <pre>{{entity.binding.effective_binding.bound | json}}</pre></div>
              </div>

              <div class="row" *ngIf="entity.binding.effective_binding">
                <div class="col-3">Effective Binding - Unbound (optional)</div>
                <div class="col">
                  <div *ngFor="let b of entity.binding.effective_binding.unbound.optional ">
                    <span *ngFor="let s of b"> &gt; {{s}}</span>
                  </div>
                </div>
              </div>

              <div class="row" *ngIf="entity.binding.effective_binding">
                <div class="col-3">Effective Binding - Unbound (required)</div>
                <div class="col">
                  <div *ngFor="let b of entity.binding.effective_binding.unbound.required ">
                    <span *ngFor="let s of b"> &gt; {{s}}</span>
                  </div>
                </div>
              </div>

              <div class="row" *ngIf="entity.binding.effective_binding">
                <div class="col-3">Effective Binding - Bind</div>
                <div class="col">
                  <div *ngFor="let b of entity.binding.effective_binding.bind">
                    <span *ngFor="let s of b"> &gt; {{s}}</span>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-3">Body</div>
                <div class="col" [innerHtml]="entity.binding.html_body"></div>
              </div>


            </div>
          </div>

        </div>
      </div>

      <div class="row" *ngSwitchDefault>
        <div class="col">
          [Pending: {{layout}}]
        </div>
      </div>

    </ng-container>


`
})
export class EmailQueueWidgetComponent {
  @Input() entity: EmailQueueEntity | null = null;
  @Input() templateHint: string = null;
  @Input() options: any = null;
  @Input() layout = 'standard';
  expand = false;
  expandRaw = false;
  openRow = false;

  @Output() widgetEvent = new EventEmitter<EmailWidgetEventStruct>();
  forwardEvent(widgetEvent: EmailWidgetEventStruct) {
    this.widgetEvent.emit(widgetEvent);
  }
  constructor() {
  }
}
