import { Component, Input } from "@angular/core";
import { EmailTemplateEntity } from "../../../entities/data/email-template.entity";

@Component({
  selector: "email-queue",
  templateUrl: "./show.component.html",
})
export class EmailQueueShowComponent {
  @Input() entry: EmailTemplateEntity = null;
  public unmodifiedEntry = null;
  public editEntry: boolean = false;
  public deleted: boolean = false;
  public saving: boolean = false;
  constructor() {}

  update(e, entity) {
    e.preventDefault();
    this.saving = true;
    entity.update();
    this.editEntry = false;
    this.saving = false;
  }

  edit(e, entity) {
    e.preventDefault();
    this.unmodifiedEntry = this.entry.toJson();
    this.editEntry = true;
  }

  cancel(e) {
    e.preventDefault();
    this.entry.refresh(this.unmodifiedEntry);
    this.editEntry = false;
  }

  delete(e, entity) {
    e.preventDefault();
    this.saving = true;
    this.editEntry = false;
    entity.delete().then((res: any) => {
      if (res) {
        this.deleted = true;
      }
      this.saving = false;
    });
  }
}
